<template>
  <!-- S 头部header -->
<!--  <div class="header">-->
<!--    <div class="header-nav">-->
<!--      <div class="header-nav-back" @click="goBackPage()">-->
<!--        <van-icon name="arrow-left"/>-->
<!--      </div>-->
<!--      <span>备注</span>-->
<!--    </div>-->
<!--  </div>-->
  <!-- E 头部header -->
<div>
  <div class="content-wrap">
    <!-- S 文本域模块 -->
    <div class="info-box">
      <div class="info-title">
        <span>备注内容</span>
      </div>
      <div class="info-input info-textarea">
        <van-field
            class="info-textarea-edit"
            v-model="remarks"
            autocomplete="off"
            rows="1"
            autosize
            type="textarea"
            placeholder="请输入备注内容..."
        />
      </div>
    </div>
    <!-- E 文本域模块 -->
    <div class="remarks-submit" @click="submitRemarks">提交</div>
  </div>
  <!-- S 底部导航栏 -->
  <div class="occupy-box"></div>
  <van-tabbar v-model="active">
    <van-tabbar-item replace to="/index">
      <span>首页</span>
      <template #icon="props">
        <div class="navIcon" v-bind:class="{ active: props.active}">
          <el-icon>
            <home-filled/>
          </el-icon>
        </div>
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/customManage">
      <span>客户管理</span>
      <template #icon="props">
        <div class="navIcon" v-bind:class="{ active: props.active}">
          <el-icon>
            <user/>
          </el-icon>
        </div>
      </template>
    </van-tabbar-item>
<!--    <van-tabbar-item replace to="/signedResources">-->
<!--      <span>已签约</span>-->
<!--      <template #icon="props">-->
<!--        <div class="navIcon" v-bind:class="{ active: props.active}">-->
<!--          <el-icon>-->
<!--            <edit/>-->
<!--          </el-icon>-->
<!--        </div>-->
<!--      </template>-->
<!--    </van-tabbar-item>-->
<!--    <van-tabbar-item replace to="/sharedPool">-->
<!--      <span>共享池</span>-->
<!--      <template #icon="props">-->
<!--        <div class="navIcon" v-bind:class="{ active: props.active}">-->
<!--          <el-icon>-->
<!--            <monitor/>-->
<!--          </el-icon>-->
<!--        </div>-->
<!--      </template>-->
<!--    </van-tabbar-item>-->
  </van-tabbar>
  <!-- S 底部导航栏 -->
</div>
</template>

<script>
  import {addCustomerRemark} from '../api/java'
  import {Notify} from 'vant';
  import {ref} from "vue";
  export default {
    data() {
      return {
        remarks: '', // 备注
      }
    },

    // 生命周期 初始化
    setup() {
      const active = ref(''); // 设定选定第几个
      return {
        active,
      };
    },

    methods: {
      // 返回上一页
      goBackPage() {
        this.$router.go(-1)
      },

      // 提交
      submitRemarks() {
        let para = {
          content: this.remarks, // 备注内容
          customer_id: this.$route.query.id
        }
        addCustomerRemark(para).then(data => {
          if (data.code === 200) {
            let  that = this
            Notify({
              type: 'success',
              message: '添加成功',
              duration: 750
            })
            setTimeout(function () {
              that.$router.go(-1)
            }, 800)
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      }
    }
  }
</script>

<style scoped>
  .content-wrap {
    position: fixed;
    padding: 20px 15px;
    background: #fff;
    height: 100%;
  }

  .info-box {
    width: 345px;
    margin: 0 auto 16px;
  }

  .info-title {
    color: #A1A7BA;
    font-size: 15px;
  }

  .info-input {
    position: relative;
    width: 343px;
    height: 43px;
    margin-top: 12px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
  }

  .info-textarea {
    height: 110px;
  }

  .info-textarea-edit {
    color: #222 !important;
    font-size: 15px !important;
  }

  .info-textarea-edit >>> textarea::-webkit-input-placeholder {
    color: #606266 !important;
  }

  .info-textarea-edit >>> textarea {
    min-height: 88px;
  }

  .remarks-submit {
    width: 345px;
    height: 50px;
    margin: 25px auto 0;
    background: #409EFF;
    color: #fff;
    font-size: 17px;
    text-align: center;
    line-height: 50px;
    border-radius: 50px;
  }
</style>
