<template>
    <div>
        <!-- S 头部header -->
        <div class="header">
            <div class="header-nav">
                <div class="submit-btn" @click="add()">添加</div>
            </div>
        </div>
        <!-- E 头部header -->
        <!-- E 列表内容 -->
        <div class="list-wrap" v-for="item in list_data">
            <div class="list-item detail-w">
                <!-- S 一级内容 -->
                <div class="list-first">
                    <div class="parent-left">{{item.title}}</div>
                    <div class="parent-right">
                        <div class="add-btn" @click="addSecond(item)">添加</div>
                        <div class="edit-btn" @click="edit(item)">修改</div>
                        <div class="del-btn" @click="del(item)">删除</div>
                    </div>
                </div>
                <!-- E 一级内容 -->
                <!-- S 二级内容 -->
                <div class="list-second" v-for="children in item.children" :key="children.id">
                    <div class="parent-left">{{children.title}}</div>
                    <div class="child-right">
                        <div class="edit-btn"  @click="edit(children)">修改</div>
                        <div class="del-btn" @click="del(children)">删除</div>
                    </div>
                </div>
                <!-- E 二级内容 -->
            </div>
        </div>
        <!-- S 列表内容 -->
        <div class="empty-data" v-if="empty_data">暂无数据</div>
        <!-- S 底部站位盒子 -->
        <div style='height: 61px;'></div>
        <!-- E 底部站位盒子 -->
    </div>
    <!-- S 底部导航栏 -->
    <div class="occupy-box"></div>
    <!-- S 底部导航栏  底部四个的 权限对应0 1 2  -->
    <div class="occupy-box"></div>
    <div v-if="fourStates">
        <van-tabbar v-model="active">
            <van-tabbar-item replace to="/index">
                <span>首页</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <home-filled/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/customManage">
                <span>客户管理</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <user/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/signedResources">
                <span>已签约</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <edit/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/sharedPool">
                <span>共享池</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <monitor/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
    <!-- S 底部导航栏  底部四个的 权限对应0 1 2-->

    <!-- S 底部导航栏  底部三个的 权限对应 3 4  -->
    <div v-if="threeStates">
        <van-tabbar v-model="active">
            <van-tabbar-item replace to="/index">
                <span>首页</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <home-filled/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/approve">
                <span>交接审批</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <Coordinate/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/customSchedule">
                <span>客户列表</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <PieChart/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
    <!-- S 底部导航栏  底部三个的 权限对应 3 4-->

    <!-- S 底部导航栏  底部两个的 权限对应 5 6  -->

    <div v-if="twoStates">
        <van-tabbar v-model="active">
            <van-tabbar-item replace to="/index">
                <span>首页</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <home-filled/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/customSchedule">
                <span>客户列表</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <PieChart/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
    <!-- S 底部导航栏  底部两个的 权限对应 5 6-->
</template>

<script>
    import {optionDel, optionData} from '../api'
    import {Notify, Dialog} from 'vant';
    import moment from "moment";
    import {ref} from "vue";

    export default {
        data() {
            return {
                /* ---- 列表数据 ---- */
                // list_data: [],
                list_data: [
                    //预留数据结构 暂时不删除
                    // {
                    //     "id": 192,
                    //     "title": "申-测试一级节点1",
                    //     "parent": 0,
                    //     "children": [
                    //         {
                    //             "id": 193,
                    //             "title": "申-测试二级节点1-1",
                    //             "parent": 192
                    //         },
                    //         {
                    //             "id": 194,
                    //             "title": "申-测试二级节点2-1",
                    //             "parent": 192
                    //         }
                    //     ]
                    // },
                    // {
                    //     "id": 192,
                    //     "title": "申-测试一级节点2",
                    //     "parent": 0,
                    //     "children": [
                    //         {
                    //             "id": 193,
                    //             "title": "申-测试二级节点2-1",
                    //             "parent": 192
                    //         },
                    //         {
                    //             "id": 194,
                    //             "title": "申-测试二级节点2-2",
                    //             "parent": 192
                    //         }
                    //     ]
                    // }
                ],
                empty_data: false, // 暂无数据
                postId: "", // 权限
                fourStates: true, // 底部栏 四个 权限0 1 2
                threeStates: false, // 底部栏 三个 权限 3 4
                twoStates: true, // 底部栏 两个 权限5 6
                projectId: '', //项目ID
            }
        },

        // 生命周期 初始化
        setup() {
            const active = ref(''); // 设定选定第几个
            return {
                active,
            };
        },

        created() {
            window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
            var pageData = JSON.parse(decodeURIComponent(this.$route.query.data))
            if (!pageData.id) {
                this.empty_data = true
            } else {
                this.projectId = pageData.id
                this.getList(this.projectId) // 列表数据
            }
            //修改（底部导航栏）
            this.postId = sessionStorage.getItem("postID")
            if (this.postId === '6' || this.postId === '5') { // 判断 文案 或者 运营经理
                this.fourStates = false // 不显示死个底部
                this.threeStates = false // 不显示两个个底部
                this.twoStates = true// 不显示三个底部
                return
            }
            if (this.postId === '3' || this.postId === '4') { // 判断 运营主管 或者 文案主管
                this.fourStates = false // 不显示死个底部
                this.threeStates = true // 显示两个个底部
                this.twoStates = false// 不显示三个底部
                return
            }
            if (this.postId === '0') { // 判断 管理员
                this.fourStates = true // 不显示两个个底部
                this.threeStates = false // 显示两个个底部
                this.twoStates = false// 不显示三个底部
                return
            }
        },

        // 获取屏幕默认高度与实时高度
        mounted: function () {
            //获取默认高度
            this.defaultHeight = document.documentElement.clientHeight
            window.onresize = () => {
                return (() => {
                    //实时屏幕高度
                    this.nowHeight = document.documentElement.clientHeight
                })();
            };
        },


        methods: {
            // 时间戳转化(年-月-日 时:分:秒)
            formatDate(time) {
                return moment(time * 1000).format("YYYY-MM-DD hh:mm:ss")
            },
            // 列表数据
            getList(id) {
                var projectId = id
                let para = {
                    type: 2,
                    project_name_id: projectId,
                }
                optionData(para).then(data => {
                    if (data.code === 200) {
                        // 暂无数据
                        if (data.data.length === 0) {
                            this.empty_data = true
                        } else {
                            this.empty_data = false
                            this.list_data = data.data
                        }
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            // 修改跳转
            edit(item) {
                item.projectId =this.projectId
                this.$router.push({
                    path: '/progressEdit',
                    query: {
                        data: encodeURIComponent(JSON.stringify(item)), // vue路由传递对象
                    }
                });
            },
            // 新增跳转
            add() {
                this.$router.push({
                    path: '/progressAdd',
                    query: {
                        id: this.projectId, // 客户id

                    }
                });
            },
            // 二级新增跳转
            addSecond(item) {
                this.$router.push({
                    path: '/progressAdd',
                    query: {
                        id: this.projectId, // 客户id
                        addId: item.id
                    }
                });
            },
            // 删除
            del(item) {
                Dialog.confirm({
                    message:
                        '此操作将永久删除该数据, 是否继续?',
                })
                .then(() => {
                    let para = {
                        id: item.id,
                    };
                    optionDel(para).then(data => {
                        if (data.code === 200) {
                            Notify({type: 'success', message: '删除成功'});
                            this.getList(this.projectId) // 列表数据
                        } else {
                            Notify({type: 'danger', message: data.message});
                        }
                    }).catch(() => {
                        Notify({type: 'danger', message: '服务连接失败'});
                    })
                })
                .catch(() => {
                    // on cancel
                });
            }
        }
    }
</script>

<style scoped>

    .header-nav {
        position: relative;
        height: 53px;
        color: #222;
        font-size: 19px;
        text-align: center;
        line-height: 53px;
    }

    /* ---- 筛选部分 ---- */
    .detail-w {
        width: 345px;
        margin: 0 auto;
    }

    /* ---- 列表数据 ---- */
    .list-wrap {
        margin: 15px 0;
    }

    .list-item {

        background: #fff;
        border-radius: 10px;
        box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
    }

    .list-first {
        padding: 10px 0;
        display: flex;
        width: 94%;
        margin: 0px auto;
        color: #222;
        font-size: 15px;
        line-height: 30px;
        justify-content: space-between;
        /*align-items: center;*/
    }

    .list-second {
        padding-bottom: 10px;
        display: flex;
        width: 90%;
        margin: 0 auto 0 7%;
        color: #222;
        font-size: 15px;
        line-height: 30px;
        justify-content: space-between;
    }

    .parent-left {
        width: 60%;
    }

    .parent-right {
        width: 38%;
        display: flex;
        justify-content: space-between;
    }

    .child-right {
        width: 26%;
        display: flex;
        justify-content: space-between;
    }

    .add-btn {
        height: 30px;
        flex-shrink: 0;
        width: 39px;
        background-color: #67C23A;
        color: #fff;
        font-size: 12px;
        text-align: center;
        border-radius: 4px;
        line-height: 30px;
    }

    .edit-btn {
        height: 30px;
        flex-shrink: 0;
        width: 39px;
        background: #409EFF;
        color: #fff;
        font-size: 12px;
        text-align: center;
        border-radius: 4px;
        line-height: 30px;
    }

    .del-btn {
        height: 30px;
        flex-shrink: 0;
        width: 39px;
        background: #F56C6C;
        color: #fff;
        font-size: 12px;
        text-align: center;
        border-radius: 4px;
        line-height: 30px;
    }
</style>
