<template>
  <div>
    <!-- S 头部header -->
    <div class="header">
      <div class="header-page">
        <div class="page-info">
          <span>共</span>
          <span class="page-num">{{total}}</span>
          <span>条/</span>
          <span class="page-num">{{sum_page}}</span>
          <span>页，</span>
          <span>当前第</span>
          <span class="page-num">{{current_page}}</span>
          <span>页</span>
        </div>
        <div class="page-btn">
          <div class="page-input">
            <van-field class="page-jump" v-model="virtually_page" @keyup.enter.native="jumpPage()"
                       autocomplete="off"
                       type="digit"/>
          </div>
          <div class="jump-btn" @click="jumpPage()">跳转</div>
        </div>
      </div>
    </div>
    <!-- E 头部header virtually_page -->
    <!-- S 筛选条件部分 -->
    <div class="screen detail-w" :style="{height: screen_height}">
      <div class="search-term">
        <div class="search-term-label">客户名称</div>
        <div class="search-term-detail">
          <van-field class="vant-input-edit" v-model="screen_name" clearable autocomplete="off"
                     @focus="focusIpt"
                     @blur="blurIpt" placeholder="请输入客户名称..."/>
        </div>
      </div>
      <div class="search-term">
        <div class="search-term-label">联系方式</div>
        <div class="term-detail-contact">
          <div class="contact-down" @click="showPopup">
            <span class="default-text" v-if="select_show">请选择</span>
            <span class="default-text" v-else>{{contact_info}}</span>
            <div class="down-icon contact-icon" v-if="clear_contact_show">
              <caret-bottom/>
            </div>
            <div class="down-icon contact-icon" @click.stop="clearableContact" v-else>
              <van-icon name="clear"/>
            </div>
          </div>
          <div class="contact-detail">
            <van-field class="vant-input-edit" v-model="customer_contact" clearable autocomplete="off"
                       @focus="focusIpt"
                       @blur="blurIpt"
                       placeholder="请输入客户的联系方式..."/>
          </div>
        </div>
      </div>
      <!-- S 筛选展开部分 -->
      <div v-if="screen_more">
        <div class="search-term">
          <div class="search-term-label">客户序号</div>
          <div class="search-term-detail">
            <van-field class="vant-input-edit" type="digit" v-model="screen_index" clearable
                       autocomplete="off"
                       @focus="focusIpt"
                       @blur="blurIpt" placeholder="请输入客户序号..."/>
          </div>
        </div>
        <div class="search-term">
          <div class="search-term-label">客户等级</div>
          <div class="search-term-detail" @click="levelPopup">
            <van-field class="vant-input-edit" v-model="screen_level" readonly placeholder="请选择"/>
            <div class="down-icon" v-if="clear_level_show">
              <caret-bottom/>
            </div>
            <div class="down-icon" @click.stop="clearableLevel" v-else>
              <van-icon name="clear"/>
            </div>
          </div>
        </div>
        <div class="search-term">
          <div class="search-term-label">跟单顾问</div>
          <div class="search-term-detail" @click="adviserPopup">
            <van-field class="vant-input-edit" v-model="screen_follow_adviser" readonly placeholder="请选择"/>
            <div class="down-icon" v-if="clear_adviser_show">
              <caret-bottom/>
            </div>
            <div class="down-icon" @click.stop="clearableAdviser" v-else>
              <van-icon name="clear"/>
            </div>
          </div>
        </div>
        <div class="search-term">
          <div class="search-term-label">来源渠道</div>
          <div class="search-term-detail" @click="sourceShow">
            <van-field
                class="vant-input-edit"
                v-model="fieldValue"
                readonly
                placeholder="请选择"
            />
            <div class="down-icon" v-if="clear_source_show">
              <caret-bottom/>
            </div>
            <div class="down-icon" @click.stop="clearableSource" v-else>
              <van-icon name="clear"/>
            </div>
          </div>
        </div>
        <div class="search-term">
          <div class="search-term-label">客户地址</div>
          <div class="search-term-detail" @click="addressShow">
            <van-field
                class="vant-input-edit"
                v-model="address_info"
                readonly
                placeholder="请选择"
            />
            <div class="down-icon" v-if="clear_address_show">
              <caret-bottom/>
            </div>
            <div class="down-icon" @click.stop="clearableAddress" v-else>
              <van-icon name="clear"/>
            </div>
          </div>
        </div>
        <div class="search-term">
          <div class="search-term-label">资源类别</div>
          <div class="search-term-detail" @click="catPopup">
            <van-field class="vant-input-edit" v-model="cat_info" readonly placeholder="请选择"/>
            <div class="down-icon" v-if="clear_cat_show">
              <caret-bottom/>
            </div>
            <div class="down-icon" @click.stop="clearableCat" v-else>
              <van-icon name="clear"/>
            </div>
          </div>
        </div>
        <div class="search-term">
          <div class="search-term-label">录入时间</div>
          <div class="search-term-detail time-slot-box">
            <div class="time-slot" @click="inputStartTime">
              <van-field class="vant-input-edit input-edit-time" v-model="input_start_time" readonly
                         placeholder="选择开始时间"/>
            </div>
            <div class="time-slot-center">至</div>
            <div class="time-slot" @click="inputEndTime">
              <van-field class="vant-input-edit input-edit-time" v-model="input_end_time" readonly
                         placeholder="选择结束时间"/>
            </div>
            <div class="down-icon time-clear" v-if="enter_clear_time" @click="enterTimeClear">
              <van-icon name="clear"/>
            </div>
          </div>
        </div>
        <div class="search-term">
          <div class="search-term-label">回访时间</div>
          <div class="search-term-detail time-slot-box">
            <div class="time-slot" @click="visitStartTime">
              <van-field class="vant-input-edit input-edit-time" v-model="visit_start_time" readonly
                         placeholder="选择开始时间"/>
            </div>
            <div class="time-slot-center">至</div>
            <div class="time-slot" @click="visitEndTime">
              <van-field class="vant-input-edit input-edit-time" v-model="visit_end_time" readonly
                         placeholder="选择结束时间"/>
            </div>
            <div class="down-icon time-clear" v-if="return_clear_time" @click="returnTimeClear">
              <van-icon name="clear"/>
            </div>
          </div>
        </div>
        <!--                <div class="search-term">-->
        <!--                    <div class="search-term-label">标签</div>-->
        <!--                    <div class="search-term-detail" @click="labelPopup">-->
        <!--                        <van-field class="vant-input-edit" v-model="customer_label" readonly placeholder="请选择"/>-->
        <!--                        <div class="down-icon" v-if="clear_label_show">-->
        <!--                            <caret-bottom/>-->
        <!--                        </div>-->
        <!--                        <div class="down-icon" @click.stop="clearableLabel" v-else>-->
        <!--                            <van-icon name="clear"/>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <div class="search-term">
          <div class="search-term-label">无效原因</div>
          <div class="search-term-detail" @click="invalidPopup">
            <van-field class="vant-input-edit invalid-select" v-model="customer_invalid" readonly placeholder="请选择"/>
            <div class="down-icon" v-if="clear_invalid_show">
              <caret-bottom/>
            </div>
            <div class="down-icon" @click.stop="clearableInvalid" v-else>
              <van-icon name="clear"/>
            </div>
          </div>
        </div>

        <div class="search-term">
          <div class="search-term-label">运营客服</div>
          <div class="search-term-detail" @click="enterPopup">
            <van-field class="vant-input-edit" v-model="customer_enter" readonly placeholder="请选择"/>
            <div class="down-icon" v-if="clear_enter_show">
              <caret-bottom/>
            </div>
            <div class="down-icon" @click.stop="clearableEnter" v-else>
              <van-icon name="clear"/>
            </div>
          </div>
        </div>
      </div>
      <!-- E 筛选展开部分 -->
      <div class="screen-operation">
        <div class="search-btn" @click="search(true)">
          <img src="../assets/search.png" alt="">
          搜索
        </div>
        <div class="more-search" @click="moreSearch()">
          <img src="../assets/open_search.png" alt="">
          {{screen_text}}
        </div>
      </div>
    </div>
    <!-- E 筛选条件部分 -->

    <!-- S 联系方式弹层 -->
    <van-popup v-model:show="contact_show" position="bottom" :style="{ height: '48%' }">
      <van-picker
          title="请选择联系方式"
          show-toolbar
          :columns="contact_data"
          @confirm="contactConfirm"
          @cancel="contactCancel"
      />
    </van-popup>
    <!-- E 联系方式弹层 -->
    <!-- S 客户等级弹层 -->
    <van-popup v-model:show="level_show" position="bottom" :style="{ height: '48%' }">
      <van-picker
          title="请选择客户等级"
          show-toolbar
          :columns="level_data"
          @confirm="levelConfirm"
          @cancel="levelCancel"
      />
    </van-popup>
    <!-- E 客户等级弹层 -->
    <!-- S 跟单顾问弹层 -->
    <van-popup v-model:show="follow_adviser_show" position="bottom" :style="{ height: '48%' }">
      <van-picker
          title="请选择跟单顾问"
          show-toolbar
          :columns="follow_adviser_data"
          @confirm="adviserConfirm"
          @cancel="adviserCancel"
      />
    </van-popup>
    <!-- E 跟单顾问弹层 -->

    <!-- S 来源弹层 -->
    <van-popup v-model:show="source_show" position="bottom">
      <van-cascader
          v-if="source_visible"
          v-model="cascaderValue"
          title="请选择来源"
          :options="options"
          :field-names="sourceNames"
          @close="source_show = false"
          @finish="onFinish"
      />
    </van-popup>
    <!-- E 来源弹层 -->

    <!-- S 录入开始时间弹层 -->
    <van-popup v-model:show="input_start_show" position="bottom" :style="{ height: '48%' }">
      <van-datetime-picker
          v-model="input_start_date"
          type="date"
          title="选择开始时间"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="inputStartConfirm"
          @cancel="inputStartCancel"
      />
    </van-popup>
    <!-- E 录入开始时间弹层 -->

    <!-- S 录入结束时间弹层 -->
    <van-popup v-model:show="input_end_show" position="bottom" :style="{ height: '48%' }">
      <van-datetime-picker
          v-model="input_end_date"
          type="date"
          title="选择结束时间"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="inputEndConfirm"
          @cancel="inputEndCancel"
      />
    </van-popup>
    <!-- E 录入结束时间弹层 -->

    <!-- S 回访开始时间弹层 -->
    <van-popup v-model:show="visit_start_show" position="bottom" :style="{ height: '48%' }">
      <van-datetime-picker
          v-model="visit_start_date"
          type="date"
          title="选择开始时间"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="visitStartConfirm"
          @cancel="visitStartCancel"
      />
    </van-popup>
    <!-- E 回访开始时间弹层 -->

    <!-- S 回访结束时间弹层 -->
    <van-popup v-model:show="visit_end_show" position="bottom" :style="{ height: '48%' }">
      <van-datetime-picker
          v-model="visit_end_date"
          type="date"
          title="选择结束时间"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="visitEndConfirm"
          @cancel="visitEndCancel"
      />
    </van-popup>
    <!-- E 回访结束时间弹层 -->

    <!-- S 标签弹层 -->
    <van-popup v-model:show="label_show" position="bottom" :style="{ height: '48%' }">
      <van-picker
          title="请选择标签"
          show-toolbar
          :columns="label_data"
          @confirm="tagConfirm"
          @cancel="tagCancel"
      />
    </van-popup>
    <!-- E 标签弹层 -->

    <!-- S 无效原因弹层 -->
    <van-popup v-model:show="invalid_show" position="bottom" :style="{ height: '48%' }">
      <van-picker
          title="请选择无效原因"
          show-toolbar
          :columns="invalid_data"
          @confirm="invalidConfirm"
          @cancel="invalidCancel"
      />
    </van-popup>
    <!-- E 无效原因弹层 -->
    <!-- S 录入弹层 -->
    <van-popup v-model:show="enter_show" position="bottom" :style="{ height: '48%' }">
      <van-picker
          title="请选择运营客服"
          show-toolbar
          :columns="enter_data"
          @confirm="enterConfirm"
          @cancel="enterCancel"
      />
    </van-popup>
    <!-- E 无效原因弹层 -->

    <!-- S 客户地址Popup -->
    <van-popup v-model:show="address_show" position="bottom">
      <van-cascader
          v-if="address_cascader_visibel"
          v-model="address_value"
          title="请选择客户地址"
          :options="address_data"
          :field-names="addressNames"
          @close="address_show = false"
          @finish="addressFinish"
      />
    </van-popup>
    <!-- E 客户地址Popup -->

    <!-- S 资源类别弹层 -->
    <van-popup v-model:show="cat_type_show" position="bottom" :style="{ height: '48%' }">
      <van-picker
          title="请选择资源类别"
          show-toolbar
          :columns="cat_type_data"
          @confirm="catConfirm"
          @cancel="catCancel"
      />
    </van-popup>
    <!-- E 资源类别弹层 -->

    <van-list
        v-model:loading="loading"
        :finished="finished"
        :finished-text="no_more"
        :offset="0"
        @load="onLoad"
    >
      <!-- S 列表部分 -->
      <div class="list-wrap" v-for="item in list_data">
        <div class="list-item detail-w">
          <div class="list-top">
            <div class="list-top-num">
              <span class="list-label">序号</span>
              <span>{{item.id}}</span>
            </div>
            <!--                        <div class="list-top-num">-->
            <!--                            <span>{{formatDate(item.create_time)}}</span>-->
            <!--                        </div>-->
          </div>
          <div class="list-info">
            <div class="info-label">客户名称</div>
            <div>{{item.user_name}}</div>
          </div>
          <!--          <div class="list-info">-->
          <!--            <div class="info-label">手机号</div>-->
          <!--            <div v-html="item.user_phone"></div>-->
          <!--            <div v-if="item.contact_information_first === 0" class="priority-box">优先</div>-->
          <!--          </div>-->
          <!--          <div class="list-info">-->
          <!--            <div class="info-label">微信</div>-->
          <!--            <div v-html="item.user_chat"></div>-->
          <!--            <div v-if="item.contact_information_first === 1" class="priority-box">优先</div>-->
          <!--          </div>-->
          <div class="list-info">
            <div class="info-label">意向国家</div>
            <div v-if="item.intentional_state!==''">{{item.intentional_state}}</div>
            <div v-else>-</div>
          </div>
          <div class="list-info">
            <div class="info-label">客户等级</div>
            <div>{{customerGrade(item.customer_grade)}}</div>
          </div>
          <div class="list-info" v-if="adviser_source_show">
            <div class="info-label">来源渠道</div>
              <div v-if="item.source_name!==null">{{item.source_name}}</div>
              <div v-if="!item.source_name">-</div>
          </div>
          <div class="list-info" v-if="adviser_source_show">
            <div class="info-label">跟单顾问</div>
            <div>{{item.follow_counsel_name}}</div>
          </div>
          <div class="list-info">
            <div class="info-label">运营客服</div>
            <div v-if="item.official_service_name!==null">{{item.official_service_name}}</div>
            <div v-else>-</div>
          </div>
          <div class="list-info">
            <div class="info-label">录入时间</div>
            <div v-if="item.create_time!==null">{{formatDate(item.create_time)}}</div>
            <div v-else>-</div>
          </div>
          <div class="list-info">
            <div class="info-label">回访时间</div>
            <div v-if="item.follow_status === 0">-</div>
            <div v-else>{{formatDate(item.follow_status)}}</div>
          </div>
          <div class="operate-info">
            <div class="both-sides" @click="toDetail(item.id)">详情</div>
            <div class="operate-center" @click="toFollowOrder(item.id)">跟单</div>
            <!--                        <div class="both-sides" @click="toJournal(item.id)">日志</div>-->
          </div>
        </div>
      </div>
      <div class="empty-data" v-if="empty_data">暂无数据</div>
      <!-- E 列表部分 -->
    </van-list>
    <!-- S 底部站位盒子 -->
    <div class="occupy-box"></div>
    <!-- E 底部站位盒子 -->
    <!-- S 底部导航栏 -->
    <!-- S 判断导航剩余一个时-->
    <div v-if="navNum ==1" class="common-nav">
      <div class="nav-index-one" v-for="(item, i) in navList">
        <div @click="add(item)">
          <div class="nav-bottom-line"></div>
          <div class="nav-right-line"></div>
          <div class="nav-icon">
            <el-icon>
              <component :is="item.ico_m"/>
            </el-icon>
          </div>
          <div class="nav-title">{{item.name}}</div>
        </div>
      </div>
    </div>
    <!-- S 判断导航剩余一个时-->
    <!-- S 判断导航剩余两个时-->
    <div v-else-if="navNum ==2" class="common-nav">
      <div class="nav-index-two" v-for="(item, i) in navList">
        <div @click="add(item)">
          <div class="nav-bottom-line"></div>
          <div class="nav-right-line"></div>
          <div class="nav-icon">
            <el-icon>
              <component :is="item.ico_m"/>
            </el-icon>
          </div>
          <div class="nav-title">{{item.name}}</div>
        </div>
      </div>
    </div>
    <!-- E 判断导航剩余两个时-->
    <!-- S 判断导航剩余零个时-->
    <div v-else class="common-nav">
      <div class="nav-index" v-for="(item, i) in navList">
        <div @click="add(item)">
          <div class="nav-bottom-line"></div>
          <div class="nav-right-line"></div>
          <div class="nav-icon">
            <el-icon>
              <component :is="item.ico_m"/>
            </el-icon>
          </div>
          <div class="nav-title">{{item.name}}</div>
        </div>
      </div>
    </div>
    <!-- E 判断导航剩余零个时-->
    <!-- E 功能模块-->
    <!-- S 底部导航栏 -->
    <div class="occupy-box"></div>
    <div v-if="fourStates">
      <van-tabbar v-model="active" v-if="bottom_nav_visible">
        <van-tabbar-item replace to="/index">
          <span>首页</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <home-filled/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/customManage">
          <span>客户管理</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <user/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <!--                <van-tabbar-item replace to="/signedResources">-->
        <!--                    <span>已签约</span>-->
        <!--                    <template #icon="props">-->
        <!--                        <div class="navIcon" v-bind:class="{ active: props.active}">-->
        <!--                            <el-icon>-->
        <!--                                <edit/>-->
        <!--                            </el-icon>-->
        <!--                        </div>-->
        <!--                    </template>-->
        <!--                </van-tabbar-item>-->
        <!--                <van-tabbar-item replace to="/sharedPool">-->
        <!--                    <span>共享池</span>-->
        <!--                    <template #icon="props">-->
        <!--                        <div class="navIcon" v-bind:class="{ active: props.active}">-->
        <!--                            <el-icon>-->
        <!--                                <monitor/>-->
        <!--                            </el-icon>-->
        <!--                        </div>-->
        <!--                    </template>-->
        <!--                </van-tabbar-item>-->
      </van-tabbar>
    </div>
    <!-- S 四个底部导航栏 -->
  </div>
</template>
<script>
  import {
      // getCustomerAdmin
  } from '../api'

  import {
      getAdviserList,
      userSourceList,
      areaDown,
      categoryList,
      downTag,
      enterDown,
      invalidReasonData,
      getCustomerAdmin
  } from '../api/java.js'


  import {Notify} from 'vant';
  import {ref} from "vue";
  import moment from "moment";
  import TabbarHtml from '../components/tabbar'

  export default {
    components: {
      TabbarHtml
    },
    data() {
      return {
        adviser_source_show: true, // 来源顾问不可见

        /* ---- 监听软键盘是否弹出 ---- */
        defaultHeight: '0',  //默认屏幕高度
        nowHeight: '0',  //实时屏幕高度
        bottom_nav_visible: true, // 底部导航显示与隐藏

        /* ---- 触底加载 ---- */
        loading: false,
        finished: false,
        searchStates: false,  // 不走 onload

        /* ---- 重置下拉部分 ---- */
        clear_contact_show: true, // 联系方式重置按钮
        clear_level_show: true, // 等级重置按钮
        clear_adviser_show: true, // 跟单顾问重置按钮
        clear_source_show: true, // 来源重置按钮
        enter_clear_time: false, // 录入时间重置按钮
        return_clear_time: false, // 回访时间重置按钮
        clear_label_show: true, // 标签重置按钮
        clear_invalid_show: true, // 无效原因重置按钮
        clear_address_show: true, // 客户地址重置按钮
        clear_cat_show: true, // 资源类别重置按钮
        clear_enter_show: true,//
        page_num: '0', // 去第几页
        virtually_page: '', // 虚假页码
        current_page: '1', // 当前第几页
        total: '0', // 总条数
        sum_page: '0', // 总页数
        screen_height: '', // 展开搜索面板高度
        screen_text: '更多筛选条件', // 更多筛选条件文字切换
        screen_switch: false, // 更多筛选条件文字切换开关
        screen_more: false,
        screen_name: '', // 客户名称
        screen_index: '', // 客户序号
        input_start_show: false, // 录入时间开始弹层
        input_end_show: false, // 录入时间结束弹层
        input_start_time: '', // 录入开始时间
        input_end_time: '', // 录入结束时间
        visit_start_show: false, // 回访开始时间弹层
        visit_end_show: false, // 回访结束时间弹层
        visit_start_time: '', // 回访开始时间
        visit_end_time: '', // 回访结束时间
        list_data: [], //列表数据


        /* ---- 联系方式选择部分 ---- */
        contact_show: false, // 联系方式弹出层
        select_show: true, // 联系方式选择默认显示
        contact_info: '', // 已选择的联系方式
        contact_info_type: '', // 已选择的联系方式id
        customer_contact: '', // 客户联系方式
        contact_data: ['手机', '微信', '邮箱', 'QQ'],
        get_contact_data: [
          {id: '0', title: '手机'},
          {id: '1', title: '微信'},
          {id: '2', title: '邮箱'},
          {id: '3', title: 'QQ'},
        ],

        /* ---- 客户等级部分 ---- */
        level_show: false, // 客户等级弹出层
        screen_level: '', // 等级
        screen_level_id: '', // 等级id
        level_data: ['A', 'B', 'B+', 'C', 'C+', 'D', 'D+', 'E', 'E+', '未分级', '无效'],
        // 真实数据
        get_level_data: [
          {id: 1, name: 'A'},
          {id: 2, name: 'B'},
          {id: 8, name: 'B+'},
          {id: 3, name: 'C'},
          {id: 9, name: 'C+'},
          {id: 4, name: 'D'},
          {id: 10, name: 'D+'},
          {id: 5, name: 'E'},
          {id: 11, name: 'E+'},
          {id: 6, name: '无效'},
          {id: 7, name: '未分级'},
        ],

        /* ---- 跟单顾问部分 ---- */
        follow_adviser_show: false, // 跟单顾问弹出层
        screen_follow_adviser: '', // 跟单顾问
        follow_adviser_id: '', // 跟单顾问id
        follow_adviser_data: [], // vant下拉数据格式(顾问列表)
        request_adviser_data: [], // 接口返回数据格式(顾问列表)

        /* ---- 来源部分 ---- */
        source_visible: false, // 来源级联选择部分控制
        source_show: false, // 来源popup弹窗控制
        source: '', // 接口传参所用数据
        fieldValue: '',
        cascaderValue: '',
        // 来源自定义字段
        sourceNames: {
          text: 'title',
          value: 'id',
          children: 'children'
        },
        options: [], // 来源数据

        /* ---- 所咋地部分 ---- */
        address_cascader_visibel: false, // 级联选择器控制
        address_show: false, // 来源popup弹窗控制
        address_request: '', // 地区(作为参数请求时传递)
        address_info: '', // 地区回显
        address_value: '', // 地区id
        // 地区自定义字段
        addressNames: {
          text: 'name',
          value: 'id',
          children: 'children'
        },
        address_data: [], // 地区数据集合

        /* ---- 时间组件 ---- */
        minDate: new Date(1970, 0, 1),
        maxDate: new Date(2122, 0, 1),
        input_start_date: new Date(), // 录入开始当前时间
        input_end_date: new Date(), // 录入结束当前时间
        visit_start_date: new Date(), // 回访开始当前时间
        visit_end_date: new Date(), // 回访结束当前时间
        formatter(type, val) {
          if (type === 'year') {
            return `${val}年`;
          } else if (type === 'month') {
            return `${val}月`;
          } else {
            return `${val}日`;
          }
          return val;
        },

        /* ---- 标签部分 ---- */
        label_show: false, // 客户等级弹出层
        customer_label: '', // 标签
        label_id: '', // 标签id
        label_data: [], // vant标签下拉数据结构
        request_label_data: [], // 接口返回标签数据

        /* ---- 无效原因部分 ---- */
        invalid_show: false, // 无效原因弹出层
        customer_invalid: '', // 无效原因
        invalid_id: '', // 无效原因id
        invalid_data: [], // vant标无效原因签下拉数据结构
        request_invalid_data: [], // 接口返回标无效原因签数据


        /* ---- 录入部分 ---- */
        enter_show: false, // 录入弹出层
        customer_enter: '', //录入
        enter_id: '', // 录入id
        enter_data: [], // vant标录入签下拉数据结构
        request_enter_data: [], // 接口返回标录入签数据

        /* ---- 资源类别部分 ---- */
        cat_type_show: false, // 跟单顾问弹出层 catPopup
        cat_info: '', // 资源类别
        cat_type_id: '', // 资源类别id
        cat_type_data: [], // vant下拉数据格式(资源类别)
        request_cat_data: [], // 接口返回数据格式(资源类别)

        empty_data: false, // 暂无数据
        no_more: '没有更多了',
        iosStates: false, // 手机系统

        threeStates: true,//三底部栏
        twoStates: false,//三底部栏
        fourStates: false,//四底部栏
        postId: "", // 权限
      }
    },
    // 生命周期 初始化
    setup() {
      const active = ref(1); // 设定选定第几个
      return {
        active,
      };
    },
    created() {
      this.fourStates = true// 不显示四个底部
      // 顾问来演不可见
      if (sessionStorage.getItem('postID') === '2' || sessionStorage.getItem('postID') === '7') {
        this.adviser_source_show = false
      }
      this.getAdviserData() // 顾问列表数据
      this.getSource() // 来源数据
      this.getAddressData() // 地区数据
      this.getCatData() // 资源类别数据
      // this.getTag() // 标签数据
      this.invalidTag() // 无效原因数据
      this.getEnter() // 获取运营客服
      this.searchStates = true
      this.getListData() // 列表数据
      this.iosStates = sessionStorage.getItem("iosStates")
    },

    // 获取屏幕默认高度与实时高度
    mounted: function () {
      //获取默认高度
      this.defaultHeight = document.documentElement.clientHeight
      window.onresize = () => {
        return (() => {
          //实时屏幕高度
          this.nowHeight = document.documentElement.clientHeight
        })();
      };
    },

    // 监听软键盘是否弹出
    watch: {
      nowHeight: function () {
        if (this.defaultHeight !== this.nowHeight) {
          this.bottom_nav_visible = false
        } else {
          this.bottom_nav_visible = true
        }
      }
    },

    methods: {
      // 列表数据
      onLoad() {
        if (this.searchStates === false) {
          if (!this.page_num) {
            this.page_num = 1
          }
            let para = {
                pageNum: parseInt(this.page_num) + 1, // 页码
                pageSize: '10', // 每页条数
                name: this.screen_name, // 客户姓名
                grade: this.screen_level_id, // 等级
                follow_counsel: this.follow_adviser_id, // 跟单顾问
                source: this.source, // 来源
                province: this.address_value, // 所在地
                type: this.contact_info_type, // 联系方式类型
                info: this.customer_contact, // 联系方式内容
                start_time: this.input_start_time, // 录入时间(开始)
                end_time: this.input_end_time, //  录入时间(结束)
                visit_start_time: this.visit_start_time, // 回访时间(开始)
                visit_end_time: this.visit_end_time, // 回访时间(结束)
                id: this.screen_index, // 客户序号
                tag: this.label_id, // 标签
                cat_id: this.cat_type_id, // 类别
                // group_leader: '0', // 组长身份(必填)
            }
          // let para = {
          //   page: parseInt(this.page_num) + 1, // 页码
          //   limit: '10', // 每页条数
          //   name: this.screen_name, // 客户姓名
          //   grade: this.screen_level_id, // 等级
          //   follow_counsel: this.follow_adviser_id, // 跟单顾问
          //   source: this.source, // 来源
          //   province: this.address_value, // 所在地
          //   type: this.contact_info_type, // 联系方式类型
          //   info: this.customer_contact, // 联系方式内容
          //   start_time: this.input_start_time, // 录入时间(开始)
          //   end_time: this.input_end_time, //  录入时间(结束)
          //   visit_start_time: this.visit_start_time, // 回访时间(开始)
          //   visit_end_time: this.visit_end_time, // 回访时间(结束)
          //   id: this.screen_index, // 客户序号
          //   tag: this.label_id, // 标签
          //   cat_id: this.cat_type_id, // 类别
          //   // group_leader: '0', // 组长身份(必填)
          // }

          if (!para.type && para.info) {
            Notify({type: 'danger', message: '请选择联系方式类型'});
            return false
          }
          if (para.type && !para.info) {
            Notify({type: 'danger', message: '请选填写联系方式内容'});
            return false
          }
          getCustomerAdmin(para).then(data => {
            // 暂无数据
            if (data.data.count === 0) {
              this.empty_data = true
              this.no_more = ''
            } else {
              this.empty_data = false
              this.no_more = '没有更多了'
            }
            if (data.code === 200) {
              this.total = data.data.count
              if (parseInt(data.data.count % 10) === 0) {
                this.sum_page = parseInt(data.data.count / 10)
              } else {
                this.sum_page = parseInt(data.data.count / 10) + 1
              }
              this.loading = false;
              // 数据全部加载完成
              if (data.data.data.length === 0) {
                this.finished = true; // 停止加载
                return
              }
              this.page_num++
              this.current_page = para.page
              this.list_data = this.list_data.concat(data.data.data) // 数组拼接
            } else {
              Notify({type: 'danger', message: data.message});
            }
          })
        }
      },

      // 时间戳转化(年-月-日 时:分:秒)
      formatDate(time) {
        return moment(time * 1000).format("YYYY-MM-DD HH:mm:ss")
      },

      // 时间戳转化(年-月-日)
      formatDate2(time) {
        return moment(time).format("YYYY-MM-DD")
      },

      // 客户等级转化
      customerGrade(value) {
        let grade_arr = ['', 'A', 'B', 'C', 'D', 'E', '无效', '未分级']
        return grade_arr[value]
      },

      // 地区数据
      getAddressData() {
        areaDown().then(data => {
          if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403 || data.code === 401) {
            Notify({type: 'danger', message: data.message});
            sessionStorage.clear()
            this.$router.push({
              path: '/weChatLogin'
            })
            return
          }
          if (data.code === 200) {
            this.address_data = data.data
          } else {
            Notify({type: 'danger', message: data.message});
          }
        })
      },

      // 标签下拉数据
      getTag() {
        downTag().then(data => {
          // 判断token
          if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403 || data.code === 401) {
            Notify({type: 'danger', message: data.message});
            sessionStorage.clear()
            this.$router.push({
              path: '/weChatLogin'
            })
            return
          }
          if (data.code === 200) {
            this.request_label_data = data.data
            for (let i = 0; i < this.request_label_data.length; i++) {
              this.label_data.push(this.request_label_data[i].title)
            }
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },


      // 运营客服
      getEnter() {
        enterDown().then(data => {
          // 判断token
          if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403 || data.code === 401) {
            Notify({type: 'danger', message: data.message});
            sessionStorage.clear()
            this.$router.push({
              path: '/weChatLogin'
            })
            return
          }
          if (data.code === 200) {
            this.request_enter_data = data.data
            for (let i = 0; i < this.request_enter_data.length; i++) {
              this.enter_data.push(this.request_enter_data[i].user_name)
            }
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 无效原因下拉数据
      invalidTag() {
        invalidReasonData().then(data => {
          // 判断token
          if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403 || data.code === 401) {
            Notify({type: 'danger', message: data.message});
            sessionStorage.clear()
            this.$router.push({
              path: '/weChatLogin'
            })
            return
          }

          if (data.code === 200) {
            this.request_invalid_data = data.data
            for (let i = 0; i < this.request_invalid_data.length; i++) {
              this.invalid_data.push(this.request_invalid_data[i].title)
            }
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 列表数据
      getListData(val) {
        this.finished = false; // 停止加载
        this.searchStates = true
        this.loading = true
        let para = {
          page: parseInt(this.page_num) + 1, // 页码
          limit: 10, // 每页多少条
          name: this.screen_name, // 客户姓名
          grade: this.screen_level_id, // 等级
          follow_counsel: this.follow_adviser_id, // 跟单顾问
          source: this.source, // 来源
          province: this.address_value, // 客户地址
          type: this.contact_info_type, // 联系方式类型
          info: encodeURIComponent(this.customer_contact), // 联系方式内容
          start_time: this.input_start_time, // 录入时间(开始)
          end_time: this.input_end_time, //  录入时间(结束)
          visit_start_time: this.visit_start_time, // 回访时间(开始)
          visit_end_time: this.visit_end_time, // 回访时间(结束)
          id: this.screen_index, // 客户序号
          tag: this.label_id, // 标签
          invalid_reason: this.invalid_id, // 无效原因
          cat_id: this.cat_type_id, // 资源类别
          official_service: this.enter_id,// 运营客服
          // group_leader: '', // 组长身份(必填)
        }
        if (para.start_time && !para.end_time) {
          Notify({type: 'danger', message: '请选择录入结束时间'})
          return
        }
        if (para.end_time && !para.start_time) {
          Notify({type: 'danger', message: '请选择录入开始时间'})
          return
        }
        if (para.visit_start_time && !para.visit_end_time) {
          Notify({type: 'danger', message: '请选择回访结束时间'})
          return
        }
        if (para.visit_end_time && !para.visit_start_time) {
          Notify({type: 'danger', message: '请选择回访开始时间'})
          return
        }
        getCustomerAdmin(para).then(data => {
          if (val) {
            this.list_data = []
          }
          this.loading = true;
          this.finished = false; // 停止加载
          this.searchStates = true
          if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403 || data.code === 401) {
            Notify({type: 'danger', message: data.message});
            sessionStorage.clear()
            this.$router.push({
              path: '/weChatLogin'
            })
            return
          }
          if (data.code === 200) {
            if (val) {
              Notify({type: 'success', message: '搜索成功', duration: 1000});
            }
            if (data.data.count === 0) {
              this.current_page = '1'
              this.empty_data = true
              this.no_more = ''

            } else {
              this.empty_data = false
              this.no_more = '没有更多了'
            }
            this.total = data.data.count
            if (parseInt(data.data.count % 10) === 0) {
              this.sum_page = parseInt(data.data.count / 10)

            } else {
              this.sum_page = parseInt(data.data.count / 10) + 1
            }
            this.loading = false;
            this.searchStates = false
            // 数据全部加载完成
            if (data.data.data.length === 0) {
              this.finished = true; // 停止加载
              return
            }
            this.page_num++
            this.current_page = para.page
            this.list_data = this.list_data.concat(data.data.data) // 数组拼接
          } else {
            this.empty_data = false
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          this.empty_data = false
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 顾问列表数据
      getAdviserData() {
        getAdviserList().then(data => {
          // 判断token
          if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403 || data.code === 401) {
            Notify({type: 'danger', message: data.message});
            sessionStorage.clear()
            this.$router.push({
              path: '/weChatLogin'
            })
            return
          }
          if (data.code === 200) {
            this.request_adviser_data = data.data
            for (let i = 0; i < this.request_adviser_data.length; i++) {
              this.follow_adviser_data.push(this.request_adviser_data[i].user_name)
            }
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 资源类别列表数据
      getCatData() {
        categoryList().then(data => {
          // 判断token
          if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403 || data.code === 401) {
            Notify({type: 'danger', message: data.message});
            sessionStorage.clear()
            this.$router.push({
              path: '/weChatLogin'
            })
            return
          }
          if (data.code === 200) {
            this.request_cat_data = data.data
            for (let i = 0; i < this.request_cat_data.length; i++) {
              this.cat_type_data.push(this.request_cat_data[i].title)
            }
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 来源数据
      getSource() {
        userSourceList().then(data => {
          if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403 || data.code === 401) {
            Notify({type: 'danger', message: data.message});
            sessionStorage.clear()
            this.$router.push({
              path: '/weChatLogin'
            })
            return
          }
          if (data.code === 200) {
            this.options = data.data
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 返回上一页
      goBackPage() {
        this.$router.push({
          path: '/index'
        })
      },

      // 跳转按钮
      jumpPage() {
        this.page_num = this.virtually_page
        // 点击搜索收起搜索栏
        if (this.screen_switch) {
          this.screen_switch = false
          this.screen_height = ''
          this.screen_text = '更多筛选条件'
          this.screen_more = false
        }
        this.searchStates = false
        if (!this.page_num) {
          Notify('请输入页码')
          return false
        }
        if (this.page_num > this.sum_page) {
          this.page_num = this.sum_page
        }
        if (this.page_num < 1) {
          this.page_num = '1'
        }
        this.page_num = this.page_num - 1
        this.list_data = []
        this.current_page = this.page_num
        this.getListData()
      },
      // 点击搜索
      search() {
        this.searchStates = false
        let search_tips = true // 点击搜索反馈信息
        this.current_page = '1'
        this.page_num = '0'
        this.virtually_page = this.current_page
        if (!this.contact_info_type && this.customer_contact) {
          Notify({type: 'danger', message: '请选择联系方式类型'});
          return false
        }
        if (this.contact_info_type && !this.customer_contact) {
          Notify({type: 'danger', message: '请选填写联系方式内容'});
          return false
        }
        this.loading = true
        this.getListData(search_tips)
      },

      // 更多筛选条件
      moreSearch() {
        this.screen_switch = !this.screen_switch
        if (this.screen_switch) {
          this.screen_height = 1100 + 'px'
          this.screen_text = '收起'
          this.screen_more = true
        } else {
          this.screen_height = ''
          this.screen_text = '更多筛选条件'
          this.screen_more = false
        }
      },

      // 跳转详情页
      toDetail(id) {
        // 跳转传id
        this.$router.push({
          path: '/customer_detail',
          query: {
            id: id,
            pages: this.page_num
          }
        })
      },

      // 跳转跟单页
      toFollowOrder(id) {
        this.$router.push({
          path: '/documentary',
          query: {
            id: id
          }
        })
      },

      // 跳转日志页
      toJournal(id) {
        this.$router.push({
          path: '/journal',
          query: {
            id: id
          }
        })
      },

      /**
       * 客户标签部分逻辑(搜索部分)
       * */
      // 标签弹层控制
      labelPopup() {
        this.label_show = true
      },

      // 确认赋值
      tagConfirm(value) {
        this.customer_label = value // 标签赋值
        for (let i = 0; i < this.request_label_data.length; i++) {
          if (value === this.request_label_data[i].title) {
            this.label_id = this.request_label_data[i].id
          }
        }
        this.label_show = false // 关闭弹层
        this.clear_label_show = false // 切换图标

      },

      // 取消选择
      tagCancel() {
        this.label_show = false // 关闭弹层
      },

      /**
       * 无效原因部分逻辑(搜索部分)
       * */
      // 标无效原因签弹层控制
      invalidPopup() {
        this.invalid_show = true
      },

      // 确认赋值
      invalidConfirm(value) {
        this.customer_invalid = value // 无效原因标签赋值
        for (let i = 0; i < this.request_invalid_data.length; i++) {
          if (value === this.request_invalid_data[i].title) {
            this.invalid_id = this.request_invalid_data[i].id
          }
        }
        this.invalid_show = false // 关闭弹层
        this.clear_invalid_show = false // 切换图标

      },

      // 取消选择
      invalidCancel() {
        this.invalid_show = false // 关闭弹层
      },

      /**
       * 运营客服部分逻辑(搜索部分)
       * */
      // 标无效原因签弹层控制
      enterPopup() {
        this.enter_show = true
      },

      // 确认赋值
      enterConfirm(value) {
        this.customer_enter = value // 无效原因标签赋值
        for (let i = 0; i < this.request_enter_data.length; i++) {
          if (value == this.request_enter_data[i].user_name) {
            this.enter_id = this.request_enter_data[i].id
          }
        }
        this.enter_show = false // 关闭弹层
        this.clear_enter_show = false // 切换图标

      },

      // 取消选择
      enterCancel() {
        this.enter_show = false // 关闭弹层
      },

      /**
       * 联系方式弹窗部分逻辑(搜索部分)
       * */
      // 联系方式弹窗控制
      showPopup() {
        this.contact_show = true;
      },

      // 确认赋值
      contactConfirm(value) {
        this.contact_info = value // 联系方式赋值
        this.select_show = false // 关闭默认文字
        // 获取下拉数据id(联系方式类型)
        for (let i = 0; i < this.get_contact_data.length; i++) {
          if (value === this.get_contact_data[i].title) {
            this.contact_info_type = this.get_contact_data[i].id
          }
        }
        this.contact_show = false // 关闭弹层
        this.clear_contact_show = false // 切换重置图标
      },

      // 取消选择
      contactCancel() {
        this.contact_show = false // 关闭弹层
      },

      /**
       * 客户等级部分逻辑(搜索部分)
       * */
      // 等级弹层控制
      levelPopup() {
        this.level_show = true
      },

      // 确认赋值
      levelConfirm(value) {
        this.screen_level = value // 等级赋值
        // 获取下拉数据id (客户等级)
        for (let i = 0; i < this.get_level_data.length; i++) {
          if (value === this.get_level_data[i].name) {
            this.screen_level_id = this.get_level_data[i].id
          }
        }
        this.level_show = false // 关闭弹层
        this.clear_level_show = false // 切换重置图标
      },

      // 取消选择
      levelCancel() {
        this.level_show = false // 关闭弹层
      },

      /**
       * 跟单顾问(搜索部分)
       * */
      // 跟单顾问弹层控制
      adviserPopup() {
        this.follow_adviser_show = true
      },

      // 确认赋值
      adviserConfirm(value) {
        this.screen_follow_adviser = value // 顾问赋值
        // 获取下拉数据id (跟单顾问)
        for (let i = 0; i < this.request_adviser_data.length; i++) {
          if (value === this.request_adviser_data[i].user_name) {
            this.follow_adviser_id = this.request_adviser_data[i].id
          }
        }
        this.follow_adviser_show = false // 关闭弹层
        this.clear_adviser_show = false // 切换图标
      },

      // 取消选择
      adviserCancel() {
        this.follow_adviser_show = false // 关闭弹层
      },

      /**
       * 客户地址级联选择器部分
       * */
      addressShow() {
        this.address_show = true // 客户地址popup控制
        this.address_cascader_visibel = true // 客户地址级联选择器控制
      },

      // 客户地址选择完毕 finish 事件
      addressFinish({selectedOptions}) {
        this.address_show = false;
        this.address_info = selectedOptions.map((option) => option.name).join('/');
        let address_arr = []
        for (let i = 0; i < selectedOptions.length; i++) {
          address_arr.push(selectedOptions[i].id)
        }
        this.address_value = address_arr // 客户地址赋值(请求数据时候用)
        this.clear_address_show = false // 切换重置图标
      },

      /**
       * 资源类别(搜索部分)
       * */
      // 资源类别弹层控制
      catPopup() {
        this.cat_type_show = true
      },

      // 确认赋值
      catConfirm(value) {
        this.cat_info = value // 顾问赋值
        // 获取下拉数据id (资源类别)
        for (let i = 0; i < this.request_cat_data.length; i++) {
          if (value === this.request_cat_data[i].title) {
            this.cat_type_id = this.request_cat_data[i].id
          }
        }
        this.cat_type_show = false // 关闭弹层
        this.clear_cat_show = false // 切换图标
      },

      // 取消选择
      catCancel() {
        this.cat_type_show = false // 关闭弹层
      },

      /**
       * 来源部分逻辑(搜索)
       * */
      sourceShow() {
        this.source_show = true; // 来源popup弹窗控制
        this.source_visible = true // 来源级联选择部分控制
      },

      // 全部选项选择完毕后，会触发 finish 事件
      onFinish({selectedOptions}) {
        this.source_show = false;
        this.fieldValue = selectedOptions.map((option) => option.title).join('/');
        let source_arr = []
        for (let i = 0; i < selectedOptions.length; i++) {
          source_arr.push(selectedOptions[i].id)
        }
        this.source = source_arr.toString() // 来源赋值(请求数据时候用)
        this.clear_source_show = false // 切换重置图标
      },

      /**
       * 录入开始时间部分
       * */
      inputStartTime() {
        this.input_start_show = true
      },

      // 确认事件
      inputStartConfirm(val) {
        this.input_start_time = this.formatDate2(val)
        let enter_star_time = new Date(this.input_start_time)
        let enter_end_time = new Date(this.input_end_time)
        if (enter_end_time.getTime() <= enter_star_time.getTime()) {
          Notify({type: 'danger', message: '开始时间不能大于或等于结束时间'});
          this.input_start_time = ''
          return
        }
        this.input_start_show = false
        this.enter_clear_time = true // 显示重置按钮
      },

      // 取消事件
      inputStartCancel() {
        this.input_start_show = false
      },

      /**
       * 录入结束时间部分
       * */
      inputEndTime() {
        this.input_end_show = true
      },

      // 确认事件
      inputEndConfirm(val) {
        this.input_end_time = this.formatDate2(val)
        let enter_star_time = new Date(this.input_start_time)
        let enter_end_time = new Date(this.input_end_time)
        if (enter_end_time.getTime() <= enter_star_time.getTime()) {
          Notify({type: 'danger', message: '开始时间不能大于或等于结束时间'});
          this.input_end_time = ''
          return
        }
        this.input_end_show = false
        this.enter_clear_time = true // 显示重置按钮
      },

      // 取消事件
      inputEndCancel() {
        this.input_end_show = false
      },

      /**
       * 回访开始时间部分
       * */
      visitStartTime() {
        this.visit_start_show = true
      },

      // 确认事件
      visitStartConfirm(val) {
        this.visit_start_time = this.formatDate2(val)
        let visit_star_time = new Date(this.visit_start_time)
        let visit_end_time = new Date(this.visit_end_time)
        if (visit_end_time.getTime() <= visit_star_time.getTime()) {
          Notify({type: 'danger', message: '开始时间不能大于或等于结束时间'});
          this.visit_start_time = ''
          return
        }
        this.visit_start_show = false
        this.return_clear_time = true // 显示重置按钮
      },

      // 取消事件
      visitStartCancel() {
        this.visit_start_show = false
      },

      /**
       * 回访结束时间部分
       * */
      visitEndTime() {
        this.visit_end_show = true
      },

      // 确认事件
      visitEndConfirm(val) {
        this.visit_end_time = this.formatDate2(val)
        let visit_star_time = new Date(this.visit_start_time)
        let visit_end_time = new Date(this.visit_end_time)
        if (visit_end_time.getTime() <= visit_star_time.getTime()) {
          Notify({type: 'danger', message: '开始时间不能大于或等于结束时间'});
          this.visit_end_time = ''
          return
        }
        this.visit_end_show = false
        this.return_clear_time = true // 显示重置按钮
      },

      // 取消事件
      visitEndCancel() {
        this.visit_end_show = false
      },

      /**
       * 重置下拉按钮
       * */
      // 联系方式重置
      clearableContact() {
        this.contact_info = '' // 清空联系类型回显
        this.contact_info_type = '' // 清空请求时参数
        this.select_show = true // 默认文字显示
        this.clear_contact_show = true // 切换重置图标
      },

      // 等级重置
      clearableLevel() {
        this.screen_level = '' // 清空等级回显
        this.screen_level_id = '' // 清空请求时参数
        this.clear_level_show = true // 切换重置图标
      },

      // 跟单顾问重置
      clearableAdviser() {
        this.screen_follow_adviser = '' // 清空跟单顾问回显
        this.follow_adviser_id = '' // 清空请求时参数
        this.clear_adviser_show = true // 切换图标
      },

      // 来源重置
      clearableSource() {
        this.source_visible = false // 来源级联选择部分控制
        this.fieldValue = '' // 清空来源回显
        this.source = '' // 清空来源传递参数
        this.cascaderValue = '' // 重置级联选择器
        this.clear_source_show = true // 切换重置图标
      },

      // 录入时间重置
      enterTimeClear() {
        this.enter_clear_time = false // 重置按钮消失
        this.input_start_time = '' // 清空录入时间(开始)
        this.input_end_time = '' // 清空录入时间(结束)
      },

      // 回访时间重置
      returnTimeClear() {
        this.return_clear_time = false // 重置按钮消失
        this.visit_start_time = '' // 清空回访时间(开始)
        this.visit_end_time = '' // 清空回访时间(结束)
      },

      // 标签重置
      clearableLabel() {
        this.customer_label = '' // 清空标签回显
        this.label_id = '' // 清空标签id
        this.clear_label_show = true // 显示下拉
      },

      // 无效原因重置
      clearableInvalid() {
        this.customer_invalid = '' // 清空标签回显
        this.invalid_id = '' // 清空标签id
        this.clear_invalid_show = true // 显示下拉
      },


      // 录入重置
      clearableEnter() {
        this.customer_enter = '' // 清空标签回显
        this.enter_id = '' // 清空标签id
        this.clear_enter_show = true // 显示下拉
      },

      // 客户地址重置
      clearableAddress() {
        this.address_value = [] // 清空客户地址参数集合
        this.address_info = '' // 清空客户地址回显
        this.address_value = '' // 重置客户地址选择器
        this.clear_address_show = true // 切换重置图标
        this.address_cascader_visibel = false // 客户地址级联选择器控制
      },

      // 资源类别重置
      clearableCat() {
        this.cat_info = '' // 清空资源类别回显
        this.cat_type_id = '' // 清空请求时参数
        this.clear_cat_show = true // 切换图标
      },

      // 苹果键盘事件
      blurIpt() {
        if (this.iosStates) {
          this.bottom_nav_visible = true
          return
        }
      },
      // 苹果键盘事件
      focusIpt() {
        if (this.iosStates) {
          this.bottom_nav_visible = false
          return
        }
      },
    }
  };
</script>
<style scoped>
  /* ---- header区域 ---- */
  .header {
    position: sticky;
    top: 0;
    /*height: 106px;*/
    height: 52px;
    background: #fff;
    box-shadow: 0 9px 16px rgba(25, 52, 139, 0.06);
    z-index: 9;
  }

  .header-nav {
    position: relative;
    height: 53px;
    border-bottom: 1px solid #F5F5FC;
    color: #222;
    font-size: 19px;
    text-align: center;
    line-height: 53px;
  }

  .header-nav div {
    position: absolute;
    left: 10px;
    width: 19px;
    height: 16px;
  }

  .header-page {
    display: flex;
    justify-content: space-between;
    height: 53px;
    padding: 0 15px;
  }

  .page-info {
    color: #A1A7BA;
    font-size: 12px;
    line-height: 53px;
  }

  .page-num {
    padding: 0 2px;
    color: #222;
  }

  .page-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .page-input {
    width: 43px;
    height: 28px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
  }

  .page-btn input {
    padding: 0;
    width: 43px;
    height: 28px;
    border: none;
    color: #A1A7BA;
    font-size: 15px;
    text-align: center;
  }

  .jump-btn {
    width: 49px;
    height: 30px;
    margin-left: 7px;
    background: #409EFF;
    border-radius: 4px;
    color: #fff;
    font-size: 15px;
    text-align: center;
    line-height: 30px;
  }

  .page-jump {
    padding: 0;
  }

  .page-jump >>> input {
    height: 30px;
    color: #A1A7BA;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
  }

  /* ---- 筛选部分 ---- */
  .detail-w {
    width: 345px;
    margin: 0 auto;
  }

  .screen {
    position: relative;
    height: 270px;
    margin-top: 15px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
  }

  .search-term {
    padding-top: 15px;
  }

  .search-term-label {
    padding: 0 20px;
    color: #A1A7BA;
    font-size: 15px;
  }

  .search-term-detail {
    position: relative;
    width: 303px;
    height: 38px;
    margin: 10px auto 0;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
  }

  .down-icon {
    position: absolute;
    top: 0;
    right: 15px;
    width: 12px;
    height: 100%;
    color: #606266;
  }

  .down-icon svg {
    position: relative;
    top: 11px;
  }

  .down-icon i {
    position: relative;
    top: 11px;
    left: -2px;
    color: #C9C7C8;
  }

  .vant-input-edit {
    height: 38px !important;
    line-height: 38px !important;
    padding: 0 10px !important;
    color: #222 !important;
    font-size: 15px !important;
  }

  .input-edit-time {
    padding: 0 !important;
    line-height: 40px !important;
  }

  .vant-input-edit >>> input::-webkit-input-placeholder {
    color: #606266 !important;
  }

  .term-detail-contact {
    display: flex;
    justify-content: space-between;
    width: 305px;
    margin: 10px auto 0;
  }

  .contact-down {
    position: relative;
    width: 85px;
    height: 38px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    line-height: 38px;
  }

  .contact-icon {
    top: 0;
    right: 8px;
  }

  .contact-icon i {
    top: 1px;
  }

  .contact-icon svg {
    top: 0;
  }

  .contact-icon {
    top: 0;
    right: 7px;
  }

  .contact-detail {
    width: 205px;
    height: 38px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
  }

  .time-clear i {
    top: 1px;
  }

  .screen-operation {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 25px;
    left: 20px;
    width: 305px;
  }

  .search-btn {
    width: 147px;
    height: 40px;
    background: #409EFF;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 15px;
  }

  .more-search {
    width: 145px;
    height: 38px;
    border: 1px solid #409EFF;
    border-radius: 4px;
    text-align: center;
    line-height: 38px;
    color: #409EFF;
    font-size: 15px;
  }

  .default-text {
    padding-left: 10px;
    color: #606266;
    font-size: 15px;
  }

  .time-slot-box {
    display: flex;
    width: 285px;
    padding: 0 10px;
    line-height: 40px;
  }

  .time-slot {
    width: 135px;
    height: 38px;
    color: #606266;
  }

  .time-slot-center {
    margin-right: 10px;
    color: #222;
    font-size: 15px;
  }

  /* ---- 列表数据 ---- */
  .list-wrap {
    margin: 15px 0;
  }

  .list-item {
    padding-bottom: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
  }

  .list-top {
    display: flex;
    justify-content: space-between;
    width: 305px;
    height: 45px;
    margin: 0 auto 16px;
    border-bottom: 1px solid #DDE0E7;
  }

  .list-top-num {
    padding-top: 17px;
    color: #222;
    font-size: 15px;
  }

  .list-label {
    padding-right: 8px;
    color: #A1A7BA;
  }

  .list-info {
    display: flex;
    width: 305px;
    margin: 13px auto 0;
    color: #222;
    font-size: 15px;
    line-height: 20px;
  }

  .info-label {
    flex-shrink: 0;
    width: 60px;
    margin-right: 10px;
    color: #A1A7BA;
  }

  .list-info-item {
    display: flex;
  }

  .one-line-info {
    width: 50px;
  }

  .operate-info {
    display: flex;
    justify-content: space-between;
    width: 305px;
    height: 40px;
    margin: 23px auto 0;
  }

  .both-sides {
    width: 147px;
    height: 38px;
    border: 1px solid #409EFF;
    border-radius: 4px;
    color: #409EFF;
    font-size: 15px;
    text-align: center;
    line-height: 38px;
  }

  .operate-center {
    width: 147px;
    height: 40px;
    background: #409EFF;
    border-radius: 4px;
    color: #fff;
    font-size: 15px;
    text-align: center;
    line-height: 40px;
  }

  /*底部栏*/
  .navIcon {
    height: 21px;
    width: 21px;
  }

  /*优先标志*/
  .priority-box {
    display: inline-block;
    position: relative;
    top: 1px;
    width: 27px;
    height: 15px;
    margin-left: 16px;
    background: #F56C6C;
    color: #fff;
    font-size: 12px;
    text-align: center;
    line-height: 15px;
    border-radius: 3px;
  }

  /*优化无效原因内容过长导致的样式问题*/
  .invalid-select >>> .van-field__control {
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
