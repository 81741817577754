<template>
  <!-- S 头部header -->
  <div class="header">
    <div class="header-nav">
      <div class="submit-btn" @click="submit()">添加</div>
    </div>
  </div>
  <div style="height: 67px;"></div>
  <!-- E 头部header -->
  <!-- S 权重列表 -->
  <div class="w">
    <div class="weight-box">

      <div class="weight-index" v-for="(item, i) in list">
        <div class="weight-left">
          <div class="right-title">
            <span class="title-name">{{item.user_name}}</span>
            <span class="title-states" v-if="item.status == 1">接单</span>
            <span class="title-states-no" v-else>不接单</span>
          </div>
          <div class="right-other">
            <div class="other-type">
              <span class="type-title">类别</span>
              <span class="type-num">{{item.title}}</span>
            </div>
            <div class="other-type">
              <span class="type-title">权重</span>
              <span class="type-num">{{item.num}}</span>

            </div>
          </div>
        </div>
        <div class="weight-right">
          <button class="right-edit" :data-id="item.id" @click="weightEdit(item)">修改</button>
          <button class="right-del" :data-id="item.id" @click="weightDel(item)">删除</button>
        </div>
      </div>
    </div>
  </div>
  <!-- E 权重列表 -->
  <!-- S 底部导航栏 -->
  <div class="occupy-box"></div>
  <van-tabbar v-model="active">
    <van-tabbar-item replace to="/index">
      <span>首页</span>
      <template #icon="props">
        <div class="navIcon" v-bind:class="{ active: props.active}">
          <el-icon>
            <home-filled/>
          </el-icon>
        </div>
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/customManage">
      <span>客户管理</span>
      <template #icon="props">
        <div class="navIcon" v-bind:class="{ active: props.active}">
          <el-icon>
            <user/>
          </el-icon>
        </div>
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/signedResources">
      <span>已签约</span>
      <template #icon="props">
        <div class="navIcon" v-bind:class="{ active: props.active}">
          <el-icon>
            <edit/>
          </el-icon>
        </div>
      </template>
    </van-tabbar-item>
    <van-tabbar-item  replace to="/sharedPool">
      <span>共享池</span>
      <template #icon="props">
        <div class="navIcon" v-bind:class="{ active: props.active}">
          <el-icon>
            <monitor/>
          </el-icon>
        </div>
      </template>
    </van-tabbar-item>
  </van-tabbar>
  <!-- S 底部导航栏 -->
</template>

<script>
  import {getWeight, delWeight} from '../api'
  import {Dialog, Notify} from 'vant';
  import {ref} from "vue";

  export default {
    name: "weightManage",
    data() {
      return {
        list: [
          //    {
          //    id: 1,//数据ID
          //    create_time: 1627542062,//创建时间
          //    update_time: 1637027257,//更新时间
          //    user_id: 44,//顾问ID <number>
          //    num: 1,//顾问权重 <number>
          //    status: 0,//顾问接单状态 <number>
          //    title: "四国1",//类别名称 <string>
          //    user_name: "王曼茹"//顾问名称 <string>
          // },
        ],
      }
    },

    // 生命周期 初始化
    setup() {
      const active = ref(''); // 设定选定第几个
      return {
        active,
      };
    },

    created() {
      window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
      this.getCatalog(); //最初的时候 获取目录的数据
    },
    methods: {

      // 返回上一页
      goBackPage() {
        this.$router.go(-1)
      },
      submit() {
        this.$router.push({
          path: '/weightAdd',
        });
      },
      // 请求
      getCatalog() {
        getWeight().then(data => {
          // 判断token
          if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403) {
            Notify({type: 'danger', message: data.message});
            sessionStorage.clear()
            this.$router.push({
              path: '/login'
            })
            return
          }

          if (data.code === 200) {
            this.list = data.data
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },
      // 修改
      weightEdit(item) {
        // alert(item.id)
        this.$router.push({
          path: '/weightAdd',
          query: {
            id: item.id, // 数据ID
            user_id: item.user_id, //顾问ID
            title: item.title,//类别名称
            num: item.num,// 权重值
            status: item.status, // 顾问接单状态
            user_name: item.user_name // 顾问名称
          }

        });
      },
      // 删除
      weightDel(item) {
        Dialog.confirm({
          message:
            '此操作将永久删除该数据, 是否继续?',
        })
          .then(() => {
            let para = {
              id: item.id,
            };
            delWeight(para).then(data => {
              if (data.code === 200) {
                Notify({type: 'success', message: '删除成功'});
                this.getCatalog()
              } else {
                Notify({type: 'danger', message: data.message});

              }
            }).catch(() => {
              Notify({type: 'danger', message: '服务连接失败'});
            })
          })
          .catch(() => {
            // on cancel
          });
      }
    }
  }
</script>

<style scoped>
  /* ---- header区域 ---- */
  .header {
    position: fixed;
    width: 100%;
    top: 0;
    height: 52px;
    margin-bottom: 12px;
    background: #fff;
    box-shadow: 0 9px 16px rgba(25, 52, 139, 0.06);
    z-index: 9;
  }

  .header-nav {
    position: relative;
    height: 53px;
    color: #222;
    font-size: 19px;
    text-align: center;
    line-height: 53px;
  }

  .header-nav-back {
    position: absolute;
    left: 10px;
    width: 19px;
    height: 16px;
  }

  /*此处可能存在添加样式问题 设计稿明显不同 2022-2-9*/
  .submit-btn1 {
    position: absolute;
    top: 11px;
    right: 15px;
    height: 30px;
    color: #409EFF;
    font-size: 15px;
    text-align: center;
    line-height: 30px;
    border-radius: 4px;
  }

  /*权重盒子*/
  .weight-box {
    width: 100%;
  }

  .weight-index {
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(108, 157, 209, 0.16);
    opacity: 1;
    border-radius: 10px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
  }



  .weight-right {
    flex-shrink: 0;
  }

  .weight-right button {
    white-space:nowrap;
    width: 60px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    border-radius: 5px;
    border: none;
    font-size: 15px;
    display: inherit;
  }

  .right-edit {
    background: #409EFF;
    margin-bottom: 8px;
  }

  .right-del {
    background: #F56C6C;
  }

  .title-name {
    font-size: 20px;
    font-weight: bold;
    line-height: 34px;
    font-size: 20px;
    color: #222222;
  }

  .title-states {
    margin-left: 15px;
    font-size: 15px;
    font-weight: 400;
    line-height: 34px;
    color: #409EFF;
  }

  .title-states-no {
    margin-left: 15px;
    font-size: 15px;
    font-weight: 400;
    line-height: 34px;
    color: #A1A7BA;
  }

  .right-other {
    display: flex;
    margin-top: 15px;
  }

  .other-type {
    width: 120px;
  }

  .type-title {
    font-size: 15px;
    color: #A1A7BA;
    margin-right: 8px;
  }

  .type-num {
    font-size: 15px;
    color: #222222;
  }


</style>
