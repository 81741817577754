<template>
    <div style="height: 100%">
        <div class="introduce-title w">
            <div class="introduce-title-top">时间选择说明</div>
            <div class="introduce-title-text">结束时间的规则为当日凌晨，选择时请向后顺延一天，示 例：查看6月1号至6月3号数据，时间需要选择为6月1号至 6月4号。</div>
        </div>
        <!-- S 筛选条件部分 -->
        <div class="screen detail-w">
            <div class="search-term">
                <div class="search-term-label">签约时间：</div>
                <div class="search-term-detail time-slot-box">
                    <div class="time-slot" @click="inputStartTime">
                        <van-field class="vant-input-edit input-edit-time" v-model="input_start_time" readonly
                                   placeholder="选择开始时间"/>
                    </div>
                    <div class="time-slot-center">至</div>
                    <div class="time-slot" @click="inputEndTime">
                        <van-field class="vant-input-edit input-edit-time" v-model="input_end_time" readonly
                                   placeholder="选择结束时间"/>
                    </div>
                    <div class="down-icon time-clear" v-if="enter_clear_time" @click="enterTimeClear">
                        <van-icon name="clear"/>
                    </div>
                </div>
            </div>
            <div class="search-term">
                <div class="search-term-label">来源</div>
                <div class="search-term-detail" @click="adviserPopup">
                    <van-field class="vant-input-edit" v-model="adviser_info" clearable readonly placeholder="请选择"/>
                    <div class="down-icon" v-if="clear_adviser_show">
                        <caret-bottom/>
                    </div>
                    <div class="down-icon" @click.stop="clearableAdviser" v-else>
                        <van-icon name="clear"/>
                    </div>
                </div>
            </div>
            <div class="search-term">
                <div class="search-term-label">公司</div>
                <div class="search-term-detail" @click="companyPopup">
                    <van-field class="vant-input-edit" v-model="company_info" clearable readonly placeholder="请选择"/>
                    <div class="down-icon" v-if="clear_company_show">
                        <caret-bottom/>
                    </div>
                    <div class="down-icon" @click.stop="clearableCompany" v-else>
                        <van-icon name="clear"/>
                    </div>
                </div>
            </div>
            <button class="contact-btn contact-btn2" @click="search()"><img src="../assets/search.png" alt=""
                                                                            class="search-img">搜索
            </button>
        </div>
        <!-- S 数量统计 -->
        <div class="introduce-statistics w">
            <div class="page-title">总计</div>
            <div class="statistics-box">
                <div class="statistics-item">
                    <div class="statistics-item-num">{{allPageNum}}</div>
                    <div class="statistics-item-text">总量</div>
                </div>
                <div class="statistics-item">
                    <div class="statistics-item-num">{{effectivePageNum}}</div>
                    <div class="statistics-item-text">有效</div>
                </div>
                <div class="statistics-item">
                    <div class="statistics-item-num">{{nonePageNum}}</div>
                    <div class="statistics-item-text">无效</div>
                </div>
            </div>
        </div>
        <!-- E 数量统计 -->
        <!-- E 筛选条件部分 -->
        <div class="content-data-box w">
            <div class="data-index" v-for="(item, index) in dataList"
                 :data-id="item.id">
                <div class="item-top">
                    <span class="item-title">序号</span>
                    <span class="item-text">{{index+1}}</span>
                </div>
                <div class="item-double">
                    <div class="double-item">
                        <span class="double-title">类别</span>
                        <span class="double-text">{{item.name}}</span>
                    </div>
                    <div class="double-item">
                        <span class="double-title">有效</span>
                        <span class="double-text">{{item.effective}}</span>
                    </div>
                </div>
                <div class="item-double">
                    <div class="double-item">
                        <span class="double-title">无效</span>
                        <span class="double-text">{{item.invalid}}</span>
                    </div>
                    <div class="double-item">
                        <span class="double-title">总量</span>
                        <span class="double-text">{{item.count}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="empty-data" v-if="searchShow">暂无数据</div>
    </div>
    <!-- S 录入开始时间弹层 -->
    <van-popup v-model:show="input_start_show" position="bottom" :style="{ height: '48%' }">
        <van-datetime-picker
                v-model="input_start_date"
                type="date"
                title="选择开始时间"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
                @confirm="inputStartConfirm"
                @cancel="inputStartCancel"
        />
    </van-popup>
    <!-- E 录入开始时间弹层 -->
    <!-- S 录入结束时间弹层 -->
    <van-popup v-model:show="input_end_show" position="bottom" :style="{ height: '48%' }">
        <van-datetime-picker
                v-model="input_end_date"
                type="date"
                title="选择结束时间"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
                @confirm="inputEndConfirm"
                @cancel="inputEndCancel"
        />
    </van-popup>
    <!-- E 录入结束时间弹层 -->
    <!-- S 顾问弹层 -->
    <van-popup v-model:show="adviser_show" position="bottom" :style="{ height: '48%' }">
        <van-picker
                title="请选择来源"
                show-toolbar
                :columns="adviser_data"
                @confirm="adviserConfirm"
                @cancel="adviserCancel"
        />
    </van-popup>
    <!-- E 顾问弹层 -->
    <!-- S 公司弹层 -->
    <van-popup v-model:show="company_show" position="bottom" :style="{ height: '48%' }">
        <van-picker
                title="请选择公司"
                show-toolbar
                :columns="company_data"
                @confirm="companyConfirm"
                @cancel="companyCancel"
        />
    </van-popup>
    <!-- E 公司弹层 -->
</template>
<script>
    import {getAdviserList, getTotalList,getCompanyList} from '../api/java'
    import {Notify} from "vant";
    import moment from "moment";

    export default {
        name: "demandTransfer",
        data() {
            return {
                screen_company: '',
                screen_contact: '',
                searchShow: false,
                dataList: [],
                /* ---- 时间组件 ---- */
                minDate: new Date(1970, 0, 1),
                maxDate: new Date(2122, 0, 1),
                input_start_date: new Date(), // 录入开始当前时间
                input_end_date: new Date(), // 录入结束当前时间
                visit_start_date: new Date(), // 回访开始当前时间
                visit_end_date: new Date(), // 回访结束当前时间
                formatter(type, val) {
                    if (type === 'year') {
                        return `${val}年`;
                    } else if (type === 'month') {
                        return `${val}月`;
                    } else {
                        return `${val}日`;
                    }
                    return val;
                },

                enter_clear_time: false, // 录入时间重置按钮
                input_start_show: false, // 录入时间开始弹层
                input_end_show: false, // 录入时间结束弹层
                input_start_time: '', // 录入开始时间
                input_end_time: '', // 录入结束时间


                /* ---- 来源部分 ---- */
                clear_adviser_show: true, // 来源问重置按钮
                adviser_id: '', // 来源id
                adviser_show: false, // 来源弹出层
                adviser_info: '', //来源
                request_adviser_data: [
                    {id: 1, label: '文拓'},
                    {id: 2, label: '老客户介绍'},
                    {id: 3, label: '自定义来源'},
                ], // 接口返回来源数据
                adviser_data: ['文拓', '老客户介绍', '自定义来源'], // vant 来源数据形式
                /* ---- 公司部分 ---- */
                clear_company_show: true, // 来源问重置按钮
                company_id: '', // 来源id
                company_show: false, // 来源弹出层
                company_info: '', //来源
                request_company_data: [
                    // {id: 1, label: '文拓'},
                    // {id: 2, label: '老客户介绍'},
                    // {id: 3, label: '自定义来源'},
                ], // 接口返回来源数据
                company_data: [], // vant 来源数据形式


                allPageNum:0, // 总量
                effectivePageNum:0,// 有效
                nonePageNum:0,// 无效
            }
        },
        created() {
            window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
            this.dataList = []
            // this.getTableData(false)
            this.gerCompany()
            //this.getAdviserData() // 顾问列表数据
        },
        methods: {
            gerCompany(){
                getCompanyList().then(data => {
                    // 判断token
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }

                    if (data.code === 200) {
                        this.request_company_data = data.data
                        for (let i = 0; i < this.request_company_data.length; i++) {
                            this.company_data.push(this.request_company_data[i].company_name)
                        }

                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })

            },
            // 顾问列表数据
            getAdviserData() {
                getAdviserList().then(data => {
                    // 判断token
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }

                    if (data.code === 200) {
                        this.request_adviser_data = data.data
                        for (let i = 0; i < this.request_adviser_data.length; i++) {
                            this.adviser_data.push(this.request_adviser_data[i].user_name)
                        }
                    }else{
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            // 取消事件
            inputEndCancel() {
                this.input_end_show = false
            },
            // 苹果键盘事件
            blurIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = true
                    return
                }
            },
            // 苹果键盘事件
            focusIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = false
                    return
                }
            },

            // 返回上一页
            goBackPage() {
                this.$router.go(-1)
            },

            // 点击查找
            search() {
                this.searchStates = false
                this.dataList = []
                this.getTableData(true)
            },
            getTableData(states) {
                this.finished = false; // 停止加载
                this.searchStates = true
                let para = {
                    company_name: this.company_id, // 页码
                    source: this.adviser_id, // 来源
                    start_time: this.input_start_time, // 开始时间
                    end_time: this.input_end_time, // 结束时间
                }
                this.effectivePageNum =0
                this.nonePageNum=0
                this.allPageNum =0
                if (!para.start_time) {
                    Notify({type: 'danger', message: '请选择录入时间'})
                    return
                }
                if (para.start_time && !para.end_time) {
                    Notify({type: 'danger', message: '请选择录入结束时间'})
                    return
                }
                if (para.end_time && !para.start_time) {
                    Notify({type: 'danger', message: '请选择录入开始时间'})
                    return
                }
                if (!para.source) {
                    Notify({type: 'danger', message: '请选择来源'})
                    return
                }
                getTotalList(para).then(data => {
                    if (data.code === 200) {
                        if (states === true) {
                            Notify({type: 'success', message: '搜索成功'});
                        }

                        this.dataList = data.data.data
                        this.loading = false;
                        this.searchStates = false
                        this.effectivePageNum =data.data.effective_count
                        this.nonePageNum=data.data.void_count
                        this.allPageNum =(this.effectivePageNum+this.nonePageNum)
                        this.current_page = para.pageNum
                        if (data.data.data.length > 0) {
                            this.dataShow = true
                            this.searchShow = false
                        } else {
                            this.dataShow = false
                            this.searchShow = true
                        }
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },

            /**
             * 录入开始时间部分
             * */
            inputStartTime() {
                this.input_start_show = true
            },

            // 时间戳转化(年-月-日 时:分:秒)
            formatDate(time) {
                return moment(time * 1000).format("YYYY-MM-DD hh:mm:ss")
            },

            // 时间戳转化(年-月-日)
            formatDate2(time) {
                return moment(time).format("YYYY-MM-DD")
            },
            /**
             * 录入结束时间部分
             * */
            inputEndTime() {
                this.input_end_show = true
            },

            // 录入时间重置
            enterTimeClear() {
                this.enter_clear_time = false // 重置按钮消失
                this.input_start_time = '' // 清空录入时间(开始)
                this.input_end_time = '' // 清空录入时间(结束)
            },
            // 确认事件
            inputStartConfirm(val) {
                this.input_start_time = this.formatDate2(val)
                let enter_star_time = new Date(this.input_start_time)
                let enter_end_time = new Date(this.input_end_time)
                if (enter_end_time.getTime() <= enter_star_time.getTime()) {
                    Notify({type: 'danger', message: '开始时间不能大于或等于结束时间'});
                    this.input_start_time = ''
                    return
                }
                this.input_start_show = false
                this.enter_clear_time = true // 显示重置按钮
            },

            // 取消事件
            inputStartCancel() {
                this.input_start_show = false
            },


            /**
             * 录入结束时间部分
             * */
            inputEndTime() {
                this.input_end_show = true
            },

            // 确认事件
            inputEndConfirm(val) {
                this.input_end_time = this.formatDate2(val)
                let enter_star_time = new Date(this.input_start_time)
                let enter_end_time = new Date(this.input_end_time)
                if (enter_end_time.getTime() <= enter_star_time.getTime()) {
                    Notify({type: 'danger', message: '开始时间不能大于或等于结束时间'});
                    this.input_end_time = ''
                    return
                }
                this.input_end_show = false
                this.enter_clear_time = true // 显示重置按钮
            },

            // 取消事件
            inputEndCancel() {
                this.input_end_show = false
            },


            /**
             * 来源(搜索部分)
             * */
            // 来源弹层控制
            adviserPopup() {
                this.adviser_show = true
            },

            // 重置来源
            clearableAdviser() {
                this.adviser_info = '' // 清空回显
                this.adviser_id = '' // 清空请求时参数
                this.clear_adviser_show = true // 切换重置图标
            },
            // 确认赋值
            adviserConfirm(value, index) {
                this.adviser_info = value // 来源赋值
                for (let i = 0; i < this.request_adviser_data.length; i++) {
                    if (index === i) {
                        this.adviser_id = this.request_adviser_data[i].id // 顾问id赋值
                    }
                }
                this.adviser_show = false // 关闭弹层
                this.clear_adviser_show = false // 切换重置图标
            },

            // 取消选择
            adviserCancel() {
                this.adviser_show = false // 关闭弹层
            },


            /**
             * 录入结束时间部分
             * */
            inputEndTime() {
                this.input_end_show = true
            },

            // 确认事件
            inputEndConfirm(val) {
                this.input_end_time = this.formatDate2(val)
                let enter_star_time = new Date(this.input_start_time)
                let enter_end_time = new Date(this.input_end_time)
                if (enter_end_time.getTime() <= enter_star_time.getTime()) {
                    Notify({type: 'danger', message: '开始时间不能大于或等于结束时间'});
                    this.input_end_time = ''
                    return
                }
                this.input_end_show = false
                this.enter_clear_time = true // 显示重置按钮
            },

            // 取消事件
            inputEndCancel() {
                this.input_end_show = false
            },


            /**
             * 公司(搜索部分)
             * */
            // 来源弹层控制
            companyPopup() {
                this.company_show = true
            },

            // 重置来源
            clearableCompany() {
                this.company_info = '' // 清空回显
                this.company_id = '' // 清空请求时参数
                this.clear_company_show = true // 切换重置图标
            },
            // 确认赋值
            companyConfirm(value, index) {
                this.company_info = value // 来源赋值
                for (let i = 0; i < this.request_company_data.length; i++) {
                    if (index === i) {
                        this.company_id = this.request_company_data[i].id // 顾问id赋值
                    }
                }
                this.company_show = false // 关闭弹层
                this.clear_company_show = false // 切换重置图标
            },

            // 取消选择
            companyCancel() {
                this.company_show = false // 关闭弹层
            },
        }
    }
</script>

<style scoped>
    /* ---- 客户详情部分---- */
    .data-index {
        padding: 14px 18px;
        background: #ffffff;
        margin: 11px 0 11px;
        position: relative;
        box-shadow: 0 3px 20px 1px rgba(108, 157, 209, 0.16);
        border-radius: 10px 10px 10px 10px;
    }

    .item-top {
        padding-bottom: 10px;
        border-bottom: 1px solid #DDE0E7;
    }

    .item-title {
        font-size: 15px;
        line-height: 15px;
        color: #A1A7BA;
        margin-right: 10px;
    }

    .item-text {
        line-height: 15px;
        font-size: 15px;
        color: #222222;
    }

    .item-double {
        display: flex;
        justify-content: space-between;
        margin-top: 14px;
    }


    .double-item {
        width: 50%;
    }

    .double-title {
        display: inline-block;
        font-size: 15px;
        line-height: 15px;
        color: #A1A7BA;
        margin-right: 10px;
        flex-shrink: 0;
    }

    .double-text {
        text-align: center;
        display: inline-block;
        line-height: 15px;
        font-size: 15px;
        color: #222222;
        width: 73%;
    }


    .vant-input-edit {
        height: 43px !important;
        line-height: 43px !important;
        padding: 0 15px !important;
        color: #222 !important;
        font-size: 15px !important;
    }

    /* ---- 筛选部分 ---- */
    .detail-w {
        width: 345px;
        margin: 0 auto;
    }

    .screen {
        position: relative;
        margin-top: 15px;
        padding-bottom: 15px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
    }

    .search-term {
        padding-top: 15px;
    }

    .search-term-label {
        padding: 0 20px;
        color: #A1A7BA;
        font-size: 15px;
    }

    .search-term-detail {
        position: relative;
        width: 303px;
        height: 38px;
        margin: 10px auto 0;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .down-icon {
        position: absolute;
        top: 0;
        right: 15px;
        width: 12px;
        height: 100%;
        color: #606266;
    }

    .down-icon svg {
        position: relative;
        top: 11px;
    }

    .down-icon i {
        position: relative;
        top: 11px;
        left: -2px;
        color: #C9C7C8;
    }

    .vant-input-edit {
        height: 38px !important;
        line-height: 38px !important;
        padding: 0 10px !important;
        color: #222 !important;
        font-size: 15px !important;
    }

    .input-edit-time {
        padding: 0 !important;
        line-height: 40px !important;
    }

    .vant-input-edit >>> input::-webkit-input-placeholder {
        color: #606266 !important;
    }

    .contact-icon i {
        top: 1px;
    }

    .contact-icon svg {
        top: 0;
    }

    .time-clear i {
        top: 1px;
    }


    .page-btn input {
        padding: 0;
        width: 43px;
        height: 28px;
        border: none;
        color: #A1A7BA;
        font-size: 15px;
        text-align: center;
    }

    .page-jump >>> input {
        height: 30px;
        color: #A1A7BA;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
    }

    .contact-btn {
        width: 305px;
        margin: 25px auto 0;
        flex-shrink: 0;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #409EFF;
        font-size: 15px;
        border-radius: 4px;
        border: none;
        color: #ffffff;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .introduce-title {
        width: 303px;
        padding: 8px 20px;
        margin-top: 15px;
        background: rgba(64, 158, 255, .08);
        border-radius: 6px;
        opacity: 1;
    }

    .introduce-title-top {
        font-size: 15px;
        color: #409EFF;
    }

    .introduce-title-text {
        margin-top: 4px;
        margin-bottom: 2px;
        font-size: 12px;
        color: #333333;
    }

    .time-slot-box {
        display: flex;
        width: 285px;
        padding: 0 10px;
        line-height: 40px;
    }

    .time-slot {
        width: 135px;
        height: 38px;
        color: #606266;
    }

    .time-slot-center {
        margin-right: 10px;
        color: #222;
        font-size: 15px;
    }

    .introduce-statistics {
        margin-top: 15px;
        width: 305px;
        padding: 10px 15px 19px;
        background: #FFFFFF;
        box-shadow: 0 3px 20px 1px rgba(108, 157, 209, 0.16);
        border-radius: 10px;
        opacity: 1;
    }

    .page-title {
        font-size: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #DCDFE6;
        color: #606266;
    }

    .statistics-box {
        width: 265px;
        margin: 16px auto 0;
        display: flex;
        justify-content: space-between;
    }

    .statistics-item-num {
        width: 100%;
        text-align: center;
        font-size: 17px;
        font-weight: bold;
        color: #409EFF;
    }

    .statistics-item-text {
        width: 100%;
        text-align: center;
        font-size: 15px;
        margin-top: 8px;
        color: #222222;
    }

    .search-img {
        width: 14px;
        margin-right: 5px;
    }
</style>
