<template>
  <div>
    <!-- S 头部header -->
    <div class="header">
      <div class="header-page">
        <div class="page-info">
          <span>共</span>
          <span class="page-num">{{total}}</span>
          <span>条/</span>
          <span class="page-num">{{sum_page}}</span>
          <span>页，</span>
          <span>当前第</span>
          <span class="page-num">{{current_page}}</span>
          <span>页</span>
        </div>
        <div class="page-btn">
          <div class="page-input">
            <van-field class="page-jump" v-model="page_num" @keyup.enter.native="jumpPage()" autocomplete="off"
                       type="digit"/>
          </div>
          <div class="jump-btn" @click="jumpPage()">跳转</div>
        </div>
      </div>
    </div>
    <!-- E 头部header -->
    <!-- S 筛选条件部分 -->
    <div class="screen detail-w" :style="{height: screen_height}">
      <div class="search-term">
        <div class="search-term-label">录入时间</div>
        <div class="search-term-detail time-slot-box">
          <div class="time-slot" @click="inputStartTime">
            <van-field class="vant-input-edit input-edit-time" v-model="input_start_time" readonly
                       placeholder="选择开始时间"/>
          </div>
          <div class="time-slot-center">至</div>
          <div class="time-slot" @click="inputEndTime">
            <van-field class="vant-input-edit input-edit-time" v-model="input_end_time" readonly placeholder="选择结束时间"/>
          </div>
          <div class="down-icon time-clear" v-if="clear_time_show" @click="clearableTime">
            <van-icon name="clear"/>
          </div>
        </div>
      </div>
      <div class="search-term">
        <div class="search-term-label">来源</div>
        <div class="search-term-detail" @click="sourceShow">
          <van-field
              class="vant-input-edit"
              v-model="fieldValue"
              readonly
              placeholder="请选择"
          />
          <div class="down-icon" v-if="clear_source_show">
            <caret-bottom/>
          </div>
          <div class="down-icon" @click.stop="clearableSource" v-else>
            <van-icon name="clear"/>
          </div>
        </div>
      </div>
      <!-- S 筛选展开部分 -->
      <div v-if="screen_more">
        <div class="search-term">
          <div class="search-term-label">标签</div>
          <div class="search-term-detail" @click="labelPopup">
            <van-field class="vant-input-edit" v-model="customer_label" readonly placeholder="请选择"/>
            <div class="down-icon" v-if="clear_label_show">
              <caret-bottom/>
            </div>
            <div class="down-icon" @click.stop="clearableLabel" v-else>
              <van-icon name="clear"/>
            </div>
          </div>
        </div>
        <div class="search-term">
          <div class="search-term-label">无效原因</div>
          <div class="search-term-detail" @click="invalidPopup">
            <van-field class="vant-input-edit" v-model="customer_invalid" readonly placeholder="请选择"/>
            <div class="down-icon" v-if="clear_invalid_show">
              <caret-bottom/>
            </div>
            <div class="down-icon" @click.stop="clearableInvalid" v-else>
              <van-icon name="clear"/>
            </div>
          </div>
        </div>
        <div class="search-term">
          <div class="search-term-label">联系方式</div>
          <div class="term-detail-contact">
            <div class="contact-down" @click="showPopup">
              <span class="default-text" v-if="select_show">请选择</span>
              <span class="default-text" v-else>{{contact_info}}</span>
              <div class="down-icon contact-icon" v-if="clear_contact_show">
                <caret-bottom/>
              </div>
              <div class="down-icon contact-icon" @click.stop="clearableContact" v-else>
                <van-icon name="clear"/>
              </div>
            </div>
            <div class="contact-detail">
              <van-field class="vant-input-edit" v-model="customer_contact" clearable autocomplete="off"
                         @focus="focusIpt" @blur="blurIpt" placeholder="请输入客户的联系方式..."/>
            </div>
          </div>
        </div>
        <div class="search-term">
          <div class="search-term-label">客户名称</div>
          <div class="search-term-detail">
            <van-field class="vant-input-edit" v-model="customer_name" clearable autocomplete="off" @focus="focusIpt"
                       @blur="blurIpt" placeholder="请输入客户名称..."/>
          </div>
        </div>
        <div class="search-term">
          <div class="search-term-label">客户序号</div>
          <div class="search-term-detail">
            <van-field class="vant-input-edit" v-model="customer_index" type="digit" clearable autocomplete="off"
                       @focus="focusIpt" @blur="blurIpt" placeholder="请输入客户序号..."/>
          </div>
        </div>
        <div class="search-term">
          <div class="search-term-label">跟单人</div>
          <div class="search-term-detail" @click="adviserPopup">
            <van-field class="vant-input-edit" v-model="follow_adviser" readonly placeholder="请选择"/>
            <div class="down-icon" v-if="clear_adviser_show">
              <caret-bottom/>
            </div>
            <div class="down-icon" @click.stop="clearableAdviser" v-else>
              <van-icon name="clear"/>
            </div>
          </div>
        </div>
        <div class="search-term">
          <div class="search-term-label">录入人</div>
          <div class="search-term-detail" @click="inputPopup">
            <van-field class="vant-input-edit" v-model="input_people" readonly placeholder="请选择"/>
            <div class="down-icon" v-if="clear_input_show">
              <caret-bottom/>
            </div>
            <div class="down-icon" @click.stop="clearableInput" v-else>
              <van-icon name="clear"/>
            </div>
          </div>
        </div>

        <div class="search-term">
          <div class="search-term-label">回访状态</div>
          <div class="search-term-detail" @click="returnPopup">
            <van-field class="vant-input-edit" v-model="return_visit" readonly placeholder="请选择"/>
            <div class="down-icon" v-if="clear_visit_show">
              <caret-bottom/>
            </div>
            <div class="down-icon" @click.stop="clearableVisit" v-else>
              <van-icon name="clear"/>
            </div>
          </div>
        </div>

      </div>
      <!-- E 筛选展开部分 -->
      <div class="screen-operation">
        <div class="search-btn" @click="search()">
          <img src="../assets/search.png" alt="">
          搜索
        </div>
        <div class="more-search" @click="moreSearch()">
          <img src="../assets/open_search.png" alt="">
          {{screen_text}}
        </div>
      </div>
    </div>
    <!-- E 筛选条件部分 -->
    <van-list
        v-model:loading="loading"
        :finished="finished"
        :finished-text="no_more"
        :offset="0"
        @load="onLoad"
    >
      <!-- S 列表部分 -->
      <div class="list-wrap" v-for="item in list_data">
        <div class="list-item detail-w">
          <div class="list-top">
            <div class="list-top-num">
              <span class="list-label">序号</span>
              <span>{{item.id}}</span>
            </div>
            <div class="list-top-num">
              <span>{{formatDate(item.create_time)}}</span>
            </div>
          </div>
          <div class="list-info">
            <div class="info-label">客户名称</div>
            <div>{{item.user_name}}</div>
          </div>

          <div class="list-info">
            <div class="info-label">回访时间</div>
            <div v-if="item.return_visit_time === 0"></div>
            <div v-else>{{formatDate(item.return_visit_time)}}</div>
          </div>
          <div class="list-info">
            <div class="info-label">回访结果</div>
            <div>
              <span v-if="item.return_visit === 0">未回访</span>
              <span v-if="item.return_visit === 3">回访中</span>
              <span v-if="item.return_visit === 1">有效</span>
              <span v-if="item.return_visit === 2">无效</span>
            </div>
          </div>
          <div class="list-info">
            <div class="info-label">来源</div>
            <div>{{item.source_name}}</div>
          </div>
          <div class="list-info one-line">
            <div class="list-info-item">
              <div class="info-label">跟单顾问</div>
              <div class="one-line-info">{{item.follow_counsel_name}}</div>
            </div>
            <div class="list-info-item" style="margin-left: 63px">
              <div class="info-label">录入人</div>
              <div class="one-line-info">{{item.official_service_name}}</div>
            </div>
          </div>
          <div class="operate-info">
            <div class="both-sides" @click="toDetail(item.id)">详情</div>
            <div class="operate-center" @click="returnOrder(item)">回访</div>
            <div class="operate-center" @click="toFollowOrder(item.id)">跟单</div>
            <div class="both-sides" @click="toJournal(item.id)">日志</div>
          </div>
        </div>
      </div>
      <div class="empty-data" v-if="empty_data">暂无数据</div>
      <!-- E 列表部分 -->
    </van-list>

    <!-- S 底部站位盒子 -->
    <div style='height: 61px;'></div>
    <!-- E 底部站位盒子 -->

    <!-- S 联系方式弹层 -->
    <van-popup v-model:show="contact_show" position="bottom" :style="{ height: '48%' }">
      <van-picker
          title="请选择联系方式"
          show-toolbar
          :columns="contact_data"
          @confirm="contactConfirm"
          @cancel="contactCancel"
      />
    </van-popup>
    <!-- E 联系方式弹层 -->
    <!-- S 来源弹层 -->
    <van-popup v-model:show="source_show" position="bottom">
      <van-cascader
          v-if="source_visible"
          v-model="cascaderValue"
          title="请选择来源"
          :options="options"
          :field-names="sourceNames"
          @close="source_show = false"
          @finish="onFinish"
      />
    </van-popup>
    <!-- E 来源弹层 -->
    <!-- S 标签弹层 -->
    <van-popup v-model:show="label_show" position="bottom" :style="{ height: '48%' }">
      <van-picker
          title="请选择标签"
          show-toolbar
          :columns="label_data"
          @confirm="levelConfirm"
          @cancel="levelCancel"
      />
    </van-popup>
    <!-- E 标签弹层 -->

    <!-- S 无效原因弹层 -->
    <van-popup v-model:show="invalid_show" position="bottom" :style="{ height: '48%' }">
      <van-picker
          title="请选择无效原因"
          show-toolbar
          :columns="invalid_data"
          @confirm="invalidConfirm"
          @cancel="invalidCancel"
      />
    </van-popup>
    <!-- E 无效原因弹层 -->

    <!-- S 跟单顾问弹层 -->
    <van-popup v-model:show="follow_adviser_show" position="bottom" :style="{ height: '48%' }">
      <van-picker
          title="请选择跟单人"
          show-toolbar
          :columns="follow_adviser_data"
          @confirm="adviserConfirm"
          @cancel="adviserCancel"
      />
    </van-popup>
    <!-- E 跟单顾问弹层 -->

    <!-- S 录入人弹层 -->
    <van-popup v-model:show="input_people_show" position="bottom" :style="{ height: '48%' }">
      <van-picker
          title="请选择录入人"
          show-toolbar
          :columns="input_people_data"
          @confirm="inputConfirm"
          @cancel="inputCancel"
      />
    </van-popup>
    <!-- E 录入人弹层 -->

    <!-- S 回访状态弹层 -->
    <van-popup v-model:show="return_visit_show" position="bottom" :style="{ height: '48%' }">
      <van-picker
          title="请选择回访状态"
          show-toolbar
          :columns="return_visit_data"
          :columns-field-names="{text: 'label'}"
          @confirm="returnConfirm"
          @cancel="returnCancel"
      />
    </van-popup>
    <!-- E 回访状态弹层 -->

    <!-- S 录入开始时间弹层 -->
    <van-popup v-model:show="input_start_show" position="bottom" :style="{ height: '48%' }">
      <van-datetime-picker
          v-model="input_start_date"
          type="date"
          title="选择开始时间"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="inputStartConfirm"
          @cancel="inputStartCancel"
      />
    </van-popup>
    <!-- E 录入开始时间弹层 -->

    <!-- S 录入结束时间弹层 -->
    <van-popup v-model:show="input_end_show" position="bottom" :style="{ height: '48%' }">
      <van-datetime-picker
          v-model="input_end_date"
          type="date"
          title="选择结束时间"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="inputEndConfirm"
          @cancel="inputEndCancel"
      />
    </van-popup>
    <!-- E 录入结束时间弹层 -->

    <!-- S 回访弹层 -->
    <van-dialog v-model:show="return_dialog" :before-close="onReturnClose" title="回访结果" show-cancel-button>
      <div class="info-box">
        <div class="info-title">
          <span>回访结果</span>
        </div>
        <div class="radio-box">
          <van-radio-group v-model="return_radio" @change="returnSelect" direction="horizontal">
            <div class="radio-item" :class="{radio_active:index+1 === return_radio_active}"
                 v-for="(item, index) in sex_data"
                 @click="switchColor(index+1)">
              <div class="radio-icon">
                <div class="radio-icon-spot"></div>
              </div>
              <van-radio :name="item.value">{{item.label}}</van-radio>
            </div>
          </van-radio-group>
        </div>
      </div>
      <div class="info-box">
        <div class="info-title">
          <span>回访内容</span>
        </div>
        <div class="info-input info-textarea">
          <van-field
              class="info-textarea-edit"
              v-model="return_info"
              autocomplete="off"
              rows="1"
              autosize
              type="textarea"
              placeholder="选填"
          />
        </div>
      </div>
    </van-dialog>
    <!-- E 回访弹层 -->
    <!-- S 底部导航栏 -->
    <van-tabbar v-model="active" v-if="bottom_nav_visible">
      <van-tabbar-item replace to="/index">
        <span>首页</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <home-filled/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/customManage">
        <span>客户管理</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <user/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/signedResources">
        <span>已签约</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <edit/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/sharedPool">
        <span>共享池</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <monitor/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <!-- S 底部导航栏 -->
  </div>
</template>

<script>
  import {
    returnVisit,
    downSource,
    downTag,
    gerAllRecord,
    getAdviserList,
    returnVisitDo,
    getSharedPool,
    invalidReasonData
  } from '../api';
  import {Dialog, Notify, Toast} from 'vant';
  import moment from "moment";
  import {ref} from "vue";

  export default {
    data() {
      return {
        /* ---- 监听软键盘是否弹出 ---- */
        defaultHeight: '0',  //默认屏幕高度
        nowHeight: '0',  //实时屏幕高度
        bottom_nav_visible: true, // 底部导航显示与隐藏
        iosStates: false, // 手机系统

        /* ---- 触底加载 ---- */
        loading: false,
        finished: false,
        searchStates: false,  // 不走 onload

        /*---- 下拉重置 ----*/
        clear_source_show: true, // 来源重置按钮
        clear_label_show: true, // 标签重置按钮
        clear_contact_show: true, // 联系方式重置按钮
        clear_adviser_show: true, // 跟单人重置按钮
        clear_input_show: true, // 录入人重置按钮
        clear_invalid_show: true, // 无效原因重置按钮
        clear_visit_show: true, // 回访状态重置按钮
        clear_time_show: false, // 录入时间重置按钮

        page_num: '0', // 去第几页
        current_page: '1', // 当前第几页
        total: '0', // 总条数
        sum_page: '0', // 总页数
        screen_height: '', // 展开搜索面板高度
        screen_text: '更多筛选条件', // 更多筛选条件文字切换
        screen_switch: false, // 更多筛选条件文字切换开关
        screen_more: false,
        input_time: '', // 录入时间
        customer_name: '', // 客户名称
        customer_index: '', // 客户序号
        input_start_show: false, // 录入时间开始弹层
        input_end_show: false, // 录入时间结束弹层
        input_start_time: '', // 录入开始时间
        input_end_time: '', // 录入结束时间
        list_data: [], // 列表数据

        /* ---- 联系方式选择部分 ---- */
        contact_show: false, // 联系方式弹出层
        select_show: true, // 联系方式选择默认显示
        contact_info: '', // 已选择的联系方式
        contact_info_type: '', // 已选择的联系方式id
        customer_contact: '', // 客户联系方式
        contact_data: ['手机', '微信', '邮箱', 'QQ'],
        get_contact_data: [
          {id: '0', title: '手机'},
          {id: '1', title: '微信'},
          {id: '2', title: '邮箱'},
          {id: '3', title: 'QQ'},
        ],

        /* ---- 标签部分 ---- */
        label_show: false, // 客户等级弹出层
        customer_label: '', // 标签
        label_id: '', // 标签id
        label_data: [], // vant标签下拉数据结构
        request_label_data: [], // 接口返回标签数据

        /* ---- 无效原因部分 ---- */
        invalid_show: false, // 无效原因弹出层
        customer_invalid: '', // 无效原因
        invalid_id: '', // 无效原因id
        invalid_data: [], // vant标无效原因签下拉数据结构
        request_invalid_data: [], // 接口返回标无效原因签数据

        /* ---- 跟单顾问部分 ---- */
        follow_adviser_show: false, // 跟单顾问弹出层
        follow_adviser: '', // 跟单顾问
        follow_adviser_id: '', // 跟单顾问id
        follow_adviser_data: [],
        request_adviser_data: [], // 接口返回数据格式(顾问列表)

        /* ---- 录入人部分 ---- */
        input_people_show: false, // 录入人弹出层
        input_people: '', // 标签
        input_people_id: '', // 录入人id
        input_people_data: [], // vant下拉录入人数据结构
        request_people_data: [], // 接口返回录入人数据

        /* ---- 回访状态部分 ---- */
        return_visit_show: false, // 回访状态弹出层
        return_visit: '', // 回访状态
        return_visit_id: '', // 录入人id
        return_visit_data: [
          {value: '0', label: '未回访'},
          {value: '3', label: '回访中'},
          {value: '1', label: '有效'},
          {value: '2', label: '无效'},
        ], // vant下拉回访状态数据结构

        /* ---- 来源部分 ---- */
        source_visible: false, // 来源级联选择器
        source_show: false, // 来源popup弹窗
        source: '', // 来源字符串作为参数请求时用
        fieldValue: '',
        cascaderValue: '',
        // 来源自定义字段
        sourceNames: {
          text: 'title',
          value: 'id',
          children: 'children'
        },
        options: [], // 来源数据

        /* ---- 时间组件 ---- */
        minDate: new Date(1970, 0, 1),
        maxDate: new Date(2122, 0, 1),
        input_start_date: new Date(), // 录入开始当前时间
        input_end_date: new Date(), // 录入结束当前时间
        formatter(type, val) {
          if (type === 'year') {
            return `${val}年`;
          } else if (type === 'month') {
            return `${val}月`;
          } else {
            return `${val}日`;
          }
          return val;
        },

        return_dialog: false, // 回访dialog控制
        /* ------ 回访结果单选 ------ */
        return_radio: 1, // 回访结果回显
        return_radio_id: 0, // 回访结果id(请求时传递的参数)
        sex_data: [
          {value: '0', label: '未回访'},
          {value: '3', label: '回访中'},
          {value: '1', label: '有效'},
          {value: '2', label: '无效'},
        ],
        return_radio_active: null, // 选中样式
        return_info: '', // 回访内容
        return_customer_id: '', // 回访客户id
        empty_data: false, // 暂无数据
        no_more: '没有更多了'
      }
    },

    // 生命周期 初始化
    setup() {
      const active = ref(''); // 设定选定第几个
      return {
        active,
      };
    },

    created() {
      window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
      this.getSource() // 来源数据
      this.getTag() // 标签数据
      this.invalidTag() // 无效原因数据
      this.getAdviserData() // 顾问列表数据
      this.getInputPeople() // 录入人数据
      this.return_radio_active = this.return_radio
      this.searchStates = true
      this.getList() // 列表数据
      this.iosStates = sessionStorage.getItem("iosStates")
    },

    // 获取屏幕默认高度与实时高度
    mounted: function () {
      //获取默认高度
      this.defaultHeight = document.documentElement.clientHeight
      window.onresize = () => {
        return (() => {
          //实时屏幕高度
          this.nowHeight = document.documentElement.clientHeight
        })();
      };
    },

    // 监听软键盘是否弹出
    watch: {
      nowHeight: function () {
        if (this.defaultHeight !== this.nowHeight) {
          this.bottom_nav_visible = false
        } else {
          this.bottom_nav_visible = true
        }
      }
    },

    methods: {
      // 列表数据
      onLoad(val) {
        if (this.searchStates == false) {
          // console.log("我是下拉")
          if (!this.page_num) {
            this.page_num = 1
          }
          let para = {
            start_time: this.input_start_time, // 开始时间
            end_time: this.input_end_time, // 结束时间
            source: this.source, // 来源
            tag: this.label_id, // 标签
            invalid_reason: this.invalid_id, // 无效原因
            type: this.contact_info_type, // 联系方式类型
            info: this.customer_contact, // 联系方式内容
            page: parseInt(this.page_num) + 1, // 页码
            limit: 10, // 每页多少条
            id: this.customer_index, // 客户序号
            name: this.customer_name, // 客户名称
            official_service: this.input_people_id, // 录入人
            follow_counsel: this.follow_adviser_id, // 顾问
            return_visit: this.return_visit_id, // 回访状态
          }

          returnVisit(para).then(data => {
            if (data.code === 200) {
              // 暂无数据显示
              if (data.data.count === 0) {
                this.empty_data = true
                this.no_more = ''
              } else {
                this.empty_data = false
                this.no_more = '没有更多了'
              }
              this.total = data.data.count
              if (parseInt(data.data.count % 10) === 0) {
                this.sum_page = parseInt(data.data.count / 10)
              } else {
                this.sum_page = parseInt(data.data.count / 10) + 1
              }
              this.loading = false;
              // 数据全部加载完成
              if (data.data.data.length === 0) {
                this.finished = true; // 停止加载
                return
              }
              this.page_num++
              this.current_page = para.page
              this.list_data = this.list_data.concat(data.data.data) // 数组拼接
            } else {
              Notify({type: 'danger', message: data.message});
            }
          }).catch(() => {
            Notify({type: 'danger', message: '服务连接失败'});
          })
        }
      },

      // 时间戳转化(年-月-日 时:分:秒)
      formatDate(time) {
        return moment(time * 1000).format("YYYY-MM-DD hh:mm:ss")
      },

      // 时间戳转化(年-月-日)
      formatDate2(time) {
        return moment(time).format("YYYY-MM-DD")
      },

      // 客户等级转化
      customerGrade(value) {
        let grade_arr = ['', 'A', 'B', 'C', 'D', 'E', '无效', '未分级']
        return grade_arr[value]
      },

      // 列表数据
      getList(val) {
        this.finished = false; // 停止加载
        this.searchStates = true
        let para = {
          start_time: this.input_start_time, // 开始时间
          end_time: this.input_end_time, // 结束时间
          source: this.source, // 来源
          tag: this.label_id, // 标签
          invalid_reason: this.invalid_id, // 无效原因
          type: this.contact_info_type, // 联系方式类型
          info: encodeURIComponent(this.customer_contact), // 联系方式内容
          page: parseInt(this.page_num) + 1, // 页码
          limit: 10, // 每页多少条
          id: this.customer_index, // 客户序号
          name: this.customer_name, // 客户名称
          official_service: this.input_people_id, // 录入人
          follow_counsel: this.follow_adviser_id, // 顾问
          return_visit: this.return_visit_id, // 回访状态
        }
        if (!para.type && para.info) {
          Notify({type: 'danger', message: '请选择联系方式类型'});
          return false
        }
        if (para.type && !para.info) {
          Notify({type: 'danger', message: '请选填写联系方式内容'});
          return false
        }
        this.current_page = para.page
        returnVisit(para).then(data => {
          // 暂无数据显示
          if (data.data.count === 0) {
            this.empty_data = true
            this.no_more = ''
            if (val) {
              Notify({type: 'success', message: '搜索成功', duration: 1000});
            }
          } else {
            this.empty_data = false
            this.no_more = '没有更多了'
          }
          if (data.code === 200) {
            this.total = data.data.count
            if (parseInt(data.data.count % 10) === 0) {
              this.sum_page = parseInt(data.data.count / 10)
            } else {
              this.sum_page = parseInt(data.data.count / 10) + 1
            }
            this.loading = false;
            this.searchStates = false
            // 数据全部加载完成
            if (data.data.data.length === 0) {
              this.finished = true; // 停止加载
              return
            }
            this.page_num++
            this.list_data = this.list_data.concat(data.data.data) // 数组拼接
            if (val) {
              Notify({type: 'success', message: '搜索成功', duration: 1000});
            }
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 来源下拉数据
      getSource() {
        downSource().then(data => {
          if (data.code === 200) {
            this.options = data.data
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 标签下拉数据
      getTag() {
        downTag().then(data => {
          // 判断token
          if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403) {
            Notify({type: 'danger', message: data.message});
            sessionStorage.clear()
            this.$router.push({
              path: '/login'
            })
            return
          }

          if (data.code === 200) {
            this.request_label_data = data.data
            for (let i = 0; i < this.request_label_data.length; i++) {
              this.label_data.push(this.request_label_data[i].title)
            }
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 无效原因下拉数据
      invalidTag() {
        invalidReasonData().then(data => {
          if (data.code === 200) {
            this.request_invalid_data = data.data
            for (let i = 0; i < this.request_invalid_data.length; i++) {
              this.invalid_data.push(this.request_invalid_data[i].title)
            }
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 顾问列表数据
      getAdviserData() {
        getAdviserList().then(data => {
          if (data.code === 200) {
            this.request_adviser_data = data.data
            for (let i = 0; i < this.request_adviser_data.length; i++) {
              this.follow_adviser_data.push(this.request_adviser_data[i].user_name)
            }
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 录入人数据
      getInputPeople() {
        gerAllRecord().then(data => {
          if (data.code === 200) {
            this.request_people_data = data.data
            for (let i = 0; i < this.request_people_data.length; i++) {
              this.input_people_data.push(this.request_people_data[i].user_name)
            }
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 返回上一页
      goBackPage() {
        this.$router.go(-1)
      },

      // 跳转按钮
      jumpPage() {
        // 点击搜索收起搜索栏
        if (this.screen_switch) {
          this.screen_switch = false
          this.screen_height = ''
          this.screen_text = '更多筛选条件'
          this.screen_more = false
        }

        this.searchStates = false
        if (!this.page_num) {
          Notify('请输入页码')
          return false
        }

        if (this.page_num > this.sum_page) {
          this.page_num = this.sum_page
        }

        if (this.page_num < 1) {
          this.page_num = '1'
        }
        this.page_num = this.page_num - 1
        this.current_page = this.page_num
        this.list_data = []
        this.getList()
      },

      // 点击搜索
      search() {
        this.searchStates = false
        let search_tips = true // 点击搜索反馈信息
        this.current_page = '1'
        this.page_num = '0'
        if (!this.contact_info_type && this.customer_contact) {
          Notify({type: 'danger', message: '请选择联系方式类型'});
          return false
        }
        if (this.contact_info_type && !this.customer_contact) {
          Notify({type: 'danger', message: '请选填写联系方式内容'});
          return false
        }
        this.list_data = []
        this.getList(search_tips)
      },

      // 更多筛选条件
      moreSearch() {
        this.screen_switch = !this.screen_switch
        if (this.screen_switch) {
          this.screen_height = 935 + 'px'
          this.screen_text = '收起'
          this.screen_more = true
        } else {
          this.screen_height = ''
          this.screen_text = '更多筛选条件'
          this.screen_more = false
        }
      },

      /**
       * 联系方式弹窗部分逻辑(搜索部分)
       * */
      // 联系方式弹窗控制
      showPopup() {
        this.contact_show = true;
      },

      // 确认赋值
      contactConfirm(value, index) {
        this.contact_info = value // 联系方式赋值
        this.select_show = false // 关闭默认文字
        // 获取下拉数据id(联系方式类型)
        for (let i = 0; i < this.get_contact_data.length; i++) {
          if (value === this.get_contact_data[i].title) {
            this.contact_info_type = this.get_contact_data[i].id
          }
        }
        this.contact_show = false // 关闭弹层
        this.clear_contact_show = false // 切换重置图标
      },

      // 取消选择
      contactCancel() {
        this.contact_show = false // 关闭弹层
      },

      /**
       * 来源部分逻辑(搜索)
       * */
      // 来源弹窗点击事件
      sourceShow() {
        this.source_show = true // 来源popup弹窗
        this.source_visible = true // 来源级联选择器
      },
      // 全部选项选择完毕后，会触发 finish 事件
      onFinish({selectedOptions}) {
        this.source_show = false;
        this.fieldValue = selectedOptions.map((option) => option.title).join('/');
        let source_arr = []
        for (let i = 0; i < selectedOptions.length; i++) {
          source_arr.push(selectedOptions[i].id)
        }
        this.source = source_arr.toString() // 来源赋值(请求数据时候用)
        this.clear_source_show = false // 切换重置图标
      },

      /**
       * 客户标签部分逻辑(搜索部分)
       * */
      // 标签弹层控制
      labelPopup() {
        this.label_show = true
      },

      // 确认赋值
      levelConfirm(value) {
        this.customer_label = value // 标签赋值
        for (let i = 0; i < this.request_label_data.length; i++) {
          if (value === this.request_label_data[i].title) {
            this.label_id = this.request_label_data[i].id
          }
        }
        this.label_show = false // 关闭弹层
        this.clear_label_show = false // 切换图标

      },

      // 取消选择
      levelCancel() {
        this.label_show = false // 关闭弹层
      },

      /**
       * 无效原因部分逻辑(搜索部分)
       * */
      // 标无效原因签弹层控制
      invalidPopup() {
        this.invalid_show = true
      },

      // 确认赋值
      invalidConfirm(value) {
        this.customer_invalid = value // 无效原因标签赋值
        for (let i = 0; i < this.request_invalid_data.length; i++) {
          if (value === this.request_invalid_data[i].title) {
            this.invalid_id = this.request_invalid_data[i].id
          }
        }
        this.invalid_show = false // 关闭弹层
        this.clear_invalid_show = false // 切换图标

      },

      // 取消选择
      invalidCancel() {
        this.invalid_show = false // 关闭弹层
      },

      /**
       * 跟单顾问(搜索部分)
       * */
      // 跟单顾问弹层控制
      adviserPopup() {
        this.follow_adviser_show = true
      },

      // 确认赋值
      adviserConfirm(value) {
        this.follow_adviser = value // 顾问赋值
        // 获取下拉数据id (跟单顾问)
        for (let i = 0; i < this.request_adviser_data.length; i++) {
          if (value === this.request_adviser_data[i].user_name) {
            this.follow_adviser_id = this.request_adviser_data[i].id
          }
        }
        this.follow_adviser_show = false // 关闭弹层
        this.clear_adviser_show = false // 切换图标
      },

      // 取消选择
      adviserCancel() {
        this.follow_adviser_show = false // 关闭弹层
      },

      /**
       * 录入人(搜索部分)
       * */
      // 录入人弹层控制
      inputPopup() {
        this.input_people_show = true
      },

      // 确认赋值
      inputConfirm(value) {
        this.input_people = value // 录入人赋值
        for (let i = 0; i < this.request_people_data.length; i++) {
          if (value === this.request_people_data[i].user_name) {
            this.input_people_id = this.request_people_data[i].id
          }
        }
        this.input_people_show = false // 关闭弹层
        this.clear_input_show = false // 切换重置图标
      },

      // 取消选择
      inputCancel() {
        this.input_people_show = false // 关闭弹层
      },

      /**
       * 回访状态
       * */
      returnPopup() {
        this.return_visit_show = true
      },

      // 确认赋值
      returnConfirm(val) {
        this.return_visit = val.label // 回显赋值
        this.return_visit_id = val.value // 状态id赋值
        this.return_visit_show = false // 关闭弹层
        this.clear_visit_show = false // 切换重置图标
      },

      // 取消选择
      returnCancel() {
        this.return_visit_show = false // 关闭弹层
      },

      /**
       * 操作按钮部分
       * */
      // 跳转详情页
      toDetail(id) {
        // 跳转传id
        this.$router.push({
          path: '/customer_detail',
          query: {
            id: id,
          }
        })
        if (sessionStorage.getItem('postID') !== '1') {
          sessionStorage.setItem('visit_status', '1') // 隐藏申报合格资源
        }
        sessionStorage.setItem('declare_show', '1') // 隐藏备注签证历史添加
      },

      /**
       * 录入开始时间部分
       * */
      inputStartTime() {
        this.input_start_show = true
      },

      // 确认事件
      inputStartConfirm(val) {
        this.input_start_time = this.formatDate2(val)
        let enter_star_time = new Date(this.input_start_time)
        let enter_end_time = new Date(this.input_end_time)
        if (enter_end_time.getTime() <= enter_star_time.getTime()) {
          Notify({type: 'danger', message: '开始时间不能大于或等于结束时间'});
          this.input_start_time = ''
          return
        }
        this.input_start_show = false
        this.clear_time_show = true // 切换图标
      },

      // 取消事件
      inputStartCancel() {
        this.input_start_show = false
      },

      /**
       * 录入结束时间部分
       * */
      inputEndTime() {
        this.input_end_show = true
      },

      // 确认事件
      inputEndConfirm(val) {
        this.input_end_time = this.formatDate2(val)
        let enter_star_time = new Date(this.input_start_time)
        let enter_end_time = new Date(this.input_end_time)
        if (enter_end_time.getTime() <= enter_star_time.getTime()) {
          Notify({type: 'danger', message: '开始时间不能大于或等于结束时间'});
          this.input_end_time = ''
          return
        }
        this.input_end_show = false
        this.clear_time_show = true // 切换图标
      },

      // 取消事件
      inputEndCancel() {
        this.input_end_show = false
      },

      // 跳转跟单页
      toFollowOrder(id) {
        this.$router.push({
          path: '/documentary',
          query: {
            id: id,
          }
        })
        sessionStorage.setItem('follow_status', '1') // 存储状态(跟单页操作按钮)
      },

      // 跳转日志页
      toJournal(id) {
        this.$router.push({
          path: '/journal',
          query: {
            id: id
          }
        })
      },

      /**
       * 回访选择部分
       * */
      // 回访
      returnOrder(item) {
        this.return_dialog = true // 开启弹窗
        this.return_info = item.content // 回访内容
        // 回访方式回显
        switch (item.return_visit) {
          case 0:
            this.return_radio_active = 1
            break;
          case 2:
            this.return_radio_active = 4
            break;
          case 3:
            this.return_radio_active = 2
            break;
          case 1:
            this.return_radio_active = 3
            break;
        }
        this.return_radio_id = item.return_visit // 回访方式
        this.return_customer_id = item.id // 回访客户id
      },
      // 性别单选监听
      returnSelect(val) {
        this.return_radio_id = val
      },

      // 单选点击变色
      switchColor(index) {
        this.return_radio_active = index
      },

      // 关闭弹窗前的一些操作
      onReturnClose(action) {
        if (action === 'confirm') {
          let para = {
            content: this.return_info, // 回访内容
            return_visit: this.return_radio_id, // 回访结果
            customer_id: this.return_customer_id, // 回访客户id
          }
          returnVisitDo(para).then(data => {
            if (data.code === 200) {
              Notify({type: 'success', message: '修改成功'});
              this.jumpPage() // 更新数据
              this.return_dialog = false
            } else {
              Notify({type: 'danger', message: data.message});
            }
          }).catch(() => {
            Notify({type: 'danger', message: '服务连接失败'});
          })
        } else {
          this.return_dialog = false
        }
      },

      /**
       * 重置下拉按钮
       * */
      // 来源重置
      clearableSource() {
        this.source_visible = false // 来源级联选择器
        this.fieldValue = '' // 清空来源回显
        this.source = '' // 清空来源传递参数
        this.cascaderValue = '' // 重置级联选择器
        this.clear_source_show = true // 切换重置图标
      },

      // 标签重置
      clearableLabel() {
        this.customer_label = '' // 清空标签回显
        this.label_id = '' // 清空标签id
        this.clear_label_show = true // 显示下拉
      },

      // 无效原因重置
      clearableInvalid() {
        this.customer_invalid = '' // 清空标签回显
        this.invalid_id = '' // 清空标签id
        this.clear_invalid_show = true // 显示下拉
      },

      // 联系方式重置
      clearableContact() {
        this.contact_info = '' // 清空联系类型回显
        this.contact_info_type = '' // 清空请求时参数
        this.select_show = true // 默认文字显示
        this.clear_contact_show = true // 切换重置图标
      },

      // 跟单顾问重置
      clearableAdviser() {
        this.follow_adviser = '' // 清空跟单顾问回显
        this.follow_adviser_id = '' // 清空请求时参数
        this.clear_adviser_show = true // 切换图标
      },

      // 录入人重置
      clearableInput() {
        this.input_people = '' // 清空回显
        this.input_people_id = '' // 清空请求时参数
        this.clear_input_show = true // 切换重置图标
      },

      // 回访状态重置
      clearableVisit() {
        this.return_visit = '' // 清空回显
        this.return_visit_id = '' // 清空请求时参数
        this.clear_visit_show = true // 切换重置图标
      },

      // 录入时间重置
      clearableTime() {
        this.input_start_time = '' // 清空开始时间
        this.input_end_time = '' // 清空结束时间
        this.clear_time_show = false // 切换图标
      },

      // 苹果键盘事件
      blurIpt() {
        if (this.iosStates) {
          this.bottom_nav_visible = true
          return
        }
      },
      // 苹果键盘事件
      focusIpt() {
        if (this.iosStates) {
          this.bottom_nav_visible = false
          return
        }
      },
    }
  }
</script>

<style scoped>
  /* ---- header区域 ---- */
  .header {
    position: sticky;
    top: 0;
    /*height: 106px;*/
    height: 52px;
    background: #fff;
    box-shadow: 0 9px 16px rgba(25, 52, 139, 0.06);
    z-index: 9;
  }

  .header-nav {
    position: relative;
    height: 53px;
    border-bottom: 1px solid #F5F5FC;
    color: #222;
    font-size: 19px;
    text-align: center;
    line-height: 53px;
  }

  .header-nav div {
    position: absolute;
    left: 10px;
    width: 19px;
    height: 16px;
  }

  .header-page {
    display: flex;
    justify-content: space-between;
    height: 53px;
    padding: 0 15px;
  }

  .page-info {
    color: #A1A7BA;
    font-size: 15px;
    line-height: 53px;
  }

  .page-num {
    padding: 0 2px;
    color: #222;
  }

  .page-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .page-input {
    width: 43px;
    height: 28px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
  }

  .page-btn input {
    padding: 0;
    width: 43px;
    height: 28px;
    border: none;
    color: #A1A7BA;
    font-size: 15px;
    text-align: center;
  }

  .jump-btn {
    width: 49px;
    height: 30px;
    margin-left: 7px;
    background: #409EFF;
    border-radius: 4px;
    color: #fff;
    font-size: 15px;
    text-align: center;
    line-height: 30px;
  }

  .page-jump {
    padding: 0;
  }
    .page-jump >>> input {
        height: 30px;
        color: #A1A7BA;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
    }

  /* ---- 筛选部分 ---- */
  .detail-w {
    width: 345px;
    margin: 0 auto;
  }

  .screen {
    position: relative;
    height: 270px;
    margin-top: 15px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
  }

  .search-term {
    padding-top: 15px;
  }

  .search-term-label {
    padding: 0 20px;
    color: #A1A7BA;
    font-size: 15px;
  }

  .search-term-detail {
    position: relative;
    width: 303px;
    height: 38px;
    margin: 10px auto 0;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
  }

  .down-icon {
    position: absolute;
    top: 0;
    right: 15px;
    width: 12px;
    height: 100%;
    color: #606266;
  }

  .down-icon svg {
    position: relative;
    top: 11px;
  }

  .down-icon i {
    position: relative;
    top: 11px;
    left: -2px;
    color: #C9C7C8;
  }

  .time-clear i {
    top: 1px;
  }

  .contact-icon {
    top: 0;
    right: 8px;
  }

  .contact-icon i {
    top: 1px;
  }

  .contact-icon svg {
    top: 0;
  }

  .vant-input-edit {
    height: 38px !important;
    line-height: 38px !important;
    padding: 0 10px !important;
    color: #222 !important;
    font-size: 15px !important;
  }

  .input-edit-time {
    padding: 0 !important;
    line-height: 40px !important;
  }

  .vant-input-edit >>> input::-webkit-input-placeholder {
    color: #606266 !important;
  }

  .term-detail-contact {
    display: flex;
    justify-content: space-between;
    width: 305px;
    margin: 10px auto 0;
  }

  .contact-down {
    position: relative;
    width: 85px;
    height: 38px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    line-height: 38px;
  }

  .contact-icon {
    top: 0;
    right: 7px;
  }

  .contact-detail {
    width: 205px;
    height: 38px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
  }

  .screen-operation {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 25px;
    left: 20px;
    width: 305px;
  }

  .search-btn {
    width: 147px;
    height: 40px;
    background: #409EFF;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 15px;
  }

  .more-search {
    width: 145px;
    height: 38px;
    border: 1px solid #409EFF;
    border-radius: 4px;
    text-align: center;
    line-height: 38px;
    color: #409EFF;
    font-size: 15px;
  }

  .default-text {
    padding-left: 10px;
    color: #606266;
    font-size: 15px;
  }

  .time-slot-box {
    display: flex;
    width: 285px;
    padding: 0 10px;
    line-height: 40px;
  }

  .time-slot {
    width: 135px;
    height: 38px;
    color: #606266;
  }

  .time-slot-center {
    margin-right: 10px;
    color: #222;
    font-size: 15px;
  }

  /* ---- 列表数据 ---- */
  .list-wrap {
    margin: 15px 0;
  }

  .list-item {
    padding-bottom: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
  }

  .list-top {
    display: flex;
    justify-content: space-between;
    width: 305px;
    height: 45px;
    margin: 0 auto 16px;
    border-bottom: 1px solid #DDE0E7;
  }

  .list-top-num {
    padding-top: 17px;
    color: #222;
    font-size: 15px;
  }

  .list-label {
    padding-right: 8px;
    color: #A1A7BA;
  }

  .list-info {
    display: flex;
    width: 305px;
    margin: 13px auto 0;
    color: #222;
    font-size: 15px;
    line-height: 20px;
  }

  .info-label {
    flex-shrink: 0;
    width: 60px;
    margin-right: 10px;
    color: #A1A7BA;
  }

  .list-info-item {
    display: flex;
  }

  .one-line-info {
    width: 50px;
  }

  .operate-info {
    display: flex;
    justify-content: space-between;
    width: 305px;
    height: 40px;
    margin: 23px auto 0;
  }

  .both-sides {
    width: 68px;
    height: 38px;
    border: 1px solid #409EFF;
    border-radius: 4px;
    color: #409EFF;
    font-size: 15px;
    text-align: center;
    line-height: 38px;
  }

  .operate-center {
    width: 70px;
    height: 40px;
    background: #409EFF;
    border-radius: 4px;
    color: #fff;
    font-size: 15px;
    text-align: center;
    line-height: 40px;
  }

  /* ---- 单选 ---- */
  .info-box {
    padding: 0 15px;
    margin: 0 auto 16px;
  }

  .info-title {
    color: #A1A7BA;
    font-size: 15px;
  }

  .radio-box {
    display: flex;
    margin-top: 2px;
    color: #606266;
    font-size: 15px;
  }

  .radio-item {
    position: relative;
    margin: 10px 10px 0 0;
    border-radius: 4px;
    border: 1px solid #C9CDD6;
    color: #606266;
    font-size: 15px;
  }

  .radio_active {
    border-color: #409EFF;
  }

  .radio-item:last-child {
    margin-right: 0;
  }

  .van-radio--horizontal {
    margin-right: 0;
    padding: 11px;
  }

  .radio-item >>> .van-radio__icon--round .van-icon {
    display: none;
  }

  .radio-item >>> .van-radio__label {
    position: relative;
    margin-left: 0;
    padding-left: 20px;
    z-index: 9;
  }

  .radio-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 14px;
    left: 12px;
    width: 10px;
    height: 10px;
    border: 2px solid #ABAEB8;
    border-radius: 50%;
  }

  .radio-icon-spot {
    width: 6px;
    height: 6px;
    background: #ABAEB8;
    border-radius: 50%;
  }

  .radio_active .radio-icon {
    border-color: #409EFF;
  }

  .radio_active .radio-icon-spot {
    background: #409EFF;
  }

  .info-input {
    position: relative;
    height: 43px;
    margin-top: 12px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
  }

  .info-textarea {
    height: 110px;
  }

  .info-textarea-edit {
    color: #222 !important;
    font-size: 15px !important;
  }

  .info-textarea-edit >>> textarea::-webkit-input-placeholder {
    color: #606266 !important;
  }

  .info-textarea-edit >>> textarea {
    min-height: 88px;
  }

  /*底部栏*/
  .navIcon {
    height: 21px;
    width: 21px;
  }
</style>
