<template>
    <div>
        <!-- S 头部header -->
        <div class="header">
            <div class="header-nav">
<!--                <div class="header-nav-back" @click="goBackPage()">-->
<!--                    <van-icon name="arrow-left"/>-->
<!--                </div>-->
<!--                <span>转案单</span>-->
                <div class="submit-btn" @click="addHandover()">添加</div>
            </div>
        </div>
        <!-- E 头部header -->
        <!-- S 列表部分 -->
        <div class="detail-w">
            <div class="person-top">
                <div class="search-term">
                    <div class="search-term-label">转案单ID：</div>
                    <div class="search-term-detail">
                        <van-field class="vant-input-edit" type="digit" v-model="customer_id" clearable placeholder="请输入转案单"
                                   maxlength="6"
                                   @focus="focusIpt"
                                   @blur="blurIpt"
                                   autocomplete="off"/>
                    </div>
                </div>
                <button class="contact-btn contact-btn2" @click="screenSearch()">搜索</button>
            </div>
            <div class="empty-data" v-if="empty_data">暂无数据</div>
            <div class="list-wrap" v-for="item in list_data" v-else>
                <div class="list-item detail-w">
                    <div class="list-top">
                        <div class="list-top-num">
                            <span class="list-label">序号</span>
                            <span>{{item.id}}</span>
                        </div>
                        <!--            <div class="list-top-num">-->
                        <!--              <span>{{formatDate(item.create_time)}}</span>-->
                        <!--            </div>-->
                    </div>
                    <div class="list-info">
                        <div class="info-label">顾问姓名</div>
                        <div class="url-content">{{item.user_name}}</div>
                    </div>
                    <div class="list-info">
                        <div class="info-label">签约项目</div>
                        <div class="url-content">{{item.sign_project}}</div>
                    </div>
                    <div class="list-info">
                        <div class="info-label" style="width: 145px">递交时间(单位：月)</div>
                        <div class="url-content">{{item.submission_time}}</div>
                    </div>
                    <div class="list-info">
                        <div class="info-label">转案时间</div>
                        <div class="url-content">
                            <span v-if="item.transfer_time!=null">{{formatDate2(item.transfer_time)}}</span>
                            <span v-else>-</span>
                        </div>
                    </div>
                    <div class="list-info">
                        <div class="info-label" style="width: 95px">最晚递交时间</div>
                        <div class="url-content" v-if="item.latest_time!==null">{{formatDate(item.latest_time)}}</div>
                        <div class="url-content" v-else>-</div>

                    </div>
                    <div class="list-info">
                        <div class="info-label" style="width: 95px">紧急程度</div>
                        <div class="url-content" v-if="item.submission_time < 6&&item.submission_time ">A类</div>
                        <div class="url-content" v-if="item.submission_time == 6">B类</div>
                        <div class="url-content" v-if="item.submission_time > 6">C类</div>
                    </div>
                    <div class="code-btn-box detail-w">
                        <div class="code-see" @click="toSee(item.id)">查看</div>
                        <div class="code-edit" @click="toEdit(item)">编辑</div>
                        <div class="code-del" @click="dels(item.id)">删除</div>
                    </div>
                </div>
            </div>

        </div>
        <!-- E 列表部分 -->
        <!-- S 底部导航栏 -->
        <div class="occupy-box"></div>
        <van-tabbar v-model="active">
            <van-tabbar-item replace to="/index">
                <span>首页</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <home-filled/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/customManage">
                <span>客户管理</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <user/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/signedResources">
                <span>已签约</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <edit/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/sharedPool">
                <span>共享池</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <monitor/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
        </van-tabbar>
        <!-- S 底部导航栏 -->
    </div>
</template>
<script>
    import {approvalStatus, referralDelete} from '../api/java'
    import {Dialog, Notify} from 'vant';
    import {ref} from "vue";
    import moment from "moment";
    export default {
        data() {
            return {
                customer_id:'',// 转案单ID
                id: '', // 客户id
                list_data: [], // 列表数据
                empty_data: false, // 暂无数据
            }
        },

        // 生命周期 初始化
        setup() {
            const active = ref(''); // 设定选定第几个
            return {
                active,
            };
        },

        created() {
            window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
            this.id = this.$route.query.id
            this.listData() // 列表数据
        },

        methods: {
            screenSearch(){
                this.listData(true)
            },

            // 返回上一页
            goBackPage() {
                this.$router.go(-1)
            },
            // 列表数据
            listData(states) {
                let para = {
                    customer_id: this.id, // 客户id
                    transfer_id: this.customer_id,
                }
                approvalStatus(para).then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        if(states === true){
                            Notify({type: 'success', message: '搜索成功', duration: 1000});
                        }
                        this.list_data = data.data
                        if (data.data.length === 0) {
                             this.empty_data = true // 无数据是展示'暂无数据'样式
                        }else{
                            this.empty_data = false // 无数据是展示'暂无数据'样式
                        }
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },

            // 查看编辑
            toSee(id){
                this.$router.push({
                    path: '/handoverIndex',
                    query: {
                        id: id,
                    }
                })
            },

            // 跳转编辑
            toEdit(row) {
                this.$router.push({
                    path: '/signedHandover',
                    query: {
                        custom_id: this.id,
                        edit_data: encodeURIComponent(JSON.stringify(row)), // vue路由传递对象
                    }
                })
            },

            // 添加跳转
            addHandover() {
                this.$router.push({
                    path: '/signedHandover',
                    query: {
                        custom_id: this.id,
                    }
                })
            },

            // 时间戳转化(年-月-日 时:分:秒)
            formatDate(time) {
                return moment(time * 1000).format("YYYY-MM-DD")
            },
            // 时间戳转化(年-月-日 时:分:秒)
            formatDate2(time) {
                return moment(time * 1000).format("YYYY-MM-DD HH:mm:ss")
            },

            // 字符串截取
            strFunc(name_str) {
                let file_name = name_str.lastIndexOf("\/");
                name_str = name_str.substring(file_name + 1, name_str.length);
                return name_str
            },
            // 苹果键盘事件
            blurIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = true
                    return
                }
            },
            // 苹果键盘事件
            focusIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = false
                    return
                }
            },

            // 删除数据
            dels(id) {
                let para = {
                    id: id // 数据id
                }
                Dialog.confirm({
                    message: '确认删除本条数据吗?',
                })
                    .then(() => {
                        referralDelete(para).then(data => {
                            if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                                Notify({type: 'danger', message: data.message});
                                sessionStorage.clear()
                                this.$router.push({
                                    path: '/weChatLogin'
                                })
                                return
                            }
                            if (data.code === 200) {
                                Notify({type: 'success', message: '删除成功'});
                                this.listData() // 更新数据
                            } else {
                                Notify({type: 'danger', message: data.message});
                            }
                        }).catch(() => {
                            Notify({type: 'danger', message: '服务连接失败'});
                        })
                    })
                    .catch(() => {
                    });
            },
        }
    }
</script>

<style scoped>
    /* ---- 列表数据 ---- */
    .list-wrap {
        margin: 15px 0;
    }

    .list-item {
        padding-bottom: 20px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
    }

    .list-top {
        display: flex;
        justify-content: space-between;
        width: 305px;
        height: 45px;
        margin: 0 auto 16px;
        border-bottom: 1px solid #DDE0E7;
    }

    .list-top-num {
        padding-top: 17px;
        color: #222;
        font-size: 15px;
    }

    .list-label {
        padding-right: 8px;
        color: #A1A7BA;
    }

    .list-info {
        display: flex;
        width: 305px;
        margin: 13px auto 0;
        color: #222;
        font-size: 15px;
        line-height: 20px;
    }

    .info-url-item {
        display: block;
    }

    .info-label {
        flex-shrink: 0;
        width: 60px;
        margin-right: 10px;
        color: #A1A7BA;
    }

    .list-info-item {
        display: flex;
    }

    .one-line-info {
        width: 50px;
    }

    .operate-info {
        display: flex;
        justify-content: space-between;
        width: 305px;
        height: 40px;
        margin: 23px auto 0;
    }

    .both-sides {
        width: 93px;
        height: 38px;
        border: 1px solid #409EFF;
        border-radius: 4px;
        color: #409EFF;
        font-size: 15px;
        text-align: center;
        line-height: 38px;
    }

    .operate-center {
        width: 345px;
        height: 40px;
        background: #409EFF;
        border-radius: 4px;
        color: #fff;
        font-size: 15px;
        text-align: center;
        line-height: 40px;
    }

    .url-content {
        width: 305px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .detail-w {
        width: 345px;
        margin: 0 auto;
    }


    /*底部栏*/
    .navIcon {
        height: 21px;
        width: 21px;
    }

    .person-top {
        background: #FFFFFF;
        box-shadow: 0 3px 10px 1px rgba(108, 157, 209, 0.16);
        border-radius: 10px;
        padding: 16px;
        margin-bottom: 15px;
    }

    .screen detail-w .table-index {
        border: none !important;
    }

    .contact-btn {
        flex-shrink: 0;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #409EFF;
        font-size: 15px;
        border-radius: 4px;
        border: none;
        color: #ffffff;
        margin-left: 10px;
        white-space: nowrap;
        padding: 0 10px;
    }

    .contact-btn2 {
        width: 100%;
        margin: 25px 0 0 0;
    } .search-term {
          padding-top: 15px;
      }

    .search-term-label {
        padding: 0 20px;
        color: #A1A7BA;
        font-size: 15px;
    }

    .search-term-detail {
        position: relative;
        width: 303px;
        margin: 10px auto 0;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .code-btn-box {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        width: 305px;
    }

    .code-see {
        text-align: center;
        font-size: 15px;
        color: #409EFF;
        width: 93px;
        height: 38px;
        line-height: 40px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #409EFF;
    }

    .code-edit {
        text-align: center;
        font-size: 15px;
        color: #FFFFFF;
        width: 93px;
        height: 38px;
        line-height: 40px;
        background: #409EFF;
        border-radius: 4px;
        border: 1px solid #409EFF;
    }

    .code-del {
        text-align: center;
        font-size: 15px;
        color: #F56C6C;
        width: 93px;
        height: 38px;
        line-height: 40px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #F56C6C;
    }

</style>
