<template>
<div style="width: 100%;height: 100%;background: #fff;position: absolute">
  <div class="not-found">
    <img src="../assets/not_found.png" alt="找不到页面">
  </div>
  <div class="not-found-text">找不到页面</div>
</div>
</template>

<style scoped>
  .not-found {
    width: 239px;
    height: 186px;
    margin: 140px auto 0;
  }

  .not-found img {
    width: 239px;
    height: 186px;
  }

  .not-found-text {
    margin-top: 5px;
    color: #333;
    font-size: 17px;
    text-align: center;
  }
</style>
