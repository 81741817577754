<template>
  <div v-if="topData.length>0">
    <!-- S tab栏 -->
    <div class="tab-daily">
      <van-tabs  title-active-color="#379CF5" title-inactive-color="#222222" @click-tab="handelTopTabChange">
        <van-tab v-for="(item,index) in topData" :title="item.user_name">
          <div class="tab-content">
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <!-- E tab栏 -->
    <div v-if="topActive==='顾问统计'">
      <!-- S 筛选条件部分 顾问统计 -->
      <div class="screen detail-w">
        <div class="search-term" style="padding-bottom: 0" v-if="companyStates">
          <div class="search-term-label">来源渠道</div>
          <div class="search-term-detail" @click="sourceShow">
            <van-field
              class="vant-input-edit"
              v-model="fieldValue"
              readonly
              placeholder="请选择来源渠道"
            />
            <div class="down-icon" v-if="clear_source_show">
              <caret-bottom/>
            </div>
            <div class="down-icon" @click.stop="clearableSource" v-else>
              <van-icon name="clear"/>
            </div>
          </div>
        </div>
        <div class="search-term" v-if="companyStates">
          <div class="search-term-label">资源类别</div>
          <div class="search-term-detail" @click="catPopup">
            <van-field class="vant-input-edit" v-model="cat_info" readonly placeholder="请选择资源类别"/>
            <div class="down-icon" v-if="clear_cat_show">
              <caret-bottom/>
            </div>
            <div class="down-icon" @click.stop="clearableCat" v-else>
              <van-icon name="clear"/>
            </div>
          </div>
        </div>
        <div class="search-term" v-if="companyStates">
          <div class="search-term-label">运营客服</div>
          <div class="search-term-detail" @click="enterPopup">
            <van-field class="vant-input-edit" v-model="enter_info" clearable readonly placeholder="请选择运营客服"/>
            <div class="down-icon" v-if="clear_enter_show">
              <caret-bottom/>
            </div>
            <div class="down-icon" @click.stop="clearableEnter" v-else>
              <van-icon name="clear"/>
            </div>
          </div>
        </div>
        <div class="search-term">
          <div class="search-term-label">跟单顾问</div>
          <div class="search-term-detail" @click="adviserPopup">
            <van-field class="vant-input-edit" v-model="screen_follow_adviser" readonly
                       placeholder="请选择跟单顾问"/>
            <div class="down-icon" v-if="clear_adviser_show">
              <caret-bottom/>
            </div>
            <div class="down-icon" @click.stop="clearableAdviser" v-else>
              <van-icon name="clear"/>
            </div>
          </div>
        </div>
        <div class="search-btn" @click="searchStates?'':search()" >
          <van-loading size="14px" color="#fff"  v-if="searchStates"/>
          <img src="../assets/search.png" alt="" v-else />
          搜索
        </div>
      </div>
      <!-- E 筛选条件部分 顾问统计 -->
        <van-loading size="24px" vertical v-if="loading">加载中...</van-loading>
        <div v-else>
          <div v-if="adviser_data&&adviser_data.length>0">
            <div class="screen detail-w" v-for="(info,index) in adviser_data" style="padding-bottom: 0;">
              <van-collapse accordion :border="false" ref="myCollapse">
                <van-collapse-item title="展开" :name="info.adviser" :border="false">
                  <template  #title>
                    <div class="top-collapse-title">
                      <div>{{info.counsel_name}}</div>
                      <div><span>总计</span> <span style="color:#409EFF;margin-left: 4px" >{{info.count}}</span></div>
                    </div>
                    <div class="top-collapse-text">
                      <van-row>
                        <van-col span="12"><span class="coll-label">已回访</span>{{info.visited_num}}</van-col>
                        <van-col span="12"><span class="coll-label">未回访</span>{{info.unvisited_num}}</van-col>
                        <van-col span="12"><span class="coll-label">捞取总数</span>{{info.qualified_num}}</van-col>
                        <van-col span="12"><span class="coll-label">捞取回访数</span>{{info.qualified_visited_num	}}</van-col>
                      </van-row>
                    </div>
                    <div :style="{display:info.isopen&&activeName===info.counsel_name?'block':'none'}">
                      <div class="tab-second">
                        <div v-for="item in FooterData" :class="info.select==item.user_name?'active-tab':''" @click="handelSelectTab(item,info,index)">
                          {{item.user_name}}
                        </div>
                      </div>
                      <div v-if="selectList(info)&&selectList(info).length>0">
                        <div v-for="item in selectList(info)" class="visit_info">
                          <van-row>
                            <van-col span="24"><span class="coll-label">客户序号</span>{{item.id}}</van-col>
                            <van-col span="24"><span class="coll-label">客户名称</span>{{item.user_name}}</van-col>
                            <van-col span="24"><span class="coll-label">客户等级</span>{{findGrade(item.customer_grade)}}</van-col>
                            <van-col span="24"><span class="coll-label">跟单顾问</span>{{item.counsel_name}}</van-col>
                            <van-col span="24"><span class="coll-label">资源类别</span>{{item.cat_name?item.cat_name:'-'}}</van-col>
                            <van-col span="24" v-if="companyStates"><span class="coll-label">来源渠道</span>{{item.source_name?item.source_name:'-'}}</van-col>
                            <van-col v-if="info.select==='已回访'" span="24" style="display: block">
                              <div class="coll-label-star">回访记录</div>
                              <div>
                                <div v-for="o in item.follow_content" style="margin: 14px 0;">
                                  <div class="follow-time">
                                    <span>回访时间</span>
                                    <span style="margin-left: 10px;">{{dateFormat(o.follow_time)}}</span>
                                  </div>
                                  <div>
                                    {{o.follow_content}}
                                  </div>
                                </div>
                              </div>
                            </van-col>
                          </van-row>
                        </div>
                      </div>
                      <div v-else class="empty">
                        <img src="../../static/empty.png">
                        <div>
                          暂无数据
                        </div>
                      </div>
                    </div>
                  </template>
                  <template  #right-icon>
                    <div class="expand" @click="changeCollapseAdviser(index)" :style="{display:info.isopen&&(activeName==info.counsel_name)?'none':'flex'}">
                      <img v-if="info.isopen" src="../../static/down.png">
                      <img v-else src="../../static/up.svg">
                      <span class="open-coll">
                      {{info.isopen?'收起':'展开'}}
                    </span>
                    </div>
                  </template>
                </van-collapse-item>
              </van-collapse>
            </div>
          </div>
          <div v-else class="empty">
            <img src="../../static/empty.png">
            <div>
              暂无数据
            </div>
          </div>
        </div>
    </div>
    <div v-if="topActive==='星标统计'">
      <!-- S 筛选条件部分 星标统计 -->
      <div class="screen detail-w">
        <div class="search-term">
          <div class="search-term-label">顾问名称</div>
          <div class="search-term-detail" @click="consultantPopup">
            <van-field class="vant-input-edit" v-model="consultant_info" readonly placeholder="请选择顾问名称"/>
            <div class="down-icon" v-if="clear_consultant_show">
              <caret-bottom/>
            </div>
            <div class="down-icon" @click.stop="clearableConsultant" v-else>
              <van-icon name="clear"/>
            </div>
          </div>
        </div>
        <div class="search-term">
          <div class="search-term-label">所属分公司</div>
          <div class="search-term-detail" @click="companyPopup">
            <van-field class="vant-input-edit" v-model="company_info" clearable readonly placeholder="请选择所属分公司"/>
            <div class="down-icon" v-if="clear_company_show">
              <caret-bottom/>
            </div>
            <div class="down-icon" @click.stop="clearableCompany" v-else>
              <van-icon name="clear"/>
            </div>
          </div>
        </div>
        <div class="search-term">
          <div class="search-term-label">意向签约项目</div>
          <div class="search-term-detail" @click="signPopup">
            <van-field class="vant-input-edit" v-model="sign_info" readonly
                       placeholder="请选择意向签约项目"/>
            <div class="down-icon" v-if="clear_sign_show">
              <caret-bottom/>
            </div>
            <div class="down-icon" @click.stop="clearableSign" v-else>
              <van-icon name="clear"/>
            </div>
          </div>
        </div>
        <div class="search-btn" @click="searchStarStates?'':searchStar()" >
          <van-loading size="14px" color="#fff"  v-if="searchStarStates"/>
          <img src="../assets/search.png" alt="" v-else />
          搜索
        </div>
      </div>
      <!-- E 筛选条件部分 星标统计 -->
      <van-loading size="24px" vertical v-if="star_loading">加载中...</van-loading>
      <div v-else>
        <div v-if="starData&&starData.length>0">
          <div class="screen detail-w" v-for="(info,index) in starData" style="padding-bottom: 0;">
                          <van-collapse accordion :border="false" ref="myCollapse">
                            <van-collapse-item title="展开" :name="info.counsel_name" :border="false">
                              <template  #title>
                                <div class="top-collapse-title">
                                  <div>{{info.counsel_name}}</div>
                                </div>
                                <div class="top-collapse-text">
                                  <van-row>
                                    <van-col span="12"><span class="coll-label">挂星数量</span>{{info.star_num}}</van-col>
                                    <van-col span="12"><span class="coll-label">摘星数量</span>{{info.pick_num}}</van-col>
<!--                      <van-col span="24"><span class="coll-label">星标客户签约总数量</span>{{info.star_sign_num}}</van-col>-->
                                  </van-row>
                                </div>
                  <div :style="{display:info.isopen&&(activeName_star==info.counsel_name)?'block':'none'}">
                                  <div class="tab-second" >
                                    <div v-for="item in FooterData" :class="info.select==item.user_name?'active-tab':''" @click="handelSelectTabStar(item,info,index)">
                                      {{item.user_name}}
                                    </div>
                                  </div>
                                  <div v-if="selectStarList(info)&&selectStarList(info).length>0">
                                    <div v-for="(item,index) in selectStarList(info)" class="visit_info">
                                      <van-row>
                                        <van-col span="24"><span class="coll-label-star">客户序号</span>{{item.id}}</van-col>
                                        <van-col span="24"><span class="coll-label-star">客户名称</span>{{item.user_name}}</van-col>
                                        <van-col span="24"><span class="coll-label-star">跟单顾问</span>{{item.counsel_name}}</van-col>
                                        <van-col span="24"><span class="coll-label-star">资源类别</span>{{item.cat_name}}</van-col>
<!--                          <van-col span="24">-->
<!--                            <van-col span="12"><span class="coll-label-star">优先级</span>{{findInformation(item.contact_information_first)}}</van-col>-->
<!--                            <van-col span="12"><span class="coll-label-star">客户等级</span>{{findGrade(item.customer_grade)}}</van-col>-->
<!--                          </van-col>-->
                                        <van-col span="24">
                                          <van-col span="12"><span class="coll-label-star">性别</span>
                                            <span class="user-sex" v-if="item.user_sex === 0">未知</span>
                                            <span class="user-sex" v-if="item.user_sex === 1">男</span>
                                            <span class="user-sex" v-if="item.user_sex === 2">女</span>
                                          </van-col>
                                          <van-col span="12"><span class="coll-label-star">年龄</span>{{item.birthday?item.birthday:'-'}}</van-col>
                                        </van-col>
                                        <van-col span="24"><span class="coll-label-star">学历</span>{{filterEducation(item.education)}}</van-col>
                                        <van-col span="24"><span class="coll-label-star">职业信息</span>{{item.work?item.work:'-'}}</van-col>
                                        <van-col span="24"><span class="coll-label-star">意向签约项目</span>{{item.intention_project?item.intention_project:'-'}}</van-col>
                                        <van-col span="24"><span class="coll-label-star">意向国家</span>{{item.intentional_state}}</van-col>
                                        <van-col span="24"><span class="coll-label-star">客户地址</span>{{item.address?item.address:'-'}}</van-col>
                                        <van-col span="24" v-if="companyStates"><span class="coll-label-star">来源渠道</span>{{item.source_name?item.source_name:'-'}}</van-col>
                                        <van-col span="24" v-if="info.select==='摘星'"><span class="coll-label-star" >挂星时间</span>{{dateFormat(item.start_time)}}</van-col>
<!--                          <van-col span="24" v-if="info.select==='摘星'">-->
                            <div v-if="info.select==='摘星'" class="coll-label-star" style="width: 100%">摘星原因</div>
                                          <div>
                                            <div v-for="o in item.reason">
                                              <div>
                                                {{o.title}}
                                              </div>
                                              <div>
                                                {{o.content}}
                                              </div>
                                            </div>
                                          </div>
<!--                          </van-col>-->
                                        <van-col span="24" style="display: block">
                            <div class="coll-label-star">回访记录</div>
                                          <div>
                              <div v-for="o in item.follow_content" style="margin: 14px 0;">
                                              <div class="follow-time">
                                                <span>回访时间</span>
                                                <span style="margin-left: 10px;">{{dateFormat(o.follow_time)}}</span>
                                              </div>
                                              <div>
                                                {{o.follow_content}}
                                              </div>
                                            </div>
                                          </div>
                                        </van-col>
                                      </van-row>
                                    </div>
                                  </div>
                                  <div v-else class="empty">
                                    <img src="../../static/empty.png">
                                    <div>
                                      暂无数据
                                    </div>
                                  </div>
                                </div>
                              </template>
                              <template  #right-icon>
                  <div class="expand" @click="changeCollapseAdviser(index)" :style="{display:info.isopen&&(activeName_star==info.counsel_name)?'none':'flex'}">
                                  <img v-if="info.isopen" src="../../static/down.png">
                                  <img v-else src="../../static/up.svg">
                                  <span class="open-coll">{{info.isopen?'收起':'展开'}}</span>
                                </div>
                              </template>
                            </van-collapse-item>
                          </van-collapse>
                        </div>
        </div>
        <div v-else class="empty">
          <img src="../../static/empty.png">
          <div>
            暂无数据
          </div>
        </div>
      </div>
    </div>
    <div v-if="topActive==='类别统计'">
      <div class="category-statistics">
        <div class="thead-category">
          <div class="thead-item" style="width: 85%">
            资源类别
          </div>
          <div class="thead-item" style="width: 15%">
            数量
          </div>
        </div>
<!--        <div class="line" style="margin-bottom: 0"></div>-->
        <van-loading size="24px" vertical v-if="category_loading">加载中...</van-loading>
        <div v-else>
          <div v-if="category_list&&category_list.length>0">
            <div v-for="item in category_list">
              <div class="tbody-category" >
                <div class="td-item" style="width: 85%">
                  {{item.name}}
                </div>
                <div class="td-item" style="width: 15%">
                  {{item.count}}
                </div>
              </div>
<!--              <div class="line" style="margin-bottom: 0"></div>-->
            </div>
          </div>
          <div v-else class="empty" style="margin-bottom: 0">
            <img src="../../static/empty.png">
            <div>
              暂无数据
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="topActive==='来源统计'">
        <van-loading size="24px" vertical v-if="scoure_loading">加载中...</van-loading>
        <div v-else>
          <div v-if="scoure_list&&scoure_list.length>0">
            <div class="screen detail-w" v-for="(info,index) in scoure_list" style="padding-bottom: 0;">
              <van-collapse accordion :border="false" ref="myCollapse">
                <van-collapse-item title="展开" :name="info.name" :border="false">
                  <template  #title>
                    <div class="source-top">
                      <div>来源渠道</div>
                      <div>数量</div>
          </div>
                    <div :class="info.isopen&&(activeName_scource==info.name)?'source-title':'source-open'" >
                      <div class="source-title-name">{{info.name}}</div>
                      <div>{{info.count}}</div>
          </div>
                    <div :style="{display:info.isopen&&(activeName_scource==info.name)?'block':'none'}">
                      <div v-if="info.records&&info.records.length>0">
                        <div class="line"></div>
                        <div v-for="(item,index) in info.records" class="visit_info">
                          <van-row>
                            <van-col span="24"><span class="coll-label">客户序号</span>{{item.id}}</van-col>
                            <van-col span="24"><span class="coll-label">客户等级</span>{{findGrade(item.customer_grade)}}</van-col>
                            <van-col span="24"><span class="coll-label">客户名称</span>{{item.user_name}}</van-col>
                            <van-col span="24"><span class="coll-label">跟单顾问</span>{{item.counsel_name}}</van-col>
                            <van-col span="24"><span class="coll-label">资源类别</span>{{item.cat_name}}</van-col>
                            <van-col span="24" v-if="companyStates"><span class="coll-label">来源渠道</span>{{item.source_name?item.source_name:'-'}}</van-col>
                            <van-col span="24" style="display: block">
                              <div class="coll-label" style="display: inline-block">回访记录</div>
                              <div v-if="item.follow_content&&item.follow_content.length>0">
                                <div v-for="o in item.follow_content" style="margin: 14px 0;">
                                  <div class="follow-time">
                                    <span>回访时间</span>
                                    <span style="margin-left: 10px;">{{dateFormat(o.follow_time)}}</span>
        </div>
                                  <div>
                                    {{o.follow_content}}
                </div>
                </div>
              </div>
                              <div v-else style="display: inline-block">-</div>
                            </van-col>
                          </van-row>
            </div>
          </div>
          <div v-else class="empty">
            <img src="../../static/empty.png">
            <div>
              暂无数据
            </div>
          </div>
        </div>
                  </template>
                  <template  #right-icon>
                    <div class="expand" @click="changeCollapseSource(index)" :style="{display:info.isopen&&(activeName_scource==info.name)?'none':'flex'}">
                      <img v-if="info.isopen" src="../../static/down.png">
                      <img v-else src="../../static/up.svg">
                      <span class="open-coll">{{info.isopen?'收起':'展开'}}</span>
                    </div>
                  </template>
                </van-collapse-item>
              </van-collapse>
            </div>
          </div>
          <div v-else class="empty" style="margin-bottom: 0">
            <img src="../../static/empty.png">
            <div>
              暂无数据
            </div>
          </div>
      </div>
    </div>
    <!-- S 来源渠道弹层 -->
    <van-popup v-model:show="source_show" position="bottom">
      <van-cascader
        v-if="source_visible"
        v-model="cascaderValue"
        title="请选择来源渠道"
        :options="options"
        :field-names="sourceNames"
        @close="source_show = false"
        @finish="onFinish"
      />
    </van-popup>
    <!-- E 来源渠道弹层 -->
    <!-- S 资源类别弹层 -->
    <search
      :visable="cat_type_show"
      :height="'48%'"
      :placeholder="'请搜索'"
      :list="cat_type_data"
      :valueName="'title'"
      :title="'请选择资源类别'"
      :onConfirm="catConfirm"
      :onCancel="catCancel"
      />
<!--    <van-popup v-model:show="cat_type_show" position="bottom" :style="{ height: '48%' }">-->
<!--      <van-picker-->
<!--        title="请选择资源类别"-->
<!--        show-toolbar-->
<!--        :columns="cat_type_data"-->
<!--        @confirm="catConfirm"-->
<!--        @cancel="catCancel"-->
<!--      />-->
<!--    </van-popup>-->
    <!-- E 资源类别弹层 -->
    <!-- S 运营客服弹层 -->
    <search
      :visable="enter_show"
      :height="'48%'"
      :placeholder="'请搜索'"
      :list="enter_data"
      :valueName="'user_name'"
      :title="'请选择运营客服'"
      :onConfirm="enterConfirm"
      :onCancel="enterCancel"
      />
<!--    <van-popup v-model:show="enter_show" position="bottom" :style="{ height: '48%' }">-->
<!--      <van-picker-->
<!--        title="请选择运营客服"-->
<!--        show-toolbar-->
<!--        :columns="enter_data"-->
<!--        @confirm="enterConfirm"-->
<!--        @cancel="enterCancel"-->
<!--      />-->
<!--    </van-popup>-->
    <!-- E 运营客服弹层 -->
    <!-- S 跟单顾问弹层 -->
    <search
        :visable="follow_adviser_show"
        :height="'48%'"
        :placeholder="'请搜索'"
        :list="follow_adviser_data"
        :valueName="'user_name'"
        :title="'请选择跟单顾问'"
        :onConfirm="adviserConfirm"
        :onCancel="adviserCancel"
      />
<!--    <van-popup v-model:show="follow_adviser_show" position="bottom" :style="{ height: '48%' }">-->
<!--      <van-picker-->
<!--        title="请选择跟单顾问"-->
<!--        show-toolbar-->
<!--        :columns="follow_adviser_data"-->
<!--        @confirm="adviserConfirm"-->
<!--        @cancel="adviserCancel"-->
<!--      />-->
<!--    </van-popup>-->
    <!-- E 跟单顾问弹层 -->
    <!-- S 顾问名称弹层 -->
    <search
      :visable="consultant_show"
      :height="'48%'"
      :placeholder="'请搜索'"
      :list="follow_adviser_data"
      :valueName="'user_name'"
      :title="'请选择跟单顾问'"
      :onConfirm="consultantConfirm"
      :onCancel="consultantCancel"
      />
<!--    <van-popup v-model:show="consultant_show" position="bottom" :style="{ height: '48%' }">-->
<!--      <van-picker-->
<!--        title="请选择跟单顾问"-->
<!--        show-toolbar-->
<!--        :columns="follow_adviser_data"-->
<!--        @confirm="consultantConfirm"-->
<!--        @cancel="consultantCancel"-->
<!--      />-->
<!--    </van-popup>-->
    <!-- E 顾问名称弹层 -->
    <!-- S 所属分公司弹层 -->
    <search
      :visable="company_show"
      :height="'48%'"
      :placeholder="'请搜索'"
      :list="companyData"
      :valueName="'city'"
      :title="'请选择所属分公司'"
      :onConfirm="companyConfirm"
      :onCancel="companyCancel"
      />
<!--    <van-popup v-model:show="company_show" position="bottom" :style="{ height: '48%' }">-->
<!--      <van-picker-->
<!--        title="请选择所属分公司"-->
<!--        show-toolbar-->
<!--        :columns="companyData"-->
<!--        @confirm="companyConfirm"-->
<!--        @cancel="companyCancel"-->
<!--      />-->
<!--    </van-popup>-->
    <!-- E 所属分公司弹层 -->
    <!-- S 签约项目弹层 -->
    <search
      :visable="sign_show"
      :height="'48%'"
      :placeholder="'请搜索'"
      :list="sign_data"
      :valueName="'title'"
      :title="'请选择意向签约项目'"
      :onConfirm="signConfirm"
      :onCancel="signCancel"
      />
<!--    <van-popup v-model:show="sign_show" position="bottom" :style="{ height: '48%' }">-->
<!--      <van-picker-->
<!--        title="请选择意向签约项目"-->
<!--        show-toolbar-->
<!--        :columns="sign_data"-->
<!--        @confirm="signConfirm"-->
<!--        @cancel="signCancel"-->
<!--      />-->
<!--    </van-popup>-->
    <!-- E 签约项目弹层 -->
    <div :style="{display:getStarShow&&topActive==='星标统计'?'block':'none',width:'100%'}" class="up-star" id="up-expand" >
      <div class="up-expand" @click="handelStarUp">
        <img src="../../static/down.png">
        <span class="open-coll">收起</span>
      </div>
    </div>
    <div :style="{display:getShow&&topActive==='顾问统计'?'block':'none',width:'100%'}" class="up-star" id="up-expand" >
      <div class="up-expand" @click="handelUp">
        <img src="../../static/down.png">
        <span class="open-coll">收起</span>
      </div>
    </div>
    <div :style="{display:getSourceShow&&topActive==='来源统计'?'block':'none',width:'100%'}" class="up-star" id="up-expand" >
      <div class="up-expand" @click="handelSourceUp">
        <img src="../../static/down.png">
        <span class="open-coll">收起</span>
      </div>
    </div>
    <!--  回到顶部  -->
    <div class="backTop"  v-show="scrollType" @click.stop="backTop">
      <img src="../../static/back-top.png" width="10" height="9">
      <div class="back-info">
        <div class="back-info1">
          回到
        </div>
        <div class="back-info2">
          顶部
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  userSourceList,//来源下拉
  categoryList,//类别下拉
  enterDown,//运营客服下拉
  getAdviserList,//顾问下拉
  branchOfficesJava,//所属分公司下拉
  downProject,//签约项目下拉
  saleDailyStatistics, // 顾问统计数据
  catDailyStatistics, //类别统计
  sourceDailyStatistics, //来源统计
  starDailyStatistics,//星标统计
  userDailyInfo
} from '../api/java'
import {Notify} from "vant";
import moment from "moment";
import search from '../unit/search.vue'
export default {
  components: {search},
  data() {
    return {
      scrollType: false, // 控制按钮显示和隐藏
      // timerId: null,
      scrollTop: 0,
      topData: [],
      topActive: '顾问统计',//顶部tab切换

      /* ---- 来源渠道部分 ---- */
      fieldValue:'',
      source_visible: false, // 来源渠道级联选择部分控制
      clear_source_show: true, // 来源渠道重置按钮
      source_show: false, // 来源渠道popup弹窗控制
      source: '', // 接口传参所用数据
      cascaderValue: '',
      sourceNames: {  // 来源渠道自定义字段
        text: 'title',
        value: 'id',
        children: 'children'
      },
      options: [], // 来源渠道数据

      /* ---- 资源类别部分 ---- */
      cat_type_show: false, // 跟单顾问弹出层 catPopup
      cat_info: '', // 资源类别
      cat_type_id: '', // 资源类别id
      cat_type_data: [], // vant下拉数据格式(资源类别)
      request_cat_data: [], // 接口返回数据格式(资源类别)
      clear_cat_show: true, // 资源类别重置按钮

      /* ----运营客服部分 ---- */
      enter_id: '', // 运营客服id
      enter_show: false, // 运营客服弹出层
      enter_info: '', // 运营客服
      request_enter_data: [], // 接口返回运营客服数据
      enter_data: [], // vant 运营客服数据形式
      clear_enter_show: true, // 录入

      /* ---- 跟单顾问部分 ---- */
      follow_adviser_show: false, // 跟单顾问弹出层
      screen_follow_adviser: '', // 跟单顾问
      follow_adviser_id: '', // 跟单顾问id
      follow_adviser_data: [], // vant下拉数据格式(顾问列表)
      request_adviser_data: [], // 接口返回数据格式(顾问列表)
      clear_adviser_show:true,//清除显示

      /* ---- 顾问名称部分 ----*/
      consultant_show:false,// 顾问名称弹出层
      consultant_info:'',//顾问名称
      consultant_id: '', // 顾问id
      clear_consultant_show:true,//消除显示

      /* ---- 所属分公司部分 ---- */
      companyData:[],//分公司列表
      company_info:'',// 分公司回显
      company_show:false,//分公司弹出显示
      clear_company_show:true,//清除分公司

      /* ---- 签约项目部分 ---- */
      sign_data: [],//签约项目下拉数据
      request_sign_data:[],//获取签约项目接口下拉数据
      sign_info:'',// 签约项目回显
      sign_project_id:'',//签约项目id
      sign_show:false,//签约项目弹出显示
      sign_id:'',//签约项目id
      clear_sign_show:true,//清除签约项目

      FooterData: [
        {id: 2, user_name: '已回访'},
        {id: 1, user_name: '未回访'},
      ],

      /* ---- 顾问统计列表部分 ---- */
      adviser_data:[],//顾问统计数据
      loading: true,// 页面显示状态
      searchStates: false, // 搜索按钮状态--顾问统计

      /* ---- 星标统计列表部分 ---- */
      starData:[],
      searchStarStates:false,//星标统计搜索
      star_loading:false,//星标统计列表loading

      /* ---- 类别统计列表部分 ---- */
      category_loading:false,//列表loading状态
      category_list:[],//类别统计数据

      /* ---- 来源统计列表部分 ---- */
      scoure_list:[],//来源统计数据
      scoure_loading:false,//列表loading状态
      activeName_scource:'',//来源统计展开

      activeName: '', // 底部tab选中
      activeName_star:'',//星标统计展开的

      companyStates:false,// true 沈阳 false 深圳
      // 底部tab数据集合
      urlPage: '',//url参数配置
      company_id:'',//接口获取company
      date:'',//时间
      url:'',//url
      search_follow_adviser:'',
    }
  },
  created() {
    if(this.$route.query.user_id&&this.$route.query.company&&this.$route.query.date){
        this.$router.push({
            path: '/errorPageNew',
            query: {
                message: '系统提示：日报功能升级，今日数据无法兼容展示，请查看19点30分或20点日报内容。', // vue路由传递对象
            }
        })
        return;
    }
    if (!sessionStorage.getItem('file_name')) {
      sessionStorage.setItem('file_name', this.$route.query.url) // 存储文件名
    }
    let time =this.$route.query.url
    let login_url = process.env.VUE_APP_BASE_URL_JAVA  // 网页授权接口(正式)
    let wx_url = login_url+'/crm/crmMobileLogin?type=1&time='+time
    let dd_url = login_url+'/crm/dingTalkLogin'
      // 正式钉钉参数 dingy8vbkbckjgrpdlf0
    if (this.$route.query.type === 'dd'&&!this.$route.query.token) {
        location.href = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=dingy8vbkbckjgrpdlf0&response_type=code&scope=snsapi_auth&state=1-${time}&redirect_uri=` + dd_url
        return;
    }
    // 正式微信参数 ww1e03ca55a309cbb1
    if (this.$route.query.type === 'wx'&&!this.$route.query.token) {
      location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww1e03ca55a309cbb1&redirect_uri='+ encodeURIComponent(wx_url) +'&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
      return;
    }
    if (this.$route.query.company && this.$route.query.token) {
      sessionStorage.setItem('postID', this.$route.query.post) // 存储  post 权限(0:管理员; 1:运营;2:顾问;)token信息
      sessionStorage.setItem('token', "bearer " + this.$route.query.token) // 存储token信息
      sessionStorage.setItem('url_java', this.$route.query.url) // 存储用户id
      // if(this.$route.query.option === 'wx'){
      //   localStorage.setItem('style', 'weChat') // 存储登录方式
      // }else if(this.$route.query.option === 'dd'){
      //   localStorage.setItem('style', 'ding') // 存储登录方式
      // }
    }
    this.url=this.$route.query.url // 存储用户id

    this.urlPage = sessionStorage.getItem('file_name')
    if(sessionStorage.getItem('token')) {
      if (this.$route.query.company == '3') { // 沈阳公司
        this.companyStates = true // 沈阳
        this.getSource()   // 获取来源渠道
        this.getCatData() // 资源类别数据
        this.getEnterData()//获取运营客服
      } else { //深圳
        this.companyStates = false
      }
      if (this.$route.query.post == '2') {      //销售分总 顾问
        this.topData = [
          {id: 3, user_name: '顾问统计'},
          {id: 1, user_name: '类别统计'},
          {id: 4, user_name: '星标统计'},
        ]
      } else if (this.$route.query.post == '1') { //运营客服
        this.topData = [
          {id: 3, user_name: '顾问统计'},
          {id: 1, user_name: '类别统计'},
          {id: 2, user_name: '来源统计'},
        ]
      } else if (this.$route.query.post == '0') { //管理员
      this.topData = [
        {id: 3, user_name: '顾问统计'},
        {id: 1, user_name: '类别统计'},
          {id: 2, user_name: '来源统计'},
        {id: 4, user_name: '星标统计'},
      ]
    }
    this.getAdviserData()//获取跟单顾问
    this.getTableData() // 获取页面表格数据请求
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScoll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScoll)
    clearInterval(this.timerId)
  },
  computed:{
    getStarShow(){
      return  this.starData.some(i=>i.isopen)
    },
    getShow(){
      return  this.adviser_data.some(i=>i.isopen)
    },
    getSourceShow(){
      return  this.scoure_list.some(i=>i.isopen)
    }
  },
  methods: {
    sourceShow() {
      this.source_show = true; // 来源渠道popup弹窗控制
      this.source_visible = true // 来源渠道级联选择部分控制
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({selectedOptions}) {
      this.source_show = false;
      this.fieldValue = selectedOptions.map((option) => option.title).join('/');
      let source_arr = []
      for (let i = 0; i < selectedOptions.length; i++) {
        source_arr.push(selectedOptions[i].id)
      }
      this.source = source_arr.toString() // 来源渠道赋值(请求数据时候用)
      this.clear_source_show = false // 切换重置图标
    },
    // 来源渠道重置
    clearableSource() {
      this.source_visible = false // 来源渠道级联选择部分控制
      this.fieldValue = '' // 清空来源渠道回显
      this.source = '' // 清空来源渠道传递参数
      this.cascaderValue = '' // 重置级联选择器
      this.clear_source_show = true // 切换重置图标
    },

    // 来源渠道数据
    getSource() {
      userSourceList().then(data => {
        if(data){

          if (data.code === 200) {
            this.options = data.data
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }
      }).catch((e) => {
        console.log(e)
        Notify({type: 'danger', message: '服务连接失败'});
      })
    },

    // 资源类别弹层控制
    catPopup() {
      this.cat_type_show = true
    },
    // 资源类别重置
    clearableCat() {
      this.cat_info = '' // 清空资源类别回显
      this.cat_type_id = '' // 清空请求时参数
      this.clear_cat_show = true // 切换图标
    },
    // 资源类别列表数据
    getCatData() {
      categoryList().then(data => {
        // 判断token
        if (data.code === 200) {
          this.request_cat_data = data.data
          this.cat_type_data=data.data
          // for (let i = 0; i < this.request_cat_data.length; i++) {
          //   this.cat_type_data.push(this.request_cat_data[i].title)
          // }
        }
      }).catch(() => {
        console.log(e)
        Notify({type: 'danger', message: '服务连接失败'});
      })
    },
    // 确认赋值
    catConfirm(value) {
      if(value){
        this.cat_info = value.title // 顾问赋值
      // 获取下拉数据id (资源类别)
      for (let i = 0; i < this.request_cat_data.length; i++) {
          if (value.title === this.request_cat_data[i].title) {
          this.cat_type_id = this.request_cat_data[i].id
        }
      }
        this.clear_cat_show = false // 切换图标
      }
      this.cat_type_show = false // 关闭弹层
      this.search_follow_adviser=''
    },
    // 取消选择
    catCancel() {
      this.cat_type_show = false // 关闭弹层
      this.search_follow_adviser=''
    },
    /** 运营客服(搜索部分) **/
    enterPopup() {
      this.enter_show = true
    },
    // 运营客服重置
    clearableEnter() {
      this.enter_info = '' // 清空请求时参数
      this.clear_enter_show = true // 切换重置图标
      this.enter_id=''
    },
    // 确认赋值
    enterConfirm(value, index) {
      if(value){
        this.enter_info = value.user_name // 顾问赋值
      // 根据选中顾问获取对应顾问id
      for (let i = 0; i < this.request_enter_data.length; i++) {
        if (index === i) {
          this.enter_id = this.request_enter_data[i].id // 顾问id赋值
        }
      }
        this.clear_enter_show = false // 切换重置图标
      }
      this.enter_show = false // 关闭弹层

    },
    // 取消选择
    enterCancel() {
      this.enter_show = false // 关闭弹层
    },
    // 获取运营客服
    getEnterData() {
      enterDown().then(data => {
        if(data){
          // 判断token
          if (data.code === 200) {
            this.request_enter_data = data.data
            this.enter_data=data.data
            // 请求数据获取顾问数组格式化为vant下拉数组格式
            // for (let i = 0; i < this.request_enter_data.length; i++) {
            //   this.enter_data.push(this.request_enter_data[i].user_name) // vant 下拉数组赋值
            // }
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }
      }).catch((e) => {
        console.log(e)
        Notify({type: 'danger', message: '服务连接失败'});
      })
    },
    // 跟单顾问弹层控制
    adviserPopup() {
      this.follow_adviser_show = true
    },
    // 跟单顾问确认赋值
    adviserConfirm(value) {
      if(value){
        this.screen_follow_adviser = value.user_name // 顾问赋值
      // 获取下拉数据id (跟单顾问)
      for (let i = 0; i < this.request_adviser_data.length; i++) {
          if (value.user_name === this.request_adviser_data[i].user_name) {
          this.follow_adviser_id = this.request_adviser_data[i].id
        }
      }
        this.clear_adviser_show = false // 切换图标
      }
      this.follow_adviser_show = false // 关闭弹层
    },
    // 取消选择
    adviserCancel() {
      this.follow_adviser_show = false // 关闭弹层
    },
    // 跟单顾问重置
    clearableAdviser() {
      this.screen_follow_adviser = '' // 清空跟单顾问回显
      this.follow_adviser_id = '' // 清空请求时参数
      this.clear_adviser_show = true // 切换图标
    },
    // 顾问列表数据
    getAdviserData() {
      getAdviserList().then(data => {
        // 判断token
        if (data.code === 200) {
          this.request_adviser_data = data.data
          this.follow_adviser_data=data.data
          // for (let i = 0; i < this.request_adviser_data.length; i++) {
          //   this.follow_adviser_data.push(this.request_adviser_data[i].user_name)
          // }
        }else{
          Notify({type: 'danger', message: data.message});
        }
      }).catch((e) => {
        console.log(e)
        Notify({type: 'danger', message: '服务连接失败'});
      })
    },

    // 顾问名称弹层控制
    consultantPopup(){
      this.consultant_show = true
    },
    // 清除顾问名称
    clearableConsultant(){
      this.consultant_info = '' // 清空跟单顾问回显
      this.consultant_id = '' // 清空请求时参数
      this.clear_consultant_show = true // 切换图标
    },
    // 确认赋值
    consultantConfirm(value){
      if(value){
        this.consultant_info = value.user_name // 顾问赋值
      // 获取下拉数据id (跟单顾问)
      for (let i = 0; i < this.request_adviser_data.length; i++) {
          if (value.user_name === this.request_adviser_data[i].user_name) {
          this.consultant_id = this.request_adviser_data[i].id
        }
      }
        this.clear_consultant_show = false // 切换图标
      }
      this.consultant_show = false // 关闭弹层

    },
    // 取消选择
    consultantCancel(){
      this.consultant_show = false // 关闭弹层
    },

    // 所属分公司列表数据
    getBranchOffices() {
      branchOfficesJava().then(data => {
        if(data){
          // 判断token
          if (data.code === 200) {
            this.companyData = data.data
            // for (let i = 0; i < companyData.length; i++) {
            //   this.companyData.push(companyData[i].city)
            // }
          }else{
            Notify({type: 'danger', message: data.message});
          }
        }
      }).catch((e) => {
        console.log()
        Notify({type: 'danger', message: '服务连接失败'});
      })
    },

    // 所属分公司弹层控制
    companyPopup(){
      this.company_show = true
    },

    // 清除所属分公司
    clearableCompany(){
      this.company_info = '' // 清空所属分公司回显
      this.clear_company_show = true // 切换图标
    },

    // 所属分公司确认赋值
    companyConfirm(value){
      if(value){
        this.company_info = value.city // 所属分公司赋值
        this.clear_company_show = false // 切换图标
      }
      this.company_show = false // 关闭弹层

    },
    // 取消选择
    companyCancel(){
      this.company_show = false // 关闭弹层
    },

    // 签约项目列表数据
    signedProjectList() {
      downProject().then(data => {
        // 判断token
        if (data.code === 200) {
          this.sign_data = data.data.data
          this.request_sign_data= data.data.data
          // for (let i = 0; i < signData.length; i++) {
          //   this.sign_data.push(signData[i].title)
          // }
        }else{
          Notify({type: 'danger', message: data.message});
        }
      }).catch((e) => {
        console.log(e)
        Notify({type: 'danger', message: '服务连接失败'});
      })
    },

    // 签约项目弹层控制
    signPopup(){
      this.sign_show = true
    },
    // 清除签约项目选中
    clearableSign(){
      this.sign_info = '' // 清空签约项目回显
      this.sign_project_id=''//清空签约项目传给后端id
      this.clear_sign_show = true // 切换图标
    },
    // 确认签约项目赋值
    signConfirm(value){
      if(value){
        this.sign_info = value.title // 签约项目赋值
      for (let i = 0; i < this.request_sign_data.length; i++) {
          if (value.title === this.request_sign_data[i].title) {
          this.sign_project_id = this.request_sign_data[i].id
        }
      }
      this.clear_sign_show = false // 切换图标
      }
      this.sign_show = false // 关闭弹层

    },
    // 取消选择
    signCancel(){
      this.sign_show = false // 关闭弹层
    },
    // 请求类别统计列表
    // 顾问统计 顾问折叠面板控制
    changeCollapseAdviser(index){
      if(this.topActive==='顾问统计'){
        this.adviser_data.forEach((i)=>{
          i.isopen=false
        })
        this.adviser_data[index].isopen=!this.adviser_data[index].isopen
        this.activeName=this.adviser_data[index].counsel_name
      }else{
        this.starData.forEach((i)=>{
          i.isopen=false
        })
        this.starData[index].isopen=!this.starData[index].isopen
        this.activeName_star=this.starData[index].counsel_name
      }
    },
    changeCollapseSource(index){
      this.scoure_list.forEach((i)=>{
        i.isopen=false
      })
      this.scoure_list[index].isopen=!this.scoure_list[index].isopen
      this.activeName_scource=this.scoure_list[index].name
    },
    // 顾问统计内部tab切换
    handelSelectTab(val,row,index){
        this.adviser_data[index].select=val.user_name
    },
    // 星标统计内部tab切换
    handelSelectTabStar(val,row,index){
      this.starData[index].select=val.user_name
    },
    // 顶部tab切换
    handelTopTabChange(val) {
      this.topActive = val.title
      this.adviser_data = this.adviser_data.map((item)=>{
        return {...item,isopen:false}
      })
      // 顾问统计
      this.clearableSource()
      this.clearableCat()
      this.clearableEnter()
      this.clearableAdviser()
      // 星标统计
      this.clearableConsultant()
      this.clearableCompany()
      this.clearableSign()
      if(val.title=='类别统计'){
        this.catStatistics()
        return
      }
      if(val.title=='来源统计'){
        this.sourceStatistics()
        return
      }
      if (val.title == '星标统计') {
        this.FooterData=[
          {id: 2, user_name: '挂星'},
          {id: 1, user_name: '摘星'},
        ]
        this.getAdviserData()//获取跟单顾问
        this.getBranchOffices()//所属分公司
        this.signedProjectList()//意向签约项目
        this.getStarTableData()
        return
      }else{
        this.FooterData=[
          {id: 2, user_name: '已回访'},
          {id: 1, user_name: '未回访'},
        ]
        if(this.companyStates == true){
          this.getSource()   // 获取来源渠道
          this.getCatData() // 资源类别数据
          this.getEnterData()//获取运营客服
        }
        this.getAdviserData()//获取跟单顾问
        this.getTableData()
        return
      }
    },
    // 获取顾问统计数据
    getTableData(states) {
      let para = {
        follow_counsel:this.follow_adviser_id,//顾问名称
        cat_id: this.cat_type_id,//资源类别
        source: this.source,//来源渠道
        official_service: this.enter_id,//运营客服
        url:this.url,//时间
      }
      this.loading = true
      saleDailyStatistics(para).then(data => {
        if (data.code === 200) {
          let adviser_data = data.data.data
          adviser_data.unshift(data.data.total)
          this.adviser_data=adviser_data.map(item => {
          return { ...item, isopen:false,select:'已回访'};
          })
          this.loading = false
          if (states === true) {
            this.searchStates = false
            Notify({type: 'success', message:'搜索成功'})
          }
        } else {
          this.loading = false
          if (states === true) {
            this.searchStates = false
          }
          Notify({type: 'danger', message: data.message})
        }
      }).catch((e) => {
        console.log(e)
        if (states === true) {
          this.searchStates = false
        }
        this.loading = false
        Notify({type: 'warning', message: '服务器连接失败'})
      })
    },
    // 点击搜索顾问统计的数据
    search() {
      // this.searchStarStates=true
      this.searchStates = true
      this.getTableData(true)
    },
    // 获取星标统计数据
    getStarTableData(states) {
      let para = {
        follow_counsel: this.consultant_id,//跟单顾问
        company: this.company_info,//所属公司
        project: this.sign_project_id,//签约项目
        url:this.url,//时间
      }
      this.star_loading = true
      starDailyStatistics(para).then(data => {
        if (data.code === 200) {
          this.star_loading = false
          let star_data = data.data.data
          star_data.unshift(data.data.total)
          this.starData=star_data.map(item => {
            return { ...item, isopen:false,select:'挂星'};
          })
          if (states === true) {
            this.searchStarStates = false
            Notify({type: 'success', message:'搜索成功'})
          }
        } else {
          this.star_loading = false
          if(states === true){
            this.searchStarStates=false
          }
          Notify({type: 'danger', message: data.message})
        }
      }).catch((e) => {
        this.star_loading = false
        if(states === true){
          this.searchStarStates=false
        }
        Notify({type: 'warning', message: '服务器连接失败'})
      })
    },
    // 点击搜索星标统计的数据
    searchStar(){
      this.searchStarStates=true
      this.getStarTableData(true)
    },
    // 类别统计
    catStatistics(){
      this.category_loading=true
      catDailyStatistics({url:this.url}).then(data => {
        if (data.code === 200) {
          this.category_list=data.data
          this.category_loading=false

        } else {
          Notify({type: 'danger', message: data.message})
          this.category_loading=false
        }
      }).catch((e) => {
        console.log(e)
        Notify({type: 'warning', message: '服务器连接失败'})
        this.category_loading=false
      })
    },
  //   来源统计
    sourceStatistics(){
      this.scoure_loading=true
      sourceDailyStatistics({url:this.url}).then(data => {
        if (data.code === 200) {
          this.scoure_list=data.data.map(item => {
            return { ...item, isopen:false};
          })
          this.scoure_loading=false
        } else {
          this.scoure_loading=false
          Notify({type: 'danger', message: data.message})
        }
      }).catch((e) => {
        this.scoure_loading=false
        Notify({type: 'warning', message: '服务器连接失败'})
      })
    },
    // 获取已回访未回访点击切换的数据--顾问统计
    selectList(row){
      if(row.select=='已回访'){
        return row.visited_records
      }else{
        return row.unvisited_records
      }
    },
    // 获取已回访未回访点击切换的数据--星标统计
    selectStarList(row){
      if(row.select=='挂星'){
        return row.star_records
      }else{
        return row.pick_records
      }
    },
    filterEducation(id){
      switch (id){
        case 1:
          return '博士';
        case 2:
          return '硕士';
        case 3:
          return '本科';
        case 4:
          return '专科';
        case 5:
          return '高中及以下';
        default:
          return '未知'
      }
    },
    findInformation(id){
      switch (id){
        case 0:
          return '电话';
        case 1:
          return '微信';
        case 2:
          return '邮箱';
        case 3:
          return 'QQ';
        case 4:
          return 'WatsApp';
        default:
          return '-'
      }
    },
    findGrade(id){
      switch (id){
        case 1:
          return 'A';
        case 2:
          return 'B';
        case 3:
          return 'C';
        case 4:
          return 'D';
        case 5:
          return 'E';
        case 6:
          return '无效';
        case 7:
          return '未分级';
        default:
          return '-'
      }
    },
    handelStarUp(){
      let up_index =this.starData.findIndex(i=>i.counsel_name==this.activeName_star)
      this.starData[up_index].isopen=false
    },
    handelUp(){
      let up_index =this.adviser_data.findIndex(i=>i.counsel_name==this.activeName)
      this.adviser_data[up_index].isopen=false
    },
    handelSourceUp(){
      let up_index =this.scoure_list.findIndex(i=>i.name==this.activeName_scource)
      this.scoure_list[up_index].isopen=false
    },
    dateFormat(date) {
      if (!date) {
        return "";
      }
      return moment(parseInt(date) * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
    backTop () {
      // this.timerId = setInterval(() => {
        // 给返回增加动画效果
        // const upSpeed = Math.floor(-this.scrollTop / 5)
        // document.documentElement.scrollTop = this.scrollTop + upSpeed
        document.documentElement.scrollTop =0
      // if (this.scrollTop === 0) {
        //   clearInterval(this.timerId)
        // }
      // }, 30)
    },
    handleScoll (ev) {
      // 当滚动的距离大于50 时出现该按钮
      this.scrollTop = window.pageYOffset
      if (window.pageYOffset / 50 > 1) {
        this.scrollType = true
      } else {
        this.scrollType = false
      }
    },
  },
}
</script>

<style scoped>
/* S -- 销售日报m -- */
.header-back{
    height: 53px;
    line-height: 53px;
    background: #fff;
    position: relative;
}
.header-back i{
    position: absolute;
    left: 15px;
    top: 50%;
    transform:translateY(-50%) ;
}
.header-title{
    width: 100%;
    text-align: center;
    font-size: 19px;
    color: #222222;
}
.tab-daily>>>.van-tabs__wrap{
  height: 54px;
}
.tab-daily>>>.van-tab__text{
    font-size: 17px;
}
.tab-daily>>>.van-tabs__line{
    bottom:22px;
    width: 13px;
    background: #379CF5;
}
.tab-daily>>>.van-tab{
    flex: none;
    padding:0;
}
.tab-daily>>>.van-tabs__nav{
    gap: 25px;
    padding-left: 15px;
}
/* E -- 销售日报m -- */
/* ---- 筛选部分 ---- */
.detail-w {
    width: 345px;
    margin: 0 auto;
}
.detail-w >>>.van-cell{
    position: static;
    background: transparent;
    display: block;
    padding: 5px 20px 15px 20px;
}

.screen:last-child{
    margin-bottom: 15px;
}
.screen {
    position: relative;
    margin-top: 15px;
    padding-bottom: 25px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
}

.search-term {
    padding-top: 15px;
}

.search-term-label {
    padding: 0 20px;
    color: #A1A7BA;
    font-size: 15px;
}

.search-term-detail {
    position: relative;
    width: 303px;
    height: 38px;
    margin: 10px auto 0;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
}

.down-icon {
    position: absolute;
    top: 0;
    right: 15px;
    width: 12px;
    height: 100%;
    color: #606266;
}

.down-icon svg {
    position: relative;
    top: 11px;
}

.down-icon i {
    position: relative;
    top: 11px;
    left: -2px;
    color: #C9C7C8;
}

.vant-input-edit {
    height: 38px !important;
    line-height: 38px !important;
    padding: 0 10px !important;
    color: #222 !important;
    font-size: 15px !important;
}
.vant-input-edit >>>.van-field__value{
    height: 100%;
}
.vant-input-edit >>>.van-field__body{
    height: 100%;
}
.vant-input-edit >>> input::-webkit-input-placeholder {
    color: #606266 !important;
}
.vant-input-edit >>>.van-field__control{
    width: 255px;
}
.search-btn {
    width: 305px;
    height: 40px;
    margin: 25px auto 0;
    background: #409EFF;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 15px;
}
.search-btn >>>.van-loading{
    display: inline-block;
}
.top-collapse-title{
    display: flex;
    height: 44px;
    box-sizing: border-box;
    align-items: center;
    padding: 14px 0 14px 0;
    justify-content: space-between;
    color: #222222;
    font-size: 15px;
    border-bottom: 1px solid #DDE0E7;
    margin-bottom: 15px;
}
.source-top{
    display: flex;
    font-size: 15px;
    color: #A1A7BA;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 15px;
    height: 21px;
    line-height: 21px;
    padding-right: 12px;
}
.source-title{
    font-size: 16px;
    color: #222222;
    display: flex;
    /*align-items: center;*/
    margin-bottom: 10px;
}
.source-open{
    font-size: 16px;
    color: #222222;
    display: flex;
    /*align-items: center;*/
    margin-bottom: 15px;
}
.source-title-name{
    width: 234px;
    word-break: break-all;
    margin-right: 28px;
    flex-shrink: 0;
}
.coll-label{
    color: #A1A7BA;
    margin-right: 10px;
    min-width: 60px;
    flex-shrink: 0;
}
.coll-label-star{
    color: #A1A7BA;
    margin-right: 10px;
    width: 95px;
    flex-shrink: 0;
    display: inline-block;
}
.top-collapse-text>>>.van-col--12{
    line-height: 15px;
    margin-bottom: 14px;
    font-size: 15px;
}
.detail-w>>>.van-collapse{
    position: relative;
}
.open-coll{
    color: #A1A7BA;
    font-size: 13px;
    margin-left: 5px;
}
.expand{
    font-size: 13px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.expand img{
    width: 7px;
    height: 4px;
}
.detail-w>>>.van-collapse-item__content{
    padding: 0;
}
.tab-second{
    font-size: 18px;
    margin-top: 11px;
    height: 31px;
    display: flex;
    border-bottom: 1px solid #DDE0E7;
    gap: 29px;
}
.active-tab{
    color:#379CF5;
}
.active-tab::after{
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    background: #379CF5;
    border-radius: 2px;
    margin-top: 5px;
}
.visit_info{
    padding: 20px 0 15px 0;
    position: relative;
}
.visit_info>>>.van-row{
    gap: 14px;
}
.visit_info>>> .van-col--24{
    display: flex;
    font-size: 15px;
}
.visit_info::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #DDE0E7;
    /*transform: scaleY(0.5); !* 将线条缩小到0.5px *!*/
    position: absolute;
    bottom: 0px;
}
/* 隐藏最后一个 .item 的伪元素 */
.visit_info:last-child::after {
    display: none;
}
.category-statistics{
    background: #fff;
    margin-top: 10px;
    min-height: calc(100vh - 64px);
}
.thead-category{
    display: flex;
    align-items: center;
    height: 46px;
    border-bottom: 1px solid #DDE0E7;
}
.thead-item{
    padding-left: 15px;
    color: #A1A7BA;
    font-size: 14px;
}
.tbody-category{
    display: flex;
    align-items: center;
    min-height: 47px;
    padding: 12px 0;
    box-sizing: border-box;
    border-bottom: 1px solid #DDE0E7;
}
.td-item{
    color: #222222;
    font-size: 16px;
    padding-left: 15px;
    word-break: break-word;
}
/* 暂无数据 */
.empty{
    display: flex;
    margin-top: 76px;
    align-items: center;
    flex-direction: column;
    font-size: 17px;
    margin-bottom: 415px;
    color: #333333;
}
.empty img{
    width: 174px;
    height: 116px;
    margin-bottom: 15px;
}
.up-star{
    position: fixed;
    bottom: 0;
    /*padding-bottom:constant(safe-area-inset-bottom);*/
    /*!*兼容 IOS<11.2*!*/
    /*padding-bottom:env(safe-area-inset-bottom);*/
    box-sizing: border-box;
    /*兼容 IOS>11.2*/
}
.up-expand{
    font-size: 13px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 30px);
    background: #fff;
    height: 30px;
    padding-bottom: 15px;
    margin: 0 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.up-expand img{
    width: 7px;
    height: 4px;
}
/* 暂无数据 */
.follow-time{
    display: flex;
    margin-bottom: 7px;
    font-size: 13px;
    color: #999999;
}
/*返回顶部*/
.backTop {
    width: 54px;
    height: 54px;
    text-align: center;
    position: fixed;
    bottom: 60px;
    right: 10px;
    background-color: #409EFF;
    box-shadow: 0px 3px 6px 1px #ADC0D5;
    border-radius: 50%;
    z-index: 999;
}
.backTop img{
    margin-top: 8px;
}
.back-info{
    font-size: 11px;
    color: #fff;
    height: 30px;
    margin-top: 1px;
    /*width: 22px;*/
}
.back-info1{
    height: 11px;
    margin-bottom: 3px;
    line-height: 11px;
}
.back-info2{
    height: 11px;
    /*margin-bottom: 2px;*/
    line-height: 11px;
}
.line{
    height: 1px;
    width: 100%;
    background: #DDE0E7;
}
</style>
