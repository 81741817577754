<template>
  <div>
    <div class="add-wrap">
<!--      &lt;!&ndash; S input必填模块 &ndash;&gt;-->
<!--      <div class="info-box">-->
<!--        <div class="search-term-label">名称<span class="required-icon">*</span></div>-->
<!--        <div class="apply-title">-->
<!--          &lt;!&ndash; S 类别 &ndash;&gt;-->
<!--          <div class="search-term-detail apply-item-title" @click="inputPopup">-->
<!--            <van-field class="vant-input-edit apply-info-input" v-model="input_people" readonly placeholder="常用标签"/>-->
<!--            <div class="down-icon" v-if="clear_input_show">-->
<!--              <caret-bottom/>-->
<!--            </div>-->
<!--            <div class="down-icon" @click.stop="clearableInput" v-else>-->
<!--              <van-icon name="clear"/>-->
<!--            </div>-->
<!--          </div>-->
<!--          &lt;!&ndash; E 类别 &ndash;&gt;-->
<!--          &lt;!&ndash; S 类别详情标题 &ndash;&gt;-->
<!--          <div class="search-term-detail" @click="adviserPopup">-->
<!--            <van-field class="vant-input-edit apply-info-input" v-model="often_tag_info" clearable readonly placeholder="请选择名称..."/>-->
<!--            <div class="down-icon" v-if="clear_adviser_show">-->
<!--              <caret-bottom/>-->
<!--            </div>-->
<!--            <div class="down-icon" @click.stop="clearableAdviser" v-else>-->
<!--              <van-icon name="clear"/>-->
<!--            </div>-->
<!--          </div>-->
<!--          &lt;!&ndash; E 类别详情标题 &ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->

      <!-- S 级联控件 -->
      <div class="info-box">
        <div class="search-term-label">名称<span class="required-icon">*</span></div>
        <div class="search-term-detail" @click="sourceShow">
          <van-field
              class="vant-input-edit"
              v-model="fieldValue"
              readonly
              placeholder="请选择"
          />
          <div class="down-icon" v-if="clear_source_show">
            <caret-bottom/>
          </div>
          <div class="down-icon" @click.stop="clearableSource" v-else>
            <van-icon name="clear"/>
          </div>
        </div>
      </div>
      <!-- E 级联控件 -->

      <!-- E input必填模块 -->
      <!-- S input必填模块 -->
      <div class="info-box">
        <div class="info-title">
          <span>内容</span>
          <span class="required-icon">*</span>
        </div>
        <div class="info-input">
          <van-field class="vant-input-edit" v-model="cost_content" autocomplete="off" clearable
                     @focus="focusIpt" @blur="blurIpt" placeholder="请输入内容..."/>
        </div>
      </div>
      <!-- E input必填模块 -->

      <!-- S 文本域模块 -->
      <div class="info-box">
        <div class="info-title">
          <span>备注</span>
        </div>
        <div class="info-input info-textarea">
          <van-field
              class="info-textarea-edit"
              @focus="focusIpt"
              @blur="blurIpt"
              v-model="cost_remark"
              autocomplete="off"
              rows="1"
              autosize
              type="textarea"
              placeholder="请输入备注..."
          />
        </div>
      </div>
      <!-- E 文本域模块 -->

      <!-- S 文件上传模块 -->
      <div class="info-box">
        <div class="upload-title">附件</div>
        <div class="upload-list" v-for="(item, i) in file_data" :data-id="item.id">
          <div class="upload-index">
            <div class="upload-left">
              <img src="../assets/upload-img.png" alt="">
              <div class="upload-name">{{item.name}}</div>
            </div>
            <div class="upload-del" @click="fileDel(item)">
              <img src="../assets/upload-del.png" alt="">
            </div>
          </div>
        </div>
        <van-uploader :after-read="beforeRead" accept="">
          <div class="upload-btn">点击上传附件</div>
        </van-uploader>
      </div>
      <!-- E 文件上传模块 -->

      <!-- S 提醒时间模块 -->
      <div class="info-box">
        <div class="info-title">
          <span>提醒时间</span>
        </div>
        <div class="info-input" @click="signPopup">
          <van-field class="vant-input-edit" v-model="sign_time" readonly placeholder="请选择"/>
          <div class="down-icon" v-if="clear_sign_show">
            <caret-bottom/>
          </div>
          <div class="down-icon" @click.stop="clearableSign" v-else>
            <van-icon name="clear"/>
          </div>
        </div>
      </div>
      <!-- E 提醒时间模块 -->

      <!-- S 文本域模块 -->
      <div class="info-box">
        <div class="info-title">
          <span>提醒内容</span>
        </div>
        <div class="info-input info-textarea">
          <van-field
              @focus="focusIpt"
              @blur="blurIpt"
              class="info-textarea-edit"
              v-model="remind_content"
              autocomplete="off"
              rows="1"
              autosize
              type="textarea"
              placeholder="请输入提醒内容..."
          />
        </div>
      </div>
      <!-- E 文本域模块 -->

      <div class="remarks-submit" @click="doSubmit()">提交</div>
    </div>

    <!-- S 底部导航栏 -->
    <div class="occupy-box"></div>
    <!-- S 两个底部导航栏 -->
    <div v-if="twoStates">
      <van-tabbar v-model="active" v-if="bottom_nav_visible">
        <van-tabbar-item replace to="/index">
          <span>首页</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <home-filled/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/customSchedule">
          <span>客户列表</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <PieChart/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
    <!-- S 两个底部导航栏 -->
    <!-- S 三个底部导航栏 -->
    <div v-if="threeStates">
      <van-tabbar v-model="active" v-if="bottom_nav_visible">
        <van-tabbar-item replace to="/index">
          <span>首页</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <home-filled/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/approve">
          <span>交接审批</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <Coordinate/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/customSchedule">
          <span>客户列表</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <PieChart/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
    <!-- S 三个底部导航栏 -->

    <!-- S 四个底部导航栏 -->
    <div v-if="fourStates">
      <van-tabbar v-model="active" v-if="bottom_nav_visible">
        <van-tabbar-item replace to="/index">
          <span>首页</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <home-filled/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/customManage">
          <span>客户管理</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <user/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/signedResources">
          <span>已签约</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <edit/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/sharedPool">
          <span>共享池</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <monitor/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
    <!-- S 四个底部导航栏 -->
    <!-- S 底部导航栏 -->

    <!-- S 顾问弹层 -->
    <van-popup v-model:show="often_show" position="bottom" :style="{ height: '48%' }">
      <van-picker
          title="请选择标签"
          show-toolbar
          value-key="title"
          :columns="often_tag_data"
          @confirm="adviserConfirm"
          @cancel="adviserCancel"
      />
    </van-popup>
    <!-- E 顾问弹层 -->

    <!-- S 提醒时间弹层 -->
    <van-popup v-model:show="sign_time_show" position="bottom" :style="{ height: '48%' }">
      <van-datetime-picker
          v-model="sign_current_date"
          type="datetime"
          title="选择提醒时间"
          :min-date="signMinDate"
          :max-date="signMaxDate"
          :formatter="formatter"
          @confirm="signTimeConfirm"
          @cancel="signTimeCancel"
      />
    </van-popup>
    <!-- E 提醒时间弹层 -->

    <!-- S 类别弹层 -->
    <van-popup v-model:show="input_people_show" position="bottom" :style="{ height: '48%' }">
      <van-picker
          title="请选择类别"
          show-toolbar
          :columns="input_people_data"
          value-key="title"
          @confirm="inputConfirm"
          @cancel="inputCancel"
      />
    </van-popup>
    <!-- E 类别弹层 -->

    <!-- S 申请进度项目弹层弹层 -->
    <van-popup v-model:show="source_show" position="bottom">
      <van-cascader
          v-if="source_visible"
          v-model="cascaderValue"
          title="请选择名称"
          :options="options"
          :field-names="sourceNames"
          @close="source_show = false"
          @finish="onFinish"
      />
    </van-popup>
    <!-- E 申请进度项目弹层弹层 -->
  </div>
</template>

<script>
  import {handoverImg, savePublicData, oftenTagData, gerAllRecord, applyOftenTag} from '../api'
  import {Dialog, Notify} from 'vant';
  import {ref} from "vue";
  import moment from "moment";

  export default {
    data() {
      return {
        customer_id: '', // 客户id
        cost_content: '', // 费用内容
        cost_remark: '', // 备注
        file_data: [], // pdf 上传路径集合数据
        edit_status: '', // 修改标识
        edit_id: '', // 修改数据id
        remind_content: '', // 提醒内容
        type_info_arr: [], // 类别详情大数组

        /* ---- 费用标签部分 ---- */
        often_show: false, // 费用标签弹出层
        often_tag_info: '', // 费用标签
        request_often_data: [], // 接口返回费用标签数据
        often_tag_data: [], // vant 下拉数据形式

        /* ---- 录入人部分 ---- */
        input_people_show: false, // 客户等级弹出层
        input_people: '', // 标签
        input_people_id: '', // 录入人id
        input_people_data: [], // vant下拉录入人数据结构
        request_people_data: [], // 接口返回录入人数据

        /* ---- 下拉重置 ---- */
        clear_adviser_show: true, // 费用标签重置按钮
        clear_sign_show: true, // 签单时间重置按钮
        clear_input_show: true, // 录入人重置按钮
        clear_source_show: true, // 来源重置按钮

        /* ---- 提醒时间 ---- */
        sign_time: '', // 签单时间
        sign_time_show: false, // 签单时间弹层显示隐藏
        signMinDate: new Date(),
        signMaxDate: new Date(2025, 10, 1), // 最大时间
        sign_current_date: new Date(), // 选中时间
        formatter(type, val) {
          if (type === 'year') {
            return `${val}年`;
          } else if (type === 'month') {
            return `${val}月`;
          } else if (type === 'day') {
            return `${val}日`;
          }
          return val;
        },

        bottom_nav_visible: true, // 底部导航显示与隐藏
        iosStates:false , // 手机系统

        threeStates: false,//三底部栏
        twoStates: false,//三底部栏
        fourStates: false,//四底部栏
        postId: "", // 权限

        /* ---- 来源部分 ---- */
        source_visible: false, // 来源级联选择部分控制
        source_show: false, // 来源popup弹窗控制
        source: '', // 接口传参所用数据
        fieldValue: '',
        cascaderValue: '',
        // 来源自定义字段
        sourceNames: {
          text: 'title',
          value: 'id',
          children: 'list'
        },
        options: [], // 常用标签数据
      }
    },

    // 生命周期 初始化
    setup() {
      const active = ref(''); // 设定选定第几个
      return {
        active,
      };
    },

    created() {
      window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
      this.iosStates = sessionStorage.getItem("iosStates")
      // 星迪修改（底部导航栏）
      this.postId = sessionStorage.getItem("postID")
      if (this.postId === '6' || this.postId === '5') { // 判断 文案 或者 运营经理
        this.threeStates = false // 不显示两个个底部
        this.fourStates = false// 不显示四个底部
        this.twoStates = true// 不显示三个底部
        this.active = null   //
      }
      if (this.postId === '3' || this.postId === '4') { // 判断 运营主管 或者 文案主管
        this.threeStates = true // 显示两个个底部
        this.twoStates = false// 不显示三个底部
        this.fourStates = false// 不显示四个底部
        this.active = null
      }
      if (this.postId === '0') { // 判断 管理员
        this.threeStates = false // 显示两个个底部
        this.twoStates = false// 不显示三个底部
        this.fourStates = true// 不显示四个底部
        this.active = null
      }


      if (this.$route.query.data) {
        let show_data = JSON.parse(decodeURIComponent(this.$route.query.data)) // 修改数据集合
        // 修改回显部分
        this.edit_id = show_data.id // 数据修改id
        if (show_data.title, 1) {
          this.clear_adviser_show = false // 费用标签重置按钮
          this.often_tag_info = this.strSplit(show_data.title, 1) // 费用标题
          this.fieldValue = this.strSplit(show_data.title, 1) // 费用标题
          this.clear_source_show = false // 来源重置按钮
        }
        this.cost_content = this.strSplit(show_data.title, 2) // 费用内容
        this.cost_remark = show_data.remark // 备注
        if (show_data.reminder_time) {
          this.sign_time = this.standardTime(show_data.reminder_time * 1000)
          this.clear_sign_show = false // 签单时间重置按钮
        }
        this.remind_content = show_data.reminder_content // 提醒内容
        // 附件回显
        this.file_data = [] // 初始化附件数组在进行操作
        for (let i = 0; i < show_data.file.length; i++) {
          this.file_data.push({
            name: this.strAccurate(show_data.file[i]),
            url: show_data.file[i]
          })
        }
      }

      // 传递客户id
      if (this.$route.query.id) {
        this.customer_id = this.$route.query.id // 客户id
      }

      // 是否为修改
      if (this.$route.query.edit_status) {
        this.edit_status = this.$route.query.edit_status // 客户id
      }

      this.applySpeedTag() // 申请进度常用标签
    },

    // 获取屏幕默认高度与实时高度
    mounted: function() {
      //获取默认高度
      this.defaultHeight = document.documentElement.clientHeight
      window.onresize = () => {
        return (() => {
          //实时屏幕高度
          this.nowHeight = document.documentElement.clientHeight
        })();
      };
    },

    // 监听软键盘是否弹出
    watch: {
      nowHeight:function() {
        if(this.defaultHeight!== this.nowHeight){
          this.bottom_nav_visible = false
        }else{
          this.bottom_nav_visible = true
        }
      }
    },

    methods: {
      /**
       * 来源部分逻辑(搜索)
       * */
      sourceShow() {
        this.source_show = true; // 来源popup弹窗控制
        this.source_visible = true // 来源级联选择部分控制
      },

      // 全部选项选择完毕后，会触发 finish 事件
      onFinish({selectedOptions}) {
        this.source_show = false;

        // 要不要显示第一条
        let new_arr = []
        for (let l = 0; l < selectedOptions.length; l++) {
          if (selectedOptions[l].parent === 0 || selectedOptions[l].parent) {
            new_arr.push(selectedOptions[l])
          }
        }

        this.fieldValue = new_arr.map((option) => option.title).join('-');
        this.often_tag_info = this.fieldValue
        let source_arr = []
        for (let i = 0; i < selectedOptions.length; i++) {
          source_arr.push(selectedOptions[i].id)
        }
        this.source = source_arr.toString() // 来源赋值(请求数据时候用)
        this.clear_source_show = false // 切换重置图标
      },

      // 字符串截取
      strSplit(str, type) {
        let new_str = str
        if (type === 1) {
          new_str = str.split(':')[0]
        } else {
          new_str = str.split(':')[1]
        }
        return new_str
      },

      // 指定区域接取字符串
      strAccurate(name_str) {
        let file_name = name_str.lastIndexOf("\/");
        name_str = name_str.substring(file_name + 1, name_str.length);
        return name_str
      },

      // 申请进度常用标签
      applySpeedTag() {
        applyOftenTag().then(data => {
          if (data.code === 200) {
            // 类别下拉数据赋值
            for (let i = 0; i < data.data.length; i++) {
              this.input_people_data.push({
                id: data.data[i].id,
                title: data.data[i].title,
              })
            }
            this.type_info_arr = data.data // 大表单赋值(所有类别)
            this.options = data.data
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 上传附件
      beforeRead(file, detail) {
        let formData = new FormData();
        formData.append("file", file.file);
        handoverImg(formData).then(data => {
          if (data.code === 200) {
            let name_str = data.data
            let file_name = name_str.lastIndexOf("\/");
            name_str = name_str.substring(file_name + 1, name_str.length);
            this.file_data.push({
              name: name_str,
              url: data.data
            })
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // PDF删除按钮
      fileDel(item) {
        Dialog.confirm({
          message: '确认删除本条数据吗?',
        })
        .then(() => {
          // this.file_data = []
          for (let i = 0; i < this.file_data.length; i++) {
            if (item.name === this.file_data[i].name) {
              this.file_data.splice(i, 1);
            }
          }
        })
        .catch(() => {
        });
      },

      // 提交
      doSubmit() {
        let that = this
        let file_arr = [] // 请求时传递的附件路径数组
        for (let i = 0; i < this.file_data.length; i++) {
          file_arr.push(this.file_data[i].url)
        }

        if (this.file_data.length === 0) {
          file_arr = ''
        }

        if (this.edit_status) {
          // 修改参数集合
          var para = {
            title: this.often_tag_info + ':' + this.cost_content, // 进度名称和内容
            customer_id: this.customer_id, // 客户id
            file: file_arr, // 附件数组
            remark: this.cost_remark, // 备注
            type: 2, // 0文案进度  1费用  2 申请进度
            reminder_time: this.sign_time, // 提醒时间
            reminder_content: this.remind_content, // 提醒内容
            id: this.edit_id, // 修改接口传递，序号ID
          }
        } else {
          // 添加参数集合
          var para = {
            title: this.often_tag_info + ':' + this.cost_content, // 进度名称和内容
            customer_id: this.customer_id, // 客户id
            file: file_arr, // 附件数组
            remark: this.cost_remark, // 备注
            type: 2, // 0文案进度  1费用  2 申请进度
            reminder_time: this.sign_time, // 提醒时间
            reminder_content: this.remind_content, // 提醒内容
            id: '', // 修改接口传递，序号ID
          }
        }

        // if (!this.often_tag_info) {
        //   Notify({type: 'danger', message: '请选择标签'});
        //   return
        // }

        if (!this.cost_content) {
          Notify({type: 'danger', message: '请填写内容'});
          return
        }

        savePublicData(para).then(data => {
          if (data.code === 200) {
            Notify({
              type: 'success',
              message: '提交成功',
              duration: 750
            })
            setTimeout(function () {
              that.$router.go(-1)
            }, 800)

          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      /**
       * 跟单顾问(搜索部分)
       * */
      // 跟单顾问弹层控制
      adviserPopup() {
        if (!this.input_people) {
          Notify({type: 'danger', message: '请先选择标签'})
        } else {
          this.often_show = true
        }
      },

      // 确认赋值
      adviserConfirm(value) {
        this.often_tag_info = value.title // 顾问赋值
        this.often_show = false // 关闭弹层
        this.clear_adviser_show = false // 切换重置图标
      },

      // 取消选择
      adviserCancel() {
        this.often_show = false // 关闭弹层
      },

      /**
       * 类别选择
       * */
      // 录入人弹层控制
      inputPopup() {
        this.input_people_show = true
      },

      // 确认赋值
      inputConfirm(value) {
        this.input_people = value.title // 录入人赋值
        for (let i = 0; i < this.type_info_arr.length; i++) {
          if (value.id === this.type_info_arr[i].id) {
            this.often_tag_data = this.type_info_arr[i].list
          }
        }

        this.input_people_show = false // 关闭弹层
        this.clear_input_show = false // 切换重置图标
      },

      // 取消选择
      inputCancel() {
        this.input_people_show = false // 关闭弹层
      },

      /**
       * 重置下拉按钮
       * */
      // 重置签约顾问
      clearableAdviser() {
        this.often_tag_info = '' // 清空回显
        this.clear_adviser_show = true // 切换重置图标
      },

      // 签单时间重置
      clearableSign() {
        this.sign_time = '' // 清空签单时间
        this.clear_sign_show = true // 切换图标
      },

      // 来源重置
      clearableSource() {
        this.source_visible = false // 来源级联选择部分控制
        this.fieldValue = '' // 清空来源回显
        this.source = '' // 清空来源传递参数
        this.cascaderValue = '' // 重置级联选择器
        this.clear_source_show = true // 切换重置图标
      },

      // 标签类别重置
      clearableInput() {
        this.input_people = '' // 清空回显
        this.often_tag_data = [] // 清空类别详情数组
        this.often_tag_info = '' // 清空类别详情回显
        this.input_people_id = '' // 清空请求时参数
        this.clear_input_show = true // 切换重置图标
        this.clear_adviser_show = true // 切换重置类别详情图标
      },

      // 苹果键盘事件
      blurIpt() {
        if (this.iosStates) {
          this.bottom_nav_visible = true
          return
        }
      },
      // 苹果键盘事件
      focusIpt() {
        if (this.iosStates) {
          this.bottom_nav_visible = false
          return
        }
      },

      /**
       * 签单状态
       * */
      // 签单单选监听
      // 提醒时间Popup
      signPopup() {
        this.sign_time_show = true
      },

      // 时间赋值
      signTimeConfirm() {
        this.sign_time_show = false
        this.sign_time = this.standardTime(this.sign_current_date)
        this.clear_sign_show = false // 切换图标
      },

      // 非时间戳转化(年-月-日 时:分:秒)
      standardTime(time) {
        return moment(time).format("YYYY-MM-DD HH:mm:ss")
      },

      // 取消时间赋值关闭弹窗
      signTimeCancel() {
        this.sign_time_show = false
      },
    }
  }
</script>

<style scoped>
  /* ---- 输入部分 ---- */
  .add-wrap {
    width: 100%;
    margin-top: 12px;
    padding: 16px 0;
    background: #fff;
  }

  .info-box {
    width: 345px;
    margin: 0 auto 16px;
  }

  .info-title {
    color: #A1A7BA;
    font-size: 15px;
  }

  .info-input {
    position: relative;
    width: 343px;
    height: 43px;
    margin-top: 12px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
  }

  .required-icon {
    padding-left: 3px;
    color: #FF0000;
  }

  .vant-input-edit {
    height: 43px !important;
    line-height: 43px !important;
    padding: 0 15px !important;
    color: #222 !important;
    font-size: 15px !important;
  }

  .vant-input-edit >>> input::-webkit-input-placeholder {
    color: #606266 !important;
  }

  .radio-box {
    display: flex;
    margin-top: 12px;
    height: 45px;
    color: #606266;
    font-size: 15px;
  }

  .radio-item {
    position: relative;
    margin-left: 10px;
    border-radius: 4px;
    border: 1px solid #C9CDD6;
    color: #606266;
    font-size: 15px;
  }

  .radio-item:last-child {
    margin-left: 0;
  }

  .radio_active {
    border-color: #409EFF;
  }

  .radio-item:last-child {
    margin-right: 0;
  }

  .van-radio--horizontal {
    margin-right: 0;
    padding: 11px;
  }

  .radio-item >>> .van-radio__icon--round .van-icon {
    display: none;
  }

  .radio-item >>> .van-radio__label {
    position: relative;
    margin-left: 0;
    padding-left: 20px;
    z-index: 9;
  }

  .radio-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 14px;
    left: 12px;
    width: 10px;
    height: 10px;
    border: 2px solid #ABAEB8;
    border-radius: 50%;
  }

  .radio-icon-spot {
    width: 6px;
    height: 6px;
    background: #ABAEB8;
    border-radius: 50%;
  }

  .radio_active .radio-icon {
    border-color: #409EFF;
  }

  .radio_active .radio-icon-spot {
    background: #409EFF;
  }

  .submit-btn1 {
    width: 345px;
    height: 50px;
    margin: 25px auto 35px;
    background: #409EFF;
    color: #fff;
    font-size: 17px;
    text-align: center;
    line-height: 50px;
    border-radius: 50px;
  }

  .info-textarea {
    height: 110px;
  }

  .info-textarea-edit {
    color: #222 !important;
    font-size: 15px !important;
  }

  .info-textarea-edit >>> textarea::-webkit-input-placeholder {
    color: #606266 !important;
  }

  .info-textarea-edit >>> textarea {
    min-height: 88px;
  }



  .remarks-submit {
    width: 345px;
    height: 50px;
    margin: 25px auto 0;
    background: #409EFF;
    color: #fff;
    font-size: 17px;
    text-align: center;
    line-height: 50px;
    border-radius: 50px;
  }

  .van-radio-group--horizontal, .van-checkbox-group--horizontal {
    flex-direction: row-reverse;
  }

  /* ---- 下载PDF模块---- */
  .upload-btn {
    width: 345px;
    margin: 0 auto;
    color: #606266;
    font-size: 17px;
    text-align: center;
    line-height: 45px;
    height: 45px;
    background: #F9F9F9;
    border-radius: 8px 8px 8px 8px;
  }

  .upload-index {
    width: 345px;
    height: 45px;
    line-height: 45px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #DCDFE6;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }

  .upload-left {
    display: flex;
  }

  .upload-title {
    font-size: 15px;
    color: #A1A7BA;
    padding-bottom: 17px;
  }

  .upload-index img {
    width: 15px;
    height: 15px;
    margin: 15px 0 0 15px;
  }

  .upload-name {
    margin-left: 10px;
    width: 165px;
    font-size: 15px;
    color: #222222;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .upload-del {
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F56C6C;
    border-radius: 50px 50px 50px 50px;
    margin-top: 9px;
    margin-right: 15px;
  }

  .upload-del img {
    margin: 0;
    width: 10px;
    height: 11px;
  }

  .upload-img-btn {
    width: 80px;
    height: 80px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #DCDFE6;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .upload-img-btn img {
    width: 35px;
    height: 35px;
  }


  .search-term {
    padding-top: 15px;
  }

  .search-term-label {
    color: #A1A7BA;
    font-size: 15px;
  }

  .search-term-detail {
    position: relative;
    height: 38px;
    margin-top: 12px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
  }

  /* 重置按钮 */
  .down-icon {
    position: absolute;
    top: 0;
    right: 10px;
    width: 12px;
    height: 100%;
    color: #606266;
  }

  .down-icon svg {
    position: relative;
    top: 11px;
  }

  .down-icon i {
    position: relative;
    top: 11px;
    left: -2px;
    color: #C9C7C8;
  }

  .apply-title {
    display: flex;
  }

  .apply-item-title {
    width: 114px;
    margin-right: 10px;
  }

  .apply-info-input >>> .van-field__control {
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
