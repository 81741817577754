<template>
    <!-- S 输入部分 -->
    <div class="add-wrap">
        <div class="search-term">
            <div class="search-term-label">公司名称 <span class="required-icon">*</span></div>
            <div class="search-term-detail">
                <van-field class="vant-input-edit" v-model="edit_company" clearable
                           autocomplete="off"
                           @focus="focusIpt"
                           @blur="blurIpt" placeholder="请输入公司名称..."/>
            </div>
        </div>
        <div class="search-term">
            <div class="search-term-label">联系人 <span class="required-icon">*</span></div>
            <div class="search-term-detail">
                <van-field class="vant-input-edit"  v-model="edit_user" clearable
                           autocomplete="off"
                           @focus="focusIpt"
                           @blur="blurIpt" placeholder="请输入联系人..."/>
            </div>
        </div>
        <div class="search-term">
            <div class="search-term-label">联系方式 <span class="required-icon">*</span></div>
            <div class="search-term-detail">
                <van-field class="vant-input-edit"  v-model="edit_contact" clearable
                           autocomplete="off"
                           @focus="focusIpt"
                           @blur="blurIpt" placeholder="请输入联系方式..."/>
            </div>
        </div>
        <div class="search-term">
            <div class="search-term-label">职务 <span class="required-icon">*</span></div>
            <div class="search-term-detail">
                <van-field class="vant-input-edit"  v-model="edit_post" clearable
                           autocomplete="off"
                           @focus="focusIpt"
                           @blur="blurIpt" placeholder="请输入职务..."/>
            </div>
        </div>
        <!-- S 跟单内容 -->
        <div class="info-box">
            <div class="info-title">
                <span>公司地址</span>
                <span class="required-icon">*</span>
            </div>
            <div class="info-input info-textarea">
                <van-field
                        class="info-textarea-edit"
                        v-model="edit_address"
                        autocomplete="off"
                        rows="1"
                        autosize
                        @focus="focusIpt"
                        @blur="blurIpt"
                        type="textarea"
                        placeholder="请输入公司地址..."
                />
            </div>
        </div>
        <!-- E 跟单内容 -->
        <div class="submit-btn1" @click="submit()">提交</div>
    </div>
    <!-- E 输入部分 -->
</template>

<script>
    import {saveChannel} from "../api/java";
    import {Notify} from "vant";
    export default {
        data() {
            return {
                edit_id:'',
                edit_company: '',
                edit_user: '',
                edit_contact: '',
                edit_post: '',
                edit_address: '',
                parent:0
            }
        },
        created() {
            window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
            this.iosStates = sessionStorage.getItem("iosStates")
            if(this.$route.query.visa_data!== undefined){
                let pageData = JSON.parse(decodeURIComponent(this.$route.query.visa_data))
                if(pageData.states){
                     this.parent =pageData.id
                }else{
                    this.parent=pageData.parent
                    this.edit_id =pageData.id
                    this.edit_company =pageData.company_name
                    this.edit_address =pageData.company_address
                    this.edit_contact =pageData.contact
                    this.edit_user =pageData.contact_user
                    this.edit_post =pageData.post
                }
            }else{
                this.parent = 0
                this.edit_id =''
            }

        },
        mounted: function () {
            //获取默认高度
            this.defaultHeight = document.documentElement.clientHeight
            window.onresize = () => {
                return (() => {
                    //实时屏幕高度
                    this.nowHeight = document.documentElement.clientHeight
                })();
            };
        },
        watch: {
            nowHeight: function () {
                if (this.defaultHeight !== this.nowHeight) {
                    this.bottom_nav_visible = false
                } else {
                    this.bottom_nav_visible = true
                }
            }
        },
        methods: {
            goBackPage() {
                this.$router.go(-1)
            },
            submit() {
                let para = {
                    id: this.edit_id,
                    company_address: this.edit_address, // 公司地址
                    company_name: this.edit_company, // 公司名称
                    contact: this.edit_contact, // 联系方式
                    contact_user: this.edit_user, // 联系人
                    parent: this.parent, // 联系人
                    post: this.edit_post, // 职务
                }
                if (para.company_name === '') {
                    Notify({type: 'danger', message: '公司名称不能为空，请输入！'});
                    return
                }
                if (para.contact_user === '') {
                    Notify({type: 'danger', message: '联系人不能为空，请输入！'});
                    return
                }
                if (para.contact === '') {
                    Notify({type: 'danger', message: '联系方式不能为空，请输入！'});
                    return
                }
                if (para.post === '') {
                    Notify({type: 'danger', message: '职务不能为空，请输入！'});
                    return
                }
                if (para.company_address === '') {
                    Notify({type: 'danger', message: '公司地址不能为空，请输入！'});
                    return
                }
                saveChannel(para).then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        Notify({type: 'success', message: "保存成功!"});
                        setTimeout(
                        this.$router.push({
                            path: '/channelAdmin',
                        }),2000);
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            // 苹果键盘事件
            blurIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = true
                    return
                }
            },
            // 苹果键盘事件
            focusIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = false
                    return
                }
            },
        }
    }
</script>

<style scoped>
    /* ---- 输入部分 ---- */
    .add-wrap {
        width: 100%;
        margin-top: 12px;
        padding: 16px 0;
        background: #fff;
    }

    .info-box {
        width: 345px;
        margin: 0 auto 16px;
        padding-top: 15px;
    }

    .info-title {
        color: #A1A7BA;
        font-size: 15px;
    }

    .info-input {
        position: relative;
        width: 343px;
        height: 43px;
        margin-top: 12px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .required-icon {
        padding-left: 3px;
        color: #FF0000;
    }

    .vant-input-edit {
        height: 43px !important;
        line-height: 43px !important;
        padding: 0 15px !important;
        color: #222 !important;
        font-size: 15px !important;
    }

    .vant-input-edit >>> input::-webkit-input-placeholder {
        color: #606266 !important;
    }

    .radio-box {
        display: flex;
        margin-top: 12px;
        height: 45px;
        color: #606266;
        font-size: 15px;
    }

    .radio-item {
        position: relative;
        margin-right: 10px;
        border-radius: 4px;
        border: 1px solid #C9CDD6;
        color: #606266;
        font-size: 15px;
    }

    .radio_active {
        border-color: #409EFF;
    }

    .radio-item:last-child {
        margin-right: 0;
    }

    .van-radio--horizontal {
        margin-right: 0;
        padding: 11px;
    }

    .radio-item >>> .van-radio__icon--round .van-icon {
        display: none;
    }

    .radio-item >>> .van-radio__label {
        position: relative;
        margin-left: 0;
        padding-left: 20px;
        z-index: 9;
    }

    .radio-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 14px;
        left: 12px;
        width: 10px;
        height: 10px;
        border: 2px solid #ABAEB8;
        border-radius: 50%;
    }

    .radio-icon-spot {
        width: 6px;
        height: 6px;
        background: #ABAEB8;
        border-radius: 50%;
    }

    .radio_active .radio-icon {
        border-color: #409EFF;
    }

    .radio_active .radio-icon-spot {
        background: #409EFF;
    }

    .submit-btn1 {
        width: 345px;
        height: 50px;
        margin: 25px auto 35px;
        background: #409EFF;
        color: #fff;
        font-size: 17px;
        text-align: center;
        line-height: 50px;
        border-radius: 50px;
    }

    .info-textarea {
        height: 110px;
    }

    .info-textarea-edit {
        color: #222 !important;
        font-size: 15px !important;
    }

    .info-textarea-edit >>> textarea::-webkit-input-placeholder {
        color: #606266 !important;
    }

    .info-textarea-edit >>> textarea {
        min-height: 88px;
    }

    /*重置按钮*/
    .down-icon {
        position: absolute;
        top: 0;
        right: 15px;
        width: 12px;
        height: 100%;
        color: #606266;
    }

    .down-icon svg {
        position: relative;
        top: 14px;
    }

    .down-icon i {
        position: relative;
        top: 14px;
        left: -2px;
        color: #C9C7C8;
    }

    .search-term {
        padding-top: 15px;
    }

    .search-term-label {
        padding: 0 20px;
        color: #A1A7BA;
        font-size: 15px;
    }

    .search-term-detail {
        position: relative;
        width: 343px;
        height: 38px;
        margin: 10px auto 0;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .vant-input-edit {
        height: 38px !important;
        line-height: 38px !important;
        padding: 0 10px !important;
        color: #222 !important;
        font-size: 15px !important;
    }
</style>
