<template>
</template>
<script>
    export default {
        name: "robLogin",
        data() {
            return {

            }
        },
        created() {
            // alert(132)
            // sessionStorage.setItem('isMenu', '1') // 存储用户名
            let login_url = 'https://crm-api-java-test.wmenzai.com/crm/rushOrderLogin'// 网页授权接口(测试)
            let Wxid ='ww0ade117fa232c373';
            // //正式Id
            // let Wxid ='ww1e03ca55a309cbb1';
            // let login_url = 'http://crm-tj-api.wmenzai.com/crm/qwxAuth2CheckTwo'// 网页授权接口(正式)
            // 刚进来没有token去登陆,登陆过有token跳过
            if (!this.$route.query.token) {
                location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+ Wxid +'&redirect_uri='+ encodeURIComponent(login_url) +'&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
            }
            if (this.$route.query.token) {
                // alert(this.$route.query.token)
                sessionStorage.setItem('token',  this.$route.query.token) // 存储  token信息
                sessionStorage.setItem('postID', '1') // 存储  token信息
                this.$router.push({
                    path: 'robIndex',
                });
            }
        },
        methods: {}
    }
</script>
<style scoped>
</style>
