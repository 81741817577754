<template>
 <div class="main-content">
  <div class="perform-top">
   <div class="perform-title">{{confirmContent.name}}</div>
   <div class="perform-info">
    <div style="flex-shrink: 0;display: flex;align-items: center;margin-right: 15px" >
     <img src="../../static/date.svg">
     <span>{{dateFormat(confirmContent.create_time)}}</span>
    </div>
    <div style="flex-shrink: 0;display: flex;align-items: center">
     <img src="../../static/person.svg">
     <span>{{confirmContent.user_name}}</span>
    </div>
   </div>
  </div>
  <div class="perform-content">
   <div v-html="confirmContent.content">
   </div>
  </div>
  <div class="perform-footer">
   <div class="perform-history" @click="handleToHistory">
    <img src="../../static/history.svg">
   </div>
   <van-button type="primary" size="large" :disabled="confirmContent.verify_type==1"  :loading="confirmLoad" @click="handleConfirm">
    <div v-if="confirmContent.verify_type==0">我已认真阅读，并确认</div>
    <div v-else><span class="time">已确认</span>{{dateFormatero(confirmContent.verify_time)}}</div>
   </van-button>
  </div>
 </div>
</template>
<script>
import
{
 getHistoricalRecordDetails,
 getPerforHistoricalAccuracy
} from "@/api/java";
import {Notify} from "vant";
import moment from "moment";

export default {
  components: {},
  data() {
    return {
     confirmLoad:false,
     confirmContent:{
      id:'',
      name:'',
      create_time:'',
      user_name:'',
      verify_type:'',
      verify_time:'',
      content:'',
      beginning_month:'',
      month_end:'',
     }
    }
  },
  created() {
   if(this.$route.query.confirmData){
    this.confirmContent.id=JSON.parse(this.$route.query.confirmData).id
    this.confirmContent.beginning_month=JSON.parse(this.$route.query.confirmData).beginning_month
    this.confirmContent.month_end=JSON.parse(this.$route.query.confirmData).month_end
   }
   this.getHistoryDetail()
  },
  computed:{
  },
  methods: {
   handleConfirm(){
    let para ={
     id:this.confirmContent.id,
     content:this.confirmContent.content
    }
    this.confirmLoad=true
    getPerforHistoricalAccuracy(para).then(data => {
     if (data.code === 200) {
      this.confirmLoad=false
      this.getHistoryDetail()
     } else {
      this.confirmLoad=false
       Notify({type: 'danger', message: data.message});
     }
    }).catch(() => {
     this.confirmLoad=false
     Notify({type: 'danger', message: '服务连接失败'});
    })
   },
   handleToHistory(){
    this.$router.push({
     name: 'performanceHistory',
    });
   },
   getHistoryDetail(){
    let para={
     id:this.confirmContent.id,
     beginning_month:this.confirmContent.beginning_month,
     month_end:this.confirmContent.month_end,
    }
    getHistoricalRecordDetails(para).then(data => {
     if (data.code === 200) {
      this.confirmContent.name=data.data.name
      this.confirmContent.create_time=data.data.create_time
      this.confirmContent.user_name=data.data.user_name
      this.confirmContent.verify_type=data.data.verify_type
      this.confirmContent.verify_time=data.data.verify_time
      this.confirmContent.content=data.data.content
     } else {
      Notify({type: 'danger', message: data.message});
     }
    }).catch(() => {
     Notify({type: 'danger', message: '服务连接失败'});
    })
   },
   dateFormat: function (date) {
    if (date === undefined || !date) {
     return "-";
    }
    return moment(date * 1000).format("YYYY-MM-DD");
   },
   dateFormatero: function (date) {
    if (date === undefined || !date) {
     return "-";
    }
    return moment(date * 1000).format("YYYY.MM.DD");
   },
  },
}
</script>

<style scoped>
.main-content{
  margin: 16px;
  height: calc(100vh - 32px);
  width: calc(100% - 32px);
  background: #fff;
  border-radius: 2px;
  padding-top: 25px;
  box-sizing: border-box;
  position: relative;
}
.perform-top{
 padding: 0 20px;
 border-bottom: 1px solid transparent;
 background: linear-gradient(white,white) padding-box,
 repeating-linear-gradient(-45deg,#C4C4C4 0, #C4C4C4 0.2em,white 0,white 0.4em);
}
.perform-title{
 font-weight: 600;
 color: #606266;
 font-size: 17px;
}
.perform-info{
 display: flex;
 /*gap: 15px;*/
 margin: 20px 0;
 font-size: 14px;
 line-height: 26px;
 color: #909399;
 flex-wrap:wrap ;

}
.perform-info img{
 margin-right: 7px;
 height: 12px;
 width: 12px;
}
.perform-content{
 padding: 25px 20px 0 20px;
 color:#606266;
 line-height: 24px;
}
.perform-content >>> strong{
 margin: 0 5px;
}
.perform-footer{
 position: absolute;
 bottom: 33px;
 display: flex;
 align-items: center;
 width: 100%;
 padding: 0 20px;
 box-sizing: border-box;
 gap: 13px;
}
.perform-footer img{
 width: 18px;
 height: 18px;
}
.perform-footer>>>.van-button--large{
 width: 240px;
 height: 50px;
 background: #419EFF;
 border-radius: 25px;
}
.perform-footer>>>.van-button__text{
 font-size: 15px;
}
.perform-history{
 height: 50px;
 width: 50px;
 background: #ECF5FF;
 border-radius: 25px;
 display: flex;
 align-items: center;
 justify-content: center;
}
.time{
 margin-right: 10px;
}
</style>