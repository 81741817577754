<template>
    <div class="box">
        <img src="../assets/no-jurisdiction.png">
        <div class="big-title">提示：{{title}}</div>
    </div>
</template>

<script>
    export default {
        name: "errorPage",
        data() {
            return {
               title:''
            }
        },
        created() {
            window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
            this.title =this.$route.query.message
        },
    }
</script>

<style scoped>
    .box {
        width: 100%;
    }
    .box img{
        width: 100%;
        height: 100%;
        margin-top: 120px;
        margin-bottom: 10px;
    }
    .big-title {
        color: #000;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
    }

</style>