let publicPath=[
  '/weChatLogin',
  '/errorPage',
  '/errorPageNew',
  '/robLogin',
  '/robIndex',
  '/dingLogin',
  '/dingTalkLogin',
  '/dailyJava',
  '/daily',
  '/performanceConfirmation',
  '/performanceHistory',
  '/middle',
  '/salesContractDaily',
]
export function isPublic(path){
  return publicPath.findIndex(i=>i===path)!==-1
}