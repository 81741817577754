<template>
    <!-- S 头部header -->
<!--    <div class="header">-->
<!--        <div class="header-nav">-->
<!--&lt;!&ndash;            <div class="header-nav-back" @click="goBackPage()">&ndash;&gt;-->
<!--&lt;!&ndash;                <van-icon name="arrow-left"/>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--            <span>转案单</span>-->
<!--        </div>-->
<!--    </div>-->
    <!-- E 头部header -->
    <div style="height: 100%">
        <div class="content-wrap">
            <div class="content-data-box">
                <div class="download-box" v-if="false">
                    <div>
                        <div class="download-title">电子合同</div>
                        <div class="download-item">
                            <document/>
                            <div class="download-mask">
                                <div class="download-icon" @click="downloadContract()">
                                    <img class="download-pic" src="../assets/download.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="download-title">付款证明</div>
                        <div class="download-item">
                            <van-icon name="photo-o"/>
                            <div class="download-mask">
                                <div class="download-icon" @click="downloadVoucher()">
                                    <img class="download-pic" src="../assets/download.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="upload-title">合同文件上传<span class="required-icon">*</span></div>
                    <div class="upload-list" v-for="(item, i) in pdfData" :data-id="item.id">
                        <div class="upload-index">
                            <div class="upload-left">
                                <img src="../assets/upload-img.png" alt="">
                                <div class="upload-name">{{item.name}}</div>
                            </div>
                            <div class="upload-del" @click="pdfDel(item)">
                                <img src="../assets/upload-del.png" alt="">
                            </div>
                        </div>
                    </div>
                    <van-uploader :after-read="beforeRead" accept=".pdf">
                        <div class="upload-btn">上传文件</div>
                    </van-uploader>
                </div>
                <!-- S 跟单内容 -->
                <div class="info-box">
                    <div class="info-title">
                        <span>合同备注</span>
                    </div>
                    <div class="info-input info-textarea">
                        <van-field
                                class="info-textarea-edit"
                                v-model="addForm.contract_content"
                                autocomplete="off"
                                rows="1"
                                autosize
                                @focus="focusIpt"
                                @blur="blurIpt"
                                type="textarea"
                                placeholder="请输入合同备注..."
                        />
                    </div>
                </div>
                <!-- E 跟单内容 -->
                <div>
                    <div class="upload-title">付款证明上传<span class="required-icon">*</span></div>
                    <div class="upload-list" v-for="(item, i) in imgData" :data-id="item.id">
                        <div class="upload-index">
                            <div class="upload-left">
                                <img src="../assets/upload-img.png" alt="">
                                <div class="upload-name">{{item.name}}</div>
                            </div>
                            <div class="upload-del" @click="imgDel(item)">
                                <img src="../assets/upload-del.png" alt="">
                            </div>
                        </div>
                    </div>
                    <van-uploader :after-read="imgRead" accept="image/*">
                        <div class="upload-btn">上传文件</div>
                    </van-uploader>
                </div>
                <!-- S 跟单内容 -->
                <div class="info-box">
                    <div class="info-title">
                        <span>付款证明备注</span>
                    </div>
                    <div class="info-input info-textarea">
                        <van-field
                                class="info-textarea-edit"
                                v-model="addForm.proof_of_payment_content"
                                autocomplete="off"
                                rows="1"
                                autosize
                                @focus="focusIpt"
                                @blur="blurIpt"
                                type="textarea"
                                placeholder="请输入付款证明备注..."
                        />
                    </div>
                </div>
                <div class="search-term">
                    <div class="search-term-label">客户身份证号<span class="required-icon">*</span></div>
                    <div class="search-term-detail">
                        <van-field class="vant-input-edit" v-model="addForm.customer_card" clearable
                                   placeholder="请输入客户身份证号"
                                   @focus="focusIpt"
                                   @blur="blurIpt"
                                   autocomplete="off"/>
                    </div>
                </div>
                <div class="search-term">
                    <div class="search-term-label">主申请人姓名<span class="required-icon">*</span></div>
                    <div class="search-term-detail">
                        <van-field class="vant-input-edit" v-model="addForm.customer_name" clearable
                                   placeholder="请输入主申请人姓名"
                                   @focus="focusIpt"
                                   @blur="blurIpt"
                                   autocomplete="off"/>
                    </div>
                </div>
                <div class="search-term">
                    <div class="search-term-label">副申请人姓名</div>
                    <div class="search-term-detail">
                        <van-field class="vant-input-edit" v-model="addForm.deputy_applicant" clearable
                                   placeholder="请输入副申请人姓名"
                                   @focus="focusIpt"
                                   @blur="blurIpt"
                                   autocomplete="off"/>
                    </div>
                </div>
                <div class="search-term">
                    <div class="search-term-label">配偶姓名</div>
                    <div class="search-term-detail">
                        <van-field class="vant-input-edit" v-model="addForm.spouse_name" clearable
                                   placeholder="请输入配偶姓名"
                                   @focus="focusIpt"
                                   @blur="blurIpt"
                                   autocomplete="off"/>
                    </div>
                </div>
                <div class="search-term">
                    <div class="search-term-label">客户手机号<span class="required-icon">*</span></div>
                    <div class="search-term-detail">
                        <van-field class="vant-input-edit" v-model="addForm.customer_phone" clearable
                                   placeholder="请输入客户手机号"
                                   type="digit"
                                   @focus="focusIpt"
                                   @blur="blurIpt"
                                   autocomplete="off"/>
                    </div>
                </div>
                <div class="search-term">
                    <div class="search-term-label">签约项目<span class="required-icon">*</span></div>
                    <div class="search-term-detail" @click="signProjectPopup">
                        <van-field class="vant-input-edit" v-model="sign_project" clearable readonly placeholder="请选择"/>
                        <div class="down-icon" v-if="clear_new_show">
                            <caret-bottom/>
                        </div>
                        <div class="down-icon" @click.stop="clearProject" v-else>
                            <van-icon name="clear"/>
                        </div>
                    </div>
                </div>
                <div class="search-term">
                    <div class="search-term-label">递交时间（单位:月）<span class="required-icon">*</span></div>
                    <div class="search-term-detail">
                        <van-field class="vant-input-edit" v-model="addForm.submission_time" clearable
                                   placeholder="请输入递交时间"
                                   type="digit"
                                   maxlength="3"
                                   @focus="focusIpt"
                                   @blur="blurIpt"
                                   autocomplete="off"/>
                    </div>
                </div>
                <div class="search-term">
                    <div class="search-term-label">家庭人数(1-9)</div>
                    <div class="search-term-detail">
                        <van-field class="vant-input-edit" v-model="addForm.number_of_families" clearable
                                   placeholder="请输入家庭人数"
                                   maxlength="1"
                                   type="digit"
                                   @focus="focusIpt"
                                   @blur="blurIpt"
                                   autocomplete="off"/>
                    </div>
                </div>
                <div class="search-term">
                    <div class="search-term-label">申请人数(1-6)</div>
                    <div class="search-term-detail">
                        <van-field class="vant-input-edit" v-model="addForm.number_of_application" clearable
                                   placeholder="请输入申请人数"
                                   type="digit"
                                   maxlength="1"
                                   @focus="focusIpt"
                                   @blur="blurIpt"
                                   autocomplete="off"/>
                    </div>
                </div>

                <div class="search-term">
                    <div class="search-term-label">最晚递交时间</div>
                    <div class="search-term-detail" @click="signPopup">
                        <van-field class="vant-input-edit" v-model="addForm.latest_time" clearable readonly
                                   placeholder="请选择"/>
                        <div class="down-icon" v-if="clear_latest_show">
                            <caret-bottom/>
                        </div>
                        <div class="down-icon" @click.stop="clearLatest" v-else>
                            <van-icon name="clear"/>
                        </div>
                    </div>
                </div>

                <div class="search-term">
                    <div class="search-term-label">客户来源</div>
                    <div class="search-term-detail">
                        <van-field class="vant-input-edit" v-model="addForm.customer_source" clearable
                                   placeholder="请输入客户来源"
                                   @focus="focusIpt"
                                   @blur="blurIpt"
                                   autocomplete="off"/>
                    </div>
                </div>
                <div class="info-box">
                    <div class="info-title">
                        <span>能否找到至少3名推荐人</span>
                    </div>
                    <div class="info-input info-textarea">
                        <van-field
                                class="info-textarea-edit"
                                v-model="addForm.background_one"
                                autocomplete="off"
                                rows="1"
                                autosize
                                @focus="focusIpt"
                                @blur="blurIpt"
                                type="textarea"
                                placeholder="请输入推荐人相关信息..."
                        />
                    </div>
                </div>
                <div class="info-box">
                    <div class="info-title">
                        <span>能否找到至少两家合作公司</span>
                    </div>
                    <div class="info-input info-textarea">
                        <van-field
                                class="info-textarea-edit"
                                v-model="addForm.background_two"
                                autocomplete="off"
                                rows="1"
                                autosize
                                @focus="focusIpt"
                                @blur="blurIpt"
                                type="textarea"
                                placeholder="请输入关于合作公司相关信息..."
                        />
                    </div>
                </div>
                <div class="info-box">
                    <div class="info-title">
                        <span>能否对接三方渠道</span>
                    </div>
                    <div class="info-input info-textarea">
                        <van-field
                                class="info-textarea-edit"
                                v-model="addForm.background_three"
                                autocomplete="off"
                                rows="1"
                                autosize
                                @focus="focusIpt"
                                @blur="blurIpt"
                                type="textarea"
                                placeholder="请输入关于对接三方渠道相关信息..."
                        />
                    </div>
                </div>
                <div class="info-box">
                    <div class="info-title">
                        <span>是否有约定三方费用范围</span>
                    </div>
                    <div class="info-input info-textarea">
                        <van-field
                                class="info-textarea-edit"
                                v-model="addForm.background_four"
                                autocomplete="off"
                                rows="1"
                                autosize
                                @focus="focusIpt"
                                @blur="blurIpt"
                                type="textarea"
                                placeholder="请输入关于约定三方费用范围相关信息..."
                        />
                    </div>
                </div>
                <div class="search-term">
                    <div class="search-term-label">合同金额</div>
                    <div class="search-term-detail">
                        <van-field class="vant-input-edit" v-model="addForm.contract_amount" clearable
                                   placeholder="请输入合同金额"
                                   @focus="focusIpt"
                                   @blur="blurIpt"
                                   autocomplete="off"/>
                    </div>
                </div>
                <div class="search-term">
                    <div class="search-term-label">合同编号</div>
                    <div class="search-term-detail">
                        <van-field class="vant-input-edit" v-model="addForm.contract_no" clearable
                                   placeholder="请输入合同编号"
                                   @focus="focusIpt"
                                   @blur="blurIpt"
                                   autocomplete="off"/>
                    </div>
                </div>
                <div class="info-box">
                    <div class="info-title">
                        <span>已付款项</span>
                    </div>
                    <div class="info-input info-textarea">
                        <van-field
                                class="info-textarea-edit"
                                v-model="addForm.paid_items"
                                autocomplete="off"
                                rows="1"
                                autosize
                                @focus="focusIpt"
                                @blur="blurIpt"
                                type="textarea"
                                placeholder="请输入已付款项..."
                        />
                    </div>
                </div>

                <div class="info-box">
                    <div class="info-title">
                        <span>付款流程</span>
                    </div>
                    <div class="info-input info-textarea">
                        <van-field
                                class="info-textarea-edit"
                                v-model="addForm.payment_process"
                                autocomplete="off"
                                rows="1"
                                autosize
                                @focus="focusIpt"
                                @blur="blurIpt"
                                type="textarea"
                                placeholder="请输入付款流程..."
                        />
                    </div>
                </div>
                <div class="info-box">
                    <div class="info-title">
                        <span>合同条款修改内容</span>
                    </div>
                    <div class="info-input info-textarea">
                        <van-field
                                class="info-textarea-edit"
                                v-model="addForm.contract_update_content"
                                autocomplete="off"
                                rows="1"
                                autosize
                                @focus="focusIpt"
                                @blur="blurIpt"
                                type="textarea"
                                placeholder="请输入合同条款修改内容..."
                        />
                    </div>
                </div>

                <div class="info-box">
                    <div class="info-title">
                        <span>家庭情况</span>
                    </div>
                    <div class="info-input info-textarea">
                        <van-field
                                class="info-textarea-edit"
                                v-model="addForm.family_situation"
                                autocomplete="off"
                                rows="1"
                                autosize
                                @focus="focusIpt"
                                @blur="blurIpt"
                                type="textarea"
                                placeholder="请输入家庭情况..."
                        />
                    </div>
                </div>

                <div class="info-box">
                    <div class="info-title">
                        <span>敏感职业</span>
                    </div>
                    <div class="info-input info-textarea">
                        <van-field
                                class="info-textarea-edit"
                                v-model="addForm.sensitive_occupation"
                                autocomplete="off"
                                rows="1"
                                autosize
                                @focus="focusIpt"
                                @blur="blurIpt"
                                type="textarea"
                                placeholder="请输入敏感职业相关信息..."
                        />
                    </div>
                </div>

                <div class="info-box">
                    <div class="info-title">
                        <span>其他：</span>
                    </div>
                    <div class="info-input info-textarea">
                        <van-field
                                class="info-textarea-edit"
                                v-model="addForm.other_content"
                                autocomplete="off"
                                rows="1"
                                autosize
                                @focus="focusIpt"
                                @blur="blurIpt"
                                type="textarea"
                                placeholder="请输入其他相关信息..."
                        />
                    </div>
                </div>

                <div class="search-term">
                    <div class="search-term-label">是否为特批标准客户</div>
                    <div class="radio-box">
                        <van-radio-group v-model="addForm.standard_customer" @change="greenCardSelect"
                                         direction="horizontal">
                            <div class="radio-item" :class="{radio_active:index+1 === green_card_active}"
                                 v-for="(item, index) in green_card_data"
                                 @click="greenCardColor(index+1)">
                                <div class="radio-icon">
                                    <div class="radio-icon-spot"></div>
                                </div>
                                <van-radio :name="item.id">{{item.name}}</van-radio>
                            </div>
                        </van-radio-group>
                    </div>
                </div>
                <div class="search-term">
                    <div class="search-term-label">销售顾问<span class="required-icon">*</span></div>
                    <div class="search-term-detail" @click="adviserPopup">
                        <van-field class="vant-input-edit" v-model="adviser_info" :disabled ='editStates' clearable readonly placeholder="请选择"/>
                        <div class="down-icon" v-if="clear_one_show">
                            <caret-bottom/>
                        </div>
                        <div class="down-icon" @click.stop="clearOne" v-else>
                            <van-icon name="clear"/>
                        </div>
                    </div>
                </div>
                <div class="search-term">
                    <div class="search-term-label">销售组长<span class="required-icon">*</span></div>
                    <div class="search-term-detail" @click="adviserGroup">
                        <van-field class="vant-input-edit" v-model="group_info" :disabled ='editStates' clearable readonly placeholder="请选择"/>
                        <div class="down-icon" v-if="clear_two_show">
                            <caret-bottom/>
                        </div>
                        <div class="down-icon" @click.stop="clearTwo" v-else>
                            <van-icon name="clear"/>
                        </div>
                    </div>
                </div>
                <!-- E 跟单内容 -->
                <div class="submit-btn1" @click="submit()">提交</div>
            </div>
        </div>
    </div>
    <!-- S 递交时间弹层 -->
    <van-popup v-model:show="sign_time_show" position="bottom" :style="{ height: '48%' }">
        <van-datetime-picker
                v-model="sign_current_date"
                type="date"
                title="选择递交时间"
                :min-date="signMinDate"
                :formatter="formatter"
                @confirm="signTimeConfirm"
                @cancel="signTimeCancel"
        />
    </van-popup>
    <!-- E 递交时间弹层 -->
    <!-- S 签约项目弹层 -->
    <van-popup v-model:show="sign_project_show" position="bottom" :style="{ height: '48%' }">
        <van-picker
                title="请选择签约项目"
                show-toolbar
                :columns-field-names="{text: 'title'}"
                :columns="sign_project_data"
                @confirm="signProjectConfirm"
                @cancel="signProjectCancel"
        />
    </van-popup>
    <!-- E 签约项目弹层 -->
    <!-- S 顾问弹层 -->
    <van-popup v-model:show="adviser_show" position="bottom" :style="{ height: '48%' }">
        <van-picker
                title="请选择顾问"
                show-toolbar
                :columns="adviser_data"
                @confirm="adviserConfirm"
                @cancel="adviserCancel"
        />
    </van-popup>
    <!-- E 顾问弹层 -->
    <!-- S 顾问组长弹层 -->
    <van-popup v-model:show="adviser_show1" position="bottom" :style="{ height: '48%' }">
        <van-picker
                title="请选择顾问"
                show-toolbar
                :columns="adviser_data"
                @confirm="groupConfirm"
                @cancel="groupCancel"
        />
    </van-popup>
    <!-- E 顾问组长弹层 -->
    <!-- S 底部导航栏 -->
    <div class="occupy-box"></div>
    <van-tabbar v-model="active" v-if="bottom_nav_visible">
        <van-tabbar-item replace to="/index">
            <span>首页</span>
            <template #icon="props">
                <div class="navIcon" v-bind:class="{ active: props.active}">
                    <el-icon>
                        <home-filled/>
                    </el-icon>
                </div>
            </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/customManage">
            <span>客户管理</span>
            <template #icon="props">
                <div class="navIcon" v-bind:class="{ active: props.active}">
                    <el-icon>
                        <user/>
                    </el-icon>
                </div>
            </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/signedResources">
            <span>已签约</span>
            <template #icon="props">
                <div class="navIcon" v-bind:class="{ active: props.active}">
                    <el-icon>
                        <edit/>
                    </el-icon>
                </div>
            </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/sharedPool">
            <span>共享池</span>
            <template #icon="props">
                <div class="navIcon" v-bind:class="{ active: props.active}">
                    <el-icon>
                        <monitor/>
                    </el-icon>
                </div>
            </template>
        </van-tabbar-item>
    </van-tabbar>
    <!-- S 底部导航栏 -->
</template>
<script>
    // import {handoverData, handoverSave, transferOrder, handoverImg, approvalStatus} from '../api'
    import {Dialog, Notify, Uploader} from "vant";
    import moment from "moment";
    import {ref} from "vue";
    import {downProject, getAdviserList, handoverImg, saveReferral, referralInfo} from "../api/java";

    export default {
        name: "demandTransfer",
        data() {
            return {
                screen_name: '', // 客户名称
                dataList: [], // 列表数据
                customerId: '', // 客户ID
                fileList: [],
                pdfData: [], // pdf 上传路径集合数据
                imgData: [],// 图片上传数据集合
                handover_data: {}, // 审批状态数组
                handover_edit_id: '', // 修改id
                upload_dom: false, // 上传部分dom
                download_dom: false, // 下载部分dom
                contract_info: '', // 合同备注
                payment_info: '', // 付款证明备注
                contract: '', // 合同下载路径
                voucher: '', // 凭证下载路径

                bottom_nav_visible: true, // 底部导航显示与隐藏
                iosStates: false, // 手机系统

                /* ---- 监听软键盘是否弹出 ---- */
                defaultHeight: '0',  //默认屏幕高度
                nowHeight: '0',  //实时屏幕高度

                threeStates: false,//三底部栏
                twoStates: false,//三底部栏
                fourStates: false,//四底部栏
                postId: "", // 权限
                sign_time_show: false, // 签单时间弹层显示隐藏
                sign_current_date: new Date(), // 选中时间
                signMinDate: new Date(1970, 0, 1),
                editStates:true,
                formatter(type, val) {
                    if (type === 'year') {
                        return `${val}年`;
                    } else if (type === 'month') {
                        return `${val}月`;
                    } else if (type === 'day') {
                        return `${val}日`;
                    }
                    return val;
                },
                /* ---- 签约项目部分 ---- */
                sign_project_all: [], // 签约项目原始数据
                sign_project_show: false, // 签约项目弹出层
                sign_project: '', // 签约项目
                sign_project_data: [], // 签约项目列表

                /* ---- 跟单顾问部分 ---- */
                adviser_id: '', // 顾问id
                adviser_show: false, // 顾问弹出层
                adviser_show1: false,// 组长顾问弹层
                adviser_info: '', // 跟单顾问
                group_info: '',// 组长
                request_adviser_data: [], // 接口返回顾问数据
                adviser_data: [], // vant 顾问数据形式
                green_card_data: [
                    {id: 0, name: '不是'},
                    {id: 1, name: '是'},
                ],
                green_card_active: null, // 绿卡选中样式
                addForm: {
                    contract: [],
                    customer_id: '',
                    customer_card: '',// 身份证
                    customer_name: '',// 主申请人姓名
                    deputy_applicant: '',// 副申请姓名
                    spouse_name: '',// 配偶姓名/
                    customer_phone: '',//手机号
                    sign_project: '',// 签约项目
                    submission_time: '',// 递交时间（单位：月）
                    number_of_families: '',// 家庭人数
                    member_family: '',// 家庭成员信息
                    number_of_application: '',// 申请人数
                    latest_time: '',// 最晚递交时间
                    customer_source: '',// 客户来源
                    background_one: '',//能否找到至少3名推荐人
                    background_two: '',//能否找到至少两家合作公司
                    background_three: '',//能否对接三方渠道
                    background_four: '',//是否有约定三方费用范围
                    contract_amount: '',//合同金额
                    contract_no: '',//合同编号
                    paid_items: '',// 已付款项
                    payment_process: '',// 付款流程
                    contract_update_content: '',//合同条款修改内容
                    family_situation: '',//家庭情况
                    sensitive_occupation: '',//敏感职业
                    other_content: '',// 其他
                    standard_customer: '',//是否为特批标准客户
                    sales_consultant: '',//销售顾问
                    eBay_amazon: '',//销售组长
                    id: '',
                    contract: '',//合同文件路径
                    proof_of_payment: '', // 付款证明图片文件路径
                    contract_explain: '', // 合同说明
                    payment_explain: '', // 付费凭证说明
                    clear_new_show: true, // 条件重置按钮

                    clear_one_show:true,// 顾问显示
                    clear_two_show:true,// 顾问显示
                    clear_latest_show:true,
                }

            }
        },
        // 生命周期 初始化
        setup() {
            const active = ref(''); // 设定选定第几个
            return {
                active,
            };
        },
        created() {
            this.clear_new_show=true
            this.clear_one_show = true
            this.clear_two_show=true
            this.clear_latest_show =true
            this.getSignProject() // 获取签约项目下拉
            this.getAddress()
            this.iosStates = sessionStorage.getItem("iosStates")
            // 星迪修改（底部导航栏）
            this.postId = sessionStorage.getItem("postID")
            if (this.postId === '6' || this.postId === '5') { // 判断 文案 或者 运营经理
                this.threeStates = false // 不显示两个个底部
                this.fourStates = false// 不显示四个底部
                this.twoStates = true// 不显示三个底部
                this.active = null   //
            }
            if (this.postId === '3' || this.postId === '4') { // 判断 运营主管 或者 文案主管
                this.threeStates = true // 显示两个个底部
                this.twoStates = false// 不显示三个底部
                this.fourStates = false// 不显示四个底部
                this.active = null
            }
            if (this.postId === '0' || this.postId === '1' || this.postId === '2' || this.postId === '7') { // 判断 管理员
                this.threeStates = false // 显示两个个底部
                this.twoStates = false// 不显示三个底部
                this.fourStates = true// 不显示四个底部
                this.active = null
            }

            window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
            this.customerId = this.$route.query.custom_id // 客户id
            this.addForm.customer_id = this.$route.query.custom_id // 客户id
            if (this.$route.query.edit_data) {
                this.dataList = JSON.parse(decodeURIComponent(this.$route.query.edit_data)) // 接收上个页面传递过来的对象
                this.editStates =true
                this.handover_edit_id = this.dataList.id // 修改id
                this.getEditInfo() // 获取转案单详情
                // 文案与运营通过一个
                // if (this.dataList.operations_status === 1 || this.dataList.copyright_status === 1) {
                //     this.download_dom = true // 审批通过显示下载
                //     this.upload_dom = false // 审批通过隐藏上传禁止修改
                //     this.contract = this.dataList.contract // 合同文件下载链接
                //     this.voucher = this.dataList.proof_of_payment // 凭证文件下载链接
                // }
                //
                // // 文案与运营均未审批
                // if (this.dataList.operations_status === 0 && this.dataList.copyright_status === 0) {
                //     this.download_dom = false // 审批通过显示下载
                //     this.upload_dom = true // 审批通过隐藏上传禁止修改
                //     // 合同文件回显
                //     this.pdfData = [
                //         {
                //             name: this.strFunc(this.dataList.contract),
                //             url: this.dataList.contract
                //         }
                //     ]
                //     // 付款证明文件回显
                //     this.imgData = [
                //         {
                //             name: this.strFunc(this.dataList.proof_of_payment),
                //             url: this.dataList.proof_of_payment
                //         }
                //     ]
                //     // 合同备注和付款证明备注
                //     this.contract_info = this.dataList.contract_content
                //     this.payment_info = this.dataList.proof_of_payment_content
                // }
            } else {
                this.editStates =false
                this.handover_edit_id = '' // 添加修改id改为空
                this.upload_dom = true // 显示上传部分
            }
        },

        // 获取屏幕默认高度与实时高度
        mounted: function () {
            //获取默认高度
            this.defaultHeight = document.documentElement.clientHeight
            window.onresize = () => {
                return (() => {
                    //实时屏幕高度
                    this.nowHeight = document.documentElement.clientHeight
                })();
            };
        },
        // 监听软键盘是否弹出
        watch: {
            nowHeight: function () {
                if (this.defaultHeight !== this.nowHeight) {
                    this.bottom_nav_visible = false
                } else {
                    this.bottom_nav_visible = true
                }
            }
        },

        methods: {
            // 下载合同
            downloadContract() {
                let download_url = process.env.VUE_APP_BASE_URL + '/api/upload/download?url=' + this.contract // 下载接口
                window.open(download_url, '_blank')
            },

            // 下载凭证
            downloadVoucher() {
                let download_url = process.env.VUE_APP_BASE_URL + '/api/upload/download?url=' + this.voucher // 下载接口
                window.open(download_url, '_blank')
            },

            // 字符串截取
            strFunc(name_str) {
                let file_name = name_str.lastIndexOf("\/");
                name_str = name_str.substring(file_name + 1, name_str.length);
                return name_str
            },
            //获取转案单详情
            getEditInfo() {
                let para = {
                    id: this.handover_edit_id
                }
                referralInfo(para).then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        this.addForm = data.data
                        // 文件回显
                        this.pdfData = [
                            {
                                name: this.strFunc(data.data.contract),
                                url: data.data.contract
                            }
                        ]
                        // 图片回显
                        this.imgData = [
                            {
                                name: this.strFunc(data.data.proof_of_payment),
                                url: data.data.proof_of_payment
                            }
                        ]
                        // 签约项目回显
                        for (let i = 0; i < this.sign_project_all.length; i++) {
                            if (this.addForm.sign_project == this.sign_project_all[i].id) {
                                this.sign_project = this.sign_project_all[i].title
                            }
                        }
                        // 组长回显
                        for (let i = 0; i < this.request_adviser_data.length; i++) {
                            if (parseInt(this.addForm.ebay_amazon) == this.request_adviser_data[i].id) {
                                this.group_info = this.request_adviser_data[i].user_name
                            }
                        }
                        // 顾问回显
                        for (let i = 0; i < this.sign_project_all.length; i++) {
                            if (parseInt(this.addForm.sales_consultant) == this.request_adviser_data[i].id) {
                                this.adviser_info = this.request_adviser_data[i].user_name
                            }
                        }
                        if (this.addForm.standard_customer === 0) {
                            this.green_card_active = 1
                        }
                        if (this.addForm.standard_customer === 1) {
                            this.green_card_active = 2
                        }
                        if (this.addForm.standard_customer === null) {
                            this.green_card_active = null
                        }

                        if(this.addForm.latest_time!== null){
                            this.addForm.latest_time =this.standardTime(this.addForm.latest_time)
                        }else{
                            this.addForm.latest_time=''
                        }


                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },

            // 调用审批状态接口
            // approveStatus() {
            //   let para = {
            //     id: this.customerId
            //   }
            //   approvalStatus(para).then(data => {
            //     if (data.code === 200) {
            //       this.handover_data = data.data // 审批状态数组赋值
            //       // 如果有一个审核通过隐藏上传部分功能展示下载
            //       if (this.handover_data.copyright_status === 1 || this.handover_data.operations_status === 1) {
            //         this.upload_dom = false
            //         this.download_dom = true
            //       }
            //
            //       if (this.handover_data.copyright_status === 0 && this.handover_data.operations_status === 0) {
            //         this.upload_dom = true
            //         this.download_dom = false
            //       }
            //
            //       // 判断是否有合同
            //       if (data.data.contract) {
            //         this.contract = data.data.contract // 合同文件路径
            //         this.pdfData = [
            //           {
            //             name: this.strFunc(data.data.contract),
            //             url: data.data.contract
            //           }
            //         ]
            //       } else {
            //         this.pdfData = []
            //       }
            //
            //       // 判断是否有凭证
            //       if (data.data.proof_of_payment) {
            //         this.voucher = data.data.proof_of_payment // 凭证文件路径
            //         this.imgData = [
            //           {
            //             name: this.strFunc(data.data.proof_of_payment),
            //             url: data.data.proof_of_payment
            //           }
            //         ]
            //       } else {
            //         this.imgData = []
            //       }
            //     } else {
            //       Notify({type: 'danger', message: data.message});
            //     }
            //   })
            // },

            // 上传PDF
            beforeRead(file, detail) {

                let formData = new FormData();
                formData.append("file", file.file);
                if (this.pdfData.length > 0) {
                    Notify({type: 'danger', message: '每次只能上传一个文件'});
                    return
                }
                handoverImg(formData).then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        let name_str = data.message
                        let file_name = name_str.lastIndexOf("\/");
                        name_str = name_str.substring(file_name + 1, name_str.length);
                        this.pdfData.push({
                            name: name_str,
                            url: data.message
                        })
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            // 图片上传
            imgRead(file, detail) {
                let formData = new FormData();
                formData.append("file", file.file);
                if (this.imgData.length > 0) {
                    Notify({type: 'danger', message: '每次只能上传一张凭证'});
                    return
                }
                handoverImg(formData).then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        let name_str = data.message
                        let file_name = name_str.lastIndexOf("\/");
                        name_str = name_str.substring(file_name + 1, name_str.length);
                        this.imgData.push({
                            name: name_str,
                            url: data.message
                        })
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            // PDF删除按钮
            pdfDel(item) {
                Dialog.confirm({
                    message: '确认删除本条数据吗?',
                })
                    .then(() => {
                        this.pdfData = []
                    })
                    .catch(() => {
                    });
            },
            // IMG删除按钮
            imgDel(item) {
                Dialog.confirm({
                    message: '确认删除本条数据吗?',
                })
                    .then(() => {
                        this.imgData = []
                    })
                    .catch(() => {
                    });
            },

            // 时间戳转化(年-月-日 时:分:秒)
            formatDate(time) {
                return moment(time * 1000).format("YYYY-MM-DD HH:mm:ss")
            },

            // 时间戳转化(年-月-日 时:分:秒)
            formatDate2(time) {
                return moment(time * 1000).format("YYYY-MM-DD")
            },

            // 返回上一页
            goBackPage() {
                this.$router.go(-1)
            },


            // 提交
            submit() {
                let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/ // 手机号正则验证
                if (this.pdfData.length === 0) {
                    Notify({type: 'danger', message: '未上传合同文件，请上传！'});
                    return
                }
                if (this.imgData.length === 0) {
                    Notify({type: 'danger', message: '未上传付款证明，请上传！'});
                    return
                }

                if (this.addForm.customer_card === '' || this.addForm.customer_card === null) {
                    Notify({type: 'danger', message: '身份证号不能为空，请输入！'});
                    return
                }
                if (this.addForm.customer_name === '' || this.addForm.customer_name === null) {
                    Notify({type: 'danger', message: '主申请人不能为空，请输入！'});
                    return
                }
                if (this.addForm.customer_phone === '' || this.addForm.customer_phone === null) {
                    Notify({type: 'danger', message: '客户手机号不能为空，请输入！'});
                    return
                }
                if (!reg.test(this.addForm.customer_phone)) {
                    // 手机号不合法
                    Notify({type: 'danger', message: '您输入的手机号码格式不正确，请输入！'});
                    return
                }
                if (this.sign_project === '' || this.customer_phone === null) {
                    Notify({type: 'danger', message: '签约项目不能为空，请选择！'});
                    return
                }

                if (this.adviser_info === '' || this.adviser_info === null) {
                    Notify({type: 'danger', message: '销售顾问不能为空，请选择！'});
                    return
                }
                if (this.group_info === '' || this.group_info === null) {
                    Notify({type: 'danger', message: '销售组长不能为空，请选择！'});
                    return
                }
                let para = this.addForm
                para.contract = this.pdfData[0].url
                para.proof_of_payment = this.imgData[0].url
                para.title_info = ''
                saveReferral(para).then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        Notify({type: 'success', message: data.message});
                        this.dataList = []
                        this.pdfData = []
                        this.imgData = []
                        this.$router.push({
                            path: '/handoverList',
                            query: {
                                id: this.customerId
                            }
                        })
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },

            // 苹果键盘事件
            blurIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = true
                    return
                }
            },
            // 苹果键盘事件
            focusIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = false
                    return
                }
            },

            // 递交时间Popup
            signPopup() {
                this.sign_time_show = true
            },
            // 时间赋值
            signTimeConfirm() {
                this.sign_time_show = false
                this.clear_latest_show =false
                this.addForm.latest_time = this.standardTimeS(this.sign_current_date)
            },
            // 非时间戳转化(年-月-日 时:分:秒)
            standardTime(time) {
                debugger
                return moment(time*1000).format("YYYY-MM-DD")
            },

            standardTimeS(time) {
                return moment(time).format("YYYY-MM-DD")
            },
            // 取消时间赋值关闭弹窗
            signTimeCancel() {
                this.sign_time_show = false
            },
            // 获取签约项目下拉
            getSignProject() {
                downProject().then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        let project_data = data.data.data
                        this.sign_project_all = project_data
                        this.condition_content = data.data.condition // 客户条件数组
                        for (let i = 0; i < project_data.length; i++) {
                            this.sign_project_data.push(
                                {'id': project_data[i].id, 'title': project_data[i].title},
                            )
                        }
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },


            // 签约项目弹层 (签约项目)
            signProjectPopup() {
                this.sign_project_show = true
            },

            // 确认赋值 (签约项目)
            signProjectConfirm(value) {
                this.sign_project = value.title
                this.sign_project_show = false
                this.addForm.sign_project = '' // 重置客户条件
                for (let i = 0; i < this.sign_project_data.length; i++) {
                    if (value.title === this.sign_project_data[i].title) {
                        this.addForm.sign_project = this.sign_project_data[i].id
                    }
                }
                this.clear_new_show = false // 切换重置图标
            },

            // 取消选择 (签约项目)
            signProjectCancel() {
                this.sign_project_show = false // 关闭弹层
            },
            // 项目
            clearProject() {
                this.sign_project = '' // 清空请求时参数
                this.clear_new_show = true // 切换重置图
                this.addForm.sign_project=''
            },

            clearOne() {
                this.adviser_info = '' // 清空请求时参数
                this.clear_one_show = true // 切换重置图
               this.addForm.sales_consultant=''
            },

            clearTwo() {
                this.group_info = '' // 清空请求时参数
                this.clear_two_show = true // 切换重置图
                this.addForm.ebay_amazon=''
            },

            clearLatest(){
                this.addForm.latest_time =''
                this.clear_latest_show = true // 切换重置图
            },
            // 获取顾问下拉
            getAddress() {
                getAdviserList().then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        this.request_adviser_data = data.data
                        // 请求数据获取顾问数组格式化为vant下拉数组格式
                        for (let i = 0; i < this.request_adviser_data.length; i++) {
                            this.adviser_data.push(this.request_adviser_data[i].user_name) // vant 下拉数组赋值
                        }
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },

            //特批客户
            greenCardSelect() {
                this.addForm.standard_customer === 1 ? this.green_card_show = true : this.green_card_show = false
            },

            // 点击选中变色
            greenCardColor(index) {
                this.green_card_active = index
            },
            // 跟单顾问弹层控制
            adviserPopup() {
                if(this.editStates !== true){
                    this.adviser_show = true
                }
            },
            // 确认赋值
            adviserConfirm(value, index) {
                this.adviser_info = value // 顾问赋值
                this.adviser_show = false
                this.clear_one_show = false // 切换重置图标
                // 根据选中顾问获取对应顾问id
                for (let i = 0; i < this.request_adviser_data.length; i++) {
                    if (index === i) {
                        this.addForm.sales_consultant = this.request_adviser_data[i].id // 顾问id赋值
                    }
                }
            },

            // 取消选择
            adviserCancel() {
                this.adviser_show = false // 关闭弹层
            },

            // 组长弹层控制
            adviserGroup() {
                if(this.editStates!== true){
                    this.adviser_show1 = true
                }
            },
            // 确认赋值
            groupConfirm(value, index) {
                this.group_info = value // 顾问赋值
                this.adviser_show1 = false
                this.clear_two_show = false // 切换重置图标
                // 根据选中顾问获取对应顾问id
                for (let i = 0; i < this.request_adviser_data.length; i++) {
                    if (index === i) {
                        this.addForm.ebay_amazon = this.request_adviser_data[i].id // 顾问id赋值
                    }
                }
            },

            // 取消选择
            groupCancel() {
                this.adviser_show1 = false // 关闭弹层
            },
        }
    }
</script>

<style scoped>

    .required-icon {
        padding-left: 3px;
        color: #FF0000;
    }

    /* ---- header区域 ---- */
    .header {
        position: sticky;
        top: 0;
        height: 52px;
        margin-bottom: 12px;
        background: #fff;
        box-shadow: 0 9px 16px rgba(25, 52, 139, 0.06);
        z-index: 9;
    }

    .header-nav {
        position: relative;
        height: 53px;
        color: #222;
        font-size: 19px;
        text-align: center;
        line-height: 53px;
    }

    .header-nav-back {
        position: absolute;
        left: 10px;
        width: 19px;
        height: 16px;
    }

    .content-wrap {
        margin-top: 12px;
        padding: 10px 15px 20px;
        background: #fff;
    }

    /* ---- 客户搜索模块---- */
    .customer-box {
        display: flex;
        justify-content: space-between;
    }

    .customer-input {
        width: 270px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
    }

    .customer-search {
        width: 65px;
        height: 45px;
        line-height: 45px;
        background: #409EFF;
        border-radius: 4px;
        text-align: center;
        font-size: 15px;
        color: #FFFFFF;
        border: none;
    }

    /* ---- 客户详情部分---- */
    .data-index {
        padding: 18px;
        background: #ffffff;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        margin: 11px 0 0;
        position: relative;
    }

    .index-top {
        padding-bottom: 9px;
        border-bottom: 1px solid #DDE0E7;
        display: flex;
        justify-content: space-between;
    }

    .name-num {
        font-size: 15px;
        color: #A1A7BA;
        margin-right: 8px;
        flex-shrink: 0;
    }

    .name-title {
        font-size: 15px;
        color: #222222;
    }

    .index-two {
        margin-top: 13px;
        display: flex;
        justify-content: space-between;
    }

    .index-three {
        margin-top: 13px;
        display: flex;
        line-height: 20px;
    }

    .data-index-active {
        border: 1px solid #409EFF;
    }

    .active {
        border: 1px solid #409EFF;

    }

    /* ---- 选择顾问---- */
    .info-box {
        width: 345px;
        margin: 15px auto 0;
    }

    .info-title {
        color: #A1A7BA;
        font-size: 15px;
    }

    .info-input {
        position: relative;
        width: 343px;
        margin-top: 12px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .down-icon {
        position: absolute;
        top: 13px;
        right: 15px;
        width: 12px;
        color: #606266;
    }

    .vant-input-edit {
        height: 43px !important;
        line-height: 43px !important;
        padding: 0 15px !important;
        color: #222 !important;
        font-size: 15px !important;
    }

    .submit-btn1 {
        width: 345px;
        height: 50px;
        margin: 25px auto 15px;
        background: #409EFF;
        color: #fff;
        font-size: 17px;
        text-align: center;
        line-height: 50px;
        border-radius: 50px;
    }

    .triangle {
        position: absolute;
        right: 0;
        bottom: 0;
        border-style: solid;
        border-width: 0 0 45px 45px;
        border-color: transparent transparent #409EFF transparent;
        width: 0;
        height: 0;
    }

    .blue-icon {
        position: absolute;
        bottom: 4px;
        right: 4px;
        color: #ffffff;
        z-index: 99
    }

    /* ---- 下载PDF模块---- */
    .upload-btn {
        width: 345px;
        margin: 0 auto;
        color: #606266;
        font-size: 17px;
        text-align: center;
        line-height: 45px;
        height: 45px;
        background: #F9F9F9;
        border-radius: 8px 8px 8px 8px;
    }

    .upload-index {
        width: 345px;
        height: 45px;
        line-height: 45px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #DCDFE6;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
    }

    .upload-left {
        display: flex;
    }

    .upload-title {
        font-size: 15px;
        color: #A1A7BA;
        padding: 22px 0 17px;
    }

    .upload-index img {
        width: 15px;
        height: 15px;
        margin: 15px 0 0 15px;
    }

    .upload-name {
        margin-left: 10px;
        width: 165px;
        font-size: 15px;
        color: #222222;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .upload-del {
        width: 27px;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F56C6C;
        border-radius: 50px 50px 50px 50px;
        margin-top: 9px;
        margin-right: 15px;
    }

    .upload-del img {
        margin: 0;
        width: 10px;
        height: 11px;
    }

    .upload-img-btn {
        width: 80px;
        height: 80px;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #DCDFE6;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .upload-img-btn img {
        width: 35px;
        height: 35px;
    }

    .priority-box {
        display: inline-block;
        position: relative;
        top: -2px;
        width: 27px;
        height: 15px;
        margin-left: 16px;
        background: #F56C6C;
        color: #fff;
        font-size: 12px;
        text-align: center;
        line-height: 15px;
        border-radius: 3px;
    }

    .download-box {
        display: flex;
        width: 345px;
        margin: 17px auto 0;
    }

    .download-item {
        position: relative;
        text-align: center;
        width: 95px;
        height: 95px;
        margin-right: 23px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .download-title {
        margin-bottom: 15px;
        color: #A1A7BA;
        font-size: 15px;
    }

    .download-item svg {
        width: 53px;
        color: #222;
        margin-top: 20px;
        font-size: 38px;
    }

    .download-mask {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        width: 97px;
        height: 97px;
        background: rgba(255, 255, 255, .8);
    }

    .download-icon {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background: #409EFF;
    }

    .download-item i {
        margin-top: 20px;
        font-size: 58px;
    }

    .download-icon svg {
        width: 15px;
        margin-top: 6px;
        color: #fff;
    }

    .download-pic {
        width: 14px;
        margin-top: 6px;
    }

    .info-textarea-edit {
        color: #222 !important;
        font-size: 15px !important;
    }

    .info-textarea-edit >>> textarea::-webkit-input-placeholder {
        color: #606266 !important;
    }

    .info-textarea-edit >>> textarea {
        min-height: 88px;
    }

    .search-term {
        padding-top: 15px;
    }

    .search-term-label {
        /*padding: 0 20px;*/
        color: #A1A7BA;
        font-size: 15px;
    }

    .search-term-detail {
        position: relative;
        width: 345px;
        height: 38px;
        margin: 10px auto 0;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .down-icon {
        position: absolute;
        top: 0;
        right: 15px;
        width: 12px;
        height: 100%;
        color: #606266;
    }

    .down-icon svg {
        position: relative;
        top: 11px;
    }

    .down-icon i {
        position: relative;
        top: 11px;
        left: -2px;
        color: #C9C7C8;
    }

    .radio-box {
        display: flex;
        margin-top: 12px;
        height: auto;
        color: #606266;
        font-size: 15px;
    }

    .radio-item {
        position: relative;
        margin-right: 10px;
        border-radius: 4px;
        border: 1px solid #C9CDD6;
        color: #606266;
        font-size: 15px;
    }

    .radio_active {
        border-color: #409EFF;
    }

    .radio-item:last-child {
        margin-right: 0;
    }

    .radio_active {
        border-color: #409EFF;
    }

    .radio-item:last-child {
        margin-right: 0;
    }

    .van-radio--horizontal {
        margin-right: 0;
        padding: 11px;
    }

    .radio-item >>> .van-radio__icon--round .van-icon {
        display: none;
    }

    .radio-item >>> .van-radio__label {
        position: relative;
        margin-left: 0;
        padding-left: 20px;
        z-index: 9;
    }

    .radio-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 14px;
        left: 12px;
        width: 10px;
        height: 10px;
        border: 2px solid #ABAEB8;
        border-radius: 50%;
    }

    .radio-icon-spot {
        width: 6px;
        height: 6px;
        background: #ABAEB8;
        border-radius: 50%;
    }

    .radio_active .radio-icon {
        border-color: #409EFF;
    }

    .radio_active .radio-icon-spot {
        background: #409EFF;
    }
    .vant-input-edit {
        height: 38px !important;
        line-height: 38px !important;
        padding: 0 10px !important;
        color: #222 !important;
        font-size: 15px !important;
    }
</style>
