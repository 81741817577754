<template>
    <!-- S 头部header -->
    <div class="header">
        <div class="header-nav">
<!--            <div class="header-nav-back" @click="goBackPage()">-->
<!--                <van-icon name="arrow-left"/>-->
<!--            </div>-->
<!--            <span>跟单记录</span>-->
            <div class="submit-btn" v-if="btn_show" @click="submit()">添加</div>
        </div>
    </div>
    <!-- E 头部header -->
    <div v-if="poolStates" class="declare-box">
        <div  class="declare-btn" @click="declare()">申报合格资源</div>
    </div>
    <!-- S 权重列表 -->
    <div class="w" style="margin-bottom: 115px">
        <div v-for="(item, i) in list" v-if="dataStates">
            <div class="documentary-box">
                <div class="list-top">{{item.follow_time}}</div>
                <div class="list-one">
                    <div class="one-left">回访方式</div>
                    <div class="one-title"> {{this.modalityData[item.follow_type]}}</div>
                </div>
                <div class="list-one">
                    <div class="one-left">回访结果</div>
                    <div class="one-title">{{this.result[item.follow_result]}}</div>
                </div>
                <div class="list-textarea">
                    <div class="textarea-top">回访内容</div>
                    <div class="textarea-title" v-if="item.follow_content!==''">{{item.follow_content}}</div>
                    <div v-else>-</div>
                </div>
                <div class="list-textarea">
                    <div class="textarea-top">备注</div>
                    <div class="textarea-title" v-if="item.follow_remarks!==''">{{item.follow_remarks}}</div>
                    <div v-else>-</div>
                </div>
                <div class="list-one">
                    <div class="one-left">添加人</div>
                    <div class="one-title">{{item.add_user}}</div>
                </div>
                <button class="list-btn" v-if="btn_show" @click="modify(item)" :data-id="item.id">修改</button>
            </div>
        </div>
        <div v-else class="empty-data">暂无数据</div>
    </div>
    <!-- E 权重列表 -->
</template>

<script>
    import {declareResources, getDocumentary} from '../api/java'
    import {Dialog, Notify} from "vant";
    import {ref} from "vue";

    export default {
        name: "documentary",
        data() {
            return {
                btn_show: true, // 修改\添加按钮隐藏
                list: [
                    // {
                    //     id: 1,//数据ID
                    //     follow_type: "电话",//回访方式
                    //     follow_result: 0,//回访结果
                    //     follow_time: "2022-09-01 09:09:01",//回访时间
                    //     follow_remarks: "加客户微信还没通过，打电话，无法接通",//备注
                    //     follow_content:  "电话空号 加微信试试",//回访内容
                    //     add_user:  "申霖",//添加人
                    // }
                ],
                modalityData: [
                    "电话", "微信", "邮件", "QQ",
                ],
                result: [
                    "无人接通", "接通成功回访"
                ],
                ajaxId: 9824, //传递add页面 用于请求数据回来
                dataStates: true,
               poolStates:true,//
                threeStates: false,//三底部栏
                twoStates: false,//三底部栏
                fourStates: false,//四底部栏
                postId: "" // 权限
            }
        },

        // 生命周期 初始化
        setup() {
            const active = ref(''); // 设定选定第几个
            return {
                active,
            };
        },

        created() {
            window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
            this.reset()
            let follow_status = sessionStorage.getItem('follow_status') // 跟单添加修改显示问题
            sessionStorage.removeItem('follow_status') // 清除客户回访过来的状态
            // 岗位权限判断
            // if (sessionStorage.getItem('postID') === '1' || this.$route.query.status === '0' || follow_status === '1' || sessionStorage.getItem('group_leader') === '1') {
            //   this.btn_show = false
            // }

           console.log(this.$route.query.status)
          if(this.$route.query.status){
            this.poolStates =true
          }else{
            this.poolStates =false
          }

            // 星迪修改（底部导航栏）
            this.postId = sessionStorage.getItem("postID")
            if (this.postId === '6' || this.postId === '5') { // 判断 文案 或者 运营经理
                this.threeStates = false // 不显示两个个底部
                this.twoStates = true// 不显示三个底部
                this.fourStates = false// 不显示四个底部
                this.active = null   //
            }
            if (this.postId === '3' || this.postId === '4') { // 判断 运营主管 或者 文案主管
                this.threeStates = true // 显示两个个底部
                this.twoStates = false// 不显示三个底部
                this.fourStates = false// 不显示四个底部
                this.active = null
            }
            if (this.postId === '0' || this.postId === '1' || this.postId === '2' || this.postId === '7') { // 判断 管理员
                this.threeStates = false // 显示两个个底部
                this.twoStates = false// 不显示三个底部
                this.fourStates = true// 不显示四个底部
                this.active = null
            }
        },
        methods: {
            // 返回上一页
            goBackPage() {
                this.$router.go(-1)
            },

            changeResult(id){
                console.log(id)

            },
            //初始化数据请求函数
            reset() {
                this.ajaxId = this.$route.query.id
                let para = {
                    customerId: this.$route.query.id
                }
                getDocumentary(para).then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        this.list = data.data
                        if (data.data.length <= 0) {
                            this.dataStates = false
                        } else {
                            this.dataStates = true
                            this.list = data.data
                        }
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            submit() {
                this.$router.push({
                    path: '/documentaryAdd',
                    query: {
                        customerId: this.ajaxId // 客户ID
                    }
                });
            },
            // 申报合格资源
            declare() {
                let that = this
                Dialog.confirm({
                    message: '是否申报合格资源?',
                })
                    .then(() => {
                        let para = {
                            id: this.$route.query.id
                        }
                        declareResources(para).then(data => {
                            if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                                Notify({type: 'danger', message: data.message});
                                sessionStorage.clear()
                                this.$router.push({
                                    path: '/weChatLogin'
                                })
                                return
                            }
                            if (data.code === 200) {
                                Notify({type: 'success', message: '申报成功'});
                                setTimeout(function () {
                                    that.$router.go(-1)
                                }, 500)
                            } else {
                                Notify({type: 'danger', message: data.message});
                            }
                        }).catch(() => {
                            Notify({type: 'danger', message: '服务连接失败'});
                        })
                    })
                    .catch(() => {
                        // on cancel
                    });
            },
            // 修改
            modify(item) {
                this.$router.push({
                    path: '/documentaryAdd',
                    query: {
                        visa_data: encodeURIComponent(JSON.stringify(item)), // vue路由传递对象
                        customerId: this.ajaxId // 客户ID
                    }
                });
            }
        },
        unmounted() {
            sessionStorage.removeItem("group_leader")
            sessionStorage.removeItem('declare_show') // 离开页面清除'申报合格资'源显示标识
        }
    }
</script>

<style scoped>
    /*此处可能存在添加样式问题 设计稿明显不同 2022-2-9*/
    .submit-btn1 {
        position: absolute;
        top: 11px;
        right: 15px;
        height: 30px;
        color: #409EFF;
        font-size: 15px;
        text-align: center;
        line-height: 30px;
        border-radius: 4px;
    }

    /*权重盒子*/
    .documentary-box:first-child {
        margin-top: 15px;
    }

    .documentary-box {
        padding: 20px;
        background: #ffffff;
        box-shadow: 0px 3px 10px rgba(108, 157, 209, 0.16);
        border-radius: 10px;
        margin-bottom: 15px;
    }

    .list-top {
        font-size: 15px;
        color: #222222;
        padding-bottom: 12px;
        border-bottom: 1px solid #DDE0E7;
    }

    .list-one {
        padding-top: 16px;
        display: flex;
        color: #222222;
        font-size: 15px;
        line-height: 15px;
    }

    .one-left {
        color: #A1A7BA;
        margin-right: 9px;
    }

    .list-textarea {
        padding-top: 13px;
        font-size: 15px;
        color: #A1A7BA;
    }

    .textarea-title {
        padding-top: 13px;
        font-size: 15px;
        color: #222222;
    }

    .list-btn {
        width: 100%;
        height: 40px;
        margin: 23px auto 0;
        background: #409EFF;
        color: #fff;
        font-size: 17px;
        text-align: center;
        line-height: 40px;
        border-radius: 4px;
        border: none;
    }

    /*暂无数据样式*/
    .noData {
        padding: 20px 0;
        text-align: center;
        font-size: 15px;
        color: #A1A7BA;
    }

    .declare-box{
        position: sticky;
        top: 0;
        width: 100%;
        padding: 1px 0;
        background: #FFFFFF;
        z-index: 2;
    }

    .declare-btn {
        width: 335px;
        height: 38px;
        margin: 15px;
        background: #EBF5FF;
        color: #409EFF;
        font-size: 15px;
        text-align: center;
        line-height: 38px;
        border: 1px solid #409EFF;
        border-radius: 60px;
    }
</style>
