<template>
    <div class="post-id">
        <div class="post-info" @click="adminBtn">管理员0</div>
        <div class="post-info" @click="operateBtn">运营1</div>
        <div class="post-info" @click="adviserBtn">顾问2</div>
    </div>
    <div class="hello">
        <div v-for="(item, index) in nav_data" @click="navTo(index)">{{item.title}}</div>
    </div>
</template>

<script>
    import {tokenDemo, userUpCode} from "../api/java";
    import {Notify} from "vant";
    export default {
        name: "HelloWorld",
        props: {
            msg: String,
        },
        data() {
            return {
                str: 123,
                nav_data: [
                    {title: '首页', url: '/index'},
                    {title: '组内数据', url: '/groupData'},
                    {title: '客户回访', url: '/returnVisit'},
                    {title: '客户管理√', url: '/customManage'},
                    {title: '客户添加√', url: '/customer_add'},
                    {title: '客户详情', url: '/customer_detail'},
                    {title: '权重管理√', url: '/weightManage'},
                    {title: '添加权重√', url: '/weightAdd'},
                    {title: '共享池√', url: '/sharedPool'},
                    {title: '分配记录√', url: '/customAllocation'},
                    {title: '已签约√', url: '/signedResources'},
                    {title: '客户转单', url: '/demandTransfer'},
                    {title: '登陆记录', url: '/loginRecord'},
                    {title: '日志', url: '/journal'},
                    {title: '跟单记录列表', url: '/documentary'},
                ]
            }
        },
        created() {
            this.getDemoToken()
            // let login_url = process.env.VUE_APP_BASE_URL + '/crm/qwxAuth2Check' // 网页授权接口(正式)
            // console.log(login_url)
        },
        methods: {
            navTo(index) {
                this.$router.push({
                    path: this.nav_data[index].url
                })
            },
            adminBtn() {
                sessionStorage.removeItem('postID')
                sessionStorage.setItem('postID', '0')
            },

            operateBtn() {
                sessionStorage.removeItem('postID')
                sessionStorage.setItem('postID', '1')
            },

            adviserBtn() {
                sessionStorage.removeItem('postID')
                sessionStorage.setItem('postID', '2')
            },
            getDemoToken() {
                let para = {
                    user_phone: 13614048679,
                    code: 121212,
                }
                tokenDemo(para).then(data => {
                    if (data.code === 200) {
                        var newToken = data.data.token  // 后台请求回来的token值11
                        sessionStorage.setItem('token', newToken) // 存储token信息
                        sessionStorage.setItem('postID', data.data.post) // 存储  post 权限(0:管理员; 1:运营;2:顾问;)token信息
                        sessionStorage.setItem('userId', data.data.id) // 存储token信息
                        // sessionStorage.setItem('nav', JSON.stringify(data.data.menu)) // 存储token信息
                        // sessionStorage.setItem('token',  "bearer " + this.$route.query.token) // 存储token信息
                        sessionStorage.setItem('userName', data.data.userName) // 存储用户名
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .hello div {
        width: 100px;
        padding: 15px;
        margin: 10px auto 0;
        text-align: center;
        border-radius: 4px;
        color: #fff;
        background: #409EFF;
    }

    .post-id {
        display: flex;
        position: sticky;
        top: 0;
        height: 50px;
        justify-content: space-around;
        background: #F5F5FC;
    }

    .post-info {
        width: 100px;
        height: 45px;
        color: #fff;
        font-size: 15px;
        text-align: center;
        line-height: 45px;
        border-radius: 4px;
        background: #FB5430;
    }
</style>
