<template>
    <div style="height: 100%">
        <div class="content-wrap">
            <div class="content-data-box">
                <div class="download-box">
                    <div>
                        <div class="download-title">电子合同</div>
                        <div class="download-item">
                            <document/>
                            <div class="download-mask">
                                <div class="download-icon" @click="downloadContract()">
                                    <img class="download-pic" src="../assets/download.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="download-title">付款证明</div>
                        <div class="download-item">
                            <van-icon name="photo-o"/>
                            <div class="download-mask">
                                <div class="download-icon" @click="downloadVoucher()">
                                    <img class="download-pic" src="../assets/download.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- S 底部导航栏 -->
    <div class="occupy-box"></div>
    <van-tabbar v-model="active">
        <van-tabbar-item replace to="/index">
            <span>首页</span>
            <template #icon="props">
                <div class="navIcon" v-bind:class="{ active: props.active}">
                    <el-icon>
                        <home-filled/>
                    </el-icon>
                </div>
            </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/customManage">
            <span>客户管理</span>
            <template #icon="props">
                <div class="navIcon" v-bind:class="{ active: props.active}">
                    <el-icon>
                        <user/>
                    </el-icon>
                </div>
            </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/signedResources">
            <span>已签约</span>
            <template #icon="props">
                <div class="navIcon" v-bind:class="{ active: props.active}">
                    <el-icon>
                        <edit/>
                    </el-icon>
                </div>
            </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/sharedPool">
            <span>共享池</span>
            <template #icon="props">
                <div class="navIcon" v-bind:class="{ active: props.active}">
                    <el-icon>
                        <monitor/>
                    </el-icon>
                </div>
            </template>
        </van-tabbar-item>
    </van-tabbar>
    <!-- S 底部导航栏 -->
</template>
<script>
    import {ref} from "vue";

    export default {
        name: "demandTransfer",
        data() {
            return {
                screen_name: '', // 客户名称
                dataList: [], // 列表数据
                customerId: '', // 客户ID
                fileList: [],
                contract: '', // 合同下载路径
                voucher: '', // 凭证下载路径
            }
        },
        // 生命周期 初始化
        setup() {
            const active = ref(''); // 设定选定第几个
            return {
                active,
            };
        },
        created() {
            this.contract = ""  // 清空
            this.voucher = "" //清空
            window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
            var pageData = JSON.parse(decodeURIComponent(this.$route.query.data))
            this.contract = pageData.contract  // 赋值合同下载链接
            this.voucher = pageData.proof_of_payment // 赋值凭证下载链接
        },
        methods: {

            // 下载合同
            downloadContract() {
                let download_url = process.env.VUE_APP_BASE_URL + '/api/upload/download?url=' + this.contract // 下载接口
                window.open(download_url, '_blank')
            },

            // 下载凭证
            downloadVoucher() {
                let download_url = process.env.VUE_APP_BASE_URL + '/api/upload/download?url=' + this.voucher // 下载接口
                window.open(download_url, '_blank')
            },

        }
    }
</script>

<style scoped>

    .content-wrap {
        margin-top: 12px;
        padding: 10px 15px 20px;
        background: #fff;
    }

    .upload-index img {
        width: 15px;
        height: 15px;
        margin: 15px 0 0 15px;
    }


    .upload-del img {
        margin: 0;
        width: 10px;
        height: 11px;
    }


    .upload-img-btn img {
        width: 35px;
        height: 35px;
    }

    .download-box {
        display: flex;
        width: 345px;
        margin: 17px auto 0;
    }

    .download-item {
        position: relative;
        text-align: center;
        width: 95px;
        height: 95px;
        margin-right: 23px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .download-title {
        margin-bottom: 15px;
        color: #A1A7BA;
        font-size: 15px;
    }

    .download-item svg {
        width: 53px;
        color: #222;
        margin-top: 20px;
        font-size: 38px;
    }

    .download-mask {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        width: 97px;
        height: 97px;
        background: rgba(255, 255, 255, .8);
    }

    .download-icon {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background: #409EFF;
    }

    .download-item i {
        margin-top: 20px;
        font-size: 58px;
    }

    .download-icon svg {
        width: 15px;
        margin-top: 6px;
        color: #fff;
    }

    .download-pic {
        width: 14px;
        margin-top: 6px;
    }
</style>
