<template>
    <div class="box">
        <img src="../assets/error_new.png">
        <div class="big-title">{{ $route.query.message }}</div>
    </div>
</template>

<script>
export default {
    name: "errorPageNew",
    data() {
        return {
            title:''
        }
    },
    created() {
        window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
    },
}
</script>

<style scoped>
.box {
    background: #FFFFFF;
    width: 100%;
    text-align: center;
    height: 100vh;
}
.box img{
    width: 252px;
    height: 208px;
    margin: 72px auto 50px;
}
.big-title {
    font-size: 17px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    margin: 0 60px;
    word-break: break-all;
}

</style>
