<template>
  <div>

  </div>
</template>
<script>

export default {
  components: {},
  data() {
    return {

    }
  },
  created() {
    let login_url = process.env.VUE_APP_BASE_URL_JAVA  // 网页授权接口(正式)
    let wx_url = login_url+'/crm/performanceRecognition/performanceQwxAuth'
    if (this.$route.query.token) {
      // sessionStorage.setItem('postID', this.$route.query.post) // 存储  post 权限(0:管理员; 1:运营;2:顾问;)token信息
      sessionStorage.setItem('token', "bearer " + this.$route.query.token) // 存储token信息
      // localStorage.setItem('style', 'weChat') // 存储登录方式
    }else{
      location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww1e03ca55a309cbb1&redirect_uri='+ encodeURIComponent(wx_url) +'&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
      return;
    }
    // 如果有id和beginning_month和month_end就去详情页,没有就去列表页---避免缓存问题
    if(this.$route.query.id&&this.$route.query.beginning_month&&this.$route.query.month_end){
      let data ={
        id:this.$route.query.id,
        beginning_month:this.$route.query.beginning_month,
        month_end:this.$route.query.month_end,
      }
      this.$router.push({
        name:'performanceConfirmation',
        query: {
          confirmData: JSON.stringify(data)
        }
      })
    }else{
      this.$router.push({
        name:'performanceHistory',
      })
    }
  },
  computed:{
  },
  methods: {}
}
</script>

<style scoped>

</style>
