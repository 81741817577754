<template >
    <div  v-loading="loading">
        <el-backtop>
            <div width="100%;">
                <i class="el-icon-caret-top"></i>
            </div>
        </el-backtop>
        <div class="main-body">
            <el-tabs v-model="topActive" @tab-click="topClick">
                <el-tab-pane v-for="(item, index) in topData" :key="item.id" :label="item.user_name" :name="item.user_name">
                </el-tab-pane>
            </el-tabs>
            <div v-if="fromState">
                <el-table
                        :data="channelData"
                        border
                        style="width: 100%">
                    <el-table-column
                            prop="title"
                            label="来源名称">
                    </el-table-column>
                    <el-table-column
                            prop="num"
                            width="80"
                            label="数量">
                    </el-table-column>
                </el-table>
            </div>
            <div v-if="typeStates">
                <el-table
                        :data="tableData"
                        border
                        style="width: 100%">
                    <el-table-column
                            prop="title"
                            label="类别名称">
                    </el-table-column>
                    <el-table-column
                            prop="num"
                            width="80"
                            label="数量">
                    </el-table-column>
                </el-table>
            </div>
            <div v-if="adviserState">
                <el-form :label-position="labelPosition" class="search-box Login_right">
                    <el-form-item label="来源" v-if="companyStates">
                        <el-cascader
                                style="width: 240px"
                                v-model="addSourceVal"
                                :options="addSource"
                                :props="{value: 'id', label: 'title'}"
                                size="small"
                                clearable>
                        </el-cascader>
                    </el-form-item>
                    <el-form-item label="类别" v-if="companyStates">
                        <el-select v-model="type_analysis" clearable placeholder="请选择" size="small" style="width:240px">
                            <el-option
                                    v-for="item in analysis_data"
                                    :key="item.id"
                                    :label="item.title"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="录入人" v-if="companyStates">
                        <el-select v-model="writeName" style="width: 240px" clearable placeholder="请选择" size="small">
                            <el-option
                                    v-for="item in Operate"
                                    :key="item.id"
                                    :label="item.user_name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="顾问">
                        <el-select v-model="tabId" clearable placeholder="请选择" filterable size="small">
                            <el-option
                                    v-for="item in tabData"
                                    :key="item.id"
                                    :label="item.user_name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-button type="primary" size="small" icon="el-icon-search" style="margin: 5px auto;" @click="search()"
                               :loading="searchStates" class="search-phone-btn">搜索
                    </el-button>
                </el-form>
                <el-collapse class="custom-statistics" v-model="activeNames" @change="handleChange" accordion>
                    <el-collapse-item name='all'>
                        <template slot="title">
                            <div class="collapse-item">{{this.allFirst}}</div>
                            <div class="collapse-item">总计：{{this.allNum}}</div>
                            <div class="collapse-item">已回访：{{this.getNum}}</div>
                            <div>未回访：{{this.allNum -this.getNum}}</div>
                            <div style="margin-left: 8px">捞取总数：{{fishOutNum}}</div>
                            <div style="margin-left: 8px">捞取回访数：{{fishOutNumVisit}}</div>
                        </template>
                        <el-tabs v-model="footerActive" @tab-click="handleClick">
                            <el-tab-pane v-for="(item, index) in FooterData" :key="item.id" :label="item.user_name"
                                         :name="item.user_name">
                            </el-tab-pane>
                        </el-tabs>
                        <div v-if="this.allData.length>0">
                            <div v-for="(item, index) in this.allData" :key="item.id" class="tab-item-box">
                                <el-col :span="6" class="table-item table-left table-bold none-bottom">客户序号</el-col>
                                <el-col :span="6" class="table-item table-left none-bottom">{{item.id}}</el-col>
                                <el-col :span="6" class="table-item table-left table-bold none-bottom">客户等级</el-col>
                                <el-col :span="6" class="table-item none-bottom">{{item.customer_grade}}</el-col>
                                <el-col :span="6" class="table-item table-left table-bold none-bottom">客户名称</el-col>
                                <el-col :span="18" class="table-item none-bottom">{{item.user_name}}</el-col>
                                <el-col :span="6" class="table-item table-left table-bold none-bottom">顾问</el-col>
                                <el-col :span="8" class="table-item table-left none-bottom">{{item.follow_counsel_name}}</el-col>
                                <el-col :span="4" class="table-item table-left table-bold none-bottom">类别</el-col>
                                <el-col :span="6" class="table-item none-bottom">{{item.cat_id}}</el-col>
                                <el-col :span="6" class="table-item table-left table-bold none-bottom" v-if="companyStates">来源</el-col>
                                <el-col :span="18" class="table-item none-bottom">{{item.source_name}}</el-col>
                                <div v-if="item.record.length > 0 && item.qualified === 2">
                                    <el-col :span="24" class="table-item table-bold">回访记录</el-col>
                                    <div v-for="(items, index) in item.record" :key="index">
                                        <el-col :span="24" class="table-item end">{{items.follow_content}}</el-col>
                                    </div>
                                </div>
                                <div v-if="item.record.length > 0 && item.qualified === 1">
                                    <el-col :span="24" class="table-item table-bold">回访记录 <div style="float: right;">添加人: {{item.record[0].add_user}}</div></el-col>
                                    <el-col :span="24" class="table-item end">{{item.record[0].follow_content}}</el-col>
                                </div>
                            </div>
                        </div>
                        <div v-else class="no-data">暂无数据</div>
                    </el-collapse-item>
                    <el-collapse-item :name=item.name v-for="(item, index) in customerFooter" :key="item.name">
                        <template slot="title">
                            <div class="collapse-item">{{item.name}}</div>
                            <div class="collapse-item">总计：{{item.sum}}</div>
                            <div class="collapse-item">已回访：{{item.visit_finish}}</div>
                            <div>未回访：{{item.visit_not}}</div>
                            <div style="margin-left: 8px">捞取数：{{item.fish_out_num}}</div>
                            <div style="margin-left: 8px">捞取回访数：{{item.fish_out_visit}}</div>
                        </template>
                        <el-tabs v-model="footerActive" @tab-click="handleClick">
                            <el-tab-pane v-for="(item, index) in FooterData" :key="item.id" :label="item.user_name"
                                         :name="item.user_name">
                            </el-tab-pane>
                        </el-tabs>
                        <div v-if="footerStates === true">
                            <div v-for="(item, index) in item.data" :key="item.id" class="tab-item-box">
                                <el-col :span="6" class="table-item table-left table-bold none-bottom">客户序号</el-col>
                                <el-col :span="6" class="table-item table-left none-bottom">{{item.id}}</el-col>
                                <el-col :span="6" class="table-item table-left table-bold none-bottom">客户等级</el-col>
                                <el-col :span="6" class="table-item none-bottom">{{item.customer_grade}}</el-col>
                                <el-col :span="6" class="table-item table-left table-bold none-bottom">客户名称</el-col>
                                <el-col :span="18" class="table-item none-bottom">{{item.user_name}}</el-col>
                                <el-col :span="6" class="table-item table-left table-bold none-bottom">顾问</el-col>
                                <el-col :span="8" class="table-item table-left none-bottom">{{item.follow_counsel_name}}</el-col>
                                <el-col :span="4" class="table-item table-left table-bold none-bottom">类别</el-col>
                                <el-col :span="6" class="table-item none-bottom">{{item.cat_id}}</el-col>
                                <el-col :span="6" class="table-item table-left table-bold none-bottom" v-if="companyStates">来源</el-col>
                                <el-col :span="18" class="table-item none-bottom">{{item.source_name}}</el-col>
                                <div v-if="item.record.length > 0 && item.qualified === 2">
                                    <el-col :span="24" class="table-item table-bold">回访记录</el-col>
                                    <div v-for="(items, index) in item.record" :key="index">
                                        <el-col :span="24" class="table-item end">{{items.follow_content}}</el-col>
                                    </div>
                                </div>
                                <div v-if="item.record.length > 0 && item.qualified === 1">
                                    <el-col :span="24" class="table-item table-bold">回访记录 <div style="float: right;">添加人: {{item.record[0].add_user}}</div></el-col>
                                    <el-col :span="24" class="table-item end">{{item.record[0].follow_content}}</el-col>
                                </div>
                            </div>
                        </div>
                        <div v-else class="no-data">暂无数据</div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
    </div>
</template>
<script>
    import {localData, localWrite, localDown, localEnter, customerNewData} from '../api'
    import {Notify} from "vant";

    export default {
        data() {
            return {
                topData: [
                    {id: 3, user_name: '顾问统计'},
                    {id: 1, user_name: '类别统计'},
                    {id: 2, user_name: '来源统计'},
                ],
                typeStates: false,
                fromState: false,
                adviserState: true,
                topActive: '顾问统计',
                activeNames: [],

                customerFooter: [],


                search_hint: false, // 搜索成功提示信息
                labelPosition: 'right',
                searchStates: false, // 搜索按钮状态


                writeName: '',   // 录入人下拉参数
                channelData: [],// 来源数据集合
                tableData: [],  //表格数据集合

                adviserData: '', // 顾问下拉数据

                // 底部tab数据集合
                tabData: [],

                FooterData: [
                    {id: 2, user_name: '已回访'},
                    {id: 1, user_name: '未回访'},
                ],

                footerData: [], // 底部表格数据

                activeName: '', // 底部tab选中
                allNum: '', // 顶部总计
                getNum: '', // 顶部已回访
                fishOutNum: '', // 捞取总数
                fishOutNumVisit: '', // 捞取回访总数

                footerActive: '已回访',//// 底部tab选中

                addSourceVal: [], //来源选中默认值
                addSource: [], // 来源数据集合
                Operate: [],// 录入人下拉数据
                type_analysis: '', // 类别回显
                analysis_data: '', // 类别下拉数据
                footerId: '',// 底部请求参数

                tabId: '',
                footerStates: false, // 底部暂无数据显示

                footerAll: '',  // 底部总计
                footerNum: '', // 底部已回访

                footerSearch: false, //底部搜索状态

                urlPage: '',//url参数配置
                getId: '',//


                allData: [],// 全部折叠面板数据
                allFirst: '全部',
                allStates: true,
                loading: true,// 页面显示状态
                companyStates:false,// true 沈阳 false 深圳
            }
        },
        created() {
            let login_url = process.env.VUE_APP_BASE_URL  // 网页授权接口(正式)

            // 刚进来没有token去登陆,登陆过有token跳过
            // if (!this.$route.query.url) {
            //     Notify('无权访问此系统!')
            // }else{
            //     sessionStorage.setItem('url', this.$route.query.url) // 存储  url 日志标识
            // }
            if (this.$route.query.type === 'dd'&&!this.$route.query.token) {
                location.href = 'https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=dingy8vbkbckjgrpdlf0&response_type=code&scope=snsapi_auth&state=STATE&redirect_uri=' + login_url + '/api/login/shenyang'
                return;
            }
            if (this.$route.query.type === 'vx'&&!this.$route.query.token) {
                location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwf257a9c8b41da94d&redirect_uri='+ encodeURIComponent(login_url) +'&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
                return;
            }


            // if (this.$route.query.url && !this.$route.query.token) {
            //     Notify('缺失令牌')
            //     return;
            // }
            //
            // if (this.$route.query.company && this.$route.query.token) {
            //     sessionStorage.setItem('postID', this.$route.query.post) // 存储  post 权限(0:管理员; 1:运营;2:顾问;)token信息
            //     sessionStorage.setItem('token',  "bearer " + this.$route.query.token) // 存储token信息
            //     sessionStorage.setItem('userName', this.$route.query.user_name) // 存储用户名
            //     this.getToken() // 获取token
            // }




            // if(this.$route.query.company ==='3'){
            //     this.companyStates =true // 沈阳
            //     this. topData= [
            //         {id: 3, user_name: '顾问统计'},
            //         {id: 1, user_name: '类别统计'},
            //         {id: 2, user_name: '来源统计'},
            //     ]
            //
            //     this.getTableData(true) // 获取页面表格数据请求
            //     this.enterData() // 获取录入人下拉数据
            //     this.getSourceData() // 获取来源
            //     this.getEnter()
            //     this.getOwnData() // 获取底部顾问折叠面板数据
            // }else{
            //     this.companyStates =false  // 深圳
            //     this.getTableData(true) // 获取页面表格数据请求
            //     this.getEnter()
            //     this.getOwnData() // 获取底部顾问折叠面板数据
            //     this. topData= [
            //         {id: 3, user_name: '顾问统计'},
            //         {id: 1, user_name: '类别统计'},
            //     ]
            // };
        },
        methods: {
            topClick(tab, event) {
                this.activeNames = []
                let text = tab.$options.propsData.label
                if (text === '来源统计') {
                    this.fromState = true
                    this.typeStates = false
                    this.adviserState = false
                }
                if (text === '类别统计') {
                    this.typeStates = true
                    this.fromState = false
                    this.adviserState = false
                }
                if (text === '顾问统计') {
                    this.typeStates = false
                    this.fromState = false
                    this.adviserState = true
                }
            },
            // 一级选项卡
            handleChange(val) {
                this.footerActive = '已回访'
                // console.log(val)
                for (let i = 0; i < this.customerFooter.length; i++) {
                    if (val === this.customerFooter[i].name) {
                        sessionStorage.setItem('pageNum', i)
                        this.footerId = this.customerFooter[i].id
                    }
                }
                this.getId = 1
                // 判断点击全部 还是点击个体顾问
                if (val === 'all') {
                    sessionStorage.setItem('pageNum', 'all')
                    this.footerId = ''
                    this.getTableData(false)
                } else {
                    this.getFooterData(true)
                }
            },

            getOwnData(states) {
                let para = {
                    source: this.addSourceVal,
                    cat_id: this.type_analysis,
                    adviser_id: this.footerId,
                    enter_id: this.writeName,
                    url: this.urlPage,
                    visit_state: this.getId
                }
                customerNewData(para).then(data => {
                    if (data.code === 200) {
                        if (states === true) {
                            this.$message.success('搜索成功')
                        }
                        // console.log(data.data)
                        this.customerFooter = data.data
                    } else {
                        Notify({type: 'danger', message: data.message})
                    }
                }).catch(() => {
                    Notify({type: 'warning', message: '服务器连接失败'})
                })
            },


            // 选项卡切换时间
            handleClick(tab, event) {
                this.footerActive = '已回访'
                let text = tab.$options.propsData.label   // 选中文字
                var pageNum = sessionStorage.getItem('pageNum') // 折叠面板index索引
                if (text === '已回访') {
                    this.getId = 1
                }
                if (text === '未回访') {
                    this.getId = 2
                }
                if (pageNum === 'all' || pageNum === null) {
                    this.footerId = ''
                    this.getTableData() // 获取页面表格数据请求

                } else {
                    this.getFooterData(true)
                }
            },

            // 获取顾问下拉
            getEnter() {
                let para = {
                    url: this.urlPage
                }
                localEnter(para).then(data => {
                    if (data.code === 200) {
                        this.tabData = data.data
                    } else {
                        Notify({type: 'danger', message: data.message})
                    }
                }).catch(() => {
                    Notify({type: 'warning', message: '服务器连接失败'})
                })
            },

            // 来源
            getSourceData() {
                let para = {
                    url: this.urlPage
                }
                localDown(para).then(data => {
                    if (data.code === 200) {
                        this.addSource = data.data
                        this.searchSource = data.data
                    } else {
                        Notify({type: 'danger', message: data.message})
                    }
                }).catch(() => {
                    Notify({type: 'warning', message: '服务器连接失败'})
                })
            },

            // 获取录入人下拉数据
            enterData() {
                localWrite().then(data => {
                    if (data.code === 200) {
                        this.Operate = data.data // 选择顾问
                    } else {
                        Notify({type: 'danger', message: data.message})
                    }
                }).catch(() => {
                    Notify({type: 'warning', message: '服务器连接失败'})
                })
            },

            // 获取表格数据
            getTableData(states) {
                let para = {
                    source: this.addSourceVal,
                    cat_id: this.type_analysis,
                    adviser_id: this.footerId,
                    enter_id: this.writeName,
                    url: this.urlPage,
                    visit_state: this.getId
                }
                if (states === true) {
                    this.searchStates = true
                    this.loading =true
                }
                localData(para).then(data => {
                    if (data.code === 200) {
                        this.loading =false
                        this.searchStates = false
                        if (!sessionStorage.getItem('channelData')) {
                            sessionStorage.setItem('channelData', JSON.stringify(data.data.channel))
                        }
                        if (!sessionStorage.getItem('tableData')) {
                            sessionStorage.setItem('tableData', JSON.stringify(data.data.cat))
                        }
                        this.channelData = JSON.parse(sessionStorage.getItem('channelData')) // 来源表格
                        this.tableData = JSON.parse(sessionStorage.getItem('tableData')) // 类别表格
                        this.analysis_data = JSON.parse(sessionStorage.getItem('tableData')) // 类别表格
                        this.allNum = data.data.count
                        this.getNum = data.data.visitRecordNum
                        this.fishOutNum = data.data.fishOutNum
                        this.fishOutNumVisit = data.data.fishOutNumVisit
                        this.allData = data.data.newCustomerArr
                    } else {
                        this.loading =false
                        if (states === true) {
                            this.searchStates = false
                        }
                        Notify({type: 'danger', message: data.message})
                    }
                }).catch(() => {
                    if (states === true) {
                        this.searchStates = false
                    }
                    this.loading =false
                    this.search_hint = false // 请求失败不显示提示信息
                    Notify({type: 'warning', message: '服务器连接失败'})
                })
            },
            // 点击搜索
            search() {
                this.footerId = this.tabId
                this.getId = ''
                this.activeNames = []
                sessionStorage.setItem('pageNum', 'all')
                // sessionStorage.removeItem('pageNum')
                this.getTableData(true)
                this.getOwnData(true)
            },

            // 获取底部tab表格数据
            getFooterData(states) {
                let para = {
                    source: this.addSourceVal,
                    cat_id: this.type_analysis,
                    adviser_id: this.footerId,
                    enter_id: this.writeName,
                    url: this.urlPage,
                    visit_state: this.getId
                }
                this.footerSearch = true
                customerNewData(para).then(data => {
                    if (data.code === 200) {
                        var pageNum = sessionStorage.getItem('pageNum')
                        if (pageNum != 'all' && data.data.length > 0) {
                            this.footerStates = true
                            this.customerFooter[pageNum].data = data.data[0].data
                            return
                        }
                        if (pageNum != 'all' && data.data.length === 0) {
                            this.footerStates = false
                            this.customerFooter[pageNum].data = []
                            return
                        }
                        if (pageNum === 'all') {
                            // console.log(data.data)
                            return
                        }
                    } else {
                        this.footerSearch = false
                        Notify({type: 'danger', message: data.message})
                    }
                }).catch(() => {
                    this.footerSearch = false
                    Notify({type: 'warning', message: '服务器连接失败'})
                })
            }
        },
    }
</script>
<style scoped>
    .search-box {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 22px;
    }

    .search-footer {
        display: flex;
        flex-wrap: wrap;
    }

    .el-form-item {
        display: flex;
        margin: 0 15px 0 0;
    }

    .search-box .el-form-item .el-select {
        width: 240px;
    }

    .search-footer .el-form-item .el-select {
        width: 215px;
    }


    .table-item {
        /*text-align: center;*/
        padding: 15px 10px;
        border: 1px solid #EBEEF5;
        font-size: 13px;
        color: #606266;
    }

    .table-bold {
        font-weight: bold;
    }

    .table-left {
        border-right: none;
    }

    .tab-item-box {
        margin-bottom: 15px;
        overflow: hidden;
    }

    .none-bottom {
        border-bottom: none;
    }

    .el-form >>> .el-form-item__label {
        text-align: justify;
        text-align-last: justify;
    }

    .el-collapse >>> .el-collapse-item:last-child {
        margin: 0;
    }

    .el-collapse >>> .el-collapse-item__content {
        padding-bottom: 0 !important;
    }


    .collapse-item {
        margin-right: 10px;
    }

    .no-data {
        font-size: 12px;
        color: #909399;
        text-align: center;
        border: 1px solid #EBEEF5;
        padding: 12px 10px;
    }

    @media screen and (max-width: 750px) {
        .main-body {
            width: 90%;
            margin: 0 auto;
        }

        .search-phone-btn {
            width: 98%;
            margin: 0 auto;
        }

        .el-form-item {
            display: flex;
            justify-content: space-between;
        }

        .search-box >>> .el-form-item__label {
            flex-shrink: 0;
            width: 60px;
        }

        .search-footer >>> .el-form-item__label {
            flex-shrink: 0;
        }

        .table-item {
            padding: 3px 2px;
            border: 1px solid #EBEEF5;
            font-size: 12px;
            color: #606266;
        }

        .el-collapse >>> .el-collapse-item__header {
            height: 60px;
            line-height: 22px;
        }

        .no-data {
            font-size: 14px;
            color: #909399;
            text-align: center;
            border: 1px solid #EBEEF5;
            padding: 6px 5px !important;
        }

    }

    @media screen and (max-width: 360px) {
        .search-footer .el-form-item .el-select {
            width: 170px;
        }

        .collapse-item {
            margin-right: 5px;
        }

        .el-collapse >>> .el-collapse-item__header {
            font-size: 12px !important;
        }
    }

    .top {
        position: fixed;
        width: 30px;
        height: 30px;
        bottom: 50px;
        right: 100px;
        background-color: aqua;
    }

    .custom-statistics >>> .el-collapse-item__header {
        flex-wrap: wrap;
    }

    .custom-statistics >>> .el-collapse-item__arrow {
        position: absolute;
        right: 10px;
    }
</style>
