<template>
    <div>
        <!-- S 头部header -->
        <div class="header">
            <div class="header-nav">
                <div class="header-nav-back" @click="goBackPage()">
                    <van-icon name="arrow-left"/>
                </div>
                <span>签约统计</span>
            </div>
        </div>
        <!-- E 头部header -->
        <!-- S 筛选条件部分 -->
        <div class="screen detail-w">
            <div class="search-term">
                <div class="search-term-label">类型</div>
                <div class="search-term-detail" @click="sourceShow">
                    <van-field class="vant-input-edit" v-model="fieldValue" readonly placeholder="请选择类型..."/>
                    <div class="down-icon" v-if="clear_source_show">
                        <caret-bottom/>
                    </div>
                    <div class="down-icon" @click.stop="clearableSource" v-else>
                        <van-icon name="clear"/>
                    </div>
                </div>
            </div>
            <div class="screen-operation">
                <div class="search-btn" @click="search(true)">
                    <img src="../assets/search.png" alt="">
                    搜索
                </div>
            </div>
        </div>
        <!-- E 筛选条件部分 -->
        <!-- S 月份单数小计 -->
        <div class="month_total">
            <div class="month_total_top">
                <div class="top_img">
                    <img src="../assets/statistics_list.png" alt="">
                </div>
                <div class="top_title">月份单数小计</div>
            </div>
            <div class="month_total_main">
                <div class="main_list">
                    <div class="main_list_num">{{ monthData('jan') }}</div>
                    <div class="main_list_month">1月</div>
                </div>
                <div class="main_list">
                    <div class="main_list_num">{{ monthData('feb') }}</div>
                    <div class="main_list_month">2月</div>
                </div>
                <div class="main_list">
                    <div class="main_list_num">{{ monthData('mar') }}</div>
                    <div class="main_list_month">3月</div>
                </div>
                <div class="main_list">
                    <div class="main_list_num">{{ monthData('apr') }}</div>
                    <div class="main_list_month">4月</div>
                </div>
                <div class="main_list">
                    <div class="main_list_num">{{ monthData('may') }}</div>
                    <div class="main_list_month">5月</div>
                </div>
                <div class="main_list">
                    <div class="main_list_num">{{ monthData('jun') }}</div>
                    <div class="main_list_month">6月</div>
                </div>
                <div class="main_list">
                    <div class="main_list_num">{{ monthData('jul') }}</div>
                    <div class="main_list_month">7月</div>
                </div>
                <div class="main_list">
                    <div class="main_list_num">{{ monthData('aug') }}</div>
                    <div class="main_list_month">8月</div>
                </div>
                <div class="main_list">
                    <div class="main_list_num">{{ monthData('sep') }}</div>
                    <div class="main_list_month">9月</div>
                </div>
                <div class="main_list">
                    <div class="main_list_num">{{ monthData('oct') }}</div>
                    <div class="main_list_month">10月</div>
                </div>
                <div class="main_list">
                    <div class="main_list_num">{{ monthData('nov') }}</div>
                    <div class="main_list_month">11月</div>
                </div>
                <div class="main_list">
                    <div class="main_list_num">{{ monthData('dec') }}</div>
                    <div class="main_list_month">12月</div>
                </div>
            </div>
        </div>
        <!-- E 月份单数小计 -->
        <!-- S 全年单数小计 -->
        <div class="month_total year_total">
            <div class="month_total_top">
                <div class="top_img">
                    <img src="../assets/statistics_list.png" alt="">
                </div>
                <div class="top_title">全年单数小计</div>
                <div class="top_num">{{ monthData('annual') }}</div>
            </div>
        </div>
        <!-- E 全年单数小计 -->
        <!-- S 列表部分 -->
        <div class="list-wrap" v-for="item in list_data">
            <div class="list-top">
                <div class="list-top-num">
                    <span class="list-label">序号</span>
                    <span>{{ item.id }}</span>
                </div>
            </div>
            <div class="list-handler">
                <div class="list-top-num">
                    <span class="list-label">顾问</span>
                    <span>{{ item.title }}</span>
                </div>
                <div class="list-label">单数</div>
            </div>
            <div class="list-month" style="margin-top: 0;">
                <div class="list-month-item">1月</div>
                <div class="list-month-item">2月</div>
                <div class="list-month-item">3月</div>
                <div class="list-month-item">4月</div>
                <div class="list-month-item">5月</div>
                <div class="list-month-item">6月</div>
            </div>
            <div class="list-number">
                <div class="list-month-item">{{ item.month_order1 }}</div>
                <div class="list-month-item">{{ item.month_order2 }}</div>
                <div class="list-month-item">{{ item.month_order3 }}</div>
                <div class="list-month-item">{{ item.month_order4 }}</div>
                <div class="list-month-item">{{ item.month_order5 }}</div>
                <div class="list-month-item">{{ item.month_order6 }}</div>
            </div>
            <div class="list-month">
                <div class="list-month-item">7月</div>
                <div class="list-month-item">8月</div>
                <div class="list-month-item">9月</div>
                <div class="list-month-item">10月</div>
                <div class="list-month-item">11月</div>
                <div class="list-month-item">12月</div>
            </div>
            <div class="list-number">
                <div class="list-month-item">{{ item.month_order7 }}</div>
                <div class="list-month-item">{{ item.month_order8 }}</div>
                <div class="list-month-item">{{ item.month_order9 }}</div>
                <div class="list-month-item">{{ item.month_order10 }}</div>
                <div class="list-month-item">{{ item.month_order11 }}</div>
                <div class="list-month-item">{{ item.month_order12 }}</div>
            </div>
            <div class="list-handler" style="margin: 20px auto 0;">
                <div class="list-top-num" style="margin-bottom: 0;">
                    <span class="list-label">全年累计单数</span>
                    <span>{{ item.total }}</span>
                </div>
            </div>
        </div>
        <div class="empty-data" v-if="list_data.length===0">暂无数据</div>
        <!-- E 列表部分 -->
        <!-- S 类型弹层 -->
        <van-popup v-model:show="source_show" position="bottom">
            <van-picker
                    title="请选择类型"
                    show-toolbar
                    :columns="options"
                    @confirm="onFinish"
                    @cancel="source_show = false"
            />
        </van-popup>
        <!-- E 类型弹层 -->
    </div>
</template>
<script>
import {Notify} from "vant";
import {ref} from "vue";
import {statisticsOrder} from "@/api/java.js";

export default {
    name: "typeStatistics",
    data() {
        return {
            total: '0', // 总条数
            month_data: [], //月数据
            list_data: [], // 列表数据
            clear_source_show: true, // 类型重置按钮
            /* ---- 类型部分 ---- */
            source_show: false,
            fieldValue: '',
            options: ['顾问', '项目', '公司'], // 类型数据
            empty_data: false, // 暂无数据
        }
    },
    // 方法集合
    methods: {
        // 返回上一页
        goBackPage() {
            this.$router.go(-1)
        },
        /**
         * 类型部分逻辑(搜索)
         * */
        // 类型弹窗
        sourceShow() {
            this.source_show = true // 类型popup弹层
        },
        // 类型确认
        onFinish(value) {
            this.source_show = false;
            this.fieldValue = value;
            this.clear_source_show = false // 切换重置图标
        },
        // 类型重置
        clearableSource() {
            this.fieldValue = '' // 清空类型回显
            this.clear_source_show = true // 切换重置图标
        },
        //月份总数
        monthData(e) {
            let num = 0
            if (this.month_data) {
                this.month_data.forEach(val => {
                    if (val.name === e) {
                        num = val.count
                    }
                })
            }
            return num
        },
        // 点击搜索
        search() {
            if (!this.fieldValue) {
                Notify({type: 'danger', message: '请选择类型'});
                return false
            }
            statisticsOrder({type: this.options.indexOf(this.fieldValue) + 1}).then(data => {
                if (data.code === 200) {
                    this.month_data = data.data.total
                    this.list_data = data.data.data
                } else {
                    Notify({type: 'danger', message: data.message});
                }
            }).catch(() => {
                Notify({type: 'danger', message: '服务连接失败'});
            })
        },
    },
    created() {
        window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
        this.fieldValue = this.options[0]
        this.search()
    },
}
</script>
<style scoped>
/* ---- 筛选部分 ---- */
.detail-w {
    width: 345px;
    margin: 0 auto;
}

.screen {
    position: relative;
    height: 182px;
    margin-top: 15px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
}

.search-term {
    padding-top: 20px;
}

.search-term-label {
    padding: 0 20px;
    color: #A1A7BA;
    font-size: 15px;
}

.search-term-detail {
    position: relative;
    width: 303px;
    height: 38px;
    margin: 13px auto 0;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
}

.down-icon {
    position: absolute;
    top: 0;
    right: 15px;
    width: 12px;
    height: 100%;
    color: #606266;
}

.down-icon svg {
    position: relative;
    top: 11px;
}

.down-icon i {
    position: relative;
    top: 11px;
    left: -2px;
    color: #C9C7C8;
}

.time-clear i {
    top: 1px;
}

.vant-input-edit {
    height: 38px !important;
    line-height: 38px !important;
    padding: 0 10px !important;
    color: #222 !important;
    font-size: 15px !important;
}

.input-edit-time {
    padding: 0 !important;
    line-height: 40px !important;
}

.vant-input-edit >>> input::-webkit-input-placeholder {
    color: #606266 !important;
}

.contact-icon i {
    top: 1px;
}

.contact-icon svg {
    top: 0;
}

.screen-operation {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 25px;
    left: 20px;
    width: 305px;
}

.search-btn {
    width: 100%;
    height: 40px;
    background: #409EFF;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 15px;
}

/* 月份单数小计 */
.month_total {
    width: 345px;
    height: 218px;
    background: #FFFFFF;
    box-shadow: 0 3px 20px 1px rgba(108, 157, 209, 0.16);
    border-radius: 10px;
    opacity: 1;
    margin: 13px auto 0;
}

.month_total_top {
    display: flex;
    align-items: center;
    height: 54px;
    width: 305px;
    margin: 0 auto;
    border-bottom: 1px solid #DCDFE6;
}

.month_total_top .top_img {
    width: 13px;
    height: 13px;
    min-width: 13px;
    margin: 0 10px 3px 0;
}

.month_total_top img {
    width: 100%;
}

.month_total_top .top_title {
    font-size: 15px;
    font-weight: 400;
    color: #606266;
    line-height: 20px;
}

.month_total_main {
    margin-top: 22px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
}

.month_total_main .main_list {
    width: 54px;
    height: 51px;
    margin: 0 0 20px;
}

.month_total_main .main_list_num {
    font-size: 18px;
    font-weight: bold;
    color: #409EFF;
    line-height: 24px;
    text-align: center;
}

.month_total_main .main_list_month {
    font-size: 15px;
    font-weight: 400;
    color: #222222;
    line-height: 20px;
    margin-top: 7px;
    text-align: center;
    white-space: nowrap;
}

.year_total {
    width: 345px;
    height: 54px;
    background: #FFFFFF;
    box-shadow: 0 3px 20px 1px rgba(108, 157, 209, 0.16);
    border-radius: 10px;
    opacity: 1;
    margin-top: 15px;
}

.month_total_top .top_num {
    font-size: 18px;
    font-weight: bold;
    color: #409EFF;
    margin-left: 15px;
    line-height: 24px;
}

.year_total .month_total_top {
    border: none;
}

/* ---- 列表数据 ---- */
.list-wrap {
    margin: 15px auto;
    padding-bottom: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
    width: 345px;
    /*height: 292px;*/
}

/*.list-item {*/
/*  */
/*    margin: 0 auto;*/
/*}*/

.list-top {
    display: flex;
    justify-content: space-between;
    width: 305px;
    height: 50px;
    margin: 0 auto 15px;
    border-bottom: 1px solid #DDE0E7;
}

.list-handler {
    width: 305px;
    margin: 0 auto 8px;
}

.list-top-num {
    padding-top: 17px;
    color: #222;
    font-size: 15px;
    line-height: 20px;
}

.list-handler .list-top-num {
    padding-top: 0;
    margin-bottom: 20px;
}

.list-label {
    padding-right: 10px;
    color: #A1A7BA;
}

.list-month {
    /*width: 100%;*/
    height: 24px;
    background: #EDEFF3;
    opacity: 0.43;
    display: flex;
    justify-content: space-evenly;
    margin: 15px 0 0;
    padding: 0 10px;
    box-sizing: border-box;
}

.list-number {
    /*width: 100%;*/
    font-size: 15px;
    font-weight: 400;
    color: #222222;
    line-height: 20px;
    display: flex;
    justify-content: space-evenly;
    margin: 7px 0 15px;
    padding: 0 10px;
    box-sizing: border-box;
}

.list-month-item {
    width: 54px;
    white-space: nowrap;
    line-height: 20px;
    text-align: center;
}

.list-month .list-month-item {
    line-height: 24px;
}
</style>
