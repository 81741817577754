<template>
  <!-- <div> -->
  <van-popup v-model:show="visable" position="bottom" :style="{ height: height }"  @closed="this.closed()" @close="beforeClose">
    <van-search :placeholder="placeholder" v-model="interpolateSearch" />
    <!-- 判断是否有数据 -->
    <div v-if="userFilter().length > 0">
      <van-picker show-toolbar :columns="userFilter()"  @confirm="onConfirm" :value-key="valueName" @cancel="onCancel" />
    </div>
    <div v-else class="no-data">
      暂无数据
    </div>

  </van-popup>
  <!-- </div> -->
</template>

<script>
export default {
  name: 'searchPicker',
  props: {
    height:{
      type: String,
    },
    placeholder: {
      type: String,
      default: '请输入'
    },
    visable:{
      type:Boolean,
    },
    onConfirm:{
      type:Function,
    },
    onCancel:{
      type:Function,
    },
    list: { // 需要展示的数组
      type: Array,
    },
    valueName: { // 需要展示的数组的name
      type: String,
      default: ''
    },
    valueId: { // 需要展示的数组的id
      type: String || Number,
      default: ''
    },
  },
  data () {
    return {
      interpolateSearch: '' // 输入框数据
    }
  },
  created() {
  },
  methods: {
    userFilter () {
      let interpolateSearch = this.interpolateSearch.toLowerCase(); // 将查询字符串转换为小写以进行不区分大小写的搜索
      let results = [];
      if(this.list.length>0){
        for (let i = 0; i < this.list.length; i++) {
          let item = this.list[i] // 将数组中的元素转换为小写以进行不区分大小写的匹配
          let find_item=item[this.valueName]
          let item_return=find_item.toLowerCase();
          // 检查数组中的元素是否包含查询字符串
          if (item_return.includes(interpolateSearch)) {
            results.push(this.list[i]);
          }
        }
        return results
      }
    },
    closed(){
      this.interpolateSearch=''
    },
    beforeClose(){
      this.onCancel()
    }
  }
}
</script>

<style  scoped>
>>>.van-field__body input{
  text-align: left !important;

}
>>>.van-field__body input:-internal-autofill-selected{
    background-color: #F7F8FA!important;
}
.no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 54px);
    color: #999;
    font-size: 16px;
}
</style>

