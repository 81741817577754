import {request} from "../service/request"

let url = process.env.VUE_APP_BASE_URL_PHP // 接口地址
// 测试请求（后期可删除）
export function getAdminUser() {
  return request({
    url: url + '/index/user/info',
    method: 'get',
  });
}

// 动态url
function dynamicUrl(url) {
  if (url) {
    let exportUrl = '';
    Object.keys(url).map((key) => {
      exportUrl += key + '=' + url[key] + '&';
    })
    exportUrl = exportUrl.substring(exportUrl.length - 1, 0)
    return exportUrl;
  }
}

// -------客户转单模块请求------- //
// 客户转单模块 -转单列表数据-
export function getTransferList(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/customer/data?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 客户转单模块 -日志列表数据-
export function getJournalList(id) {
  return request({
    url: url + '/index/history/index?customerId=' + id,
    method: 'get',
  })
}

// 客户转单模块 -转单操作-
export function transferOrder(data) {
  return request({
    url: url + '/index/customer/change',
    method: 'post',
    data: data
  })
}

// -------客户公共部分接口请求------- //
// -顾问列表数据-
export function getAdviserList() {
  return request({
    url: url + '/index/user/sale',
    method: 'get',
  })
}

// -跟单记录列表数据-
export function getDocumentary(id) {
  return request({
    url: url + '/index/follow/index?customerId=' + id,
    method: 'get',
  })
}

// -添加跟单记录-
export function addDocumentary(data) {
  return request({
    url: url + '/index/follow/save',
    method: 'post',
    data: data
  })
}

// -获取客户详情-
export function getCustomerInfo(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/info/index?' + paraUrl,
    method: 'get',
  })
}

// -添加客户备注-
export function addCustomerRemark(data) {
  return request({
    url: url + '/index/content/save',
    method: 'post',
    data: data
  })
}

// -客户签证历史-
export function getVisaHistory(id) {
  return request({
    url: url + '/index/visa_history/index?id=' + id,
    method: 'get',
  })
}

// -添加/修改签证历史-
export function saveVisaHistory(data) {
  return request({
    url: url + '/index/visa_history/save',
    method: 'post',
    data: data
  })
}

// -删除签证历史-
export function deleteVisaHistory(data) {
  return request({
    url: url + '/index/visa_history/delete',
    method: 'post',
    data: data
  })
}

// -获取客户详情所在地-
export function getCustomerAddress() {
  return request({
    url: url + '/index/area/data',
    method: 'get',
  })
}

// -获取客户配偶信息-
export function getCustomerSpouse(user_phone) {
  return request({
    url: url + '/index/customer/spouse?user_phone=' + user_phone,
    method: 'get',
  })
}

// -修改客户详情信息-
export function saveCustomerDetail(data) {
  return request({
    url: url + '/index/customer/save',
    method: 'post',
    data: data
  })
}

// -------登陆模块接口请求------- //
// 登陆模块 -用户列表下拉-
export function getUser() {
  return request({
    url: url + '/index/user/all',
    method: 'get',
  })
}

// 登陆模块 -登录记录列表-
export function getRecord(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/record/index?' + paraUrl,
    method: 'get',
  })
}


// -------分配模块接口请求------- //
// 分配模块 -表格数据-
export function getAllocation(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/distribution/index?' + paraUrl,
    method: 'get',
  })
}

// -------首页模块接口请求------- //
// 首页模块 -今日待回访表格数据-
export function getToday() {
  return request({
    url: url + '/index/follow/today',
    method: 'get',
  })
}

// 首页模块 -上方搜索功能数据-
export function getInfo(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/info/info?' + paraUrl,
    method: 'get',
  })
}

// -------共享池/已签约/客户管理部分接口请求------- //
// -共享池数据列表-
export function getSharedPool(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/customer/shared?' + paraUrl,
    method: 'get',
  })
}

// -来源共享池下拉搜索-
export function downSource() {
  return request({
    url: url + '/index/source/down_data',
    method: 'get',
  })
}

// -来源共享池下拉搜索-
export function downTag() {
  return request({
    url: url + '/index/tag/down_data',
    method: 'get',
  })
}

// -已签约数据列表-
export function getSigned(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/info/deal?' + paraUrl,
    method: 'get',
  })
}

// -申报合格资源-
export function declareResources(data) {
  return request({
    url: url + '/index/info/qualified',
    method: 'post',
    data: data
  })
}

// -客户管理列表-
export function getCustomerAdmin(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/info/data?' + paraUrl,
    method: 'get',
  })
}

// -客户添加-
export function addCustomer(data) {
  return request({
    url: url + '/index/info/save_data',
    method: 'post',
    data: data
  })
}

// -------客户公共部分接口请求------- //
// -退出登录-
export function logOut() {
  return request({
    url: url + '/index/token/out',
    method: 'get',
  })
}

// -类别列表-
export function categoryList() {
  return request({
    url: url + '/index/cat/name',
    method: 'get',
  })
}

// -------顾问权重管理部分接口请求------- //
// -获取列表数据-
export function getWeight() {
  return request({
    url: url + '/index/weight/index',
    method: 'get',
  })
}

// -修改/添加列表数据-
export function saveWeight(data) {
  return request({
    url: url + '/index/weight/save_data',
    method: 'post',
    data: data
  })
}

// -权重管理删除-
export function delWeight(data) {
  return request({
    url: url + '/index/weight/delete_data',
    method: 'post',
    data: data
  })
}


// -11-19 新增录入人 模块    列表数据-
export function gerAllRecord() {
  return request({
    url: url + '/index/user/operate',
    method: 'get',
  })
}

// -2022-01-29 客户回访-
export function returnVisit(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/customer/visit?' + paraUrl,
    method: 'get',
  })
}

// -2022-01-29 修改客户回访-
export function returnVisitDo(data) {
  return request({
    url: url + '/index/return_visit/data',
    method: 'post',
    data: data
  })
}

// -2022-02-18  首页 本季度数据-
export function mobileInfo() {
  return request({
    url: url + '/index/info/mobile',
    method: 'post',
  })
}


// -2022-02-24  首页 移动端 获取用户身份、菜单数据-
export function mobileLogin() {
  return request({
    url: url + '/index/user/menu',
    method: 'post',
  })
}


// -2022-03-29  文案交接 数据-
export function handoverData(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/progress/index?' + paraUrl,
    method: 'get',
  })
}

// -2022-03-29 文件上传
export function handoverImg(data) {
  return request({
    url: url + '/index/progress/upload',
    method: 'post',
    data: data
  })
}

// -2022-03-30 交接客户
export function handoverSave(data) {
  return request({
    url: url + '/index/progress/save_data',
    method: 'post',
    data: data
  })
}


/**
 * 交接审批
 * */
// 列表数据
// -2022-01-29 客户回访-
export function approveList(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/progress/data?' + paraUrl,
    method: 'get',
  })
}

// -2022-03-22 文案下拉数据-
export function copywriting() {
  return request({
    url: url + '/index/user/copyWriting',
    method: 'get',
  })
}

// -2022-03-22 运营经理下拉-
export function operationsDown() {
  return request({
    url: url + '/index/user/operationsManager',
    method: 'get',
  })
}

// -2022-03-22 分配运营经理或者文案-
export function doDistribution(data) {
  return request({
    url: url + '/index/progress/update',
    method: 'post',
    data: data
  })
}


// -2022-03-31 项目管理-

// 项目管理列表
export function projectData() {
  return request({
    url: url + '/index/project/index',
    method: 'get',
  })
}

// 项目管理新增 / 修改
export function projectAdd(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/project/save_data?' + paraUrl,
    method: 'post',
    data: data
  })
}

/**
 * 2022-03-31 默认选项
 * */
// -2022-03-31 默认选项-数据列表-
export function optionData(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/option/index?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 项目管理下拉
export function projectSelect() {
  return request({
    url: url + '/index/project/name',
    method: 'get',
  })
}

// 默认选项-删除数据-
export function optionDel(data) {
  return request({
    url: url + '/index/option/delete_data',
    method: 'post',
    data: data
  })
}

// -2022-4-1 默认选项-新增数据-
export function optionAdd(data) {
  return request({
    url: url + '/index/option/save_data',
    method: 'post',
    data: data
  })
}

// -2022-03-24 默认选项-修改数据-
export function optionUpdate(data) {
  return request({
    url: url + '/index/option/update_data',
    method: 'post',
    data: data
  })
}


/**
 * 客户列表
 * */
// 列表数据
export function customerListData(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/progress/customer_progress?' + paraUrl,
    method: 'get',
  })
}

// 列表数据
export function customerCostData(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/progress/cost_data?' + paraUrl,
    method: 'get',
  })
}

// 为某客户新增（修改）文案进度 / 费用 / 申请进度
export function savePublicData(data) {
  return request({
    url: url + '/index/progress/save_public_data',
    method: 'post',
    data: data
  })
}

// 某类别常用数据
export function oftenTagData(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/option/data?' + paraUrl,
    method: 'get',
  })
}

// 删除某客户的文案进度 / 费用 / 申请进度
export function deleteProgress(data) {
  return request({
    url: url + '/index/progress/delete_progress',
    method: 'post',
    data: data
  })
}

// 某客户文案进度
export function progressData(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/progress/progress_data?' + paraUrl,
    method: 'get',
  })
}

// 某客户申请进度
export function applyData(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/progress/apply_data?' + paraUrl,
    method: 'get',
  })
}

// 申请进度常用标签
export function applyOftenTag() {
  return request({
    url: url + '/index/option/tab',
    method: 'get',
  })
}

/**
 * 2022-04-12 获取某客户交接审批状态
 * */
// 获取某客户交接审批状态
export function approvalStatus(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/progress/customer_progress_info?' + paraUrl,
    method: 'get',
  })
}

/**
 * 2022-04-12 客户详情 - 无效原因
 * */
// 获取某客户交接审批状态
export function invalidReasonData() {
  return request({
    url: url + '/index/tag/down_data2',
    method: 'get',
  })
}


/**
 * 2022-04-19合同管理
 * */
// 合同管理列表
export function contractData(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/contract/index?' + paraUrl,
    method: 'get',
  })
}

// 签约项目下拉
export function signProject() {
  return request({
    url: url + '/index/signed_projects/data',
    method: 'get',
  })
}


/**
 * 2023-02-23日报部分
 * */
// 日报查询
export function localData(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/daily_paper/local_data?' + paraUrl,
    method: 'get',
  })
}


// 日报顾问下拉
export function localEnter(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/daily_paper/adviser_down?' + paraUrl,
    method: 'get',
  })
}


// 日报获取来源下拉数据
export function localDown(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/daily_paper/source_down?' + paraUrl,
    method: 'get',
  })
}

// 日报获取来源下拉数据
export function localWrite() {
  return request({
    url: url + '/index/daily_paper/enter_down',
    method: 'get',
  })
}

// 日报获取来源下拉数据
export function customerNewData(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/index/daily_paper/adviser_customer2?' + paraUrl,
    method: 'get',
  })
}

// 2023-10-20 新增抢单池
// 抢单列表
export function getsOrdersList() {
  return request({
    url: 'https://crm-api-test.immiknow.com/crm/seize-orders/seizeOrdersList',
    method: 'get',
  })
}

// 抢单
export function getsOrdersIndex(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: 'https://crm-api-test.immiknow.com/crm/seize-orders/update?' + paraUrl,
    method: 'get',
  })
}

