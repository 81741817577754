<template>
    <div style="height: 100%">
        <div class="content-wrap">
            <!-- S 输入框模块 -->
            <div class="upload-title">二维码名称<span class="required-icon">*</span></div>
            <div class="info-input">
                <van-field class="vant-input-edit" v-model="code_title" autocomplete="off" clearable
                           @focus="focusIpt"
                           @blur="blurIpt"
                           placeholder="请输入二维码名称..."/>
            </div>
            <!-- E 输入框模块 -->
            <div class="content-data-box">
                <div class="upload-title">上传二维码<span class="required-icon">*</span></div>
                <div class="upload-list" v-for="(item, i) in imgData" :data-id="item.id">
                    <div class="upload-index">
                        <div class="upload-del" @click="imgDel(item)">
                            <img src="../assets/upload-dels.png" alt="">
                        </div>
                        <img class="upload-index-img" :src="item.url" alt="">
                    </div>
                </div>
                <div v-if="addStates">
                    <van-uploader  :after-read="imgRead" accept="image/*">
                        <div class="upload-btn"></div>
                    </van-uploader>
                </div>
                <div class="upload-btns" v-if="showStates">
                    <van-loading size="24px" vertical>加载中...</van-loading>
                </div>
                <!-- E 跟单内容 -->
                <div class="btn-box">
                    <div class="submit-save" @click="save()">确定</div>
                    <div class="submit-cancel" @click="cancel()">取消</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {Dialog, Notify} from "vant";
    import {handoverImg, userUpCode} from "../api/java";

    export default {
        name: "demandTransfer",
        data() {
            return {
                addStates: true,
                fileList: [],
                pdfData: [],
                imgData: [],
                upload_dom: false,
                download_dom: false,
                voucher: '',
                bottom_nav_visible: true,
                iosStates: false,
                /* ---- 监听软键盘是否弹出 ---- */
                defaultHeight: '0',  //默认屏幕高度
                nowHeight: '0',  //实时屏幕高度
                green_card_active: null, // 绿卡选中样式
                code_title: '',
                pageTitle: '',
                id: '',
                showStates: false
            }
        },
        created() {
            this.iosStates = sessionStorage.getItem("iosStates")
            window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
            if (this.$route.query.data == undefined) {
                this.pageTitle = '添加二维码'
                this.id = ''
            } else {
                this.pageTitle = '修改二维码'
                var pageData = JSON.parse(decodeURIComponent(this.$route.query.data))
                this.id = pageData.id
                this.code_title = pageData.code_title
                this.addStates = false
                this.imgData.push({
                    name: '',
                    url: pageData.code_path
                })
            }
        },

        // 获取屏幕默认高度与实时高度
        mounted: function () {
            //获取默认高度
            this.defaultHeight = document.documentElement.clientHeight
            window.onresize = () => {
                return (() => {
                    //实时屏幕高度
                    this.nowHeight = document.documentElement.clientHeight
                })();
            };
        },
        // 监听软键盘是否弹出
        watch: {
            nowHeight: function () {
                if (this.defaultHeight !== this.nowHeight) {
                    this.bottom_nav_visible = false
                } else {
                    this.bottom_nav_visible = true
                }
            }
        },

        methods: {
            strFunc(name_str) {
                let file_name = name_str.lastIndexOf("\/");
                name_str = name_str.substring(file_name + 1, name_str.length);
                return name_str
            },
            imgRead(file, detail) {
                file.status = 'uploading';
                file.message = '上传中...';
                let formData = new FormData();
                formData.append("file", file.file);
                if (this.imgData.length > 0) {
                    Notify({type: 'danger', message: '每次只能上传一张凭证'});
                    return
                }
                this.addStates = false
                this.showStates =true
                handoverImg(formData).then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        this.imgData.push({
                            name: '',
                            url: data.message
                        })
                        this.addStates = false
                        this.showStates =false
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    this.addStates = false
                    this.showStates =false
                    Notify({type: 'danger', message: '服务连接失败'});
                })

            },
            imgDel() {
                Dialog.confirm({
                    message: '确认删除本条数据吗?',
                })
                    .then(() => {
                        this.imgData = []
                        this.addStates = true
                    })
                    .catch(() => {
                    });
            },
            goBackPage() {
                this.$router.go(-1)
            },
            // 苹果键盘事件
            blurIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = true
                    return
                }
            },
            // 苹果键盘事件
            focusIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = false
                    return
                }
            },
            save() {
                if (!this.code_title) {
                    Notify({type: 'danger', message: '请填写二维码名称'})
                    return
                }
                if (this.imgData[0] === undefined) {
                    Notify({type: 'danger', message: '请上传二维码'})
                    return
                }
                let para = {
                    id: this.id,
                    code_path: this.imgData[0].url,
                    code_title: this.code_title,
                }
                userUpCode(para).then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        Notify({type: 'success', message: '添加成功'});
                        setTimeout(
                            this.$router.push({
                                path: '/personalSet',
                            }), 4000);
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            cancel() {
                this.$router.push({
                    path: '/personalSet',
                })
            },
        }
    }
</script>
<style scoped>
    .required-icon {
        padding-left: 3px;
        color: #FF0000;
    }

    .content-wrap {
        margin-top: 12px;
        padding: 10px 15px 20px;
        background: #fff;
    }

    /* ---- 下载PDF模块---- */
    .upload-btn {
        background: #67C23A;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        width: 95px;
        height: 95px;
        background: url(../assets/upload-btn.png) no-repeat center center/95px 95px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .upload-btns {
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        width: 95px;
        height: 95px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .upload-index {
        width: 95px;
        height: 95px;
        line-height: 45px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #DCDFE6;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        position: relative;
    }

    .upload-title {
        font-size: 15px;
        color: #A1A7BA;
        padding: 15px 0 15px;
    }

    .upload-index img {
        width: 15px;
        height: 15px;
        margin: 15px 0 0 15px;
    }

    .upload-del {
        position: absolute;
        right: -27px;
        top: -21px;
        width: 27px;
        height: 27px;
        display: flex;
        background: #FFFFFF;
        align-items: center;
        justify-content: center;
        border-radius: 50px 50px 50px 50px;
        margin-top: 9px;
        margin-right: 15px;
        z-index: 99;
    }

    .upload-del img {
        margin: 0;
        width: 23px;
        height: 23px;
    }

    .upload-img-btn img {
        width: 35px;
        height: 35px;
    }

    .download-item svg {
        width: 53px;
        color: #222;
        margin-top: 20px;
        font-size: 38px;
    }

    .download-item i {
        margin-top: 20px;
        font-size: 58px;
    }

    .download-icon svg {
        width: 15px;
        margin-top: 6px;
        color: #fff;
    }

    .info-textarea-edit >>> textarea::-webkit-input-placeholder {
        color: #606266 !important;
    }

    .info-textarea-edit >>> textarea {
        min-height: 88px;
    }


    .down-icon svg {
        position: relative;
        top: 11px;
    }

    .down-icon i {
        position: relative;
        top: 11px;
        left: -2px;
        color: #C9C7C8;
    }

    .radio-item >>> .van-radio__icon--round .van-icon {
        display: none;
    }

    .radio-item >>> .van-radio__label {
        position: relative;
        margin-left: 0;
        padding-left: 20px;
        z-index: 9;
    }

    .radio_active .radio-icon {
        border-color: #409EFF;
    }

    .radio_active .radio-icon-spot {
        background: #409EFF;
    }

    .upload-index .upload-index-img {
        margin: 6px auto;
        width: 84px;
        height: 84px;
    }

    .btn-box {
        padding-top: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .submit-save {
        width: 162px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: #409EFF;
        border-radius: 40px;
        font-size: 17px;
        color: #FFFFFF;
    }

    .submit-cancel {
        width: 160px;
        height: 48px;
        line-height: 50px;
        text-align: center;
        background: #FFFFFF;
        border-radius: 40px;
        font-size: 17px;
        color: #409EFF;
        border: 1px solid #409EFF;
    }

    .info-input {
        position: relative;
        width: 343px;
        height: 43px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .info-input >>> .van-field__control {
        color: #222;
    }
</style>
