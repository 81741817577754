<template>
    <div>
        <!-- S 头部header -->
        <div class="header">
            <div class="header-nav">
                <div class="header-nav-back" @click="goBackPage()">
                    <van-icon name="arrow-left"/>
                </div>
                <span>入职两月签约统计</span>
            </div>
        </div>
        <!-- E 头部header -->
        <!-- S 筛选条件部分 -->
        <div class="screen detail-w">
            <div class="search-term">
                <div class="search-term-label">录入时间</div>
                <div class="search-term-detail time-slot-box">
                    <div class="time-slot" @click="inputStartTime">
                        <van-field class="vant-input-edit input-edit-time" v-model="input_start_time" readonly
                                   placeholder="请输入开始时间"/>
                    </div>
                    <div class="time-slot-center">至</div>
                    <div class="time-slot" @click="inputEndTime">
                        <van-field class="vant-input-edit input-edit-time" v-model="input_end_time" readonly
                                   placeholder="请输入结束时间"/>
                    </div>
                    <div class="down-icon time-clear" v-if="enter_clear_time" @click="enterTimeClear">
                        <van-icon name="clear"/>
                    </div>
                </div>
            </div>
            <div class="screen-operation">
                <div class="search-btn" @click="search(true)">
                    <img src="../assets/search.png" alt="">
                    搜索
                </div>
            </div>
        </div>
        <!-- E 筛选条件部分 -->
        <!-- S 签约统计列表 -->
        <div class="list-wrap" v-for="item in list_data">
            <div class="list-handler">
                <div class="list-top-num">
                    <span class="list-label" style="padding-right: 25px;">顾问ID</span>
                    <span>{{ item.id }}</span>
                </div>
            </div>
            <div class="list-handler">
                <div class="list-top-num">
                    <span class="list-label">顾问名称</span>
                    <span>{{ item.user_name }}</span>
                </div>
            </div>
            <div class="list-handler" style="margin-bottom: 13px;">
                <div class="list-top-num">
                    <span class="list-label">入职时间</span>
                    <span>{{ formatDate(item.entry_time) }}</span>
                </div>
            </div>
        </div>
        <div class="empty-data" v-if="list_data.length===0">暂无数据</div>
        <!-- E 签约统计列表 -->
        <!-- S 录入开始时间弹层 -->
        <van-popup v-model:show="input_start_show" position="bottom" :style="{ height: '48%' }">
            <van-datetime-picker
                    v-model="input_start_date"
                    type="date"
                    title="选择开始时间"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :formatter="formatter"
                    @confirm="inputStartConfirm"
                    @cancel="inputStartCancel"
            />
        </van-popup>
        <!-- E 录入开始时间弹层 -->
        <!-- S 录入结束时间弹层 -->
        <van-popup v-model:show="input_end_show" position="bottom" :style="{ height: '48%' }">
            <van-datetime-picker
                    v-model="input_end_date"
                    type="date"
                    title="选择结束时间"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :formatter="formatter"
                    @confirm="inputEndConfirm"
                    @cancel="inputEndCancel"
            />
        </van-popup>
        <!-- E 录入结束时间弹层 -->
    </div>
</template>
<script>
import {Notify} from "vant";
import moment from "moment";
import {getEntryTimeList,} from '@/api/java'

export default {
    components: {},
    props: {},
    data() {
        return {
            total: '0', // 总条数
            /* ---- 时间选择 ---- */
            input_time: '', // 录入时间
            input_start_show: false, // 录入时间开始弹层
            input_end_show: false, // 录入时间结束弹层
            input_start_time: '', // 录入开始时间
            input_end_time: '', // 录入结束时间

            list_data: [], // 列表数据
            /* ---- 时间组件 ---- */
            minDate: new Date(1970, 0, 1),
            maxDate: new Date(2122, 0, 1),
            input_start_date: new Date(), // 录入开始当前时间
            input_end_date: new Date(), // 录入结束当前时间
            enter_clear_time: false, // 录入时间重置按钮
            empty_data: false, // 暂无数据
        }
    },
    // 方法集合
    methods: {
        // 返回上一页
        goBackPage() {
            this.$router.go(-1)
        },
        formatter(type, val) {
            if (type === 'year') {
                return `${val}年`;
            } else if (type === 'month') {
                return `${val}月`;
            } else {
                return `${val}日`;
            }
        },
        // 录入时间重置
        enterTimeClear() {
            this.enter_clear_time = false // 隐藏重置按钮
            this.input_start_time = '' // 清空录入时间(开始)
            this.input_end_time = '' // 清空录入时间(结束)
        },
        // 时间戳转化(年-月-日 时:分:秒)
        formatDate(time) {
            return moment(time * 1000).format("YYYY-MM-DD")
        },
        // 时间戳转化(年-月-日)
        formatDate2(time) {
            return moment(time).format("YYYY-MM-DD")
        },
        /**
         * 录入开始时间部分
         * */
        inputStartTime() {
            this.input_start_show = true
        },
        // 确认事件
        inputStartConfirm(val) {
            this.input_start_time = this.formatDate2(val)
            let enter_star_time = new Date(this.input_start_time)
            let enter_end_time = new Date(this.input_end_time)
            if (enter_end_time.getTime() <= enter_star_time.getTime()) {
                Notify({type: 'danger', message: '开始时间不能大于或等于结束时间'});
                this.input_start_time = ''
                return
            }
            this.input_start_show = false
            this.enter_clear_time = true // 展示重置按钮
        },
        // 取消事件
        inputStartCancel() {
            this.input_start_show = false
        },
        /**
         * 录入结束时间部分
         * */
        inputEndTime() {
            this.input_end_show = true
        },
        // 确认事件
        inputEndConfirm(val) {
            this.input_end_time = this.formatDate2(val)
            let enter_star_time = new Date(this.input_start_time)
            let enter_end_time = new Date(this.input_end_time)
            if (enter_end_time.getTime() <= enter_star_time.getTime()) {
                Notify({type: 'danger', message: '开始时间不能大于或等于结束时间'});
                this.input_end_time = ''
                return
            }
            this.input_end_show = false
            this.enter_clear_time = true // 展示重置按钮
        },
        // 取消事件
        inputEndCancel() {
            this.input_end_show = false
        },
        // 点击搜索
        search() {
            if (!(this.input_start_time && this.input_end_time)) {
                Notify({type: 'danger', message: '请选择开始时间及结束时间'});
                return false
            }
            let para = {
                start_time: this.input_start_time, // 开始时间
                end_time: this.input_end_time, // 结束时间
            }
            getEntryTimeList(para).then(data => {
                if (data.code === 200) {
                    this.list_data = data.data
                    this.total = data.count
                } else {
                    Notify({type: 'danger', message: data.message});
                }
            }).catch(() => {
                Notify({type: 'danger', message: '服务连接失败'});
            })
        },
        //时间默认值
        defaultTime() {
            this.input_end_time = this.formatDate(Math.round(new Date() / 1000))
            this.input_start_time = this.formatDate(Math.round(new Date() / 1000) - 5184000)
        },
        // 时间组件默认回显
        timeItem() {
            this.input_start_date = new Date(new Date().getTime() - (24 * 3600 * 1000) * 60)// 结束时间默认值
            this.input_end_date = new Date() // 开始时间默认值
        },
    },
    created() {
        window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
        this.timeItem() //回显时间
        this.defaultTime()//赋值默认时间
        this.search() //默认两个月数据
    },
    mounted() {
    },
}
</script>
<style scoped>
/* ---- 筛选部分 ---- */
.detail-w {
    width: 345px;
    margin: 0 auto;
}

.screen {
    position: relative;
    height: 182px;
    margin-top: 15px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
}

.search-term {
    padding-top: 19px;
}

.search-term-label {
    padding: 0 20px;
    color: #A1A7BA;
    font-size: 15px;
}

.search-term-detail {
    position: relative;
    width: 303px;
    height: 38px;
    margin: 13px auto 0;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
}

.down-icon {
    position: absolute;
    top: 0;
    right: 15px;
    width: 12px;
    height: 100%;
    color: #606266;
}

.down-icon svg {
    position: relative;
    top: 11px;
}

.down-icon i {
    position: relative;
    top: 11px;
    left: -2px;
    color: #C9C7C8;
}

.time-clear i {
    top: 1px;
}

.vant-input-edit {
    height: 38px !important;
    line-height: 38px !important;
    padding: 0 10px !important;
    color: #222 !important;
    font-size: 15px !important;
}

.input-edit-time {
    padding: 0 !important;
    line-height: 40px !important;
}

.vant-input-edit >>> input::-webkit-input-placeholder {
    color: #606266 !important;
}

.contact-icon i {
    top: 1px;
}

.contact-icon svg {
    top: 0;
}

.screen-operation {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 25px;
    left: 20px;
    width: 305px;
}

.search-btn {
    width: 100%;
    height: 40px;
    background: #409EFF;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 15px;
}


.time-slot-box {
    display: flex;
    width: 285px;
    padding: 0 10px;
    line-height: 40px;
}

.time-slot {
    width: 135px;
    height: 38px;
    color: #606266;
}

.time-slot-center {
    margin-right: 10px;
    color: #222;
    font-size: 15px;
}

/* ---- 列表数据 ---- */
.list-wrap {
    margin: 15px auto;
    padding: 5px 0;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
    width: 345px;
}

.list-handler {
    width: 305px;
    margin: 0 auto;
}

.list-top-num {
    padding-top: 13px;
    color: #222;
    font-size: 15px;
    line-height: 20px;
}

.list-label {
    padding-right: 10px;
    color: #A1A7BA;
}
</style>
