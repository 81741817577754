<template>
  <div>
    <!-- S 头部header -->
    <div class="header">
      <div class="header-page">
        <div class="page-info">
          <span>共</span>
          <span class="page-num">{{total}}</span>
          <span>条/</span>
          <span class="page-num">{{sum_page}}</span>
          <span>页，</span>
          <span>当前第</span>
          <span class="page-num">{{current_page}}</span>
          <span>页</span>
        </div>
        <div class="page-btn">
          <div class="page-input">
            <van-field class="page-jump" v-model="page_num" @keyup.enter.native="jumpPage()" autocomplete="off"
                       type="digit"/>
          </div>
          <div class="jump-btn" @click="jumpPage()">跳转</div>
        </div>
      </div>
    </div>
    <!-- E 头部header -->

    <!-- S 筛选条件部分 -->
    <div class="screen detail-w">
      <div class="search-term">
        <div class="search-term-label">签约顾问</div>
        <div class="search-term-detail" @click="adviserPopup">
          <van-field class="vant-input-edit" v-model="adviser_info" clearable readonly placeholder="请选择"/>
          <div class="down-icon" v-if="clear_adviser_show">
            <caret-bottom/>
          </div>
          <div class="down-icon" @click.stop="clearableAdviser" v-else>
            <van-icon name="clear"/>
          </div>
        </div>
      </div>
      <div class="search-term">
        <div class="search-term-label">客户名称</div>
        <div class="search-term-detail">
          <van-field class="vant-input-edit" v-model="customer_name" clearable placeholder="请输入客户名称"
                     @focus="focusIpt"
                     @blur="blurIpt"
                     autocomplete="off"/>
        </div>
      </div>
      <div class="search-btn" @click="search()">
        <img src="../assets/search.png" alt="">
        搜索
      </div>
    </div>
    <!-- E 筛选条件部分 -->

    <van-list
        v-model:loading="loading"
        :finished="finished"
        :finished-text="no_more"
        :offset="0"
        @load="onLoad"
    >
      <!-- S 列表部分 -->
      <div class="list-wrap" v-for="item in list_data">
        <div class="list-item detail-w">
          <div class="list-top">
            <div class="list-top-num">
              <span class="list-label">序号</span>
              <span>{{item.customer_id}}</span>
            </div>
            <div class="list-top-num">
              <span>{{formatDate(item.create_time)}}</span>
            </div>
          </div>
          <div class="list-info">
            <div class="info-label">客户名称</div>
            <div>{{item.user_name}}</div>
          </div>
          <div class="list-info">
            <div class="info-label">签约项目</div>
            <div v-html="item.project"></div>
          </div>
          <div class="list-info">
            <div class="info-label">跟单顾问</div>
            <div>{{item.adviser_name}}</div>
          </div>
          <div class="list-info one-line">
            <div class="list-info-item">
              <div class="info-label">运营主管</div>
              <div class="one-line-info">{{item.operations_director_name}}</div>
            </div>
            <div class="list-info-item" style="margin-left: 63px">
              <div class="info-label">运营经理</div>
              <div class="one-line-info">{{item.official_service_name}}</div>
            </div>
          </div>
          <div class="list-info one-line">
            <div class="list-info-item">
              <div class="info-label">文案主管</div>
              <div class="one-line-info">{{item.copywriter_supervisor_name}}</div>
            </div>
            <div class="list-info-item" style="margin-left: 63px">
              <div class="info-label">文案</div>
              <div class="one-line-info">{{item.copyright_name}}</div>
            </div>
          </div>
          <div class="list-info one-line">
            <div class="list-info-item">
              <div class="info-label">运营审批</div>
              <div class="one-line-info">
                <span v-if="item.operations_status === 0" style="color: #F56C6C; font-size: 15px;">未审核</span>
                <span v-if="item.operations_status === 1" style="color: #409EFF; font-size: 15px;">已审核</span>
              </div>
            </div>
            <div class="list-info-item" style="margin-left: 63px">
              <div class="info-label">文案审批</div>
              <div class="one-line-info">
                <span v-if="item.copyright_status === 0" style="color: #F56C6C; font-size: 15px;">未审核</span>
                <span v-if="item.copyright_status === 1" style="color: #409EFF; font-size: 15px;">已审核</span>
              </div>
            </div>
          </div>
          <div class="operate-info">
            <div class="operate-center" @click="detailList(item.customer_id)">详情</div>
            <div class="operate-center" @click="examine(item)">审核</div>
          </div>
        </div>
      </div>
      <div class="empty-data" v-if="empty_data">暂无数据</div>
      <!-- E 列表部分 -->
    </van-list>

    <!-- S 顾问弹层 -->
    <van-popup v-model:show="adviser_show" position="bottom" :style="{ height: '48%' }">
      <van-picker
          title="请选择顾问"
          show-toolbar
          :columns="adviser_data"
          @confirm="adviserConfirm"
          @cancel="adviserCancel"
      />
    </van-popup>
    <!-- E 顾问弹层 -->


    <!-- S 底部导航栏 -->
    <div class="occupy-box"></div>
    <!-- S 两个底部导航栏 -->
    <div v-if="twoStates">
      <van-tabbar v-model="active" v-if="bottom_nav_visible">
        <van-tabbar-item replace to="/index">
          <span>首页</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <home-filled/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/customSchedule">
          <span>客户列表</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <PieChart/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
    <!-- S 两个底部导航栏 -->
    <!-- S 三个底部导航栏 -->
    <div v-if="threeStates">
      <van-tabbar v-model="active" v-if="bottom_nav_visible">
        <van-tabbar-item replace to="/index">
          <span>首页</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <home-filled/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/approve">
          <span>交接审批</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <Coordinate/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/customSchedule">
          <span>客户列表</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <PieChart/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
    <!-- S 三个底部导航栏 -->

    <!-- S 四个底部导航栏 -->
    <div v-if="fourStates">
      <van-tabbar v-model="active" v-if="bottom_nav_visible">
        <van-tabbar-item replace to="/index">
          <span>首页</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <home-filled/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/customManage">
          <span>客户管理</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <user/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/signedResources">
          <span>已签约</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <edit/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/sharedPool">
          <span>共享池</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <monitor/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
    <!-- S 四个底部导航栏 -->
  </div>
</template>

<script>
  import {approveList, getAdviserList, getSigned} from '../api/index'
  import {Notify} from 'vant';
  import {ref} from "vue";
  import moment from "moment";

  export default {
    data() {
      return {
        /* ---- 触底加载 ---- */
        loading: false,
        finished: false,
        searchStates: false,  // 不走 onload

        /*---- 下拉重置 ----*/
        clear_adviser_show: true, // 签约顾问重置按钮

        page_num: '0', // 去第几页
        current_page: '1', // 当前第几页
        total: '0', // 总条数
        sum_page: '0', // 总页数
        customer_name: '', // 客户名称
        list_data: [], //列表数据

        /* ---- 跟单顾问部分 ---- */
        adviser_id: '', // 顾问id
        adviser_show: false, // 顾问弹出层
        adviser_info: '', // 跟单顾问
        request_adviser_data: [], // 接口返回顾问数据
        adviser_data: [], // vant 顾问数据形式

        empty_data: false, // 暂无数据
        no_more: '没有更多了',
        bottom_nav_visible: true, // 底部导航显示与隐藏
        iosStates:false , // 手机系统

        /* ---- 监听软键盘是否弹出 ---- */
        defaultHeight: '0',  //默认屏幕高度
        nowHeight:  '0',  //实时屏幕高度
        threeStates: false,//三底部栏
        twoStates: false,//三底部栏
        fourStates: false,//四底部栏
        postId: "" // 权限
      }
    },
    // 生命周期 初始化
    setup() {
      const active = ref(1); // 设定选定第几个
      return {
        active,
      };
    },

    // 生命周期
    created() {
      window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
      this.getList() // 列表数据
      this.getAdviserData() // 顾问数据

      this.searchStates = true
      this.iosStates = sessionStorage.getItem("iosStates")

      // 星迪修改（底部导航栏）
      this.postId = sessionStorage.getItem("postID")
      if (this.postId === '6' || this.postId === '5') { // 判断 文案 或者 运营经理
        this.threeStates = false // 不显示两个个底部
        this.twoStates = true// 不显示三个底部
        this.active = 1   //
      }
      if (this.postId === '3' || this.postId === '4') { // 判断 运营主管 或者 文案主管
        this.threeStates = true // 显示两个个底部
        this.twoStates = false// 不显示三个底部
        this.active = 1
      }
      if (this.postId === '0') { // 判断 管理员
        this.threeStates = false // 显示两个个底部
        this.twoStates = false// 不显示三个底部
        this.fourStates = true// 不显示三个底部
        this.active = null
      }
    },

    // 获取屏幕默认高度与实时高度
    mounted: function() {
      //获取默认高度
      this.defaultHeight = document.documentElement.clientHeight
      window.onresize = () => {
        return (() => {
          //实时屏幕高度
          this.nowHeight = document.documentElement.clientHeight
        })();
      };
    },

    // 监听软键盘是否弹出
    watch: {
      nowHeight:function() {
        if(this.defaultHeight!== this.nowHeight){
          this.bottom_nav_visible = false
        }else{
          this.bottom_nav_visible = true
        }
      }
    },

    methods: {

      /**
       * 列表部分
       * */
      // 列表数据
      onLoad(val) {
        if (this.searchStates == false) {
          if (!this.page_num) {
            this.page_num = 1
          }
          let para = {
            page: parseInt(this.page_num) + 1, // 当前第几页
            limit: '10', // 每页10条
            name: this.customer_name, // 客户名称
            adviser: this.adviser_id, // 顾问名称(id)
          }

          approveList(para).then(data => {
            if (data.code === 200) {
              // 暂无数据显示
              if (data.data.count === 0) {
                this.empty_data = true
                Notify({type: 'success', message: '搜索成功', duration: 1000});
                this.no_more = ''
              } else {
                this.empty_data = false
                this.no_more = '没有更多了'
              }
              this.total = data.data.count
              if (parseInt(data.data.count % 10) === 0) {
                this.sum_page = parseInt(data.data.count / 10)
              } else {
                this.sum_page = parseInt(data.data.count / 10) + 1
              }
              this.loading = false;
              // 数据全部加载完成
              if (data.data.data.length === 0) {
                this.finished = true; // 停止加载
                return
              }
              this.page_num++
              this.current_page = para.page
              this.list_data = this.list_data.concat(data.data.data) // 数组拼接
              if (val) {
                Notify({type: 'success', message: '搜索成功', duration: 1000});
                this.finished = false; // 继续加载
              }
            } else {
              Notify({type: 'danger', message: data.message});
            }
          }).catch(() => {
            Notify({type: 'danger', message: '服务连接失败'});
          })
        }
      },

      // 列表数据
      getList(val) {
        this.finished = false; // 停止加载
        this.searchStates = true
        let para = {
          page: parseInt(this.page_num) + 1, // 当前第几页
          limit: '10', // 每页10条
          name: this.customer_name, // 客户名称
          adviser: this.adviser_id // 顾问名称(id)
        }
        this.current_page = para.page
        approveList(para).then(data => {
          // 暂无数据显示
          if (data.data.count === 0) {
            this.empty_data = true
            this.no_more = ''
          } else {
            this.empty_data = false
            this.no_more = '没有更多了'
          }
          if (data.code === 200) {
            this.total = data.data.count
            if (parseInt(data.data.count % 10) === 0) {
              this.sum_page = parseInt(data.data.count / 10)
            } else {
              this.sum_page = parseInt(data.data.count / 10) + 1
            }
            this.loading = false;
            this.searchStates = false
            // 数据全部加载完成
            if (data.data.data.length === 0) {
              this.finished = true; // 停止加载
              return
            }
            this.page_num++
            this.list_data = this.list_data.concat(data.data.data) // 数组拼接
            if (val) {
              Notify({type: 'success', message: '搜索成功', duration: 1000});
              this.finished = false; // 继续加载
            }
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 跳转按钮
      jumpPage() {
        if (!this.page_num) {
          Notify('请输入页码')
          return false
        }

        if (this.page_num > this.sum_page) {
          this.page_num = this.sum_page
        }

        if (this.page_num < 1) {
          this.page_num = '1'
        }

        this.page_num = this.page_num - 1
        this.current_page = this.page_num
        this.list_data = []
        this.getList()
      },

      // 点击搜索
      search() {
        this.searchStates = false
        let search_tips = true // 点击搜索反馈信息
        this.current_page = '1'
        this.page_num = '0'
        this.list_data = []
        this.getList(search_tips)
      },

      // 审批按钮
      examine(data) {
        this.$router.push({
          path: '/approveOperation',
          query: {
            data: encodeURIComponent(JSON.stringify(data)), // vue路由传递对象
            // customer_id: this.query_id
          }
        })
      },

      // 跳转详情
      detailList(id) {
        this.$router.push({
          path: '/detailShow',
          query: {
            id: id // 客户id
          }
        })
      },

      // 获取顾问列表
      getAdviserData() {
        getAdviserList().then(data => {
          // 判断token
          if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403) {
            Notify({type: 'danger', message: data.message});
            sessionStorage.clear()
            this.$router.push({
              path: '/login'
            })
            return
          }
          if (data.code === 200) {
            this.request_adviser_data = data.data
            // 请求数据获取顾问数组格式化为vant下拉数组格式
            for (let i = 0; i < this.request_adviser_data.length; i++) {
              this.adviser_data.push(this.request_adviser_data[i].user_name) // vant 下拉数组赋值
            }
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      /**
       * 跟单顾问(搜索部分)
       * */
      // 跟单顾问弹层控制
      adviserPopup() {
        this.adviser_show = true
      },

      // 确认赋值
      adviserConfirm(value, index) {
        this.adviser_info = value // 顾问赋值
        // 根据选中顾问获取对应顾问id
        for (let i = 0; i < this.request_adviser_data.length; i++) {
          if (index === i) {
            this.adviser_id = this.request_adviser_data[i].id // 顾问id赋值
          }
        }
        this.adviser_show = false // 关闭弹层
        this.clear_adviser_show = false // 切换重置图标
      },

      // 取消选择
      adviserCancel() {
        this.adviser_show = false // 关闭弹层
      },

      /**
       * 重置下拉按钮
       * */
      // 重置签约顾问
      clearableAdviser() {
        this.adviser_info = '' // 清空回显
        this.adviser_id = '' // 清空请求时参数
        this.clear_adviser_show = true // 切换重置图标
      },

      // 苹果键盘事件
      blurIpt() {
        if(this.iosStates){
          this.bottom_nav_visible = true
          return
        }
      },
      // 苹果键盘事件
      focusIpt() {
        if(this.iosStates){
          this.bottom_nav_visible = false
          return
        }
      },

      // 时间戳转化(年-月-日 时:分:秒)
      formatDate(time) {
        return moment(time * 1000).format("YYYY-MM-DD HH:mm:ss")
      },
    }
  }
</script>

<style scoped>
  /* ---- header区域 ---- */
  .header {
    position: sticky;
    top: 0;
    /*height: 106px;*/
    height: 52px;
    background: #fff;
    box-shadow: 0 9px 16px rgba(25, 52, 139, 0.06);
    z-index: 9;
  }

  .header-nav {
    position: relative;
    height: 53px;
    border-bottom: 1px solid #F5F5FC;
    color: #222;
    font-size: 19px;
    text-align: center;
    line-height: 53px;
  }

  .header-nav div {
    position: absolute;
    left: 10px;
    width: 19px;
    height: 16px;
  }

  .header-page {
    display: flex;
    justify-content: space-between;
    height: 53px;
    padding: 0 15px;
  }

  .page-info {
    color: #A1A7BA;
    font-size: 15px;
    line-height: 53px;
  }

  .page-num {
    padding: 0 2px;
    color: #222;
  }

  .page-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .page-input {
    width: 43px;
    height: 28px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
  }

  .page-btn input {
    padding: 0;
    width: 43px;
    height: 28px;
    border: none;
    color: #A1A7BA;
    font-size: 15px;
    text-align: center;
  }

  .jump-btn {
    width: 49px;
    height: 30px;
    margin-left: 7px;
    background: #409EFF;
    border-radius: 4px;
    color: #fff;
    font-size: 15px;
    text-align: center;
    line-height: 30px;
  }

  .page-jump {
    padding: 0;
  }
    .page-jump >>> input {
        height: 30px;
        color: #A1A7BA;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
    }

  /* ---- 筛选部分 ---- */
  .detail-w {
    width: 345px;
    margin: 0 auto;
  }

  .screen {
    position: relative;
    margin-top: 15px;
    padding-bottom: 25px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
  }

  .search-term {
    padding-top: 15px;
  }

  .search-term-label {
    padding: 0 20px;
    color: #A1A7BA;
    font-size: 15px;
  }

  .search-term-detail {
    position: relative;
    width: 303px;
    height: 38px;
    margin: 10px auto 0;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
  }

  .down-icon {
    position: absolute;
    top: 0;
    right: 15px;
    width: 12px;
    height: 100%;
    color: #606266;
  }

  .down-icon svg {
    position: relative;
    top: 11px;
  }

  .down-icon i {
    position: relative;
    top: 11px;
    left: -2px;
    color: #C9C7C8;
  }

  .vant-input-edit {
    height: 38px !important;
    line-height: 38px !important;
    padding: 0 10px !important;
    color: #222 !important;
    font-size: 15px !important;
  }

  .vant-input-edit >>> input::-webkit-input-placeholder {
    color: #606266 !important;
  }

  .search-btn {
    width: 305px;
    height: 40px;
    margin: 25px auto 0;
    background: #409EFF;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 15px;
  }


  /* ---- 列表数据 ---- */
  .list-wrap {
    margin: 15px 0;
  }

  .list-item {
    padding-bottom: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
  }

  .list-top {
    display: flex;
    justify-content: space-between;
    width: 305px;
    height: 45px;
    margin: 0 auto 16px;
    border-bottom: 1px solid #DDE0E7;
  }

  .list-top-num {
    padding-top: 17px;
    color: #222;
    font-size: 15px;
  }

  .list-label {
    padding-right: 8px;
    color: #A1A7BA;
  }

  .list-info {
    display: flex;
    width: 305px;
    margin: 13px auto 0;
    color: #222;
    font-size: 15px;
    line-height: 20px;
  }

  .info-label {
    flex-shrink: 0;
    width: 60px;
    margin-right: 10px;
    color: #A1A7BA;
  }

  .list-info-item {
    display: flex;
  }

  .one-line-info {
    width: 50px;
  }

  .operate-info {
    display: flex;
    justify-content: space-between;
    width: 305px;
    height: 40px;
    margin: 23px auto 0;
  }

  .both-sides {
    width: 93px;
    height: 38px;
    border: 1px solid #409EFF;
    border-radius: 4px;
    color: #409EFF;
    font-size: 15px;
    text-align: center;
    line-height: 38px;
  }

  .operate-center {
    width: 148px;
    height: 40px;
    background: #409EFF;
    border-radius: 4px;
    color: #fff;
    font-size: 15px;
    text-align: center;
    line-height: 40px;
  }


  /*底部栏*/
  .navIcon {
    height: 21px;
    width: 21px;
  }
</style>
