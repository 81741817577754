<template>
    <!-- S 头部header-->
    <div class="header">
        <div class="header-nav">
            <div class="submit-btn" @click="topAdd()">添加</div>
        </div>
    </div>
    <!-- E 头部header -->
    <div style="height: 100%">
        <!-- S 筛选条件部分 -->
        <div class="screen detail-w">
            <div class="search-term">
                <div class="search-term-label">公司名称</div>
                <div class="search-term-detail">
                    <van-field class="vant-input-edit" v-model="screen_company" clearable
                               autocomplete="off"
                               @focus="focusIpt"
                               @blur="blurIpt" placeholder="请输入公司名称..."/>
                </div>
            </div>
            <div class="search-term">
                <div class="search-term-label">联系方式</div>
                <div class="search-term-detail">
                    <van-field class="vant-input-edit" v-model="screen_contact" clearable
                               autocomplete="off"
                               @focus="focusIpt"
                               @blur="blurIpt" placeholder="请输入联系方式..."/>
                </div>
            </div>
            <button class="contact-btn contact-btn2" @click="search()">
                <img src="../assets/search.png" alt=""
                     class="search-img">
                搜索</button>
        </div>
        <!-- E 筛选条件部分 -->
        <div class="content-data-box w">
            <div class="data-index" v-for="(item, i) in dataList"
                 :data-id="item.id">
                <div class="index-three">
                    <span class="name-num">公司名称</span>
                    <span class="name-title">{{item.company_name}}</span>
                </div>
                <div class="index-three">
                    <span class="name-num">联系方式</span>
                    <span class="name-title">{{item.contact}}</span>
                </div>
                <div class="index-three">
                    <span class="name-num">联系人</span>
                    <span class="name-title">{{item.contact_user}}</span>
                </div>
                <div class="index-three">
                    <span class="name-num">公司地址</span>
                    <span class="name-title">{{item.company_address}}</span>
                </div>
                <div class="index-three">
                    <span class="name-num">运营客服</span>
                    <span class="name-title">{{item.user_name}}</span>
                </div>
                <div class="operate-info">
                    <div class="both-sides" v-if="item.parent === 0" @click="jumpAdd(item,true)">添加</div>
                    <div class="operate-center" @click="toEdit(item)">修改</div>
                    <div class="del-sides" @click="toDel(item.id)">删除</div>
                </div>
                <div v-if="item.children" v-for="(items, i) in item.children" style="border-top: 1px solid #DDE0E7">
                    <div class="index-three">
                        <span class="name-num">公司名称</span>
                        <span class="name-title">{{items.post}}</span>
                    </div>
                    <div class="index-three">
                        <span class="name-num">手机号</span>
                        <span class="name-title">{{items.contact}}</span>
                    </div>
                    <div class="index-three">
                        <span class="name-num">联系人</span>
                        <span class="name-title">{{items.contact_user}}</span>
                    </div>
                    <div class="index-three">
                        <span class="name-num">公司地址</span>
                        <span class="name-title">{{items.company_name}}</span>
                    </div>
                    <div class="index-three">
                        <span class="name-num">运营客服</span>
                        <span class="name-title">{{items.user_name}}</span>
                    </div>
                    <div class="child-info">
                        <div class="edit-child" @click="toEdit(items)">修改</div>
                        <div class="del-child" @click="toDel(items.id)">删除</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="empty-data" v-if="searchShow">暂无数据</div>
    </div>
</template>
<script>
    import {getChannelList, deleteChannel} from '../api/java'
    import {Dialog, Notify} from "vant";
    export default {
        name: "demandTransfer",
        data() {
            return {
                screen_company: '',
                screen_contact: '',
                searchShow: false,
                dataList: [],
            }
        },
        created() {
            window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
            this.dataList = []
            this.getTableData(false)
        },
        methods: {
            // 取消事件
            inputEndCancel() {
                this.input_end_show = false
            },
            // 苹果键盘事件
            blurIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = true
                    return
                }
            },
            // 苹果键盘事件
            focusIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = false
                    return
                }
            },

            // 返回上一页
            goBackPage() {
                this.$router.go(-1)
            },

            // 点击查找
            search() {
                this.searchStates = false
                this.dataList = []
                this.getTableData(true)
            },

            getTableData(states) {
                this.finished = false; // 停止加载
                this.searchStates = true
                let para = {
                    company_name: this.screen_company, // 页码
                    contact: this.screen_contact, // 每页条数
                }
                getChannelList(para).then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        if (states === true) {
                            Notify({type: 'success', message: '搜索成功'});
                        }
                        this.dataList = data.data.data
                        this.loading = false;
                        this.searchStates = false
                        this.current_page = para.pageNum
                        if (data.data.data.length > 0) {
                            this.dataShow = true
                            this.searchShow = false
                        } else {
                            this.dataShow = false
                            this.searchShow = true
                        }
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            /**
             * 操作按钮部分
             * */
            topAdd() {
                this.$router.push({
                    path: '/channelEdit',
                })
            },
            jumpAdd(item, states) {
                item.states = states
                this.$router.push({
                    path: '/channelEdit',
                    query: {
                        visa_data: encodeURIComponent(JSON.stringify(item)), // vue路由传递对象
                    }
                })
            },
            // 跳转详情页
            toEdit(item) {
                if (item !== undefined) {
                    // 跳转传id
                    this.$router.push({
                        path: '/channelEdit',
                        query: {
                            visa_data: encodeURIComponent(JSON.stringify(item)), // vue路由传递对象
                        }
                    })
                }
            },
            // 签证历史删除
            toDel(id) {
                Dialog.confirm({
                    message: '确认删除本条数据吗?',
                })
                    .then(() => {
                        let para = {
                            id: id, // 数据id
                        }
                        deleteChannel(para).then(data => {
                            if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                                Notify({type: 'danger', message: data.message});
                                sessionStorage.clear()
                                this.$router.push({
                                    path: '/weChatLogin'
                                })
                                return
                            }
                            if (data.code === 200) {
                                Notify({type: 'success', message: '已删除'});
                                this.getTableData() // 更新数据
                            } else {
                                Notify({type: 'danger', message: data.message});
                            }
                        }).catch(() => {
                            Notify({type: 'danger', message: '服务连接失败'});
                        })
                    })
                    .catch(() => {
                        // on cancel
                    });
            },
        }
    }
</script>

<style scoped>
    /* ---- 客户详情部分---- */
    .data-index {
        padding: 18px;
        background: #ffffff;
        margin: 11px 0 11px;
        position: relative;
        box-shadow: 0px 3px 20px 1px rgba(108, 157, 209, 0.16);
        border-radius: 10px 10px 10px 10px;
    }

    .index-top {
        padding-bottom: 9px;
        border-bottom: 1px solid #DDE0E7;
        display: flex;
        justify-content: space-between;
    }

    .name-num {
        font-size: 15px;
        color: #A1A7BA;
        margin-right: 8px;
        flex-shrink: 0;
        width: 60px;
    }

    .name-title {
        font-size: 15px;
        color: #222222;
    }


    .index-three {
        margin-top: 13px;
        display: flex;
        align-items: center;
    }

    .vant-input-edit {
        height: 43px !important;
        line-height: 43px !important;
        padding: 0 15px !important;
        color: #222 !important;
        font-size: 15px !important;
    }

    /* ---- 筛选部分 ---- */
    .detail-w {
        width: 345px;
        margin: 0 auto;
    }

    .screen {
        position: relative;
        height: 256px;
        margin-top: 15px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
    }

    .search-term {
        padding-top: 15px;
    }

    .search-term-label {
        padding: 0 20px;
        color: #A1A7BA;
        font-size: 15px;
    }

    .search-term-detail {
        position: relative;
        width: 303px;
        height: 38px;
        margin: 10px auto 0;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .down-icon {
        position: absolute;
        top: 0;
        right: 15px;
        width: 12px;
        height: 100%;
        color: #606266;
    }

    .down-icon svg {
        position: relative;
        top: 11px;
    }

    .down-icon i {
        position: relative;
        top: 11px;
        left: -2px;
        color: #C9C7C8;
    }

    .vant-input-edit {
        height: 38px !important;
        line-height: 38px !important;
        padding: 0 10px !important;
        color: #222 !important;
        font-size: 15px !important;
    }

    .input-edit-time {
        padding: 0 !important;
        line-height: 40px !important;
    }

    .vant-input-edit >>> input::-webkit-input-placeholder {
        color: #606266 !important;
    }

    .contact-icon i {
        top: 1px;
    }

    .contact-icon svg {
        top: 0;
    }

    .time-clear i {
        top: 1px;
    }


    .page-btn input {
        padding: 0;
        width: 43px;
        height: 28px;
        border: none;
        color: #A1A7BA;
        font-size: 15px;
        text-align: center;
    }
    .page-jump >>> input {
        height: 30px;
        color: #A1A7BA;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
    }


    .operate-info {
        display: flex;
        justify-content: space-between;
        width: 305px;
        height: 40px;
        margin: 23px auto;
    }

    .both-sides {
        width: 93px;
        height: 38px;
        border: 1px solid #409EFF;
        border-radius: 4px;
        color: #409EFF;
        font-size: 15px;
        text-align: center;
        line-height: 38px;
    }

    .del-sides {
        width: 93px;
        height: 38px;
        border: 1px solid #F56C6C;
        border-radius: 4px;
        color: #F56C6C;
        font-size: 15px;
        text-align: center;
        line-height: 38px;
    }

    .operate-center {
        width: 93px;
        height: 38px;
        background: #409EFF;
        border-radius: 4px;
        color: #fff;
        font-size: 15px;
        text-align: center;
        line-height: 40px;
    }

    .contact-btn {
        width: 305px;
        margin: 25px auto 0;
        flex-shrink: 0;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #409EFF;
        font-size: 15px;
        border-radius: 4px;
        border: none;
        color: #ffffff;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .child-info {
        display: flex;
        justify-content: space-between;
        width: 305px;
        height: 40px;
        margin: 23px auto 0;

    }

    .edit-child {
        width: 148px;
        height: 38px;
        background: #409EFF;
        border-radius: 4px;
        color: #fff;
        font-size: 15px;
        text-align: center;
        line-height: 40px;
    }

    .del-child {
        width: 148px;
        height: 38px;
        background: #FFFFFF;
        border-radius: 4px;
        color: #F56C6C;
        font-size: 15px;
        text-align: center;
        line-height: 40px;
        border: 1px solid #F56C6C;
    }

    .header-nav .top-btn {
        position: absolute;
        top: 11px !important;
        right: 15px !important;
        width: 60px;
        height: 30px;
        color: #409EFF;
        font-size: 15px;
        text-align: center;
        line-height: 30px;
        border-radius: 4px;
    }
    .search-img {
        width: 14px;
        margin-right: 5px;
    }
</style>
