<template>
    <div>
        <!-- S 头部header -->
        <div class="header">
            <div class="header-page">
                <div class="page-info">
                    <span>共</span>
                    <span class="page-num">{{total}}</span>
                    <span>条/</span>
                    <span class="page-num">{{sum_page}}</span>
                    <span>页，</span>
                    <span>当前第</span>
                    <span class="page-num">{{current_page}}</span>
                    <span>页</span>
                </div>
                <div class="page-btn">
                    <div class="page-input">
                        <van-field class="page-jump" v-model="page_num" @keyup.enter.native="jumpPage()" type="digit"/>
                    </div>
                    <div class="jump-btn" @click="jumpPage()">跳转</div>
                </div>
            </div>
        </div>
        <!-- E 头部header -->
        <!-- S 筛选条件部分 -->
        <div class="screen detail-w">
            <div class="search-term">
                <div class="search-term-label">签约时间：</div>
                <div class="search-term-detail time-slot-box">
                    <div class="time-slot" @click="inputStartTime">
                        <van-field class="vant-input-edit input-edit-time" v-model="input_start_time" readonly
                                   placeholder="选择开始时间"/>
                    </div>
                    <div class="time-slot-center">至</div>
                    <div class="time-slot" @click="inputEndTime">
                        <van-field class="vant-input-edit input-edit-time" v-model="input_end_time" readonly
                                   placeholder="选择结束时间"/>
                    </div>
                    <div class="down-icon time-clear" v-if="enter_clear_time" @click="enterTimeClear">
                        <van-icon name="clear"/>
                    </div>
                </div>
            </div>
            <div class="search-term">
                <div class="search-term-label">运营客服</div>
                <div class="search-term-detail" @click="enterPopup">
                    <van-field class="vant-input-edit" v-model="enter_info" clearable readonly placeholder="请选择"/>
                    <div class="down-icon" v-if="clear_enter_show">
                        <caret-bottom/>
                    </div>
                    <div class="down-icon" @click.stop="clearableEnter" v-else>
                        <van-icon name="clear"/>
                    </div>
                </div>
            </div>
            <div v-if="screen_more">
                <div class="search-term" v-if="adviser_source_show">
                    <div class="search-term-label">跟单顾问</div>
                    <div class="search-term-detail" @click="adviserPopup">
                        <van-field class="vant-input-edit" v-model="adviser_info" clearable readonly placeholder="请选择"/>
                        <div class="down-icon" v-if="clear_adviser_show">
                            <caret-bottom/>
                        </div>
                        <div class="down-icon" @click.stop="clearableAdviser" v-else>
                            <van-icon name="clear"/>
                        </div>
                    </div>
                </div>
                <div class="search-term">
                    <div class="search-term-label">客户名称</div>
                    <div class="search-term-detail">
                        <van-field class="vant-input-edit" v-model="customer_name" clearable placeholder="请输入客户名称"
                                   @focus="focusIpt"
                                   @blur="blurIpt"
                                   autocomplete="off"/>
                    </div>
                </div>
                <div class="search-term">
                    <div class="search-term-label">客户序号</div>
                    <div class="search-term-detail">
                        <van-field class="vant-input-edit" v-model="customer_id" clearable placeholder="请输入客户序号"
                                   @focus="focusIpt"
                                   @blur="blurIpt"
                                   autocomplete="off"/>
                    </div>
                </div>
                <div class="search-term">
                    <div class="search-term-label">客户条件</div>
                    <div class="search-term-detail" @click="conditionPopup">
                        <van-field class="vant-input-edit" v-model="condition_info" clearable readonly
                                   placeholder="请选择"/>
                        <div class="down-icon" v-if="clear_condition_show">
                            <caret-bottom/>
                        </div>
                        <div class="down-icon" @click.stop="clearableCondition" v-else>
                            <van-icon name="clear"/>
                        </div>
                    </div>
                </div>
                <div class="search-term" >
                    <div class="search-term-label">签约项目</div>
                    <div class="search-term-detail" @click="projectPopup">
                        <van-field class="vant-input-edit" v-model="project_info" clearable readonly placeholder="请选择"/>
                        <div class="down-icon" v-if="clear_project_show">
                            <caret-bottom/>
                        </div>
                        <div class="down-icon" @click.stop="clearableProject" v-else>
                            <van-icon name="clear"/>
                        </div>
                    </div>
                </div>
                <div class="search-term">
                    <div class="search-term-label">来源渠道</div>
                    <div class="search-term-detail" @click="sourceShow">
                        <van-field
                                class="vant-input-edit"
                                v-model="fieldValue"
                                readonly
                                placeholder="请选择"
                        />
                        <div class="down-icon" v-if="clear_source_show">
                            <caret-bottom/>
                        </div>
                        <div class="down-icon" @click.stop="clearableSource" v-else>
                            <van-icon name="clear"/>
                        </div>
                    </div>
                </div>
            </div>
            <!-- E 筛选展开部分 -->
            <div class="screen-operation">
                <div class="search-btn" @click="search()">
                    <img src="../assets/search.png" alt="">
                    搜索
                </div>
                <div class="more-search" @click="moreSearch()">
                    <img src="../assets/open_search.png" alt="">
                    {{screen_text}}
                </div>
            </div>
        </div>
        <!-- E 筛选条件部分 -->
        <van-list
                v-model:loading="loading"
                :finished="finished"
                :finished-text="no_more"
                :offset="0"
                @load="onLoad"
        >
            <!-- S 列表部分 -->
            <div class="list-wrap" v-for="item in list_data">
                <div class="list-item detail-w">
                    <div class="list-top">
                        <div class="list-top-num">
                            <span class="list-label">序号</span>
                            <span>{{item.id}}</span>
                        </div>
                    </div>
                    <div class="list-info">
                        <div class="info-label">客户名称</div>
                        <div>{{item.user_name}}</div>
                    </div>
                    <div class="list-info">
                        <div class="info-label">签约项目</div>
                        <div>{{item.project}}</div>
                    </div>
                    <div class="list-info" v-if="adviser_source_show">
                        <div class="info-label">来源渠道</div>
                        <div>{{item.source_name}}</div>
                    </div>
                    <div class="list-info">
                        <div class="info-label">客户条件</div>
                        <div v-if="item.condition !==''">{{customerCondition(item.condition)}}</div>
                        <div v-else>-</div>
                    </div>
                    <div class="list-info">
                        <div class="info-label">签约顾问</div>
                        <div v-if="item.sign_counsel_name!== null">{{item.sign_counsel_name}}</div>
                        <div v-else>-</div>
                    </div>
                    <div class="list-info">
                        <div class="info-label">运营客服</div>
                        <div v-if="item.official_service_name!== null">{{item.official_service_name}}</div>
                        <div v-else>-</div>
                    </div>
                    <div class="list-info">
                        <div class="info-label">跟单顾问</div>
                        <div v-if="item.follow_counsel_name!== null">{{item.follow_counsel_name}}</div>
                        <div v-else>-</div>
                    </div>
                    <div class="list-info">
                        <div class="info-label">录入时间</div>
                        <div v-if="item.create_time!==null">{{formatDate(item.create_time)}}</div>
                        <div v-else>-</div>
                    </div>
                    <div class="list-info">
                        <div class="info-label">签约时间</div>
                        <div v-if="item.bill_time!==null">{{formatDate(item.bill_time)}}</div>
                        <div v-else>-</div>
                    </div>
                    <div class="list-info">
                        <div class="info-label">转化周期</div>
                        <div v-if="item.cycle!==''">{{item.cycle}}</div>
                        <div v-else>-</div>
                    </div>
                    <div class="operate-info">
                        <div class="both-sides" @click="toDetail(item.id)">详情</div>
                        <div class="operate-center" @click="toFollowOrder(item.id)">跟单</div>
                        <div class="operate-center" @click="toHandover(item)">转案</div>
<!--                        <div class="both-sides" @click="toJournal(item.id)">日志</div>-->
                    </div>
                </div>
            </div>
            <div class="empty-data" v-if="empty_data">暂无数据</div>
            <!-- E 列表部分 -->
        </van-list>
        <!-- S 底部站位盒子 -->
        <div style='height: 61px;'></div>
        <!-- E 底部站位盒子 -->
        <!-- S 底部导航栏 -->
        <div class="occupy-box"></div>
        <van-tabbar v-model="active" v-if="bottom_nav_visible">
            <van-tabbar-item replace to="/index">
                <span>首页</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <home-filled/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/customManage">
                <span>客户管理</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <user/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/signedResources">
                <span>已签约</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <edit/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/sharedPool">
                <span>共享池</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <monitor/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
        </van-tabbar>
        <!-- S 底部导航栏 -->
        <!-- S 录入弹层 -->
        <van-popup v-model:show="enter_show" position="bottom" :style="{ height: '48%' }">
            <van-picker
                    title="请选择运营客服"
                    show-toolbar
                    :columns="enter_data"
                    @confirm="enterConfirm"
                    @cancel="enterCancel"
            />
        </van-popup>
        <!-- E 顾问弹层 -->
        <!-- S 顾问弹层 -->
        <van-popup v-model:show="adviser_show" position="bottom" :style="{ height: '48%' }">
            <van-picker
                    title="请选择顾问"
                    show-toolbar
                    :columns="adviser_data"
                    @confirm="adviserConfirm"
                    @cancel="adviserCancel"
            />
        </van-popup>
        <!-- E 顾问弹层 -->
        <!-- S 来源渠道弹层 -->
        <van-popup v-model:show="source_show" position="bottom">
            <van-cascader
                    v-if="source_visible"
                    v-model="cascaderValue"
                    title="请选择来源渠道"
                    :options="options"
                    :field-names="sourceNames"
                    @close="source_show = false"
                    @finish="onFinish"
            />
        </van-popup>
        <!-- E 来源渠道弹层 -->
        <!-- S 录入开始时间弹层 -->
        <van-popup v-model:show="input_start_show" position="bottom" :style="{ height: '48%' }">
            <van-datetime-picker
                    v-model="input_start_date"
                    type="date"
                    title="选择开始时间"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :formatter="formatter"
                    @confirm="inputStartConfirm"
                    @cancel="inputStartCancel"
            />
        </van-popup>
        <!-- E 录入开始时间弹层 -->
        <!-- S 录入结束时间弹层 -->
        <van-popup v-model:show="input_end_show" position="bottom" :style="{ height: '48%' }">
            <van-datetime-picker
                    v-model="input_end_date"
                    type="date"
                    title="选择结束时间"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :formatter="formatter"
                    @confirm="inputEndConfirm"
                    @cancel="inputEndCancel"
            />
        </van-popup>
        <!-- E 录入结束时间弹层 -->
        <!-- S 条件弹层 -->
        <van-popup v-model:show="condition_show" position="bottom" :style="{ height: '48%' }">
            <van-picker
                    title="请选择条件"
                    show-toolbar
                    :columns="condition_data"
                    @confirm="conditionConfirm"
                    @cancel="conditionCancel"
            />
        </van-popup>
        <!-- E 条件弹层 -->

        <!-- S 签约项目弹层 -->
        <van-popup v-model:show="project_show" position="bottom" :style="{ height: '48%' }">
            <van-picker
                    title="请选择签约项目"
                    show-toolbar
                    :columns="project_data"
                    @confirm="projectConfirm"
                    @cancel="projectCancel"
            />
        </van-popup>
        <!-- E 签约项目弹层 -->
    </div>
</template>

<script>
    // import { getSigned} from '../api'
    import {downSource, downProject, enterDown, getAdviserList, getSigned} from '../api/java.js'

    import {Notify} from "vant";
    import moment from "moment";
    import {ref} from "vue";

    export default {
        data() {
            return {
                adviser_source_show: true, // 来源渠道顾问不可见
                customer_id: '', // 客户序号
                /* ---- 触底加载 ---- */
                loading: false,
                finished: false,
                searchStates: false,  // 不走 onload

                /*---- 下拉重置 ----*/
                clear_adviser_show: true, // 签约顾问重置按钮
                clear_enter_show: true, // 录入
                clear_source_show: true, // 来源渠道重置按钮

                clear_condition_show: true, // 条件重置按钮
                clear_project_show: true,//签约项目


                page_num: '0', // 去第几页
                current_page: '1', // 当前第几页
                total: '0', // 总条数
                sum_page: '0', // 总页数
                customer_name: '', // 客户名称
                list_data: [], // 已签约资源列表数据

                /* ----运营客服部分 ---- */
                enter_id: '', // 运营客服id
                enter_show: false, // 运营客服弹出层
                enter_info: '', // 运营客服
                request_enter_data: [], // 接口返回运营客服数据
                enter_data: [], // vant 运营客服数据形式

                /* ---- 条件部分 ---- */
                condition_id: '', // 条件id
                condition_show: false, // 条件弹出层
                condition_info: '', // 条件
                request_condition_data: [], // 接口返回顾问数据
                condition_data: [],


                /* ---- 签约项目 ---- */
                project_id: '', // 条件id
                project_show: false, // 条件弹出层
                project_info: '', // 条件
                request_project_data: [], // 接口返回顾问数据
                project_data: [],

                /* ---- 跟单顾问部分 ---- */
                adviser_id: '', // 顾问id
                adviser_show: false, // 顾问弹出层
                adviser_info: '', // 跟单顾问
                request_adviser_data: [], // 接口返回顾问数据
                adviser_data: [], // vant 顾问数据形式

                /* ---- 来源渠道部分 ---- */
                source: '', // 来源渠道(字符串)
                source_show: false, // popup弹窗
                source_visible: false, // 级联选择器
                fieldValue: '',
                cascaderValue: '',
                // 来源渠道自定义字段
                sourceNames: {
                    text: 'title',
                    value: 'id',
                    children: 'children'
                },
                options: [], // 来源渠道数据集合
                empty_data: false, // 暂无数据
                no_more: '没有更多了',

                bottom_nav_visible: true, // 底部导航显示与隐藏
                iosStates: false, // 手机系统

                /* ---- 监听软键盘是否弹出 ---- */
                defaultHeight: '0',  //默认屏幕高度
                nowHeight: '0',  //实时屏幕高度

                /* ---- 时间组件 ---- */
                minDate: new Date(1970, 0, 1),
                maxDate: new Date(2122, 0, 1),
                input_start_date: new Date(), // 录入开始当前时间
                input_end_date: new Date(), // 录入结束当前时间
                visit_start_date: new Date(), // 回访开始当前时间
                visit_end_date: new Date(), // 回访结束当前时间
                formatter(type, val) {
                    if (type === 'year') {
                        return `${val}年`;
                    } else if (type === 'month') {
                        return `${val}月`;
                    } else {
                        return `${val}日`;
                    }
                    return val;
                },

                enter_clear_time: false, // 录入时间重置按钮
                input_start_show: false, // 录入时间开始弹层
                input_end_show: false, // 录入时间结束弹层
                input_start_time: '', // 录入开始时间
                input_end_time: '', // 录入结束时间
                visit_start_show: false, // 回访开始时间弹层
                visit_end_show: false, // 回访结束时间弹层
                visit_start_time: '', // 回访开始时间
                visit_end_time: '', // 回访结束时间
                screen_more: false,
                screen_text: '更多筛选条件', // 更多筛选条件文字切换
                screen_switch: false, // 更多筛选条件文字切换开关
            }
        },
        // 生命周期 初始化
        setup() {
            const active = ref(2); // 设定选定第几个
            return {
                active,
            };
        },
        created() {
            window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
            // 顾问来演不可见
            if (sessionStorage.getItem('postID') === '2' || sessionStorage.getItem('postID') === '7') {
                this.adviser_source_show = false
            }
            // 来源渠道数据过长 根据现设计稿导致显示不全(前端解决方法;字号改为12px)
            this.getAdviserData() // 获取顾问
            this.getSourceData() // 来源渠道数据
            this.searchStates = true
            this.getList() // 已签约资源数据
            this.getProjectData() //  获取已签约
            this.getEnterData() // 获取运营客服
            this.iosStates = sessionStorage.getItem("iosStates")
        },

        // 获取屏幕默认高度与实时高度
        mounted: function () {
            //获取默认高度
            this.defaultHeight = document.documentElement.clientHeight
            window.onresize = () => {
                return (() => {
                    //实时屏幕高度
                    this.nowHeight = document.documentElement.clientHeight
                })();
            };
        },
        // 监听软键盘是否弹出
        watch: {
            nowHeight: function () {
                if (this.defaultHeight !== this.nowHeight) {
                    this.bottom_nav_visible = false
                } else {
                    this.bottom_nav_visible = true
                }
            }
        },
        methods: {
            // 获取运营客服
            getEnterData() {
                enterDown().then(data => {
                    // 判断token
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        this.request_enter_data = data.data
                        // 请求数据获取顾问数组格式化为vant下拉数组格式
                        for (let i = 0; i < this.request_enter_data.length; i++) {
                            this.enter_data.push(this.request_enter_data[i].user_name) // vant 下拉数组赋值
                        }
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            // 列表数据
            onLoad(val) {
                if (this.searchStates == false) {
                    // console.log("我是下拉")
                    if (!this.page_num) {
                        this.page_num = 1
                    }
                    let para = {
                        pageNum: parseInt(this.page_num) + 1, // 当前第几页
                        pageSize: '10', // 每页10条
                        user_name: this.customer_name, // 客户名称
                        sale: this.adviser_id, // 顾问名称(id)
                        source: this.source, // 来源渠道
                        start_time: this.input_start_time, // 开始时间
                        end_time: this.input_end_time, // 结束时间
                        operate: this.enter_id,// 运营客服 （运营）
                        condition: this.condition_id,// 客户条件
                        project: this.project_id,// 项目
                        customer_id: this.customer_id,// 客户id
                    }
                    if(para.start_time &&!para.end_time){
                        Notify({type: 'danger', message: '请选择签约结束时间'})
                        return
                    }
                    if(para.end_time &&!para.start_time){
                        Notify({type: 'danger', message: '请选择签约开始时间'})
                        return
                    }
                    getSigned(para).then(data => {
                        this.loading = true;

                        if (data.code === 200) {
                            this.empty_data = true
                            // 暂无数据显示
                            if (data.data.total === 0) {
                                this.current_page = '1'
                                this.empty_data = true
                                Notify({type: 'success', message: '搜索成功', duration: 1000});
                                this.no_more = ''
                            } else {
                                this.empty_data = false
                                this.no_more = '没有更多了'
                            }
                            this.total = data.data.total
                            if (parseInt(data.data.total % 10) === 0) {
                                this.sum_page = parseInt(data.data.total / 10)
                            } else {
                                this.sum_page = parseInt(data.data.total / 10) + 1
                            }

                            this.loading = false;
                            // 数据全部加载完成
                            if (data.data.data.length === 0) {
                                this.finished = true; // 停止加载
                                return
                            }
                            this.page_num++
                            this.current_page = para.pageNum
                            this.list_data = this.list_data.concat(data.data.data) // 数组拼接
                            if (val) {
                                Notify({type: 'success', message: '搜索成功', duration: 1000});
                                this.finished = false; // 继续加载
                            }
                        } else {
                            Notify({type: 'danger', message: data.message});
                        }
                    }).catch(() => {
                        Notify({type: 'danger', message: '服务连接失败'});
                    })
                }
            },

            // 时间戳转化
            formatDate(time) {
                return moment(time * 1000).format("YYYY-MM-DD")
            },

            // 获取顾问列表
            getAdviserData() {
                getAdviserList().then(data => {
                    // 判断token
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        this.request_adviser_data = data.data
                        // 请求数据获取顾问数组格式化为vant下拉数组格式
                        for (let i = 0; i < this.request_adviser_data.length; i++) {
                            this.adviser_data.push(this.request_adviser_data[i].user_name) // vant 下拉数组赋值
                        }
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },

            // 获取来源渠道数据
            getSourceData() {
                downSource().then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        this.options = data.data
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            // 项目下拉数据
            getProjectData() {
                downProject().then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        this.request_project_data = data.data.data
                        this.request_condition_data = data.data.condition
                        // 请求数据获取顾问数组格式化为vant下拉数组格式
                        for (let i = 0; i < this.request_condition_data.length; i++) {
                            this.condition_data.push(this.request_condition_data[i].title) // vant 下拉数组赋值
                        }
                        // 请求数据获取顾问数组格式化为vant下拉数组格式
                        for (let i = 0; i < this.request_project_data.length; i++) {
                            this.project_data.push(this.request_project_data[i].title) // vant 下拉数组赋值
                        }
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },

            // 已签约资源数据
            getList(val) {
                this.finished = false; // 停止加载
                this.searchStates = true
                let para = {
                    pageNum: parseInt(this.page_num) + 1, // 当前第几页
                    pageSize: '10', // 每页10条
                    username: this.customer_name, // 客户名称
                    sale: this.adviser_id, // 顾问名称(id)
                    source: this.source, // 来源渠道
                    start_time: this.input_start_time, // 开始时间
                    end_time: this.input_end_time, // 结束时间
                    operate: this.enter_id,// 运营客服 （运营）
                    condition: this.condition_id,// 客户条件
                    project: this.project_id,// 项目
                    customer_id: this.customer_id,// 客户id
                }
                if(para.start_time &&!para.end_time){
                    Notify({type: 'danger', message: '请选择签约结束时间'})
                    return
                }
                if(para.end_time &&!para.start_time){
                    Notify({type: 'danger', message: '请选择签约开始时间'})
                    return
                }
                this.current_page = para.pageNum
                this.loading = true;
                getSigned(para).then(data => {
                    if (val) {
                        this.list_data = []
                    }
                    // 暂无数据显示
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        this.list_data = []
                        if (val) {
                            Notify({type: 'success', message: '搜索成功', duration: 1000});
                        }
                        this.total = data.data.total
                        if (parseInt(data.data.total % 10) === 0) {
                            this.sum_page = parseInt(data.data.total / 10)
                        } else {
                            this.sum_page = parseInt(data.data.total / 10) + 1
                        }
                        if (data.data.total === 0) {
                            this.empty_data = true
                            this.no_more = ''
                            this.current_page = '1'
                        } else {
                            this.empty_data = false
                            this.no_more = '没有更多了'
                        }
                        this.loading = false;
                        this.searchStates = false
                        // 数据全部加载完成
                        if (data.data.data.length === 0) {
                            this.finished = true; // 停止加载
                            return
                        }
                        this.page_num++
                        this.list_data = this.list_data.concat(data.data.data) // 数组拼接
                    } else {
                        this.loading = true;
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    this.loading = true;
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },

            // 返回上一页
            goBackPage() {
                this.$router.push({
                    path: '/index'
                })
            },

            // 跳转按钮
            jumpPage() {
                if (!this.page_num) {
                    Notify('请输入页码')
                    return false
                }

                if (this.page_num > this.sum_page) {
                    this.page_num = this.sum_page
                }

                if (this.page_num < 1) {
                    this.page_num = '1'
                }
                this.page_num = this.page_num - 1
                this.current_page = this.page_num
                this.list_data = []
                this.getList()
            },

            // 点击搜索
            search() {
                this.searchStates = false
                let search_tips = true // 点击搜索反馈信息
                this.current_page = '1'
                this.page_num = '0'
                this.getList(search_tips)
            },

            /**
             * 跟单顾问(搜索部分)
             * */
            // 跟单顾问弹层控制
            adviserPopup() {
                this.adviser_show = true
            },
            /**
             * 条件(搜索部分)
             * */
            conditionPopup() {
                this.condition_show = true
            },
            clearableCondition() {
                this.condition_info = '' // 清空请求时参数
                this.clear_condition_show = true // 切换重置图标
                this.condition_id=''
            },
            // 确认赋值
            conditionConfirm(value, index) {
                this.condition_info = value // 顾问赋值
                // 根据选中顾问获取对应顾问id
                for (let i = 0; i < this.request_condition_data.length; i++) {
                    if (index === i) {
                        this.condition_id = this.request_condition_data[i].id // 顾问id赋值
                    }
                }
                this.condition_show = false // 关闭弹层
                this.clear_condition_show = false // 切换重置图标
            },

            // 取消选择
            conditionCancel() {
                this.condition_show = false // 关闭弹层
            },
            /**
             * 运营客服(搜索部分)
             * */
            enterPopup() {
                this.enter_show = true
            },

            clearableEnter() {
                this.enter_info = '' // 清空请求时参数
                this.clear_enter_show = true // 切换重置图标
                this.enter_id=''
            },

            // 确认赋值
            enterConfirm(value, index) {
                this.enter_info = value // 顾问赋值
                // 根据选中顾问获取对应顾问id
                for (let i = 0; i < this.request_enter_data.length; i++) {
                    if (index === i) {
                        this.enter_id = this.request_enter_data[i].id // 顾问id赋值
                    }
                }
                this.enter_show = false // 关闭弹层
                this.clear_enter_show = false // 切换重置图标
            },

            // 取消选择
            enterCancel() {
                this.enter_show = false // 关闭弹层
            },

            // 确认赋值
            adviserConfirm(value, index) {
                this.adviser_info = value // 顾问赋值
                // 根据选中顾问获取对应顾问id
                for (let i = 0; i < this.request_adviser_data.length; i++) {
                    if (index === i) {
                        this.adviser_id = this.request_adviser_data[i].id // 顾问id赋值
                    }
                }
                this.adviser_show = false // 关闭弹层
                this.clear_adviser_show = false // 切换重置图标
            },

            // 取消选择
            adviserCancel() {
                this.adviser_show = false // 关闭弹层
            },

            /**
             * 来源渠道部分逻辑(搜索)
             * */
            // 来源渠道popup弹窗
            sourceShow() {
                this.source_show = true // 来源渠道popup
                this.source_visible = true // 来源渠道级联选择器
            },
            // 全部选项选择完毕后，会触发 finish 事件
            onFinish({selectedOptions}) {
                this.source_show = false;
                this.fieldValue = selectedOptions.map((option) => option.title).join('/');
                let source_arr = []
                for (let i = 0; i < selectedOptions.length; i++) {
                    source_arr.push(selectedOptions[i].id)
                }
                this.source = source_arr.toString() // 来源渠道赋值(请求数据时候用)
                this.clear_source_show = false // 切换重置图标
            },

            /**
             * 操作按钮部分
             * */
            // 跳转详情页
            toDetail(id) {
                // 跳转传id
                this.$router.push({
                    path: '/customer_detail',
                    query: {
                        id: id
                    }
                })
            },

            // 跳转跟单页
            toFollowOrder(id) {
                this.$router.push({
                    path: '/documentary',
                    query: {
                        id: id
                    }
                })
            },

            // 跳转日志页
            toJournal(id) {
                this.$router.push({
                    path: '/journal',
                    query: {
                        id: id
                    }
                })
            },

            /**
             * 重置下拉按钮
             * */
            // 重置签约顾问
            clearableAdviser() {
                this.adviser_info = '' // 清空回显
                this.adviser_id = '' // 清空请求时参数
                this.clear_adviser_show = true // 切换重置图标
            },

            // 来源渠道重置
            clearableSource() {
                this.source_visible = false // 来源渠道级联选择器
                this.fieldValue = '' // 清空来源渠道回显
                this.source = '' // 清空来源渠道传递参数
                this.cascaderValue = '' // 重置级联选择器
                this.clear_source_show = true // 切换重置图标
            },
            // 苹果键盘事件
            blurIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = true
                    return
                }
            },
            // 苹果键盘事件
            focusIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = false
                    return
                }
            },

            /**
             * 跳转文案交接
             * */
            toHandover(item) {
                if (sessionStorage.getItem('postID')) {
                    this.$router.push({
                        path: '/handoverList',
                        query: {
                            id: item.id
                        }
                    })
                } else {
                    Notify({type: 'danger', message: '非顾问无法操作'});
                }

            },

            /**
             * 录入开始时间部分
             * */
            inputStartTime() {
                this.input_start_show = true
            },

            // 时间戳转化(年-月-日 时:分:秒)
            formatDate(time) {
                return moment(time * 1000).format("YYYY-MM-DD hh:mm:ss")
            },

            // 时间戳转化(年-月-日)
            formatDate2(time) {
                return moment(time).format("YYYY-MM-DD")
            },
            /**
             * 录入结束时间部分
             * */
            inputEndTime() {
                this.input_end_show = true
            },

            // 录入时间重置
            enterTimeClear() {
                this.enter_clear_time = false // 重置按钮消失
                this.input_start_time = '' // 清空录入时间(开始)
                this.input_end_time = '' // 清空录入时间(结束)
            },
            // 确认事件
            inputStartConfirm(val) {
                this.input_start_time = this.formatDate2(val)
                let enter_star_time = new Date(this.input_start_time)
                let enter_end_time = new Date(this.input_end_time)
                if (enter_end_time.getTime() <= enter_star_time.getTime()) {
                    Notify({type: 'danger', message: '开始时间不能大于或等于结束时间'});
                    this.input_start_time = ''
                    return
                }
                this.input_start_show = false
                this.enter_clear_time = true // 显示重置按钮
            },

            // 取消事件
            inputStartCancel() {
                this.input_start_show = false
            },


            /**
             * 录入结束时间部分
             * */
            inputEndTime() {
                this.input_end_show = true
            },

            // 确认事件
            inputEndConfirm(val) {
                this.input_end_time = this.formatDate2(val)
                let enter_star_time = new Date(this.input_start_time)
                let enter_end_time = new Date(this.input_end_time)
                if (enter_end_time.getTime() <= enter_star_time.getTime()) {
                    Notify({type: 'danger', message: '开始时间不能大于或等于结束时间'});
                    this.input_end_time = ''
                    return
                }
                this.input_end_show = false
                this.enter_clear_time = true // 显示重置按钮
            },

            // 取消事件
            inputEndCancel() {
                this.input_end_show = false
            },

            /**
             * 签约项目(搜索部分)
             * */
            projectPopup() {
                this.project_show = true
            },

            // 录入时间重置
            clearableProject() {
                this.project_info = '' // 清空请求时参数
                this.clear_project_show = true // 切换重置图
                this.project_id=''
            },


            // 确认赋值
            projectConfirm(value, index) {
                console.log(value)
                this.project_info = value // 顾问赋值
                // 根据选中顾问获取对应顾问id
                for (let i = 0; i < this.request_project_data.length; i++) {
                    if (index === i) {
                        this.project_id = this.request_project_data[i].id // 顾问id赋值
                    }
                }
                this.project_show = false // 关闭弹层
                this.clear_project_show = false // 切换重置图标
            },

            // 取消选择
            projectCancel() {
                this.project_show = false // 关闭弹层
            },
            // 客户等级转化
            customerCondition(value) {
                let grade_arr = ['', '符合', '基础符合', '特批符合']
                return grade_arr[value]
            },
            // 更多筛选条件
            moreSearch() {
                this.screen_switch = !this.screen_switch
                if (this.screen_switch) {
                    this.screen_height = 1105 + 'px'
                    this.screen_text = '收起'
                    this.screen_more = true
                } else {
                    this.screen_height = ''
                    this.screen_text = '更多筛选条件'
                    this.screen_more = false
                }
            },

        },
    }
</script>

<style scoped>
    /* ---- header区域 ---- */
    .header {
        position: sticky;
        top: 0;
        /*height: 106px;*/
        height: 52px;
        background: #fff;
        box-shadow: 0 9px 16px rgba(25, 52, 139, 0.06);
        z-index: 9;
    }

    .header-nav {
        position: relative;
        height: 53px;
        border-bottom: 1px solid #F5F5FC;
        color: #222;
        font-size: 19px;
        text-align: center;
        line-height: 53px;
    }

    .header-nav div {
        position: absolute;
        left: 10px;
        width: 19px;
        height: 16px;
    }

    .header-page {
        display: flex;
        justify-content: space-between;
        height: 53px;
        padding: 0 15px;
    }

    .page-info {
        color: #A1A7BA;
        font-size: 12px;
        line-height: 53px;
    }

    .page-num {
        padding: 0 2px;
        color: #222;
    }

    .page-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .page-input {
        width: 43px;
        height: 28px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .page-btn input {
        padding: 0;
        width: 43px;
        height: 28px;
        border: none;
        color: #A1A7BA;
        font-size: 15px;
        text-align: center;
    }

    .jump-btn {
        width: 49px;
        height: 30px;
        margin-left: 7px;
        background: #409EFF;
        border-radius: 4px;
        color: #fff;
        font-size: 15px;
        text-align: center;
        line-height: 30px;
    }

    .page-jump {
        padding: 0;
    }
    .page-jump >>> input {
        height: 30px;
        color: #A1A7BA;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
    }

    /* ---- 筛选部分 ---- */
    .detail-w {
        width: 345px;
        margin: 0 auto;
    }

    /*.screen {*/
    /*  position: relative;*/
    /*  margin-top: 15px;*/
    /*  padding-bottom: 25px;*/
    /*  background: #fff;*/
    /*  border-radius: 10px;*/
    /*  box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);*/
    /*}*/

    .search-term {
        padding-top: 15px;
    }

    .search-term-label {
        padding: 0 20px;
        color: #A1A7BA;
        font-size: 15px;
    }

    .search-term-detail {
        position: relative;
        width: 303px;
        height: 38px;
        margin: 10px auto 0;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .down-icon {
        position: absolute;
        top: 0;
        right: 15px;
        width: 12px;
        height: 100%;
        color: #606266;
        display: flex;

    }

    .down-icon svg {
        position: relative;
        top: 0;
    }

    .down-icon i {
        position: relative;
        top: 11px;
        left: -2px;
        color: #C9C7C8;
    }

    .vant-input-edit {
        height: 38px !important;
        line-height: 38px !important;
        padding: 0 10px !important;
        color: #222 !important;
        font-size: 15px !important;
    }

    .vant-input-edit >>> input::-webkit-input-placeholder {
        color: #606266 !important;
    }


    /* ---- 列表数据 ---- */
    .list-wrap {
        margin: 15px 0;
    }

    .list-item {
        padding-bottom: 20px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
    }

    .list-top {
        display: flex;
        justify-content: space-between;
        width: 305px;
        height: 45px;
        margin: 0 auto 16px;
        border-bottom: 1px solid #DDE0E7;
    }

    .list-top-num {
        padding-top: 17px;
        color: #222;
        font-size: 15px;
    }

    .list-label {
        padding-right: 8px;
        color: #A1A7BA;
    }

    .list-info {
        display: flex;
        width: 305px;
        margin: 13px auto 0;
        color: #222;
        font-size: 15px;
    }

    .info-label {
        flex-shrink: 0;
        width: 60px;
        margin-right: 10px;
        color: #A1A7BA;
    }

    .operate-info {
        display: flex;
        justify-content: space-between;
        width: 305px;
        height: 40px;
        margin: 23px auto 0;
    }

    .both-sides {
        width: 70px;
        height: 38px;
        border: 1px solid #409EFF;
        border-radius: 4px;
        color: #409EFF;
        font-size: 15px;
        text-align: center;
        line-height: 38px;
    }

    .operate-center {
        width: 70px;
        height: 40px;
        background: #409EFF;
        border-radius: 4px;
        color: #fff;
        font-size: 15px;
        text-align: center;
        line-height: 40px;
    }

    /*底部栏*/
    .navIcon {
        height: 21px;
        width: 21px;
    }

    .time-slot-box {
        display: flex;
        width: 285px;
        padding: 0 10px;
        line-height: 40px;
    }

    .time-slot {
        width: 135px;
        height: 38px;
        color: #606266;
    }

    .time-slot-center {
        margin-right: 10px;
        color: #222;
        font-size: 15px;
    }

    .screen-operation {
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 25px;
        left: 20px;
        width: 305px;
    }

    .screen {
        position: relative;
        margin-top: 15px;
        background: #fff;
        padding-bottom: 90px;
        border-radius: 10px;
        box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
    }

    .search-btn {
        width: 147px;
        height: 40px;
        background: #409EFF;
        border-radius: 4px;
        text-align: center;
        line-height: 40px;
        color: #fff;
        font-size: 15px;
    }

    .more-search {
        width: 145px;
        height: 38px;
        border: 1px solid #409EFF;
        border-radius: 4px;
        text-align: center;
        line-height: 38px;
        color: #409EFF;
        font-size: 15px;
    }
    .both-sides {
        width: 93px;
        height: 38px;
        border: 1px solid #409EFF;
        border-radius: 4px;
        color: #409EFF;
        font-size: 15px;
        text-align: center;
        line-height: 38px;
    }

    .operate-center {
        width: 95px;
        height: 40px;
        background: #409EFF;
        border-radius: 4px;
        color: #fff;
        font-size: 15px;
        text-align: center;
        line-height: 40px;
    }
</style>
