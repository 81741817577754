<template>
    <div>
        <!-- 钉钉免登 -->
    </div>
</template>

<script>
import {userData,getTokenByDingId} from "../api/java.js";
import {Notify} from "vant";

export default {
    created() {
        if (this.$route.query.dingTalkId){
            // localStorage.setItem('dingId', this.$route.query.dingTalkId) // 存储token信息
            this.getToken()
        }else {
            let redirect_uri = encodeURIComponent(process.env.VUE_APP_BASE_URL_JAVA + '/crm/dingTalkLogin')  // 钉钉回调地址
            let appId = 'dingy8vbkbckjgrpdlf0'
            // window.location.href = 'https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid="+"dingy8vbkbckjgrpdlf0&response_type=code&scope=snsapi_auth&state=STATE&redirect_uri=encodedUrl'
            window.location.href = 'https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=' + appId + '&response_type=code&scope=snsapi_auth&state=0&redirect_uri=' + redirect_uri
        }
        // localStorage.setItem('dingId', this.$route.query.dingTalkId) // 存储token信息
        // this.getToken()
        // var newToken = "bearer " + this.$route.query.token  // 后台请求回来的token值11
        // sessionStorage.setItem('token', newToken) // 存储token信息
        // sessionStorage.setItem('token',this.$route.query.token) // 存储token信息
        // this.getUserData() // 个人信息
    },
    methods: {
        //获取token
        getToken() {
            let para = {
                dingId: this.$route.query.dingTalkId
            }
            getTokenByDingId(para).then(data => {
                if (data.code === 200) {
                    var newToken = "bearer " + data.message  // 后台请求回来的token值
                    sessionStorage.setItem('token', newToken) // 存储token信息
                    this.getUserData()
                    // Notify({ type: 'success', message: '登录成功' })
                } else {
                    Notify({type: 'danger', message: data.message})
                }
            }).catch(() => {
                Notify({type: 'warning', message: '服务器连接失败'})
            })
        },
        // 通过token 获取登录人信息
        getUserData(){
            userData().then(data => {
                if (data.code === 200) {
                    sessionStorage.setItem('postID', data.data.postId) // 存储  post 权限(0:管理员; 1:运营;2:顾问;)token信息
                    sessionStorage.setItem('userId', data.data.userId) // 存储  post 权限(0:管理员; 1:运营;2:顾问;)token信息
                    sessionStorage.setItem('userName', data.data.userName) // 存储用户名
                    sessionStorage.setItem('version', data.version) // 存储用户名
                    localStorage.setItem('style', 'ding') // 存储登录方式
                    Notify({ type: 'success', message: '登录成功' })
                    this.$router.push({
                        path: '/index',
                    });
                } else {
                    Notify({type: 'danger', message: data.message})
                }
            }).catch(() => {
                Notify({type: 'warning', message: '服务器连接失败'})
            })
        },
    }
}
</script>

<style scoped>

</style>
