<template>
  <div class="main-content">
    <div class="history-title">历史记录</div>
    <van-list
      :loading="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-cell v-for="item in list" :key="item.id"  class="history-item" @click="handleDetail(item)">
        <div>
          {{item.name}}
        </div>
        <div class="history-item-to">
          <div class="confirmed" v-if="item.verify_type==1">
            已确认
          </div>
          <div class="unconfirm" v-else>
            未确认
          </div>
          <img src="../../static/arrow.svg">
        </div>
      </van-cell>
    </van-list>
  </div>
</template>
<script>
import {Notify} from "vant";
import {
  getHistoricalRecordDetails,
  getPerforHistoricalRecord,// 获取业绩历史记录列表
} from "@/api/java";

export default {
  components: {},
  data() {
    return {
      finished:false,
      loading:false,
      list:[],
      limit:10,
      currentPage: 1,
      total:0,
    }
  },
  created() {
    this.getHistoryList()
  },
  computed:{

  },
  methods: {
    onLoad(){
      if(this.loading||this.finished){return}
      this.currentPage++;
      this.loading = true; // 开始加载数据
      let para = {
        pageNum: this.currentPage,
        pageSize: this.limit,
      }
      getPerforHistoricalRecord(para).then(data => {
        if (data.code === 200) {
          this.list = this.list.concat(data.data);
          this.loading = false
          if (this.list.length >= this.total) {
            this.finished = true;
          }
        } else {
          this.loading = false
          this.finished = true;
          Notify({type: 'danger', message: data.message});
          return
        }
      }).catch((e) => {
        console.log(e)
        this.loading = false
        this.finished = true;
        Notify({type: 'danger', message: '服务连接失败'});
        return
      })
    },
    getHistoryList(){
      this.loading = true; // 开始加载数据
      let para = {
        pageNum: this.currentPage,
        pageSize: this.limit,
      }
      getPerforHistoricalRecord(para).then(data => {
        if (data.code === 200) {
          this.list = data.data
          this.total=data.count
          this.loading = false
          if (this.list.length >= this.total) {
            this.finished = true;
          }
        } else {
          this.loading = false
          this.finished = true;
          Notify({type: 'danger', message: data.message});
          return
        }
      }).catch((e) => {
        console.log(e)
        this.loading = false
        this.finished = true;
        Notify({type: 'danger', message: '服务连接失败'});
        return
      })
    },
    handleDetail(item){
      this.$router.push({
        name:'performanceConfirmation',
        query: {
          confirmData: JSON.stringify(item)
        }
      })
    },
  },
}
</script>

<style scoped>
  .main-content{
    padding: 16px;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
  }
  .history-title{
    font-weight: 600;
    font-size: 17px;
    color: #606266;
    margin-top: 9px;
    margin-bottom: 20px;
  }
  >>>.van-list{
    border-radius: 2px;
  }
  .history-item>>>.van-cell__value--alone{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .history-item{
    padding: 20px;
  }
  .history-item-to{
    display: flex;
    gap: 15px;
    align-items: center;
    cursor: pointer;
  }
  .history-item-to img{
    width: 5px;
    height: 9px;
  }
  .confirmed{
    width: 54px;
    height: 25px;
    line-height: 25px;
    border-radius: 4px;
    color: #419EFF;
    flex-shrink: 0;
    background: #ECF5FF;
    border: 1px solid #B3D8FF;
    font-size: 12px;
    text-align: center;
  }
  .unconfirm{
    width: 54px;
    height: 25px;
    line-height: 25px;
    border-radius: 4px;
    background: #FEF0F0;
    border: 1px solid #FDE2E2;
    color: #F56C6C;
    font-size: 12px;
    text-align: center;
    flex-shrink: 0;
  }

</style>