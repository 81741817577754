<template style="width: 100%;height: 100%;">
    <div class="add-wrap">
        <!-- E 名称输入 -->
        <div class="info-box">
            <div class="info-title">
                <span>进度名称:</span>
                <span class="required-icon">*</span>
            </div>
            <div class="info-input">
                <van-field class="vant-input-edit" v-model="title"
                           clearable autocomplete="off" placeholder="请输入进度名称..."/>
            </div>
        </div>
        <!-- E 名称输入 -->
        <div class="submit-btn1" @click="edit()">提交</div>
    </div>
    <!-- E 输入部分 -->
    <!-- S 底部导航栏  底部四个的 权限对应0 1 2  -->
    <div class="occupy-box"></div>
    <div  v-if="fourStates">
        <van-tabbar v-model="active">
            <van-tabbar-item replace to="/index">
                <span>首页</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <home-filled/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/customManage">
                <span>客户管理</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <user/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/signedResources">
                <span>已签约</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <edit/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/sharedPool">
                <span>共享池</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <monitor/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
    <!-- S 底部导航栏  底部四个的 权限对应0 1 2-->

    <!-- S 底部导航栏  底部三个的 权限对应 3 4  -->
    <div  v-if="threeStates">
        <van-tabbar v-model="active">
            <van-tabbar-item replace to="/index">
                <span>首页</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <home-filled/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/approve">
                <span>交接审批</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <Coordinate/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/customSchedule">
                <span>客户列表</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <PieChart/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
    <!-- S 底部导航栏  底部三个的 权限对应 3 4-->

    <!-- S 底部导航栏  底部两个的 权限对应 5 6  -->

    <div v-if="twoStates">
        <van-tabbar v-model="active">
            <van-tabbar-item replace to="/index">
                <span>首页</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <home-filled/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/customSchedule">
                <span>客户列表</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <PieChart/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
    <!-- S 底部导航栏  底部两个的 权限对应 5 6-->
</template>

<script>
    import {optionUpdate} from '../api'
    import {Notify} from 'vant';
    import {ref} from "vue";
    import {useRouter} from "vue-router";

    export default {
        name: "weightAdd",

        data() {
            return {
                projectId:'', //项目ID
                title: '', // 名称
                id: '' , // 修改ID
                parent:0, // 父级添加参数 0
                pageState:true ,// true 是新增状态 false 为修改
                fourStates:true, // 底部栏 四个 权限0 1 2
                threeStates:false, // 底部栏 三个 权限 3 4
                twoStates:true, // 底部栏 两个 权限5 6
            }
        },

        // 生命周期 初始化
        setup() {
            const active = ref(''); // 设定选定第几个
            return {
                active,
            };
        },
        created() {
            window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
            var pageData = JSON.parse(decodeURIComponent(this.$route.query.data))
            this.parent =pageData.parent // parent 参数
            this.projectId =pageData.projectId// projectId 项目ID
            this.id =pageData.id, // 修改ID
            this.title =pageData.title // 修改内容
            //修改（底部导航栏）
            this.postId = sessionStorage.getItem("postID")
            if (this.postId === '6' || this.postId === '5') { // 判断 文案 或者 运营经理
                this.fourStates =false // 不显示死个底部
                this.threeStates =false // 不显示两个个底部
                this.twoStates =true// 不显示三个底部
                return
            }
            if (this.postId === '3' || this.postId === '4') { // 判断 运营主管 或者 文案主管
                this.fourStates =false // 不显示死个底部
                this.threeStates =true // 显示两个个底部
                this.twoStates =false// 不显示三个底部
                return
            }
            if (this.postId === '0') { // 判断 管理员
                this.fourStates =true // 不显示两个个底部
                this.threeStates =false // 显示两个个底部
                this.twoStates =false// 不显示三个底部
                return
            }
        },
        methods: {
            // 提交
            edit() {
                if (this.title == '') {
                    Notify({type: 'danger', message: '名称不能为空，请输入', duration: 1000});
                    return
                }
                let para={
                    id:this.id,
                    type: 2,
                    parent: this.parent,
                    project_name_id: this.projectId,
                    title: this.title,
                }
                optionUpdate(para).then(data => {
                    if (data.code === 200) {
                        Notify({type: 'success', message: data.message, duration: 1000});
                        this.$router.go(-1)
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
        },

    }
</script>

<style scoped>
    html,
    body {
        margin: 0;
        padding: 0;
        height: 100px;

    }

    /* ---- header区域 ---- */
    .header {
        position: sticky;
        top: 0;
        height: 52px;
        background: #fff;
        box-shadow: 0 9px 16px rgba(25, 52, 139, 0.06);
        z-index: 9;
    }

    .header-nav {
        position: relative;
        height: 53px;
        color: #222;
        font-size: 19px;
        text-align: center;
        line-height: 53px;
    }

    .header-nav div {
        position: absolute;
        left: 10px;
        width: 19px;
        height: 16px;
    }

    /* ---- 输入部分 ---- */
    .add-wrap {
        width: 100%;
        margin-top: 12px;
        padding: 16px 0 1px;
        background: #fff;
        height: 100%;
    }

    .info-box {
        width: 345px;
        margin: 0 auto 16px;
    }

    .info-title {
        color: #A1A7BA;
        font-size: 15px;
    }

    .info-input {
        position: relative;
        width: 343px;
        height: 43px;
        margin-top: 12px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .required-icon {
        padding-left: 3px;
        color: #FF0000;
    }

    .vant-input-edit {
        height: 43px !important;
        line-height: 43px !important;
        padding: 0 15px !important;
        color: #222 !important;
        font-size: 15px !important;
    }

    .vant-input-edit >>> input::-webkit-input-placeholder {
        color: #606266 !important;
    }

    .radio-box {
        display: flex;
        margin-top: 12px;
        height: 45px;
        color: #606266;
        font-size: 15px;
    }

    .radio-item {
        position: relative;
        margin-right: 10px;
        border-radius: 4px;
        border: 1px solid #C9CDD6;
        color: #606266;
        font-size: 15px;
    }

    .radio_active {
        border-color: #409EFF;
    }

    .radio-item:last-child {
        margin-right: 0;
    }

    .van-radio--horizontal {
        margin-right: 0;
        padding: 11px;
    }

    .radio-item >>> .van-radio__icon--round .van-icon {
        display: none;
    }

    .radio-item >>> .van-radio__label {
        margin-left: 20px;
    }

    .radio-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 14px;
        left: 12px;
        width: 10px;
        height: 10px;
        border: 2px solid #ABAEB8;
        border-radius: 50%;
    }

    .radio-icon-spot {
        width: 6px;
        height: 6px;
        background: #ABAEB8;
        border-radius: 50%;
    }

    .radio_active .radio-icon {
        border-color: #409EFF;
    }

    .radio_active .radio-icon-spot {
        background: #409EFF;
    }


    .info-textarea {
        height: 110px;
    }

    .info-textarea-edit {
        color: #222 !important;
        font-size: 15px !important;
    }

    .info-textarea-edit >>> textarea::-webkit-input-placeholder {
        color: #606266 !important;
    }

    .info-textarea-edit >>> textarea {
        min-height: 88px;
    }

    .down-icon {
        position: absolute;
        top: 13px;
        right: 15px;
        width: 12px;
        color: #606266;
    }


    .submit-btn1 {
        width: 345px;
        height: 50px;
        margin: 25px auto 35px;
        background: #409EFF;
        color: #fff;
        font-size: 17px;
        text-align: center;
        line-height: 50px;
        border-radius: 50px;
    }

</style>
