<template>
  <div class="main-body" v-if="daily_list.length>0">
    <!-- S banner区域 -->
    <div class="banner-top">
      <div class="banner-title">
          统计截止时间：{{time}}
      </div>
    </div>
    <!-- E banner区域 -->
    <!-- S tab切换区域 -->
    <div class="tabs-daily">
      <div :class="activeTab===1?'tabs-item active-tab':'tabs-item'"  @click="handeChangeTab(1)">
        今日数据
      </div>
      <div :class="activeTab===2?'tabs-item active-tab':'tabs-item'" @click="handeChangeTab(2)">
        本月数据
      </div>
    </div>
    <!-- E tab切换区域 -->
    <!-- S 列表区域 -->
    <van-loading size="24px" vertical v-if="daily_loading">加载中...</van-loading>
    <div class="daily-content" v-else>
      <div class="daily-content-info" v-for="(item,index) in daily_list">
        <div class="daily-content-top">
          <div :class="item.rank<=3?'top'+' top'+(item.rank):'top top-default'">
            <div class="top-title" v-if="item.rank>3">
              Top
            </div>
            <div class="top-text"  v-if="item.rank>3">
              {{ formatNumber(item.rank) }}
            </div>
          </div>
          <div :class="item.open?'daily-info-top-open':'daily-info-top'">
            <div class="daily-company">
              {{item.city}}
            </div>

            <div class="daily-company-total"  v-if="item.count>0">
              <div v-if="!item.open">
                <span class="num">{{item.count}}</span><span class="unit">单</span>
              </div>
            </div>
            <div class="daily-company-total"  v-else>
              <div>
                <span class="num">{{item.count}}</span><span class="unit">单</span>
              </div>
            </div>
            <div class="arrow-fold" @click="handeChangeFold(index)">
              <img src="../assets/arrow-up.png" v-if="!item.open">
              <img src="../assets/arrow-down.png" v-else>
            </div>
          </div>
        </div>
        <div class="daily-content-open" v-if="item.open&&item.proList.length>0">
          <div class="daily-open-title">{{ activeTab==1?"今日":'本月' }}签约数量 <span>{{item.count}}</span></div>
          <div class="daily-open-content">
            <img src="../assets/mark-top.png" class="mark-top">
            <img src="../assets/mark-bottom.png" class="mark-bottom">
            <div class="daily-open-list">
              <div v-for="o in item.proList">
                {{ o }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- E 列表区域 -->
    <!-- S 回到顶部区域 -->
    <div class="back-top" v-show="scrollType"  @click.stop="backTop">
      <img src="../assets/back-top.png">
    </div>
    <!-- E 回到顶部区域 -->
  </div>
</template>
<script>
import {getMonthSignData, getTodaySignData} from "@/api/java";
import {Notify} from "vant";
import moment from "moment/moment";

export default {
  components: {},
  data() {
    return {
      activeTab:1,
      daily_list:[ ],
      scrollType: false, // 控制回到顶部按钮显示和隐藏
      daily_loading:false,//日报loading
      time:'',//查询截止时间
    }
  },
  created() {
    let time = this.$route.query.url

    let login_url = process.env.VUE_APP_BASE_URL_JAVA  // 网页授权接口(正式)
    let wx_url = login_url+'/crm/crmMobileLogin?type=2&time='+time

    if (!this.$route.query.token) {
      location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww1e03ca55a309cbb1&redirect_uri='+ encodeURIComponent(wx_url) +'&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
      return;
    }
    if (this.$route.query.token) {
      sessionStorage.setItem('token', "bearer " + this.$route.query.token) // 存储token信息
      sessionStorage.setItem('url_java', this.$route.query.url) // 存储用户id
      this.time = this.$route.query.url
    }
    this.getDayList()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScoll)
  },
  methods: {
    formatNumber(num) {
      return num >= 1 && num <= 9 ? '0' + num : num;
    },
    // 切换当月或者当日数据
    handeChangeTab(type){
      this.activeTab=type
      if(type===1){
        this.getDayList()
      }else if(type===2){
        this.getMouthList()
      }
    },
    // 展开收起
    handeChangeFold(index){
      this.daily_list[index].open=!this.daily_list[index].open
    },
    // 回到顶部
    backTop () {
      document.documentElement.scrollTop =0
    },

    handleScoll (ev) {
      // 当滚动的距离大于50 时出现该按钮
      this.scrollTop = window.pageYOffset
      if (window.pageYOffset / 50 > 1) {
        this.scrollType = true
      } else {
        this.scrollType = false
      }
    },
    //当日数据
    getDayList(){
      let para = {
        time:this.time
      }
      this.daily_loading = true
      getTodaySignData(para).then(data => {
        if (data.code === 200) {
          this.daily_list=this.sort(data.data)
          this.daily_loading = false
        } else {
          this.daily_loading = false
          Notify({type: 'danger', message: data.message})
        }
      }).catch((e) => {
        this.loading = false
        Notify({type: 'warning', message: '服务器连接失败'})
      })
    },
    //当月数据
    getMouthList(){
      let para = {
        time:this.time
      }
      this.daily_loading = true
      getMonthSignData(para).then(data => {
        if (data.code === 200) {
          this.daily_list=this.sort(data.data)
          this.daily_loading = false
        } else {
          this.daily_loading = false
          Notify({type: 'danger', message: data.message})
        }
      }).catch((e) => {
        this.loading = false
        Notify({type: 'warning', message: '服务器连接失败'})
      })
    },
    formatDate(date){
      if (!date) {
        return "";
      }
      return moment(parseInt(date) * 1000).format("YYYY-MM-DD ");
    },
    // 排序加名次
    sort(data){
      // 按数量排序，并保持原始顺序
      let currentRank = 1; // 初始排名
      // 增加排名字段
      data.forEach((item, index) => {
        if (index > 0 && item.count === data[index - 1].count) {
          // 如果当前元素和前一个元素的数量相同，则保持相同排名
          item.rank = data[index - 1].rank;
        } else {
          // 否则，设置当前元素的排名为当前排名
          item.rank = currentRank;
        }
        currentRank = item.rank + 1; // 更新当前排名
      });
      return data
    }
  }
}
</script>
<style scoped>
.main-body{
    width: 375px;
    margin: 0 auto;
    position: relative;
}
.banner-top{
    height: 240px;
    width: 100%;
    background: url(../assets/banner1.png) no-repeat center center/375px 240px;
    position: relative;
}
.banner-title{
    /*width: 175px;*/
    height: 30px;
    background: linear-gradient( 180deg, #5C9CEB 0%, #3F85FF 100%);
    border-radius: 15px;
    position: absolute;
    bottom: 34px;
    left:27px;
    padding: 0 12px;
    box-sizing:border-box;
    line-height: 30px;
    color: #ffffff;
    font-size: 12px;
}
.tabs-daily{
    width: 100%;
    height: 50px;
    display: flex;
    background: #3871E7;
    align-items: center;
    padding: 0 15px;
    justify-content: space-between;
    box-sizing: border-box;
}
.tabs-item{
    width: 167px;
    height: 36px;
    box-sizing: border-box;
    padding-top: 7px;
    text-align: center;
    border-radius: 18px;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    cursor: pointer;
}
.active-tab{
    background: #DEF1FF;
    font-weight: 600;
    font-size: 16px;
    color: #3871E7;
}
.daily-content{
    width: 100%;
    background: #BBDEFC;
    padding: 15px 15px 15px 15px;
    box-sizing: border-box;
    min-height: calc( 100vh - 290px);
}
.daily-content-info{
    position: relative;
    margin-bottom: 15px;
    border-radius: 5px;
    background: #DCEEFF;
}
.daily-content-info:last-child{
    margin-bottom: 0;
}
.daily-content-top{
    padding: 0 20px 0 15px;
    /*min-height: 65px;*/
}
.daily-content-fold{
    padding: 14px 21px 23px 15px;
}
.top{
    position: absolute;
    top:-3px;
    display: flex;
    align-items: center;
    flex-direction: column;
    left: 16px;
    padding-top: 6px;
    box-sizing: border-box;
}
.top1{
    top:-9px;
    left: 13px;
    width: 53px;
    height: 66px;
    background: url(../assets/top1.png) no-repeat center center/53px 66px;
    color: #BE7500;
}
.top2{
    top:-9px;
    left: 13px;
    width: 53px;
    height: 66px;
    background: url(../assets/top2.png) no-repeat center center/53px 66px;
    color: #727689;;
}
.top3{
    top:-9px;
    left: 13px;
    width: 53px;
    height: 66px;
    background: url(../assets/top3.png) no-repeat center center/53px 66px;
    color: #D75A1F;
}
.top-default{
    width: 46px;
    height: 60px;
    background:url(../assets/top-default.png) no-repeat center center/46px 60px;
    color: #FFFFFF;
}
.top-title{
    font-size: 13px;
    font-weight: bold;
}
.top-text{
    font-size: 23px;
    font-weight: bold;
    margin-top: 0;
}
.daily-info-top{
    margin-left: 60px;
    display: flex;
    align-items: center;
    min-height: 65px;
    /*justify-content: space-between;*/
    padding:13px 0 ;
    box-sizing: border-box;
}
.daily-info-top-open{
    margin-left: 60px;
    display: flex;
    align-items: center;
    min-height: 65px;
    justify-content: space-between;
    padding:13px 0 ;
    box-sizing: border-box;
}
.daily-company{
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #222222;
    width: 126px;
    white-space: break-spaces;
}
.daily-company-total{
    width: 56px;
    text-align: right;

    color: #222222;
    margin-left: 29px;
}
.daily-company-total .num{
    display: inline-block;
    font-weight: bold;
    font-size: 23px;
    line-height: 32px;
}
.daily-company-total .unit{
    display: inline-block;
    font-size: 18px;
    line-height: 32px;
}
.arrow-fold{
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #4086FF;
    cursor: pointer;
    margin-left: 22px;
}
.arrow-fold img{
    width: 17px;
    height: 17px;
    /*margin-right: 5px;*/
}
.daily-content-open{
    background: #FFFFFF;
    border-radius: 30px 30px 5px 5px;
    /*margin-top: 4px;*/
    padding: 11px 15px 15px 15px;
}
.daily-open-title{
    font-weight: 600;
    font-size: 16px;
    color: #26282E;
    margin-bottom: 17px;
}
.daily-open-title span{
    color: #FC385A;
    font-size: 26px;
    margin-left: 5px;
}
.daily-open-content{
    background: rgba(208, 227, 255,0.15);
    padding: 20px 15px 15px 15px;
    border-radius: 8px;
    position: relative;
}
.daily-open-list{
    font-size: 14px;
    color: #616B7C;
    line-height: 25px;
}
.daily-open-content img{
    width: 24px;
    height: 20px;
}
.mark-top{
    position: absolute;
    top: -8px;
    left: 4px;
}
.mark-bottom{
    position: absolute;
    bottom: 0;
    right: -5px;
}
.back-top{
    width: 50px;
    height: 50px;
    position: fixed;
    z-index: 999;
    bottom: 100px;
    right: 10px;
    background: #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
}
.back-top img{
  width: 100%;
  height: 100%;
}
</style>