<template>
  <div class="w">
    <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        :offset="0"
        @load="onLoad">
      <div class="list">
        <div class="list-box2" v-for="(item,index) in dataList" :key="index">
          <div class="record-box">
            <div class="record-adviser">
              <div class="adviser-name">{{item.user_name}}</div>
              <div class="adviser-id">{{item.user_id}}</div>
            </div>
            <div class="record-index-double">
              <div class="record-index">
                <div class="index-name">方式</div>
                <div class="index-title">{{item.type}}</div>
              </div>
              <div class="record-index">
                <div class="index-name">设备</div>
                <div class="index-title">{{item.equipment}}</div>
              </div>
            </div>
            <div class="record-index">
              <div class="index-name">位置</div>
              <div class="index-title">{{item.address}}</div>
            </div>
            <div class="record-index">
              <div class="index-name">时间</div>
              <div class="index-title">{{formatDate(item.create_time)}}</div>
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
  <!-- S 底部导航栏 -->
  <div class="occupy-box"></div>
  <!-- S 两个底部导航栏 -->
  <div v-if="twoStates">
    <van-tabbar v-model="active" v-if="bottom_nav_visible">
      <van-tabbar-item replace to="/index">
        <span>首页</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <home-filled/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/customSchedule">
        <span>客户列表</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <PieChart/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
  <!-- S 两个底部导航栏 -->
  <!-- S 三个底部导航栏 -->
  <div v-if="threeStates">
    <van-tabbar v-model="active" v-if="bottom_nav_visible">
      <van-tabbar-item replace to="/index">
        <span>首页</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <home-filled/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/approve">
        <span>交接审批</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <Coordinate/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/customSchedule">
        <span>客户列表</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <PieChart/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
  <!-- S 三个底部导航栏 -->

  <!-- S 四个底部导航栏 -->
  <div v-if="fourStates">
    <van-tabbar v-model="active" v-if="bottom_nav_visible">
      <van-tabbar-item replace to="/index">
        <span>首页</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <home-filled/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/customManage">
        <span>客户管理</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <user/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/signedResources">
        <span>已签约</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <edit/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/sharedPool">
        <span>共享池</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <monitor/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
  <!-- S 四个底部导航栏 -->
</template>

<script>
  import {getRecord, getUser} from '../api'
  import {Notify} from "vant";
  import {ref} from "vue";
  import moment from "moment";

  export default {
    name: "loginRecord",
    data() {
      return {
        loading: false, // 上拉加载 ??????
        finished: false, // 上拉加载完毕
        dataList: [],
        current: 1,
        size: 10,
        finishedText: "没有更多了",
        user_id: '',
        user_title: '',

        bottom_nav_visible: true, // 底部导航显示与隐藏

        threeStates: false,//三底部栏
        twoStates: false,//三底部栏
        fourStates: false,//四底部栏
        postId: "" // 权限
      }
    },

    // 生命周期 初始化
    setup() {
      const active = ref(''); // 设定选定第几个
      return {
        active,
      };
    },

    created() {
      // 调用列表查询接口
      this.user_title = sessionStorage.getItem('user_name'); //获取权限参数
      // this.user_title ="申霖"
      // 星迪修改（底部导航栏）
      this.postId = sessionStorage.getItem("postID")
      if (this.postId === '6' || this.postId === '5') { // 判断 文案 或者 运营经理
        this.threeStates = false // 不显示两个个底部
        this.fourStates = false// 不显示四个底部
        this.twoStates = true// 不显示三个底部
        this.active = null   //
      }
      if (this.postId === '3' || this.postId === '4') { // 判断 运营主管 或者 文案主管
        this.threeStates = true // 显示两个个底部
        this.twoStates = false// 不显示三个底部
        this.fourStates = false// 不显示四个底部
        this.active = null
      }
      if (this.postId === '0' || this.postId === '1' || this.postId === '2' || this.postId === '7') { // 判断 管理员
        this.threeStates = false // 显示两个个底部
        this.twoStates = false// 不显示三个底部
        this.fourStates = true// 不显示四个底部
        this.active = null
      }
    },
    methods: {
      // 时间戳转化(年-月-日 时:分:秒)
      formatDate(time) {
        return moment(time * 1000).format("YYYY-MM-DD HH:mm:ss")
      },

      // 返回上一页
      goBackPage() {
        this.$router.go(-1)
      },
      // 上拉刷新
      onLoad() {
        let para = {
          page: this.current, // 页码
          limit: 10, // 每页多少条
          userId: this.user_id, // 客户序号
        }
        getRecord(para).then(data => {
          // 判断token
          if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403) {
            Notify({type: 'danger', message: data.message});
            sessionStorage.clear()
            this.$router.push({
              path: '/login'
            })
            return
          }

          this.loading = false;
          this.current++
          this.dataList = this.dataList.concat(data.data.data)
          if (this.dataList.length >= data.data.count) {
            this.finished = true; // 停止加载
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },
    }
  }
</script>

<style scoped>
  .login-header {
    position: fixed;
    width: 100%;
  }

  /* ---- 内容部分---- */
  .record-box {
    margin: 15px 0;
    padding: 11px 20px;
    background: #fff;
    box-shadow: 0 3px 10px rgba(108, 157, 209, 0.16);
    border-radius: 10px;
  }

  .record-adviser {
    display: flex;
    margin-bottom: 14px;

  }

  .adviser-name {
    font-size: 20px;
    font-weight: bold;
    color: #222222;
    line-height: 34px;
  }

  .adviser-id {
    margin-left: 15px;
    font-size: 15px;
    line-height: 34px;
    color: #A1A7BA;
  }

  .record-index {
    display: flex;
    line-height: 25px;
  }

  .index-name {
    font-size: 15px;
    color: #A1A7BA;
    margin-right: 10px;
    margin-bottom: 2px;
  }

  .index-title {
    font-size: 15px;
    color: #222222;
  }

  .record-index-double {
    display: flex;
    justify-content: space-between;
  }

  .record-index-double .record-index {
    width: 50%;
  }
</style>
