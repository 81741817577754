<template style="width: 100%;height: 100%;">
    <!-- S 输入部分 -->
    <div class="add-wrap">
        <!-- S 选择顾问 -->
        <div class="info-box">
            <div class="info-title">
                <span>选择顾问</span>
                <span class="required-icon">*</span>
            </div>
            <div class="info-input" @click="adviserPopup">
                <van-field class="vant-input-edit" v-model="screen_follow_adviser" readonly placeholder="请选择"/>
                <div class="down-icon">
                    <caret-bottom/>
                </div>
            </div>
        </div>
        <!-- S 选择顾问 -->
        <!-- S 类别单选 -->
        <div class="info-box">
            <div class="info-title">
                <span>类别</span>
                <span class="required-icon">*</span>
            </div>
            <div class="radio-box">
                <van-radio-group v-model="type" direction="horizontal">
                    <div class="radio-item" :class="{radio_active:index+1 === type_active}"
                         v-for="(item, index) in type_data"
                         @click="switchColorType(index+1)">
                        <div class="radio-icon">
                            <div class="radio-icon-spot"></div>
                        </div>
                        <van-radio :name="index+1">{{item}}</van-radio>
                    </div>
                </van-radio-group>
            </div>
        </div>
        <!-- E 类别单选 -->
        <!-- E 权重值输入 -->
        <div class="info-box">
            <div class="info-title">
                <span>权重值</span>
                <span class="required-icon">*</span>
            </div>
            <div class="info-input">
                <van-field class="vant-input-edit" v-model="weight" type="digit"
                           @focus="focusIpt"
                           @blur="blurIpt"
                           maxlength="2" clearable autocomplete="off" placeholder="请输入权重值..."/>
            </div>
        </div>
        <!-- E 权重值输入 -->
        <!-- S 接单状态单选 -->
        <div class="info-box">
            <div class="info-title">
                <span>接单状态</span>
                <span class="required-icon">*</span>
            </div>
            <div class="radio-box">
                <van-radio-group v-model="radio" direction="horizontal">
                    <div class="radio-item" :class="{radio_active:index+1 === line_active}"
                         v-for="(item, index) in line_data"
                         @click="switchColor(index+1)">
                        <div class="radio-icon">
                            <div class="radio-icon-spot"></div>
                        </div>
                        <van-radio :name="index+1">{{item}}</van-radio>
                    </div>
                </van-radio-group>
            </div>
        </div>
        <!-- E 接单状态单选 -->
        <div class="submit-btn1" @click="submit()">提交</div>
    </div>
    <!-- E 输入部分 -->

    <!-- S 选择顾问弹层 -->
    <van-popup v-model:show="follow_adviser_show" position="bottom" :style="{ height: '48%' }">
        <van-picker
            title="请选择顾问"
            show-toolbar
            :columns="follow_adviser_data"
            @confirm="adviserConfirm"
            @cancel="adviserCancel"
        />
    </van-popup>
    <!-- E 跟单顾问弹层 -->
    <!-- S 底部导航栏 -->

    <van-tabbar v-model="active" v-if="bottom_nav_visible">
        <van-tabbar-item replace to="/index">
            <span>首页</span>
            <template #icon="props">
                <div class="navIcon" v-bind:class="{ active: props.active}">
                    <el-icon>
                        <home-filled/>
                    </el-icon>
                </div>
            </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/customManage">
            <span>客户管理</span>
            <template #icon="props">
                <div class="navIcon" v-bind:class="{ active: props.active}">
                    <el-icon>
                        <user/>
                    </el-icon>
                </div>
            </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/signedResources">
            <span>已签约</span>
            <template #icon="props">
                <div class="navIcon" v-bind:class="{ active: props.active}">
                    <el-icon>
                        <edit/>
                    </el-icon>
                </div>
            </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/sharedPool">
            <span>共享池</span>
            <template #icon="props">
                <div class="navIcon" v-bind:class="{ active: props.active}">
                    <el-icon>
                        <monitor/>
                    </el-icon>
                </div>
            </template>
        </van-tabbar-item>
    </van-tabbar>
    <!-- S 底部导航栏 -->
</template>

<script>
    import {categoryList, getAdviserList, saveWeight} from '../api'
    import {Notify} from 'vant';
    import {ref} from "vue";

    export default {
        name: "weightAdd",

        data() {
            return {
                weight: '', // 权重值
                /* ------ 接单单选 ------ */
                radio: 0, // 接单回显
                line_data: ['接单', '不接单'],
                line_active: null, // 接单选中样式
                status: undefined,// 接单单选选中index


                /* ------ 类别单选 ------ */
                type: 0, // 类别回显
                type_data: [],
                type_active: null, // 类别选中样式
                ajaxType: '', // 类别单选选中index
                catData: [],  // 请求回来的分类数据
                /* ---- 跟单顾问部分 ---- */
                saleData: [
                    // {id: 83, user_name: "赖雪涛"},
                    // {id: 82, user_name: "Harry"},
                    // {id: 81, user_name: "孙闯"},
                    // {id: 78, user_name: "范嘉瑞"},
                    // {id: 77, user_name: "李建飞"},
                    // {id: 76, user_name: "吴展"},
                    // {id: 74, user_name: "Jimmy"},
                    // {id: 73, user_name: "秦鑫"},
                    // {id: 72, user_name: "刘晓茜"},
                    // {id: 68, user_name: "韩海玲"},
                    // {id: 64, user_name: "施欣宇"},
                    // {id: 44, user_name: "王曼茹"}
                ],// 请求回来的跟单顾问数据
                user_id: '',
                follow_adviser_show: false, // 跟单顾问弹出层
                screen_follow_adviser: '', // 跟单顾问
                follow_adviser_data: [],// 跟单顾问
                id: '',
                type_id: undefined,
                cat_id: '',
                addStatus: true,

                bottom_nav_visible: true, // 底部导航显示与隐藏
                iosStates: false, // 手机系统

                /* ---- 监听软键盘是否弹出 ---- */
                defaultHeight: '0',  //默认屏幕高度
                nowHeight: '0',  //实时屏幕高度
            }
        },

        // 生命周期 初始化
        setup() {
            const active = ref(''); // 设定选定第几个
            return {
                active,
            };
        },

        created() {
            window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
            if (this.$route.query.id == undefined) {
                this.addStatus = true
                // console.log(true)

            } else {
                this.addStatus = false
                // console.log(false)
            }

            this.iosStates = sessionStorage.getItem("iosStates")
            this.handle(this.$route.query) // 处理顾问list数据
            this.type_data = []
        },

        methods: {
            handle(item) {
                // 请求类别数据
                categoryList().then(data => {
                    if (data.code === 200) {
                        this.catData = data.data
                        for (let i = 0; i < this.catData.length; i++) {
                            this.type_data.push(this.catData[i].title)
                            if (item.title == this.catData[i].title) {
                                //类别回显样式(类别)
                                this.type_active = i + 1
                                this.type_id = i
                            }
                        }
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
                // 请求顾问数据
                getAdviserList().then(data => {
                    if (data.code === 200) {
                        this.saleData = data.data
                        for (let i = 0; i < data.data.length; i++) {
                            this.follow_adviser_data.push(data.data[i].user_name)
                        }
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })

                this.user_id = item.user_id  //赋值 顾问ID
                this.screen_follow_adviser = item.user_name  // 赋值 顾问名称
                this.weight = item.num   // 赋值 权重值
                this.id = item.id   // 赋值 权重值
                // 接单状态  回显处理
                if (parseInt(item.status) == 0) {
                    this.line_active = 2
                    this.status = 0
                    return
                }
                if (parseInt(item.status) == 1) {
                    this.line_active = 1
                    this.status = 1
                    return
                }
            },
            /**
             * 接单选择部分
             * * */
            // 接单单选点击变色
            switchColor(index) {
                this.line_active = index
                if (index == 1) {
                    this.status = 1
                    return
                }
                if (index == 2) {
                    this.status = 0
                }
            },

            /**
             * 类别选择部分
             * */
            // 单选点击变色
            switchColorType(index) {
                this.type_active = index
                this.type_id = index - 1  // 请求选中的
            },
            /**
             * 跟单顾问(搜索部分)
             * */
            // 选择顾问弹层控制
            adviserPopup() {
                this.follow_adviser_show = true
            },

            // 确认赋值
            adviserConfirm(value, index) {
                this.screen_follow_adviser = value // 顾问赋值
                this.follow_adviser_show = false // 关闭弹层
                for (let i = 0; i < this.saleData.length; i++) {
                    if (index == i) {
                        this.user_id = this.saleData[i].id
                    }
                }
            },
            // 苹果键盘事件
            blurIpt() {
                if (this.iosStates == true) {
                    this.bottom_nav_visible = true
                    return
                }
            },
            // 苹果键盘事件
            focusIpt() {
                if (this.iosStates == true) {
                    this.bottom_nav_visible = false
                    return
                }
            },
            // 取消选择
            adviserCancel() {
                this.follow_adviser_show = false // 关闭弹层
            },
            // 提交
            submit() {
                let para;
                if (this.user_id == undefined) {
                    Notify({type: 'danger', message: '请选择顾问'});
                    return
                }
                if (this.type_id == undefined) {
                    Notify({type: 'danger', message: '请选择类别'});
                    return
                }
                if (this.weight == undefined) {
                    Notify({type: 'danger', message: '请输入权重值'});
                    return
                }
                if (this.status == undefined) {
                    Notify({type: 'danger', message: '请选择接单状态'});
                    return
                }
                if (this.addStatus == true) {
                    para = {
                        user_id: this.user_id,// 顾问ID
                        cat_id: this.catData[this.type_id].id,// 项目ID
                        status: this.status,// 接单状态
                        num: this.weight,// 权重值
                    }
                } else {
                    para = {
                        user_id: this.user_id,// 顾问ID
                        cat_id: this.catData[this.type_id].id,// 项目ID
                        status: this.status,// 接单状态
                        num: this.weight,// 权重值
                        id: this.id // 数据ID
                    }
                }

                //
                saveWeight(para).then(data => {
                    if (data.code === 200) {
                        Notify({type: 'success', message: '保存成功！'});
                        this.$router.go(-1)
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            // 返回上一页
            goBackPage() {
                this.$router.go(-1)
            },
        },
        // 获取屏幕默认高度与实时高度
        mounted: function () {
            //获取默认高度
            this.defaultHeight = document.documentElement.clientHeight
            window.onresize = () => {
                return (() => {
                    //实时屏幕高度
                    this.nowHeight = document.documentElement.clientHeight
                })();
            };
        },

        // 监听软键盘是否弹出
        watch: {
            nowHeight: function () {
                if (this.defaultHeight !== this.nowHeight) {
                    this.bottom_nav_visible = false
                } else {
                    this.bottom_nav_visible = true
                }
            }
        },
    }
</script>

<style scoped>
    html,
    body {
        margin: 0;
        padding: 0;
        height: 100px;

    }

    /* ---- header区域 ---- */
    .header {
        position: sticky;
        top: 0;
        height: 52px;
        background: #fff;
        box-shadow: 0 9px 16px rgba(25, 52, 139, 0.06);
        z-index: 9;
    }

    .header-nav {
        position: relative;
        height: 53px;
        color: #222;
        font-size: 19px;
        text-align: center;
        line-height: 53px;
    }

    .header-nav div {
        position: absolute;
        left: 10px;
        width: 19px;
        height: 16px;
    }

    /* ---- 输入部分 ---- */
    .add-wrap {
        width: 100%;
        margin-top: 12px;
        padding: 16px 0 61px;
        background: #fff;
    }

    .info-box {
        width: 345px;
        margin: 0 auto 16px;
    }

    .info-title {
        color: #A1A7BA;
        font-size: 15px;
    }

    .info-input {
        position: relative;
        width: 343px;
        height: 43px;
        margin-top: 12px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .required-icon {
        padding-left: 3px;
        color: #FF0000;
    }

    .vant-input-edit {
        height: 43px !important;
        line-height: 43px !important;
        padding: 0 15px !important;
        color: #222 !important;
        font-size: 15px !important;
    }

    .vant-input-edit >>> input::-webkit-input-placeholder {
        color: #606266 !important;
    }

    .radio-box {
        display: flex;
        margin-top: 12px;
        height: 45px;
        color: #606266;
        font-size: 15px;
    }

    .radio-item {
        position: relative;
        margin-right: 10px;
        border-radius: 4px;
        border: 1px solid #C9CDD6;
        color: #606266;
        font-size: 15px;
    }

    .radio_active {
        border-color: #409EFF;
    }

    .radio-item:last-child {
        margin-right: 0;
    }

    .van-radio--horizontal {
        margin-right: 0;
        padding: 11px;
    }

    .radio-item >>> .van-radio__icon--round .van-icon {
        display: none;
    }

    .radio-item >>> .van-radio__label {
        margin-left: 20px;
    }

    .radio-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 14px;
        left: 12px;
        width: 10px;
        height: 10px;
        border: 2px solid #ABAEB8;
        border-radius: 50%;
    }

    .radio-icon-spot {
        width: 6px;
        height: 6px;
        background: #ABAEB8;
        border-radius: 50%;
    }

    .radio_active .radio-icon {
        border-color: #409EFF;
    }

    .radio_active .radio-icon-spot {
        background: #409EFF;
    }


    .info-textarea {
        height: 110px;
    }

    .info-textarea-edit {
        color: #222 !important;
        font-size: 15px !important;
    }

    .info-textarea-edit >>> textarea::-webkit-input-placeholder {
        color: #606266 !important;
    }

    .info-textarea-edit >>> textarea {
        min-height: 88px;
    }

    .down-icon {
        position: absolute;
        top: 13px;
        right: 15px;
        width: 12px;
        color: #606266;
    }


    .submit-btn1 {
        width: 345px;
        height: 50px;
        margin: 25px auto 35px;
        background: #409EFF;
        color: #fff;
        font-size: 17px;
        text-align: center;
        line-height: 50px;
        border-radius: 50px;
    }

</style>
