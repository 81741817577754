<template>
    <div style="height: 100%">
        <!-- S 筛选条件部分 -->
        <div class="screen detail-w">
            <div class="search-term">
                <div class="search-term-label">时间范围：</div>
                <div class="search-term-detail time-slot-box">
                    <div class="time-slot" @click="inputStartTime">
                        <van-field class="vant-input-edit input-edit-time" v-model="input_start_time" readonly
                                   placeholder="选择开始时间"/>
                    </div>
                    <div class="time-slot-center">至</div>
                    <div class="time-slot" @click="inputEndTime">
                        <van-field class="vant-input-edit input-edit-time" v-model="input_end_time" readonly
                                   placeholder="选择结束时间"/>
                    </div>
                    <div class="down-icon time-clear" v-if="enter_clear_time" @click="enterTimeClear">
                        <van-icon name="clear"/>
                    </div>
                </div>
            </div>
            <div class="search-term">
                <div class="search-term-label">类别</div>
                <div class="search-term-detail" @click="catPopup">
                    <van-field class="vant-input-edit" v-model="cat_info" readonly placeholder="请选择类别"/>
                    <div class="down-icon" v-if="clear_cat_show">
                        <caret-bottom/>
                    </div>
                    <div class="down-icon" @click.stop="clearableCat" v-else>
                        <van-icon name="clear"/>
                    </div>
                </div>
            </div>
            <div class="search-term">
                <div class="search-term-label">顾问</div>
                <div class="search-term-detail" @click="adviserPopup">
                    <van-field class="vant-input-edit" v-model="screen_follow_adviser" clearable readonly placeholder="请选择顾问"/>
                    <div class="down-icon" v-if="clear_adviser_show">
                        <caret-bottom/>
                    </div>
                    <div class="down-icon" @click.stop="clearableAdviser" v-else>
                        <van-icon name="clear"/>
                    </div>
                </div>
            </div>
            <button class="contact-btn contact-btn2" @click="search()">
                <img src="../assets/search.png" alt="" class="search-img">
                搜索</button>
        </div>
        <!-- E 筛选条件部分 -->
        <div class="content-data-box w">
            <div class="data-index" v-for="(item, i) in dataList"
                 :data-id="item.id">

                <div class="item-private">
                    <span class="item-title">顾问名称</span>
                    <span class="item-text">{{item.name}}</span>
                </div>
                <div class="item-third">
                    <span class="item-title">资源总数</span>
                    <span class="item-text">{{item.total}}</span>
                </div>
            </div>
        </div>
        <div class="empty-data" v-if="searchShow">暂无数据</div>
    </div>
    <!-- S 录入开始时间弹层 -->
    <van-popup v-model:show="input_start_show" position="bottom" :style="{ height: '48%' }">
        <van-datetime-picker
                v-model="input_start_date"
                type="date"
                title="选择开始时间"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
                @confirm="inputStartConfirm"
                @cancel="inputStartCancel"
        />
    </van-popup>
    <!-- E 录入开始时间弹层 -->
    <!-- S 录入结束时间弹层 -->
    <van-popup v-model:show="input_end_show" position="bottom" :style="{ height: '48%' }">
        <van-datetime-picker
                v-model="input_end_date"
                type="date"
                title="选择结束时间"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
                @confirm="inputEndConfirm"
                @cancel="inputEndCancel"
        />
    </van-popup>
    <!-- E 录入结束时间弹层 -->
    <!-- S 跟单顾问弹层 -->
    <van-popup v-model:show="follow_adviser_show" position="bottom" :style="{ height: '48%' }">
        <van-picker
                title="请选择顾问"
                show-toolbar
                :columns="follow_adviser_data"
                @confirm="adviserConfirm"
                @cancel="adviserCancel"
        />
    </van-popup>
    <!-- E 跟单顾问弹层 -->
    <!-- S 类别弹层 -->
    <van-popup v-model:show="cat_type_show" position="bottom" :style="{ height: '48%' }">
        <van-picker
                title="请选择类别"
                show-toolbar
                :columns="cat_type_data"
                @confirm="catConfirm"
                @cancel="catCancel"
        />
    </van-popup>
    <!-- E 类别弹层 -->
</template>
<script>
    import {categoryList, getAdviserList,getCatList} from '../api/java'
    import {Dialog, Notify} from "vant";
    import moment from "moment";

    export default {
        name: "demandTransfer",
        data() {
            return {
                screen_company: '',
                screen_contact: '',
                searchShow: true,
                dataList: [],
                /* ---- 时间组件 ---- */
                minDate: new Date(1970, 0, 1),
                maxDate: new Date(2122, 0, 1),
                input_start_date: new Date(), // 录入开始当前时间
                input_end_date: new Date(), // 录入结束当前时间
                visit_start_date: new Date(), // 回访开始当前时间
                visit_end_date: new Date(), // 回访结束当前时间
                formatter(type, val) {
                    if (type === 'year') {
                        return `${val}年`;
                    } else if (type === 'month') {
                        return `${val}月`;
                    } else {
                        return `${val}日`;
                    }
                    return val;
                },

                enter_clear_time: false, // 录入时间重置按钮
                input_start_show: false, // 录入时间开始弹层
                input_end_show: false, // 录入时间结束弹层
                input_start_time: '', // 录入开始时间
                input_end_time: '', // 录入结束时间



                /* ---- 跟单顾问部分 ---- */
                clear_adviser_show: true, // 跟单顾问重置按钮
                follow_adviser_show: false, // 跟单顾问弹出层
                screen_follow_adviser: '', // 跟单顾问
                follow_adviser_id: '', // 跟单顾问id
                follow_adviser_data: [], // vant下拉数据格式(顾问列表)
                request_adviser_data: [], // 接口返回数据格式(顾问列表)


                /* ---- 类别部分 ---- */
                clear_cat_show: true, // 类别重置按钮
                cat_type_show: false, // 跟单顾问弹出层 catPopup
                cat_info: '', // 类别
                cat_type_id: '', // 类别id
                cat_type_data: [], // vant下拉数据格式(类别)
                request_cat_data: [], // 接口返回数据格式(类别)

                /* ---- 公司部分 ---- */
                clear_company_show: true, // 来源问重置按钮
                company_id: '', // 来源id
                company_show: false, // 来源弹出层
                company_info: '', //来源
                request_company_data: [
                    {id: 1, label: '文拓'},
                    {id: 2, label: '其他'},
                ], // 接口返回来源数据
                company_data: ['文拓', '其他'], // vant 来源数据形式
            }
        },
        created() {
            window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
            this.dataList = []
            // this.getTableData(false)
            this.getCatData() // 类别数据
            this.getAdviserData() // 顾问列表数据
        },
        methods: {
            // 取消事件
            inputEndCancel() {
                this.input_end_show = false
            },
            // 苹果键盘事件
            blurIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = true
                    return
                }
            },
            // 苹果键盘事件
            focusIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = false
                    return
                }
            },

            // 返回上一页
            goBackPage() {
                this.$router.go(-1)
            },

            // 点击查找
            search() {
                this.searchStates = false
                this.getTableData(true)
            },

            getTableData(states) {
                this.finished = false; // 停止加载
                this.searchStates = true
                let para = {
                    start_time: this.input_start_time, // 开始时间
                    end_time: this.input_end_time, // 结束时间
                    type:this.cat_type_id,
                    adviser:this.follow_adviser_id,
                }
                if (!para.start_time) {
                    Notify({type: 'danger', message: '请选择开始时间'})
                    return
                }
                if(para.start_time &&!para.end_time){
                    Notify({type: 'danger', message: '请选择结束时间'})
                    return
                }
                if(para.end_time &&!para.start_time){
                    Notify({type: 'danger', message: '请选择开始时间'})
                    return
                }
                if (!para.type) {
                    Notify({type: 'danger', message: '请选择类别'})
                    return
                }
                getCatList(para).then(data => {
                    if (data.code === 200) {
                        if (states === true) {
                            Notify({type: 'success', message: '搜索成功'});
                        }
                       this.dataList = data.data
                        this.loading = false;
                        this.searchStates = false
                        // this.current_page = para.pageNum
                        if (data.data.length > 0) {
                            this.dataShow = true
                            this.searchShow = false
                        } else {
                            this.dataShow = false
                            this.searchShow = true
                        }
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            /**
             * 操作按钮部分
             * */
            topAdd() {
                this.$router.push({
                    path: '/channelEdit',
                })
            },
            jumpAdd(item, states) {
                item.states = states
                this.$router.push({
                    path: '/channelEdit',
                    query: {
                        visa_data: encodeURIComponent(JSON.stringify(item)), // vue路由传递对象
                    }
                })
            },
            /**
             * 录入开始时间部分
             * */
            inputStartTime() {
                this.input_start_show = true
            },

            // 时间戳转化(年-月-日 时:分:秒)
            formatDate(time) {
                return moment(time * 1000).format("YYYY-MM-DD hh:mm:ss")
            },

            // 时间戳转化(年-月-日)
            formatDate2(time) {
                return moment(time).format("YYYY-MM-DD")
            },
            /**
             * 录入结束时间部分
             * */
            inputEndTime() {
                this.input_end_show = true
            },

            // 录入时间重置
            enterTimeClear() {
                this.enter_clear_time = false // 重置按钮消失
                this.input_start_time = '' // 清空录入时间(开始)
                this.input_end_time = '' // 清空录入时间(结束)
            },
            // 确认事件
            inputStartConfirm(val) {
                this.input_start_time = this.formatDate2(val)
                let enter_star_time = new Date(this.input_start_time)
                let enter_end_time = new Date(this.input_end_time)
                if (enter_end_time.getTime() <= enter_star_time.getTime()) {
                    Notify({type: 'danger', message: '开始时间不能大于或等于结束时间'});
                    this.input_start_time = ''
                    return
                }
                this.input_start_show = false
                this.enter_clear_time = true // 显示重置按钮
            },

            // 取消事件
            inputStartCancel() {
                this.input_start_show = false
            },


            /**
             * 录入结束时间部分
             * */
            inputEndTime() {
                this.input_end_show = true
            },

            // 确认事件
            inputEndConfirm(val) {
                this.input_end_time = this.formatDate2(val)
                let enter_star_time = new Date(this.input_start_time)
                let enter_end_time = new Date(this.input_end_time)
                if (enter_end_time.getTime() <= enter_star_time.getTime()) {
                    Notify({type: 'danger', message: '开始时间不能大于或等于结束时间'});
                    this.input_end_time = ''
                    return
                }
                this.input_end_show = false
                this.enter_clear_time = true // 显示重置按钮
            },

            // 取消事件
            inputEndCancel() {
                this.input_end_show = false
            },

            // 顾问列表数据
            getAdviserData() {
                getAdviserList().then(data => {
                    // 判断token
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/login'
                        })
                        return
                    }

                    if (data.code === 200) {
                        this.request_adviser_data = data.data
                        for (let i = 0; i < this.request_adviser_data.length; i++) {
                            this.follow_adviser_data.push(this.request_adviser_data[i].user_name)
                        }
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },

            /**
             * 跟单顾问(搜索部分)
             * */
            // 跟单顾问弹层控制
            adviserPopup() {
                this.follow_adviser_show = true
            },

            // 确认赋值
            adviserConfirm(value) {
                this.screen_follow_adviser = value // 顾问赋值
                // 获取下拉数据id (跟单顾问)
                for (let i = 0; i < this.request_adviser_data.length; i++) {
                    if (value === this.request_adviser_data[i].user_name) {
                        this.follow_adviser_id = this.request_adviser_data[i].id
                    }
                }
                this.follow_adviser_show = false // 关闭弹层
                this.clear_adviser_show = false // 切换图标
            },
            // 取消选择
            adviserCancel() {
                this.follow_adviser_show = false // 关闭弹层
            },
            // 跟单顾问重置
            clearableAdviser() {
                this.screen_follow_adviser = '' // 清空跟单顾问回显
                this.follow_adviser_id = '' // 清空请求时参数
                this.clear_adviser_show = true // 切换图标
            },

            /**
             * 类别(搜索部分)
             * */
            // 类别列表数据
            getCatData() {
                categoryList().then(data => {
                    // 判断token
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/login'
                        })
                        return
                    }
                    if (data.code === 200) {
                        this.request_cat_data = data.data
                        for (let i = 0; i < this.request_cat_data.length; i++) {
                            this.cat_type_data.push(this.request_cat_data[i].title)
                        }
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            // 类别弹层控制
            catPopup() {
                this.cat_type_show = true
            },

            // 确认赋值
            catConfirm(value) {
                this.cat_info = value // 顾问赋值
                // 获取下拉数据id (类别)
                for (let i = 0; i < this.request_cat_data.length; i++) {
                    if (value === this.request_cat_data[i].title) {
                        this.cat_type_id = this.request_cat_data[i].id
                    }
                }
                this.cat_type_show = false // 关闭弹层
                this.clear_cat_show = false // 切换图标
            },

            // 取消选择
            catCancel() {
                this.cat_type_show = false // 关闭弹层
            },
            // 类别重置
            clearableCat() {
                this.cat_info = '' // 清空类别回显
                this.cat_type_id = '' // 清空请求时参数
                this.clear_cat_show = true // 切换图标
            },

        }
    }
</script>

<style scoped>
    /* ---- 客户详情部分---- */
    .data-index {
        padding: 14px 18px;
        background: #ffffff;
        margin: 11px 0 11px;
        position: relative;
        box-shadow: 0 3px 20px 1px rgba(108, 157, 209, 0.16);
        border-radius: 10px 10px 10px 10px;
    }

    .item-title{
        font-size: 15px;
        line-height: 15px;
        color: #A1A7BA;
        margin-right:5px;
    }

    .item-text {
        line-height: 15px;
        font-size: 15px;
        color: #222222;
    }

    .vant-input-edit {
        height: 43px !important;
        line-height: 43px !important;
        padding: 0 15px !important;
        color: #222 !important;
        font-size: 15px !important;
    }

    /* ---- 筛选部分 ---- */
    .detail-w {
        width: 345px;
        margin: 0 auto;
    }

    .screen {
        position: relative;
        margin-top: 15px;
        padding-bottom: 15px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
    }

    .search-term {
        padding-top: 15px;
    }

    .search-term-label {
        padding: 0 20px;
        color: #A1A7BA;
        font-size: 15px;
    }

    .search-term-detail {
        position: relative;
        width: 303px;
        height: 38px;
        margin: 10px auto 0;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .down-icon {
        position: absolute;
        top: 0;
        right: 15px;
        width: 12px;
        height: 100%;
        color: #606266;
    }

    .down-icon svg {
        position: relative;
        top: 11px;
    }

    .down-icon i {
        position: relative;
        top: 11px;
        left: -2px;
        color: #C9C7C8;
    }

    .vant-input-edit {
        height: 38px !important;
        line-height: 38px !important;
        padding: 0 10px !important;
        color: #222 !important;
        font-size: 15px !important;
    }

    .input-edit-time {
        padding: 0 !important;
        line-height: 40px !important;
    }

    .vant-input-edit >>> input::-webkit-input-placeholder {
        color: #606266 !important;
    }

    .contact-icon i {
        top: 1px;
    }

    .contact-icon svg {
        top: 0;
    }

    .time-clear i {
        top: 1px;
    }

    .page-btn input {
        padding: 0;
        width: 43px;
        height: 28px;
        border: none;
        color: #A1A7BA;
        font-size: 15px;
        text-align: center;
    }

    .page-jump >>> input {
        height: 30px;
        color: #A1A7BA;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
    }

    .contact-btn {
        width: 305px;
        margin: 25px auto 0;
        flex-shrink: 0;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #409EFF;
        font-size: 15px;
        border-radius: 4px;
        border: none;
        color: #ffffff;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .time-slot-box {
        display: flex;
        width: 285px;
        padding: 0 10px;
        line-height: 40px;
    }

    .time-slot {
        width: 135px;
        height: 38px;
        color: #606266;
    }

    .time-slot-center {
        margin-right: 10px;
        color: #222;
        font-size: 15px;
    }

    .item-third{
        margin-top: 11px;
    }

    .search-img{
        width: 14px;
       margin-right: 5px;
    }
</style>
