<template>
    <div>
        <!-- S 头部header -->
        <div class="header">
            <div class="header-nav">
                <div class="submit-btn" @click="add()">添加</div>
            </div>
        </div>
        <!-- E 头部header -->
        <div class="list-wrap" v-for="item in list_data">
            <div class="list-item detail-w">
                <div class="list-top">
                    <div class="list-top-num">
                        <span class="list-label">序号</span>
                        <span>{{item.id}}</span>
                    </div>
                    <div class="list-top-num">
                        <span>{{formatDate(item.create_time)}}</span>
                    </div>
                </div>
                <div class="list-info">
                    <div class="info-label">名称</div>
                    <div>{{item.title}}</div>
                </div>
                <div class="operate-info">
                    <div class="both-sides" @click="edit(item)">修改</div>
                    <div class="operate-center" @click="progress(item)">申请进度</div>
                </div>
            </div>
        </div>
        <div class="empty-data" v-if="empty_data">暂无数据</div>
        <!-- S 底部站位盒子 -->
        <div style='height: 61px;'></div>
        <!-- E 底部站位盒子 -->
    </div>
    <!-- S 底部导航栏 -->
    <div class="occupy-box"></div>
    <!-- S 底部导航栏  底部四个的 权限对应0 1 2  -->
    <div class="occupy-box"></div>
    <div  v-if="fourStates">
        <van-tabbar v-model="active">
            <van-tabbar-item replace to="/index">
                <span>首页</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <home-filled/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/customManage">
                <span>客户管理</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <user/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/signedResources">
                <span>已签约</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <edit/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/sharedPool">
                <span>共享池</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <monitor/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
    <!-- S 底部导航栏  底部四个的 权限对应0 1 2-->

    <!-- S 底部导航栏  底部三个的 权限对应 3 4  -->
    <div  v-if="threeStates">
        <van-tabbar v-model="active">
            <van-tabbar-item replace to="/index">
                <span>首页</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <home-filled/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/approve">
                <span>交接审批</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <Coordinate/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/customSchedule">
                <span>客户列表</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <PieChart/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
    <!-- S 底部导航栏  底部三个的 权限对应 3 4-->

    <!-- S 底部导航栏  底部两个的 权限对应 5 6  -->

    <div v-if="twoStates">
        <van-tabbar v-model="active">
            <van-tabbar-item replace to="/index">
                <span>首页</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <home-filled/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/customSchedule">
                <span>客户列表</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <PieChart/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
    <!-- S 底部导航栏  底部两个的 权限对应 5 6-->
</template>

<script>
    import {projectData} from '../api'
    import {Notify} from 'vant';
    import moment from "moment";
    import {ref} from "vue";

    export default {
        data() {
            return {
                /* ---- 列表数据 ---- */
                list_data: [],
                empty_data: false, // 暂无数据
                postId: "", // 权限

                fourStates:true, // 底部栏 四个 权限0 1 2
                threeStates:false, // 底部栏 三个 权限 3 4
                twoStates:true, // 底部栏 两个 权限5 6
            }
        },

        // 生命周期 初始化
        setup() {
            const active = ref(''); // 设定选定第几个
            return {
                active,
            };
        },

        created() {
            window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
            this.getList() // 列表数据
            //修改（底部导航栏）
            this.postId = sessionStorage.getItem("postID")
            if (this.postId === '6' || this.postId === '5') { // 判断 文案 或者 运营经理
                this.fourStates =false // 不显示死个底部
                this.threeStates =false // 不显示两个个底部
                this.twoStates =true// 不显示三个底部
                return
            }
            if (this.postId === '3' || this.postId === '4') { // 判断 运营主管 或者 文案主管
                this.fourStates =false // 不显示死个底部
                this.threeStates =true // 显示两个个底部
                this.twoStates =false// 不显示三个底部
                return
            }
            if (this.postId === '0') { // 判断 管理员
                this.fourStates =true // 不显示两个个底部
                this.threeStates =false // 显示两个个底部
                this.twoStates =false// 不显示三个底部
                return
            }
        },

        // 获取屏幕默认高度与实时高度
        mounted: function () {
            //获取默认高度
            this.defaultHeight = document.documentElement.clientHeight
            window.onresize = () => {
                return (() => {
                    //实时屏幕高度
                    this.nowHeight = document.documentElement.clientHeight
                })();
            };
        },


        methods: {
            // 时间戳转化(年-月-日 时:分:秒)
            formatDate(time) {
                return moment(time * 1000).format("YYYY-MM-DD HH:mm:ss")
            },
            // 列表数据
            getList(val) {
                projectData().then(data => {
                    if (data.code === 200) {
                        // 暂无数据
                        if (data.data.length === 0) {
                            this.empty_data = true
                        } else {
                            this.empty_data = false
                            this.list_data = data.data
                        }
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            // 修改跳转
            edit(item){
                this.$router.push({
                    path: '/projectEdit',
                    query: {
                        data: encodeURIComponent(JSON.stringify(item)), // vue路由传递对象
                    }
                });
            },
            // 新增跳转
            add(){
                this.$router.push({
                    path: '/projectEdit',
                });
            },
            // 申请进度
            progress(item){
                this.$router.push({
                    path: '/progressIndex',
                    query: {
                        data: encodeURIComponent(JSON.stringify(item)), // vue路由传递对象
                    }
                })
            }
        }
    }
</script>

<style scoped>

    .header-nav {
        position: relative;
        height: 53px;
        color: #222;
        font-size: 19px;
        text-align: center;
        line-height: 53px;
    }
    /* ---- 筛选部分 ---- */
    .detail-w {
        width: 345px;
        margin: 0 auto;
    }

    /* ---- 列表数据 ---- */
    .list-wrap {
        margin: 15px 0;
    }

    .list-item {
        padding-bottom: 20px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
    }

    .list-top {
        display: flex;
        justify-content: space-between;
        width: 305px;
        height: 45px;
        margin: 0 auto 16px;
        border-bottom: 1px solid #DDE0E7;
    }

    .list-top-num {
        padding-top: 17px;
        color: #222;
        font-size: 15px;
    }

    .list-label {
        padding-right: 8px;
        color: #A1A7BA;
    }

    .list-info {
        display: flex;
        width: 305px;
        margin: 13px auto 0;
        color: #222;
        font-size: 15px;
    }

    .info-label {
        flex-shrink: 0;
        width: 60px;
        margin-right: 10px;
        color: #A1A7BA;
    }

    .list-info-item {
        display: flex;
    }


    .operate-info {
        display: flex;
        justify-content: space-between;
        width: 305px;
        height: 40px;
        margin: 23px auto 0;
    }

    .both-sides {
        width: 148px;
        height: 38px;
        border-radius: 4px;
        color: #FFFFFF;
        background: #409EFF;
        font-size: 15px;
        text-align: center;
        line-height: 38px;
    }

    .operate-center {
        width: 148px;
        height: 38px;
        background: #409EFF;
        border-radius: 4px;
        color: #FFFFFF;
        font-size: 15px;
        text-align: center;
        line-height: 40px;
    }
</style>
