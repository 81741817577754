<template>

    <div class="renew-box" style="height: 50px;background: white;display: flex;align-items: center;">
        <div class="w remew-center" style="display: flex;justify-content: space-between;align-items: center;">
            <div class="remew-text" style="font-size: 15px;">
                版本号：{{versionVal}}
            </div>
            <van-button type="primary"  style="height: 30px;font-size: 15px; border-radius: 4px;" :disabled="updateVisible" @click="updateVersion">立即更新</van-button>
        </div>
    </div>
    <van-notice-bar
            left-icon="volume-o"
            @click="showMessage()"
            color="#67C23A" background="#f0f9eb"
            :text="'更新公告：' + notice_title"
    />
    <div class="w">
        <div class="common-title">当前登录账户：<span class="user-name">{{userName}}</span></div>
        <!-- S 排行榜 -->
        <div class="chart-box">
            <div class="chart-top-tab">
                <div v-for="(index) in tabData" @click="changes(index)" class="top-tab-item"
                     :class="{active:index == num}">
                    {{index}}
                </div>
            </div>
            <div class="chart-text">
                <div class="left">
                    <div class="text-num">排行</div>
                    <div class="text-name">顾问</div>
                </div>
                <div class="text-count">签单量（单）</div>
            </div>
            <div class="chart-body">
                <van-loading size="24px" vertical v-if="loadState">加载中...</van-loading>
                <div v-for="(item, index) in chartList">
                    <div class="chart-item" v-if="index === 0">
                        <div class="left">
                            <img src="../assets/one.png" alt="" class="item-num">
                            <div class="item-name">
                                <img :src="item.avatar" alt="" class="item-own one">
                                <div>{{item.user_name}}</div>
                            </div>
                        </div>
                        <div class="item-count">{{item.num}}</div>
                    </div>
                    <div class="chart-item" v-else-if="index ===1">
                        <div class="left">
                            <img src="../assets/two.png" alt="" class="item-num">
                            <div class="item-name">
                                <img :src="item.avatar" alt="" class="item-own two">
                                <div>{{item.user_name}}</div>
                            </div>
                        </div>
                        <div class="item-count">{{item.num}}</div>
                    </div>
                    <div class="chart-item" v-else-if="index ===2">
                        <div class="left">
                            <img src="../assets/three.png" alt="" class="item-num">
                            <div class="item-name">
                                <img :src="item.avatar" alt="" class="item-own three">
                                <div>{{item.user_name}}</div>
                            </div>
                        </div>
                        <div class="item-count">{{item.num}}</div>
                    </div>
                    <div class="chart-item" v-else>
                        <div class="left">
                            <div class="item-num">{{index+1}}</div>
                            <div class="item-name">
                                <img :src="item.avatar" alt="" class="item-own">
                                <div>{{item.user_name}}</div>
                            </div>
                        </div>
                        <div class="item-count">{{item.num}}</div>
                    </div>
                </div>
                <div v-if="chartStates" class="noData">暂无数据</div>
            </div>
        </div>
        <!-- S 排行榜 -->
        <div class="contract-detail">
            <div class="contract-detail-left">签约明细</div>
            <van-button type="primary" class="contract-detail-right" @click="detailsPopup()"  :disabled="viewDetails">查看明细</van-button>
<!--            <button class="contract-detail-right" @click="detailsPopup()" :disabled="chartStates">查看明细</button>-->
        </div>
        <div v-if="powerStates">
            <!-- S 待回访数据-->
            <div class="index-table">
                <!-- S 待回访数据标题-->
                <div class="table-title" style="padding-top: 15px">
                    今日待回访
                </div>
                <!-- E 待回访数据标题-->
                <div v-if="addShow" class="noData">暂无待回访</div>
                <div v-for="(item, i) in list" v-else class="table-box">
                    <div class="table-index">
                        <div class="index-top">
                            <div class="top-title">{{item.user_name}}</div>
                            <button @click="documentary(item)" :data-id="item.id">
                                <!--                                <van-icon name="chat-o"/>-->
                                <img src="../assets/index_follow.png" class="follow_btn">
                                <span style="white-space: nowrap">跟单</span>
                            </button>
                        </div>
                        <div class="index-title">
                            <span class="index-left">手机</span>
                            <span class="index-right" v-if="item.user_phone!== ''">{{item.user_phone}}</span>
                            <span class="index-right" v-else>-</span>
                        </div>
                        <div class="index-title">
                            <span class="index-left">微信</span>
                            <span class="index-right" v-if="item.user_chat!== ''">{{item.user_chat}}</span>
                            <span class="index-right" v-else>-</span>
                        </div>
                        <div class="index-title">
                            <span class="index-left">备注</span>
                            <span class="index-right" v-if="item.follow_remarks!== ''">{{item.follow_remarks}}</span>
                            <span class="index-right" v-else>-</span>
                        </div>
                    </div>
                </div>
                <!-- S 待回访数据主体-->
            </div>
            <!-- E 待回访数据-->
        </div>
        <div v-if="screen_states">
            <!-- S 筛选条件部分 -->
            <div class="screen detail-w">
                <div class="search-term">
                    <div class="table-title">
                        客户查找
                    </div>
                    <div class="term-detail-contact">
                        <div class="contact-down" @click="showPopup">
                            <span class="default-text" v-if="select_show">请选择</span>
                            <span class="default-text" v-else>{{contact_info}}</span>
                            <div class="down-icon contact-icon" v-if="clear_contact_show">
                                <caret-bottom/>
                            </div>
                            <div class="down-icon contact-icon" @click.stop="clearableContact" v-else>
                                <van-icon name="clear"/>
                            </div>
                        </div>
                        <div class="contact-detail" style="width: 210px">
                            <van-field class="vant-input-edit" v-model="screen_name"
                                       @focus="focusIpt"
                                       @blur="blurIpt"
                                       clearable autocomplete="off"
                                       placeholder="请输入..."/>
                        </div>
                    </div>
                    <div class="term-detail-contact">
                        <div class="contact-detail" style="margin-left: 0; width: 100%;">
                            <van-field class="vant-input-edit" v-model="screen_customer_name"
                                       @focus="focusIpt"
                                       @blur="blurIpt"
                                       clearable autocomplete="off"
                                       placeholder="请输入客户姓名..."/>
                        </div>
                    </div>
                    <button class="contact-btn contact-btn2" @click="screenSearch()">
                        <img src="../assets/search.png" alt="" class="search-img">
                        搜索
                    </button>
                </div>
                <!-- E 搜索数据标题-->
                <div v-if="contactShow" class="noData">暂无数据</div>
                <div v-else>
                    <div v-for="(item, index) in contactList" :key="index" class="table-index" style="padding-top: 0;">
                        <div class="index-title">
                            <span class="index-left">序号</span>
                            <span class="index-right">{{item.id}}</span>
                        </div>
                        <div class="index-title">
                            <span class="index-left">客户名称</span>
                            <span class="index-right">{{item.user_name}}</span>
                        </div>
                        <div class="index-title">
                            <span class="index-left">跟单顾问</span>
                            <span class="index-right">{{item.counsel_name}}</span>
                        </div>
                        <div class="index-title">
                            <span class="index-left">运营客服</span>
                            <span class="index-right">{{item.official_service_name}}</span>
                        </div>
                        <div class="index-title">
                            <span class="index-left">来源渠道</span>
                            <span class="index-right">{{item.source_name}}</span>
                        </div>
                        <div class="index-title">
                            <span class="index-left">录入时间</span>
                            <span class="index-right">{{formatDate(item.create_time)}}</span>
                        </div>
                    </div>
                </div>
                <!-- S 搜索数据主体-->
            </div>
            <!-- E 筛选条件部分 -->
        </div>
        <div v-if="findStates">
            <!-- S 客户查找部分 -->
            <div class="screen detail-w">
                <div class="search-term">
                    <div class="table-title">
                        客户查找
                    </div>
                    <div class="term-detail-contact">
                        <div class="contact-detail" style="margin-left: 0; width: 100%;">
                            <van-field class="vant-input-edit" v-model="screen_customer_phone"
                                       @focus="focusIpt"
                                       @blur="blurIpt"
                                       clearable autocomplete="off"
                                       placeholder="请输入联系方式..."/>
                        </div>
                    </div>
                    <button class="contact-btn contact-btn2" @click="screenPhone()"><img src="../assets/search.png"
                                                                                         alt="" class="search-img">搜索
                    </button>
                </div>
                <!-- E 搜索数据标题-->
                <div v-if="customertShow" class="noData">暂无数据</div>
                <div v-else>
                    <div class="table-index" style="padding-top: 0;">
                        <div class="index-title">
                            <span class="index-left">客户名称</span>
                            <span class="index-right">{{customertList.customer_name}}</span>
                        </div>
                        <div class="index-title">
                            <span class="index-left">跟单顾问</span>
                            <span class="index-right">{{customertList.user_name}}</span>
                        </div>
                        <div class="index-title">
                            <span class="index-left">是否共享池</span>
                            <span class="index-right" v-if="customertList.share_state ===0">不在</span>
                            <span class="index-right" v-if="customertList.share_state ===1">在共享池</span>
                            <span v-if="customertList.share_state ===''">-</span>
                        </div>
                        <div class="index-title">
                            <span class="index-left">签约状态</span>
                            <span class="index-right" v-if="customertList.issign_bill ===1">是</span>
                            <span class="index-right" v-if="customertList.issign_bill ===2">否</span>
                            <span v-if="customertList.share_state ===''">-</span>
                        </div>
                        <div class="index-title">
                            <span class="index-left">顾问状态</span>
                            <span class="index-right" v-if="customertList.work_status ===1">正常</span>
                            <span class="index-right" v-if="customertList.work_status ===2">休息</span>
                            <span class="index-right" v-if="customertList.work_status ===3">离职中</span>
                            <span class="index-right" v-if="customertList.work_status ===4">离职</span>
                            <span v-if="customertList.work_status ===''">-</span>
                        </div>
                        <div class="index-title">
                            <span class="index-left">录入时间</span>
                            <span class="index-right">{{formatDate(customertList.create_time)}}</span>
                        </div>
                    </div>
                </div>
                <!-- S 搜索数据主体-->
            </div>
            <!-- E 客户查找部分 -->
        </div>
        <!-- S 本季度数据-->
        <div class="index-quarter" v-if="adminStates">
            <div class="table-title">本季度数据</div>
            <div class="quarter-box">
                <div class="quarter-index">
                    <div class="quarter-num">{{count}}</div>
                    <div class="quarter-title">总量</div>
                </div>
                <div class="quarter-index">
                    <div class="quarter-num">{{invalid}}</div>
                    <div class="quarter-title">无效</div>
                </div>
                <div class="quarter-index">
                    <div class="quarter-num">{{effective}}</div>
                    <div class="quarter-title">有效</div>
                </div>
            </div>
        </div>
        <!-- E 本季度数据-->

        <div v-if="censusStates">
            <!-- S 筛选条件部分 -->
            <div class="screen detail-w">
                <div class="table-title" style="padding-top: 15px">
                    客户统计:
                </div>
                <div class="search-term" style="padding-bottom: 0">
                    <div class="search-term-label">录入时间：</div>
                    <div class="search-term-detail time-slot-box">
                        <div class="time-slot" @click="inputStartTime">
                            <van-field class="vant-input-edit input-edit-time" v-model="input_start_time" readonly
                                       placeholder="选择开始时间"/>
                        </div>
                        <div class="time-slot-center">至</div>
                        <div class="time-slot" @click="inputEndTime">
                            <van-field class="vant-input-edit input-edit-time" v-model="input_end_time" readonly
                                       placeholder="选择结束时间"/>
                        </div>
                        <div class="down-icon time-clear" v-if="enter_clear_time" @click="enterTimeClear">
                            <van-icon name="clear"/>
                        </div>
                    </div>
                </div>
                <div class="search-term" style="padding-bottom: 0">
                    <div class="search-term-label">来源渠道</div>
                    <div class="search-term-detail" @click="sourceShow">
                        <van-field
                                class="vant-input-edit"
                                v-model="fieldValue"
                                readonly
                                placeholder="请选择来源渠道"
                        />
                        <div class="down-icon" v-if="clear_source_show">
                            <caret-bottom/>
                        </div>
                        <div class="down-icon" @click.stop="clearableSource" v-else>
                            <van-icon name="clear"/>
                        </div>
                    </div>
                </div>

                <button class="contact-btn contact-btn2" @click="censusSearch()">
                    <img src="../assets/search.png" alt="" class="search-img">
                    搜索
                </button>
            </div>
            <!-- E 筛选条件部分 -->

            <!-- S 列表部分 -->
            <div class="list-wrap" v-for="(item,index) in dataList">
                <div class="list-top">
                    <div class="list-top-num">
                        <span class="list-label">序号</span>
                        <span>{{index+1}}</span>
                    </div>
                </div>
                <div class="list-bottom">
                    <div class="list-bottom-info">
                        <span class="list-label">顾问名称</span>
                        <span>{{ item.title }}</span>
                    </div>
                    <div class="list-bottom-info">
                        <span class="list-label">资源总数</span>
                        <span>{{ item.total }}</span>
                    </div>
                    <div class="list-bottom-info">
                        <span class="list-label" style="padding-right: 25px;">签约数</span>
                        <span>{{ item.success }}</span>
                    </div>
                    <div class="list-bottom-info">
                        <span class="list-label">未分类资源数</span>
                        <span>{{ item.unclassified }}</span>
                    </div>
                    <div class="list-bottom-info">
                        <span class="list-label">未回访数</span>
                        <span>{{ item.noReturnVisit }}</span>
                    </div>
                    <div class="list-bottom-info" style="margin: 10px 0 10px;">
                        <span class="list-label" style="padding-right: 40px;">等级</span>
                    </div>
                    <div class="list-month" style="margin-top: 0;">
                        <div class="list-month-item">A</div>
                        <div class="list-month-item">B</div>
                        <div class="list-month-item">C</div>
                        <div class="list-month-item">D</div>
                        <div class="list-month-item">E</div>
                        <div class="list-month-item">无效</div>
                    </div>
                    <div class="list-number">
                        <div class="list-month-item">{{ item.a }}</div>
                        <div class="list-month-item">{{ item.b }}</div>
                        <div class="list-month-item">{{ item.c }}</div>
                        <div class="list-month-item">{{ item.d }}</div>
                        <div class="list-month-item">{{ item.e }}</div>
                        <div class="list-month-item">{{ item.invalid }}</div>
                    </div>
                </div>
            </div>
            <div class="empty-data" v-if="dataList.length===0">暂无数据</div>
        </div>
        <!-- S 添加客户-->
        <button class="index-add" @click='addNew()' v-if="noSee">
            <div class="add-inside">
                <img src="../assets/index_add.png" class="add-inside_btn">
                <span>添加客户</span>
            </div>
        </button>
        <!-- E 添加客户-->
        <!-- S 功能模块-->
        <!-- S 判断导航剩余一个时-->
<!--        <div v-if="navNum ==1" class="common-nav">-->
<!--            <div class="nav-index-one" v-for="(item, i) in navList">-->
<!--                <div @click="add(item)">-->
<!--                    <div class="nav-bottom-line"></div>-->
<!--                    <div class="nav-right-line"></div>-->
<!--                    <div class="nav-icon">-->
<!--                        <el-icon>-->
<!--                            <component :is="item.ico_m"/>-->
<!--                        </el-icon>-->
<!--                    </div>-->
<!--                    <div class="nav-title">{{item.name}}</div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <!-- S 判断导航剩余一个时-->
        <!-- S 判断导航剩余两个时-->
<!--        <div v-else-if="navNum ==2" class="common-nav">-->
<!--            <div class="nav-index-two" v-for="(item, i) in navList">-->
<!--                <div @click="add(item)">-->
<!--                    <div class="nav-bottom-line"></div>-->
<!--                    <div class="nav-right-line"></div>-->
<!--                    <div class="nav-icon">-->
<!--                        <el-icon>-->
<!--                            <component :is="item.ico_m"/>-->
<!--                        </el-icon>-->
<!--                    </div>-->
<!--                    <div class="nav-title">{{item.name}}</div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <!-- E 判断导航剩余两个时-->
        <!-- S 判断导航剩余零个时-->
<!--        <div v-else class="common-nav">-->
<!--            <div class="nav-index" v-for="(item, i) in navList">-->
<!--                <div @click="add(item)">-->
<!--                    <div class="nav-bottom-line"></div>-->
<!--                    <div class="nav-right-line"></div>-->
<!--                    <div class="nav-icon">-->
<!--                        <el-icon>-->
<!--                            <component :is="item.ico_m"/>-->
<!--                        </el-icon>-->
<!--                    </div>-->
<!--                    <div class="nav-title">{{item.name}}</div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <!-- E 判断导航剩余零个时-->
        <!-- E 功能模块-->
        <!-- S 底部导航栏 -->
        <div class="occupy-box"></div>
        <!-- S 四个底部导航栏 -->
        <div v-if="fourStates">
            <van-tabbar v-model="active" v-if="bottom_nav_visible">
                <van-tabbar-item replace to="/index">
                    <span>首页</span>
                    <template #icon="props">
                        <div class="navIcon" v-bind:class="{ active: props.active}">
                            <el-icon>
                                <home-filled/>
                            </el-icon>
                        </div>
                    </template>
                </van-tabbar-item>
                <van-tabbar-item replace to="/customManage">
                    <span>客户管理</span>
                    <template #icon="props">
                        <div class="navIcon" v-bind:class="{ active: props.active}">
                            <el-icon>
                                <user/>
                            </el-icon>
                        </div>
                    </template>
                </van-tabbar-item>
<!--                <van-tabbar-item replace to="/signedResources">-->
<!--                    <span>已签约</span>-->
<!--                    <template #icon="props">-->
<!--                        <div class="navIcon" v-bind:class="{ active: props.active}">-->
<!--                            <el-icon>-->
<!--                                <edit/>-->
<!--                            </el-icon>-->
<!--                        </div>-->
<!--                    </template>-->
<!--                </van-tabbar-item>-->
<!--                <van-tabbar-item replace to="/sharedPool">-->
<!--                    <span>共享池</span>-->
<!--                    <template #icon="props">-->
<!--                        <div class="navIcon" v-bind:class="{ active: props.active}">-->
<!--                            <el-icon>-->
<!--                                <monitor/>-->
<!--                            </el-icon>-->
<!--                        </div>-->
<!--                    </template>-->
<!--                </van-tabbar-item>-->
            </van-tabbar>
        </div>
        <!-- S 四个底部导航栏 -->
        <!-- S 联系方式弹层-->
        <van-popup v-model:show="contact_show" position="bottom" :style="{ height: '48%' }">
            <van-picker
                    title="请选择联系方式"
                    show-toolbar
                    :columns="contact_data"
                    @confirm="contactConfirm"
                    @cancel="contactCancel"
            />
        </van-popup>
        <!-- E 联系方式弹层 -->
        <!-- S 签约明细弹层-->
        <van-popup v-model:show="details_show" round :style="{ height: '75%' }">
            <div class="details_body">
                <div class="details_body-top">
                    签约明细
                    <div class="details_body-close">
                        <img src="../assets/detail-close.png" @click="closeDeatil()">
                    </div>
                </div>
                <div class="signed-data">
                    <div class="list-item detail-w" v-for="(item, index) in signedData">
                        <div class="list-top">
                            <div class="list-top-num">
                                <span class="list-label">序号</span>
                                <span>{{index+1}}</span>
                            </div>
                        </div>
                        <div class="list-info">
                            <div class="info-label">顾问名称</div>
                            <div>{{item.user_name}}</div>
                        </div>
                        <div class="list-info">
                            <div class="info-label">客户序号</div>
                            <div>{{item.id}}</div>
                        </div>
                        <div class="list-info">
                            <div class="info-label">签约项目</div>
                            <div v-if="item.project_name !==null">{{item.project_name}}</div>
                            <div v-else>-</div>
                        </div>
                        <div class="list-info" v-if="adviserStates">
                            <div class="info-label" >来源渠道</div>
                            <div v-if="item.source_name !==null">{{item.source_name}}</div>
                            <div v-else>-</div>
                        </div>
                        <div class="list-info">
                            <div class="info-label">签约时间</div>
                            <div v-if="item.bill_time!==null">{{formatDate3(item.bill_time)}}</div>
                            <div v-else>-</div>
                        </div>
                    </div>
                    <div v-if="signedData.length===0" class="noData">暂无数据</div>
                    <!-- 更多内容 -->
                </div>
            </div>
        </van-popup>
        <!-- E 签约明细弹层 -->
        <!-- S 录入开始时间弹层 -->
        <van-popup v-model:show="input_start_show" position="bottom" :style="{ height: '48%' }">
            <van-datetime-picker
                    v-model="input_start_date"
                    type="date"
                    title="选择开始时间"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :formatter="formatter"
                    @confirm="inputStartConfirm"
                    @cancel="inputStartCancel"
            />
        </van-popup>
        <!-- E 录入开始时间弹层 -->
        <!-- S 录入结束时间弹层 -->
        <van-popup v-model:show="input_end_show" position="bottom" :style="{ height: '48%' }">
            <van-datetime-picker
                    v-model="input_end_date"
                    type="date"
                    title="选择结束时间"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :formatter="formatter"
                    @confirm="inputEndConfirm"
                    @cancel="inputEndCancel"
            />
        </van-popup>
        <!-- E 录入结束时间弹层 -->
        <!-- S 来源渠道弹层 -->
        <van-popup v-model:show="source_show" position="bottom">
            <van-cascader
                    v-if="source_visible"
                    v-model="cascaderValue"
                    title="请选择来源渠道"
                    :options="options"
                    :field-names="sourceNames"
                    @close="source_show = false"
                    @finish="onFinish"
            />
        </van-popup>
        <!-- E 来源渠道弹层 -->
        <!-- S 更新公告-->
        <van-popup v-model:show="message_show" closeable round>
            <div class="message_body" @click="showImage($event)">
                <div class="notice-info" v-html="notice_info" ></div>
            </div>
        </van-popup>
        <!-- E 更新公告 -->
    </div>
</template>
<script>
    import {ref} from 'vue';
    import {
        checkCustomerInfo,
        getToday,
        getMobileMenu,
        checkCustomerPhone,
        getRankList,
        getCatList,
        getAdviserList, categoryList, userSourceList, getCensusList, noticeInfo, userDeCode
    } from '../api/java'
    import {Notify, ImagePreview, Dialog} from "vant";
    import moment from "moment";

    export default {
        data() {
            return {
                clear_contact_show: true, // 联系方式重置按钮

                /* ---- 监听软键盘是否弹出 ---- */
                defaultHeight: '0',  //默认屏幕高度
                nowHeight: '0',  //实时屏幕高度

                userName: "",
                addShow: true,
                contactShow: true,
                chartStates:true,
                censusStates: false,
                screen_name: '',
                screen_customer_name: '', // 客户查找: 姓名
                screen_customer_phone: '',// 客户查找 ： 联系方式
                loadState:true,
                // 今日待回访数据
                list: [
                    {
                        id: 4842, // 数据id
                        user_name: "正北", // 姓名
                        user_phone: null, // 电话
                        user_chat: "wxid_1xiui72nt1ug22", // 微信
                        user_qq: null, // QQ
                        remind_time: 1626399135, // 提示时间
                        adviser_name: "王曼茹"  // 顾问姓名
                    }
                ],
                customertShow: true,
                customertList: {},//
                contactList: {
                    // id: 92, // 数据id
                    // user_name: "王曼茹", // 客户姓名
                    // source_name: "沈阳文拓线上资源-移民内参会员", // 来源渠道
                    // official_service: "李玲玉", // 运营客服
                    // follow_counsel: "王曼茹", // 跟单顾问
                    // create_time: 1548661854 // 顾问姓名
                },
                //季度数据
                count: '0',//总量
                invalid: '0',//belong
                effective: '0',//有效

                //功能导航数据
                navList: [],
                /* ---- 联系方式选择部分 ---- */
                contact_show: false, // 联系方式弹出层
                select_show: true, // 联系方式选择默认显示
                contact_info: '', // 已选择的联系方式
                contact_info_type: [
                    "user_phone",
                    "user_chat",
                    "user_email",
                    "user_qq",
                ], // 已选择的联系方式id
                type: '',
                customer_contact: '', // 客户联系方式
                contact_data: ['手机', '微信', '邮箱', 'QQ'],
                get_contact_data: [
                    {id: '0', title: '手机'},
                    {id: '1', title: '微信'},
                    {id: '2', title: '邮箱'},
                    {id: '3', title: 'QQ'},
                ],
                powerStates: true,  // true 为顾问 false为运营
                adminStates: false,
                navNum: 0, //余数
                screen_states: false,
                bottom_nav_visible: true, // 底部导航显示与隐藏
                iosStates: false,
                threeStates: false,//三底部栏
                twoStates: false,//三底部栏
                fourStates: false,//四底部栏
                postId: "", // 权限
                noSee: true,
                tabList: [],// 底部导航数据

                findStates: false,

                tabData: ["月排行", "季度排行",], //导航栏标题

                num: "月排行",
                chartList: [],
                signedData: [],

                details_show: false,// 签约明细


                screen_company: '',
                screen_contact: '',
                searchShow: true,
                dataList: [],
                /* ---- 时间组件 ---- */
                minDate: new Date(1970, 0, 1),
                maxDate: new Date(2122, 0, 1),
                input_start_date: new Date(), // 录入开始当前时间
                input_end_date: new Date(), // 录入结束当前时间
                visit_start_date: new Date(), // 回访开始当前时间
                visit_end_date: new Date(), // 回访结束当前时间
                formatter(type, val) {
                    if (type === 'year') {
                        return `${val}年`;
                    } else if (type === 'month') {
                        return `${val}月`;
                    } else {
                        return `${val}日`;
                    }
                    return val;
                },

                enter_clear_time: false, // 录入时间重置按钮
                input_start_show: false, // 录入时间开始弹层
                input_end_show: false, // 录入时间结束弹层
                input_start_time: '', // 录入开始时间
                input_end_time: '', // 录入结束时间


                /* ---- 来源渠道部分 ---- */
                clear_source_show: true, // 来源渠道重置按钮
                source_visible: false, // 来源渠道级联选择部分控制
                source_show: false, // 来源渠道popup弹窗控制
                source: '', // 接口传参所用数据
                fieldValue: '',
                cascaderValue: '',
                // 来源渠道自定义字段
                sourceNames: {
                    text: 'title',
                    value: 'id',
                    children: 'children'
                },
                options: [], // 来源渠道数据

                /* ---- 公司部分 ---- */
                clear_company_show: true, // 来源渠道问重置按钮
                company_id: '', // 来源渠道id
                company_show: false, // 来源渠道弹出层
                company_info: '', //来源渠道
                request_company_data: [
                    {id: 1, label: '文拓'},
                    {id: 2, label: '其他'},

                ], // 接口返回来源渠道数据
                company_data: ['文拓', '其他'], // vant 来源渠道数据形式

                message_show: false,
                notice_info: '', // 公告详情
                notice_title: '', // 公告内容

                indexUpdate: false,
                versionVal:'',//版本号
                updateVisible: false, // 更新按钮
                hiddenVersionVal:'',//


                adviserStates:true,//
                viewDetails:true
            }
        },
        methods: {
            // 公告显示图片
            showImage(e){
                if(e.target){
                    if(e.target.nodeName ==='IMG'){
                        let seeData=[]
                        seeData.push(e.target.currentSrc)
                        ImagePreview({
                            images: seeData,
                            closeable: true,
                        });
                    }
                }
            },

            // 检测是否存在新版本
            updateVersion(){
                let para = {
                    id: sessionStorage.getItem('userId')
                }
                getMobileMenu(para).then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403 || data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        Dialog.confirm({
                            message: '是否刷新页面更新到最新版本?',
                        })
                            .then(() => {
                                location.reload()
                                sessionStorage.setItem('version', data.version) // 更新本地版本号
                                this.updateVisible = false
                                sessionStorage.setItem('update_btn', '0')
                            })
                            .catch(() => {
                                this.updateVisible = true
                            });
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            // 来源渠道数据
            getSource() {
                userSourceList().then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403 || data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        this.options = data.data
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },

            // 时间戳转化(年-月-日 时:分:秒)
            formatDate(time) {
                return moment(time * 1000).format("YYYY-MM-DD HH:mm:ss")
            },
            // 顶部tab切换
            changes(key) {
                this.num = key;
                this.loadState =true
                this.chartStates =false
                this.signedData =[]
                this.chartList=[]
                this.getRank()
            },
            phoneType() {
                let u = navigator.userAgent, app = navigator.appVersion;
                let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //如果输出结果是true就判定是android终端或者uc浏览器
                let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //根据输出结果true或者false来判断ios终端
                if (isAndroid) {
                    this.iosStates = false
                    sessionStorage.setItem('iosStates', false)  //
                } else if (isiOS) {
                    // alert("苹果手机进来的");
                    sessionStorage.setItem('iosStates', true)  //
                    this.iosStates = true
                }
            },
            blurIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = true
                    return
                }
            },
            focusIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = false
                    return
                }
            },
            // 跟单
            documentary(item) {
                console.log(item.id)
                this.$router.push({
                    path: '/documentary',
                    query: {
                        id: item.id
                    }
                });
            },
            // 联系方式查找
            screenPhone() {
                let para = {
                    content: this.screen_customer_phone
                }
                checkCustomerPhone(para).then(data => {
                    if (data.code === 200) {
                        Notify({type: 'success', message: '搜索成功', duration: 1000});
                        if (data.data.length = 0) {
                            this.customertShow = true
                        } else {
                            this.customertShow = false
                            this.customertList = data.data
                        }
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })

            },
            addNew() {
                this.$router.push({
                    path: '/customer_add',
                });
            },
            add(item) {
                this.$router.push({path: item.path}); // 跳转页面
            },
            showPopup() {
                this.contact_show = true;
            },
            contactConfirm(value) {
                this.contact_info = value // 联系方式赋值
                this.select_show = false // 关闭默认文字
                // 获取下拉数据id(联系方式类型)
                for (let i = 0; i < this.get_contact_data.length; i++) {
                    if (value === this.get_contact_data[i].title) {
                        this.type = this.contact_info_type[i]
                    }
                }
                this.contact_show = false // 关闭弹层
                this.clear_contact_show = false // 切换重置图标
            },

            // 取消选择
            contactCancel() {
                this.contact_show = false // 关闭弹层
            },
            //今日待回访（顾问显示）
            adviserToday() {
                getToday().then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403 || data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        if (data.data.length == 0) {
                            this.addShow = true
                        } else {
                            this.addShow = false
                            this.list = data.data
                        }
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            // 登陆获取数据
            getMenu() {
                let para = {
                    id: sessionStorage.getItem('userId')
                }
                getMobileMenu(para).then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403 || data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        this.navList = []
                        let menuData = []
                        for (let i = 0; i < data.data.length; i++) {
                            if (data.data[i].mobile_status == 1) {
                                menuData.push(data.data[i])
                            }
                        }
                        this.navList = menuData
                        this.versionVal =sessionStorage.getItem('version');
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            //客户查找数据（运营显示）
            screenSearch() {
                let para = {
                    type: this.type,
                    content: encodeURIComponent(this.screen_name),
                    name: this.screen_customer_name
                }

                if (!para.name) {
                    if (!para.type) {
                        Notify({type: 'danger', message: '请选择联系方式！'});
                        return false
                    }
                    if (!para.content) {
                        Notify({type: 'danger', message: '请输入方式内容'});
                        return false
                    }
                }
                checkCustomerInfo(para).then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403 || data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code !== 200) {
                        this.contactShow = true
                        Notify({type: 'danger', message: data.message});
                    } else {
                        if (!data.data) {
                            this.contactShow = true
                        } else {
                            Notify({type: 'success', message: '搜索成功'});
                            data.data.create_time = this.$moment(data.data.create_time * 1000).format("YYYY-MM-DD HH:mm:ss")
                            this.contactList = data.data
                            this.contactShow = false
                        }
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },

            // 时间戳转化(年-月-日 时:分:秒)
            formatDate(time) {
                return moment(time * 1000).format("YYYY-MM-DD HH:mm:ss")
            },

            // 联系方式重置
            clearableContact() {
                this.contact_info = '' // 清空联系类型回显
                this.type = '' // 清空请求时参数
                this.select_show = true // 默认文字显示
                this.clear_contact_show = true // 切换重置图标
            },
            getRank() {
                this.chartStates =false
                this.viewDetails =true
                this.signedData =[]
                this.chartList=[]
                var type = 0
                if (this.num === '月排行') {
                    type = 0
                } else {
                    type = 1
                }
                let para = {
                    type: type,
                }

                getRankList(para).then(data => {
                    this.loadState =true
                    this.chartStates =false
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403 || data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code !== 200) {
                        this.loadState =false
                        Notify({type: 'danger', message: data.message});
                    } else {
                        this.loadState =false
                        if (data.data.data.length ===0 ) {
                            this.chartStates =true
                            this.viewDetails =true
                        } else {
                            this.chartStates =false
                            this.viewDetails =false
                            this.chartList = data.data.list
                            this.signedData = data.data.data
                        }
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },


            detailsPopup() {
                this.details_show = true;
            },

            closeDeatil() {
                this.details_show = false;
            },


            // 取消事件
            inputEndCancel() {
                this.input_end_show = false
            },
            // 苹果键盘事件
            blurIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = true
                    return
                }
            },
            // 苹果键盘事件
            focusIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = false
                    return
                }
            },

            // 返回上一页
            goBackPage() {
                this.$router.go(-1)
            },

            // 点击查找
            censusSearch() {
                this.searchStates = false
                this.getTableData(true)
            },

            getTableData(states) {
                this.finished = false; // 停止加载
                this.searchStates = true
                let para = {
                    start_time: this.input_start_time, // 开始时间
                    end_time: this.input_end_time, // 结束时间
                    source: this.source,
                }
                if (!para.start_time) {
                    Notify({type: 'danger', message: '请选择开始时间'})
                    return
                }
                if (para.start_time && !para.end_time) {
                    Notify({type: 'danger', message: '请选择结束时间'})
                    return
                }
                if (para.end_time && !para.start_time) {
                    Notify({type: 'danger', message: '请选择开始时间'})
                    return
                }

                getCensusList(para).then(data => {
                    if (data.code === 200) {
                        if (states === true) {
                            Notify({type: 'success', message: '搜索成功'});
                        }
                        this.dataList = data.data.data
                        this.loading = false;
                        this.searchStates = false
                        // this.current_page = para.pageNum
                        if (data.data.length > 0) {
                            this.dataShow = true
                            this.searchShow = false
                        } else {
                            this.dataShow = false
                            this.searchShow = true
                        }
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            /**
             * 操作按钮部分
             * */
            topAdd() {
                this.$router.push({
                    path: '/channelEdit',
                })
            },
            jumpAdd(item, states) {
                item.states = states
                this.$router.push({
                    path: '/channelEdit',
                    query: {
                        visa_data: encodeURIComponent(JSON.stringify(item)), // vue路由传递对象
                    }
                })
            },
            /**
             * 录入开始时间部分
             * */
            inputStartTime() {
                this.input_start_show = true
            },

            // 时间戳转化(年-月-日 时:分:秒)
            formatDate(time) {
                return moment(time * 1000).format("YYYY-MM-DD HH:mm:ss")
            },

            // 时间戳转化(年-月-日)
            formatDate2(time) {
                return moment(time).format("YYYY-MM-DD")
            },

            // 时间戳转化(年-月-日)
            formatDate3(time) {
                return moment(time * 1000).format("YYYY-MM-DD")
            },
            /**
             * 录入结束时间部分
             * */
            inputEndTime() {
                this.input_end_show = true
            },

            // 录入时间重置
            enterTimeClear() {
                this.enter_clear_time = false // 重置按钮消失
                this.input_start_time = '' // 清空录入时间(开始)
                this.input_end_time = '' // 清空录入时间(结束)
            },
            // 确认事件
            inputStartConfirm(val) {
                this.input_start_time = this.formatDate2(val)
                let enter_star_time = new Date(this.input_start_time)
                let enter_end_time = new Date(this.input_end_time)
                if (enter_end_time.getTime() <= enter_star_time.getTime()) {
                    Notify({type: 'danger', message: '开始时间不能大于或等于结束时间'});
                    this.input_start_time = ''
                    return
                }
                this.input_start_show = false
                this.enter_clear_time = true // 显示重置按钮
            },

            // 取消事件
            inputStartCancel() {
                this.input_start_show = false
            },


            /**
             * 录入结束时间部分
             * */
            inputEndTime() {
                this.input_end_show = true
            },

            // 确认事件
            inputEndConfirm(val) {
                this.input_end_time = this.formatDate2(val)
                let enter_star_time = new Date(this.input_start_time)
                let enter_end_time = new Date(this.input_end_time)
                if (enter_end_time.getTime() <= enter_star_time.getTime()) {
                    Notify({type: 'danger', message: '开始时间不能大于或等于结束时间'});
                    this.input_end_time = ''
                    return
                }
                this.input_end_show = false
                this.enter_clear_time = true // 显示重置按钮
            },

            // 取消事件
            inputEndCancel() {
                this.input_end_show = false
            },

            // 顾问列表数据
            getAdviserData() {
                getAdviserList().then(data => {
                    // 判断token
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/login'
                        })
                        return
                    }

                    if (data.code === 200) {
                        this.request_adviser_data = data.data
                        for (let i = 0; i < this.request_adviser_data.length; i++) {
                            this.follow_adviser_data.push(this.request_adviser_data[i].user_name)
                        }
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },


            /**
             * 来源渠道部分逻辑(搜索)
             * */
            // 来源渠道重置
            clearableSource() {
                this.source_visible = false // 来源渠道级联选择部分控制
                this.fieldValue = '' // 清空来源渠道回显
                this.source = '' // 清空来源渠道传递参数
                this.cascaderValue = '' // 重置级联选择器
                this.clear_source_show = true // 切换重置图标
            },

            sourceShow() {
                this.source_show = true; // 来源渠道popup弹窗控制
                this.source_visible = true // 来源渠道级联选择部分控制
            },

            // 全部选项选择完毕后，会触发 finish 事件
            onFinish({selectedOptions}) {
                this.source_show = false;
                this.fieldValue = selectedOptions.map((option) => option.title).join('/');
                let source_arr = []
                for (let i = 0; i < selectedOptions.length; i++) {
                    source_arr.push(selectedOptions[i].id)
                }
                this.source = source_arr.toString() // 来源渠道赋值(请求数据时候用)
                this.clear_source_show = false // 切换重置图标
            },

            showMessage() {
                this.message_show = true
            },

            // 获取公告详情
            getNotice(state) {
                let para = {
                    status: state
                }
                noticeInfo(para).then(data => {
                    // 判断token
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/login'
                        })
                        return
                    }

                    if (data.code === 200) {
                        this.notice_title = data.data.title // 公告赋值
                        if (state === 1) {
                            this.notice_info = data.data.content // 公告详情
                            const regex = new RegExp('<img', 'gi');
                            this.notice_info = this.notice_info.replace(regex, `<img style="max-width: 100%;"`);
                        }
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })

            },
        },
        // 生命周期 初始化
        setup() {
            const active = ref(0); // 设定选定第几个
            return {
                active,
            };
        },
        created() {
            window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
            this.fourStates = true
            this.userName = sessionStorage.getItem('userName');
            this.versionVal =sessionStorage.getItem('version');
            this.phoneType()
            this.getNotice(1) // 获取数据
            // this.getMenu()
            this.getRank() //
            this.postId = sessionStorage.getItem('postID')
            if (this.postId === '2' || this.postId === '7') {
                this.screen_states = false
                this.findStates = true
                this.powerStates = true
                this.adviserStates = false
                this.adviserToday()
            }
            if (this.postId === '0') {
                this.getSource()   // 获取来源渠道
                this.screen_states = true
                this.findStates = true
                this.powerStates = false
                this.censusStates = true
            }
            if (sessionStorage.getItem('update_btn') === '1') {
                this.updateVisible = false
            }else{
                this.updateVisible = true
            }

        },
        // 获取屏幕默认高度与实时高度
        mounted: function () {
            //获取默认高度
            this.defaultHeight = document.documentElement.clientHeight
            window.onresize = () => {
                return (() => {
                    //实时屏幕高度
                    this.nowHeight = document.documentElement.clientHeight
                })();
            };
        },
        // 监听软键盘是否弹出
        watch: {
            nowHeight: function () {
                if (this.defaultHeight !== this.nowHeight) {
                    this.bottom_nav_visible = false
                } else {
                    this.bottom_nav_visible = true
                }
            }
        },
    };
</script>
<style scoped>
    html,
    body {
        background: #F5F5FC;
    }

    .w {
        width: 92%;
        margin: 0 auto;
    }

    .common-title {
        height: 58px;
        line-height: 58px;
        color: #A1A7BA;
    }

    .user-name {
        margin-left: 10px;
        font-size: 18px;
        font-weight: bold;
        color: #222222;
    }

    .index-table {
        margin: 15px 0;
        padding: 10px 20px 0;
        background: #ffffff;
        box-shadow: 0 3px 10px rgba(108, 157, 209, 0.16);
        border-radius: 10px;
    }

    .table-title {
        font-size: 15px;
        color: #606266;
        padding-bottom: 10px;
        border-bottom: 1px solid #DCDFE6;
    }

    .table-index {
        border-bottom: 1px solid #DCDFE6;
        padding-bottom: 22px;
        padding-top: 24px;
    }

    .table-box:last-child .table-index {

        border: none;
    }

    .index-top {
        display: flex;
        justify-content: space-between;
    }

    .top-title {
        width: 60%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #222222;
        font-size: 18px;
    }

    .index-top button {
        width: 67px;
        height: 30px;
        background: rgba(64, 158, 255, 0.39);
        border-radius: 8px;
        background: #409EFF;
        border: none;
        font-size: 15px;
        color: #FFFFFF;
        position: relative;
    }

    .index-title {
        padding-top: 20px;
        display: flex;
    }

    .index-left {
        font-size: 15px;
        color: #A1A7BA;
        flex-shrink: 0;
    }

    .index-right {
        margin-left: 10px;
        font-size: 15px;
        color: #222222;
    }

    .index-table:last-child {
        border: none;
    }

    /*添加客户样式*/
    .index-add {
        margin-bottom: 15px;
        width: 100%;
        line-height: 48px;
        background: #409EFF;
        box-shadow: 0 3px 10px rgba(64, 158, 255, 0.6);
        border-radius: 10px;
        border: none;
        padding: 0;
        position: relative;
    }

    .add-inside {
        margin: 10px;
        border: 1px dashed #FFFFFF;
        border-radius: 7px;
        font-size: 18px;
        color: #ffffff;
    }

    .common-nav {
        margin-top: 15px;
        padding: 20px;
        background: rgba(255, 255, 255, 0.39);
        box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
        opacity: 1;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: 25px;
    }

    .nav-index {
        width: 33%;
        height: 80px;
        position: relative;
    }

    .nav-bottom-line {
        position: absolute;
        bottom: 0;
        left: 7px;
        height: 1px;
        background: #DCDFE6;
        width: 85px;
    }

    .nav-right-line {
        position: absolute;
        top: 7px;
        right: 0;
        width: 1px;
        background: #DCDFE6;
        height: 65px;
    }

    .nav-index:nth-last-child(1) .nav-bottom-line {
        width: 0;
    }

    .nav-index:nth-last-child(2) .nav-bottom-line {
        width: 0;
    }

    .nav-index:nth-last-child(3) .nav-bottom-line {
        width: 0;
    }

    .nav-index:nth-child(3n) .nav-right-line {
        height: 0;
    }

    /* S  导航余数等于2*/

    .nav-index-two {
        width: 33%;
        height: 80px;
        position: relative;
    }

    .nav-index-two:nth-last-child(1) .nav-bottom-line {
        width: 0;
    }

    .nav-index-two:nth-last-child(2) .nav-bottom-line {
        width: 0;
    }

    .nav-index-two:nth-child(3n) .nav-right-line {
        height: 0;
    }

    /* E  导航余数等于2*/

    /* S  导航余数等于1*/
    .nav-index-one {
        width: 33%;
        height: 80px;
        position: relative;
    }

    .nav-index-one:nth-last-child(1) .nav-bottom-line {
        width: 0;
    }

    .nav-index-one:nth-child(3n) .nav-right-line {
        height: 0;
    }

    /* E  导航余数等于1*/

    .nav-icon {
        width: 21px;
        height: 21px;
        margin: 17px auto 4px;
    }

    .nav-title {
        width: 100%;
        text-align: center;
        font-size: 15px;
        color: #222222;
        margin-top: 7px;
    }

    /*底部栏*/
    .navIcon {
        height: 21px;
        width: 21px;
    }

    /*暂无数据样式*/
    .noData {
        padding: 20px 0;
        text-align: center;
        font-size: 15px;
        color: #A1A7BA;
    }

    /*季度数据*/
    .index-quarter {
        margin-top: 15px;
        padding: 10px 20px 0;
        background: #ffffff;
        box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
        border-radius: 10px;
    }

    .quarter-box {
        display: flex;
        padding: 25px 0;
    }

    .quarter-index {
        flex: 1;
        text-align: center;
    }

    .quarter-num {
        width: 100%;
        font-size: 18px;
        font-weight: bold;
        line-height: 30px;
        color: #409EFF;
    }

    .quarter-title {
        width: 100%;
        font-size: 15px;
        color: #222222;
    }

    .screen {
        margin: 15px 0;
        background: #FFFFFF;
        border-radius: 10px;
        box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
        padding: 10px 20px 0;
    }


    .search-term {
        padding: 15px 0;
    }


    .down-icon {
        position: absolute;
        top: 0;
        right: 15px;
        width: 12px;
        height: 100%;
        color: #606266;
    }

    .down-icon svg {
        position: relative;
        top: 11px;
    }

    .down-icon i {
        position: relative;
        top: 11px;
        left: -2px;
        color: #C9C7C8;
    }

    .contact-icon {
        top: 0;
        right: 8px;
    }

    .contact-icon i {
        top: 1px;
    }

    .contact-icon svg {
        top: 0;
    }

    .vant-input-edit {
        height: 38px !important;
        line-height: 38px !important;
        padding: 0 10px !important;
        color: #222 !important;
        font-size: 15px !important;
    }


    .vant-input-edit >>> input::-webkit-input-placeholder {
        color: #606266 !important;
    }

    .term-detail-contact {
        display: flex;
        width: 305px;
        margin: 25px auto 0;
    }

    .contact-down {
        position: relative;
        width: 76px;
        height: 38px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        line-height: 38px;
        font-size: 15px;
        padding-left: 5px;
    }

    .contact-icon {
        top: 0;
        right: 7px;
    }

    .contact-detail {
        margin-left: 10px;
        width: 150px;
        height: 38px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .contact-btn {
        flex-shrink: 0;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #409EFF;
        font-size: 15px;
        border-radius: 4px;
        border: none;
        color: #ffffff;
        margin-left: 10px;
        white-space: nowrap;
        padding: 0 10px;
    }

    .follow_btn {
        width: 16px;
        height: 15px;
        position: absolute;
        left: 7px;
        top: 7px;
    }

    .index-top span {
        position: relative;
        left: 8px;
    }

    .add-inside_btn {
        position: relative;
        top: 3px;
        width: 17px;
        height: 17px;
        margin-right: 10px;
    }

    .add-inside span {
        display: inline-block;
        margin: 0;
    }

    .screen detail-w .table-index {
        border: none !important;
    }

    .default-text {
        color: #606266;
        font-size: 15px;
    }

    .contact-btn2 {
        width: 100%;
        margin: 25px 0 0 0;
    }

    .chart-box {
        padding-bottom: 1px;
        background: #FFFFFF;
        box-shadow: 0 3px 20px 1px rgba(108, 157, 209, 0.16);
        border-radius: 20px 20px 20px 20px;
        margin-bottom: 15px;
    }

    .chart-top-tab {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 65px 0;
        padding-top: 20px;
    }

    .chart-top-tab .active {
        display: block;
        color: #379CF5;
    }

    .chart-top-tab .active:after {
        display: block;
    }

    .top-tab-item {
        font-size: 18px;
        color: #222222;
        position: relative;
    }

    .top-tab-item:after {
        display: none;
        content: "";
        border-radius: 4px 4px 4px 4px;
        width: 13px;
        height: 4px;
        background: #379CF5;
        position: absolute;
        bottom: -6px;
        left: 39%;
    }


    .chart-text {
        display: flex;
        margin: 23px 10px 20px 20px;
        justify-content: space-between;
    }

    .left {
        display: flex;
        align-items: center;
    }

    .text-num {
        font-size: 13px;
        color: #A1A7BA;
    }

    .text-name {
        margin-left: 45px;
        font-size: 13px;
        color: #A1A7BA;
    }

    .text-count {
        font-size: 13px;
        color: #A1A7BA;
    }

    .chart-body {
        margin: 0 10px 20px 20px;
    }

    .chart-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;
    }


    .item-num {
        width: 29px;
        height: 29px;
        flex-shrink: 0;
        color: #A1A7BA;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
    }

    .item-name {
        flex-shrink: 0;
        margin-left: 45px;
        font-size: 13px;
        color: #222222;
        display: flex;
        align-items: center;
    }

    .item-count {
        width: 78px;
        flex-shrink: 0;
        text-align: center;
        font-size: 13px;
        color: #222222;
    }

    .item-own {
        border-radius: 50%;
        height: 37px;
        width: 37px;
        margin-right: 10px;
        box-sizing: border-box;
        /*被迫怪异盒模型*/
    }

    .one {
        border: 1px solid #FECC00;
        border-radius: 50%;
    }

    .two {
        border: 1px solid #B8CDDC;

        border-radius: 50%;
    }

    .three {
        border: 1px solid #DBC6B3;
        border-radius: 50%;
    }

    .list-item {
        margin: 0 20px;
        background: #fff;
    }

    .list-top {
        display: flex;
        justify-content: space-between;
        height: 45px;
        margin: 0 auto 16px;
        border-bottom: 1px solid #DDE0E7;
    }

    .list-top-num {
        padding-top: 17px;
        color: #222;
        font-size: 15px;
    }

    .list-label {
        padding-right: 8px;
        color: #A1A7BA;
    }

    .list-info {
        display: flex;
        width: 305px;
        margin: 13px auto 0;
        color: #222;
        font-size: 15px;
        align-items: center;
    }

    .info-label {
        flex-shrink: 0;
        width: 60px;
        margin-right: 10px;
        color: #A1A7BA;
    }

    .contract-detail {
        width: 305px;
        padding: 0 20px;
        align-items: center;
        justify-content: space-between;
        height: 64px;
        display: flex;
        margin-bottom: 15px;
        background: #FFFFFF;
        box-shadow: 0 3px 20px 1px rgba(108, 157, 209, 0.16);
        border-radius: 20px 20px 20px 20px;
    }

    .contract-detail-left {
        font-size: 20px;
        font-weight: bold;
        color: #409EFF;
    }

    .contract-detail-right {
        width: 100px;
        height: 33px;
        line-height: 33px;
        background: #409EFF;
        border-radius: 36px 36px 36px 36px;
        text-align: center;
        font-size: 15px;
        color: #FFFFFF;
    }

    .details_body {
        width: 325px;
        background: #FFFFFF;
        border-radius: 32px 32px 32px 32px;
    }

    .details_body-top {
        position: sticky;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 17px;
        color: #FFFFFF;
        width: 100%;
        height: 50px;
        background: #409EFF;
    }

    .details_body-close {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .details_body-close img {
        width: 14px;
        height: 14px;
    }

    /* ---- 筛选部分 ---- */


    .screen {
        position: relative;
        margin-top: 15px;
        padding-bottom: 15px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
    }

    .search-term-label {
        color: #A1A7BA;
        font-size: 15px;
    }

    .search-term-detail {
        position: relative;
        width: 303px;
        height: 38px;
        margin: 10px auto 0;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .down-icon {
        position: absolute;
        top: 0;
        right: 15px;
        width: 12px;
        height: 100%;
        color: #606266;
    }

    .down-icon svg {
        position: relative;
        top: 11px;
    }

    .down-icon i {
        position: relative;
        top: 11px;
        left: -2px;
        color: #C9C7C8;
    }

    .vant-input-edit {
        height: 38px !important;
        line-height: 38px !important;
        padding: 0 10px !important;
        color: #222 !important;
        font-size: 15px !important;
    }

    .input-edit-time {
        padding: 0 !important;
        line-height: 40px !important;
    }

    .vant-input-edit >>> input::-webkit-input-placeholder {
        color: #606266 !important;
    }

    .contact-icon i {
        top: 1px;
    }

    .contact-icon svg {
        top: 0;
    }

    .time-clear i {
        top: 1px;
    }

    .page-btn input {
        padding: 0;
        width: 43px;
        height: 28px;
        border: none;
        color: #A1A7BA;
        font-size: 15px;
        text-align: center;
    }

    .page-jump >>> input {
        height: 30px;
        color: #A1A7BA;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
    }

    .contact-btn {
        width: 305px;
        margin: 25px auto 0;
        flex-shrink: 0;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #409EFF;
        font-size: 15px;
        border-radius: 4px;
        border: none;
        color: #ffffff;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .time-slot-box {
        display: flex;
        width: 285px;
        padding: 0 10px;
        line-height: 40px;
    }

    .time-slot {
        width: 135px;
        height: 38px;
        color: #606266;
    }

    .time-slot-center {
        margin-right: 10px;
        color: #222;
        font-size: 15px;
    }

    .item-third {
        margin-top: 11px;
    }

    .search-img {
        width: 14px;
        margin-right: 5px;
    }


    /* ---- 列表数据 ---- */
    .list-wrap {
        width: 345px;
        margin: 15px auto;
        padding-bottom: 17px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
    }

    .list-top {
        display: flex;
        justify-content: space-between;
        width: 305px;
        height: 50px;
        margin: 0 auto 17px;
        border-bottom: 1px solid #DDE0E7;
    }

    .list-top-num {
        padding-top: 17px;
        color: #222;
        font-size: 15px;
        line-height: 20px;
    }

    .list-label {
        padding-right: 10px;
        color: #A1A7BA;
    }

    .list-bottom {
        display: flex;
        flex-wrap: wrap;
    }

    .list-bottom-info {
        color: #222;
        font-size: 15px;
        line-height: 20px;
        width: 50%;
        padding-left: 20px;
        box-sizing: border-box;
        margin-bottom: 15px;
    }

    .list-bottom-info:first-child {
        width: 100%;
    }

    .list-month {
        height: 24px;
        background: #EDEFF3;
        opacity: 0.43;
        display: flex;
        justify-content: space-evenly;
        margin: 15px 0 0;
        padding: 0 10px;
        box-sizing: border-box;
    }

    .list-number {
        font-size: 15px;
        font-weight: 400;
        color: #222222;
        line-height: 20px;
        display: flex;
        justify-content: space-evenly;
        margin: 7px 0 0;
        padding: 0 10px;
        box-sizing: border-box;
    }

    .list-month-item {
        width: 54px;
        white-space: nowrap;
        line-height: 20px;
        text-align: center;
    }

    .list-month .list-month-item {
        line-height: 24px;
    }

    .message_body {
        font-size: 15px;
        width: 285px;
        padding: 20px;
        background: #FFFFFF;
        border-radius: 32px 32px 32px 32px;
    }

    .signed-data {
        padding-bottom: 20px;
    }
</style>
