<template>
  <!-- S 头部header -->
<!--  <div class="header">-->
<!--    <div class="header-nav">-->
<!--      <div class="header-nav-back" @click="goBackPage()">-->
<!--        <van-icon name="arrow-left"/>-->
<!--      </div>-->
<!--      <span>签证历史</span>-->
<!--    </div>-->
<!--  </div>-->
  <!-- E 头部header -->
  <div>
    <div class="add-wrap">
      <!-- S input必填模块 -->
      <div class="info-box">
        <div class="info-title">
          <span>签证国家</span>
          <span class="required-icon">*</span>
        </div>
        <div class="info-input">
          <van-field class="vant-input-edit" v-model="visa_country" autocomplete="off" clearable
                     placeholder="请输入签证国家..."/>
        </div>
      </div>
      <!-- E input必填模块 -->
      <!-- S input必填模块 -->
      <div class="info-box">
        <div class="info-title">
          <span>签证类型</span>
          <span class="required-icon">*</span>
        </div>
        <div class="info-input">
          <van-field class="vant-input-edit" v-model="visa_type" autocomplete="off" clearable
                     placeholder="请输入签证类型..."/>
        </div>
      </div>
      <!-- E input必填模块 -->
      <!-- S input模块 -->
      <div class="info-box">
        <div class="info-title">
          <span>工作单位</span>
        </div>
        <div class="info-input">
          <van-field class="vant-input-edit" v-model="work_unit" autocomplete="off" clearable
                     placeholder="请输入工作单位..."/>
        </div>
      </div>
      <!-- E input模块 -->
      <!-- S input模块 -->
      <div class="info-box">
        <div class="info-title">
          <span>工作职责</span>
        </div>
        <div class="info-input">
          <van-field class="vant-input-edit" v-model="work_duty" autocomplete="off" clearable
                     placeholder="请输入工作职责..."/>
        </div>
      </div>
      <!-- E input模块 -->
      <!-- S 文本域模块 -->
      <div class="info-box">
        <div class="info-title">
          <span>提交信息</span>
          <span class="required-icon">*</span>
        </div>
        <div class="info-input info-textarea">
          <van-field
              class="info-textarea-edit"
              v-model="submit_info"
              autocomplete="off"
              rows="1"
              autosize
              type="textarea"
              placeholder="请输入提交信息..."
          />
        </div>
      </div>
      <!-- E 文本域模块 -->
      <!-- S 下拉模块 -->
      <div class="info-box">
        <div class="info-title">
          <span>提交时间</span>
          <span class="required-icon">*</span>
        </div>
        <div class="info-input" @click="timePopup">
          <van-field class="vant-input-edit" v-model="submit_time" readonly placeholder="请选择"/>
          <div class="down-icon" v-if="clear_submit_show">
            <caret-bottom/>
          </div>
          <div class="down-icon" @click.stop="clearableSubmit" v-else>
            <van-icon name="clear"/>
          </div>
        </div>
      </div>
      <!-- E 下拉模块 -->
      <!-- S 有无拒签史单选 -->
      <div class="info-box">
        <div class="info-title">
          <span>有无拒签史</span>
        </div>
        <div class="radio-box">
          <van-radio-group v-model="radio" @change="refuseVisa" direction="horizontal">
            <div class="radio-item" :class="{radio_active:index+1 === refuse_visa_active}"
                 v-for="(item, index) in refuse_visa_data"
                 @click="switchColor(index+1)">
              <div class="radio-icon">
                <div class="radio-icon-spot"></div>
              </div>
              <van-radio :name="index+1">{{item}}</van-radio>
            </div>
          </van-radio-group>
        </div>
      </div>
      <!-- E 有无拒签史单选 -->
      <!-- S 文本域模块 -->
      <div class="info-box" v-if="refuse_visa_show">
        <div class="info-title">
          <span>拒签原因</span>
          <span class="required-icon">*</span>
        </div>
        <div class="info-input info-textarea">
          <van-field
              class="info-textarea-edit"
              v-model="refuse_visa_reason"
              autocomplete="off"
              rows="1"
              autosize
              type="textarea"
              placeholder="请输入拒签原因..."
          />
        </div>
      </div>
      <!-- E 文本域模块 -->
      <div class="remarks-submit" @click="submit">提交</div>
    </div>

    <!-- S 提交时间弹层 -->
    <van-popup v-model:show="time_show" position="bottom" :style="{ height: '48%' }">
      <van-datetime-picker
          v-model="submit_date"
          type="date"
          title="选择提交时间"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="submitTimeConfirm"
          @cancel="submitTimeCancel"
      />
    </van-popup>
    <!-- E 提交时间弹层 -->
  </div>
</template>

<script>
  import {saveVisaHistory} from '../api/java.js'
  import {Notify} from 'vant';
  import moment from "moment";
  import {ref} from "vue";

  export default {
    data() {
      return {
        clear_submit_show: true, // 提交时间重置

        edit_id: '', // 修改id
        visa_country: '', // 签证国家
        visa_type: '', // 签证类型
        work_unit: '', // 工作单位
        work_duty: '', // 工作职责
        submit_info: '', // 提交信息

        submit_time: '', // 提交时间
        time_show: false, // 时间弹窗
        minDate: new Date(1970, 0, 1),
        maxDate: new Date(),
        submit_date: new Date(),
        formatter(type, val) {
          if (type === 'year') {
            return `${val}年`;
          } else if (type === 'month') {
            return `${val}月`;
          } else {
            return `${val}日`;
          }
          return val;
        },

        /* ------ 有无拒签史单选 ------ */
        radio: 2, // 有无拒签史回显
        refuse_visa_data: ['有', '无'],
        refuse_visa_active: null, // 有无拒签史选中样式
        refuse_visa_show: false, // 是否显示拒签原因
        refuse_visa_reason: '', // 拒签原因
        pageTitle:'添加'  // 页面Title
      }
    },

    // 生命周期 初始化
    setup() {
      const active = ref(''); // 设定选定第几个
      return {
        active,
      };
    },

    created() {
      window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
      if (this.$route.query.visa_data) {
        let edit_data = JSON.parse(decodeURIComponent(this.$route.query.visa_data)) // 接收上个页面传递过来的对象
        this.pageTitle ='修改'
        this.edit_id = edit_data.id // 修改数据id
        this.visa_country = edit_data.country // 签证国家回显
        this.visa_type = edit_data.type // 签证类别回显
        this.work_unit = edit_data.work_unit // 工作单位回显
        this.work_duty = edit_data.operating_duty // 工作职责回显
        this.submit_info = edit_data.submit_info // 提交信息
        this.submit_time = this.formatDate(edit_data.time_of_submission) // 提交时间
        this.radio = edit_data.refused // 有无拒签史
        this.refuse_visa_reason = edit_data.reason // 有无拒签史
        // 重置提交时间
        if (edit_data.time_of_submission) {
          this.clear_submit_show = false // 切换图标
        }
      } else{
        this.pageTitle= '添加'
      }
      this.initialRadio() // 有无拒签史单选初始化
      this.radio === 1 ? this.refuse_visa_show = true : this.refuse_visa_show = false // 是否显示拒签原因初始化
    },

    methods: {
      // 时间戳转化(年-月-日)
      formatDate(time) {
        return moment(time*1000).format("YYYY-MM-DD")
      },

      // 时间戳转化(年-月-日)
      formatDate2(time) {
        return moment(time).format("YYYY-MM-DD")
      },

      // 返回上一页
      goBackPage() {
        this.$router.go(-1)
      },


      // 提交时间弹窗
      timePopup() {
        this.time_show = true
      },

      // 时间赋值
      submitTimeConfirm() {
        this.time_show = false
        this.submit_time = this.formatDate2(this.submit_date)
        this.clear_submit_show = false // 切换图标
      },

      // 取消赋值关闭弹窗
      submitTimeCancel() {
        this.time_show = false
      },

      /**
       * 有无拒签史部分
       * */
      // 有无拒签史监听
      refuseVisa() {
        this.radio === 2 ? this.refuse_visa_show = false : this.refuse_visa_show = true
      },

      // 单选点击变色
      switchColor(index) {
        this.refuse_visa_active = index
      },

      // 初始化单选样式(有无拒签史)
      initialRadio() {
        this.refuse_visa_active = this.radio
      },

      // 添加/修改提交
      submit() {
        this.submitRequest()
      },

      // 接口调用
      submitRequest() {
        let that = this
        if (this.$route.query.visa_data) {
          var para = {
            id: this.edit_id, // 数据id
            country: this.visa_country, // 国家
            type: this.visa_type, // 签证类型
            submit_info: this.submit_info, // 提交信息
            work_unit: this.work_unit, // 工作单位
            operating_duty: this.work_duty, // 工作职责
            time_of_submission: this.submit_time, // 提交时间
            refused: this.radio, // 有无拒签史
            reason: this.refuse_visa_reason, // 拒签原因
            customer_id: this.$route.query.customer_id // 客户id
          }
        } else {
          var para = {
            country: this.visa_country, // 国家
            type: this.visa_type, // 签证类型
            submit_info: this.submit_info, // 提交信息
            work_unit: this.work_unit, // 工作单位
            operating_duty: this.work_duty, // 工作职责
            time_of_submission: this.submit_time, // 提交时间
            refused: this.radio, // 有无拒签史
            reason: this.refuse_visa_reason, // 拒签原因
            customer_id: this.$route.query.id // 客户id
          }
        }


        if (!para.country) {
          Notify({type: 'danger', message: '请填写签证国家'});
          return;
        }

        if (!para.type) {
          Notify({type: 'danger', message: '请填写签证类型'});
          return;
        }

        if (!para.submit_info) {
          Notify({type: 'danger', message: '请填写提交信息'});
          return;
        }

        if (!para.time_of_submission) {
          Notify({type: 'danger', message: '请选择提交时间'});
          return;
        }

        if (para.refused === 1) {
          if (!this.refuse_visa_reason) {
            Notify({type: 'danger', message: '请填写拒签原因'});
            return
          }
        }

        // 点击无拒签史清空拒签原因
        if (para.refused !== 1) {
          para.reason = ''
        }

        saveVisaHistory(para).then(data => {
          if (data.code === 200) {
            Notify({
              type: 'success',
              message: '提交成功',
              duration: 750
            })
            setTimeout(function () {
              that.$router.go(-1)
            }, 800)
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 重置提交时间
      clearableSubmit() {
        this.submit_time = '' // 清空时间
        this.clear_submit_show = true // 切换图标
      }

    }
  }
</script>

<style scoped>
  /* ---- 输入部分 ---- */
  .add-wrap {
    width: 100%;
    margin-top: 12px;
    padding: 16px 0;
    background: #fff;
  }

  .info-box {
    width: 345px;
    margin: 0 auto 16px;
  }

  .info-title {
    color: #A1A7BA;
    font-size: 15px;
  }

  .info-input {
    position: relative;
    width: 343px;
    height: 43px;
    margin-top: 12px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
  }

  .required-icon {
    padding-left: 3px;
    color: #FF0000;
  }

  .vant-input-edit {
    height: 43px !important;
    line-height: 43px !important;
    padding: 0 15px !important;
    color: #222 !important;
    font-size: 15px !important;
  }

  .vant-input-edit >>> input::-webkit-input-placeholder {
    color: #606266 !important;
  }

  .radio-box {
    display: flex;
    margin-top: 12px;
    height: 45px;
    color: #606266;
    font-size: 15px;
  }

  .radio-item {
    position: relative;
    margin-left: 10px;
    border-radius: 4px;
    border: 1px solid #C9CDD6;
    color: #606266;
    font-size: 15px;
  }

  .radio-item:last-child {
    margin-left: 0;
  }

  .radio_active {
    border-color: #409EFF;
  }

  .radio-item:last-child {
    margin-right: 0;
  }

  .van-radio--horizontal {
    margin-right: 0;
    padding: 11px;
  }

  .radio-item >>> .van-radio__icon--round .van-icon {
    display: none;
  }

  .radio-item >>> .van-radio__label {
    position: relative;
    margin-left: 0;
    padding-left: 20px;
    z-index: 9;
  }

  .radio-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 14px;
    left: 12px;
    width: 10px;
    height: 10px;
    border: 2px solid #ABAEB8;
    border-radius: 50%;
  }

  .radio-icon-spot {
    width: 6px;
    height: 6px;
    background: #ABAEB8;
    border-radius: 50%;
  }

  .radio_active .radio-icon {
    border-color: #409EFF;
  }

  .radio_active .radio-icon-spot {
    background: #409EFF;
  }

  .submit-btn1 {
    width: 345px;
    height: 50px;
    margin: 25px auto 35px;
    background: #409EFF;
    color: #fff;
    font-size: 17px;
    text-align: center;
    line-height: 50px;
    border-radius: 50px;
  }

  .info-textarea {
    height: 110px;
  }

  .info-textarea-edit {
    color: #222 !important;
    font-size: 15px !important;
  }

  .info-textarea-edit >>> textarea::-webkit-input-placeholder {
    color: #606266 !important;
  }

  .info-textarea-edit >>> textarea {
    min-height: 88px;
  }

  /*重置按钮*/
  .down-icon {
    position: absolute;
    top: 0;
    right: 15px;
    width: 12px;
    height: 100%;
    color: #606266;
  }

  .down-icon svg {
    position: relative;
    top: 14px;
  }

  .down-icon i {
    position: relative;
    top: 14px;
    left: -2px;
    color: #C9C7C8;
  }

  .remarks-submit {
    width: 345px;
    height: 50px;
    margin: 25px auto 0;
    background: #409EFF;
    color: #fff;
    font-size: 17px;
    text-align: center;
    line-height: 50px;
    border-radius: 50px;
  }

  .van-radio-group--horizontal, .van-checkbox-group--horizontal {
    flex-direction: row-reverse;
  }
</style>
