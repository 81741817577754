import {request} from "../service/request"

let url = process.env.VUE_APP_BASE_URL_JAVA // 接口地址

// 动态url
function dynamicUrl(url) {
  if (url) {
    let exportUrl = '';
    Object.keys(url).map((key) => {
      exportUrl += key + '=' + url[key] + '&';
    })
    exportUrl = exportUrl.substring(exportUrl.length - 1, 0)
    return exportUrl;
  }
}

// -------首页模块------- //
// 获取客户信息-
export function checkCustomerInfo(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/checkCustomerInfo?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 获取签约客户-
export function checkCustomerPhone(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/checkCustomer?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 今日待回访-
export function getToday() {
  return request({
    url: url + '/crm/today',
    method: 'get',
  })
}


// 获取来源下拉-
export function downSource() {
  return request({
    url: url + '/crm/chooseSource',
    method: 'get',
  })
}


// 签约项目下拉-
export function downProject() {
  return request({
    url: url + '/crm/projectData',
    method: 'get',
  })
}

// 录入人下拉-
export function enterDown() {
  return request({
    url: url + '/crm/entryPersonList',
    method: 'get',
  })
}

// 来源下拉-
export function userSourceList() {
  return request({
    url: url + '/crm/userSourceList',
    method: 'get',
  })
}

// 顾问人下拉-
export function getAdviserList() {
  return request({
    url: url + '/crm/consultantNameList',
    method: 'get',
  })
}

// 标签下拉-
export function downTag() {
  return request({
    url: url + '/crm/labelDrop',
    method: 'get',
  })
}

// 无效原因下拉-
export function invalidReasonData() {
  return request({
    url: url + '/crm/labelDropTwo',
    method: 'get',
  })
}

// 类别下拉-
export function categoryList() {
  return request({
    url: url + '/crm/categoryList',
    method: 'get',
  })
}

// 已签约列表-
export function getSigned(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/signCustomer?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 客户详情-
export function getCustomerInfo(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/userCustomerDetails?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 所在地下拉-
export function areaDown() {
  return request({
    url: url + '/crm/areaList',
    method: 'get',
  })
}

// 标签下拉-
export function invalidDown() {
  return request({
    url: url + '/crm/labelDropTwo',
    method: 'get',
  })
}

// 签证历史-
export function visaHistoryList(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/visaHistoryList?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 备注历史-
export function userContentList(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/userContentList?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 签证历史修改 新增-
export function saveVisaHistory(data) {
  return request({
    url: url + '/crm/visaHistoryAddUp',
    method: 'post',
    data: data
  })
}

// 签证历史修改 删除-
export function delVisaHistory(data) {
  return request({
    url: url + '/crm/visaHistoryDel',
    method: 'post',
    data: data
  })
}

// 跟单列表-
export function getDocumentary(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/userFollowList?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 新增跟单-
export function addDocumentary(data) {
  return request({
    url: url + '/crm/userFollowSaveUp',
    method: 'post',
    data: data
  })
}

// 跟单列表-
export function getJournalList(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/contentOperationList?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 新增备注-
export function addCustomerRemark(data) {
  return request({
    url: url + '/crm/userContentAdd',
    method: 'post',
    data: data
  })
}

// 转案列表-
export function approvalStatus(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/referralList?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 转案列表-
export function referralInfo(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/referralInfo?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 转案列新增修改-
export function saveReferral(data) {
  return request({
    url: url + '/crm/saveReferral',
    method: 'post',
    data: data
  })
}

// 文件上传
export function handoverImg(data) {
  return request({
    url: url + '/common/ossUpload',
    method: 'post',
    data: data
  })
}

// 客户列表-
export function getCustomerAdmin(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/userCustomerList?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 共享池列表-
export function getSharedPool(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/sharedCustomer?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 客户添加-
export function addCustomer(data) {
  return request({
    url: url + '/crm/userCustomerAdd',
    method: 'post',
    data: data
  })
}

// 渠道列表（级联）-
export function channelList(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/channelList?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 客户转单列表-
export function getTransferList(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/customerTransfer?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 客户转单操作-
export function transferOrder(data) {
  return request({
    url: url + '/crm/change',
    method: 'post',
    data: data
  })
}

// 删除转案单-
export function referralDelete(data) {
  return request({
    url: url + '/crm/referralDelete',
    method: 'post',
    data: data
  })
}

// 客户释放列表-
export function getReleaseList(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/releaseList?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 客户释放操作-
export function customerRelease(data) {
  return request({
    url: url + '/crm/customerRelease',
    method: 'post',
    data: data
  })
}

// 渠道列表-
export function getChannelList(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/channelList?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 渠道删除-
export function deleteChannel(data) {
  return request({
    url: url + '/crm/deleteChannel',
    method: 'post',
    data: data
  })
}

// 渠道编辑-
export function saveChannel(data) {
  return request({
    url: url + '/crm/saveChannel',
    method: 'post',
    data: data
  })
}

// -修改客户详情信息-
export function saveCustomerDetail(data) {
  return request({
    url: url + '/crm/customerSubmit',
    method: 'post',
    data: data
  })
}

// -二维码新增修改-
export function userUpCode(data) {
  return request({
    url: url + '/crm/userUpCode',
    method: 'post',
    data: data
  })
}

// 获取个人信息-
export function userInfo() {
  return request({
    url: url + '/crm/userInfo',
    method: 'get',
  })
}

// 二维码列表-
export function userCodeList() {
  return request({
    url: url + '/crm/userCodeList',
    method: 'get',
  })
}

// -二维码新增删除-
export function userDeCode(data) {
  return request({
    url: url + '/crm/userDeCode',
    method: 'post',
    data: data
  })
}

// -测试token-
export function tokenDemo(data) {
  return request({
    url: url + '/crm/userLogin',
    method: 'post',
    data: data
  })
}

// -申报合格资源-
export function declareResources(data) {
  return request({
    url: url + '/crm/qualified',
    method: 'post',
    data: data
  })
}

// 获取菜单-
export function getMobileMenu(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/menu?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 项目权重列表-
export function weightList() {
  return request({
    url: url + '/crm/weightList',
    method: 'get',
  })
}

// 获取用户信息-
export function userData() {
  return request({
    url: url + '/crm/user',
    method: 'get',
  })
}

// -企业微信绑定-
export function bindWx(data) {
  return request({
    url: url + '/crm/qwPhoneBang',
    method: 'post',
    data: data
  })
}

// 带二级 去签约项目
export function signedProject() {
  return request({
    url: url + '/crm/signedProject',
    method: 'get',
  })
}

// -获取客户配偶信息-
export function getCustomerSpouse(user_phone) {
  return request({
    url: url + '/crm/customSubmitSearch?user_phone=' + user_phone,
    method: 'get',
  })
}

// 公司名称下拉-
export function getCompanyList(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/totalCompanyList?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 数据统计列表-
export function getTotalList(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/totalList?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 转换率统计列表-
export function getTransformList(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/conversionRateList?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 入职时间统计列表-
export function getEntryTimeList(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/entryStatisticsList?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 类别统计列表-
export function getCatList(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/cat?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 入职时间统计列表-
export function resourcesData(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/resource?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 签约统计列表-
export function statisticsOrder(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/bill?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 获取顾问月度/季度签单量排行榜-
export function getRankList(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/ranking?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 客户信息统计-
export function getCensusList(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/statistics?' + paraUrl,
    method: 'get',
    data: data
  })
}

// 首页公告-
export function noticeInfo(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    // url: url + '/crm/notice?' + paraUrl, // 已废弃
    url: url + '/crm/notice/details?' + paraUrl,
    method: 'get',
    data: data
  })
}

// -------钉钉免登（2024-01-15）------- //
// 获取token
export function getTokenByDingId(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    // url: url + '/crm/notice?' + paraUrl, // 已废弃
    url: url + '/crm/getTokenByDingId?' + paraUrl,
    method: 'get',
    data: data
  })
}
// ------S 销售日报（2024-07-19）------- //
// 所属分公司
export function branchOfficesJava() {
  return request({
    url: url + '/crm/branchCompany',
    method: 'get',
  })
}
// 顾问统计
export function saleDailyStatistics(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/saleDailyStatistics?' + paraUrl,
    method: 'get',
  })
}
// 类别统计
export function catDailyStatistics(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/catDailyStatistics?' + paraUrl,
    method: 'get',
  })
}
// 来源统计
export function sourceDailyStatistics(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/sourceDailyStatistics?' + paraUrl,
    method: 'get',
  })
}
// 星标统计
export function starDailyStatistics(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/starDailyStatistics?' + paraUrl,
    method: 'get',
  })
}
// 获取日报身份crm/userDailyInfo
export function userDailyInfo(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/userDailyInfo?' + paraUrl,
    method: 'get',
  })
}
// ------E 销售日报（2024-07-19）------- //

// ------业绩确认 -确认页（2024-08-20）------- //
// 获取业绩历史记录列表
export function getPerforHistoricalRecord(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/performanceRecognition/perforHistoricalRecord?'+ paraUrl,
    method: 'get',
  })
}

// 获取业绩确认内容
export function getHistoricalRecordDetails(data) {
  return request({
    url: url + '/crm/performanceRecognition/historicalRecordDetails',
    method: 'POST',
    data:data
  })
}
// 确认
export function getPerforHistoricalAccuracy(data) {
  return request({
    url: url + '/crm/performanceRecognition/perforHistoricalAccuracy',
    method: 'POST',
    data:data
  })
}

// 业绩确认企业微信隐藏左上角转发
export function getJsapiTicket(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/performanceRecognition/getJsapiTicket?'+ paraUrl,
    method: 'get',
  })
}
// ------业绩确认 -确认页（2024-08-20）------- //

// ------销售部签约日报（2024-10-24）------- //
// 当月数据
export function getTodaySignData(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/todaySignData?'+ paraUrl,
    method: 'get',
  })
}
// 当月数据
export function getMonthSignData(data) {
  var paraUrl = dynamicUrl(data)
  return request({
    url: url + '/crm/monthSignData?'+ paraUrl,
    method: 'get',
  })
}
// ------销售部签约日报（2024-10-24）------- //