<template>
    <div>
        <!-- S 列表部分 -->
        <div class="list-wrap" v-for="item in tableData">
            <div class="list-item detail-w">
                <div class="list-top">
                    <div class="list-top-num">
                        <span class="list-label">序号</span>
                        <span>{{item.customer_id}}</span>
                    </div>
                </div>
                <div class="list-info">
                    <div class="info-label">客户名称</div>
                    <div>{{item.customer_name}}</div>
                </div>
                <div class="list-info">
                    <div class="info-label">资源类别</div>
                    <div>{{item.cat_name}}</div>
                </div>
                <div class="list-info">
                    <div class="info-label">录入时间</div>
                    <div v-if="item.create_time!==null">{{formatDate(item.create_time)}}</div>
                    <div v-else>-</div>
                </div>
                <div class="operate-info">
                    <div class="both-sides" @click="getIndex(item.id)">抢单</div>
                    <!--                        <div class="both-sides" @click="toJournal(item.id)">日志</div>-->
                </div>
            </div>
        </div>
        <div class="empty-data" v-if="empty_data">暂无数据</div>
        <!-- E 列表部分 -->
    </div>
</template>

<script>
    import {getsOrdersList, getsOrdersIndex} from "../api/index";
    import {Notify, Dialog} from 'vant';
    import moment from "moment";

    export default {
        name: "robIndex",
        data() {
            return {
                total: '0', // 总条数
                /* ---- 触底加载 ---- */
                loading: false,
                finished: false,
                searchStates: false,  // 不走 onload
                no_more: '没有更多了',
                tableData: [], //列表数据
                empty_data: true, // 暂无数据

                path: process.env.BASE_API_WEBSOCKET,
                demoUrl: 'wss://crm-api-test.immiknow.com/crm/websocketTwo'
            }
        },
        created() {
             this.initWebpack() // 初始化websocket
        },
        // 销毁页面
        destroyed() {
            this.ws.close()
        },
        methods: {
            initWebpack() {
                this.ws = new WebSocket(this.demoUrl) // 请求地址
                this.ws.onopen = this.onopen // 发送请求
                this.ws.onmessage = this.onmessage // 返回数据
                this.ws.onclose = this.onclose // 关闭websoket
                this.ws.onerror = this.onerror // 报错
            },
            onopen() {
                this.getData() // 调用请求表格数据
            },
            // 后台返回数据
            onmessage(e) {
                var mydata = JSON.parse(e.data)
                if (mydata.length === 0) {
                    this.empty_data = true
                    this.tableData = []
                } else {
                    this.empty_data = false
                    this.tableData = mydata
                }
            },
            // 销毁
            onclose() {
                Notify({type: 'danger', message: '服务连接失败'});
            },
            getData() {
                getsOrdersList().then(data => {
                    if (data.code === 200) {
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});

                })
            },
            // 时间戳转化(年-月-日 时:分:秒)
            formatDate(time) {
                return moment(time * 1000).format("YYYY-MM-DD HH:mm:ss")
            },

            getIndex(id) {
                var paraId = id
                Dialog.confirm({
                    title: '提示',
                    message: '是否确认抢单？',
                    confirmButtonColor: "#409EFF",//确认按钮上文字的颜色，默认为#ee0a24
                })
                    .then(() => {
                        let para = {
                            id: paraId,
                        }
                        getsOrdersIndex(para).then(data => {
                            if (data.code === 200) {
                                Notify({type: 'success', message: '您已抢单成功'});
                            } else {
                                Notify({type: 'danger', message: data.message});
                            }
                        }).catch(() => {
                            Notify({type: 'danger', message: '服务连接失败'});
                        })
                    })
                    .catch(() => {

                    });
            },

        }
    }
</script>

<style scoped>
    /* ---- header区域 ---- */
    .header {
        position: sticky;
        top: 0;
        /*height: 106px;*/
        height: 52px;
        background: #fff;
        box-shadow: 0 9px 16px rgba(25, 52, 139, 0.06);
        z-index: 9;
    }


    .header-nav div {
        position: absolute;
        left: 10px;
        width: 19px;
        height: 16px;
    }

    .header-page {
        display: flex;
        justify-content: space-between;
        height: 53px;
        padding: 0 15px;
    }

    .page-info {
        color: #A1A7BA;
        font-size: 12px;
        line-height: 53px;
    }

    .page-num {
        padding: 0 2px;
        color: #222;
    }

    .page-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .page-input {
        width: 43px;
        height: 28px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .page-btn input {
        padding: 0;
        width: 43px;
        height: 28px;
        border: none;
        color: #A1A7BA;
        font-size: 15px;
        text-align: center;
    }

    .jump-btn {
        width: 49px;
        height: 30px;
        margin-left: 7px;
        background: #409EFF;
        border-radius: 4px;
        color: #fff;
        font-size: 15px;
        text-align: center;
        line-height: 30px;
    }

    .page-jump {
        padding: 0;
    }

    .page-jump >>> input {
        height: 30px;
        color: #A1A7BA;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
    }

    /* ---- 筛选部分 ---- */
    .detail-w {
        width: 345px;
        margin: 0 auto;
    }


    .down-icon svg {
        position: relative;
        top: 11px;
    }

    .down-icon i {
        position: relative;
        top: 11px;
        left: -2px;
        color: #C9C7C8;
    }

    .vant-input-edit >>> input::-webkit-input-placeholder {
        color: #606266 !important;
    }


    .contact-icon i {
        top: 1px;
    }

    .contact-icon svg {
        top: 0;
    }


    .time-clear i {
        top: 1px;
    }


    /* ---- 列表数据 ---- */
    .list-wrap {
        margin: 15px 0;
    }

    .list-item {
        padding-bottom: 20px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
    }

    .list-top {
        display: flex;
        justify-content: space-between;
        width: 305px;
        height: 45px;
        margin: 0 auto 16px;
        border-bottom: 1px solid #DDE0E7;
    }

    .list-top-num {
        padding-top: 17px;
        color: #222;
        font-size: 15px;
    }

    .list-label {
        padding-right: 8px;
        color: #A1A7BA;
    }

    .list-info {
        display: flex;
        width: 305px;
        margin: 13px auto 0;
        color: #222;
        font-size: 15px;
        line-height: 20px;
    }

    .info-label {
        flex-shrink: 0;
        width: 60px;
        margin-right: 10px;
        color: #A1A7BA;
    }


    .operate-info {
        display: flex;
        justify-content: space-between;
        width: 305px;
        height: 40px;
        margin: 23px auto 0;
    }

    .both-sides {
        background: #409EFF;
        width: 305px;
        height: 40px;
        border-radius: 4px;
        color: #FFFFFF;
        font-size: 15px;
        text-align: center;
        line-height: 40px;
    }


    /*暂无数据*/
    .empty-data {
        width: 90%;
        height: 55px;
        margin: 15px auto;
        background: #fff;
        color: #A1A7BA;
        font-size: 15px;
        text-align: center;
        line-height: 55px;
        border-radius: 10px;
        box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
    }
</style>
