<template>
    <div class="w">
        <!-- S 个人信息 -->
        <div class="person-top">
            <div class="person-top-blue">
                <div class="blue-line"></div>
                <div class="blue-text">个人信息</div>
            </div>
            <div class="top-item">
                <div class="top-item-label">昵称</div>
                <div class="top-item-text" v-if="name!== ''">{{name}}</div>
                <div class="top-item-text" v-else>-</div>
            </div>
            <div class="top-item">
                <div class="top-item-label">电话</div>
                <div class="top-item-text" v-if="phone!== ''">{{phone}}</div>
                <div class="top-item-text" v-else>-</div>
            </div>
            <div class="top-item">
                <div class="top-item-label">公司</div>
                <div class="top-item-text" v-if="company!== ''">{{companyData[company]}}</div>
                <div class="top-item-text" v-else>-</div>
            </div>
            <div class="top-item">
                <div class="top-item-label">钉钉ID</div>
                <div class="top-item-text" v-if="DDid!== ''">{{DDid}}</div>
                <div class="top-item-text" v-else>-</div>
            </div>
            <div class="top-item">
                <div class="top-item-label">岗位</div>
                <div class="top-item-text" v-if="post!== ''">{{post_type[post]}}</div>
                <div class="top-item-text" v-else>-</div>
            </div>
            <div class="top-item">
                <div class="top-item-label">工作状态</div>
                <div class="top-item-text" v-if="work!== ''">{{wordData[work]}}</div>
                <div class="top-item-text" v-else>-</div>
            </div>
        </div>
        <!-- E 个人信息 -->
        <!-- S 三方绑定 -->
        <div class="person-top">
            <div class="person-top-blue">
                <div class="blue-line"></div>
                <div class="blue-text">三方绑定</div>
            </div>
            <div class="top-item">
                <div class="top-item-label">平台</div>
                <div class="top-item-text">企业微信</div>
            </div>
            <div class="top-item">
                <div class="top-item-label">状态</div>
                <div class="top-item-text">{{wxSates}}</div>
            </div>
            <div class="top-item">
                <div class="top-item-label">数据</div>
                <div class="top-item-text" v-if="Wxid!== ''">{{Wxid}}</div>
                <div class="top-item-text" v-else>-</div>
            </div>
            <div class="wx-binding" @click="binding()">
                <img src="../assets/code-bind.png" alt="" class="search-img">
                绑定
            </div>
        </div>
        <!-- E 三方绑定 -->
        <!-- S 项目权重 -->
        <div class="person-top" v-if="weightStates">
            <div class="code-top" style="margin-bottom: 10px">
                <div class="person-top-blue">
                    <div class="blue-line"></div>
                    <div class="blue-text">项目权重</div>
                </div>
            </div>
            <div class="empty-data" v-if="empty_data">暂无数据</div>
            <div class="code-item" v-for="item in weightData" v-else>
                <div class="top-item">
                    <div class="top-item-label">项目名称</div>
                    <div class="top-item-text">{{item.title}}</div>
                </div>
                <div class="top-item">
                    <div class="top-item-label" style="width: 85px">权重</div>
                    <div class="top-item-text">{{item.num}}</div>
                </div>
                <div class="top-item">
                    <div class="top-item-label" style="width: 85px">接单状态</div>
                    <div class="top-item-text">
                        <span v-if="item.status === true">接单</span>
                        <span v-if="item.status === false">不接单</span>
                     </div>
                </div>
            </div>

        </div>
        <!-- E 项目权重 -->
        <!-- S 二维码信息 -->
        <div class="person-top" >
            <div class="code-top">
                <div class="person-top-blue">
                    <div class="blue-line"></div>
                    <div class="blue-text">二维码信息</div>
                </div>
                <div class="code-btn" @click="addCode()">添加</div>
            </div>
            <div class="empty-data" v-if="code_data">暂无数据</div>
            <div class="code-item" v-for="item in codeData" v-else>
                <div class="top-item">
                    <div class="top-item-label">序号</div>
                    <div class="top-item-text">{{item.id}}</div>
                </div>
                <div class="top-item">
                    <div class="top-item-label">名称</div>
                    <div class="top-item-text">{{item.code_title}}</div>
                </div>
                <div class="code-btn-box">
                    <div class="code-see" @click="seeImg(item)">查看</div>
                    <div class="code-edit" @click="edit(item)">编辑</div>
                    <div class="code-del" @click="dels(item.id)">删除</div>
                </div>
            </div>

        </div>
        <!-- E 二维码信息 -->
    </div>
</template>
<script>
    import {Dialog, Notify, ImagePreview} from "vant";
    import {ref} from "vue";
    import {userInfo, userCodeList, userDeCode,weightList} from "../api/java";
    export default {
        name: "personal",
        data() {
            return {
                name: '',
                phone: '',
                company: '',
                DDid: '',
                post: '',
                work: '',
                Wxid: '',
                wxSates: '',
                post_type: ['管理员', '运营', '顾问', '客服经理', '文案主管', '客服', '文案', '渠道经理', '文案总监', '财务'], // 岗位名称
                companyData: ['沈阳文拓', '深圳远达'],
                wordData: ['', '正常', '休息', '离职中', '离职'],
                codeData: [],
                weightStates:false,
                weightData:[],
                code_data:false,
            }
        },
        created() {
            this.getData()
            this.getCode()
            this.getWeight()
        },
        methods: {
            getWeight(){
                weightList().then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        this.weightData =data.data
                        if (data.data.length === 0) {
                            this.empty_data = true
                        }else{
                            if (this.postId === '2') {
                                this.weightStates =true
                            }
                            this.empty_data = false
                        }
                    } else {
                        this.weightStates = false
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            getData() {
                userInfo().then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        this.name = data.data.user_name
                        this.phone = data.data.user_phone
                        this.post = data.data.post
                        this.Wxid = data.data.wx_id
                        this.DDid =data.data.dd_id
                        this.company = data.data.company
                        this.work = data.data.work_status
                        if (data.data.wx_id == null) {
                            this.wxSates = '未绑定'
                        } else {
                            this.wxSates = '绑定'
                        }
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            getCode() {
                userCodeList().then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        this.codeData = data.data
                        if(data.count === 0){
                            this.code_data = true
                        }else{
                            this.code_data = false
                        }
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            addCode() {
                this.$router.push({
                    path: '/codeEdit',
                })
            },
            goBackPage() {
                this.$router.go(-1)
            },
            edit(row) {
                this.$router.push({
                    path: '/codeEdit',
                    query: {
                        data: encodeURIComponent(JSON.stringify(row)), // vue路由传递对象
                    }
                })
            },
            dels(id) {
                let para = {
                    id: id // 数据id
                }
                Dialog.confirm({
                    message: '确认删除本条数据吗?',
                })
                    .then(() => {
                        userDeCode(para).then(data => {
                            if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                                Notify({type: 'danger', message: data.message});
                                sessionStorage.clear()
                                this.$router.push({
                                    path: '/weChatLogin'
                                })
                                return
                            }
                            if (data.code === 200) {
                                Notify({type: 'success', message: '删除成功'});
                                this.getCode() // 更新数据
                            } else {
                                Notify({type: 'danger', message: data.message});
                            }
                        }).catch(() => {
                            Notify({type: 'danger', message: '服务连接失败'});
                        })
                    })
                    .catch(() => {
                    });
            },
            seeImg(item){
                let seeData=[]
                seeData.push(item.code_path)
                ImagePreview({
                    images: seeData,
                    closeable: true,
                });
            },
            binding(){
                this.$router.push({
                    path: '/WeChatAdd',
                })
            },
        }
    }
</script>

<style scoped>
    .person-top {
        background: #FFFFFF;
        box-shadow: 0 3px 10px 1px rgba(108, 157, 209, 0.16);
        border-radius: 10px;
        padding: 16px;
        margin-top: 15px;
    }

    .person-top-blue {
        display: flex;
        align-items: center;
    }

    .blue-line {
        width: 2px;
        height: 14px;
        background: #409EFF;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
    }

    .blue-text {
        margin-left: 5px;
        font-size: 16px;
        color: #333333;
        line-height: 0px;
    }

    .top-item {
        display: flex;
        margin-top: 10px;
        margin-bottom: 12px;
    }

    .top-item:last-child {
        margin-bottom: 0;
    }

    .top-item-label {
        flex-shrink: 0;
        width: 70px;
        font-size: 15px;
        color: #A1A7BA;
    }

    .top-item-text {
        font-size: 15px;
        color: #222222;
    }

    .wx-binding {
        margin-top: 18px;
        width: 305px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #409EFF;
        border-radius: 4px;
        color: #FFFFFF;
        font-size: 15px;
    }

    .code-item {
        padding-bottom: 25px;
        border-bottom: 1px solid #DDE0E7;
    }

    .code-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .code-btn {
        width: 67px;
        height: 30px;
        line-height: 30px;
        background: #409EFF;
        text-align: center;
        border-radius: 4px;
        color: #FFFFFF;
    }

    .code-btn-box {
        padding-top: 5px;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .code-see {
        text-align: center;
        font-size: 15px;
        color: #409EFF;
        width: 93px;
        height: 38px;
        line-height: 40px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #409EFF;
    }

    .code-edit {
        text-align: center;
        font-size: 15px;
        color: #FFFFFF;
        width: 93px;
        height: 38px;
        line-height: 40px;
        background: #409EFF;
        border-radius: 4px;
        border: 1px solid #409EFF;
    }

    .code-del {
        text-align: center;
        font-size: 15px;
        color: #F56C6C;
        width: 93px;
        height: 38px;
        line-height: 40px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #F56C6C;
    }

    .empty-data{
        width: 100% !important;
    }
    .search-img{
        width: 14px;
        margin-right: 5px;
    }
</style>