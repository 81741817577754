import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import Vant from 'vant';
import 'vant/lib/index.css';
import elementIcon from "../svgicon";
import moment from 'moment'
import {isPublic} from "@/service/pubilcPath";
const app = createApp(App)
app.use(router).use(Vant).use(elementIcon);
app.mount('#app')
app.config.globalProperties.$moment = moment

/* 路由守卫 */
router.beforeEach((to, from, next) => {
  // 动态修改title
  if (to.meta.title) {
    document.title = to.meta.title
  }
      // next()
  //登录守卫
  if (isPublic(to.path)) {
    next()
  } else {
    if ((sessionStorage.getItem('postID')) === null) {
        if (localStorage.getItem('style') === 'ding'){
            next({path: '/dingTalkLogin'})
        }else if(localStorage.getItem('style') === 'weChat'){
            next({path: '/weChatLogin'})
        }
    } else {
      next()
    }
    return
  }
})
