<template>
  <div>
    <!-- S 第一基本信息部分大模块 -->
    <div class="title">基本信息</div>
    <div class="info-box" :class="{info_box_all:basic_more === false}">
      <div class="info-title">
        <span class="user-name">{{info_arr.user_name}}</span>
        <span class="user-sex" v-if="info_arr.user_sex === 0">未知</span>
        <span class="user-sex" v-if="info_arr.user_sex === 1">男</span>
        <span class="user-sex" v-if="info_arr.user_sex === 2">女</span>
        <span v-if="info_arr.birthday">{{info_arr.birthday}}岁</span>
      </div>
      <!-- S 单行 两条数据 -->
      <div class="info-item">
        <div class="content-info">
          <div class="content-title">电话</div>
          <div class="content-detail" v-if="info_arr.user_phone">
            <span>{{info_arr.user_phone}}</span>
            <span v-if="info_arr.contact_information_first === 0" class="priority-box">优先</span>
          </div>
          <div class="content-detail" v-else>-</div>
        </div>

      </div>
      <!-- E 单行 两条数据 -->

      <!-- S 单行 两条数据(附属联系方式) -->
      <div class="info-item" style="padding: 5px 0 0">
        <div class="content-info-concat">
          <div class="content-info-box">
            <div class="content-title">附属电话</div>
            <div class="content-detail" v-if="!concat_arr_phone">-</div>
          </div>
          <div class="content-info-box" v-for="item in concat_arr.dynamicPhone">
            <div class="content-title concat-title-text">{{item.name}}</div>
            <div class="content-detail">{{item.value}}</div>
          </div>
        </div>
      </div>
      <!-- E 单行 两条数据(附属联系方式) -->

      <!-- S 单行 一条数据 -->
      <div class="info-item">
        <div class="content-info content-info-one">
          <div class="content-title">微信</div>
          <div class="content-detail" v-if="info_arr.user_chat">
            <span>{{info_arr.user_chat}}</span>
            <span v-if="info_arr.contact_information_first === 1" class="priority-box">优先</span>
          </div>
          <div class="content-detail" v-else>-</div>
        </div>
      </div>
      <!-- E 单行 一条数据 -->
      <!-- S 单行 一条数据(微信) -->
      <div class="info-item">
        <div class="content-info content-info-one">
          <div class="content-title">附属微信</div>
          <div class="content-detail" v-if="!concat_arr_wx">-</div>
        </div>
      </div>

      <div class="info-item" v-for="item in concat_arr.dynamicWeChat">
        <div class="content-info content-info-one">
          <div class="content-title">{{item.name}}</div>
          <div class="content-detail">{{item.value}}</div>
        </div>
      </div>
      <!-- E 单行 一条数据(微信) -->

      <!-- S 单行 一条数据 -->
      <div class="info-item">
        <div class="content-info content-info-one">
          <div class="content-title">QQ</div>
          <div class="content-detail" v-if="info_arr.user_qq">
            <span>{{info_arr.user_qq}}</span>
            <span v-if="info_arr.contact_information_first === 3" class="priority-box">优先</span>
          </div>
          <div class="content-detail" v-else>-</div>
        </div>
      </div>
      <!-- E 单行 一条数据 -->
      <!-- S 单行 一条数据(微信) -->
      <div class="info-item">
        <div class="content-info content-info-one">
          <div class="content-title">附属QQ</div>
          <div class="content-detail" v-if="!concat_arr_qq">-</div>
        </div>
      </div>

      <div class="info-item" v-for="item in concat_arr.dynamicQQ">
        <div class="content-info content-info-one">
          <div class="content-title">{{item.name}}</div>
          <div class="content-detail">{{item.value}}</div>
        </div>
      </div>
      <!-- E 单行 一条数据(微信) -->

      <!-- S 单行 一条数据 -->
      <div class="info-item">
        <div class="content-info content-info-one">
          <div class="content-title">邮箱</div>
          <div class="content-detail" v-if="info_arr.user_email">
            <span>{{info_arr.user_email}}</span>
            <span v-if="info_arr.contact_information_first === 2" class="priority-box">优先</span>
          </div>
          <div class="content-detail" v-else>-</div>
        </div>
      </div>
      <!-- E 单行 一条数据 -->

      <!-- S 单行 一条数据(邮箱) -->
      <div class="info-item">
        <div class="content-info content-info-one">
          <div class="content-title">附属邮箱</div>
          <div class="content-detail" v-if="!concat_arr_email">-</div>
        </div>
      </div>

      <div class="info-item" v-for="item in concat_arr.dynamicEmail">
        <div class="content-info content-info-one">
          <div class="content-title">{{item.name}}</div>
          <div class="content-detail">{{item.value}}</div>
        </div>
      </div>
      <!-- E 单行 一条数据(邮箱) -->

      <!-- S 单行 两条数据 -->
      <div class="info-item">
        <div class="content-info">
          <div class="content-title">意向国家</div>
          <div class="content-detail">{{info_arr.intentional_state}}</div>
        </div>
      </div>
      <!-- E 单行 两条数据 -->

      <!-- S 单行 两条数据 -->
      <div class="info-item">
        <div class="content-info">
          <div class="content-title">等级</div>
          <div class="content-detail" v-if="info_arr.customer_grade">{{customerLevel(info_arr.customer_grade)}}</div>
          <div class="content-detail" v-else>-</div>
        </div>
      </div>
      <!-- E 单行 两条数据 -->

      <!-- S 单行 一条数据 -->
      <div class="info-item">
        <div class="content-info content-info-one">
          <div class="content-title">所在地</div>
          <div class="content-detail" v-if="info_arr.province_m">{{info_arr.province_m}}</div>
          <div class="content-detail" v-else>-</div>
        </div>
      </div>
      <!-- E 单行 一条数据 -->

      <!-- S 单行 两条数据 -->
      <div class="info-item">
        <div class="content-info">
          <div class="content-title">跟单顾问</div>
          <div class="content-detail" v-if="info_arr.follow_counsel_name">{{info_arr.follow_counsel_name}}</div>
          <div class="content-detail" v-else>-</div>
        </div>
      </div>
      <!-- E 单行 两条数据 -->

      <!-- S 单行 两条数据 -->
      <div class="info-item">
        <div class="content-info">
          <div class="content-title">录入人</div>
          <div class="content-detail" v-if="info_arr.official_service_name">{{info_arr.official_service_name}}</div>
          <div class="content-detail" v-else>-</div>
        </div>
      </div>
      <!-- E 单行 两条数据 -->

      <!-- S 单行 两条数据 -->
      <div class="info-item">
        <div class="content-info">
          <div class="content-title">签约状态</div>
          <div class="content-detail" v-if="info_arr.issign_bill === 1">已签约</div>
          <div class="content-detail" v-if="info_arr.issign_bill === 2">未签约</div>
        </div>
      </div>
      <!-- E 单行 两条数据 -->

      <!-- S 单行 一条数据 -->
      <div class="info-item">
        <div class="content-info content-info-one">
          <div class="content-title">无效原因</div>
          <div class="content-detail" v-if="!info_arr.invalid_reason">-</div>
          <div class="content-detail" v-else>
            {{invalidEcho(info_arr.invalid_reason)}}
          </div>
        </div>
      </div>
      <!-- E 单行 一条数据 -->

      <!-- S 单行 一条数据 -->
      <div class="info-item" v-if="info_arr.invalid_reason === 10">
        <div class="content-info content-info-one">
          <div class="content-title">其他原因</div>
          <div class="content-detail">
            {{info_arr.invalid_reason_content}}
          </div>
        </div>
      </div>
      <!-- E 单行 一条数据 -->

      <!-- S 单行 一条数据(客户咨询备注) -->
      <div class="info-item">
        <div v-if="info_arr.remarks">
          <div class="content-title content-title-lang">客户咨询备注</div>
          <div class="content-detail" style="margin-top: 10px">{{info_arr.remarks}}</div>
        </div>
        <div class="content-info content-info-one" v-if="!info_arr.remarks">
          <div class="content-title content-title-lang">客户咨询备注</div>
          <div class="content-detail">-</div>
        </div>
      </div>
      <!-- E 单行 一条数据(客户咨询备注) -->

      <!-- S 单行 一条数据(婚姻状况) -->
      <div class="info-item">
        <div class="content-info content-info-one">
          <div class="content-title content-title-lang">婚姻状况</div>
          <div class="content-detail">{{marriageStatus(info_arr.marriage)}}</div>
        </div>
      </div>
      <!-- E 单行 一条数据(婚姻状况) -->

      <!-- S 配偶信息部分 -->
      <div v-if="info_arr.marriage === 1">
        <!-- S 单行 两条数据 -->
        <div class="info-item">
          <div class="content-info">
            <div class="content-title">配偶姓名</div>
            <div class="content-detail" v-if="marriage_arr.user_name">{{marriage_arr.user_name}}</div>
            <div class="content-detail" v-else>-</div>
          </div>
        </div>
        <!-- E 单行 两条数据 -->

        <!-- S 单行 两条数据 -->
        <div class="info-item">
          <div class="content-info">
            <div class="content-title">配偶电话</div>
            <div class="content-detail" v-if="marriage_arr.user_phone">{{marriage_arr.user_phone}}</div>
            <div class="content-detail" v-else>-</div>
          </div>
        </div>
        <!-- E 单行 两条数据 -->

        <!-- S 单行 一条数据 -->
        <div class="info-item">
          <div class="content-info content-info-one">
            <div class="content-title">配偶微信</div>
            <div class="content-detail" v-if="marriage_arr.user_chat">{{marriage_arr.user_chat}}</div>
            <div class="content-detail" v-else>-</div>
          </div>
        </div>
        <!-- E 单行 一条数据 -->

        <!-- S 单行 一条数据 -->
        <div class="info-item">
          <div class="content-info content-info-one">
            <div class="content-title">配偶邮箱</div>
            <div class="content-detail" v-if="marriage_arr.user_email">{{marriage_arr.user_email}}</div>
            <div class="content-detail" v-else>-</div>
          </div>
        </div>
        <!-- E 单行 一条数据 -->

        <!-- S 单行 一条数据 -->
        <div class="info-item">
          <div class="content-info content-info-one">
            <div class="content-title">配偶QQ</div>
            <div class="content-detail" v-if="marriage_arr.user_qq">{{marriage_arr.user_qq}}</div>
            <div class="content-detail" v-else>-</div>
          </div>
        </div>
        <!-- E 单行 一条数据 -->

        <!-- S 单行 一条数据 -->
        <div class="info-item">
          <div class="content-info content-info-one">
            <div class="content-title">配偶学历</div>
            <div class="content-detail" v-if="marriage_arr.education === ''">-</div>
            <div class="content-detail" v-else>{{customerEducation(marriage_arr.education)}}</div>
          </div>
        </div>
        <!-- E 单行 一条数据 -->

        <!-- S 单行 两条数据 -->
        <div class="info-item">
          <div class="content-info">
            <div class="content-title">配偶专业</div>
            <div class="content-detail" v-if="marriage_arr.speciality">{{marriage_arr.speciality}}</div>
            <div class="content-detail" v-else>-</div>
          </div>
        </div>
        <!-- E 单行 两条数据 -->

        <!-- S 单行 两条数据 -->
        <div class="info-item">
          <div class="content-info">
            <div class="content-title">配偶职业</div>
            <div class="content-detail" v-if="marriage_arr.work">{{marriage_arr.work}}</div>
            <div class="content-detail" v-else>-</div>
          </div>
        </div>
        <!-- E 单行 两条数据 -->

        <!-- S 单行 两条数据 -->
        <div class="info-item">
          <div class="content-info">
            <div class="content-title">工作年限</div>
            <div class="content-detail" v-if="marriage_arr.work_life">{{marriage_arr.work_life}}</div>
            <div class="content-detail" v-else>-</div>
          </div>
        </div>
        <!-- E 单行 两条数据 -->

        <!-- S 单行 两条数据 -->
        <div class="info-item">
          <div class="content-info">
            <div class="content-title">外语水平</div>
            <div class="content-detail" v-if="marriage_arr.language_ability">{{marriage_arr.language_ability}}</div>
            <div class="content-detail" v-else>-</div>
          </div>
        </div>
        <!-- E 单行 两条数据 -->

        <!-- S 单行 一条数据(护照情况) -->
        <div class="info-item">
          <div class="content-info content-info-one">
            <div class="content-title content-title-lang">其他国家护照情况</div>
            <div class="content-detail" v-if="marriage_arr.third_party_country_status === 1">有</div>
            <div class="content-detail" v-if="marriage_arr.third_party_country_status === 0">没有</div>
          </div>
        </div>
        <!-- E 单行 一条数据(护照情况) -->

        <!-- S 单行 一条数据(护照详情) -->
        <div class="info-item" v-if="marriage_arr.third_party_country_status === 1">
          <div class="content-info content-info-one">
            <div class="content-title content-title-lang">其他国家护照详情</div>
            <div class="content-detail">{{marriage_arr.third_party_country}}</div>
          </div>
        </div>
        <!-- E 单行 一条数据(护照详情) -->

        <!-- S 单行 一条数据(绿卡情况) -->
        <div class="info-item">
          <div class="content-info content-info-one">
            <div class="content-title content-title-lang">其他国家绿卡情况</div>
            <div class="content-detail" v-if="marriage_arr.green_card_status === 1">有</div>
            <div class="content-detail" v-if="marriage_arr.green_card_status === 2">没有</div>
          </div>
        </div>
        <!-- E 单行 一条数据(绿卡情况) -->

        <!-- S 单行 一条数据(绿卡详情) -->
        <div class="info-item" v-if="marriage_arr.green_card_status === 1">
          <div class="content-info content-info-one">
            <div class="content-title content-title-lang">其他国家绿卡详情</div>
            <div class="content-detail">{{marriage_arr.green_card}}</div>
          </div>
        </div>
        <!-- E 单行 一条数据(绿卡详情) -->
      </div>
      <!-- E 配偶信息部分 -->
    </div>
    <div class="more-bottom" @click="basicInfo()">
      <div class="more-bottom-info" v-if="basic_more">
        <span style="padding-right: 5px">更多</span>
        <img src="../assets/detail_down.png" alt="">
      </div>
      <div class="more-bottom-info" v-else>
        <span style="padding-right: 5px">收起</span>
        <img src="../assets/detail_up.png" alt="">
      </div>
    </div>
    <!-- E 第一基本信息部分大模块 -->

    <!-- S 教育背景及工作经历部分大模块 -->
    <div class="title">教育背景及工作经历</div>
    <div class="info-box teach-box">
      <!-- S 单行 一条数据 -->
      <div class="info-item">
        <div class="content-info content-info-one">
          <div class="content-title">毕业院校</div>
          <div class="content-detail" v-if="info_arr.school">{{info_arr.school}}</div>
          <div class="content-detail" v-else>-</div>
        </div>
      </div>
      <!-- E 单行 一条数据 -->

      <!-- S 单行 一条数据 -->
      <div class="info-item">
        <div class="content-info content-info-one">
          <div class="content-title">学历</div>
          <div class="content-detail" v-if="info_arr.education === ''">-</div>
          <div class="content-detail" v-else>{{customerEducation(info_arr.education)}}</div>
        </div>
      </div>
      <!-- E 单行 一条数据 -->

      <!-- S 单行 一条数据 -->
      <div class="info-item">
        <div class="content-info">
          <div class="content-title">专业</div>
          <div class="content-detail" v-if="info_arr.speciality">{{info_arr.speciality}}</div>
          <div class="content-detail" v-else>-</div>
        </div>
      </div>
      <!-- E 单行 一条数据 -->

      <!-- S 单行 两条数据 -->
      <div class="info-item">
        <div class="content-info">
          <div class="content-title">外语水平</div>
          <div class="content-detail" v-if="info_arr.language_ability">{{info_arr.language_ability}}</div>
          <div class="content-detail" v-else>-</div>
        </div>
      </div>
      <!-- E 单行 两条数据 -->

      <!-- S 单行 两条数据 -->
      <div class="info-item">
        <div class="content-info">
          <div class="content-title">职业信息</div>
          <div class="content-detail" v-if="info_arr.work">{{info_arr.work}}</div>
          <div class="content-detail" v-else>-</div>
        </div>
      </div>
      <!-- E 单行 两条数据 -->

      <!-- S 单行 两条数据 -->
      <div class="info-item">
        <div class="content-info">
          <div class="content-title">工作年限</div>
          <div class="content-detail" v-if="info_arr.work_life">{{info_arr.work_life}}</div>
          <div class="content-detail" v-else>-</div>
        </div>
      </div>
      <!-- E 单行 两条数据 -->
      <!-- S 单行 两条数据 -->
      <div class="info-item" style="border: none">
        <div class="content-info">
          <div class="content-title">资金状况</div>
          <div class="content-detail" v-if="info_arr.capital_preparation">{{info_arr.capital_preparation}}</div>
          <div class="content-detail" v-else>-</div>
        </div>
      </div>
      <!-- E 单行 两条数据 -->
    </div>
    <!-- E 教育背景及工作经历部分大模块 -->

    <!-- S 其他部分大模块 -->
    <div class="title">其他</div>
    <div class="info-box other-box" :class="{info_box_all:other_more === false}">
      <!-- S 单行 一条数据(企业主) -->
      <div class="info-item">
        <div class="content-info content-info-one">
          <div class="content-title content-title-lang">是否为企业主</div>
          <div class="content-detail" v-if="info_arr.is_company === 1">是</div>
          <div class="content-detail" v-if="info_arr.is_company === 2">否</div>
        </div>
      </div>
      <!-- E 单行 一条数据(企业主) -->

      <!-- S 单行 一条数据(护照情况) -->
      <div class="info-item">
        <div class="content-info content-info-one">
          <div class="content-title content-title-lang">其他国家护照情况</div>
          <div class="content-detail" v-if="info_arr.third_party_country_status === 1">有</div>
          <div class="content-detail" v-if="info_arr.third_party_country_status === 0">没有</div>
        </div>
      </div>
      <!-- E 单行 一条数据(护照情况) -->

      <!-- S 单行 一条数据(绿卡情况) -->
      <div class="info-item">
        <div class="content-info content-info-one">
          <div class="content-title content-title-lang">其他国家绿卡情况</div>
          <div class="content-detail" v-if="info_arr.green_card_status === 1">有</div>
          <div class="content-detail" v-if="info_arr.green_card_status === 2">没有</div>
        </div>
      </div>
      <!-- E 单行 一条数据(绿卡情况) -->

      <!-- S 单行 一条数据(签约项目信息) -->
      <div class="info-item" v-if="info_arr.issign_bill === 1">
        <div class="content-info content-info-one">
          <div class="content-title content-title-lang">签约项目信息</div>
          <div class="content-detail">{{info_arr.project}}</div>
        </div>
      </div>
      <!-- E 单行 一条数据(签约项目信息) -->

      <!-- S 单行 一条数据(签约时间) -->
      <div class="info-item" v-if="info_arr.issign_bill === 1">
        <div class="content-info content-info-one">
          <div class="content-title content-title-lang">签单时间</div>
          <div class="content-detail">{{formatDateInfo(info_arr.bill_time)}}</div>
        </div>
      </div>
      <!-- E 单行 一条数据(签约时间) -->

      <!-- S 企业主信息 -->
      <div v-if="info_arr.is_company === 1">
        <!-- S 单行 一条数据(企业主-企业规模) -->
        <div class="info-item">
          <div class="content-info content-info-one">
            <div class="content-title content-title-lang">企业规模</div>
            <div class="content-detail">{{info_arr.scale}}</div>
          </div>
        </div>
        <!-- E 单行 一条数据(企业主-企业规模) -->
        <!-- S 单行 一条数据(企业主-主营业务) -->
        <div class="info-item">
          <div class="content-info content-info-one">
            <div class="content-title content-title-lang">主营业务</div>
            <div class="content-detail">{{info_arr.business}}</div>
          </div>
        </div>
        <!-- E 单行 一条数据(企业主-主营业务) -->
        <!-- S 单行 一条数据(企业主-企业成立时间) -->
        <div class="info-item">
          <div class="content-info content-info-one">
            <div class="content-title content-title-lang">企业成立时间</div>
            <div class="content-detail">{{formatDate(info_arr.founding_time)}}</div>
          </div>
        </div>
        <!-- E 单行 一条数据(企业主-企业成立时间) -->
      </div>
      <!-- E 企业主信息 -->

      <!-- S 单行 一条数据(护照详情) -->
      <div class="info-item" v-if="info_arr.third_party_country_status === 1">
        <div class="content-info content-info-one">
          <div class="content-title content-title-lang">其他国家护照详情</div>
          <div class="content-detail">{{info_arr.third_party_country}}</div>
        </div>
      </div>
      <!-- E 单行 一条数据(护照详情) -->

      <!-- S 单行 一条数据(绿卡详情) -->
      <div class="info-item" v-if="info_arr.green_card_status === 1">
        <div class="content-info content-info-one">
          <div class="content-title content-title-lang">其他国家绿卡详情</div>
          <div class="content-detail">{{info_arr.green_card}}</div>
        </div>
      </div>
      <!-- E 单行 一条数据(绿卡详情) -->
    </div>
    <div class="more-bottom" @click="otherInfo()">
      <div class="more-bottom-info" v-if="other_more">
        <span style="padding-right: 5px">更多</span>
        <img src="../assets/detail_down.png" alt="">
      </div>
      <div class="more-bottom-info" v-else>
        <span style="padding-right: 5px">收起</span>
        <img src="../assets/detail_up.png" alt="">
      </div>
    </div>
    <!-- E 其他部分大模块 -->

    <!-- S 备注部分 -->
    <div class="title">备注</div>
    <div v-if="remark_arr.length">
      <div class="remark-wrap" v-bind:style="{height:visa_box_height}" :class="{info_box_all:remark_more === false}">
        <!-- S 展示部分 -->
          <div v-if="remark_more" class="remark-box remark-box-edit">
            <div class="content-detail" style="border-bottom: 1px dashed #CCCFD9; padding-bottom: 13px">{{remark_arr[0].content}}
            </div>
            <div class="info-item">
              <div class="content-info content-info-one">
                <div class="content-title content-title-lang">添加人</div>
                <div class="content-detail">{{remark_arr[0].user_name}}</div>
              </div>
            </div>
            <div class="info-item" style="border: none">
              <div class="content-info content-info-one">
                <div class="content-title content-title-lang">添加时间</div>
                <div class="content-detail">{{formatDate(remark_arr[0].create_time)}}</div>
              </div>
            </div>
          </div>
        <!-- E 展示部分 -->

        <!-- S 循环部分 -->
        <div v-if="!remark_more" class="remark-box remark-box-edit" v-for="item in remark_arr">
          <div class="content-detail" style="border-bottom: 1px dashed #CCCFD9; padding-bottom: 13px">{{item.content}}
          </div>
          <div class="info-item">
            <div class="content-info content-info-one">
              <div class="content-title content-title-lang">添加人</div>
              <div class="content-detail">{{item.user_name}}</div>
            </div>
          </div>
          <div class="info-item" style="border: none">
            <div class="content-info content-info-one">
              <div class="content-title content-title-lang">添加时间</div>
              <div class="content-detail">{{formatDate(item.create_time)}}</div>
            </div>
          </div>
        </div>
        <!-- E 循环部分 -->
      </div>
      <div class="more-bottom" @click="remarkMore()">
        <div class="more-bottom-info" v-if="remark_more">
          <span style="padding-right: 5px">更多</span>
          <img src="../assets/detail_down.png" alt="">
        </div>
        <div class="more-bottom-info" v-else>
          <span style="padding-right: 5px">收起</span>
          <img src="../assets/detail_up.png" alt="">
        </div>
      </div>
    </div>
    <div class="remark-wrap null-data" style="color: #606266;font-size: 15px;" v-else>暂无数据</div>
    <!-- E 备注部分 -->

    <!-- S 签证历史部分 -->
    <div class="title">签证历史</div>
    <div class="visa-height" v-if="visa_history_arr.length">
      <div class="remark-wrap visa-wrap" v-bind:style="{height:visa_box_height}" :class="{info_box_all:visa_more === false}">
        <div>
          <!-- S 展示部分 -->
          <div
              v-if="visa_more"
              class="remark-box remark-box-new remark-box-edit"
              style="padding-top: 5px">
            <div class="info-item">
              <div class="content-info content-info-one">
                <div class="content-title content-title-lang">国家</div>
                <div class="content-detail">{{visa_history_arr[0].country}}</div>
              </div>
            </div>
            <div class="info-item">
              <div class="content-info content-info-one">
                <div class="content-title content-title-lang">签证类型</div>
                <div class="content-detail">{{visa_history_arr[0].type}}</div>
              </div>
            </div>
            <div class="info-item">
              <div>
                <div class="content-title content-title-lang">提交信息</div>
                <div class="content-detail" style="margin-top: 10px">{{visa_history_arr[0].submit_info}}</div>
              </div>
            </div>
            <div class="info-item">
              <div class="content-info content-info-one">
                <div class="content-title content-title-lang">工作单位</div>
                <div class="content-detail" v-if="visa_history_arr[0].work_unit">{{visa_history_arr[0].work_unit}}</div>
                <div class="content-detail" v-else>-</div>
              </div>
            </div>
            <div class="info-item">
              <div v-if="visa_history_arr[0].operating_duty">
                <div class="content-title content-title-lang">工作职责</div>
                <div class="content-detail" style="margin-top: 10px">{{visa_history_arr[0].operating_duty}}</div>
              </div>
              <div class="content-info content-info-one" v-if="!visa_history_arr[0].operating_duty">
                <div class="content-title content-title-lang">工作职责</div>
                <div class="content-detail">-</div>
              </div>
            </div>
            <div class="info-item">
              <div class="content-info content-info-one">
                <div class="content-title content-title-lang">提交时间</div>
                <div class="content-detail">{{formatDate(visa_history_arr[0].time_of_submission)}}</div>
              </div>
            </div>
            <div class="info-item">
              <div class="content-info content-info-one">
                <div class="content-title content-title-lang">有无拒签史</div>
                <div class="content-detail" v-if="visa_history_arr[0].refused === 1">有</div>
                <div class="content-detail" v-if="visa_history_arr[0].refused === 2">无</div>
              </div>
            </div>
            <div class="info-item" v-if="visa_history_arr[0].refused === 1">
              <div>
                <div class="content-title content-title-lang">拒签原因</div>
                <div class="content-detail" style="margin-top: 10px">{{visa_history_arr[0].reason}}</div>
              </div>
            </div>
          </div>
          <!-- E 展示部分 -->

          <!-- S 循环部分 -->
          <div
              v-if="!visa_more"
              class="remark-box remark-box-new remark-box-edit"
              style="padding-top: 5px"
              v-for="item in visa_history_arr">
            <div class="info-item">
              <div class="content-info content-info-one">
                <div class="content-title content-title-lang">国家</div>
                <div class="content-detail">{{visa_history_arr[0].country}}</div>
              </div>
            </div>
            <div class="info-item">
              <div class="content-info content-info-one">
                <div class="content-title content-title-lang">签证类型</div>
                <div class="content-detail">{{item.type}}</div>
              </div>
            </div>
            <div class="info-item">
              <div>
                <div class="content-title content-title-lang">提交信息</div>
                <div class="content-detail" style="margin-top: 10px">{{item.submit_info}}</div>
              </div>
            </div>
            <div class="info-item">
              <div class="content-info content-info-one">
                <div class="content-title content-title-lang">工作单位</div>
                <div class="content-detail" v-if="item.work_unit">{{item.work_unit}}</div>
                <div class="content-detail" v-else>-</div>
              </div>
            </div>
            <div class="info-item">
              <div v-if="item.operating_duty">
                <div class="content-title content-title-lang">工作职责</div>
                <div class="content-detail" style="margin-top: 10px">{{item.operating_duty}}</div>
              </div>
              <div class="content-info content-info-one" v-if="!item.operating_duty">
                <div class="content-title content-title-lang">工作职责</div>
                <div class="content-detail">-</div>
              </div>
            </div>
            <div class="info-item">
              <div class="content-info content-info-one">
                <div class="content-title content-title-lang">提交时间</div>
                <div class="content-detail">{{formatDate(item.time_of_submission)}}</div>
              </div>
            </div>
            <div class="info-item">
              <div class="content-info content-info-one">
                <div class="content-title content-title-lang">有无拒签史</div>
                <div class="content-detail" v-if="item.refused === 1">有</div>
                <div class="content-detail" v-if="item.refused === 2">无</div>
              </div>
            </div>
            <div class="info-item" v-if="item.refused === 1">
              <div>
                <div class="content-title content-title-lang">拒签原因</div>
                <div class="content-detail" style="margin-top: 10px">{{item.reason}}</div>
              </div>
            </div>
          </div>
          <!-- E 循环部分 -->

        </div>
      </div>
      <div class="more-bottom" @click="visakMore()">
        <div class="more-bottom-info" v-if="visa_more">
          <span style="padding-right: 5px">更多</span>
          <img src="../assets/detail_down.png" alt="">
        </div>
        <div class="more-bottom-info" v-else>
          <span style="padding-right: 5px">收起</span>
          <img src="../assets/detail_up.png" alt="">
        </div>
      </div>
    </div>

    <div class="remark-wrap null-data" style="color: #606266;font-size: 15px;" v-else>暂无数据</div>
    <!-- E 签证历史部分 -->

    <div style="height: 20px"></div>
    <!-- S 底部导航栏 -->
    <div class="occupy-box"></div>
    <!-- S 两个底部导航栏 -->
    <div v-if="twoStates">
      <van-tabbar v-model="active">
        <van-tabbar-item replace to="/index">
          <span>首页</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <home-filled/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/customSchedule">
          <span>客户列表</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <PieChart/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
    <!-- S 两个底部导航栏 -->
    <!-- S 三个底部导航栏 -->
    <div v-if="threeStates">
      <van-tabbar v-model="active">
        <van-tabbar-item replace to="/index">
          <span>首页</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <home-filled/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/approve">
          <span>交接审批</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <Coordinate/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/customSchedule">
          <span>客户列表</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <PieChart/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
    <!-- S 三个底部导航栏 -->

    <!-- S 四个底部导航栏 -->
    <div v-if="fourStates">
      <van-tabbar v-model="active">
        <van-tabbar-item replace to="/index">
          <span>首页</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <home-filled/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/customManage">
          <span>客户管理</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <user/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/signedResources">
          <span>已签约</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <edit/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/sharedPool">
          <span>共享池</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <monitor/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
    <!-- S 四个底部导航栏 -->
  </div>
</template>

<script>
  import {getCustomerInfo, getVisaHistory, invalidReasonData} from '../api/index'
  import {Notify} from "vant";
  import moment from "moment";
  import {ref} from "vue";

  export default {
    data() {
      return {
        invalid_arr: [], // 失效原因列表

        customer_id: '', // 客户id
        info_arr: [], // 基本信息数组
        concat_arr: [], // 附属联系方式数组
        concat_arr_phone: '', // 电话数组有无数据
        concat_arr_qq: '', // qq数组有无数据
        concat_arr_wx: '', // 微信数组有无数据
        concat_arr_email: '', // 邮箱数组有无数据
        info_arr_tag: '', // 标签数组是否为空
        marriage_arr: [], // 配偶信息数组
        remark_arr: [], // 备注信息数组
        visa_history_arr: [], // 签证历史备注

        basic_more: true, // 基本信息切换
        other_more: true, // 其他信息切换
        remark_more: true, // 备注切换
        visa_more: true, // 签证历史切换
        visa_box_height: 'auto',

        threeStates: true,//三底部栏
        twoStates: false,//三底部栏
        fourStates: false,//四底部栏
        postId: "" // 权限
      }
    },

    // 生命周期 初始化
    setup() {
      const active = ref(''); // 设定选定第几个
      return {
        active,
      };
    },

    created() {
      window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
      if (this.$route.query.id) {
        this.customer_id = this.$route.query.id // 客户id赋值
        this.invalidList() // 无效原因列表
        this.getCustomInfo() // 获取客户详情
        this.getHistory() // 客户签证历史
      }

      // 星迪修改（底部导航栏）
      this.postId = sessionStorage.getItem("postID")
      console.log(typeof (this.postId))
      if (this.postId === '6' || this.postId === '5') { // 判断 文案 或者 运营经理
        this.threeStates = false // 不显示两个个底部
        this.twoStates = true// 不显示三个底部
        this.active = '1'   //
      }
      if (this.postId === '3' || this.postId === '4') { // 判断 运营主管 或者 文案主管
        this.threeStates = true // 显示两个个底部
        this.twoStates = false// 不显示三个底部
        this.active = '2'
      }
      if (this.postId === '0' || this.postId === '1' || this.postId === '2' || this.postId === '7') { // 判断 管理员
        this.threeStates = false // 显示两个个底部
        this.twoStates = false// 不显示三个底部
        this.fourStates = true// 不显示三个底部
        this.active = null
      }
    },

    methods: {
      // 失效原因列表
      invalidList() {
        invalidReasonData().then(data => {
          if (data.code === 200) {
            this.invalid_arr = data.data
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 失效回显
      invalidEcho(val) {
        for (let i = 0; i < this.invalid_arr.length; i++) {
          if (val === this.invalid_arr[i].id) {
            return this.invalid_arr[i].title
          }
        }
      },

      // 客户详情数据
      getCustomInfo() {
        let para = {
          id: this.customer_id
        }
        getCustomerInfo(para).then(data => {
          if (data.code === 200) {
            this.info_arr = data.data.info // 基本信息
            this.concat_arr = data.data.contact // 附属联系方式
            this.marriage_arr = data.data.marriage // 配偶信息
            this.remark_arr = data.data.remark // 备注信息

            this.concat_arr_phone = this.concat_arr.dynamicPhone.length // 有无附属电话
            this.concat_arr_qq = this.concat_arr.dynamicQQ.length // 有无附属QQ
            this.concat_arr_wx = this.concat_arr.dynamicWeChat.length // 有无附属微信
            this.concat_arr_email = this.concat_arr.dynamicEmail.length // 有无附属微信
            this.info_arr_tag = this.info_arr.customer_tags.length // 客户标签数组有无数据
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 客户签证历史
      getHistory() {
        getVisaHistory(this.customer_id).then(data => {
          if (data.code === 200) {
            this.visa_history_arr = data.data // 签证历史赋值
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 客户等级回显
      customerLevel(val) {
        let level_arr = ['', 'A', 'B', 'C', 'D', 'E', '无效', '未分级']
        return level_arr[val]
      },

      // 客户学历回显
      customerEducation(val) {
        let education_arr = ['未知', '博士', '硕士', '本科', '专科', '高中及以下']
        return education_arr[val]
      },

      // 婚姻状况回显
      marriageStatus(val) {
        let marriage_arr = ['未知', '已婚', '未婚', '离异', ' 丧偶']
        return marriage_arr[val]
      },

      // 时间戳转化(年-月-日)
      formatDate(time) {
        return moment(time * 1000).format("YYYY-MM-DD")
      },

      // 时间戳转化(年-月-日 时:分:秒)
      formatDateInfo(time) {
        return moment(time * 1000).format("YYYY-MM-DD HH:mm:ss")
      },

      // 基本信息切换
      basicInfo() {
        this.basic_more = !this.basic_more
      },

      // 教育背景及工作经历
      otherInfo() {
        this.other_more = !this.other_more
      },

      // 备注更多切换
      remarkMore() {
        this.remark_more = !this.remark_more
      },

      // 签证历史更多切换
      visakMore() {
        this.visa_more = !this.visa_more
      }
    },
  }
</script>

<style scoped>
  .title {
    height: 45px;
    padding-left: 15px;
    color: #A1A7BA;
    font-size: 15px;
    line-height: 45px;
  }

  .info-box {
    background: #fff;
    height: 395px;
    overflow: hidden;
  }

  .teach-box {
    height: auto;
  }

  .other-box {
    height: 235px;
  }

  .info_box_all {
    height: auto !important;
  }

  .info-title {
    padding: 15px;
    color: #606266;
    font-size: 15px;
    border-bottom: 1px dashed #CCCFD9;
  }

  .user-name {
    color: #222;
    font-size: 20px;
  }

  .user-sex {
    padding: 0 10px;
  }

  .info-item {
    display: flex;
    padding: 13px 15px;
    border-bottom: 1px dashed #CCCFD9;
  }

  .remark-box-new .info-item:last-child {
    border: none;
  }

  .content-info {
    display: flex;
    width: 100%;
    line-height: 20px;
  }

  .content-info-concat {
    width: 100%;
    line-height: 20px;
  }

  .content-title {
    flex-shrink: 0;
    width: 60px;
    margin-right: 8px;
    color: #A1A7BA;
    font-size: 15px;
  }

  .content-detail {
    color: #606266;
    font-size: 15px;
    word-break: break-all;
  }

  .content-info-box {
    display: flex;
    flex-wrap: wrap;
    padding: 13px 15px;
    border-bottom: 1px dashed #CCCFD9;
  }

  .content-info-box:last-child {
    border: none;
  }

  .concat-title-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .content-info-one {
    width: 100%;
  }

  .content-title-lang {
    width: auto;
  }

  .remark-wrap {
    height: 165px;
    padding: 20px 15px 0;
    background: #fff;
    overflow: hidden;
  }

  .remark-box {
    padding: 15px 15px 0 15px;
    margin-top: 15px;
    border: 1px solid #DCDFE6;
    border-radius: 10px;
  }

  .remark-box:first-child {
    margin-top: 0;
  }

  .more-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    background: #fff;
  }

  .more-bottom-info {
    color: #409EFF;
    font-size: 15px;
  }

  .more-bottom-info img {
    position: relative;
    top: -2px;
    width: 10px;
  }

  .null-data {
    height: 40px;
  }

  .priority-box {
    display: inline-block;
    position: relative;
    top: -2px;
    width: 27px;
    height: 15px;
    margin-left: 16px;
    background: #F56C6C;
    color: #fff;
    font-size: 12px;
    text-align: center;
    line-height: 15px;
    border-radius: 3px;
  }

  .remark-box-edit .info-item {
    padding: 13px 0;
  }

  /*底部栏*/
  .navIcon {
    height: 21px;
    width: 21px;
  }
</style>
