import axios from 'axios'
import {request} from "../service/request";

let requestAddress = process.env.VUE_APP_BASE_URL // 接口地址

// 获取测试token
export const getToken = params => {
  return axios.get(requestAddress + '/api/login/token', params).then(res => res.data)
}
// 刷新token
export function refreshToken() {
  return request ({
    url: requestAddress + '/index/token/refresh',
    method: 'get',
  })
}

// -------登录部分接口请求------- //
// -手机号 验证码登录-
export function phoneLogin(data) {
  return request ({
    url: requestAddress + '/api/login/login',
    method: 'post',
    data:data
  })
}

// -短信接口-
export function phoneSend(data) {
  return request ({
    url: requestAddress + '/api/login/send',
    method: 'post',
    data:data
  })
}
