<template>
  <div>
    <div class="add-wrap">
      <div class="info-box">
        <div class="info-title">
          <span>客户姓名</span>
          <span class="required-icon">*</span>
        </div>
        <div class="info-input">
          <van-field class="vant-input-edit" @focus="focusIpt"
                     @blur="blurIpt" v-model="name" autocomplete="off" clearable placeholder="请输入客户姓名..."/>
        </div>
      </div>
      <div class="info-box">
        <div class="info-title">
          <span>电话</span>
        </div>
        <div class="info-input">
          <van-field class="vant-input-edit" v-model="phone" autocomplete="off" type="digit" clearable
                     placeholder="四选一" @focus="focusIpt" @blur="blurIpt"/>
        </div>
      </div>
      <div class="info-box">
        <div class="info-title">
          <span>微信</span>
        </div>
        <div class="info-input">
          <van-field class="vant-input-edit" @focus="focusIpt"
                     @blur="blurIpt" v-model="we_chat" autocomplete="off" clearable placeholder="四选一"/>
        </div>
      </div>
      <div class=" info-box">
        <div class="info-title">
          <span>邮箱</span>
        </div>
        <div class="info-input">
          <van-field class="vant-input-edit" @focus="focusIpt"
                     @blur="blurIpt" v-model="email" autocomplete="off" clearable placeholder="四选一"/>
        </div>
      </div>
      <div class="info-box">
        <div class="info-title">
          <span>QQ</span>
        </div>
        <div class="info-input">
          <van-field class="vant-input-edit" v-model="qq" autocomplete="off" type="digit" clearable
                     placeholder="四选一" @focus="focusIpt" @blur="blurIpt"/>
        </div>
      </div>

      <!-- S 优先级单选 -->
      <div class="info-box" style="margin-bottom: 6px;">
        <div class="info-title">
          <span>优先级</span>
          <span class="required-icon">*</span>
        </div>
        <div class="radio-box">
          <van-radio-group v-model="priority" @change="prioritySelect" direction="horizontal">
            <div class="radio-item" :class="{radio_active:index+1 === priority_active}"
                 v-for="(item, index) in priority_data"
                 @click="priorityColor(index+1)">
              <div class="radio-icon">
                <div class="radio-icon-spot"></div>
              </div>
              <van-radio :name="item.id">{{item.title}}</van-radio>
            </div>
          </van-radio-group>
        </div>
      </div>
      <!-- E 优先级单选 -->
      <div class="info-box">
        <div class="info-title">
          <span>意向国家</span>
        </div>
        <div class="info-input">
          <van-field class="vant-input-edit" @focus="focusIpt"
                     @blur="blurIpt" v-model="intention_country" autocomplete="off" clearable
                     placeholder="选填"/>
        </div>
      </div>
      <!-- S 性别单选 -->
      <div class="info-box" style="margin-bottom: 6px;">
        <div class="info-title">
          <span>性别</span>
        </div>
        <div class="radio-box">
          <van-radio-group v-model="radio" @change="sexSelect" direction="horizontal">
            <div class="radio-item" :class="{radio_active:index+1 === sex_active}"
                 v-for="(item, index) in sex_data"
                 @click="switchColor(index+1)">
              <div class="radio-icon">
                <div class="radio-icon-spot"></div>
              </div>
              <van-radio :name="item.id">{{item.title}}</van-radio>
            </div>
          </van-radio-group>
        </div>
      </div>
      <!-- E 性别单选 -->

      <!-- S 客户地址 -->
      <div class="info-box">
        <div class="info-title">
          <span>客户地址</span>
        </div>
        <div class="info-input">
          <van-field
              class="vant-input-edit"
              v-model="address_info"
              readonly
              placeholder="选填"
              @click="address_show = true"
          />
          <div class="down-icon">
            <caret-bottom/>
          </div>
        </div>
      </div>
      <!-- E 客户地址 -->
      <van-notice-bar
          left-icon="volume-o"
          color="#67C23A"
          text="来源渠道选择说明：历史签约客户推荐签约请选择老客户介绍；个人渠道资源请选择自定义"
      />
      <!-- S 来源渠道 -->
      <div class="info-box">
        <div class="info-title">
          <span>来源渠道</span>
          <span class="required-icon">*</span>
        </div>
        <div class="info-input">
          <van-field
              class="vant-input-edit"
              v-model="source_info"
              readonly
              placeholder="必填"
              @click="source_show = true"
          />
          <div class="down-icon">
            <caret-bottom/>
          </div>
        </div>
      </div>
      <!-- E 来源渠道 -->
      <!-- S 渠道 -->
      <div class="info-box" v-if="follow_channel_show">
        <div class="info-title">
          <span>来源渠道</span>
          <span class="required-icon">*</span>
        </div>
        <div class="info-input">
          <van-field
              class="vant-input-edit"
              v-model="source_info"
              readonly
              placeholder="必填"
              @click="source_show = true"
          />
          <div class="down-icon">
            <caret-bottom/>
          </div>
        </div>
      </div>
      <!-- E 渠道 -->

      <van-action-sheet
          description="自定义来源渠道"
          v-model:show="actionsShow" :actions="actions" @select="onSelect"/>
      <div class="info-box" v-if="self_source_show">
        <div class="info-title">
          <span>自定义来源渠道</span>
          <span class="required-icon">*</span>
        </div>
        <div class="info-input">
          <van-field class="vant-input-edit" v-model="self_source" autocomplete="off" clearable
                     placeholder="请输入自定义来源渠道..."/>
        </div>
      </div>

      <div class="info-box" v-if="old_customer_show">
        <div class="info-title">
          <span>老客户序号</span>
          <span class="required-icon">*</span>
        </div>
        <div class="info-input">
          <van-field class="vant-input-edit" v-model="self_source" autocomplete="off" clearable maxlength="10"
                     placeholder="请输入老客户序号"/>
        </div>
      </div>

      <!-- S 资源类别单选 -->
      <div class="info-box" style="margin-bottom: 6px">
        <div class="info-title">
          <span>资源类别</span>
          <span class="required-icon">*</span>
        </div>
        <div class="radio-box">
          <van-radio-group v-model="type" @change="typeSelect" direction="horizontal">
            <div class="radio-item" :class="{radio_active:index+1 === type_active}"
                 v-for="(item, index) in request_type_data"
                 @click="switchColorType(index+1)">
              <div class="radio-icon">
                <div class="radio-icon-spot"></div>
              </div>
              <van-radio :name="item.id">{{item.title}}</van-radio>
            </div>
          </van-radio-group>
        </div>
      </div>
      <!-- E 资源类别单选 -->

      <div class="info-box">
        <div class="info-title">
          <span>咨询备注</span>
        </div>
        <div class="info-input info-textarea">
          <van-field
              class="info-textarea-edit"
              v-model="remarks"
              autocomplete="off"
              rows="1"
              autosize
              @focus="focusIpt"
              @blur="blurIpt"
              type="textarea"
              placeholder="选填"
          />
        </div>
      </div>
      <div class="info-box" v-if="adviser_show">
        <div class="info-title">
          <span>指定顾问</span>
        </div>
        <div class="info-input" @click="adviserPopup">
          <van-field class="vant-input-edit" v-model="screen_follow_adviser" readonly placeholder="请选择"/>
          <div class="down-icon">
            <caret-bottom/>
          </div>
        </div>
      </div>
      <div class="submit-btn1" @click="submit()">提交</div>
    </div>
    <!-- E 输入部分 -->

    <!-- S 客户地址Popup -->
    <van-popup v-model:show="address_show" position="bottom">
      <van-cascader
          v-model="address_value"
          title="请选择客户地址区"
          :options="address_data"
          :field-names="addressNames"
          @close="address_show = false"
          @finish="addressFinish"
      />
    </van-popup>
    <!-- E 客户地址Popup -->

    <!-- S 来源渠道Popup -->
    <van-popup v-model:show="source_show" position="bottom">
      <van-cascader
          v-model="source_value"
          title="请选择来源渠道"
          :options="source_data"
          :field-names="sourceNames"
          @close="source_show = false"
          @finish="sourceFinish"
      />
    </van-popup>
    <!-- E 来源渠道Popup -->

    <!-- S 跟单顾问弹层 -->
    <van-popup v-model:show="follow_adviser_show" position="bottom" :style="{ height: '48%' }">
      <van-picker
          title="请选择跟单顾问"
          show-toolbar
          :columns="follow_adviser_data"
          @confirm="adviserConfirm"
          @cancel="adviserCancel"
      />
    </van-popup>
    <!-- E 跟单顾问弹层 -->
    <!-- S 自定义渠道弹层 -->
    <van-popup v-model:show="follow_channel_show" position="bottom" :style="{ height: '48%' }">
      <van-cascader
          v-model="source_value"
          title="请选择渠道"
          :options="channel_data"
          :field-names="channelNames"
          @close="follow_channel_show = false"
          @finish="channelFinish"
      />

    </van-popup>
    <!-- E 自定义渠道弹层 -->

    <!-- S 信息已存在 -->
    <div class="info-tip-dialog">
      <van-dialog v-model:show="info_existence" :show-cancel-button='false' title="信息已存在!" show-cancel-button>
        <div style="height: 10px"></div>
        <div v-for="item in info_tips" class="tips-wrap">
          <div class="tips-box">
            <span class="tips-title">所属品牌</span>
            <span class="tips-info">{{item.belong_system}}</span>
          </div>
          <div class="tips-box">
            <span class="tips-title">客户序号</span>
            <span class="tips-info">{{item.id}}</span>
          </div>
          <div class="tips-box">
            <span class="tips-title">录入时间</span>
            <span class="tips-info">{{item.create_time}}</span>
          </div>
          <div class="tips-box">
            <span class="tips-title">客户等级</span>
            <span class="tips-info">{{item.customer_grade}}</span>
          </div>
          <div class="tips-box">
            <span class="tips-title">跟单顾问</span>
            <span class="tips-info">{{item.follow_counsel}}</span>
          </div>
          <div class="tips-box">
            <span class="tips-title">运营客服</span>
            <span class="tips-info">{{item.official_service}}</span>
          </div>
          <div class="tips-line"></div>
        </div>
      </van-dialog>
    </div>
    <!-- E 信息已存在 -->
    <!-- S 底部导航栏 -->
    <div class="occupy-box"></div>
    <van-tabbar v-model="active" v-if="bottom_nav_visible">
      <van-tabbar-item replace to="/index">
        <span>首页</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <home-filled/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/customManage">
        <span>客户管理</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <user/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
      <!--            <van-tabbar-item replace to="/signedResources">-->
      <!--                <span>已签约</span>-->
      <!--                <template #icon="props">-->
      <!--                    <div class="navIcon" v-bind:class="{ active: props.active}">-->
      <!--                        <el-icon>-->
      <!--                            <edit/>-->
      <!--                        </el-icon>-->
      <!--                    </div>-->
      <!--                </template>-->
      <!--            </van-tabbar-item>-->
      <!--            <van-tabbar-item replace to="/sharedPool">-->
      <!--                <span>共享池</span>-->
      <!--                <template #icon="props">-->
      <!--                    <div class="navIcon" v-bind:class="{ active: props.active}">-->
      <!--                        <el-icon>-->
      <!--                            <monitor/>-->
      <!--                        </el-icon>-->
      <!--                    </div>-->
      <!--                </template>-->
      <!--            </van-tabbar-item>-->
    </van-tabbar>
    <!-- S 底部导航栏 -->
  </div>
</template>

<script>
  // import {getCustomerAddress, downSource, categoryList, addCustomer, getAdviserList} from '../api'
  import {
      // addCustomer,
      // downSource
  } from '../api'

  import {areaDown, categoryList, getAdviserList, channelList,addCustomer,downSource,userSourceList} from '../api/java'

  import {Notify} from 'vant';
  import {ref} from "vue";

  export default {
    name: "customer_add",
    data() {
      return {
        adviser_show: false, // 顾问下拉显示与隐藏
        name: '', // 客户姓名
        phone: '', // 电话(四选一)
        we_chat: '', // 微信(四选一)
        qq: '', // QQ(四选一)
        email: '', // 邮箱(四选一)
        intention_country: '', // 意向国家
        remarks: '', // 咨询备注

        /* ------ 性别单选 ------ */
        radio: 3, // 性别回显
        sex_id: '0', // 性别id(请求时传递的参数)
        sex_data: [
          {id: '1', title: '男'},
          {id: '2', title: '女'},
          {id: '0', title: '未知'},
        ],
        sex_active: null, // 性别选中样式

        /* ------ 优先级单选 ------ */
        priority: 1, // 优先级回显
        priority_id: '0', // 优先级id(请求时传递的参数)
        priority_data: [
          {id: '0', title: '电话'},
          {id: '1', title: '微信'},
          {id: '2', title: '邮箱'},
          {id: '3', title: 'QQ'}
        ],
        priority_active: null, // 优先级选中样式

        /* ------ 资源类别单选 ------ */
        type: null, // 回显
        type_id: '', // 资源类别id(请求时传递的参数)
        request_type_data: [], // 接口返回的资源类别数据
        type_active: null, // 资源类别选中样式

        /* ---- 跟单顾问部分 ---- */
        follow_adviser_show: false, // 跟单顾问弹出层
        follow_adviser_id: '', // 跟单顾问id
        screen_follow_adviser: '', // 跟单顾问
        follow_adviser_data: [],
        request_adviser_data: [], // 接口返回顾问数据

        /* ------ 客户地址级联选择部分 ------ */
        address_show: false,
        address_request: '', // 地区(作为参数请求时传递)
        address_info: '', // 地区回显
        address_value: '', // 地区id
        // 地区自定义字段
        addressNames: {
          text: 'name',
          value: 'id',
          children: 'children'
        },
        address_data: [], // 地区数据

        /* ------ 来源渠道地级联选择部分 ------ */
        source_show: false,
        source: '', // 来源渠道(作为参数请求时传递)
        source2: '', // 来源渠道2(作为参数请求时传递)
        self_source: '', // 自定义来源渠道
        self_source_show: false, // 自定义来源渠道显示
        source_info: '', // 来源渠道回显
        source_value: '', // 来源渠道id
        // 地区自定义字段
        sourceNames: {
          text: 'title',
          value: 'id',
          children: 'children'
        },
        source_data: [], // 来源渠道数据
        channel_data: [], // 渠道数据
        channelNames: {
          text: 'company_name',
          value: 'id',
          children: 'children'
        },
        info_existence: false, // 信息已存在
        info_tips: '', // 提示信息

        bottom_nav_visible: true, // 底部导航显示与隐藏
        iosStates: false, // 手机系统

        /* ---- 监听软键盘是否弹出 ---- */
        defaultHeight: '0',  //默认屏幕高度
        nowHeight: '0',  //实时屏幕高度

        actionsShow: false,
        actions: [{name: '选择渠道', id: 0}, {name: '自定义来源渠道', id: 2}],

        /* ---- 跟单顾问部分 ---- */
        follow_channel_show: false, // 跟单顾问弹出层
        follow_channel_id: '', // 跟单顾问id
        screen_follow_channel: '', // 跟单顾问
        follow_channel_data: [],
        request_channel_data: [], // 接口返回顾问数据

        old_customer_show: false, // 老客户序号显示
      }
    },

    // 生命周期 初始化
    setup() {
      const active = ref(''); // 设定选定第几个
      return {
        active,
      };
    },

    created() {
      window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
      if (sessionStorage.getItem('postID') === '1') {
        this.adviser_show = true
      }
      this.getAddress() // 获取地区
      this.getSourceData() // 来源渠道吗数据
      this.resourcesType() // 资源资源类别数据
      this.getAdviserData() // 顾问数据列表
      this.initialRadio() // 单选按钮初始化
      this.getChannelData()

      this.iosStates = sessionStorage.getItem("iosStates")
    },
    // 获取屏幕默认高度与实时高度
    mounted: function () {
      //获取默认高度
      this.defaultHeight = document.documentElement.clientHeight
      window.onresize = () => {
        return (() => {
          //实时屏幕高度
          this.nowHeight = document.documentElement.clientHeight
        })();
      };
    },
    // 监听软键盘是否弹出
    watch: {
      nowHeight: function () {
        if (this.defaultHeight !== this.nowHeight) {
          this.bottom_nav_visible = false
        } else {
          this.bottom_nav_visible = true
        }
      }
    },
    methods: {
      // 客户地址数据
      getAddress() {
        areaDown().then(data => {
          if (data.code === 200) {
            this.address_data = data.data
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 来源渠道数据
      getSourceData() {
          userSourceList().then(data => {
          if (data.code === 200) {
            this.source_data = data.data
            for (let i = 0; i < this.source_data.length; i++) {
              if (this.source_data[i].id === 46) {
                delete this.source_data[i].children
              }
            }
          } else {
            Notify({type: 'danger', message: data.message});
          }
            }).catch(() => {
                Notify({type: 'danger', message: '服务连接失败'});
        })
            // downSource().then(data => {
            //     if (data.code === 200) {
            //         this.source_data = data.data
            //         for (let i = 0; i < this.source_data.length; i++) {
            //             if (this.source_data[i].id === 46) {
            //                 delete this.source_data[i].children
            //             }
            //         }
            //     } else {
            //         Notify({type: 'danger', message: data.message});
            //     }
            // })
      },

      // 渠道数据
      getChannelData() {
        let para = {
          company_name: '',
          contact: ''
        }
        channelList(para).then(data => {
          if (data.code === 200) {
            data.data.data.forEach(function (element) {
              if (element.children.length === 0) {
                delete element.children
              }
            });
            this.channel_data = data.data.data
          } else {
            Notify({type: 'danger', message: data.message});
          }
        })
      },

      // 资源资源类别数据
      resourcesType() {
        categoryList().then(data => {
          if (data.code === 200) {
            this.request_type_data = data.data
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 顾问列表数据
      getAdviserData() {
        getAdviserList().then(data => {
          if (data.code === 200) {
            this.request_adviser_data = data.data
            for (let i = 0; i < this.request_adviser_data.length; i++) {
              this.follow_adviser_data.push(this.request_adviser_data[i].user_name)
            }
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 返回上一页
      goBackPage() {
        this.$router.go(-1)
      },

      // 初始化单选样式
      initialRadio() {
        this.sex_active = this.radio // 性别初始化
        this.type_active = this.type // 资源类别初始化
        this.priority_active = this.priority // 优先级初始化
      },

      /**
       * 性别选择部分
       * */
      // 性别单选监听
      sexSelect(val) {
        this.sex_id = val
      },

      // 单选点击变色
      switchColor(index) {
        this.sex_active = index
      },

      /**
       * 优先级选择部分
       * */
      // 性别单选监听
      prioritySelect(val) {
        this.priority_id = val
      },

      // 单选点击变色
      priorityColor(index) {
        this.priority_active = index
      },


      /**
       * 资源类别选择部分
       * */
      // 资源类别单选监听
      typeSelect(val) {
        this.type_id = val
      },

      // 单选点击变色
      switchColorType(index) {
        this.type_active = index
      },


      /**
       * 跟单顾问(搜索部分)
       * */
      // 跟单顾问弹层控制
      adviserPopup() {
        this.follow_adviser_show = true
      },

      // 确认赋值
      adviserConfirm(value, index) {
        this.screen_follow_adviser = value // 顾问赋值
        // 获取下拉数据id (跟单顾问)
        for (let i = 0; i < this.request_adviser_data.length; i++) {
          if (value === this.request_adviser_data[i].user_name) {
            this.follow_adviser_id = this.request_adviser_data[i].id
          }
        }
        this.follow_adviser_show = false // 关闭弹层
      },

      // 取消选择
      adviserCancel() {
        this.follow_adviser_show = false // 关闭弹层
      },

      /**
       * 级联选择器部分
       * */
      // 客户地址选择完毕 finish 事件
      addressFinish({selectedOptions}) {
        this.address_show = false;
        this.address_info = selectedOptions.map((option) => option.name).join('/');
        let address_arr = []
        for (let i = 0; i < selectedOptions.length; i++) {
          address_arr.push(selectedOptions[i].id)
        }
        this.address_request = address_arr.toString() // 客户地址赋值(请求数据时候用)
      },

      // 来源渠道选择完毕 finish 事件
      sourceFinish({selectedOptions}) {
        this.source_show = false;
        this.source_info = selectedOptions.map((option) => option.title).join('/');
        if (this.source_info === '自定义来源') {
          this.self_source_show = true // 展示自定义来源渠道
          this.old_customer_show = false // 隐藏老客户序号
          this.actionsShow = true
          this.self_source = ''
        } else {
          this.self_source_show = false // 隐藏自定义来源渠道
          this.old_customer_show = false // 隐藏老客户序号
          this.self_source = '' // 清空自定义来源渠道
        }

        if (this.source_info === '老客户介绍') {
          this.old_customer_show = true // 显示老客户序号
          this.self_source_show = false // 隐藏自定义来源渠道
        } else {
          this.old_customer_show = false // 隐藏老客户序号
          this.self_source_show = false // 隐藏自定义来源渠道
          this.self_source = '' // 清空自定义来源渠道
        }

        let source_arr = []
        for (let i = 0; i < selectedOptions.length; i++) {
          source_arr.push(selectedOptions[i].id)
        }
        this.source = source_arr[0] // 来源渠道赋值(请求数据时候用)


        if (source_arr[1] !== undefined) {
          this.source2 = source_arr[1]
        } else {
          this.source2 = ''
        }

        // 来源渠道赋值(请求数据时候用)
      },

      // 自定义
      channelFinish({selectedOptions}) {
        this.follow_channel_show = false
        this.self_source_show = true // 隐藏自定义来源渠道
        this.self_source = selectedOptions.map((option) => option.company_name).join('-')
      },

      // 提交
      submit() {
        let para = {
          user_name: this.name, // 客户昵称
          user_phone: this.phone, // 电话
          user_chat: this.we_chat, // 微信
          user_email: this.email, // 邮箱
          user_qq: this.qq, // QQ
          user_sex: this.sex_id, // 性别
          intentional_state: this.intention_country, // 意向国家
          belonging: this.address_request, // 客户地址
          source: this.source, // 来源渠道1
          source2: this.source2, // 来源渠道2
          source_text: this.self_source, // 自定义来源渠道
          remarks: this.remarks, // 备注
          cat: this.type_id, // 资源类别
          follow_counsel: this.follow_adviser_id, // 跟单顾问
          contact_information_first: this.priority_id // 优先级id
        }

        if (para.follow_counsel === '') {

          para.follow_counsel = sessionStorage.getItem('userId')
        }

        //
        if (!para.user_name) {
          Notify({type: 'danger', message: '客户姓名不能为空'});
          return
        }

        if (!para.user_phone && !para.user_chat && !para.user_email && !para.user_qq) {
          Notify({type: 'danger', message: '电话、微信、邮箱、QQ四者必须填写一项!'})
          return false
        }

        if (para.user_phone) {
          let judge_phone = /^1[3456789]\d{9}$/; // 电话正则判断
          if (!judge_phone.test(para.user_phone)) {
            Notify({type: 'danger', message: '手机号不符合规则'})
            return false
          }
        }

        if (para.user_email) {
          let judge_email = /^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/; // 邮箱正则判断
          if (!judge_email.test(para.user_email)) {
            Notify({type: 'danger', message: '邮箱不符合规则'})
            return false
          }
        }

        if (para.user_qq) {
          let judge_QQ = /^[1-9]\d{4,9}$/; // QQ正则判断
          if (!judge_QQ.test(para.user_qq)) {
            Notify({type: 'danger', message: 'QQ号不符合规则'})
            return false
          }
        }

        if (para.contact_information_first === '') {
          Notify({type: 'danger', message: '请选择优先级'});
          return
        }

        if (!para.source) {
          Notify({type: 'danger', message: '请选择来源渠道'})
          return false
        }

        if (para.source === 46) {
          if (!para.source_text) {
            Notify({type: 'danger', message: '请填写自定义来源渠道'})
            return false
          }
        }

        if (para.source === 77) {
          if (!para.source_text) {
            Notify({type: 'danger', message: '请填写老客户序号'})
            return false
          }
        }

        if (para.source === 77) {
          let judge_customer_num = /^[0-9]*$/; // 数字正则
          if (!judge_customer_num.test(para.source_text)) {
            Notify({type: 'danger', message: '老客户序号只能输入数字'})
            return false
          }
        }

        if (!para.cat) {
          Notify({type: 'danger', message: '请选择资源类别'})
          return false
        }

        addCustomer(para).then(data => {
          let that = this
          if (data.code === 200) {
            Notify({
              type: 'success',
              message: '添加成功',
              duration: 750
            })
            setTimeout(function () {
              that.$router.go(-1)
            }, 800)
            return
          }

          if (data.code === 3001) {
            this.info_existence = true
            this.info_tips = data.data.content
            return;
          }

          if (data.code !== 200) {
            Notify({type: 'danger', message: data.message})
            return;
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },
      // 苹果键盘事件
      blurIpt() {
        if (this.iosStates) {
          this.bottom_nav_visible = true
          return
        }
      },
      // 苹果键盘事件
      focusIpt() {
        if (this.iosStates) {
          this.bottom_nav_visible = false
          return
        }
      },

      onSelect(item) {
        console.log(item)
        this.actionsShow = false
        if (item.id == 0) {
          this.follow_channel_show = true

        } else {
          this.self_source = ''
          this.self_source_show = true // 展示自定义来源渠道
        }
        // 默认情况下点击选项时不会自动收起
        // 可以通过 close-on-click-action 属性开启自动收起
        // this.show = false;
        // Toast(item.name);
      },
    }
  }
</script>

<style scoped>
  /* ---- 输入部分 ---- */
  .add-wrap {
    width: 100%;
    margin-top: 12px;
    padding: 16px 0;
    background: #fff;
  }

  .info-box {
    width: 345px;
    margin: 0 auto 16px;
  }

  .info-title {
    color: #A1A7BA;
    font-size: 15px;
  }

  .info-input {
    position: relative;
    width: 343px;
    height: 43px;
    margin-top: 12px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
  }

  .required-icon {
    padding-left: 3px;
    color: #FF0000;
  }

  .vant-input-edit {
    height: 43px !important;
    line-height: 43px !important;
    padding: 0 15px !important;
    color: #222 !important;
    font-size: 15px !important;
  }

  .vant-input-edit >>> input::-webkit-input-placeholder {
    color: #606266 !important;
  }

  .radio-box {
    display: flex;
    margin-top: 12px;
    color: #606266;
    font-size: 15px;
  }

  .radio-item {
    position: relative;
    margin: 0 10px 10px 0;
    border-radius: 4px;
    border: 1px solid #C9CDD6;
    color: #606266;
    font-size: 15px;
  }

  .radio_active {
    border-color: #409EFF;
  }

  .radio-item:last-child {
    margin-right: 0;
  }

  .van-radio--horizontal {
    margin-right: 0;
    padding: 11px;
  }

  .radio-item >>> .van-radio__icon--round .van-icon {
    display: none;
  }

  .radio-item >>> .van-radio__label {
    position: relative;
    margin-left: 0;
    padding-left: 20px;
    z-index: 9;
  }

  .radio-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 14px;
    left: 12px;
    width: 10px;
    height: 10px;
    border: 2px solid #ABAEB8;
    border-radius: 50%;
  }

  .radio-icon-spot {
    width: 6px;
    height: 6px;
    background: #ABAEB8;
    border-radius: 50%;
  }

  .radio_active .radio-icon {
    border-color: #409EFF;
  }

  .radio_active .radio-icon-spot {
    background: #409EFF;
  }

  .submit-btn1 {
    width: 345px;
    height: 50px;
    margin: 25px auto 35px;
    background: #409EFF;
    color: #fff;
    font-size: 17px;
    text-align: center;
    line-height: 50px;
    border-radius: 50px;
  }

  .info-textarea {
    height: 110px;
  }

  .info-textarea-edit {
    color: #222 !important;
    font-size: 15px !important;
  }

  .info-textarea-edit >>> textarea::-webkit-input-placeholder {
    color: #606266 !important;
  }

  .info-textarea-edit >>> textarea {
    min-height: 88px;
  }

  .down-icon {
    position: absolute;
    top: 13px;
    right: 15px;
    width: 12px;
    color: #606266;
  }

  .info-tip-dialog >>> .van-dialog__header {
    padding-top: 15px;
  }

  .info-tip-dialog >>> .van-dialog__content div {
    padding: 0 20px;
    font-size: 15px;
    color: #222;
  }


  /*底部栏*/
  .navIcon {
    height: 21px;
    width: 21px;
  }

  /*信息重复部分样式*/
  .tips-box {
    margin-bottom: 8px;
  }

  .tips-title {
    color: #A1A7BA;
    font-size: 15px;
    padding-right: 10px;
  }

  .tips-info {
    color: #222;
    font-size: 15px;
  }

  .tips-wrap:last-child .tips-line {
    background: unset;
  }

  .tips-line {
    margin: 0 auto 5px;
    width: 80%;
    height: 1px;
    background: #DDE0E7;
  }
</style>
