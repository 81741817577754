<template>
    <div style="height: 100%">
        <!-- S 头部header -->
        <div class="header">
            <div class="header-page">
                <div class="page-info">
                    <span>共</span>
                    <span class="page-num">{{total}}</span>
                    <span>条/</span>
                    <span class="page-num">{{sum_page}}</span>
                    <span>页，</span>
                    <span>当前第</span>
                    <span class="page-num">{{current_page}}</span>
                    <span>页</span>
                </div>
                <div class="page-btn">
                    <div class="page-input">
                        <van-field class="page-jump" v-model="page_num" @keyup.enter.native="jumpPage()"
                                   autocomplete="off"
                                   type="digit"/>
                    </div>
                    <div class="jump-btn" @click="jumpPage()">跳转</div>
                </div>
            </div>
        </div>
        <!-- E 头部header -->
        <div class="content-wrap">
            <!-- S 筛选条件部分 -->
            <div class="screen detail-w" :style="{height: screen_height}">
                <div class="search-term">
                    <div class="search-term-label">客户序号</div>
                    <div class="search-term-detail">
                        <van-field class="vant-input-edit" type="digit" v-model="screen_id" clearable
                                   autocomplete="off"
                                   @focus="focusIpt"
                                   @blur="blurIpt" placeholder="请输入客户序号..."/>
                    </div>
                </div>
                <div class="search-term">
                    <div class="search-term-label">跟单顾问</div>
                    <div class="search-term-detail" @click="adviserPopup">
                        <van-field class="vant-input-edit" v-model="screen_follow_adviser" readonly
                                   placeholder="请选择"/>
                        <div class="down-icon" v-if="clear_adviser_show">
                            <caret-bottom/>
                        </div>
                        <div class="down-icon" @click.stop="clearableAdviser" v-else>
                            <van-icon name="clear"/>
                        </div>
                    </div>
                </div>
                <!-- S 筛选展开部分 -->
                <div v-if="screen_more">
                    <div class="search-term">
                        <div class="search-term-label">客户名称</div>
                        <div class="search-term-detail">
                            <van-field class="vant-input-edit" v-model="screen_name" clearable autocomplete="off"
                                       @focus="focusIpt"
                                       @blur="blurIpt" placeholder="请输入客户名称..."/>
                        </div>
                    </div>
                    <div class="search-term">
                        <div class="search-term-label">资源等级</div>
                        <div class="search-term-detail" @click="levelPopup">
                            <van-field class="vant-input-edit" v-model="screen_level" readonly placeholder="请选择"/>
                            <div class="down-icon" v-if="clear_level_show">
                                <caret-bottom/>
                            </div>
                            <div class="down-icon" @click.stop="clearableLevel" v-else>
                                <van-icon name="clear"/>
                            </div>
                        </div>
                    </div>
                    <div class="search-term">
                        <div class="search-term-label">联系方式</div>
                        <div class="search-term-detail">
                            <van-field class="vant-input-edit" type="digit" v-model="screen_contact" clearable
                                       autocomplete="off"
                                       @focus="focusIpt"
                                       @blur="blurIpt" placeholder="请输入联系方式..."/>
                        </div>
                    </div>
                    <div class="search-term">
                        <div class="search-term-label">资源类别</div>
                        <div class="search-term-detail" @click="catPopup">
                            <van-field class="vant-input-edit" v-model="cat_info" readonly placeholder="请选择"/>
                            <div class="down-icon" v-if="clear_cat_show">
                                <caret-bottom/>
                            </div>
                            <div class="down-icon" @click.stop="clearableCat" v-else>
                                <van-icon name="clear"/>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- E 筛选展开部分 -->
                <div class="screen-operation">
                    <div class="search-btn" @click="search()">
                        <img src="../assets/search.png" alt="">
                        搜索
                    </div>
                    <div class="more-search" @click="moreSearch()">
                        <img src="../assets/open_search.png" alt="">
                        {{screen_text}}
                    </div>
                </div>
            </div>
            <!-- E 筛选条件部分 -->
            <div class="all-change w" v-if="!searchShow">
                <div class="all-change-box">
                    <div class="all-change-item" @click="allCheck(true)" v-if="this.allCheckStates === true">
                        <img class="all-change-checked" src="../assets/all-check.png" alt=""><span>全选</span>
                    </div>
                    <div class="all-change-item" @click="allCheck(false)" v-else>
                        <img class="all-change-checked" src="../assets/no-check.png" alt=""><span>全选</span>
                    </div>
                </div>
            </div>
            <div class="content-data-box" v-if="this.dataShow">
                <van-list
                        v-model:loading="loading"
                        :finished="finished"
                        :finished-text="no_more"
                        :offset="0"
                        @load="onLoad"
                >
                    <div class="data-index" v-for="(item, i) in dataList" :class="{'active':item.isShow}"
                         :data-id="item.id">
                        <div class="index-top">
                            <div class="index-title" @click="dataBtn(item)">
                                <img class="blue-icon" src="../assets/no-check.png" alt="" v-if="item.isShow ===false">
                                <img class="blue-icon" src="../assets/all-check.png" alt="" v-else>
                                <span class="name-num">序号</span>
                                <span class="name-title">{{item.id}}</span>
                            </div>
                            <div class="index-title">
                                <span class="name-title">{{formatDate(item.create_time)}}</span>
                            </div>
                        </div>
                        <div class="index-three">
                            <span class="name-num">客户名称</span>
                            <span class="name-title">{{item.user_name}}</span>
                        </div>
                        <div class="index-three">
                                <span class="name-num">意向国家</span>
                                <span class="name-title" v-if="item.intentional_state !== ''">{{item.intentional_state}}</span>
                                <div v-else>-</div>
                        </div>
                        <div class="index-three">
                            <div class="index-title">
                                <span class="name-num">资源等级</span>
                                <span class="name-title" v-if="item.intentional_state !== null">{{customerGrade(item.customer_grade)}}</span>
                                <div v-else>-</div>
                            </div>
                        </div>
                        <div class="index-three" v-if="adviserStates">
                            <span class="name-num">来源渠道</span>
                            <span class="name-title" v-if="item.source_name !== ''">{{item.source_name}}</span>
                            <div v-else>-</div>
                        </div>
                        <div class="operate-info">
                            <div class="search-btn" @click="toDetail(item.id)">详情</div>
                            <div class="more-search" @click="toFollowOrder(item.id)">跟单</div>
                        </div>
                    </div>
                </van-list>
                <div style="height: 154px;"></div>
                <div style="position: fixed;bottom: 62px;background: #FFFFFF;z-index: 99">
                    <!-- S 选择顾问 -->
                    <div class="info-box" v-show="this.dataShow">
                        <div class="info-title" style="margin-top: 11px">
                            <span>待转顾问</span>
                        </div>
                        <div class="info-input" @click="todoPopup">
                            <van-field class="vant-input-edit" v-model="screen_follow_todo" readonly placeholder="请选择"/>

                            <div class="down-icon" v-if="clear_todo_show">
                                <caret-bottom/>
                            </div>
                            <div class="down-icon" @click.stop="clearableTodo" v-else>
                                <van-icon name="clear"/>
                            </div>
                        </div>
                    </div>
                    <!-- S 选择顾问 -->
                    <div class="submit-btn1" @click="submit()" v-show="this.dataShow">提交</div>
                </div>
                </div>
            <div class="empty-data" v-if="searchShow">暂无数据</div>
        </div>
    </div>
    <!-- S 跟单顾问弹层 -->
    <van-popup v-model:show="follow_adviser_show" position="bottom" :style="{ height: '48%' }">
        <van-picker
                title="请选择跟单顾问"
                show-toolbar
                :columns="follow_adviser_data"
                @confirm="adviserConfirm"
                @cancel="adviserCancel"
        />
    </van-popup>
    <!-- E 跟单顾问弹层 -->
    <!-- S 客户资源等级弹层 -->
    <van-popup v-model:show="level_show" position="bottom" :style="{ height: '48%' }">
        <van-picker
                title="请选择客户资源等级"
                show-toolbar
                :columns="level_data"
                @confirm="levelConfirm"
                @cancel="levelCancel"
        />
    </van-popup>
    <!-- E 客户资源等级弹层 -->
    <!-- S 资源类别弹层 -->
    <van-popup v-model:show="cat_type_show" position="bottom" :style="{ height: '48%' }">
        <van-picker
                title="请选择资源类别"
                show-toolbar
                :columns="cat_type_data"
                @confirm="catConfirm"
                @cancel="catCancel"
        />
    </van-popup>
    <!-- E 资源类别弹层 -->
    <!-- S 待转顾问弹层 -->
    <van-popup v-model:show="follow_todo_show" position="bottom" :style="{ height: '48%' }">
        <van-picker
                title="请选择跟单顾问"
                show-toolbar
                :columns="follow_todo_data"
                @confirm="todoConfirm"
                @cancel="todoCancel"
        />
    </van-popup>
    <!-- E 待转顾问弹层 -->
    <!-- S 底部导航栏 -->
    <div class="occupy-box"></div>
    <van-tabbar v-model="active" v-if="bottom_nav_visible">
        <van-tabbar-item replace to="/index">
            <span>首页</span>
            <template #icon="props">
                <div class="navIcon" v-bind:class="{ active: props.active}">
                    <el-icon>
                        <home-filled/>
                    </el-icon>
                </div>
            </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/customManage">
            <span>客户管理</span>
            <template #icon="props">
                <div class="navIcon" v-bind:class="{ active: props.active}">
                    <el-icon>
                        <user/>
                    </el-icon>
                </div>
            </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/signedResources">
            <span>已签约</span>
            <template #icon="props">
                <div class="navIcon" v-bind:class="{ active: props.active}">
                    <el-icon>
                        <edit/>
                    </el-icon>
                </div>
            </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/sharedPool">
            <span>共享池</span>
            <template #icon="props">
                <div class="navIcon" v-bind:class="{ active: props.active}">
                    <el-icon>
                        <monitor/>
                    </el-icon>
                </div>
            </template>
        </van-tabbar-item>
    </van-tabbar>
    <!-- S 底部导航栏 -->
</template>
<script>
    import {categoryList, getAdviserList, getTransferList, transferOrder} from '../api/java'
    import {Notify} from "vant";
    import moment from "moment";
    import {ref} from "vue";
    import progressAdd from "./progressAdd";

    export default {
        name: "demandTransfer",
        data() {
            return {

                /* ---- 跟单顾问部分 ---- */
                saleData: [],// 请求回来的跟单顾问数据
                user_id: '', // 跟单顾问id
                finished: false,
                searchStates: false,  // 不走 onload
                isShow: false, // 客户选中状态
                dataList: [], // 列表数据
                customerId: [], // 客户ID
                original_adviser_id: '', // 原顾问id
                /* ---- 分页 ---- */
                page_num: '0', // 去第几页
                current_page: '1', // 当前第几页
                total: '0', // 总条数
                sum_page: '0', // 总页数

                screen_contact: '',// 联系方式
                screen_id: '',// 用户ID
                screen_name: '', // 客户名称
                /* ---- 跟单顾问部分 ---- */
                clear_adviser_show: true,
                follow_adviser_show: false, // 跟单顾问弹出层
                screen_follow_adviser: '', // 跟单顾问
                follow_adviser_id: '', // 跟单顾问id
                follow_adviser_data: [], // vant下拉数据格式(顾问列表)
                request_adviser_data: [], // 接口返回数据格式(顾问列表)


                /* ---- 待转顾问部分 ---- */
                clear_todo_show: true,
                follow_todo_show: false, // 跟单顾问弹出层
                follow_todo_data: [],//
                screen_follow_todo: '', // 跟单顾问
                follow_todo_id: '', // 跟单顾问id


                /* ---- 资源类别部分 ---- */
                clear_cat_show: true, // 资源类别重置按钮
                cat_type_show: false, // 跟单顾问弹出层 catPopup
                cat_info: '', // 资源类别
                cat_type_id: '', // 资源类别id
                cat_type_data: [], // vant下拉数据格式(资源类别)
                request_cat_data: [], // 接口返回数据格式(资源类别)

                /* ---- 客户资源等级部分 ---- */
                clear_level_show: true, // 资源等级重置按钮
                level_show: false, // 客户资源等级弹出层
                screen_level: '', // 资源等级
                screen_level_id: '', // 资源等级id
                level_data: ['A', 'B','C','D','E','无效','未分级',],
                // 真实数据
                get_level_data: [
                    {id: 1, name: 'A'},
                    {id: 2, name: 'B'},
                    {id: 8, name: 'B+'},
                    {id: 3, name: 'C'},
                    {id: 9, name: 'C+'},
                    {id: 4, name: 'D'},
                    {id: 10, name: 'D+'},
                    {id: 5, name: 'E'},
                    {id: 11, name: 'E+'},
                    {id: 6, name: '无效'},
                    {id: 7, name: '未分级'},
                ],
                triangle: false,
                dataShow: false,
                searchShow: false,
                bottom_nav_visible: true, // 底部导航显示与隐藏
                screen_height: '', // 展开搜索面板高度
                screen_text: '更多筛选条件', // 更多筛选条件文字切换
                screen_switch: false, // 更多筛选条件文字切换开关
                screen_more: false,
                allCheckStates: false,
                adviserStates:false
            }
        },
        // 生命周期 初始化
        setup() {
            const active = ref(''); // 设定选定第几个
            return {
                active,
            };
        },
        created() {
            window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
            this.getAdviserData() // 顾问列表数据
            this.getCatData() // 资源类别
            this.searchShow = true
            this.searchStates = true
            this.dataShow = false
            this.page_num = 1
            if (this.postId === '2' || this.postId === '7') {
                this.adviserStates = false
            }
            if (this.postId === '0' ) {
                this.adviserStates = true
            }
        },
        // 监听软键盘是否弹出
        watch: {
            nowHeight: function () {
                if (this.defaultHeight !== this.nowHeight) {
                    this.bottom_nav_visible = false
                } else {
                    this.bottom_nav_visible = true
                }
            }
        },
        methods: {
            onLoad() {
                if (this.searchStates == false) {
                    if (!this.page_num) {
                        this.page_num = 1
                    }
                    this.getLoad()
                }
            },
            getLoad() {
                this.finished = false; // 停止加载
                this.searchStates = true
                let para = {
                    pageNum: parseInt(this.page_num) + 1, // 页码
                    pageSize: '10', // 每页条数
                    id: this.screen_id, // 客户id
                    name: this.screen_name, // 客户id
                    userId: this.follow_adviser_id, // 顾问id
                    cat_id: this.cat_type_id,// 资源类别
                    customer_grade: this.screen_level_id,// 客户资源等级
                    contact: this.screen_contact,// 联系方式
                }

                if (!para.userId && !para.id) {
                    this.dataShow = false
                    this.searchShow = true
                    Notify({type: 'danger', message: '客户序号与顾问须选填一项'});
                } else {
                    getTransferList(para).then(data => {
                        if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                            Notify({type: 'danger', message: data.message});
                            sessionStorage.clear()
                            this.$router.push({
                                path: '/weChatLogin'
                            })
                            return
                        }
                        if (data.code === 200) {
                            Notify({type: 'success', message: '搜索成功'});
                            // 暂无数据
                            if (data.data.data.count === 0) {
                                this.no_more = ''
                            } else {
                                this.no_more = '没有更多了'
                            }
                            // 数据全部加载完成
                            if (data.data.data.length === 0) {
                                this.finished = true; // 停止加载
                                return
                            }
                            this.page_num++
                            for (let i = 0; i < data.data.data.length; i++) {
                                data.data.data[i].isShow = false
                            }
                            this.dataList = this.dataList.concat(data.data.data) // 数组拼接
                            this.total = data.data.count
                            if (parseInt(data.data.count % 10) === 0) {
                                this.sum_page = parseInt(data.data.count / 10)
                            } else {
                                this.sum_page = parseInt(data.data.count / 10) + 1
                            }
                            this.allCheckStates = false
                            this.loading = false;
                            this.searchStates = false
                            this.current_page = para.pageNum
                        } else {
                            Notify({type: 'danger', message: data.message});
                        }
                    }).catch(() => {
                        Notify({type: 'danger', message: '服务连接失败'});
                    })
                }
            },
            // 更多筛选条件
            moreSearch() {
                this.screen_switch = !this.screen_switch
                if (this.screen_switch) {
                    this.screen_height = 600 + 'px'
                    this.screen_text = '收起'
                    this.screen_more = true
                } else {
                    this.screen_height = ''
                    this.screen_text = '更多筛选条件'
                    this.screen_more = false
                }
            },

            // 苹果键盘事件
            blurIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = true
                    return
                }
            },
            // 苹果键盘事件
            focusIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = false
                    return
                }
            },

            /**
             * 客户资源等级部分逻辑(搜索部分)
             * */
            // 资源等级弹层控制
            levelPopup() {
                this.level_show = true
            },

            // 确认赋值
            levelConfirm(value) {
                this.screen_level = value // 资源等级赋值
                // 获取下拉数据id (客户资源等级)
                for (let i = 0; i < this.get_level_data.length; i++) {
                    if (value === this.get_level_data[i].name) {
                        this.screen_level_id = this.get_level_data[i].id
                    }
                }
                this.level_show = false // 关闭弹层
                this.clear_level_show = false // 切换重置图标
            },

            // 取消选择
            levelCancel() {
                this.level_show = false // 关闭弹层
            },

            // 资源等级重置
            clearableLevel() {
                this.screen_level = '' // 清空资源等级回显
                this.screen_level_id = '' // 清空请求时参数
                this.clear_level_show = true // 切换重置图标
            },


            /**
             * 跟单顾问(搜索部分)
             * */
            // 顾问列表数据
            getAdviserData() {
                getAdviserList().then(data => {
                    // 判断token
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        this.request_adviser_data = data.data
                        for (let i = 0; i < this.request_adviser_data.length; i++) {
                            this.follow_adviser_data.push(this.request_adviser_data[i].user_name)
                            this.follow_todo_data = this.follow_adviser_data
                        }
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            // 跟单顾问弹层控制
            adviserPopup() {
                this.follow_adviser_show = true
            },

            /**
             * 资源类别(搜索部分)
             * */
            // 资源类别列表数据
            getCatData() {
                categoryList().then(data => {
                    // 判断token
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        this.request_cat_data = data.data
                        for (let i = 0; i < this.request_cat_data.length; i++) {
                            this.cat_type_data.push(this.request_cat_data[i].title)
                        }
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            // 资源类别弹层控制
            catPopup() {
                this.cat_type_show = true
            },

            // 确认赋值
            catConfirm(value) {
                this.cat_info = value // 顾问赋值
                // 获取下拉数据id (资源类别)
                for (let i = 0; i < this.request_cat_data.length; i++) {
                    if (value === this.request_cat_data[i].title) {
                        this.cat_type_id = this.request_cat_data[i].id
                    }
                }
                this.cat_type_show = false // 关闭弹层
                this.clear_cat_show = false // 切换图标
            },

            // 取消选择
            catCancel() {
                this.cat_type_show = false // 关闭弹层
            },
            // 资源类别重置
            clearableCat() {
                this.cat_info = '' // 清空资源类别回显
                this.cat_type_id = '' // 清空请求时参数
                this.clear_cat_show = true // 切换图标
            },

            // 确认赋值
            adviserConfirm(value) {
                this.screen_follow_adviser = value // 顾问赋值
                // 获取下拉数据id (跟单顾问)
                for (let i = 0; i < this.request_adviser_data.length; i++) {
                    if (value === this.request_adviser_data[i].user_name) {
                        this.follow_adviser_id = this.request_adviser_data[i].id
                    }
                }
                this.follow_adviser_show = false // 关闭弹层
                this.clear_adviser_show = false // 切换图标
            },

            // 取消选择
            adviserCancel() {
                this.follow_adviser_show = false // 关闭弹层
            },
            // 跟单顾问重置
            clearableAdviser() {
                this.screen_follow_adviser = '' // 清空跟单顾问回显
                this.follow_adviser_id = '' // 清空请求时参数
                this.clear_adviser_show = true // 切换图标
            },


            // 时间戳转化(年-月-日 时:分:秒)
            formatDate(time) {
                return moment(time * 1000).format("YYYY-MM-DD HH:mm:ss")
            },

            // 顾问列表数据
            adviserData() {
                getAdviserList().then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        this.saleData = data.data
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },

            // 返回上一页
            goBackPage() {
                this.$router.go(-1)
            },

            // 点击查找
            search() {
                this.allCheckStates = false
                this.searchStates = false
                this.current_page = '1'
                this.page_num = '0'
                this.dataList =[]
                this.getTableData()
            },

            getTableData(states) {

                this.finished = false; // 停止加载

                let para = {
                    pageNum: parseInt(this.page_num) + 1, // 页码
                    pageSize: '10', // 每页条数
                    id: this.screen_id, // 客户id
                    name: this.screen_name, // 客户名字
                    userId: this.follow_adviser_id, // 顾问id
                    cat_id: this.cat_type_id,// 资源类别
                    customer_grade: this.screen_level_id,// 客户资源等级
                    contact: this.screen_contact,// 联系方式
                }
                if (!para.userId && !para.id) {
                    this.dataShow = false
                    this.searchShow = true
                    Notify({type: 'danger', message: '客户序号与顾问须选填一项'});
                } else {
                    getTransferList(para).then(data => {
                        if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                            Notify({type: 'danger', message: data.message});
                            sessionStorage.clear()
                            this.$router.push({
                                path: '/weChatLogin'
                            })
                            return
                        }
                        if (data.code === 200) {
                            Notify({type: 'success', message: '搜索成功'});
                            // this.dataList = data.data.data
                            this.page_num++
                            this.dataList = this.dataList.concat(data.data.data) // 数组拼接
                            this.total = data.data.count
                            if (parseInt(data.data.count % 10) === 0) {
                                this.sum_page = parseInt(data.data.count / 10)
                            } else {
                                this.sum_page = parseInt(data.data.count / 10) + 1
                            }
                            for (let i = 0; i < this.dataList.length; i++) {
                                this.dataList[i].isShow = false
                            }
                            this.loading = false;
                            this.searchStates = false
                            this.current_page = para.pageNum
                            if (data.data.data.length > 0) {
                                this.dataShow = true
                                this.searchShow = false
                            } else {
                                this.dataShow = false
                                this.searchShow = true
                            }
                        } else {
                            Notify({type: 'danger', message: data.message});
                        }
                    }).catch(() => {
                        Notify({type: 'danger', message: '服务连接失败'});
                    })
                }

            },


            // 客户选中事件
            dataBtn(item) {
                item.isShow = !item.isShow; // 选中样式取反
                item.triangle = !item.triangle
                if (!this.customerId.includes(item.id)) {
                    this.customerId.push(item.id) // 判断已选列表中是否存在该id，不是则追加进去
                } else {
                    let index = this.customerId.indexOf(item.id) // 当前id的所在位置
                    this.customerId.splice(index, 1) // 否则则删除
                }
                if (this.customerId.length == this.dataList.length) {
                    this.allCheckStates = true
                } else {
                    this.allCheckStates = false
                }
            },
            // 全选
            allCheck(states) {
                if (states === true) {
                    for (let i = 0; i < this.dataList.length; i++) {
                        this.dataList[i].isShow = false
                    }
                    this.allCheckStates = false
                } else {
                    this.allCheckStates = true
                    for (let i = 0; i < this.dataList.length; i++) {
                        this.dataList[i].isShow = true
                    }
                }

                if (this.customerId.length == this.dataList.length) {
                    this.customerId = [] // 判断是否已全部选中，是则清空已选列表
                } else {
                    this.allCheckStates = true
                    this.dataList.forEach((item) => {
                        if (!this.customerId.includes(item.id)) {
                            this.customerId.push(item.id) // 否则将未选中的全部加入已选列表中
                        }
                    })
                }
                console.log(this.customerId)
            },
            // 跟单顾问弹层控制
            todoPopup() {
                this.follow_todo_show = true
            },

            // 确认赋值
            todoConfirm(value) {
                this.screen_follow_todo = value // 顾问赋值
                // // 获取下拉数据id (跟单顾问)
                for (let i = 0; i < this.request_adviser_data.length; i++) {
                    if (value === this.request_adviser_data[i].user_name) {
                        this.follow_todo_id = this.request_adviser_data[i].id
                    }
                }
                this.follow_todo_show = false // 关闭弹层
                this.clear_todo_show = false // 切换图标
            },

            // 取消选择
            todoCancel() {
                this.follow_todo_show = false // 关闭弹层
            },

            // 跟单顾问重置
            clearableTodo() {
                this.screen_follow_todo = '' // 清空跟单顾问回显
                this.follow_todo_id = '' // 清空请求时参数
                this.clear_todo_show = true // 切换图标
            },

            // 跳转按钮
            jumpPage() {
                // 点击搜索收起搜索栏
                if (this.screen_switch) {
                    this.screen_switch = false
                    this.screen_height = ''
                    this.screen_text = '更多筛选条件'
                    this.screen_more = false
                }

                this.searchStates = false
                if (!this.page_num) {
                    Notify('请输入页码')
                    return false
                }

                if (this.page_num > this.sum_page) {
                    this.page_num = this.sum_page
                }

                if (this.page_num < 1) {
                    this.page_num = '1'
                }
                this.page_num = this.page_num - 1
                this.current_page = this.page_num
                this.dataList = []
                this.getTableData()
            },
            // 客户资源等级转化
            customerGrade(value) {
                let grade_arr = ['', 'A', 'B', 'C', 'D', 'E', '无效', '未分级']
                return grade_arr[value]
            },

            // 提交
            submit() {
                let para = {
                    userId: this.follow_adviser_id, // 原顾问id
                    customerId: this.customerId.toString(),// 客户ID
                    changeId: this.follow_todo_id,// 待转顾问ID
                    id: '', // 前端写死的
                }

                if(this.follow_adviser_id ==''){
                    para.userId=sessionStorage.getItem('userId')
                }
                if (para.customerId.length === 0) {
                    Notify({type: 'danger', message: '未选择客户，请选择！'});
                    return
                }
                if (para.changeId === '') {
                    Notify({type: 'danger', message: '待转顾问不能为空，请选择！'});
                    return
                }

                if (para.userId === para.changeId) {
                    Notify({type: 'danger', message: '同一顾问不能进行此操作'});
                    return
                }
                if(para.userId == ''){
                    para.userId =sessionStorage.getItem('userId')
                }
                transferOrder(para).then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        Notify({type: 'success', message: '转单成功'});
                        window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
                        this.dataList = []
                        this.follow_adviser_id = ''  // 跟单顾问参数
                        this.screen_follow_adviser ='' ,// 跟单顾问回显
                        this.follow_todo_id = ''
                        this.customerId = []

                        this.screen_follow_todo = '' // 待转顾问回显
                        this.follow_todo_id ='',// 待转顾问参数
                        this.screen_id = '' // 客户id
                        this.screen_name = '', // 客户id

                        this.cat_type_id = '' ,// 资源类别擦念书
                        this.cat_info ='',// 资源类别回显
                        this.screen_level_id = '',// 客户资源等级
                        this.screen_level ='',// 客户回显
                        this.screen_contact = ''// 联系方式

                        this.dataShow =false
                        this.allCheckStates =false
                        this.current_page = '1'
                        this.page_num = '1'
                        this.total= '0', // 总条数
                        this.sum_page= '0' // 总页数
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            // 跳转详情页
            toDetail(id) {
                // 跳转传id
                this.$router.push({
                    path: '/customer_detail',
                    query: {
                        id: id,
                        pages: this.page_num
                    }
                })
            },

            // 跳转跟单页
            toFollowOrder(id) {
                this.$router.push({
                    path: '/documentary',
                    query: {
                        id: id
                    }
                })
            },
        }
    }
</script>

<style scoped>
    /* ---- header区域 ---- */
    .header {
        position: sticky;
        top: 0;
        height: 52px;
        margin-bottom: 12px;
        background: #fff;
        box-shadow: 0 9px 16px rgba(25, 52, 139, 0.06);
        z-index: 9;
    }

    .header-nav {
        position: relative;
        height: 53px;
        color: #222;
        font-size: 19px;
        text-align: center;
        line-height: 53px;
    }

    .header-nav-back {
        position: absolute;
        left: 10px;
        width: 19px;
        height: 16px;
    }

    .content-wrap {
        margin-top: 12px;
        padding: 20px 15px;
        background: #fff;
        height: 100%;
    }

    /* ---- 客户搜索模块---- */
    .customer-box {
        display: flex;
        justify-content: space-between;
    }

    .customer-input {
        width: 270px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
    }

    .customer-search {
        width: 65px;
        height: 45px;
        line-height: 45px;
        background: #409EFF;
        border-radius: 4px;
        text-align: center;
        font-size: 15px;
        color: #FFFFFF;
        border: none;
    }

    /* ---- 客户详情部分---- */
    .data-index {
        padding: 18px;
        background: #ffffff;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        margin: 11px 0 0;
        position: relative;
    }

    .index-top {
        padding-bottom: 9px;
        border-bottom: 1px solid #DDE0E7;
        display: flex;
        justify-content: space-between;
    }

    .name-num {
        font-size: 15px;
        color: #A1A7BA;
        margin-right: 8px;
    }

    .name-title {
        font-size: 15px;
        color: #222222;
    }

    .index-two {
        margin-top: 13px;
        display: flex;
        justify-content: space-between;
    }

    .index-three {
        margin-top: 13px;
        display: flex;
    }

    .data-index-active {
        border: 1px solid #409EFF;
    }

    .active {
        border: 1px solid #409EFF;

    }

    /* ---- 选择顾问---- */
    .info-box {
        width: 345px;
        margin: 0 auto 16px;
    }

    .info-title {
        color: #A1A7BA;
        font-size: 15px;
    }

    .info-input {
        position: relative;
        width: 343px;
        height: 38px;
        margin-top: 12px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .down-icon {
        position: absolute;
        top: 13px;
        right: 15px;
        width: 12px;
        color: #606266;
    }

    .vant-input-edit {
        height: 43px !important;
        line-height: 43px !important;
        padding: 0 15px !important;
        color: #222 !important;
        font-size: 15px !important;
    }

    .submit-btn1 {
        width: 345px;
        height: 50px;
        margin: 25px auto 25px;
        background: #409EFF;
        color: #fff;
        font-size: 17px;
        text-align: center;
        line-height: 50px;
        border-radius: 50px;
    }


    .triangle {
        position: absolute;
        right: 0;
        bottom: 0;
        border-style: solid;
        border-width: 0 0 45px 45px;
        border-color: transparent transparent #409EFF transparent;
        width: 0;
        height: 0;
    }

    .blue-icon {
        margin-top: 2px;
        margin-right: 6px;
        width: 15px;
        height: 15px;

        color: blue;
        z-index: 99
    }

    /* ---- 筛选部分 ---- */
    .detail-w {
        width: 345px;
        margin: 0 auto;
    }

    .screen {
        position: relative;
        height: 270px;
        margin-top: 15px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
    }

    .search-term {
        padding-top: 15px;
    }

    .search-term-label {
        padding: 0 20px;
        color: #A1A7BA;
        font-size: 15px;
    }

    .search-term-detail {
        position: relative;
        width: 303px;
        height: 38px;
        margin: 10px auto 0;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .down-icon {
        position: absolute;
        top: 0;
        right: 15px;
        width: 12px;
        height: 100%;
        color: #606266;
    }

    .down-icon svg {
        position: relative;
        top: 11px;
    }

    .down-icon i {
        position: relative;
        top: 11px;
        left: -2px;
        color: #C9C7C8;
    }

    .vant-input-edit {
        height: 38px !important;
        line-height: 38px !important;
        padding: 0 10px !important;
        color: #222 !important;
        font-size: 15px !important;
    }

    .input-edit-time {
        padding: 0 !important;
        line-height: 40px !important;
    }

    .vant-input-edit >>> input::-webkit-input-placeholder {
        color: #606266 !important;
    }

    .term-detail-contact {
        display: flex;
        justify-content: space-between;
        width: 305px;
        margin: 10px auto 0;
    }

    .contact-down {
        position: relative;
        width: 85px;
        height: 38px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        line-height: 38px;
    }

    .contact-icon {
        top: 0;
        right: 8px;
    }

    .contact-icon i {
        top: 1px;
    }

    .contact-icon svg {
        top: 0;
    }

    .contact-icon {
        top: 0;
        right: 7px;
    }

    .contact-detail {
        width: 205px;
        height: 38px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .time-clear i {
        top: 1px;
    }

    .screen-operation {
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 25px;
        left: 20px;
        width: 305px;
    }

    .search-btn {
        width: 147px;
        height: 40px;
        background: #409EFF;
        border-radius: 4px;
        text-align: center;
        line-height: 40px;
        color: #fff;
        font-size: 15px;
    }

    .more-search {
        width: 145px;
        height: 38px;
        border: 1px solid #409EFF;
        border-radius: 4px;
        text-align: center;
        line-height: 38px;
        color: #409EFF;
        font-size: 15px;
    }

    .default-text {
        padding-left: 10px;
        color: #606266;
        font-size: 15px;
    }

    .time-slot-box {
        display: flex;
        width: 285px;
        padding: 0 10px;
        line-height: 40px;
    }

    .time-slot {
        width: 135px;
        height: 38px;
        color: #606266;
    }

    .time-slot-center {
        margin-right: 10px;
        color: #222;
        font-size: 15px;
    }

    .operate-info {
        display: flex;
        justify-content: space-between;
        width: 305px;
        height: 40px;
        margin: 23px auto 0;
    }

    .all-change-item {
        margin-top: 15px;
        display: flex;
        align-items: center;
    }

    .all-change-checked {
        width: 15px;
        height: 15px;
        margin-right: 9px;
    }

    /* ---- header区域 ---- */
    .header {
        position: sticky;
        top: 0;
        /*height: 106px;*/
        height: 52px;
        background: #fff;
        box-shadow: 0 9px 16px rgba(25, 52, 139, 0.06);
        z-index: 9;
    }

    .header-nav {
        position: relative;
        height: 53px;
        border-bottom: 1px solid #F5F5FC;
        color: #222;
        font-size: 19px;
        text-align: center;
        line-height: 53px;
    }

    .header-nav div {
        position: absolute;
        left: 10px;
        width: 19px;
        height: 16px;
    }

    .header-page {
        display: flex;
        justify-content: space-between;
        height: 53px;
        padding: 0 15px;
    }

    .page-info {
        color: #A1A7BA;
        font-size: 12px;
        line-height: 53px;
    }

    .page-num {
        padding: 0 2px;
        color: #222;
    }

    .page-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .page-input {
        width: 43px;
        height: 28px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .page-btn input {
        padding: 0;
        width: 43px;
        height: 28px;
        border: none;
        color: #A1A7BA;
        font-size: 15px;
        text-align: center;
    }

    .jump-btn {
        width: 49px;
        height: 30px;
        margin-left: 7px;
        background: #409EFF;
        border-radius: 4px;
        color: #fff;
        font-size: 15px;
        text-align: center;
        line-height: 30px;
    }

    .page-jump {
        padding: 0;
    }
    .page-jump >>> input {
        height: 30px;
        color: #A1A7BA;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
    }
</style>
