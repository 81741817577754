<template>
  <div>
  </div>
</template>

<script>
  import {refreshToken} from "../api/login";
  import {Notify} from "vant";

  export default {
    created() {
      // 此处为打包环境 打包时放开此处
      // S----打包环境----
      // let login_url = process.env.VUE_APP_BASE_URL // 正式地址
      // if (this.$route.query.company === 'sy') {
      //   location.href = 'https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=dingy8vbkbckjgrpdlf0&response_type=code&scope=snsapi_auth&state=STATE&redirect_uri=' + login_url + '/api/login/shenyang'
      //   return;
      // }
      //
      // if (this.$route.query.company === 'sz') {
      //   location.href = 'https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=dingwswtrpb8vhyo6tka&response_type=code&scope=snsapi_auth&state=STATE&redirect_uri=' + login_url + '/api/login/shenzhen'
      //   return;
      // }
      //
      // if (!this.$route.query.company) {
      //   Notify('无权访问此系统!')
      //   return
      // }
      //
      // if (this.$route.query.company && !this.$route.query.token) {
      //   Notify('缺失令牌')
      //   return;
      // }
      //
      // if (this.$route.query.company && this.$route.query.token) {
      //   sessionStorage.setItem('postID', this.$route.query.post) // 存储  post 权限(0:管理员; 1:运营;2:顾问;)token信息
      //   sessionStorage.setItem('token',  "bearer " + this.$route.query.token) // 存储token信息
      //   sessionStorage.setItem('userName', this.$route.query.user_name) // 存储用户名
      //   this.getToken() // 获取token
      // }
      // E----打包环境----


      // 此处为测试环境 测试时调用此模块
      // S----测试调试环境----
      // sessionStorage.setItem('postID', '0') // 存储  post 权限(0:管理员; 1:运营;2:顾问;)token信息
      // // sessionStorage.setItem('token',  "bearer " + 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOjE5LCJhdWQiOiIiLCJleHAiOjE2NDg5MjE1NjksImlhdCI6MTY0ODg2MTU2OSwiaXNzIjoiIiwianRpIjoiNzk0MjgzNWUyMjQ2M2UxOTBhODc2NzhmOGQ2ZTUzMTAiLCJuYmYiOjE2NDg4NjE1NjksInN1YiI6IiJ9.NmrLtvzFslw5UDS0hmh4Hwh0JVZmAeOzbxLkOb9THGY') // 存储token信息
      // sessionStorage.setItem('token',  "bearer " + this.$route.query.token) // 存储token信息
      // sessionStorage.setItem('userName', '123') // 存储用户名
      // this.getToken() // 获取token
      // E----测试调试环境----
    },
    methods: {
      //获取token
      getToken() {
        refreshToken().then(data => {
          if (data.code === 200) {
            var newToken = "bearer " + data.data  // 后台请求回来的token值
            sessionStorage.setItem('token', newToken) // 存储token信息
            this.$router.push({
              path: '/index',
            });
            // Notify({ type: 'success', message: '登录成功' })
          } else {
            Notify({type: 'danger', message: data.message})
          }
        }).catch(() => {
          Notify({type: 'warning', message: '服务器连接失败'})
        })
      },
    }
  }
</script>

<style scoped>

</style>
