<template>
  <div>
    <div class="container">
      <div class="download-box">
        <div>
          <div class="download-title">电子合同</div>
          <div class="download-item">
            <document />
            <div class="download-mask">
              <div class="download-icon" @click="downloadContract()">
                <img class="download-pic" src="../assets/download.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="download-title">付款证明</div>
          <div class="download-item">
            <van-icon name="photo-o" />
            <div class="download-mask">
              <div class="download-icon" @click="downloadVoucher()">
                <img class="download-pic" src="../assets/download.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="info-box">
        <div class="download-title">基本信息</div>
        <div class="info-content">
          <div class="info-content-item">
            <div class="content-item-title">客户名称</div>
            <div class="content-item-detail">{{customer_name}}</div>
          </div>
          <div class="info-content-item">
            <div class="content-item-title">签约项目</div>
            <div class="content-item-detail">{{sign_project}}</div>
          </div>
          <div class="info-content-item">
            <div class="content-item-title">跟单顾问</div>
            <div class="content-item-detail">{{follow_adviser}}</div>
          </div>
          <div class="info-content-item">
            <div class="content-item-title">运营主管</div>
            <div class="content-item-detail">{{operate_admin}}</div>
          </div>
          <div class="info-content-item">
            <div class="content-item-title">运营经理</div>
            <div class="content-item-detail">{{operations_manager_name}}</div>
          </div>
          <div class="info-content-item">
            <div class="content-item-title">文案主管</div>
            <div class="content-item-detail">{{copywriting_admin}}</div>
          </div>
          <div class="info-content-item">
            <div class="content-item-title">文案</div>
            <div class="content-item-detail">{{copyright_name}}</div>
          </div>
          <div class="info-content-item">
            <div class="content-item-title">创建时间</div>
            <div class="content-item-detail">{{formatDate(create_time)}}</div>
          </div>
          <div class="info-content-item">
            <div class="content-item-title">更新时间</div>
            <div class="content-item-detail">{{formatDate(update_time)}}</div>
          </div>
          <div class="info-content-item">
            <div class="content-item-title">运营审批</div>
            <div class="content-item-detail">
              <span style="color: #F56C6C" v-if="operations_status === 0">未审核</span>
              <span style="color: #409EFF" v-if="operations_status === 1">已审核</span>
            </div>
          </div>
          <div class="info-content-item">
            <div class="content-item-title">审核时间</div>
            <div class="content-item-detail">
              <span v-if="operations_time">{{formatDate(operations_time)}}</span>
              <span v-else>暂无数据</span>
            </div>
          </div>
          <div class="info-content-item">
            <div class="content-item-title">文案审批</div>
            <div class="content-item-detail">
              <span style="color: #F56C6C" v-if="copyright_status === 0">未审核</span>
              <span style="color: #409EFF" v-if="copyright_status === 1">已审核</span>
            </div>
          </div>
          <div class="info-content-item">
            <div class="content-item-title">审核时间</div>
            <div class="content-item-detail">
              <span v-if="copyright_time">{{formatDate(copyright_time)}}</span>
              <span v-else>暂无数据</span>
            </div>
          </div>
        </div>
      </div>

      <div class="search-term" v-if="person_visible">
        <div class="search-term-label">选择人员</div>
        <div class="search-term-detail" @click="adviserPopup">
          <van-field class="vant-input-edit" v-model="personnel_info" clearable readonly placeholder="请选择"/>
          <div class="down-icon" v-if="clear_personnel_show">
            <caret-bottom/>
          </div>
          <div class="down-icon" @click.stop="clearableAdviser" v-else>
            <van-icon name="clear"/>
          </div>
        </div>
      </div>

      <div class="btn-wrap">
        <div class="btn-item-l" @click="submitBtn">确定</div>
        <div class="btn-item-r" @click="cancelBtn">取消</div>
      </div>
    </div>

    <!-- S 顾问弹层 -->
    <van-popup v-model:show="personnel_show" position="bottom" :style="{ height: '48%' }">
      <van-picker
          title="请选择顾问"
          show-toolbar
          :columns="personnel_data"
          @confirm="adviserConfirm"
          @cancel="adviserCancel"
      />
    </van-popup>
    <!-- E 顾问弹层 -->

    <!-- S 底部导航栏 -->
    <div class="occupy-box"></div>
    <!-- S 两个底部导航栏 -->
    <div v-if="twoStates">
      <van-tabbar v-model="active">
        <van-tabbar-item replace to="/index">
          <span>首页</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <home-filled/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/customSchedule">
          <span>客户列表</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <PieChart/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
    <!-- S 两个底部导航栏 -->
    <!-- S 三个底部导航栏 -->
    <div v-if="threeStates">
      <van-tabbar v-model="active">
        <van-tabbar-item replace to="/index">
          <span>首页</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <home-filled/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/approve">
          <span>交接审批</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <Coordinate/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/customSchedule">
          <span>客户列表</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <PieChart/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
    <!-- S 三个底部导航栏 -->

    <!-- S 四个底部导航栏 -->
    <div v-if="fourStates">
      <van-tabbar v-model="active">
        <van-tabbar-item replace to="/index">
          <span>首页</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <home-filled/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/customManage">
          <span>客户管理</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <user/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/signedResources">
          <span>已签约</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <edit/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/sharedPool">
          <span>共享池</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <monitor/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
    <!-- S 四个底部导航栏 -->
    <!-- S 底部导航栏 -->
  </div>
</template>

<script>
  import moment from "moment";
  import {copywriting, operationsDown, doDistribution} from "../api";
  import {Notify} from "vant";
  import {ref} from "vue";

  export default {
    data() {
      return {
        /* ---- 基本信息 ---- */
        customer_name: '', // 客户名称
        sign_project: '', // 签约项目
        operate_admin: '', // 运营主管
        operations_manager_name: '', // 运营经理
        follow_adviser: '', // 跟单顾问
        copywriting_admin: '', // 文案主管
        copyright_name: '', // 文案
        contract: '', // 合同文件路径
        payment_voucher: '', // 付款凭证图片路径
        create_time: '', // 创建时间
        update_time: '', // 更新时间
        operations_status: '', // 运营审核状态
        operations_time: '', // 运营审核时间
        copyright_status: '', // 文案审核状态
        copyright_time: '', // 文案审核时间
        data_id: '', // 数据id
        customer_id: '', // 客户id
        adviser_id_info: '', // 顾问id

        /* ---- 人员选择部分 ---- */
        personnel_id: '', // 人员id
        personnel_show: false, // 选择人员弹出层
        personnel_info: '', // 选择人员回显
        request_personnel_data: [], // 接口返回顾问数据
        personnel_data: [], // vant 顾问数据形式
        clear_personnel_show: true, // 签约顾问重置按钮
        person_visible: false, // 选择分配人员下拉框

        threeStates: true,//三底部栏
        twoStates: false,//三底部栏
        fourStates: false,//四底部栏
        postId: "" // 权限
      }
    },

    // 生命周期 初始化
    setup() {
      const active = ref(null); // 设定选定第几个
      return {
        active,
      };
    },

    created() {
      window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
      if (this.$route.query.data) {
        let show_data = JSON.parse(decodeURIComponent(this.$route.query.data)) // 接收上个页面传递过来的对象
        this.customer_name = show_data.user_name // 客户姓名
        this.sign_project = show_data.project // 签约项目
        this.follow_adviser = show_data.adviser_name // 跟单顾问
        this.operate_admin = show_data.operations_director_name // 运营主管
        this.operations_manager_name = show_data.operations_manager_name // 运营经理
        this.copywriting_admin = show_data.copywriter_supervisor_name // 文案主管
        this.copyright_name = show_data.copyright_name // 文案
        this.create_time = show_data.create_time // 创建时间
        this.update_time = show_data.update_time // 更新时间
        this.operations_status = show_data.operations_status // 运营审批状态
        this.operations_time = show_data.operations_time // 运营审批时间
        this.copyright_status = show_data.copyright_status // 文案审批状态
        this.copyright_time = show_data.copyright_time // 运营审批时间
        this.payment_voucher = show_data.proof_of_payment // 付款凭证路径
        this.contract = show_data.contract // 合同文件路径
        this.data_id = show_data.id // 数据id
        this.customer_id = show_data.customer_id // 客户id
        this.adviser_id_info = show_data.adviser_id // 顾问id

        if (show_data.operations_manager_id) {
          this.clear_personnel_show = false
        }

        if (sessionStorage.getItem('postID') === '3') {
          this.personnel_info = show_data.operations_manager_name // 运营经理
          this.personnel_id = show_data.operations_manager_id // 运营经理id
        }
        if (sessionStorage.getItem('postID') === '4') {
          this.personnel_info = show_data.copyright_name // 文案
          this.personnel_id = show_data.copyright_id // 文案id
        }
      }

      if (sessionStorage.getItem('postID') === '3') {
        this.person_visible = true
        this.operationsSelect() // 运营经理下拉
      }

      if (sessionStorage.getItem('postID') === '4') {
        this.person_visible = true
        this.getCopywriting() // 文案下拉数据
      }

      // 星迪修改（底部导航栏）
      this.postId = sessionStorage.getItem("postID")
      if (this.postId === '6' || this.postId === '5') { // 判断 文案 或者 运营经理
        this.threeStates = false // 不显示两个个底部
        this.twoStates = true// 不显示三个底部
        this.active = 1   //
      }
      if (this.postId === '3' || this.postId === '4') { // 判断 运营主管 或者 文案主管
        this.threeStates = true // 显示两个个底部
        this.twoStates = false// 不显示三个底部
        this.active = null
      }
      if (this.postId === '0') { // 判断 管理员
        this.threeStates = false // 显示两个个底部
        this.twoStates = false// 不显示三个底部
        this.fourStates = true// 不显示三个底部
        this.active = null
      }

    },

    methods: {
      // 时间戳转化(年-月-日 时:分:秒)
      formatDate(time) {
        return moment(time * 1000).format("YYYY-MM-DD HH:mm:ss")
      },

      // 运营下拉数据
      operationsSelect() {
        operationsDown().then(data => {
          // 判断token
          if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403) {
            Notify({type: 'danger', message: data.message});
            sessionStorage.clear()
            this.$router.push({
              path: '/login'
            })
            return
          }
          if (data.code === 200) {
            this.request_personnel_data = data.data
            // 请求数据获取顾问数组格式化为vant下拉数组格式
            for (let i = 0; i < this.request_personnel_data.length; i++) {
              this.personnel_data.push(this.request_personnel_data[i].user_name) // vant 下拉数组赋值
            }
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 文案下拉数据
      getCopywriting() {
        copywriting().then(data => {
          // 判断token
          if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403) {
            Notify({type: 'danger', message: data.message});
            sessionStorage.clear()
            this.$router.push({
              path: '/login'
            })
            return
          }
          if (data.code === 200) {
            this.request_personnel_data = data.data
            // 请求数据获取顾问数组格式化为vant下拉数组格式
            for (let i = 0; i < this.request_personnel_data.length; i++) {
              this.personnel_data.push(this.request_personnel_data[i].user_name) // vant 下拉数组赋值
            }
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      /**
       * 跟单顾问(搜索部分)
       * */
      // 跟单顾问弹层控制
      adviserPopup() {
        this.personnel_show = true
      },

      // 确认赋值
      adviserConfirm(value, index) {
        this.personnel_info = value // 顾问赋值
        // 根据选中顾问获取对应顾问id
        for (let i = 0; i < this.request_personnel_data.length; i++) {
          if (index === i) {
            this.personnel_id = this.request_personnel_data[i].id // 顾问id赋值
          }
        }
        this.personnel_show = false // 关闭弹层
        this.clear_personnel_show = false // 切换重置图标
      },

      // 取消选择
      adviserCancel() {
        this.personnel_show = false // 关闭弹层
      },

      /**
       * 重置下拉按钮
       * */
      // 重置签约顾问
      clearableAdviser() {
        this.personnel_info = '' // 清空回显
        this.personnel_id = '' // 清空请求时参数
        this.clear_personnel_show = true // 切换重置图标
      },

      // 提交
      submitBtn() {
        let that = this
        let para = {
          id: this.data_id, // 数据id
          customer_id: this.customer_id, // 客户id
          user_id: this.personnel_id, // 分配给谁
          adviser_id: this.adviser_id_info // 顾问id
        }

        if (sessionStorage.getItem('postID') === '3' || sessionStorage.getItem('postID') === '4') {
          if (!para.user_id) {
            Notify({type: 'danger', message: '请选择分配人员!'});
            return
          }
          // 调用接口执行操作
          doDistribution(para).then(data => {
            if (data.code === 200) {
              Notify({type: 'success', message: '操作成功!'});
              setTimeout(function () {
                that.$router.go(-1)
              }, 800)
            } else {
              Notify({type: 'danger', message: data.message});
            }
          }).catch(() => {
            Notify({type: 'danger', message: '服务连接失败'});
          })
        } else {
          Notify({type: 'danger', message: '无权操作!'});
        }
      },

      // 取消按钮
      cancelBtn() {
        this.$router.go( -1) // 返回上一页
      },

      // 下载合同
      downloadContract() {
        let download_url = process.env.VUE_APP_BASE_URL + '/api/upload/download?url=' + this.contract // 下载接口
        window.open(download_url, '_blank')
      },

      // 下载凭证
      downloadVoucher() {
        let download_url = process.env.VUE_APP_BASE_URL + '/api/upload/download?url=' + this.payment_voucher // 下载接口
        window.open(download_url, '_blank')
      },
    }
  }
</script>

<style scoped>
  .container {
    margin-top: 12px;
    padding-bottom: 40px;
    background: #fff;
    overflow: hidden;
  }

  .download-box {
    display: flex;
    width: 345px;
    margin: 17px auto 0;
  }

  .download-item {
    position: relative;
    text-align: center;
    width: 95px;
    height: 95px;
    margin-right: 23px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
  }

  .download-title {
    margin-bottom: 15px;
    color: #A1A7BA;
    font-size: 15px;
  }

  .download-item svg {
    width: 53px;
    color: #222;
    margin-top: 20px;
    font-size: 38px;
  }

  .download-mask {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    width: 97px;
    height: 97px;
    background: rgba(255, 255, 255, .8);
  }

  .download-icon {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background: #409EFF;
  }

  .download-item i {
    margin-top: 20px;
    font-size: 58px;
  }

  .download-icon svg {
    width: 15px;
    margin-top: 6px;
    color: #fff;
  }

  .info-box {
    width: 345px;
    margin: 20px auto 0;
  }

  .info-content {
    width: 343px;
    margin: 0 auto;
    padding: 15px 0;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
  }

  .info-content-item {
    display: flex;
    margin-bottom: 10px;
  }

  .content-item-title {
    flex-shrink: 0;
    width: 60px;
    margin-left: 20px;
    color: #A1A7BA;
    font-size: 15px;
  }

  .content-item-detail {
    flex-shrink: 0;
    width: 230px;
    margin-left: 10px;
    color: #222;
    font-size: 15px;
    line-height: 20px;
  }

  .search-term {
    padding-top: 15px;
  }

  .search-term-label {
    padding: 0 20px;
    color: #A1A7BA;
    font-size: 15px;
  }

  .search-term-detail {
    position: relative;
    width: 345px;
    height: 38px;
    margin: 10px auto 0;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
  }

  .vant-input-edit {
    position: relative;
    top: -2px;
  }

  .down-icon {
    position: absolute;
    top: 0;
    right: 15px;
    width: 12px;
    height: 100%;
    color: #606266;
  }

  .down-icon svg {
    position: relative;
    top: 11px;
  }

  .down-icon i {
    position: relative;
    top: 11px;
    left: -2px;
    color: #C9C7C8;
  }

  .btn-wrap {
    display: flex;
    justify-content: space-between;
    width: 345px;
    margin: 25px auto 0;
  }

  .btn-item-l {
    width: 162px;
    height: 50px;
    background: #409EFF;
    color: #fff;
    font-size: 16px;
    text-align: center;
    line-height: 50px;
    border-radius: 50px;
  }

  .btn-item-r {
    width: 160px;
    height: 48px;
    color: #409EFF;
    font-size: 16px;
    text-align: center;
    line-height: 50px;
    border-radius: 50px;
    border: 1px solid #409EFF;
  }

  .download-pic {
    width: 14px;
    margin-top: 6px;
  }

  /*底部栏*/
  .navIcon {
    height: 21px;
    width: 21px;
  }
</style>
