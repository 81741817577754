<template>
  <!-- S 头部header -->
  <div class="header">
    <div class="header-nav">
      <div class="submit-btn" @click="addCost()">添加</div>
    </div>
  </div>
  <div style="height: 53px"></div>
  <!-- E 头部header -->
  <!-- S 数据列表 -->
  <div class="w">
    <div v-for="(item, i) in list" v-if="dataStates">
      <div class="documentary-box">
        <div class="list-top">{{formatDate(item.create_time)}}</div>
        <div class="list-one">
          <div class="one-left">名称</div>
          <div class="one-title"> {{strSplit(item.title, 2)}}</div>
        </div>
<!--        <div class="list-one">-->
<!--          <div class="one-left">内容</div>-->
<!--          <div class="one-title"> {{strSplit(item.title, 2)}}</div>-->
<!--        </div>-->
        <div class="list-one">
          <div class="one-left">操作人</div>
          <div class="one-title">{{item.user_name}}</div>
        </div>
        <div class="list-one">
          <div class="one-left">备注</div>
          <div class="one-title">{{item.remark}}</div>
        </div>
        <div class="list-textarea">
          <div class="textarea-top">附件</div>
          <div class="file-item" v-for="file in item.file">
            <img class="file-icon" src="../assets/upload-img.png" alt="">
            <div class="file-name">{{strAccurate(file)}}</div>
            <div class="download-box" @click="downLoadBtn(file)">
              <img src="../assets/download.png" alt="">
            </div>
          </div>
        </div>
        <div class="btn-box">
          <div class="box-edit" @click="modify(item)">修改</div>
          <div class="box-del" @click="delData(item.id)">删除</div>
        </div>
      </div>
    </div>
    <div v-else class="empty-data">暂无数据</div>
  </div>
  <!-- E 数据列表 -->
  <!-- S 底部导航栏 -->
  <div class="occupy-box"></div>
  <!-- S 两个底部导航栏 -->
  <div v-if="twoStates">
    <van-tabbar v-model="active">
      <van-tabbar-item replace to="/index">
        <span>首页</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <home-filled/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/customSchedule">
        <span>客户列表</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <PieChart/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
  <!-- S 两个底部导航栏 -->
  <!-- S 三个底部导航栏 -->
  <div v-if="threeStates">
    <van-tabbar v-model="active">
      <van-tabbar-item replace to="/index">
        <span>首页</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <home-filled/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/approve">
        <span>交接审批</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <Coordinate/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/customSchedule">
        <span>客户列表</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <PieChart/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
  <!-- S 三个底部导航栏 -->

  <!-- S 四个底部导航栏 -->
  <div v-if="fourStates">
    <van-tabbar v-model="active">
      <van-tabbar-item replace to="/index">
        <span>首页</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <home-filled/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/customManage">
        <span>客户管理</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <user/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/signedResources">
        <span>已签约</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <edit/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/sharedPool">
        <span>共享池</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <monitor/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
  <!-- S 四个底部导航栏 -->
</template>

<script>
  import {customerCostData, deleteProgress} from '../api'
  import {Dialog, Notify} from "vant";
  import {ref} from "vue";
  import moment from "moment";

  export default {
    name: "documentary",
    data() {
      return {
        list: [], // 列表数据
        file_list: [], // 附件数据
        custom_id: '', // 客户id
        dataStates: true, // 暂无数据开关

        threeStates: false,//三底部栏
        twoStates: false,//三底部栏
        fourStates: false,//四底部栏
        postId: "" // 权限
      }
    },

    // 生命周期 初始化
    setup() {
      const active = ref(''); // 设定选定第几个
      return {
        active,
      };
    },

    created() {
      this.custom_id = this.$route.query.id // 获取客户id
      window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
      this.reset() // 请求列表数据

      // 星迪修改（底部导航栏）
      this.postId = sessionStorage.getItem("postID")
      if (this.postId === '6' || this.postId === '5') { // 判断 文案 或者 运营经理
        this.threeStates = false // 不显示两个个底部
        this.fourStates = false// 不显示四个底部
        this.twoStates = true// 不显示三个底部
        this.active = null   //
      }
      if (this.postId === '3' || this.postId === '4') { // 判断 运营主管 或者 文案主管
        this.threeStates = true // 显示两个个底部
        this.twoStates = false// 不显示三个底部
        this.fourStates = false// 不显示四个底部
        this.active = null
      }
      if (this.postId === '0') { // 判断 管理员
        this.threeStates = false // 显示两个个底部
        this.twoStates = false// 不显示三个底部
        this.fourStates = true// 不显示四个底部
        this.active = null
      }
    },
    methods: {
      // 时间戳转化(年-月-日 时:分:秒)
      formatDate(time) {
        return moment(time * 1000).format("YYYY-MM-DD HH:mm:ss")
      },

      // 字符串截取
      strSplit(str, type) {
        let new_str = str
        if (type === 1) {
          new_str = str.split(':')[0]
        } else {
          new_str = str.split(':')[1]
        }
        return new_str
      },

      // 指定区域接取字符串
      strAccurate(name_str) {
        let file_name = name_str.lastIndexOf("\/");
        name_str = name_str.substring(file_name + 1, name_str.length);
        return name_str
      },

      // 下载附件
      downLoadBtn(file_url) {
        let download_url = process.env.VUE_APP_BASE_URL + '/api/upload/download?url=' + file_url // 下载接口
        window.open(download_url, '_blank')
      },


      //初始化数据请求函数
      reset() {
        let para = {
          customer_id: this.$route.query.id
        }
        customerCostData(para).then(data => {
          if (data.code === 200) {
            this.list = data.data
            if (data.data.length <= 0) {
              this.dataStates = false
            } else {
              this.dataStates = true
              this.list = data.data
            }
          } else {
            Notify({type: 'danger', message: data.message});
          }
        })
      },

      // 添加
      addCost() {
        this.$router.push({
          path: '/customCostDetail',
          query: {
            id: this.custom_id // 客户ID
          }
        });
      },

      // 修改
      modify(item) {
        this.$router.push({
          path: '/customCostDetail',
          query: {
            data: encodeURIComponent(JSON.stringify(item)), // vue路由传递对象
            id: this.custom_id, // 客户ID
            edit_status: '1', // 修改状态
          }
        });
      },

      // 删除数据
      delData(id) {
        let para = {
          id: id // 数据id
        }
        Dialog.confirm({
          message: '确认删除本条数据吗?',
        })
        .then(() => {
          deleteProgress(para).then(data => {
            if (data.code === 200) {
              Notify({type: 'success', message: '删除成功'});
              this.reset() // 更新数据
            } else {
              Notify({type: 'danger', message: data.message});
            }
          }).catch(() => {
            Notify({type: 'danger', message: '服务连接失败'});
          })
        })
        .catch(() => {
        });
      },
    },
  }
</script>

<style scoped>
  .header {
    position: fixed;
    top: 0;
    width: 100%;
  }


  /*此处可能存在添加样式问题 设计稿明显不同 2022-2-9*/
  .submit-btn1 {
    position: absolute;
    top: 11px;
    right: 15px;
    height: 30px;
    color: #409EFF;
    font-size: 15px;
    text-align: center;
    line-height: 30px;
    border-radius: 4px;
  }

  /*权重盒子*/
  .documentary-box:first-child {
    margin-top: 15px;
  }

  .documentary-box {
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(108, 157, 209, 0.16);
    border-radius: 10px;
    margin-bottom: 15px;
  }

  .list-top {
    font-size: 15px;
    color: #222222;
    padding-bottom: 12px;
    border-bottom: 1px solid #DDE0E7;
  }

  .list-one {
    padding-top: 16px;
    display: flex;
    color: #222222;
    font-size: 15px;
    line-height: 15px;
  }

  .one-left {
    flex-shrink: 0;
    width: 45px;
    color: #A1A7BA;
    margin-right: 9px;
  }

  .list-textarea {
    padding-top: 13px;
    font-size: 15px;
    color: #A1A7BA;
  }

  .textarea-title {
    padding-top: 13px;
    font-size: 15px;
    color: #222222;
  }

  .list-btn {
    width: 100%;
    height: 40px;
    margin: 23px auto 0;
    background: #409EFF;
    color: #fff;
    font-size: 17px;
    text-align: center;
    line-height: 40px;
    border-radius: 4px;
    border: none;
  }

  /*暂无数据样式*/
  .noData {
    padding: 20px 0;
    text-align: center;
    font-size: 15px;
    color: #A1A7BA;
  }

  /* 附件样式 */
  .file-item {
    display: flex;
    align-items: center;
    width: 303px;
    height: 43px;
    margin-top: 15px;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
  }

  .file-icon {
    display: block;
    flex-shrink: 0;
    width: 15px;
    height: 15px;
    margin-left: 15px;
  }

  .file-name {
    width: 165px;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 11px;
    word-break: break-all;
    color: #222;
    font-size: 15px;
    line-height: 43px;
  }

  .download-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27px;
    height: 27px;
    margin-left: 58px;
    background: #409EFF;
    border-radius: 50%;
  }

  .download-box img {
    display: block;
    width: 13px;
    height: 13px;
  }

  /* ---- 按钮盒子 ---- */
  .btn-box {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
  }


  .box-edit {
    width: 146px;
    height: 38px;
    border: 1px solid #409EFF;
    border-radius: 4px;
    color: #409EFF;
    font-size: 15px;
    text-align: center;
    line-height: 38px;
  }

  .box-del {
    width: 148px;
    height: 40px;
    border-radius: 4px;
    background: #F56C6C;
    color: #fff;
    text-align: center;
    line-height: 40px;
  }
</style>
