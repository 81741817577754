<template>
  <div>
    <!-- S 头部header -->
    <div class="header">
<!--      <div class="header-nav">-->
<!--        <div @click="goBackPage()">-->
<!--          <van-icon name="arrow-left"/>-->
<!--        </div>-->
<!--        <span>分配记录</span>-->
<!--      </div>-->
      <div class="header-page">
        <div class="page-info">
          <span>共</span>
          <span class="page-num">{{total}}</span>
          <span>条/</span>
          <span class="page-num">{{sum_page}}</span>
          <span>页，</span>
          <span>当前第</span>
          <span class="page-num">{{current_page}}</span>
          <span>页</span>
        </div>
        <div class="page-btn">
          <div class="page-input">
            <van-field class="page-jump" v-model="page_num" autocomplete="off" type="digit"/>
          </div>
          <div class="jump-btn" @click="jumpPage()">跳转</div>
        </div>
      </div>
    </div>
    <!-- E 头部header -->

    <!-- S 筛选条件部分 -->
    <div class="screen detail-w" :style="{height: screen_height}">
      <div class="search-term">
        <div class="search-term-label">客户名称</div>
        <div class="search-term-detail">
          <van-field class="vant-input-edit" v-model="customer_name" clearable autocomplete="off" @focus="focusIpt"
                     @blur="blurIpt" placeholder="请输入客户名称"/>
        </div>
      </div>
      <div class="search-term" v-if="enter_visible">
        <div class="search-term-label">录入人</div>
        <div class="search-term-detail" @click="inputPopup">
          <van-field class="vant-input-edit" v-model="input_people" readonly placeholder="请选择"/>
          <div class="down-icon" v-if="clear_input_show">
            <caret-bottom/>
          </div>
          <div class="down-icon" @click.stop="clearableInput" v-else>
            <van-icon name="clear"/>
          </div>
        </div>
      </div>
      <!-- S 筛选展开部分 -->
      <div v-if="screen_more">
        <div class="search-term" v-if="adviser_source_show">
          <div class="search-term-label">顾问</div>
          <div class="search-term-detail" @click="adviserPopup">
            <van-field class="vant-input-edit" v-model="adviser_info" readonly placeholder="请选择"/>
            <div class="down-icon" v-if="clear_adviser_show">
              <caret-bottom/>
            </div>
            <div class="down-icon" @click.stop="clearableAdviser" v-else>
              <van-icon name="clear"/>
            </div>
          </div>
        </div>
        <div class="search-term">
          <div class="search-term-label">分配方式</div>
          <div class="search-term-detail" @click="allotPopup">
            <van-field class="vant-input-edit" v-model="allot_mode" readonly placeholder="请选择"/>
            <div class="down-icon" v-if="clear_allot_show">
              <caret-bottom/>
            </div>
            <div class="down-icon" @click.stop="clearableAllot" v-else>
              <van-icon name="clear"/>
            </div>
          </div>
        </div>
      </div>
      <!-- E 筛选展开部分 -->
      <div class="screen-operation">
        <div class="search-btn" @click="search()">
          <img src="../assets/search.png" alt="">
          搜索
        </div>
        <div class="more-search" @click="moreSearch()">
          <img src="../assets/open_search.png" alt="">
          {{screen_text}}
        </div>
      </div>
    </div>
    <!-- E 筛选条件部分 -->

    <van-list
        v-model:loading="loading"
        :finished="finished"
        :finished-text="no_more"
        :offset="0"
        @load="onLoad"
    >
      <!-- S 列表部分 -->
      <div class="list-data" v-for="item in list_data">
        <div class="list-item">
          <div class="list-title">序号</div>
          <div class="list-info">{{item.id}}</div>
        </div>
        <div class="list-item">
          <div class="list-title">客户名称</div>
          <div class="list-info">{{item.user_name}}</div>
        </div>
        <div class="list-item">
          <div class="list-title">顾问</div>
          <div class="list-info">{{item.adviser}}</div>
        </div>
        <div class="list-item">
          <div class="list-title">录入人</div>
          <div class="list-info">{{item.user}}</div>
        </div>
        <div class="list-item">
          <div class="list-title">分配方式</div>
          <div class="list-info">{{item.type}}</div>
        </div>
        <div class="list-item">
          <div class="list-title">分配时间</div>
          <div class="list-info">{{formatDate(item.create_time)}}</div>
        </div>
      </div>
      <div class="empty-data" v-if="empty_data">暂无数据</div>
      <!-- E 列表部分 -->
    </van-list>

    <!-- S 底部站位盒子 -->
    <div style='height: 61px;'></div>
    <!-- E 底部站位盒子 -->

    <!-- S 录入人弹层 -->
    <van-popup v-model:show="input_people_show" position="bottom" :style="{ height: '48%' }">
      <van-picker
          title="请选择录入人"
          show-toolbar
          :columns="input_people_data"
          @confirm="inputConfirm"
          @cancel="inputCancel"
      />
    </van-popup>
    <!-- E 录入人弹层 -->
    <!-- S 顾问弹层 -->
    <van-popup v-model:show="adviser_show" position="bottom" :style="{ height: '48%' }">
      <van-picker
          title="请选择顾问"
          show-toolbar
          :columns="adviser_data"
          @confirm="adviserConfirm"
          @cancel="adviserCancel"
      />
    </van-popup>
    <!-- E 顾问弹层 -->

    <!-- S 分配方式弹层 -->
    <van-popup v-model:show="allot_show" position="bottom" :style="{ height: '48%' }">
      <van-picker
          title="分配方式"
          show-toolbar
          :columns="allot_data"
          @confirm="allotConfirm"
          @cancel="allotCancel"
      />
    </van-popup>
    <!-- E 分配方式弹层 -->
  </div>
  <!-- S 底部导航栏 -->
  <div class="occupy-box"></div>
  <van-tabbar v-model="active" v-if="bottom_nav_visible">
    <van-tabbar-item replace to="/index">
      <span>首页</span>
      <template #icon="props">
        <div class="navIcon" v-bind:class="{ active: props.active}">
          <el-icon>
            <home-filled/>
          </el-icon>
        </div>
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/customManage">
      <span>客户管理</span>
      <template #icon="props">
        <div class="navIcon" v-bind:class="{ active: props.active}">
          <el-icon>
            <user/>
          </el-icon>
        </div>
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/signedResources">
      <span>已签约</span>
      <template #icon="props">
        <div class="navIcon" v-bind:class="{ active: props.active}">
          <el-icon>
            <edit/>
          </el-icon>
        </div>
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/sharedPool">
      <span>共享池</span>
      <template #icon="props">
        <div class="navIcon" v-bind:class="{ active: props.active}">
          <el-icon>
            <monitor/>
          </el-icon>
        </div>
      </template>
    </van-tabbar-item>
  </van-tabbar>
  <!-- S 底部导航栏 -->
</template>

<script>
  import {getAllocation, gerAllRecord, getAdviserList, getSharedPool} from '../api'
  import {Notify} from 'vant';
  import moment from "moment";
  import {ref} from "vue";

  export default {
    data() {
      return {
        adviser_source_show: true, // 顾问权限判断
        enter_visible: true, // 运营权限判断

        /* ---- 监听软键盘是否弹出 ---- */
        defaultHeight: '0',  //默认屏幕高度
        nowHeight: '0',  //实时屏幕高度
        bottom_nav_visible: true, // 底部导航显示与隐藏
        iosStates: false, // 手机系统

        /* ---- 触底加载 ---- */
        loading: false,
        finished: false,
        searchStates: false,  // 不走 onload

        /*---- 下拉重置 ----*/
        clear_input_show: true, // 录入人重置按钮
        clear_adviser_show: true, // 顾问重置按钮
        clear_allot_show: true, // 分配方式重置按钮

        page_num: '0', // 去第几页
        current_page: '1', // 当前第几页
        total: '0', // 总条数
        sum_page: '0', // 总页数
        screen_height: '', // 展开搜索面板高度
        screen_text: '更多筛选条件', // 更多筛选条件文字切换
        screen_switch: false, // 更多筛选条件文字切换开关
        screen_more: false,
        customer_name: '', // 客户名称

        /* ---- 录入人部分 ---- */
        input_people_show: false, // 客户等级弹出层
        input_people: '', // 标签
        input_people_id: '', // 录入人id
        input_people_data: [], // vant下拉录入人数据结构
        request_people_data: [], // 接口返回录入人数据

        /* ---- 跟单顾问部分 ---- */
        adviser_show: false, // 顾问弹出层
        adviser_info: '', // 跟单顾问
        adviser_id: '', // 顾问id
        adviser_data: [], // vant下拉顾问数据结构
        request_adviser_data: [], // 请求回来的顾问数据

        /* ---- 分配方式 ---- */
        allot_show: false, // 分配方式弹出层
        allot_mode: '', // 分配方式
        allot_id: '', // 分配方式id
        allot_data: ['系统分配', '抢单', '直属'],
        get_allot_data: [
          {id: 0, name: '系统分配'},
          {id: 1, name: '抢单'},
          {id: 2, name: '直属'},
        ],

        /* ---- 列表数据 ---- */
        list_data: [],
        empty_data: false, // 暂无数据
        no_more: '没有更多了'
      }
    },

    // 生命周期 初始化
    setup() {
      const active = ref(''); // 设定选定第几个
      return {
        active,
      };
    },

    created() {
      // 顾问来演不可见
      if (sessionStorage.getItem('postID') === '2' || sessionStorage.getItem('postID') === '7') {
        this.adviser_source_show = false
      }

      // 运营分配记录录入人不可见
      if (sessionStorage.getItem('postID') === '1') {
        this.enter_visible = false
      }

      this.getInputPeople() // 录入人数据
      this.getAdviserList() // 顾问数据
      this.searchStates = true
      this.getList() // 列表数据
      this.iosStates = sessionStorage.getItem("iosStates")
    },

    // 获取屏幕默认高度与实时高度
    mounted: function () {
      //获取默认高度
      this.defaultHeight = document.documentElement.clientHeight
      window.onresize = () => {
        return (() => {
          //实时屏幕高度
          this.nowHeight = document.documentElement.clientHeight
        })();
      };
    },

    // 监听软键盘是否弹出
    watch: {
      nowHeight: function () {
        if (this.defaultHeight !== this.nowHeight) {
          this.bottom_nav_visible = false
        } else {
          this.bottom_nav_visible = true
        }
      }
    },

    methods: {
      // 列表数据
      onLoad(val) {
        if (this.searchStates == false) {
          if (!this.page_num) {
            this.page_num = 1
          }
          let para = {
            page: parseInt(this.page_num) + 1, // 页码
            limit: 10, // 每页多少条
            userId: this.input_people_id, // 录入人id
            saleId: this.adviser_id, // 顾问id
            type: this.allot_id, // 分配类型
            user: this.customer_name // 客户名称
          }

          getAllocation(para).then(data => {
            if (data.code === 200) {
              // 暂无数据
              if (data.data.count === 0) {
                this.empty_data = true
                this.no_more = ''
              } else {
                this.empty_data = false
                this.no_more = '没有更多了'
              }
              this.total = data.data.count
              if (parseInt(data.data.count % 10) === 0) {
                this.sum_page = parseInt(data.data.count / 10)
              } else {
                this.sum_page = parseInt(data.data.count / 10) + 1
              }
              this.loading = false;
              // 数据全部加载完成
              if (data.data.data.length === 0) {
                this.finished = true; // 停止加载
                return
              }
              this.page_num++
              this.current_page = para.page
              this.list_data = this.list_data.concat(data.data.data) // 数组拼接
              if (val) {
                Notify({type: 'success', message: '搜索成功', duration: 1000});
                this.finished = false
              }
            } else {
              Notify({type: 'danger', message: data.message});
            }
          }).catch(() => {
            Notify({type: 'danger', message: '服务连接失败'});
          })
        }
      },

      // 时间戳转化(年-月-日 时:分:秒)
      formatDate(time) {
        return moment(time * 1000).format("YYYY-MM-DD HH:mm:ss")
      },

      // 列表数据
      getList(val) {
        this.finished = false; // 停止加载
        this.searchStates = true
        let para = {
          page: parseInt(this.page_num) + 1, // 页码
          limit: 10, // 每页多少条
          userId: this.input_people_id, // 录入人id
          saleId: this.adviser_id, // 顾问id
          type: this.allot_id, // 分配类型
          user: this.customer_name // 客户名称
        }
        this.current_page = para.page
        getAllocation(para).then(data => {
          if (data.code === 200) {
            if (data.data.count === 0) {
              Notify({type: 'success', message: '搜索成功', duration: 1000});
            }
            // 暂无数据
            if (data.data.count === 0) {
              this.empty_data = true
              this.no_more = ''
            } else {
              this.empty_data = false
              this.no_more = '没有更多了'
            }
            this.total = data.data.count
            if (parseInt(data.data.count % 10) === 0) {
              this.sum_page = parseInt(data.data.count / 10)
            } else {
              this.sum_page = parseInt(data.data.count / 10) + 1
            }
            this.loading = false;
            this.searchStates = false
            // 数据全部加载完成
            if (data.data.data.length === 0) {
              this.finished = true; // 停止加载
              return
            }
            this.page_num++
            this.list_data = data.data.data
            if (val) {
              Notify({type: 'success', message: '搜索成功', duration: 1000});
              this.finished = false
            }
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 录入人数据
      getInputPeople() {
        gerAllRecord().then(data => {
          if (data.code === 200) {
            this.request_people_data = data.data
            for (let i = 0; i < this.request_people_data.length; i++) {
              this.input_people_data.push(this.request_people_data[i].user_name)
            }
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 顾问数据
      getAdviserList() {
        getAdviserList().then(data => {
          // 判断token
          if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403) {
            Notify({type: 'danger', message: data.message});
            sessionStorage.clear()
            this.$router.push({
              path: '/login'
            })
            return
          }

          if (data.code === 200) {
            this.request_adviser_data = data.data
            for (let i = 0; i < this.request_adviser_data.length; i++) {
              this.adviser_data.push(this.request_adviser_data[i].user_name)
            }
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 返回上一页
      goBackPage() {
        this.$router.go(-1)
      },

      // 跳转按钮
      jumpPage() {
        if (!this.page_num) {
          Notify('请输入页码')
          return false
        }

        if (this.page_num > this.sum_page) {
          this.page_num = this.sum_page
        }

        if (this.page_num < 1) {
          this.page_num = '1'
        }

        this.page_num = this.page_num - 1
        this.current_page = this.page_num
        this.list_data = []
        this.getList()
      },

      // 点击搜索
      search() {
        this.searchStates = false
        let search_tips = true // 点击搜索反馈信息
        this.current_page = '1'
        this.page_num = '0'
        this.list_data = []
        this.getList(search_tips)
      },

      // 更多筛选条件
      moreSearch() {
        this.screen_switch = !this.screen_switch
        if (this.screen_switch) {
          if (sessionStorage.getItem('postID') === '2' || sessionStorage.getItem('postID') === '1' || sessionStorage.getItem('postID') === '7') {
            this.screen_height = 320 + 'px'
          } else {
            this.screen_height = 405 + 'px'
          }
          this.screen_text = '收起'
          this.screen_more = true
        } else {
          this.screen_height = ''
          this.screen_text = '更多筛选条件'
          this.screen_more = false
        }
      },

      /**
       * 录入人(搜索部分)
       * */
      // 录入人弹层控制
      inputPopup() {
        this.input_people_show = true
      },

      // 确认赋值
      inputConfirm(value) {
        this.input_people = value // 录入人赋值
        for (let i = 0; i < this.request_people_data.length; i++) {
          if (value === this.request_people_data[i].user_name) {
            this.input_people_id = this.request_people_data[i].id
          }
        }
        this.input_people_show = false // 关闭弹层
        this.clear_input_show = false // 切换重置图标
      },

      // 取消选择
      inputCancel() {
        this.input_people_show = false // 关闭弹层
      },

      /**
       * 跟单顾问(搜索部分)
       * */
      // 跟单顾问弹层控制
      adviserPopup() {
        this.adviser_show = true
      },

      // 确认赋值
      adviserConfirm(value, index) {
        this.adviser_info = value // 顾问赋值
        for (let i = 0; i < this.request_adviser_data.length; i++) {
          if (value === this.request_adviser_data[i].user_name) {
            this.adviser_id = this.request_adviser_data[i].id
          }
        }
        this.adviser_show = false // 关闭弹层
        this.clear_adviser_show = false // 切换重置图标
      },

      // 取消选择
      adviserCancel() {
        this.adviser_show = false // 关闭弹层
      },

      /**
       * 分配方式部分
       * */
      allotPopup() {
        this.allot_show = true
      },

      // 确认赋值
      allotConfirm(value) {
        this.allot_mode = value // 分配方式赋值
        for (let i = 0; i < this.get_allot_data.length; i++) {
          if (value === this.get_allot_data[i].name) {
            this.allot_id = this.get_allot_data[i].id
          }
        }
        this.allot_show = false // 关闭弹层
        this.clear_allot_show = false // 切换重置图标
      },

      // 取消选择
      allotCancel() {
        this.allot_show = false // 关闭弹层
      },

      /**
       * 操作按钮部分
       * */
      // 跳转详情页
      toDetail(id) {
        // 跳转传id
        this.$router.push({
          path: '/customer_detail',
          query: {
            id: id
          }
        })
      },

      /**
       * 重置下拉按钮
       * */
      // 录入人重置
      clearableInput() {
        this.input_people = '' // 清空回显
        this.input_people_id = '' // 清空请求时参数
        this.clear_input_show = true // 切换重置图标
      },

      // 顾问重置
      clearableAdviser() {
        this.adviser_info = '' // 清空回显
        this.adviser_id = '' // 清空请求时参数
        this.clear_adviser_show = true // 切换重置图标
      },

      // 分配方式重置
      clearableAllot() {
        this.allot_mode = '' // 清空回显
        this.allot_id = '' // 清空请求时参数
        this.clear_allot_show = true // 切换重置图标
      },

      // 苹果键盘事件
      blurIpt() {
        if (this.iosStates) {
          this.bottom_nav_visible = true
          return
        }
      },
      // 苹果键盘事件
      focusIpt() {
        if (this.iosStates) {
          this.bottom_nav_visible = false
          return
        }
      },
    }
  }
</script>

<style scoped>
  /* ---- header区域 ---- */
  .header {
    position: sticky;
    top: 0;
    height: 53px;
    background: #fff;
    box-shadow: 0 9px 16px rgba(25, 52, 139, 0.06);
    z-index: 9;
  }

  .header-nav {
    position: relative;
    height: 53px;
    border-bottom: 1px solid #F5F5FC;
    color: #222;
    font-size: 19px;
    text-align: center;
    line-height: 53px;
  }

  .header-nav div {
    position: absolute;
    left: 10px;
    width: 19px;
    height: 16px;
  }

  .header-page {
    display: flex;
    justify-content: space-between;
    height: 53px;
    padding: 0 15px;
  }

  .page-info {
    color: #A1A7BA;
    font-size: 15px;
    line-height: 53px;
  }

  .page-num {
    padding: 0 2px;
    color: #222;
  }

  .page-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .page-input {
    width: 43px;
    height: 28px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
  }

  .page-btn input {
    padding: 0;
    width: 43px;
    height: 28px;
    border: none;
    color: #A1A7BA;
    font-size: 15px;
    text-align: center;
  }

  .jump-btn {
    width: 49px;
    height: 30px;
    margin-left: 7px;
    background: #409EFF;
    border-radius: 4px;
    color: #fff;
    font-size: 15px;
    text-align: center;
    line-height: 30px;
  }

  .page-jump {
    padding: 0;
  }
    .page-jump >>> input {
        height: 30px;
        color: #A1A7BA;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
    }

  /* ---- 筛选部分 ---- */
  .detail-w {
    width: 345px;
    margin: 0 auto;
  }

  .screen {
    position: relative;
    /*height: 270px;*/
    margin-top: 15px;
    padding-bottom: 25px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
  }

  .search-term {
    padding-top: 15px;
  }

  .search-term-label {
    padding: 0 20px;
    color: #A1A7BA;
    font-size: 15px;
  }

  .search-term-detail {
    position: relative;
    width: 303px;
    height: 38px;
    margin: 10px auto 0;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
  }

  .down-icon {
    position: absolute;
    top: 0;
    right: 15px;
    width: 12px;
    height: 100%;
    color: #606266;
  }

  .down-icon svg {
    position: relative;
    top: 11px;
  }

  .down-icon i {
    position: relative;
    top: 11px;
    left: -2px;
    color: #C9C7C8;
  }

  .vant-input-edit {
    height: 38px !important;
    line-height: 38px !important;
    padding: 0 10px !important;
    color: #222 !important;
    font-size: 15px !important;
  }

  .vant-input-edit >>> input::-webkit-input-placeholder {
    color: #606266 !important;
  }

  .term-detail-contact {
    display: flex;
    justify-content: space-between;
    width: 305px;
    margin: 10px auto 0;
  }

  .contact-down {
    position: relative;
    width: 85px;
    height: 38px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    line-height: 38px;
  }

  .contact-icon {
    top: 0;
    right: 7px;
  }

  .contact-detail {
    width: 205px;
    height: 38px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
  }

  .screen-operation {
    display: flex;
    justify-content: space-between;
    /*position: absolute;*/
    /*bottom: 25px;*/
    /*left: 20px;*/
    width: 305px;
    margin: 25px auto 0;
  }

  .search-btn {
    width: 147px;
    height: 40px;
    background: #409EFF;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 15px;
  }

  .more-search {
    width: 145px;
    height: 38px;
    border: 1px solid #409EFF;
    border-radius: 4px;
    text-align: center;
    line-height: 38px;
    color: #409EFF;
    font-size: 15px;
  }

  .default-text {
    padding-left: 10px;
    color: #606266;
    font-size: 15px;
  }

  /* ---- 列表部分 ---- */
  .list-data {
    width: 305px;
    margin: 15px auto;
    padding: 17px 20px;
    background: #fff;
    font-size: 15px;
    border-radius: 10px;
    box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
  }

  .list-item {
    display: flex;
    margin-bottom: 13px;
  }

  .list-item:last-child {
    margin-bottom: 0;
  }

  .list-title {
    color: #A1A7BA;
    margin-right: 9px;
    line-height: 20px;
  }

  .list-info {
    color: #222;
    line-height: 20px;
  }
</style>
