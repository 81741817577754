<template>

    <!-- S 数据列表 -->
    <div class="w">
        <!-- S 列表部分 -->
        <div class="list-wrap" v-for="item in list">
            <div class="list-item detail-w">
                <div class="list-top">
                    <div class="list-top-num">
                        <span class="list-label">序号</span>
                        <span>{{item.id}}</span>
                    </div>
                    <div class="list-top-num">
                        <span>{{formatDate(item.create_time)}}</span>
                    </div>
                </div>
                <div class="list-info">
                    <div class="info-label">合同路径</div>
                    <div class="url-content">{{strFunc(item.contract)}}</div>
                </div>
                <div class="list-info">
                    <div class="info-label">合同备注</div>
                    <div>{{item.contract_content}}</div>
                </div>
                <div class="list-info">
                    <div class="info-label" style="width: 95px">付款证明路径</div>
                    <div class="url-content">{{strFunc(item.proof_of_payment)}}</div>
                </div>
                <div class="list-info">
                    <div class="info-label"  style="width: 95px">付款证明备注</div>
                    <div>{{item.proof_of_payment_content}}</div>
                </div>
                <div class="list-info">
                    <div class="info-label">操作人</div>
                    <div class="url-content">{{item.adviser_name}}</div>
                </div>
                <div class="list-info one-line">
                    <div class="list-info-item">
                        <div class="info-label">运营审批</div>
                        <div class="one-line-info">
                            <span v-if="item.operations_status === 0" style="color: #F56C6C; font-size: 15px;">未审核</span>
                            <span v-if="item.operations_status === 1" style="color: #409EFF; font-size: 15px;">已审核</span>
                        </div>
                    </div>
                    <div class="list-info-item" style="margin-left: 63px">
                        <div class="info-label">文案审批</div>
                        <div class="one-line-info">
                            <span v-if="item.copyright_status === 0" style="color: #F56C6C; font-size: 15px;">未审核</span>
                            <span v-if="item.copyright_status === 1" style="color: #409EFF; font-size: 15px;">已审核</span>
                        </div>
                    </div>
                </div>
                <div class="operate-info">
                    <div class="operate-center" @click="down(item)">下载</div>
                </div>
            </div>
        </div>
        <div class="empty-data" v-if="dataStates">暂无数据</div>
        <!-- E 列表部分 -->
    </div>
    <!-- E 数据列表 -->
    <!-- S 底部导航栏 -->
    <div class="occupy-box"></div>
    <!-- S 两个底部导航栏 -->
    <div v-if="twoStates">
        <van-tabbar v-model="active">
            <van-tabbar-item replace to="/index">
                <span>首页</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <home-filled/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/customSchedule">
                <span>客户列表</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <PieChart/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
    <!-- S 两个底部导航栏 -->
    <!-- S 三个底部导航栏 -->
    <div v-if="threeStates">
        <van-tabbar v-model="active">
            <van-tabbar-item replace to="/index">
                <span>首页</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <home-filled/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/approve">
                <span>交接审批</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <Coordinate/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/customSchedule">
                <span>客户列表</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <PieChart/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
    <!-- S 三个底部导航栏 -->

    <!-- S 四个底部导航栏 -->
    <div v-if="fourStates">
        <van-tabbar v-model="active">
            <van-tabbar-item replace to="/index">
                <span>首页</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <home-filled/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/customManage">
                <span>客户管理</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <user/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/signedResources">
                <span>已签约</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <edit/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/sharedPool">
                <span>共享池</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <monitor/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
    <!-- S 四个底部导航栏 -->
</template>

<script>
    import {approvalStatus} from '../api'
    import {Dialog, Notify} from "vant";
    import {ref} from "vue";
    import moment from "moment";

    export default {
        name: "documentary",
        data() {
            return {
                list: [], // 列表数据
                file_list: [], // 附件数据
                custom_id: '', // 客户id
                dataStates: true, // 暂无数据开关

                threeStates: false,//三底部栏
                twoStates: false,//三底部栏
                fourStates: false,//四底部栏
                postId: "" // 权限
            }
        },

        // 生命周期 初始化
        setup() {
            const active = ref(''); // 设定选定第几个
            return {
                active,
            };
        },

        created() {
            this.custom_id = this.$route.query.id // 获取客户id
            window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
            this.reset() // 请求列表数据
            // 星迪修改（底部导航栏）
            this.postId = sessionStorage.getItem("postID")
            if (this.postId === '6' || this.postId === '5') { // 判断 文案 或者 运营经理
                this.threeStates = false // 不显示两个个底部
                this.fourStates = false// 不显示四个底部
                this.twoStates = true// 不显示三个底部
                this.active = null   //
            }
            if (this.postId === '3' || this.postId === '4') { // 判断 运营主管 或者 文案主管
                this.threeStates = true // 显示两个个底部
                this.twoStates = false// 不显示三个底部
                this.fourStates = false// 不显示四个底部
                this.active = null
            }
            if (this.postId === '0') { // 判断 管理员
                this.threeStates = false // 显示两个个底部
                this.twoStates = false// 不显示三个底部
                this.fourStates = true// 不显示四个底部
                this.active = null
            }
        },
        methods: {
            // 时间戳转化(年-月-日 时:分:秒)
            formatDate(time) {
                return moment(time * 1000).format("YYYY-MM-DD HH:mm:ss")
            },

            // 字符串截取
            strFunc(name_str) {
                let file_name = name_str.lastIndexOf("\/");
                name_str = name_str .substring(file_name + 1, name_str .length);
                return name_str
            },

            // 指定区域接取字符串
            strAccurate(name_str) {
                let file_name = name_str.lastIndexOf("\/");
                name_str = name_str.substring(file_name + 1, name_str.length);
                return name_str
            },
            // 下载
            down(item) {
                this.$router.push({
                    path: '/contractDown',
                    query: {
                        data: encodeURIComponent(JSON.stringify(item)), // vue路由传递对象
                    }
                })
            },


            //初始化数据请求函数
            reset() {
                let para = {
                    id: this.$route.query.id
                }
                approvalStatus(para).then(data => {
                    if (data.code === 200) {
                        if (data.data.length == 0) {
                            this.dataStates = true
                            this.list=[]
                        } else {
                            this.dataStates = false
                            this.list = data.data
                        }
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
        },
    }
</script>

<style scoped>
    .header {
        position: fixed;
        top: 0;
        width: 100%;
    }

    /* ---- 列表数据 ---- */
    .list-wrap {
        margin: 15px 0;
    }

    .list-item {
        padding-bottom: 20px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
    }

    .list-top {
        display: flex;
        justify-content: space-between;
        width: 305px;
        height: 45px;
        margin: 0 auto 16px;
        border-bottom: 1px solid #DDE0E7;
    }

    .list-top-num {
        padding-top: 17px;
        color: #222;
        font-size: 15px;
    }

    .list-label {
        padding-right: 8px;
        color: #A1A7BA;
    }

    .list-info {
        display: flex;
        width: 305px;
        margin: 13px auto 0;
        color: #222;
        font-size: 15px;
        line-height: 20px;
    }

    .info-label {
        flex-shrink: 0;
        width: 60px;
        margin-right: 10px;
        color: #A1A7BA;
    }

    .list-info-item {
        display: flex;
    }

    .one-line-info {
        width: 50px;
    }

    .operate-info {
        display: flex;
        justify-content: space-between;
        width: 305px;
        height: 40px;
        margin: 23px auto 0;
    }

    .both-sides {
        width: 93px;
        height: 38px;
        border: 1px solid #409EFF;
        border-radius: 4px;
        color: #409EFF;
        font-size: 15px;
        text-align: center;
        line-height: 38px;
    }

    .operate-center {
        width: 305px;
        height: 40px;
        background: #409EFF;
        border-radius: 4px;
        color: #fff;
        font-size: 15px;
        text-align: center;
        line-height: 40px;
    }

    .url-content {
        width: 305px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>
