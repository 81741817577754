<template>
    <!-- S 头部header -->
    <!--    <div class="header">-->
    <!--        <div class="header-nav">-->
    <!--            <div class="header-nav-back" @click="goBackPage()">-->
    <!--                <van-icon name="arrow-left"/>-->
    <!--            </div>-->
    <!--            <span>转案单详情</span>-->
    <!--        </div>-->
    <!--    </div>-->
    <!-- E 头部header -->
    <!-- S 个人信息 -->
    <div class="person-top">
        <div class="person-top-blue">
            <div class="blue-line"></div>
            <div class="blue-text">个人信息</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">转案单ID</div>
            <div class="top-item-text">{{addForm.id}}</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">主申请人姓名</div>
            <div class="top-item-text">{{addForm.customer_name}}</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">副申请人姓名</div>
            <div class="top-item-text" v-if="addForm.deputy_applicant!==''">{{addForm.deputy_applicant}}</div>
            <div class="top-item-text" v-else>-</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">配偶姓名</div>
            <div class="top-item-text" v-if="addForm.spouse_name!==null">{{addForm.spouse_name}}</div>
            <div class="top-item-text" v-else>-</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">客户身份证号</div>
            <div class="top-item-text">{{addForm.customer_card}}</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">客户手机号</div>
            <div class="top-item-text">{{addForm.customer_phone}}</div>
        </div>

        <div class="top-item">
            <div class="top-item-label">销售顾问</div>
            <div class="top-item-text">{{addForm.sales_consultant}}</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">销售组长</div>
            <div class="top-item-text" v-if="addForm.ebay_amazon!==null">{{addForm.sales_consultant}}</div>
            <div class="top-item-text" v-else>-</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">是否为特批标准客户</div>
            <div class="top-item-text">
                <span v-if="addForm.standard_customer === 0">不是</span>
                <span v-if="addForm.standard_customer === 1">是</span>
                <span v-if="addForm.standard_customer === null">-</span>
            </div>
        </div>
        <div class="top-item">
            <div class="top-item-label">最晚递交时间</div>
            <div class="top-item-text" v-if="addForm.latest_time!==null">{{formatDate2(addForm.latest_time)}}</div>
            <div class="top-item-text" v-else>-</div>
        </div>
    </div>
    <!-- E 个人信息 -->
    <!-- S 家庭特殊情况 -->
    <div class="person-top">
        <div class="person-top-blue">
            <div class="blue-line"></div>
            <div class="blue-text">家庭特殊情况</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">家庭人数</div>
            <div class="top-item-text" v-if="addForm.number_of_families!==null">{{addForm.number_of_families}}</div>
            <div class="top-item-text" v-else>-</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">家庭成员</div>
            <div class="top-item-text" v-if="addForm.member_family!==''">{{addForm.member_family}}</div>
            <div class="top-item-text" v-else>-</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">申请人数</div>
            <div class="top-item-text" v-if="addForm.number_of_application!==null">{{addForm.number_of_application}}
            </div>
            <div class="top-item-text" v-else>-</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">敏感职业</div>
            <div class="top-item-text" v-if="addForm.sensitive_occupation!==''">{{addForm.sensitive_occupation}}</div>
            <div class="top-item-text" v-else>-</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">其他</div>
            <div class="top-item-text" v-if="addForm.other_content!==''">{{addForm.other_content}}</div>
            <div class="top-item-text" v-else>-</div>
        </div>
    </div>
    <!-- E 家庭特殊情况 -->
    <!-- S 合同信息 -->
    <div class="person-top">
        <div class="person-top-blue">
            <div class="blue-line"></div>
            <div class="blue-text">合同信息</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">签约项目</div>
            <div class="top-item-text">{{addForm.project_name}}</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">创建时间</div>
            <div class="top-item-text">{{formatDate(addForm.create_time)}}</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">递交时间（单位：月）</div>
            <div class="top-item-text" v-if="addForm.submission_time!==null">{{addForm.submission_time}}</div>
            <div class="top-item-text" v-else>-</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">转案时间</div>
            <div class="top-item-text" v-if="addForm.transfer_time!==null">{{formatDate(addForm.transfer_time)}}</div>
            <div class="top-item-text" v-else>-</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">客户来源</div>
            <div class="top-item-text" v-if="addForm.customer_source!==''">{{addForm.customer_source}}</div>
            <div class="top-item-text" v-else>-</div>
        </div>


        <div class="top-item">
            <div class="top-item-label">能否找到至少3名推荐人</div>
            <div class="top-item-text" v-if="addForm.background_one!==''">{{addForm.background_one}}</div>
            <div class="top-item-text" v-else>-</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">能否找到至少2家合作公司</div>
            <div class="top-item-text" v-if="addForm.background_two!==''">{{addForm.background_two}}</div>
            <div class="top-item-text" v-else>-</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">能否对接三方渠道</div>
            <div class="top-item-text" v-if="addForm.background_three!==''">{{addForm.background_three}}</div>
            <div class="top-item-text" v-else>-</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">是否有约定三方费用范围</div>
            <div class="top-item-text" v-if="addForm.background_four!==''">{{addForm.background_four}}</div>
            <div class="top-item-text" v-else>-</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">合同金额</div>
            <div class="top-item-text" v-if="addForm.contract_amount!==''">{{addForm.contract_amount}}</div>
            <div class="top-item-text" v-else>-</div>
        </div>

        <div class="top-item">
            <div class="top-item-label">已付款项</div>
            <div class="top-item-text" v-if="addForm.paid_items!==''">{{addForm.paid_items}}</div>
            <div class="top-item-text" v-else>-</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">合同编号</div>
            <div class="top-item-text" v-if="addForm.contract_no!==''">{{addForm.contract_no}}</div>
            <div class="top-item-text" v-else>-</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">合同条款修改内容</div>
            <div class="top-item-text" v-if="addForm.contract_update_content!==''">{{addForm.contract_update_content}}
            </div>
            <div class="top-item-text" v-else>-</div>
        </div>
        <div class="top-item">
            <div class="top-item-label">付款流程</div>
            <div class="top-item-text" v-if="addForm.payment_process!==''">{{addForm.payment_process}}</div>
            <div class="top-item-text" v-else>-</div>
        </div>
    </div>
    <!-- E 合同信息 -->
</template>

<script>
    import {Dialog, Notify, ImagePreview} from "vant";
    import {ref} from "vue";
    import {userCodeList, referralInfo, getAdviserList} from "../api/java";
    import moment from "moment";

    export default {
        name: "personal",
        data() {
            return {
                addForm: {
                    id: '',//转案单
                    customer_name: '',//主申请人姓名
                    request_adviser_data: [], // 接口返回顾问数据
                }
            }
        },
        // 生命周期 初始化
        setup() {
            const active = ref(''); // 设定选定第几个
            return {
                active,
            };
        },

        created() {
            this.getAdviserData()
            this.getEditInfo()
        },
        methods: {

            // 获取顾问列表
            getAdviserData() {
                getAdviserList().then(data => {
                    // 判断token
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403 || data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        this.request_adviser_data = []
                        this.request_adviser_data = data.data

                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            // 时间戳转化(年-月-日 时:分:秒)
            formatDate(time) {
                return moment(time * 1000).format("YYYY-MM-DD HH:mm:ss")
            },
            // 时间戳转化(年-月-日)
            formatDate2(time) {
                return moment(time * 1000).format("YYYY-MM-DD")
            },
            //获取转案单详情
            getEditInfo() {
                let para = {
                    id: this.$route.query.id
                }
                referralInfo(para).then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403 || data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        this.addForm = data.data
                        this.request_adviser_data.forEach((item) => {
                            if (item.id == this.addForm.sales_consultant) {
                                this.addForm.sales_consultant = item.user_name
                            }
                            if (item.id == this.addForm.ebay_amazon) {
                                this.addForm.ebay_amazon = item.user_name
                            }
                        })

                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })
            },
            // 返回上一页
            goBackPage() {
                this.$router.go(-1)
            },
        }
    }
</script>

<style scoped>
    .person-top {
        background: #FFFFFF;
        padding: 16px;
        margin-bottom: 15px;
    }

    .person-top-blue {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    .blue-line {
        width: 2px;
        height: 14px;
        background: #409EFF;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
    }

    .blue-text {
        margin-left: 5px;
        font-size: 16px;
        color: #333333;
        line-height: 0px;
    }

    .top-item {
        display: flex;
        margin-top: 10px;
        align-items: center;
        margin-bottom: 12px;
    }

    .top-item:last-child {
        margin-bottom: 0;
    }

    .top-item-label {
        flex-shrink: 0;
        font-size: 15px;
        color: #A1A7BA;
    }

    .top-item-text {
        margin-left: 10px;
        font-size: 15px;
        color: #222222;
    }


</style>