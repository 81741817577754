·   · <template>
  <div>
    <div class="add-wrap">
      <!-- S input必填模块 -->
      <div class="info-box">
        <div class="search-term-label">标签<span class="required-icon">*</span></div>
        <div class="search-term-detail" @click="adviserPopup">
          <van-field class="vant-input-edit" v-model="often_tag_info" clearable readonly placeholder="请选择"/>
          <div class="down-icon" v-if="clear_adviser_show">
            <caret-bottom/>
          </div>
          <div class="down-icon" @click.stop="clearableAdviser" v-else>
            <van-icon name="clear"/>
          </div>
        </div>
      </div>

      <!-- E input必填模块 -->
      <!-- S input必填模块 -->
      <div class="info-box">
        <div class="info-title">
          <span>名称</span>
          <span class="required-icon">*</span>
        </div>
        <div class="info-input">
          <van-field class="vant-input-edit" v-model="cost_content" autocomplete="off" clearable
                     @focus="focusIpt" @blur="blurIpt" placeholder="请输入名称..."/>
        </div>
      </div>
      <!-- E input必填模块 -->

      <!-- S 文本域模块 -->
      <div class="info-box">
        <div class="info-title">
          <span>备注</span>
        </div>
        <div class="info-input info-textarea">
          <van-field
              @focus="focusIpt"
              @blur="blurIpt"
              class="info-textarea-edit"
              v-model="cost_remark"
              autocomplete="off"
              rows="1"
              autosize
              type="textarea"
              placeholder="请输入备注..."
          />
        </div>
      </div>
      <!-- E 文本域模块 -->

      <!-- S 文件上传模块 -->
      <div class="info-box">
        <div class="upload-title">附件</div>
        <div class="upload-list" v-for="(item, i) in file_data" :data-id="item.id">
          <div class="upload-index">
            <div class="upload-left">
              <img src="../assets/upload-img.png" alt="">
              <div class="upload-name">{{item.name}}</div>
            </div>
            <div class="upload-del" @click="fileDel(item)">
              <img src="../assets/upload-del.png" alt="">
            </div>
          </div>
        </div>
        <van-uploader :after-read="beforeRead" accept="">
          <div class="upload-btn">点击上传附件</div>
        </van-uploader>
      </div>
      <!-- E 文件上传模块 -->
      <div class="remarks-submit" @click="doSubmit()">提交</div>
    </div>

    <!-- S 底部导航栏 -->
    <div class="occupy-box"></div>
    <!-- S 两个底部导航栏 -->
    <div v-if="twoStates">
      <van-tabbar v-model="active" v-if="bottom_nav_visible">
        <van-tabbar-item replace to="/index">
          <span>首页</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <home-filled/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/customSchedule">
          <span>客户列表</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <PieChart/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
    <!-- S 两个底部导航栏 -->
    <!-- S 三个底部导航栏 -->
    <div v-if="threeStates">
      <van-tabbar v-model="active" v-if="bottom_nav_visible">
        <van-tabbar-item replace to="/index">
          <span>首页</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <home-filled/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/approve">
          <span>交接审批</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <Coordinate/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/customSchedule">
          <span>客户列表</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <PieChart/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
    <!-- S 三个底部导航栏 -->

    <!-- S 四个底部导航栏 -->
    <div v-if="fourStates">
      <van-tabbar v-model="active" v-if="bottom_nav_visible">
        <van-tabbar-item replace to="/index">
          <span>首页</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <home-filled/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/customManage">
          <span>客户管理</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <user/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/signedResources">
          <span>已签约</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <edit/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/sharedPool">
          <span>共享池</span>
          <template #icon="props">
            <div class="navIcon" v-bind:class="{ active: props.active}">
              <el-icon>
                <monitor/>
              </el-icon>
            </div>
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
    <!-- S 四个底部导航栏 -->

    <!-- S 顾问弹层 -->
    <van-popup v-model:show="often_show" position="bottom" :style="{ height: '48%' }">
      <van-picker
          title="请选择标签"
          show-toolbar
          :columns="often_tag_data"
          @confirm="adviserConfirm"
          @cancel="adviserCancel"
      />
    </van-popup>
    <!-- E 顾问弹层 -->
  </div>
</template>

<script>
  import {handoverImg, savePublicData, oftenTagData} from '../api'
  import {Dialog, Notify} from 'vant';
  import {ref} from "vue";

  export default {
    data() {
      return {
        customer_id: '', // 客户id
        cost_content: '', // 费用内容
        cost_remark: '', // 备注
        file_data: [], // pdf 上传路径集合数据
        edit_status: '', // 修改标识
        edit_id: '', // 修改数据id

        /* ---- 费用标签部分 ---- */
        often_show: false, // 费用标签弹出层
        often_tag_info: '', // 费用标签
        request_often_data: [], // 接口返回费用标签数据
        often_tag_data: [], // vant 下拉数据形式

        /*---- 下拉重置 ----*/
        clear_adviser_show: true, // 费用标签重置按钮

        bottom_nav_visible: true, // 底部导航显示与隐藏
        iosStates:false , // 手机系统

        threeStates: false,//三底部栏
        twoStates: false,//三底部栏
        fourStates: false,//四底部栏
        postId: "" // 权限
      }
    },

    // 生命周期 初始化
    setup() {
      const active = ref(''); // 设定选定第几个
      return {
        active,
      };
    },

    created() {
      window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
      this.iosStates = sessionStorage.getItem("iosStates")
      if (this.$route.query.data) {
        let show_data = JSON.parse(decodeURIComponent(this.$route.query.data)) // 修改数据集合
        // 修改回显部分
        this.edit_id = show_data.id // 数据修改id
        this.often_tag_info = this.strSplit(show_data.title, 1) // 费用标题
        this.cost_content = this.strSplit(show_data.title, 2) // 费用内容
        this.cost_remark = show_data.remark // 备注
        // 附件回显
        this.file_data = [] // 初始化附件数组在进行操作
        for (let i = 0; i < show_data.file.length; i++) {
          this.file_data.push({
            name: this.strAccurate(show_data.file[i]),
            url: show_data.file[i]
          })
        }
      }

      // 传递客户id
      if (this.$route.query.id) {
        this.customer_id = this.$route.query.id // 客户id
      }

      // 是否为修改
      if (this.$route.query.edit_status) {
        this.edit_status = this.$route.query.edit_status // 客户id
      }

      this.getOftenData() // 类别常用数据

      // 星迪修改（底部导航栏）
      this.postId = sessionStorage.getItem("postID")
      if (this.postId === '6' || this.postId === '5') { // 判断 文案 或者 运营经理
        this.threeStates = false // 不显示两个个底部
        this.fourStates = false// 不显示四个底部
        this.twoStates = true// 不显示三个底部
        this.active = null   //
      }
      if (this.postId === '3' || this.postId === '4') { // 判断 运营主管 或者 文案主管
        this.threeStates = true // 显示两个个底部
        this.twoStates = false// 不显示三个底部
        this.fourStates = false// 不显示四个底部
        this.active = null
      }
      if (this.postId === '0') { // 判断 管理员
        this.threeStates = false // 显示两个个底部
        this.twoStates = false// 不显示三个底部
        this.fourStates = true// 不显示四个底部
        this.active = null
      }
    },

    // 获取屏幕默认高度与实时高度
    mounted: function() {
      //获取默认高度
      this.defaultHeight = document.documentElement.clientHeight
      window.onresize = () => {
        return (() => {
          //实时屏幕高度
          this.nowHeight = document.documentElement.clientHeight
        })();
      };
    },

    // 监听软键盘是否弹出
    watch: {
      nowHeight:function() {
        if(this.defaultHeight!== this.nowHeight){
          this.bottom_nav_visible = false
        }else{
          this.bottom_nav_visible = true
        }
      }
    },

    methods: {
      // 字符串截取
      strSplit(str, type) {
        let new_str = str
        if (type === 1) {
          new_str = str.split(':')[0]
        } else {
          new_str = str.split(':')[1]
        }
        return new_str
      },

      // 指定区域接取字符串
      strAccurate(name_str) {
        let file_name = name_str.lastIndexOf("\/");
        name_str = name_str.substring(file_name + 1, name_str.length);
        return name_str
      },

      // 上传附件
      beforeRead(file, detail) {
        let formData = new FormData();
        formData.append("file", file.file);
        handoverImg(formData).then(data => {
          if (data.code === 200) {
            let name_str = data.data
            let file_name = name_str.lastIndexOf("\/");
            name_str = name_str.substring(file_name + 1, name_str.length);
            this.file_data.push({
              name: name_str,
              url: data.data
            })
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // PDF删除按钮
      fileDel(item) {
        Dialog.confirm({
          message: '确认删除本条数据吗?',
        })
        .then(() => {
          // this.file_data = []
          for (let i = 0; i < this.file_data.length; i++) {
            if (item.name === this.file_data[i].name) {
              this.file_data.splice(i, 1);
            }
          }
        })
        .catch(() => {
        });
      },

      // 提交
      doSubmit() {
        let that = this
        let file_arr = [] // 请求时传递的附件路径数组
        for (let i = 0; i < this.file_data.length; i++) {
          file_arr.push(this.file_data[i].url)
        }

        if (this.file_data.length === 0) {
          file_arr = ''
        }

        if (this.edit_status) {
          // 修改参数集合
          var para = {
            title: this.often_tag_info + ':' + this.cost_content, // 进度名称和内容
            customer_id: this.customer_id, // 客户id
            file: file_arr, // 附件数组
            remark: this.cost_remark, // 备注
            type: 1, // 0文案进度  1费用  2 申请进度
            reminder_time: '', // 提醒时间
            reminder_content: '', // 提醒内容
            id: this.edit_id, // 修改接口传递，序号ID
          }
        } else {
          // 添加参数集合
          var para = {
            title: this.often_tag_info + ':' + this.cost_content, // 进度名称和内容
            customer_id: this.customer_id, // 客户id
            file: file_arr, // 附件数组
            remark: this.cost_remark, // 备注
            type: 1, // 0文案进度  1费用  2 申请进度
            reminder_time: '', // 提醒时间
            reminder_content: '', // 提醒内容
            id: '', // 修改接口传递，序号ID
          }
        }

        if (!this.often_tag_info) {
          Notify({type: 'danger', message: '请选择标签'});
          return
        }

        if (!this.cost_content) {
          Notify({type: 'danger', message: '请填写内容'});
          return
        }

        savePublicData(para).then(data => {
          if (data.code === 200) {
            Notify({
              type: 'success',
              message: '提交成功',
              duration: 750
            })
            setTimeout(function () {
              that.$router.go(-1)
            }, 800)

          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 获取费用标签列表
      getOftenData() {
        let para = {
          type: 1, // 类别: 0进度;  1费用
        }
        oftenTagData(para).then(data => {
          // 判断token
          if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403) {
            Notify({type: 'danger', message: data.message});
            sessionStorage.clear()
            this.$router.push({
              path: '/login'
            })
            return
          }
          if (data.code === 200) {
            this.request_often_data = data.data
            // 请求数据获取顾问数组格式化为vant下拉数组格式
            for (let i = 0; i < this.request_often_data.length; i++) {
              this.often_tag_data.push(this.request_often_data[i].title) // vant 下拉数组赋值
            }
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      /**
       * 跟单顾问(搜索部分)
       * */
      // 跟单顾问弹层控制
      adviserPopup() {
        this.often_show = true
      },

      // 确认赋值
      adviserConfirm(value) {
        this.often_tag_info = value // 顾问赋值
        this.often_show = false // 关闭弹层
        this.clear_adviser_show = false // 切换重置图标
      },

      // 取消选择
      adviserCancel() {
        this.often_show = false // 关闭弹层
      },

      /**
       * 重置下拉按钮
       * */
      // 重置签约顾问
      clearableAdviser() {
        this.often_tag_info = '' // 清空回显
        this.clear_adviser_show = true // 切换重置图标
      },

      // 苹果键盘事件
      blurIpt() {
        if (this.iosStates) {
          this.bottom_nav_visible = true
          return
        }
      },
      // 苹果键盘事件
      focusIpt() {
        if (this.iosStates) {
          this.bottom_nav_visible = false
          return
        }
      },
    }
  }
</script>

<style scoped>
  /* ---- 输入部分 ---- */
  .add-wrap {
    width: 100%;
    margin-top: 12px;
    padding: 16px 0;
    background: #fff;
  }

  .info-box {
    width: 345px;
    margin: 0 auto 16px;
  }

  .info-title {
    color: #A1A7BA;
    font-size: 15px;
  }

  .info-input {
    position: relative;
    width: 343px;
    height: 43px;
    margin-top: 12px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
  }

  .required-icon {
    padding-left: 3px;
    color: #FF0000;
  }

  .vant-input-edit {
    height: 43px !important;
    line-height: 43px !important;
    padding: 0 15px !important;
    color: #222 !important;
    font-size: 15px !important;
  }

  .vant-input-edit >>> input::-webkit-input-placeholder {
    color: #606266 !important;
  }

  .radio-box {
    display: flex;
    margin-top: 12px;
    height: 45px;
    color: #606266;
    font-size: 15px;
  }

  .radio-item {
    position: relative;
    margin-left: 10px;
    border-radius: 4px;
    border: 1px solid #C9CDD6;
    color: #606266;
    font-size: 15px;
  }

  .radio-item:last-child {
    margin-left: 0;
  }

  .radio_active {
    border-color: #409EFF;
  }

  .radio-item:last-child {
    margin-right: 0;
  }

  .van-radio--horizontal {
    margin-right: 0;
    padding: 11px;
  }

  .radio-item >>> .van-radio__icon--round .van-icon {
    display: none;
  }

  .radio-item >>> .van-radio__label {
    position: relative;
    margin-left: 0;
    padding-left: 20px;
    z-index: 9;
  }

  .radio-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 14px;
    left: 12px;
    width: 10px;
    height: 10px;
    border: 2px solid #ABAEB8;
    border-radius: 50%;
  }

  .radio-icon-spot {
    width: 6px;
    height: 6px;
    background: #ABAEB8;
    border-radius: 50%;
  }

  .radio_active .radio-icon {
    border-color: #409EFF;
  }

  .radio_active .radio-icon-spot {
    background: #409EFF;
  }

  .submit-btn1 {
    width: 345px;
    height: 50px;
    margin: 25px auto 35px;
    background: #409EFF;
    color: #fff;
    font-size: 17px;
    text-align: center;
    line-height: 50px;
    border-radius: 50px;
  }

  .info-textarea {
    height: 110px;
  }

  .info-textarea-edit {
    color: #222 !important;
    font-size: 15px !important;
  }

  .info-textarea-edit >>> textarea::-webkit-input-placeholder {
    color: #606266 !important;
  }

  .info-textarea-edit >>> textarea {
    min-height: 88px;
  }

  /*重置按钮*/
  .down-icon {
    position: absolute;
    top: 0;
    right: 15px;
    width: 12px;
    height: 100%;
    color: #606266;
  }

  .down-icon svg {
    position: relative;
    top: 14px;
  }

  .down-icon i {
    position: relative;
    top: 14px;
    left: -2px;
    color: #C9C7C8;
  }

  .remarks-submit {
    width: 345px;
    height: 50px;
    margin: 25px auto 0;
    background: #409EFF;
    color: #fff;
    font-size: 17px;
    text-align: center;
    line-height: 50px;
    border-radius: 50px;
  }

  .van-radio-group--horizontal, .van-checkbox-group--horizontal {
    flex-direction: row-reverse;
  }

  /* ---- 下载PDF模块---- */
  .upload-btn {
    width: 345px;
    margin: 0 auto;
    color: #606266;
    font-size: 17px;
    text-align: center;
    line-height: 45px;
    height: 45px;
    background: #F9F9F9;
    border-radius: 8px 8px 8px 8px;
  }

  .upload-index {
    width: 345px;
    height: 45px;
    line-height: 45px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #DCDFE6;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }

  .upload-left {
    display: flex;
  }

  .upload-title {
    font-size: 15px;
    color: #A1A7BA;
    padding-bottom: 17px;
  }

  .upload-index img {
    width: 15px;
    height: 15px;
    margin: 15px 0 0 15px;
  }

  .upload-name {
    margin-left: 10px;
    width: 165px;
    font-size: 15px;
    color: #222222;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .upload-del {
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F56C6C;
    border-radius: 50px 50px 50px 50px;
    margin-top: 9px;
    margin-right: 15px;
  }

  .upload-del img {
    margin: 0;
    width: 10px;
    height: 11px;
  }

  .upload-img-btn {
    width: 80px;
    height: 80px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #DCDFE6;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .upload-img-btn img {
    width: 35px;
    height: 35px;
  }


  .search-term {
    padding-top: 15px;
  }

  .search-term-label {
    color: #A1A7BA;
    font-size: 15px;
  }

  .search-term-detail {
    position: relative;
    height: 38px;
    margin-top: 12px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
  }

  .down-icon {
    position: absolute;
    top: 0;
    right: 15px;
    width: 12px;
    height: 100%;
    color: #606266;
  }

  .down-icon svg {
    position: relative;
    top: 11px;
  }

  .down-icon i {
    position: relative;
    top: 11px;
    left: -2px;
    color: #C9C7C8;
  }
</style>
