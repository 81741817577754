<template>
    <div>
        <div class="add-wrap">
            <!-- S 优先级单选 -->
            <div class="info-box" style="margin-bottom: 6px;">
                <div class="info-title">
                    <span>优先级</span>
                    <span class="required-icon">*</span>
                </div>
                <div class="radio-box">
                    <van-radio-group v-model="priority" @click="prioritySelect" direction="horizontal">
                        <div class="radio-item" :class="{radio_active:index+1 === priority_active}"
                             v-for="(item, index) in priority_data"
                             @click="priorityColor(index+1)">
                            <div class="radio-icon">
                                <div class="radio-icon-spot"></div>
                            </div>
                            <van-radio :name="item.id">{{item.title}}</van-radio>
                        </div>
                    </van-radio-group>
                </div>
            </div>
            <div class="info-box">
                <div class="info-title">
                    <span>手机号</span>
                    <span class="required-icon">*</span>
                </div>
                <div class="info-input">
                    <van-field class="vant-input-edit" @focus="focusIpt"
                               type="digit"
                               @blur="blurIpt" v-model="phone" autocomplete="off" clearable placeholder="请输入手机号..."/>
                </div>
            </div>
            <div class="submit-btn1" @click="submit()">绑定</div>
        </div>
        <!-- E 输入部分 -->
        <!-- S 底部导航栏 -->
        <div class="occupy-box"></div>
        <van-tabbar v-model="active" v-if="bottom_nav_visible">
            <van-tabbar-item replace to="/index">
                <span>首页</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <home-filled/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/customManage">
                <span>客户管理</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <user/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/signedResources">
                <span>已签约</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <edit/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/sharedPool">
                <span>共享池</span>
                <template #icon="props">
                    <div class="navIcon" v-bind:class="{ active: props.active}">
                        <el-icon>
                            <monitor/>
                        </el-icon>
                    </div>
                </template>
            </van-tabbar-item>
        </van-tabbar>
        <!-- S 底部导航栏 -->
    </div>
</template>

<script>
    import {bindWx} from '../api/java'
    import {Notify} from 'vant';
    import {ref} from "vue";

    export default {
        name: "customer_add",
        data() {
            return {
                /* ------ 优先级单选 ------ */
                priority: 1, // 优先级回显
                priority_id: '', // 优先级id(请求时传递的参数)
                priority_data: [
                    {id: 1, title: '深圳'},
                    {id: 2, title: '佛山'},
                ],
                phone:'',// 手机号
                priority_active: null, // 优先级选中样式
            }
        },
        created() {
            window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
            if (sessionStorage.getItem('postID') === '1') {
                this.adviser_show = true
            }
            this.iosStates = sessionStorage.getItem("iosStates")
        },
        // 获取屏幕默认高度与实时高度
        mounted: function () {
            //获取默认高度
            this.defaultHeight = document.documentElement.clientHeight
            window.onresize = () => {
                return (() => {
                    //实时屏幕高度
                    this.nowHeight = document.documentElement.clientHeight
                })();
            };
        },
        // 监听软键盘是否弹出
        watch: {
            nowHeight: function () {
                if (this.defaultHeight !== this.nowHeight) {
                    this.bottom_nav_visible = false
                } else {
                    this.bottom_nav_visible = true
                }
            }
        },
        methods: {
            // 返回上一页
            goBackPage() {
                this.$router.go(-1)
            },
            // 初始化单选样式
            initialRadio() {
                this.priority_active = this.priority // 优先级初始化
            },
            // 单选点击变色
            priorityColor(index) {
                this.priority_active = index
            },

            prioritySelect() {
                 this.priority_id = this.priority
            },
            // 提交
            submit() {
                let para ={
                    phone:this.phone,
                    company:this.priority_id
                }
                if (!para.company) {
                    Notify({type: 'danger', message: '请选择公司'})
                    return
                }
                if (!para.phone) {
                    Notify({type: 'danger', message: '请填写手机号'})
                    return
                }
                if (para.phone) {
                    let judge_phone = /^1[3456789]\d{9}$/; // 电话正则判断
                    if (!judge_phone.test(para.phone)) {
                        Notify({type: 'danger', message: '手机号不符合规则'})
                        return false
                    }
                }
                bindWx(para).then(data => {
                    if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                        Notify({type: 'danger', message: data.message});
                        sessionStorage.clear()
                        this.$router.push({
                            path: '/weChatLogin'
                        })
                        return
                    }
                    if (data.code === 200) {
                        Notify({type: 'success', message: '绑定成功'});
                        this.phone=''
                        this.priority_id=''
                        setTimeout(
                            this.$router.push({
                                path: '/personalSet',
                            }),4000);
                    } else {
                        Notify({type: 'danger', message: data.message});
                    }
                }).catch(() => {
                    Notify({type: 'danger', message: '服务连接失败'});
                })

            },
            // 苹果键盘事件
            blurIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = true
                    return
                }
            },
            // 苹果键盘事件
            focusIpt() {
                if (this.iosStates) {
                    this.bottom_nav_visible = false
                    return
                }
            },
        }
    }
</script>

<style scoped>
    /* ---- 输入部分 ---- */
    .add-wrap {
        width: 100%;
        margin-top: 12px;
        padding: 16px 0;
        background: #fff;
    }

    .info-box {
        width: 345px;
        margin: 0 auto 16px;
    }

    .info-title {
        color: #A1A7BA;
        font-size: 15px;
    }

    .info-input {
        position: relative;
        width: 343px;
        height: 43px;
        margin-top: 12px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow: hidden;
    }

    .required-icon {
        padding-left: 3px;
        color: #FF0000;
    }

    .vant-input-edit {
        height: 43px !important;
        line-height: 43px !important;
        padding: 0 15px !important;
        color: #222 !important;
        font-size: 15px !important;
    }

    .vant-input-edit >>> input::-webkit-input-placeholder {
        color: #606266 !important;
    }

    .radio-box {
        display: flex;
        margin-top: 12px;
        color: #606266;
        font-size: 15px;
    }

    .radio-item {
        position: relative;
        margin: 0 10px 10px 0;
        border-radius: 4px;
        border: 1px solid #C9CDD6;
        color: #606266;
        font-size: 15px;
    }

    .radio_active {
        border-color: #409EFF;
    }

    .radio-item:last-child {
        margin-right: 0;
    }

    .van-radio--horizontal {
        margin-right: 0;
        padding: 11px;
    }

    .radio-item >>> .van-radio__icon--round .van-icon {
        display: none;
    }

    .radio-item >>> .van-radio__label {
        position: relative;
        margin-left: 0;
        padding-left: 20px;
        z-index: 9;
    }

    .radio-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 14px;
        left: 12px;
        width: 10px;
        height: 10px;
        border: 2px solid #ABAEB8;
        border-radius: 50%;
    }

    .radio-icon-spot {
        width: 6px;
        height: 6px;
        background: #ABAEB8;
        border-radius: 50%;
    }

    .radio_active .radio-icon {
        border-color: #409EFF;
    }

    .radio_active .radio-icon-spot {
        background: #409EFF;
    }

    .submit-btn1 {
        width: 345px;
        height: 50px;
        margin: 25px auto 35px;
        background: #409EFF;
        color: #fff;
        font-size: 17px;
        text-align: center;
        line-height: 50px;
        border-radius: 50px;
    }

    .info-textarea {
        height: 110px;
    }

    .info-textarea-edit {
        color: #222 !important;
        font-size: 15px !important;
    }

    .info-textarea-edit >>> textarea::-webkit-input-placeholder {
        color: #606266 !important;
    }

    .info-textarea-edit >>> textarea {
        min-height: 88px;
    }

    .down-icon {
        position: absolute;
        top: 13px;
        right: 15px;
        width: 12px;
        color: #606266;
    }

    .info-tip-dialog >>> .van-dialog__header {
        padding-top: 15px;
    }

    .info-tip-dialog >>> .van-dialog__content div {
        padding: 0 20px;
        font-size: 15px;
        color: #222;
    }


    /*底部栏*/
    .navIcon {
        height: 21px;
        width: 21px;
    }
</style>
