<template>
  <!-- S 头部header -->
<!--  <div class="header">-->
<!--    <div class="header-nav">-->
<!--      <div class="header-nav-back" @click="goBackPage()">-->
<!--        <van-icon name="arrow-left"/>-->
<!--      </div>-->
<!--      <span>跟单记录</span>-->
<!--    </div>-->
<!--  </div>-->
  <!-- E 头部header -->
  <!-- S 输入部分 -->
  <div class="add-wrap">
    <!-- S 跟单时间单选 -->
    <div class="info-box">
      <div class="info-title">
        <span>跟单时间</span>
        <span class="required-icon">*</span>
      </div>
      <div class="info-input" @click="adviserPopup">
        <van-field class="vant-input-edit" v-model="screen_follow_adviser" readonly placeholder="请选择"/>
        <div class="down-icon" v-if="clear_follow_show">
          <caret-bottom/>
        </div>
        <div class="down-icon" @click.stop="clearableFollow" v-else>
          <van-icon name="clear"/>
        </div>
      </div>
    </div>
    <!-- E 跟单时间单选 -->
    <!-- S 回访方式单选 -->
    <div class="info-box">
      <div class="info-title">
        <span>回访方式</span>
        <span class="required-icon">*</span>
      </div>
      <div class="radio-box">
        <van-radio-group v-model="radio" direction="horizontal">
          <div class="radio-item" :class="{radio_active:index+1 === visit_active}" v-for="(item, index) in visit_data"
               @click="switchColor(index+1)">
            <div class="radio-icon">
              <div class="radio-icon-spot"></div>
            </div>
            <van-radio :name="index+1">{{item}}</van-radio>
          </div>
        </van-radio-group>
      </div>
    </div>
    <!-- E 回访方式单选 -->
    <!-- S 跟单内容 -->
    <div class="info-box">
      <div class="info-title">
        <span>跟单内容</span>
        <span class="required-icon">*</span>
      </div>
      <div class="info-input info-textarea">
        <van-field
            class="info-textarea-edit"
            v-model="content"
            autocomplete="off"
            rows="1"
            autosize
            @focus="focusIpt"
            @blur="blurIpt"
            type="textarea"
            placeholder="请输入跟单内容..."
        />
      </div>
    </div>
    <!-- E 跟单内容 -->
    <!-- S 跟单结果单选 -->
    <div class="info-box">
      <div class="info-title">
        <span>跟单结果</span>
        <span class="required-icon">*</span>
      </div>
      <div class="radio-box">
        <van-radio-group v-model="type" direction="horizontal">
          <div class="radio-item" :class="{radio_active:index+1 === type_active}" v-for="(item, index) in type_data"
               @click="switchColorType(index+1)">
            <div class="radio-icon">
              <div class="radio-icon-spot"></div>
            </div>
            <van-radio :name="index+1">{{item}}</van-radio>
          </div>
        </van-radio-group>
      </div>
    </div>
    <!-- E 跟单结果单选 -->
    <!-- S 跟单提醒 -->
    <div class="info-box">
      <div class="info-title">
        <span>跟单提醒</span>
      </div>
      <div class="info-input" @click="remindPopup">
        <van-field class="vant-input-edit" v-model="screen_follow_remind" readonly placeholder="请选择"/>
        <div class="down-icon" v-if="clear_remind_show">
          <caret-bottom/>
        </div>
        <div class="down-icon" @click.stop="clearableRemind" v-else>
          <van-icon name="clear"/>
        </div>
      </div>
    </div>
    <!-- E 跟单提醒 -->
    <!-- S 跟单内容 -->
    <div class="info-box">
      <div class="info-title">
        <span>备注</span>
      </div>
      <div class="info-input info-textarea">
        <van-field
            class="info-textarea-edit"
            v-model="remarks"
            autocomplete="off"
            rows="1"
            autosize
            @focus="focusIpt"
            @blur="blurIpt"
            type="textarea"
            placeholder="请输入备注..."
        />
      </div>
    </div>
    <!-- E 跟单内容 -->

    <div class="submit-btn1" @click="submit()">提交</div>
  </div>
  <!-- E 输入部分 -->

  <!-- S 跟单时间弹层 -->
  <van-popup v-model:show="follow_adviser_show" position="bottom" :style="{ height: '48%' }">
    <van-datetime-picker
        v-model="follow_time"
        type="datetime"
        title="选择跟单时间"
        :max-date="signMaxDate"
        :formatter="formatter"
        @confirm="adviserConfirm"
        @cancel="adviserCancel"
    />
  </van-popup>
  <!-- E 跟单时间弹层 -->

  <!-- S 跟单提醒弹层 -->
  <van-popup v-model:show="follow_remind_show" position="bottom" :style="{ height: '48%' }">
    <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择跟单时间"
        :min-date="signMinDate"
        :max-date="signMaxDate"
        :formatter="formatter"
        @confirm="remindConfirm"
        @cancel="remindCancel"
    />
  </van-popup>
  <!-- E 跟单提醒弹层 -->
  <!-- S 底部导航栏 -->
  <div class="occupy-box"></div>
  <!-- S 两个底部导航栏 -->
  <div v-if="twoStates">
    <van-tabbar v-model="active" v-if="bottom_nav_visible">
      <van-tabbar-item replace to="/index">
        <span>首页</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <home-filled/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/customSchedule">
        <span>客户列表</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <PieChart/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
  <!-- S 两个底部导航栏 -->
  <!-- S 三个底部导航栏 -->
  <div v-if="threeStates">
    <van-tabbar v-model="active" v-if="bottom_nav_visible">
      <van-tabbar-item replace to="/index">
        <span>首页</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <home-filled/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/approve">
        <span>交接审批</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <Coordinate/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/customSchedule">
        <span>客户列表</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <PieChart/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
  <!-- S 三个底部导航栏 -->

  <!-- S 四个底部导航栏 -->
  <div v-if="fourStates">
    <van-tabbar v-model="active" v-if="bottom_nav_visible">
      <van-tabbar-item replace to="/index">
        <span>首页</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <home-filled/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/customManage">
        <span>客户管理</span>
        <template #icon="props">
          <div class="navIcon" v-bind:class="{ active: props.active}">
            <el-icon>
              <user/>
            </el-icon>
          </div>
        </template>
      </van-tabbar-item>
<!--      <van-tabbar-item replace to="/signedResources">-->
<!--        <span>已签约</span>-->
<!--        <template #icon="props">-->
<!--          <div class="navIcon" v-bind:class="{ active: props.active}">-->
<!--            <el-icon>-->
<!--              <edit/>-->
<!--            </el-icon>-->
<!--          </div>-->
<!--        </template>-->
<!--      </van-tabbar-item>-->
<!--      <van-tabbar-item replace to="/sharedPool">-->
<!--        <span>共享池</span>-->
<!--        <template #icon="props">-->
<!--          <div class="navIcon" v-bind:class="{ active: props.active}">-->
<!--            <el-icon>-->
<!--              <monitor/>-->
<!--            </el-icon>-->
<!--          </div>-->
<!--        </template>-->
<!--      </van-tabbar-item>-->
    </van-tabbar>
  </div>
  <!-- S 四个底部导航栏 -->
</template>

<script>
  import {ref} from 'vue';
  import {addDocumentary} from "../api/java";
  import {useRouter} from 'vue-router'
  import {Notify} from "vant";

  export default {
    name: "documentaryAdd",
    setup() {
      const active = ref(''); // 设定选定第几个
      const router = useRouter()
      let follow_time = ref(new Date()); // 定义跟单时间
      let currentDate = ref(new Date());
      if (router.currentRoute.value.query.visa_data) {
        let pageData = JSON.parse(decodeURIComponent(router.currentRoute.value.query.visa_data))   // query传递过来的参数集合
        // S 处理跟单时间回显
        function formatTime(date) {
          let dates = new Date(parseInt(Date.parse(date))) // 重新拼接
          var Dates = new Array(dates.getFullYear(), dates.getMonth(), dates.getDate(), dates.getHours(), dates.getMinutes(), dates.getSeconds())
          return Dates;
        }

        // E 处理跟单时间回显
        formatTime(pageData.follow_time) // 跟单时间 数据处理
        formatTime(pageData.remind_time)// 跟单提醒 数据处理

        follow_time.value.setFullYear(formatTime(pageData.follow_time)[0]);
        follow_time.value.setMonth(formatTime(pageData.follow_time)[1]);
        follow_time.value.setDate(formatTime(pageData.follow_time)[2]);
        follow_time.value.setHours(formatTime(pageData.follow_time)[3]);
        follow_time.value.setMinutes(formatTime(pageData.follow_time)[4]);
        follow_time.value.setSeconds(formatTime(pageData.follow_time)[5]);

        currentDate.value.setFullYear(formatTime(pageData.remind_time)[0]);
        currentDate.value.setMonth(formatTime(pageData.remind_time)[1]);
        currentDate.value.setDate(formatTime(pageData.remind_time)[2]);
        currentDate.value.setHours(formatTime(pageData.remind_time)[3]);
        currentDate.value.setMinutes(formatTime(pageData.remind_time)[4]);
        currentDate.value.setSeconds(formatTime(pageData.remind_time)[5]);
      }


      return {
        minDate: new Date(1960, 0, 1), // 起始时间
        maxDate: new Date(2025, 10, 1), // 最大时间
        currentDate, // 跟单时间
        follow_time,  // 跟单提醒
        active,
      };

    },
    data() {
      return {
        /* ------ 重置按钮 ------ */
        clear_follow_show: true, // 跟单时间重置
        clear_remind_show: true, // 提醒时间重置

        /* ------ 回访方式单选 ------ */
        radio: '', // 回访方式回显
        visit_data: ['电话', '微信', '邮件', 'QQ'],
        visit_active: null, // 回访方式选中样式

        /* ------ 跟单结果单选 ------ */
        type: '', // 跟单结果回显
        type_data: [ '无人接通', '接通成功回访'],
        type_active: null, // 跟单结果选中样式

        /* ---- 跟单时间部分 ---- */
        follow_adviser_show: false, // 跟单时间弹出层
        screen_follow_adviser: '',
        /* ---- 跟单提醒部分 ---- */
        follow_remind_show: false, // 跟单提醒弹出层
        screen_follow_remind: '', //跟单提醒参数
        signMinDate: new Date(),
        signMaxDate: new Date(2122, 0, 1),

        /* ---- 时间格式 ---- */
        formatter(type, val) {
          if (type === 'year') {
            return `${val}年`;
          } else if (type === 'month') {
            return `${val}月`;
          } else if (type === 'day') {
            return `${val}日`;
          }
          return val;
        },

        content: '',// 跟单内容
        remarks: '',// 备注
        customerId: '', // 客户ID
        addStatus: true,
        pageTitle: '添加',

        bottom_nav_visible: true, // 底部导航显示与隐藏
        iosStates: false, // 手机系统

        /* ---- 监听软键盘是否弹出 ---- */
        defaultHeight: '0',  //默认屏幕高度
        nowHeight: '0',  //实时屏幕高度

        threeStates: false,//三底部栏
        twoStates: false,//三底部栏
        fourStates: false,//四底部栏
        postId: "" // 权限
      }
    },

    created() {
      window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
      this.iosStates = sessionStorage.getItem("iosStates")

      // 星迪修改（底部导航栏）
      this.postId = sessionStorage.getItem("postID")
      if (this.postId === '6' || this.postId === '5') { // 判断 文案 或者 运营经理
        this.threeStates = false // 不显示两个个底部
        this.fourStates = false// 不显示四个底部
        this.twoStates = true// 不显示三个底部
        this.active = null   //
      }
      if (this.postId === '3' || this.postId === '4') { // 判断 运营主管 或者 文案主管
        this.threeStates = true // 显示两个个底部
        this.twoStates = false// 不显示三个底部
        this.fourStates = false// 不显示四个底部
        this.active = null
      }
      if (this.postId === '0' || this.postId === '1' || this.postId === '2' || this.postId === '7') { // 判断 管理员
        this.threeStates = false // 显示两个个底部
        this.twoStates = false// 不显示三个底部
        this.fourStates = true// 不显示四个底部
        this.active = null
      }

      if (this.$route.query.visa_data) {
        this.addStatus = false
        let edit_data = JSON.parse(decodeURIComponent(this.$route.query.visa_data)) // 接收上个页面传递过来的对象
        this.pageTitle = '修改'
        this.radio = edit_data.follow_type
        this.type = edit_data.follow_result
        this.visit_active = edit_data.follow_type + 1
        this.screen_follow_adviser = edit_data.follow_time
        this.content = edit_data.follow_content
        this.screen_follow_remind = edit_data.remind_time
        this.remarks = edit_data.follow_remarks
        this.customerId = this.$route.query.customerId
        this.id = edit_data.id
        // 跟单时间重置
        if (edit_data.follow_time) {
          this.clear_follow_show = false // 切换图标
        }

        // 提醒时间重置
        if (edit_data.remind_time) {
          this.clear_remind_show = false // 切换图标
        }
        if (edit_data.follow_result == 1) {
          this.type_active = 2
          return
        }
        if (edit_data.follow_result == 0) {
          this.type_active = 1
          return
        }
      } else {
        this.pageTitle = '添加'
        this.customerId = this.$route.query.customerId
        this.addStatus = true
      }
    },
    // 获取屏幕默认高度与实时高度
    mounted: function () {
      //获取默认高度
      this.defaultHeight = document.documentElement.clientHeight
      window.onresize = () => {
        return (() => {
          //实时屏幕高度
          this.nowHeight = document.documentElement.clientHeight
        })();
      };
    },
    // 监听软键盘是否弹出
    watch: {
      nowHeight: function () {
        if (this.defaultHeight !== this.nowHeight) {
          this.bottom_nav_visible = false
        } else {
          this.bottom_nav_visible = true
        }
      }
    },
    methods: {
      // 返回上一页
      goBackPage() {
        this.$router.go(-1)
      },

      //时间GMT转换为 xxxx-xx-xx形式
      GMTToStr(value) {
        let date = new Date(value)

        let month_follow = date.getMonth() + 1 // 月
        let day_follow = date.getDate() // 日
        let hours_follow = date.getHours() // 小时
        let minutes_follow = date.getMinutes() // 分钟
        let seconds_follow = date.getSeconds() // 秒
        if (month_follow < 10) {
          month_follow = '0' + month_follow
        }

        if (day_follow < 10) {
          day_follow = '0' + day_follow
        }

        if (hours_follow < 10) {
          hours_follow = '0' + hours_follow
        }

        if (minutes_follow < 10) {
          minutes_follow = '0' + minutes_follow
        }

        if (seconds_follow < 10) {
          seconds_follow = '0' + seconds_follow
        }




        let Str = date.getFullYear() + '-' +
          month_follow + '-' +
          day_follow + ' ' +
          hours_follow + ':' +
          minutes_follow + ':' +
          seconds_follow
        return Str
      },
      /**
       * 回访方式选择部分
       * */
      // 回访方式单选点击变色
      switchColor(index) {
        this.visit_active = index
        this.radio = index - 1

      },

      // 初始化单选样式(回访方式)
      initialRadio() {
        this.visit_active = this.radio
      },

      /**
       * 跟单结果选择部分
       * */
      // 跟单结果单选点击变色
      switchColorType(index) {
        this.type_active = index
        if (index == 2) {
          this.type = 1
          return
        }
        if (index == 1) {
          this.type = 0
          return
        }
      },

      /**
       * 跟单时间
       * */
      // 跟单时间弹层控制
      adviserPopup() {
        this.follow_adviser_show = true
      },
      // 确认赋值
      adviserConfirm(value) {
        console.log( this.GMTToStr(value))
        this.screen_follow_adviser = this.GMTToStr(value) // 跟单时间赋值
        this.follow_adviser_show = false // 关闭弹层
        this.clear_follow_show = false // 切换图标
      },
      // 取消选择
      adviserCancel() {
        this.follow_adviser_show = false // 关闭弹层
      },

      /**
       * 跟单提醒
       * */
      // 跟单提醒弹层控制
      remindPopup() {
        this.follow_remind_show = true
      },
      // 确认赋值
      remindConfirm(value) {
        this.GMTToStr(value)
        this.screen_follow_remind = this.GMTToStr(value) // 跟单提醒赋值
        this.follow_remind_show = false // 关闭弹层
        this.clear_remind_show = false // 切换图标
      },
      // 取消选择
      remindCancel() {
        this.follow_remind_show = false // 关闭弹层
      },
      // 请求修改操作
      operationFun(data) {
        if (Date.parse(data.follow_time) > Date.parse(data.remind_time)) {
          Notify({type: 'danger', message: '跟单提醒不能大于跟单时间，请重新选择'});
          return
        }
        addDocumentary(data).then(data => {
          if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
            Notify({type: 'danger', message: data.message});
            sessionStorage.clear()
            this.$router.push({
              path: '/weChatLogin'
            })
            return
          }
          if (data.code === 200) {
            Notify({type: 'success', message: "保存成功!"});
            // setTimeout(
            // this.$router.push({
            //     path: '/documentary',
            //     query: {
            //         id: this.$route.query.customerId // 客户ID
            //     }
            // }),4000);
            this.$router.go(-1) // 后期出问题在修改
          } else {
            Notify({type: 'danger', message: data.message});
          }
        }).catch(() => {
          Notify({type: 'danger', message: '服务连接失败'});
        })
      },

      // 提交
      submit() {
        let para = {
          customer_id: this.customerId, // 客户ID 此处暂时没处理
          follow_time: this.screen_follow_adviser,  //跟单时间
          follow_type: this.radio,// 跟单方式
          follow_content: this.content, // 跟单内容
          follow_remarks: this.remarks, // 备注内容
          follow_result: this.type, // 跟单结果
          remind_time: this.screen_follow_remind,  // 下次提醒时间
          id: this.id, // 数据Id  此处暂时没处理
        }

         console.log(para)
        let paraAdd = {
          id:'',
          customer_id: this.customerId, // 客户ID 此处暂时没处理
          follow_time: this.screen_follow_adviser,  //跟单时间
          follow_type: this.radio,// 跟单方式
          follow_content: this.content, // 跟单内容
          follow_remarks: this.remarks, // 备注内容
          follow_result: this.type, // 跟单结果
          remind_time: this.screen_follow_remind,  // 下次提醒时间
        }
        if (this.addStatus == true) {
          if (paraAdd.follow_time === '') {
            Notify({type: 'danger', message: '跟单时间不能为空，请选择！'});
            return
          }
          if (paraAdd.follow_type === '') {
            Notify({type: 'danger', message: '回访方式不能为空，请选择！'});
            return
          }
          if (paraAdd.follow_content === '') {
            Notify({type: 'danger', message: '跟单内容不能为空，请选择！'});
            return
          }
          if (paraAdd.follow_result === '') {
            Notify({type: 'danger', message: '跟单结果不能为空，请选择！'});
            return
          }
          this.operationFun(paraAdd)
        } else {
          if (para.follow_time === '' || para.follow_type === '' || para.follow_content === '' || para.follow_content === '') {
            Notify({type: 'danger', message: '必填项不能为空，请选择！'});
            return
          }
          this.operationFun(para)
        }
      },

      /**
       * 重置变扭部分
       * */
      // 跟单时间重置
      clearableFollow() {
        this.screen_follow_adviser = '' // 清空时间
        this.clear_follow_show = true // 切换图标
      },

      // 跟单提醒
      clearableRemind() {
        this.screen_follow_remind = '' // 清空时间
        this.clear_remind_show = true // 切换图标
      },

      // 苹果键盘事件
      blurIpt() {
        if (this.iosStates) {
          this.bottom_nav_visible = true
          return
        }
      },
      // 苹果键盘事件
      focusIpt() {
        if (this.iosStates) {
          this.bottom_nav_visible = false
          return
        }
      },
    }
  }
</script>

<style scoped>
  /* ---- 输入部分 ---- */
  .add-wrap {
    width: 100%;
    margin-top: 12px;
    padding: 16px 0;
    background: #fff;
  }

  .info-box {
    width: 345px;
    margin: 0 auto 16px;
  }

  .info-title {
    color: #A1A7BA;
    font-size: 15px;
  }

  .info-input {
    position: relative;
    width: 343px;
    height: 43px;
    margin-top: 12px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
  }

  .required-icon {
    padding-left: 3px;
    color: #FF0000;
  }

  .vant-input-edit {
    height: 43px !important;
    line-height: 43px !important;
    padding: 0 15px !important;
    color: #222 !important;
    font-size: 15px !important;
  }

  .vant-input-edit >>> input::-webkit-input-placeholder {
    color: #606266 !important;
  }

  .radio-box {
    display: flex;
    margin-top: 12px;
    height: 45px;
    color: #606266;
    font-size: 15px;
  }

  .radio-item {
    position: relative;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #C9CDD6;
    color: #606266;
    font-size: 15px;
  }

  .radio_active {
    border-color: #409EFF;
  }

  .radio-item:last-child {
    margin-right: 0;
  }

  .van-radio--horizontal {
    margin-right: 0;
    padding: 11px;
  }

  .radio-item >>> .van-radio__icon--round .van-icon {
    display: none;
  }

  .radio-item >>> .van-radio__label {
    position: relative;
    margin-left: 0;
    padding-left: 20px;
    z-index: 9;
  }

  .radio-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 14px;
    left: 12px;
    width: 10px;
    height: 10px;
    border: 2px solid #ABAEB8;
    border-radius: 50%;
  }

  .radio-icon-spot {
    width: 6px;
    height: 6px;
    background: #ABAEB8;
    border-radius: 50%;
  }

  .radio_active .radio-icon {
    border-color: #409EFF;
  }

  .radio_active .radio-icon-spot {
    background: #409EFF;
  }

  .submit-btn1 {
    width: 345px;
    height: 50px;
    margin: 25px auto 35px;
    background: #409EFF;
    color: #fff;
    font-size: 17px;
    text-align: center;
    line-height: 50px;
    border-radius: 50px;
  }

  .info-textarea {
    height: 110px;
  }

  .info-textarea-edit {
    color: #222 !important;
    font-size: 15px !important;
  }

  .info-textarea-edit >>> textarea::-webkit-input-placeholder {
    color: #606266 !important;
  }

  .info-textarea-edit >>> textarea {
    min-height: 88px;
  }

  /*重置按钮*/
  .down-icon {
    position: absolute;
    top: 0;
    right: 15px;
    width: 12px;
    height: 100%;
    color: #606266;
  }

  .down-icon svg {
    position: relative;
    top: 14px;
  }

  .down-icon i {
    position: relative;
    top: 14px;
    left: -2px;
    color: #C9C7C8;
  }
</style>
