<template>
  <router-view />
</template>
<script>
import {getJsapiTicket} from "@/api/java";
import {Notify} from "vant";
export default {
  created() {
    this.getHiddenWecomMenu() //隐藏转发
  },
  methods: {
    // 业绩确认企业微信隐藏左上角转发
    getHiddenWecomMenu(){
      let para ={
        url: String(location.href.split('#')[0])
      }
      getJsapiTicket(para).then(res => {
        if(res){
          if (res.code === 200) {
            wx.config({
              beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: 'ww1e03ca55a309cbb1', // 必填，企业微信的corpID，必须是本企业的corpID，不允许跨企业使用
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
              jsApiList: [
                "hideOptionMenu",
                'hideAllNonBaseMenuItem',
              ] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
            });
            //配置成功以后config:ok
            wx.ready(function (res) {
              //隐藏右上角菜单接口
              wx.hideAllNonBaseMenuItem({
                success:function (res){
                },
                fail:function (err){
                }
              });
              wx.hideOptionMenu({
                success:function (){
                },
                fail:function (err){
                }
              });
            });
          }
          else {
            Notify({type: 'danger', message: res.message});
          }
        }
      }).catch((err) => {
        // this.$message.error('服务器连接失败200!')
        Notify({type: 'danger', message: `获取企微验证信息失败: ${err.message}`});
      })
    },
  }
}
</script>
<style>
*{
    margin: 0;
    padding: 0;
}
html,
body,#app {
  margin: 0;
  padding: 0;
  /*height: 100%;*/
  background: #F5F5FC;
}

.w {
  width: 92%;
  margin: 0 auto;
}

/* ---- header区域 ---- */
.header {
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 98px;
  background: #FFFFFF;
  box-shadow: 0 -12px 16px 1px rgba(0,0,0,0.06);
  opacity: 1;
  z-index: 10;
}

.header-nav {
  position: relative;
  color: #222;
  font-size: 19px;
  text-align: center;
  line-height: 53px;
}

.header-nav-back {
  position: absolute;
  left: 10px;
  width: 19px;
  height: 16px;
}

.submit-btn {
  font-size: 17px;
  color: #ffffff;
  width: 345px;
  margin: 25px auto 0;
  height: 50px;
  text-align: center;
  background: #409EFF;
  border-radius: 24px;
  opacity: 1;
}

.header-seat{
  height: 98px;
}


 /*底部导航栏样式*/
.occupy-box{
  height: 61px;
  width: 100%;
}
.van-tabbar {
  height: 61px !important;
}

.van-tabbar-item__icon {
  margin-top: 3px;
  margin-bottom: 7px;
}

.van-tabbar-item__text {
  font-size: 14px;
  color: #333742;
}

/*底部栏 选中的文字颜色*/
.van-tabbar-item--active .van-tabbar-item__text {
  font-size: 14px;
  color: #409EFF;
}

/*暂无数据样式*/
.noData {
  padding: 20px 0;
  text-align: center;
  font-size: 15px;
  color: #A1A7BA;
}

/*底部栏*/
.navIcon {
  height: 21px;
  width: 21px;
}

.van-tabbar--fixed {
  z-index: 99 !important;
}

/*列表页搜索和更多搜索条件图标*/
.search-btn img {
  position: relative;
  top: 2px;
  width: 14px;
}

.more-search img {
  position: relative;
  top: 2px;
  width: 15px;
}

.info-label {
  white-space: nowrap;
}

/*暂无数据*/
.empty-data {
  width: 345px;
  height: 55px;
  margin: 15px auto;
  background: #fff;
  color: #A1A7BA;
  font-size: 15px;
  text-align: center;
  line-height: 55px;
  border-radius: 10px;
  box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
}
</style>
