<template>
  <div>
  <!-- 佛山登录页 -->
  </div>
</template>

<script>
  import {refreshToken} from "../api/login";
  import {Notify} from "vant";

  export default {
    created() {
      let login_url = process.env.VUE_APP_BASE_URL + '/api/login/mobile_wx_fs' // 网页授权接口(正式)
      // 刚进来没有token去登陆,登陆过有token跳过
      if (!this.$route.query.token) {
        location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwf257a9c8b41da94d&redirect_uri='+ encodeURIComponent(login_url) +'&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
      }

      // if (!this.$route.query.company) {
      //   Notify('无权访问此系统!')
      //   return
      // }

      if (this.$route.query.company && !this.$route.query.token) {
        Notify('缺失令牌')
        return;
      }

      if (this.$route.query.company && this.$route.query.token) {
        sessionStorage.setItem('postID', this.$route.query.post) // 存储  post 权限(0:管理员; 1:运营;2:顾问;)token信息
        sessionStorage.setItem('token',  "bearer " + this.$route.query.token) // 存储token信息
        sessionStorage.setItem('userName', this.$route.query.user_name) // 存储用户名
        this.getToken() // 获取token
      }

      /**
       * 测试登录部分 登录方法  http://localhost:8080?token=
       * */
      // sessionStorage.setItem('postID', '0') // 存储  post 权限(0:管理员; 1:运营;2:顾问;)token信息
      // sessionStorage.setItem('token',  "bearer " + this.$route.query.token) // 存储token信息
      // sessionStorage.setItem('userName', '123') // 存储用户名
      // this.getToken() // 获取token
    },
    methods: {
      //获取token
      getToken() {
        refreshToken().then(data => {
          if (data.code === 200) {
            var newToken = "bearer " + data.data  // 后台请求回来的token值
            sessionStorage.setItem('token', newToken) // 存储token信息
            this.$router.push({
              path: '/index',
            });
            // Notify({ type: 'success', message: '登录成功' })
          } else {
            Notify({type: 'danger', message: data.message})
          }
        }).catch(() => {
          Notify({type: 'warning', message: '服务器连接失败'})
        })
      },
    }
  }
</script>

<style scoped>

</style>
