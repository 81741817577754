<template>
    <van-tabbar
            v-model="tabNum"
            :active-color="activeColor"
            :inactive-color="inactiveColor"
    >
        <van-tabbar-item
                v-for="(item, index) in tabList"
                :key="index"
                replace
                :name="item.label"
                :icon="item.icon"
                :to="item.routerPath"
        >
            <span>{{item.label}}</span>
            <template #icon="props">
                <div class="navIcon">
                    <el-icon>
                        <component :is="item.icon"/>
                    </el-icon>
                </div>
              </template>
        </van-tabbar-item>
    </van-tabbar>
</template>

<script>
    export default {
        props: {
            active: Number
        },
        data() {
            return {
                tabNum: "", // 通过name修改
                tabList: [
                    // {
                    //   label: "首页", //文字标签
                    //   routerPath: "/index", //路由
                    //   icon: "HomeFilled" //图标
                    // },
                    // {
                    //     label: "客户管理", //文字标签
                    //     routerPath: "/customManage", //路由
                    //     icon: "User" //图标
                    // },
                    // {
                    //     label: "已签约", //文字标签
                    //     routerPath: "/signedResources", //路由
                    //     icon: "Edit" //图标
                    // },
                    // {
                    //     label: "共享池", //文字标签
                    //     routerPath: "/sharedPool", //路由
                    //     icon: "Monitor" //图标
                    // },
                ],
                activeColor: "#409EFF", //激活色
                inactiveColor: "#7d7e80" ,// 未选中标签的颜色 000
                postId:''  // 权限
            }
        },
        methods: {
        },
        created() {
            this.postId = sessionStorage.getItem("postID")
            if(this.active ==''){
               this.tabNum='首页'
            }
            this.tabList=[]
            // console.log(this.tabList)
            // console.log(this.active)
            this.tabList = JSON.parse(sessionStorage.getItem('tabData')) // 获取导航
            for (let i = 0; i < this.tabList.length; i++) {
                if(this.active == i){
                    this.tabNum =this.tabList[i].label
                }
            }
        }
    }
</script>