<template>
  <div>
  <!-- 深圳登录页 -->
  </div>
</template>

<script>
  import {refreshToken} from "../api/login";
  import {userData} from "../api/java.js";
  import {Notify} from "vant";

  export default {
    created() {
        let login_url = process.env.VUE_APP_BASE_URL_JAVA + '/crm/crmMobileLogin?type=0'// 网页授权接口(正式)
      // let Wxid ='ww0ade117fa232c373'; // 测试Id
      let Wxid ='ww1e03ca55a309cbb1'; //正式我们在Vxid
      // 刚进来没有token去登陆,登陆过有token跳过
      if (!this.$route.query.token) {
        location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+ Wxid +'&redirect_uri='+ encodeURIComponent(login_url) +'&response_type=code&scope=snsapi_base&state=m#wechat_redirect'
      }
      if (this.$route.query.token) {
        var newToken = "bearer " + this.$route.query.token  // 后台请求回来的token值11
        sessionStorage.setItem('token', newToken) // 存储token信息
        // sessionStorage.setItem('token',this.$route.query.token) // 存储token信息
        this.getUserData() // 个人信息
      }
      // var token = sessionStorage.getItem('token')
      /**
       * 测试登录部分 登录方法  http://localhost:8080?token=
       * */
      // sessionStorage.setItem('postID', '0') // 存储  post 权限(0:管理员; 1:运营;2:顾问;)token信息
      // sessionStorage.setItem('token',  "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjFjOWYwZThlLTc5NGUtNGFlZC1iYjYyLTYzOTk2NTJlMmI4OSJ9.h7G5_kLmlzXFYusQwAyqVU-F18eG4jFPfXNdQ5qXPaYB4IMEGyX17ocoMCbT3HPjw0M5jgV79rUJAIaTwg8Saw" ) // 存储token信息
      // sessionStorage.setItem('userName', '123') // 存储用户名
      // this.getToken() // 获取token

    },
    methods: {
      //获取token
      getToken() {
        refreshToken().then(data => {
          if (data.code === 200) {
            var newToken = "bearer " + data.data  // 后台请求回来的token值
            sessionStorage.setItem('token', newToken) // 存储token信息
            this.$router.push({
              path: '/index',
            });
            // Notify({ type: 'success', message: '登录成功' })
          } else {
            Notify({type: 'danger', message: data.message})
          }
        }).catch(() => {
          Notify({type: 'warning', message: '服务器连接失败'})
        })
      },

      // 通过token 获取登录人信息
      getUserData(){

        userData().then(data => {
          if (data.code === 200) {
            sessionStorage.setItem('postID', data.data.postId) // 存储  post 权限(0:管理员; 1:运营;2:顾问;)token信息
            sessionStorage.setItem('userId', data.data.userId) // 存储  post 权限(0:管理员; 1:运营;2:顾问;)token信息
            sessionStorage.setItem('userName', data.data.userName) // 存储用户名
            sessionStorage.setItem('version', data.version) // 存储用户名
            localStorage.setItem('style', 'weChat') // 存储登录方式
            Notify({ type: 'success', message: '登录成功' })
            this.$router.push({
              path: '/index',
            });
          } else {
            Notify({type: 'danger', message: data.message})
          }
        }).catch(() => {
          Notify({type: 'warning', message: '服务器连接失败'})
        })
      },
    }
  }
</script>

<style scoped>

</style>
