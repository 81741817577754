<template>
  <div style="height: 15px"></div>
  <!-- E 头部header -->
  <div class="line-box" v-for="(item, i) in list">
    <div class="box-body">
      <div class="box-circle"></div>
      <div class="box-gray">
        <div class="gray-top">
          <div class="top-name">{{item.user_name}}</div>
          <div class="top-and">于</div>
          <div class="top-time">{{item.create_time}}</div>
        </div>
        <div class="gray-text">{{item.content}}</div>
      </div>
    </div>
  </div>
  <!-- S 底部导航栏 -->
  <div class="occupy-box"></div>
  <van-tabbar v-model="active">
    <van-tabbar-item replace to="/index">
      <span>首页</span>
      <template #icon="props">
        <div class="navIcon" v-bind:class="{ active: props.active}">
          <el-icon>
            <home-filled/>
          </el-icon>
        </div>
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/customManage">
      <span>客户管理</span>
      <template #icon="props">
        <div class="navIcon" v-bind:class="{ active: props.active}">
          <el-icon>
            <user/>
          </el-icon>
        </div>
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/signedResources">
      <span>已签约</span>
      <template #icon="props">
        <div class="navIcon" v-bind:class="{ active: props.active}">
          <el-icon>
            <edit/>
          </el-icon>
        </div>
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/sharedPool">
      <span>共享池</span>
      <template #icon="props">
        <div class="navIcon" v-bind:class="{ active: props.active}">
          <el-icon>
            <monitor/>
          </el-icon>
        </div>
      </template>
    </van-tabbar-item>
  </van-tabbar>
  <!-- S 底部导航栏 -->
</template>

<script>
  import {getJournalList} from '../api/java'
  import {Notify} from "vant";
  import {ref} from "vue";

  export default {
    name: "journal",
    data() {
      return {
        list: [], // 列表数据
        ajaxId: ''
      }
    },

    // 生命周期 初始化
    setup() {
      const active = ref(''); // 设定选定第几个
      return {
        active,
      };
    },

    created() {
      window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
      this.ajaxId = this.$route.query.id
      this.list = []
      let para={
        customerId:this.$route.query.id
      }
      getJournalList(para).then(data => {
        if (data.code === 200) {
          this.list = data.data
          for (let i = 0; i < this.list.length; i++) {
            this.list[i].create_time = this.$moment(this.list[i].create_time * 1000).format("YYYY-MM-DD HH:mm:ss")
          }
        } else {
          Notify({type: 'danger', message: data.message});
        }
      }).catch(() => {
        Notify({type: 'danger', message: '服务连接失败'});
      })
    },
    methods: {
      // 返回上一页
      goBackPage() {
        this.$router.go(-1)
      },
    }
  }
</script>

<style scoped>

  .journal-header {
    position: fixed;
    width: 100%;
  }

  .line-box {
    /*padding: 20px;*/
    /*background: red;*/
    border-left: 1px solid #DCDFE6;
    margin-left: 20px;
  }

  .box-body {
    /*width: 100%;*/
    position: absolute;
    padding-bottom: 15px;
    border-left: 1px solid #DCDFE6;
    position: relative;
  }

  .box-circle {
    position: absolute;
    left: -6px;
    top: 45%;
    width: 10px;
    height: 10px;
    background: #A1A7BA;
    opacity: 1;
    border-radius: 50%;
  }

  .gray-top {
    border-bottom: 1px solid #DCDFE6;
    font-size: 15px;
    color: #A1A7BA;
    display: flex;
    line-height: 21px;
    padding-bottom: 9px;
  }

  .top-and {
    margin: 0 8px;
  }

  .gray-text {
    margin-top: 11px;
    color: #222222;
    font-size: 15px;
    word-break: break-all;
  }

  .box-gray {
    position: relative;
    left: 5%;
    top: 0;
    width: 78%;
    padding: 18px;
    height: 64%;
    background: #FFFFFF;
    box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
    opacity: 1;
    border-radius: 10px;
  }
</style>
