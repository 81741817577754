<template>
    <div>
        <!-- S 头部header-->
        <div class="header" v-if="declare_show">
            <div class="header-nav">
                <div class="submit-btn" @click="submit()">提交</div>
            </div>
        </div>
        <!-- E 头部header -->
        <div v-if="visit_show">
            <div v-if="!declare_show" class="declare-seat"></div>
            <div v-if="group_leader_declare" class="declare-box">
                <div  class="declare-btn" @click="declare()" v-if="!declare_show">申报合格资源</div>
            </div>
        </div>
        <!-- S 内容部分(第一块) -->
        <div class="content-wrap">
            <!-- S 输入框模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>客户名称</span>
                </div>
                <div class="info-input">
                    <van-field class="vant-input-edit" v-model="customer_form.user_name" autocomplete="off" clearable
                               @focus="focusIpt"
                               @blur="blurIpt"
                               placeholder="请填写客户名称..."/>
                </div>
            </div>
            <!-- E 输入框模块 -->
            <!-- S 性别单选 -->
            <div class="info-box">
                <div class="info-title">
                    <span>性别</span>
                </div>
                <div class="radio-box">
                    <van-radio-group v-model="customer_form.user_sex" @change="sexSelect" direction="horizontal">
                        <div class="radio-item" :class="{radio_active:index+1 === sex_active}" v-for="(item, index) in sex_data"
                             @click="switchColor(index+1)">
                            <div class="radio-icon">
                                <div class="radio-icon-spot"></div>
                            </div>
                            <van-radio :name="item.id">{{item.title}}</van-radio>
                        </div>
                    </van-radio-group>
                </div>
            </div>
            <!-- E 性别单选 -->
            <!-- S 输入框模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>年龄</span>
                </div>
                <div class="info-input">
                    <van-field class="vant-input-edit" type="digit" maxlength="2" v-model="customer_form.birthday"
                               @focus="focusIpt"
                               @blur="blurIpt"
                               autocomplete="off"
                               clearable placeholder="请填写客户年龄..."/>
                </div>
            </div>
            <!-- E 输入框模块 -->
        </div>
        <!-- E 内容部分(第一块) -->
        <!-- S 内容部分(动态添加联系方式--电话) -->
        <div class="content-wrap" v-if="group_leader !== '1'">
            <!-- S 输入框模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>电话</span>
                </div>
                <div class="info-input info-input-flex">
                    <div class="content-info">
                        <span v-if="customer_form.user_phone">{{customer_form.user_phone}}</span>
                        <span v-else>暂无数据</span>
                    </div>
                    <div class="add-content" @click="addPhone">添加</div>
                </div>
            </div>
            <!-- E 输入框模块 -->
            <!-- S 输入框模块 -->
            <div class="info-box" v-for="(item, index) in concat_phone_data">
                <div class="info-title">
                    <span>电话{{index+1}}</span>
                </div>
                <div class="info-input info-input-flex">
                    <div class="concat-title">
                        <van-field class="vant-input-edit" v-model="item.name"
                                   @focus="focusIpt" @blur="blurIpt" autocomplete="off"/>
                    </div>
                    <div class="concat-info">
                        <van-field class="vant-input-edit" v-model="item.value" @focus="focusIpt"
                                   @blur="blurIpt" autocomplete="off"/>
                    </div>
                    <div class="add-content content-del" @click="delPhone(index)">删除</div>
                </div>
            </div>
            <!-- E 输入框模块 -->
        </div>
        <!-- E 内容部分(动态添加联系方式--电话) -->

        <!-- S 内容部分(动态添加联系方式--微信) -->
        <div class="content-wrap" v-if="group_leader !== '1'">
            <!-- S 输入框模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>微信</span>
                </div>
                <div class="info-input info-input-flex">
                    <div class="content-info">
                        <span v-if="customer_form.user_chat">{{customer_form.user_chat}}</span>
                        <span v-else>暂无数据</span>
                    </div>
                    <div class="add-content" @click="addVx">添加</div>
                </div>
            </div>
            <!-- E 输入框模块 -->
            <!-- S 输入框模块 -->
            <div class="info-box" v-for="(item, index) in concat_vx_data">
                <div class="info-title">
                    <span>微信{{index+1}}</span>
                </div>
                <div class="info-input info-input-flex">
                    <div class="concat-title">
                        <van-field class="vant-input-edit" v-model="item.name" @focus="focusIpt" @blur="blurIpt"
                                   autocomplete="off"/>
                    </div>
                    <div class="concat-info">
                        <van-field class="vant-input-edit" v-model="item.value" @focus="focusIpt" @blur="blurIpt"
                                   autocomplete="off"/>
                    </div>
                    <div class="add-content content-del" @click="delVx(index)">删除</div>
                </div>
            </div>
            <!-- E 输入框模块 -->
        </div>
        <!-- E 内容部分(动态添加联系方式--微信) -->

        <!-- S 内容部分(动态添加联系方式--邮箱) -->
        <div class="content-wrap" v-if="group_leader !== '1'">
            <!-- S 输入框模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>邮箱</span>
                </div>
                <div class="info-input info-input-flex">
                    <div class="content-info">
                        <span v-if="customer_form.user_email">{{customer_form.user_email}}</span>
                        <span v-else>暂无数据</span>
                    </div>
                    <div class="add-content" @click="addEmail">添加</div>
                </div>
            </div>
            <!-- E 输入框模块 -->
            <!-- S 输入框模块 -->
            <div class="info-box" v-for="(item, index) in concat_email_data">
                <div class="info-title">
                    <span>邮箱{{index+1}}</span>
                </div>
                <div class="info-input info-input-flex">
                    <div class="concat-title">
                        <van-field class="vant-input-edit" v-model="item.name" @focus="focusIpt" @blur="blurIpt"
                                   autocomplete="off"/>
                    </div>
                    <div class="concat-info">
                        <van-field class="vant-input-edit" v-model="item.value" @focus="focusIpt" @blur="blurIpt"
                                   autocomplete="off"/>
                    </div>
                    <div class="add-content content-del" @click="delEmail(index)">删除</div>
                </div>
            </div>
            <!-- E 输入框模块 -->
        </div>
        <!-- E 内容部分(动态添加联系方式--邮箱) -->

        <!-- S 内容部分(动态添加联系方式--QQ) -->
        <div class="content-wrap" v-if="group_leader !== '1'">
            <!-- S 输入框模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>QQ</span>
                </div>
                <div class="info-input info-input-flex">
                    <div class="content-info">
                        <span v-if="customer_form.user_qq">{{customer_form.user_qq}}</span>
                        <span v-else>暂无数据</span>
                    </div>
                    <div class="add-content" @click="addQq">添加</div>
                </div>
            </div>
            <!-- E 输入框模块 -->
            <!-- S 输入框模块 -->
            <div class="info-box" v-for="(item, index) in concat_qq_data">
                <div class="info-title">
                    <span>QQ{{index+1}}</span>
                </div>
                <div class="info-input info-input-flex">
                    <div class="concat-title">
                        <van-field class="vant-input-edit" v-model="item.name" @focus="focusIpt" @blur="blurIpt"
                                   autocomplete="off"/>
                    </div>
                    <div class="concat-info">
                        <van-field class="vant-input-edit" v-model="item.value" @focus="focusIpt" @blur="blurIpt"
                                   autocomplete="off"/>
                    </div>
                    <div class="add-content content-del" @click="delQq(index)">删除</div>
                </div>
            </div>
            <!-- E 输入框模块 -->
        </div>
        <!-- E 内容部分(动态添加联系方式--QQ) -->

        <div class="content-wrap">
            <!-- S 优先级单选 -->
            <div class="info-box">
                <div class="info-title">
                    <span>优先级</span>
                </div>
                <div class="radio-box">
                    <van-radio-group v-model="customer_form.contact_information_first" @change="prioritySelect"
                                     direction="horizontal">
                        <div class="radio-item" :class="{radio_active:index+1 === priority_active}"
                             v-for="(item, index) in priority_data"
                             @click="priorityColor(index+1)">
                            <div class="radio-icon">
                                <div class="radio-icon-spot"></div>
                            </div>
                            <van-radio :name="item.id">{{item.title}}</van-radio>
                        </div>
                    </van-radio-group>
                </div>
            </div>
            <!-- E 优先级单选 -->

            <!-- S 输入框模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>意向国家</span>
                </div>
                <div class="info-input">
                    <van-field class="vant-input-edit" v-model="customer_form.intentional_state" autocomplete="off" clearable
                               @focus="focusIpt" @blur="blurIpt"
                               placeholder="请填写意向国家..."/>
                </div>
            </div>
            <!-- E 输入框模块 -->
            <!-- S 下拉模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>客户等级</span>
                </div>
                <div class="info-input" @click="levelPopup">
                    <div class="popup-data">{{customerGrade(customer_form.customer_grade)}}</div>
                    <div class="popup-placeholder" v-if="!customer_form.customer_grade">请选择</div>
                    <div class="down-icon" v-if="clear_level_show">
                        <caret-bottom/>
                    </div>
                    <div class="down-icon" @click.stop="clearableLevel" v-else>
                        <van-icon name="clear"/>
                    </div>
                </div>
            </div>
            <!-- E 下拉模块 -->
            <!-- S 客户地址 -->
            <div class="info-box">
                <div class="info-title">
                    <span>客户地址</span>
                </div>
                <div class="info-input">
                    <van-field
                            class="vant-input-edit"
                            v-model="customer_form.province_m"
                            readonly
                            placeholder="请选择"
                            @click="addressShow"
                    />
                    <div class="down-icon" v-if="clear_address_show">
                        <caret-bottom/>
                    </div>
                    <div class="down-icon" @click.stop="clearableAddress" v-else>
                        <van-icon name="clear"/>
                    </div>
                </div>
            </div>
            <!-- E 客户地址 -->
            <!-- S 下拉模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>学历</span>
                </div>
                <div class="info-input" @click="educationPopup">
                    <div class="popup-data">{{education(customer_form.education)}}</div>
                    <div class="popup-placeholder" v-if="!customer_form.education">请选择</div>
                    <div class="down-icon" v-if="clear_education_show">
                        <caret-bottom/>
                    </div>
                    <div class="down-icon" @click.stop="clearableEducation" v-else>
                        <van-icon name="clear"/>
                    </div>
                </div>
            </div>
            <!-- E 下拉模块 -->
            <!-- S 输入框模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>毕业院校</span>
                </div>
                <div class="info-input">
                    <van-field class="vant-input-edit" v-model="customer_form.school" autocomplete="off" clearable
                               @focus="focusIpt" @blur="blurIpt"
                               placeholder="请填写毕业院校..."/>
                </div>
            </div>
            <!-- E 输入框模块 -->
            <!-- S 输入框模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>专业</span>
                </div>
                <div class="info-input">
                    <van-field class="vant-input-edit" v-model="customer_form.speciality" autocomplete="off" clearable
                               @focus="focusIpt" @blur="blurIpt"
                               placeholder="请填写专业..."/>
                </div>
            </div>
            <!-- E 输入框模块 -->
            <!-- S 输入框模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>职业信息</span>
                </div>
                <div class="info-input">
                    <van-field class="vant-input-edit" v-model="customer_form.work" autocomplete="off" clearable @focus="focusIpt"
                               @blur="blurIpt"
                               placeholder="请填写职业信息..."/>
                </div>
            </div>
            <!-- E 输入框模块 -->
            <!-- S 输入框模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>工作年限</span>
                </div>
                <div class="info-input">
                    <van-field class="vant-input-edit" v-model="customer_form.work_life" autocomplete="off" clearable
                               @focus="focusIpt" @blur="blurIpt"
                               placeholder="请填写工作年限..."/>
                </div>
            </div>
            <!-- E 输入框模块 -->
            <!-- S 输入框模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>资金状况</span>
                </div>
                <div class="info-input">
                    <van-field class="vant-input-edit" v-model="customer_form.capital_preparation" autocomplete="off" clearable
                               @focus="focusIpt" @blur="blurIpt"
                               placeholder="请填写资金状况..."/>
                </div>
            </div>
            <!-- E 输入框模块 -->
            <!-- S 输入框模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>外语水平</span>
                </div>
                <div class="info-input">
                    <van-field class="vant-input-edit" v-model="customer_form.language_ability" autocomplete="off" clearable
                               @focus="focusIpt" @blur="blurIpt"
                               placeholder="请填写外语水平..."/>
                </div>
            </div>
            <!-- E 输入框模块 -->
        </div>
        <div class="content-wrap content-wrap-text">
            <!-- S 输入框模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>客户序号</span>
                </div>
                <div class="not-text-box">
                    <span v-if="customer_form.id">{{customer_form.id}}</span>
                    <span v-else>暂无数据</span>
                </div>
            </div>
            <!-- E 输入框模块 -->
        </div>
        <div class="content-wrap content-wrap-text"  style="padding: 0;" v-if="customer_form.follow_counsel_name ===customer_form.official_service_name ">
            <!-- S 输入框模块 -->
            <div class="info-box" >
                <div class="info-title" style="padding-top: 15px">
                    <span>来源渠道</span>
                </div>
                <div class="not-text-box" style="padding-bottom: 15px">
                    <span v-if="customer_form.source_name">{{customer_form.source_name}}</span>
                    <span v-else>暂无数据</span>
                </div>
            </div>
            <!-- E 输入框模块 -->
            <!-- S 输入框模块 -->
            <div class="info-box" v-if="customer_form.source_name==='老客户介绍'">
                <div class="info-title" style="padding-top: 15px">
                    <span>老客户序号</span>
                </div>
                <div class="not-text-box" style="padding-bottom: 15px">
                    <span v-if="customer_form.source_text">{{customer_form.source_text}}</span>
                    <span v-else>-</span>
                </div>
            </div>
            <!-- E 输入框模块 -->
        </div>

        <div class="content-wrap content-wrap-text">
            <!-- S 输入框模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>跟单顾问</span>
                </div>
                <div class="not-text-box">
                    <span v-if="customer_form.follow_counsel_name">{{customer_form.follow_counsel_name}}</span>
                    <span v-else>暂无数据</span>
                </div>
            </div>
            <!-- E 输入框模块 -->
        </div>

        <div class="content-wrap content-wrap-text">
            <!-- S 输入框模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>运营客服</span>
                </div>
                <div class="not-text-box">
                    <span v-if="customer_form.official_service_name">{{customer_form.official_service_name}}</span>
                    <span v-else>暂无数据</span>
                </div>
            </div>
            <!-- E 输入框模块 -->
        </div>

        <div class="content-wrap content-wrap-text">
            <!-- S 复选模块 -->
            <div class="info-box info-box-checkbox">
                <div class="info-title" style="padding-left: 5px; margin-bottom: 12px">
                    <span>无效原因</span>
                </div>
                <div class="search-term-detail" @click="adviserPopup">
                    <van-field class="vant-input-edit" v-model="adviser_info" clearable readonly placeholder="请选择"/>
                    <div class="down-icon" v-if="clear_adviser_show">
                        <caret-bottom/>
                    </div>
                    <div class="down-icon" @click.stop="clearableAdviser" v-else>
                        <van-icon name="clear"/>
                    </div>
                </div>
            </div>

            <div class="info-box" v-if="invalid_visible">
                <div class="info-title">
                    <span>说明原因</span>
                </div>
                <div class="info-input">
                    <van-field class="vant-input-edit" v-model="customer_form.invalid_reason_content" autocomplete="off" clearable
                               @focus="focusIpt"
                               @blur="blurIpt"
                               placeholder="请详细说明原因..."/>
                </div>
            </div>
            <!-- E 复选模块 -->
        </div>
        <div class="content-wrap content-wrap-text">
            <!-- S 输入框模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>所属公司</span>
                </div>
                <div class="not-text-box">
                    <span v-if="customer_form.company_name!== ''">{{customer_form.company_name}}</span>
                    <span v-else>-</span>
                </div>
            </div>
            <!-- E 输入框模块 -->
        </div>
        <div class="content-wrap content-wrap-text">
            <!-- S 输入框模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>资源方</span>
                </div>
                <div class="not-text-box">
                    <span v-if="customer_form.resource!== ''">{{customer_form.resource}}</span>
                    <span v-else>-</span>
                </div>
            </div>
            <!-- E 输入框模块 -->
        </div>
        <div class="content-wrap">
            <!-- S 单选模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>是否为企业业主</span>
                </div>
                <div class="radio-box phone-type">
                    <van-radio-group v-model="customer_form.is_company" @change="enterpriseSelect" direction="horizontal">
                        <div class="radio-item" :class="{radio_active:index+1 === company_main_active}"
                             v-for="(item, index) in company_main_data"
                             @click="enterpriseColor(index+1)">
                            <div class="radio-icon">
                                <div class="radio-icon-spot"></div>
                            </div>
                            <van-radio :name="index+1">{{item}}</van-radio>
                        </div>
                    </van-radio-group>
                </div>
            </div>
            <!-- E 单选模块 -->
            <!-- S 企业其他信息 -->
            <div v-if="other_company_show">
                <!-- S 输入框模块 -->
                <div class="info-box">
                    <div class="info-title">
                        <span>企业规模</span>
                    </div>
                    <div class="info-input">
                        <van-field class="vant-input-edit" v-model="customer_form.scale" autocomplete="off" clearable
                                   @focus="focusIpt" @blur="blurIpt"
                                   placeholder="请填写企业规模..."/>
                    </div>
                </div>
                <!-- E 输入框模块 -->
                <!-- S 输入框模块 -->
                <div class="info-box">
                    <div class="info-title">
                        <span>主营业务</span>
                    </div>
                    <div class="info-input">
                        <van-field class="vant-input-edit" v-model="customer_form.business" autocomplete="off" clearable
                                   @focus="focusIpt" @blur="blurIpt"
                                   placeholder="请填写主营业务..."/>
                    </div>
                </div>
                <!-- E 输入框模块 -->
                <!-- S 下拉模块 -->
                <div class="info-box">
                    <div class="info-title">
                        <span>企业成立时间</span>
                    </div>
                    <div class="info-input" @click="createPopup">
                        <van-field class="vant-input-edit" v-model="create_time" readonly placeholder="请选择"/>
                        <div class="down-icon" v-if="clear_create_show">
                            <caret-bottom/>
                        </div>
                        <div class="down-icon" @click.stop="clearableCreate" v-else>
                            <van-icon name="clear"/>
                        </div>
                    </div>
                </div>
                <!-- E 下拉模块 -->
            </div>
            <!-- E 企业其他信息 -->
        </div>

        <div class="content-wrap">
            <!-- S 单选模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>其他国家护照情况</span>
                </div>
                <div class="radio-box">
                    <van-radio-group v-model="customer_form.third_party_country_status" @change="passportSelect"
                                     direction="horizontal">
                        <div class="radio-item" :class="{radio_active:index+1 === passport_active}"
                             v-for="(item, index) in passport_data"
                             @click="passportColor(index+1)">
                            <div class="radio-icon">
                                <div class="radio-icon-spot"></div>
                            </div>
                            <van-radio :name="item.id">{{item.name}}</van-radio>
                        </div>
                    </van-radio-group>
                </div>
            </div>
            <!-- E 单选模块 -->
            <!-- S 文本域模块 -->
            <div class="info-box" v-if="passport_info_show">
                <div class="info-title">
                    <span>其他国家护照详情</span>
                </div>
                <div class="info-input info-textarea">
                    <van-field
                            class="info-textarea-edit"
                            v-model="customer_form.third_party_country"
                            autocomplete="off"
                            rows="1"
                            autosize
                            type="textarea"
                            placeholder="请输入"
                            @focus="focusIpt"
                            @blur="blurIpt"
                    />
                </div>
            </div>
            <!-- E 文本域模块 -->
        </div>
        <div class="content-wrap">
            <!-- S 单选模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>其他国家绿卡情况</span>
                </div>
                <div class="radio-box">
                    <van-radio-group v-model="customer_form.green_card_status" @change="greenCardSelect" direction="horizontal">
                        <div class="radio-item" :class="{radio_active:index+1 === green_card_active}"
                             v-for="(item, index) in green_card_data"
                             @click="greenCardColor(index+1)">
                            <div class="radio-icon">
                                <div class="radio-icon-spot"></div>
                            </div>
                            <van-radio :name="item.id">{{item.name}}</van-radio>
                        </div>
                    </van-radio-group>
                </div>
            </div>
            <!-- E 单选模块 -->
            <!-- S 文本域模块 -->
            <div class="info-box" v-if="green_card_show">
                <div class="info-title">
                    <span>其他国家绿卡详情</span>
                </div>
                <div class="info-input info-textarea">
                    <van-field
                            class="info-textarea-edit"
                            v-model="customer_form.green_card"
                            autocomplete="off"
                            rows="1"
                            autosize
                            type="textarea"
                            placeholder="请输入"
                            @focus="focusIpt"
                            @blur="blurIpt"
                    />
                </div>
            </div>
            <!-- E 文本域模块 -->
        </div>

        <div class="content-wrap">
            <!-- S 单选模块 -->
            <div class="info-box" style="margin-bottom: 6px">
                <div class="info-title">
                    <span>婚姻状况</span>
                </div>
                <div class="radio-box">
                    <van-radio-group v-model="customer_form.marriage" @change="marriageSelect" direction="horizontal">
                        <div class="radio-item" style="margin-bottom: 10px" :class="{radio_active:index+1 === marriage_active}"
                             v-for="(item, index) in marriage_data"
                             @click="marriageColor(index+1)">
                            <div class="radio-icon">
                                <div class="radio-icon-spot"></div>
                            </div>
                            <van-radio :name="item.id">{{item.name}}</van-radio>
                        </div>
                    </van-radio-group>
                </div>
            </div>
            <!-- E 单选模块 -->
            <div v-if="marriage_show">
                <!-- S 输入框模块 -->
                <div class="info-box">
                    <div class="info-title">
                        <span>配偶电话</span>
                    </div>
                    <div class="info-input info-input-marriage">
                        <div class="marriage-item">
                            <van-field class="vant-input-edit" type="digit" maxlength="11" v-model="spouse_phone" autocomplete="off"
                                       clearable @focus="focusIpt" @blur="blurIpt" placeholder="请填写电话..."/>
                        </div>
                        <div class="marriage-item-btn" @click="searchSpouse">搜索</div>
                    </div>
                </div>
                <!-- E 输入框模块 -->
                <!-- S 输入框模块 -->
                <div class="info-box">
                    <div class="info-title">
                        <span>配偶姓名</span>
                    </div>
                    <div class="info-input">
                        <van-field class="vant-input-edit" v-model="spouse_name" autocomplete="off" clearable @focus="focusIpt"
                                   @blur="blurIpt"
                                   placeholder="请填写姓名..."/>
                    </div>
                </div>
                <!-- E 输入框模块 -->
                <!-- S 输入框模块 -->
                <div class="info-box">
                    <div class="info-title">
                        <span>配偶微信</span>
                    </div>
                    <div class="info-input">
                        <van-field class="vant-input-edit" v-model="spouse_vx" autocomplete="off" clearable @focus="focusIpt"
                                   @blur="blurIpt"
                                   placeholder="请填写微信..."/>
                    </div>
                </div>
                <!-- E 输入框模块 -->
                <!-- S 输入框模块 -->
                <div class="info-box">
                    <div class="info-title">
                        <span>配偶邮箱</span>
                    </div>
                    <div class="info-input">
                        <van-field class="vant-input-edit" v-model="spouse_email" autocomplete="off" clearable @focus="focusIpt"
                                   @blur="blurIpt"
                                   placeholder="请填写邮箱..."/>
                    </div>
                </div>
                <!-- E 输入框模块 -->
                <!-- S 输入框模块 -->
                <div class="info-box">
                    <div class="info-title">
                        <span>配偶年龄</span>
                    </div>
                    <div class="info-input">
                        <van-field class="vant-input-edit" type="digit" maxlength="2" v-model="spouse_age" autocomplete="off"
                                   @focus="focusIpt" @blur="blurIpt" clearable placeholder="请填写年龄..."/>
                    </div>
                </div>
                <!-- E 输入框模块 -->
                <!-- S 输入框模块 -->
                <div class="info-box">
                    <div class="info-title">
                        <span>配偶QQ</span>
                    </div>
                    <div class="info-input">
                        <van-field class="vant-input-edit" type="digit" v-model="spouse_qq" autocomplete="off" clearable
                                   @focus="focusIpt" @blur="blurIpt" placeholder="请填写QQ..."/>
                    </div>
                </div>
                <!-- E 输入框模块 -->
                <!-- S 下拉模块 -->
                <div class="info-box">
                    <div class="info-title">
                        <span>配偶学历</span>
                    </div>
                    <div class="info-input" @click="spouseEducationPopup">
                        <div class="popup-data">{{education(spouse_education)}}</div>
                        <div class="popup-placeholder" v-if="!spouse_education">请选择</div>
                        <div class="down-icon" v-if="spouse_education_visible">
                            <caret-bottom/>
                        </div>
                        <div class="down-icon" @click.stop="clearableSpouseEducation" v-else>
                            <van-icon name="clear"/>
                        </div>
                    </div>
                </div>
                <!-- E 下拉模块 -->
                <!-- S 输入框模块 -->
                <div class="info-box">
                    <div class="info-title">
                        <span>配偶专业</span>
                    </div>
                    <div class="info-input">
                        <van-field class="vant-input-edit" v-model="spouse_major" autocomplete="off" clearable @focus="focusIpt"
                                   @blur="blurIpt" placeholder="请填写专业..."/>
                    </div>
                </div>
                <!-- E 输入框模块 -->
                <!-- S 输入框模块 -->
                <div class="info-box">
                    <div class="info-title">
                        <span>配偶职业</span>
                    </div>
                    <div class="info-input">
                        <van-field class="vant-input-edit" v-model="spouse_occupation" autocomplete="off" clearable
                                   @focus="focusIpt" @blur="blurIpt" placeholder="请填写职业..."/>
                    </div>
                </div>
                <!-- E 输入框模块 -->
                <!-- S 输入框模块 -->
                <div class="info-box">
                    <div class="info-title">
                        <span>工作年限</span>
                    </div>
                    <div class="info-input">
                        <van-field class="vant-input-edit" v-model="spouse_work_years" autocomplete="off" clearable
                                   @focus="focusIpt" @blur="blurIpt" placeholder="请填写工作年限..."/>
                    </div>
                </div>
                <!-- E 输入框模块 -->
                <!-- S 输入框模块 -->
                <div class="info-box">
                    <div class="info-title">
                        <span>外语水平</span>
                    </div>
                    <div class="info-input">
                        <van-field class="vant-input-edit" v-model="spouse_language_level" autocomplete="off" clearable
                                   @focus="focusIpt" @blur="blurIpt" placeholder="请填写外语水平..."/>
                    </div>
                </div>
                <!-- E 输入框模块 -->
                <!-- S 单选模块 -->
                <div class="info-box">
                    <div class="info-title">
                        <span>其他国家护照情况</span>
                    </div>
                    <div class="radio-box">
                        <van-radio-group v-model="spouse_passport_status" @change="spousePassportSelect" direction="horizontal">
                            <div class="radio-item" :class="{radio_active:index === spouse_passport_active}"
                                 v-for="(item, index) in spouse_passport_data"
                                 @click="spousePassportColor(index)">
                                <div class="radio-icon">
                                    <div class="radio-icon-spot"></div>
                                </div>
                                <van-radio :name="item.id">{{item.name}}</van-radio>
                            </div>
                        </van-radio-group>
                    </div>
                </div>
                <!-- E 单选模块 -->
                <!-- S 文本域模块 -->
                <div class="info-box" v-if="spouse_passport_show">
                    <div class="info-title">
                        <span>其他国家护照详情</span>
                    </div>
                    <div class="info-input info-textarea">
                        <van-field
                                class="info-textarea-edit"
                                v-model="spouse_passport_info"
                                autocomplete="off"
                                rows="1"
                                autosize
                                @focus="focusIpt"
                                @blur="blurIpt"
                                type="textarea"
                                placeholder="请输入"
                        />
                    </div>
                </div>
                <!-- E 文本域模块 -->
                <!-- S 单选模块 -->
                <div class="info-box">
                    <div class="info-title">
                        <span>其他国家绿卡情况</span>
                    </div>
                    <div class="radio-box phone-type">
                        <van-radio-group v-model="spouse_card_status" @change="spouseCardSelect" direction="horizontal">
                            <div class="radio-item" :class="{radio_active:index+1 === spouse_card_active}"
                                 v-for="(item, index) in spouse_card_data"
                                 @click="spouseCardColor(index+1)">
                                <div class="radio-icon">
                                    <div class="radio-icon-spot"></div>
                                </div>
                                <van-radio :name="item.id">{{item.name}}</van-radio>
                            </div>
                        </van-radio-group>
                    </div>
                </div>
                <!-- E 单选模块 -->
                <!-- S 文本域模块 -->
                <div class="info-box" v-if="spouse_card_show">
                    <div class="info-title">
                        <span>其他国家绿卡详情</span>
                    </div>
                    <div class="info-input info-textarea">
                        <van-field
                                class="info-textarea-edit"
                                v-model="spouse_card_info"
                                autocomplete="off"
                                rows="1"
                                autosize
                                @focus="focusIpt"
                                @blur="blurIpt"
                                type="textarea"
                                placeholder="请输入"
                        />
                    </div>
                </div>
                <!-- E 文本域模块 -->
            </div>
        </div>

        <div class="content-wrap" v-if="false">
            <!-- S 单选模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>签单状态</span>
                </div>
                <div class="radio-box">
                    <van-radio-group v-model="customer_form.issign_bill" @change="signSelect" direction="horizontal">
                        <div class="radio-item" :class="{radio_active:index+1 === sign_active}"
                             v-for="(item, index) in sign_data"
                             @click="signColor(index+1)">
                            <div class="radio-icon">
                                <div class="radio-icon-spot"></div>
                            </div>
                            <van-radio :name="item.id">{{item.name}}</van-radio>
                        </div>
                    </van-radio-group>
                </div>
            </div>
            <!-- E 单选模块 -->
            <div v-if="sign_show">
                <!-- S 下拉模块 -->
                <div class="info-box">
                    <div class="info-title">
                        <span>签单时间</span>
                    </div>
                    <div class="info-input" @click="signPopup">
                        <van-field class="vant-input-edit" v-model="sign_time" readonly placeholder="请选择"/>
                        <div class="down-icon" v-if="clear_sign_show">
                            <caret-bottom/>
                        </div>
                        <div class="down-icon" @click.stop="clearableSign" v-else>
                            <van-icon name="clear"/>
                        </div>
                    </div>
                </div>
                <!-- E 下拉模块 -->
                <!-- S 输入框模块 -->
                <!--        <div class="info-box">-->
                <!--          <div class="info-title">-->
                <!--            <span>签约项目信息</span>-->
                <!--          </div>-->
                <!--          <div class="info-input">-->
                <!--            <van-field class="vant-input-edit" v-model="customer_form.project" autocomplete="off" clearable-->
                <!--                       @focus="focusIpt" @blur="blurIpt" placeholder="请填写签约项目信息"/>-->
                <!--          </div>-->
                <!--        </div>-->
                <!-- E 输入框模块 -->

                <!-- S 下拉模块 -->
                <div class="info-box info-box-checkbox">
                    <div class="info-title" style="padding-left: 5px; margin-bottom: 12px">
                        <span>签约项目信息</span>
                    </div>
                    <div class="search-term-detail" @click="signProjectPopup">
                        <van-field class="vant-input-edit" v-model="sign_project" clearable readonly placeholder="请选择"/>
                        <div class="down-icon" v-if="clear_project_show">
                            <caret-bottom/>
                        </div>
                        <div class="down-icon" @click.stop="clearableSignProject" v-else>
                            <van-icon name="clear"/>
                        </div>
                    </div>
                </div>
                <!-- E 下拉模块 -->

                <!-- S 下拉模块 -->
                <div class="info-box info-box-checkbox" v-if="condition_visible">
                    <div class="info-title" style="padding-left: 5px; margin-bottom: 12px">
                        <span>客户条件</span>
                    </div>
                    <div class="search-term-detail" @click="conditionPopup">
                        <van-field class="vant-input-edit" v-model="condition_val" clearable readonly placeholder="请选择"/>
                        <div class="down-icon" v-if="clear_condition_show">
                            <caret-bottom/>
                        </div>
                        <div class="down-icon" @click.stop="clearableCondition" v-else>
                            <van-icon name="clear"/>
                        </div>
                    </div>
                </div>
                <!-- E 下拉模块 -->
            </div>
        </div>

        <div class="content-wrap">
            <!-- S 文本域模块 -->
            <div class="info-box">
                <div class="info-title">
                    <span>客户咨询备注</span>
                </div>
                <div class="info-input info-textarea">
                    <van-field
                            class="info-textarea-edit"
                            v-model="customer_form.remarks"
                            autocomplete="off"
                            rows="1"
                            autosize
                            @focus="focusIpt"
                            @blur="blurIpt"
                            type="textarea"
                            placeholder="请输入"
                    />
                </div>
            </div>
            <!-- E 文本域模块 -->
        </div>
        <div>
            <div class="content-wrap">
                <div class="remarks-top">
                    <div class="remarks-title">备注</div>
                    <div v-if="group_leader_declare">
                        <div class="remarks-add" v-if="declare_show" @click="remarksAdd">添加</div>
                    </div>
                </div>
                <div class="remarks-info" v-if="remarks_data" v-for="(item, index) in remarks_data">
                    <div class="remarks-item">
                        <div class="remarks-item-title">添加人</div>
                        <div class="remarks-item-content">{{item.user_name}}</div>
                    </div>
                    <div class="remarks-item">
                        <div class="remarks-item-title">时间</div>
                        <div class="remarks-item-content">{{formatDate(item.create_time)}}</div>
                    </div>
                    <div class="remarks-item-info">
                        <div class="remarks-item-title">内容</div>
                        <div class="remarks-item-content" style="margin-top: 8px">{{item.content}}</div>
                    </div>
                </div>
                <div class="remarks-not-data" v-if="remarks_data.length === 0">暂无数据</div>
            </div>
        </div>

        <div style="margin-bottom: 15px">
            <div class="content-wrap">
                <div class="remarks-top">
                    <div class="remarks-title">签证历史</div>
                    <div v-if="group_leader_declare">
                        <div class="remarks-add" v-if="declare_show" @click="historyAdd">添加</div>
                    </div>
                </div>
                <div class="remarks-info history-info" v-if="history_data" v-for="(item, index) in history_data">
                    <div class="remarks-item">
                        <div class="remarks-item-title">签证国家</div>
                        <div class="remarks-item-content" v-if="item.country!==''">{{item.country}}</div>
                        <div v-else>-</div>
                    </div>
                    <div class="remarks-item">
                        <div class="remarks-item-title">签证类型</div>
                        <div class="remarks-item-content" v-if="item.type!==''">{{item.type}}</div>
                        <div v-else>-</div>
                    </div>
                    <div class="remarks-item-info">
                        <div class="remarks-item-title">提交信息</div>
                        <div class="remarks-item-content" style="margin: 8px 0 15px" v-if="item.submit_info!==''">{{item.submit_info}}</div>
                        <div v-else>-</div>
                    </div>
                    <div class="remarks-item">
                        <div class="remarks-item-title">工作单位</div>
                        <div class="remarks-item-content" v-if="item.work_unit!==''">{{item.work_unit}}</div>
                        <div v-else>-</div>
                    </div>
                    <div class="remarks-item-info">
                        <div class="remarks-item-title">工作职责</div>
                        <div class="remarks-item-content" style="margin: 8px 0 15px" v-if="item.operating_duty!==''">{{item.operating_duty}}</div>
                        <div v-else>-</div>
                    </div>
                    <div class="remarks-item">
                        <div class="remarks-item-title">提交时间</div>
                        <div class="remarks-item-content">{{formatDate2(item.time_of_submission)}}</div>
                    </div>
                    <div class="remarks-item">
                        <div class="remarks-item-title">有无拒签史</div>
                        <div class="remarks-item-content">
                            <span v-if="item.refused === 2">无</span>
                            <span v-else>有</span>
                        </div>
                    </div>
                    <div class="remarks-item-info" v-if="item.refused !== 2">
                        <div class="remarks-item-title">拒签原因</div>
                        <div class="remarks-item-content" style="margin: 8px 0 15px">{{item.reason}}</div>
                    </div>
                    <div class="history-btn">
                        <div class="history-btn-item" v-if="declare_show" @click="historyEdit(item)">修改</div>
                        <div class="history-btn-item" v-if="declare_show" @click="historyDel(item.id)">删除</div>
                    </div>
                </div>
                <div class="remarks-not-data" v-if="history_data.length === 0">暂无数据</div>
            </div>
        </div>
        <div class="header-seat" v-if="declare_show"></div>
        <!-- S 电话弹窗dialog -->
        <div class="phone-dialog">
            <van-dialog
                    title="添加电话"
                    v-model:show="phone_show"
                    :before-close="onPhoneAddClose"
                    show-cancel-button>
                <div class="dialog-phone-box">
                    <div class="info-title">
                        <span>电话类型</span>
                    </div>
                    <div class="radio-box phone-type">
                        <van-radio-group v-model="phone_type" @change="phoneTypeChange" direction="horizontal">
                            <div class="radio-item" :class="{radio_active:index+1 === phone_type_active}"
                                 v-for="(item, index) in phone_type_data"
                                 @click="phoneTypeColor(index+1)">
                                <div class="radio-icon">
                                    <div class="radio-icon-spot"></div>
                                </div>
                                <van-radio :name="index+1">{{item}}</van-radio>
                            </div>
                        </van-radio-group>
                    </div>
                </div>
                <div class="dialog-phone-box">
                    <div class="dialog-phone-name">姓名</div>
                    <div class="dialog-phone-detail">
                        <van-field v-model="add_phone_name" autocomplete="off" clearable
                                   placeholder="请填写姓名..."/>
                    </div>
                </div>
                <div class="dialog-phone-box">
                    <div class="dialog-phone-name">电话</div>
                    <div class="dialog-phone-detail">
                        <van-field v-model="add_phone_text" autocomplete="off" clearable
                                   placeholder="请填写电话号码..."/>
                    </div>
                </div>
            </van-dialog>
        </div>
        <!-- E 电话弹窗dialog -->

        <!-- S QQ弹窗dialog -->
        <div class="phone-dialog">
            <van-dialog
                    title="添加QQ"
                    v-model:show="qq_show"
                    :before-close="onQqAddClose"
                    show-cancel-button>
                <div class="dialog-phone-box">
                    <div class="dialog-phone-name">姓名</div>
                    <div class="dialog-phone-detail">
                        <van-field v-model="add_qq_name" autocomplete="off" clearable
                                   placeholder="请填写姓名..."/>
                    </div>
                </div>
                <div class="dialog-phone-box">
                    <div class="dialog-phone-name">QQ</div>
                    <div class="dialog-phone-detail">
                        <van-field v-model="add_qq_text" autocomplete="off" clearable type="digit"
                                   placeholder="请填写QQ号..."/>
                    </div>
                </div>
            </van-dialog>
        </div>
        <!-- E QQ弹窗dialog -->

        <!-- S 微信弹窗dialog -->
        <div class="phone-dialog">
            <van-dialog
                    title="添加微信"
                    v-model:show="vx_show"
                    :before-close="onVxAddClose"
                    show-cancel-button>
                <div class="dialog-phone-box">
                    <div class="dialog-phone-name">姓名</div>
                    <div class="dialog-phone-detail">
                        <van-field v-model="add_vx_name" autocomplete="off" clearable
                                   placeholder="请填写姓名..."/>
                    </div>
                </div>
                <div class="dialog-phone-box">
                    <div class="dialog-phone-name">微信</div>
                    <div class="dialog-phone-detail">
                        <van-field v-model="add_vx_text" autocomplete="off" clearable
                                   placeholder="请填写微信..."/>
                    </div>
                </div>
            </van-dialog>
        </div>
        <!-- E 微信弹窗dialog -->

        <!-- S 邮箱弹窗dialog -->
        <div class="phone-dialog">
            <van-dialog
                    title="添加邮箱"
                    v-model:show="email_show"
                    :before-close="onEmailAddClose"
                    show-cancel-button>
                <div class="dialog-phone-box">
                    <div class="dialog-phone-name">姓名</div>
                    <div class="dialog-phone-detail">
                        <van-field v-model="add_email_name" autocomplete="off" clearable
                                   placeholder="请填写姓名..."/>
                    </div>
                </div>
                <div class="dialog-phone-box">
                    <div class="dialog-phone-name">邮箱</div>
                    <div class="dialog-phone-detail">
                        <van-field v-model="add_email_text" autocomplete="off" clearable
                                   placeholder="请填写邮箱..."/>
                    </div>
                </div>
            </van-dialog>
        </div>
        <!-- E 邮箱弹窗dialog -->
        <!-- S 客户等级弹层 -->
        <van-popup v-model:show="level_popup_show" position="bottom" :style="{ height: '48%' }">
            <van-picker
                    title="请选择客户等级"
                    show-toolbar
                    :columns="get_level_data"
                    :columns-field-names="{text: 'name'}"
                    @confirm="levelConfirm"
                    @cancel="levelCancel"
            />
        </van-popup>
        <!-- E 客户等级弹层 -->

        <!-- S 客户地址Popup -->
        <van-popup v-model:show="address_show" position="bottom">
            <van-cascader
                    v-if="address_cascader_visibel"
                    v-model="address_value"
                    title="请选择客户地址"
                    :options="address_data"
                    :field-names="addressNames"
                    @close="address_show = false"
                    @finish="addressFinish"
            />
        </van-popup>
        <!-- E 客户地址Popup -->

        <!-- S 学历弹层 -->
        <van-popup v-model:show="education_show" position="bottom" :style="{ height: '48%' }">
            <van-picker
                    title="请选择学历"
                    show-toolbar
                    :columns="get_education_data"
                    :columns-field-names="{text: 'label'}"
                    @confirm="educationConfirm"
                    @cancel="educationCancel"
            />
        </van-popup>
        <!-- E 学历弹层 -->

        <!-- S 企业成立时间弹层 -->
        <van-popup v-model:show="create_time_show" position="bottom" :style="{ height: '48%' }">
            <van-datetime-picker
                    v-model="create_current_date"
                    type="date"
                    title="选择成立时间"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :formatter="formatter"
                    @confirm="createTimeConfirm"
                    @cancel="createTimeCancel"
            />
        </van-popup>
        <!-- E 企业成立时间弹层 -->
        <!-- S 学历弹层 -->
        <van-popup v-model:show="spouse_education_show" position="bottom" :style="{ height: '48%' }">
            <van-picker
                    title="请选择配偶学历"
                    show-toolbar
                    :columns-field-names="{text: 'label'}"
                    :columns="spouse_education_data"
                    @confirm="spouseEducationConfirm"
                    @cancel="spouseEducationCancel"
            />
        </van-popup>
        <!-- E 学历弹层 -->
        <!-- S 签约项目弹层 -->
        <van-popup v-model:show="sign_project_show" position="bottom" :style="{ height: '48%' }">
            <van-picker
                    title="请选择签约项目"
                    show-toolbar
                    :columns-field-names="{text: 'title'}"
                    :columns="sign_project_data"
                    @confirm="signProjectConfirm"
                    @cancel="signProjectCancel"
            />
        </van-popup>
        <!-- E 签约项目弹层 -->
        <!-- S 客户条件弹层 -->
        <van-popup v-model:show="condition_show" position="bottom" :style="{ height: '48%' }">
            <van-picker
                    title="请选择客户条件"
                    show-toolbar
                    :columns-field-names="{text: 'condition'}"
                    :columns="condition_data"
                    @confirm="conditionConfirm"
                    @cancel="conditionCancel"
            />
        </van-popup>
        <!-- E 客户条件弹层 -->
        <!-- S 签约时间弹层 -->
        <van-popup v-model:show="sign_time_show" position="bottom" :style="{ height: '48%' }">
            <van-datetime-picker
                    v-model="sign_current_date"
                    type="datetime"
                    title="选择签单时间"
                    :min-date="signMinDate"
                    :max-date="signMaxDate"
                    :formatter="formatter"
                    @confirm="signTimeConfirm"
                    @cancel="signTimeCancel"
            />
        </van-popup>
        <!-- E 签约时间弹层 -->

        <!-- S 无效原因弹层 -->
        <van-popup v-model:show="adviser_show" position="bottom" :style="{ height: '48%' }">
            <van-picker
                    title="请选择无效原因"
                    show-toolbar
                    :columns="adviser_data"
                    :columns-field-names="{text: 'title'}"
                    @confirm="adviserConfirm"
                    @cancel="adviserCancel"
            />
        </van-popup>
        <!-- E 无效原因弹层 -->

        <!-- S 底部导航栏 -->
        <!--    <div class="occupy-box"></div>-->
        <!--    <van-tabbar v-model="active" v-if="bottom_nav_visible">-->
        <!--      <van-tabbar-item replace to="/index">-->
        <!--        <span>首页</span>-->
        <!--        <template #icon="props">-->
        <!--          <div class="navIcon" v-bind:class="{ active: props.active}">-->
        <!--            <el-icon>-->
        <!--              <home-filled/>-->
        <!--            </el-icon>-->
        <!--          </div>-->
        <!--        </template>-->
        <!--      </van-tabbar-item>-->
        <!--      <van-tabbar-item replace to="/customManage">-->
        <!--        <span>客户管理</span>-->
        <!--        <template #icon="props">-->
        <!--          <div class="navIcon" v-bind:class="{ active: props.active}">-->
        <!--            <el-icon>-->
        <!--              <user/>-->
        <!--            </el-icon>-->
        <!--          </div>-->
        <!--        </template>-->
        <!--      </van-tabbar-item>-->
        <!--      <van-tabbar-item replace to="/signedResources">-->
        <!--        <span>已签约</span>-->
        <!--        <template #icon="props">-->
        <!--          <div class="navIcon" v-bind:class="{ active: props.active}">-->
        <!--            <el-icon>-->
        <!--              <edit/>-->
        <!--            </el-icon>-->
        <!--          </div>-->
        <!--        </template>-->
        <!--      </van-tabbar-item>-->
        <!--      <van-tabbar-item replace to="/sharedPool">-->
        <!--        <span>共享池</span>-->
        <!--        <template #icon="props">-->
        <!--          <div class="navIcon" v-bind:class="{ active: props.active}">-->
        <!--            <el-icon>-->
        <!--              <monitor/>-->
        <!--            </el-icon>-->
        <!--          </div>-->
        <!--        </template>-->
        <!--      </van-tabbar-item>-->
        <!--    </van-tabbar>-->
        <!-- S 底部导航栏 -->
    </div>
</template>

<script>



import {
    areaDown,
    invalidDown,
    getCustomerSpouse,
    visaHistoryList,
    userContentList,
    delVisaHistory,
    // saveCustomerDetail,
    declareResources,
    signedProject,
    // getCustomerInfo
} from '../api/java.js'

import {
    getCustomerInfo,
    saveCustomerDetail
} from '../api/index.js'

import {Toast, Dialog, Notify} from 'vant';
import moment from "moment";
import {ref} from "vue";

export default {
    data() {
        return {
            /* ---- 重置下拉部分 ---- */
            clear_level_show: true, // 等级重置按钮
            clear_address_show: true, // 客户地址重置按钮
            clear_education_show: true, // 学历重置按钮
            clear_create_show: true, // 企业成立时间重置按钮
            spouse_education_visible: true, // 配偶学历重置按钮
            clear_sign_show: true, // 签单时间重置按钮
            clear_adviser_show: true, // 签约顾问重置按钮
            clear_project_show: true, // 签约项目重置按钮
            clear_condition_show: true, // 客户调价重置按钮

            post_id_show: true, // 岗位权限
            group_leader: '', // 是否为组长1是
            group_leader_declare: true, // 组长进来资源按钮处理
            visit_show: true, // 客户回访隐藏申报合格资源
            declare_show: true, // 是否显示申报合格资源按钮
            visit_submit: false, // 客户回访展示提交按钮
            query_id: '', // 上个页面传递过来的客户id
            customer_form: {}, // 客户详情大表单
            customer_name: '', // 客户名称
            customer_age: '', // 年龄
            customer_phone: '18899990000', // 客户电话
            customer_qq: '', // 客户QQ
            customer_vx: '', // 客户微信
            customer_email: 'zhangxingdi@163.com', // 客户邮箱
            customer_to_country: '', // 意向国家
            graduation_school: '', // 毕业院校
            study_major: '', // 专业
            occupation_info: '', // 职业信息
            work_years: '', // 工作年限
            fund_status: '', // 资金状况
            language_level: '', // 外语水平
            source: '', // 来源渠道
            follow_adviser: '范嘉瑞', // 来源渠道
            entered_by: '', //运营客服
            customer_ask_remarks: '', // 客户咨询备注

            /* ------ 性别单选 ------ */
            radio: 3, // 性别回显
            sex_id: '0', // 性别id(请求时传递的参数)
            sex_data: [
                {id: '1', title: '男'},
                {id: '2', title: '女'},
                {id: '0', title: '未知'},
            ],
            sex_active: null, // 性别选中样式

            /* ------ 是否为企业主单选 ------ */
            company_main: 1, // 企业主回显
            company_main_data: ['是', '否'],
            company_main_active: null, // 企业主选中样式
            other_company_show: false, // 企业相关信息展示隐藏
            company_scale: '', // 企业规模
            main_business: '', // 主营业务
            create_time: '', // 成立时间
            create_time_show: false, // 企业成立时间弹层显示隐藏
            minDate: new Date(1970, 0, 1),
            maxDate: new Date(),
            create_current_date: new Date(),
            formatter(type, val) {
                if (type === 'year') {
                    return `${val}年`;
                } else if (type === 'month') {
                    return `${val}月`;
                } else if (type === 'day') {
                    return `${val}日`;
                }
                return val;
            },

            /* ---- 护照状态 ---- */
            passport_status: '1', // 护照状态
            passport_data: [
                {id: 0, name: '无'},
                {id: 1, name: '有'},
            ],
            passport_active: null, // 护照选中样式
            passport_info_show: false, // 护照情况详情显示隐藏
            passport_info: '', // 护照情况详情

            /* ---- 绿卡状态 ---- */
            green_card_status: '2', // 绿卡状态
            green_card_data: [
                {id: 2, name: '无'},
                {id: 1, name: '有'},
            ],
            green_card_active: null, // 绿卡选中样式
            green_card_show: false, // 绿卡详情显示隐藏
            green_card_info: '', // 绿卡详情

            /**
             * 婚姻状况
             * */
            /* ---- 婚姻状况 ---- */
            marriage_status: 1, // 婚姻状况
            marriage_data: [
                {id: 0, name: '未知'},
                {id: 1, name: '已婚'},
                {id: 2, name: '未婚'},
                {id: 3, name: '离异'},
                {id: 4, name: '丧偶'},
            ],
            marriage_form: {
                birthday: '', //年龄
                education: '', // 学历
                green_card: '', // 绿卡详情
                green_card_status: '', // 绿卡状态
                language_ability: '', // 英语水平
                speciality: '', // 所学专业
                third_party_country: '', // 护照详情
                third_party_country_status: '', // 护照状态
                user_chat: '', // 微信
                user_name: '', // 姓名
                user_email: '', // 邮箱
                user_phone: '', // 电话
                user_qq: '', // QQ
                work: '', // 职业
                work_life: '', // 年限
            }, // 配偶提交大表单
            marriage_active: null, // 婚姻状况选中样式
            marriage_show: false, // 配偶情显示隐藏
            spouse_phone: '', // 配偶电话
            spouse_name: '', // 配偶姓名
            spouse_vx: '', // 配偶微信
            spouse_email: '', // 配偶邮箱
            spouse_age: '', // 配偶年龄
            spouse_qq: '', // 配偶QQ
            spouse_major: '', // 配偶专业
            spouse_occupation: '', // 配偶职业
            spouse_work_years: '', // 工作年限
            spouse_language_level: '', // 外语水平

            /* ---- 配偶学历部分 ---- */
            spouse_education_show: false, // 配偶学历弹出层
            spouse_education: '', // 配偶学历
            spouse_education_data: [
                {value: 0, label: '未知'},
                {value: 1, label: '博士'},
                {value: 2, label: '硕士'},
                {value: 3, label: '本科'},
                {value: 4, label: '专科'},
                {value: 5, label: '高中及以下'},
            ],

            /* ---- 签约项目部分 ---- */
            sign_project_show: false, // 签约项目弹出层
            sign_project: '', // 签约项目
            sign_project_data: [], // 签约项目列表

            /* ---- 客户条件 ---- */
            condition_visible: false, // 客户条件下拉是否可见
            condition_content: [], // 客户条件详情
            sign_project_all: [], // 签约项目原始数据
            condition_show: false, // 客户条件弹出层
            condition_val: '', // 客户条件
            condition_data: [], // 客户条件列表

            /* ---- 配偶护照状态 ---- */
            spouse_passport_status: null, // 护照状态
            spouse_passport_data: [
                {id: 0, name: '无'},
                {id: 1, name: '有'}
            ],
            spouse_passport_active: null, // 护照选中样式
            spouse_passport_show: false, // 护照情况详情显示隐藏
            spouse_passport_info: '', // 护照情况详情

            /* ---- 配偶绿卡状态 ---- */
            spouse_card_status: null, // 绿卡状态
            spouse_card_data: [
                {id: 1, name: '有'},
                {id: 2, name: '无'},
            ],
            spouse_card_active: null, // 绿卡选中样式
            spouse_card_show: false, // 绿卡详情显示隐藏
            spouse_card_info: '', // 绿卡详情

            /* ---- 动态电话数据 ---- */
            phone_show: false, // 动态电话弹窗
            is_close: true,
            add_phone_name: '', // 姓名(动态电话)
            add_phone_text: '', // 电话号码(动态电话)
            phone_type: 2, // 电话回显
            phone_type_data: ['国外电话', '国内电话'],
            phone_type_active: null, // 性别选中样式
            concat_phone_data: [],

            /* ---- 动态QQ数据 ---- */
            qq_show: false, // 动态QQ弹窗
            add_qq_name: '', // 姓名(动态QQ)
            add_qq_text: '', // QQ号码(动态QQ)
            concat_qq_data: [],

            /* ---- 动态微信数据 ---- */
            vx_show: false, // 动态微信弹窗
            add_vx_name: '', // 姓名(动态微信)
            add_vx_text: '', // 微信号码(动态电话)
            concat_vx_data: [],

            /* ---- 动态邮箱数据 ---- */
            email_show: false, // 动态邮箱弹窗
            add_email_name: '', // 姓名(动态邮箱)
            add_email_text: '', // 邮箱号码(动态电话)
            concat_email_data: [],

            /* ---- 动态联系方式校验 ---- */
            category_phone_switch: false, // 电话校验开关

            /* ---- 客户等级部分 ---- */
            level_popup_show: false, // 客户等级弹出层
            level_info: '', // 客户等级
            screen_level_id: '', // 等级id
            level_data: ['A','B','B+','C','C+', 'D','D+', 'E','E+', '未分级', '无效'],
            // 真实数据
            get_level_data: [
                {id: 1, name: 'A', disabled: true},
                {id: 2, name: 'B'},
                {id: 3, name: 'C'},
                {id: 4, name: 'D'},
                {id: 5, name: 'E'},
                {id: 6, name: '无效'},
                {id: 7, name: '未分级'},
            ],

            /* ---- 无效原因部分 ---- */
            adviser_id: '', // 顾问id
            adviser_show: false, // 顾问弹出层
            adviser_info: '', // 跟单顾问
            request_adviser_data: [], // 接口返回顾问数据
            adviser_data: [], // vant 顾问数据形式
            invalid_visible: false, // 其他原因显示

            /* ------ 优先级单选 ------ */
            priority: 1, // 优先级回显
            priority_id: '0', // 优先级id(请求时传递的参数)
            priority_data: [
                {id: '0', title: '电话'},
                {id: '1', title: '微信'},
                {id: '2', title: '邮箱'},
                {id: '3', title: 'QQ'}
            ],
            priority_active: null, // 优先级选中样式

            /* ------ 客户地址级联选择部分 ------ */
            address_cascader_visibel: false, // 级联选择器控制
            address_show: false,
            address_request: '', // 地区(作为参数请求时传递)
            address_info: '', // 地区回显
            address_value: '', // 地区id
            // 地区自定义字段
            addressNames: {
                text: 'name',
                value: 'id',
                children: 'children'
            },
            address_data: [], // 地区数据集合

            /* ---- 学历部分 ---- */
            education_show: false, // 学历弹出层
            education_info: '', // 学历
            get_education_data: [
                {value: 0, label: '未知'},
                {value: 1, label: '博士'},
                {value: 2, label: '硕士'},
                {value: 3, label: '本科'},
                {value: 4, label: '专科'},
                {value: 5, label: '高中及以下'},
            ],

            /* ---- 签单状态 ---- */
            sign_status: 1, // 签约状态
            sign_data: [
                {id: 2, name: '未签单'},
                {id: 1, name: '已签单'},
            ],
            sign_active: null, // 签约选中样式
            sign_show: false, // 签约情况详情显示隐藏
            sign_info: '', // 签约情况详情

            sign_time: '', // 签单时间
            sign_time_show: false, // 签单时间弹层显示隐藏
            signMinDate: new Date(1970, 0, 1),
            signMaxDate: new Date(),
            sign_current_date: new Date(), // 选中时间

            remarks_data: [], // 添加备注(倒数第二个模块)
            history_data: [], // 签证历史(最后一个)

            bottom_nav_visible: true, // 底部导航显示与隐藏
            iosStates: false, // 手机系统

            /* ---- 监听软键盘是否弹出 ---- */
            defaultHeight: '0',  //默认屏幕高度
            nowHeight: '0',  //实时屏幕高度

            submitStates:true ,// 提交按钮状态

            show:true

        }
    },

    // 生命周期 初始化
    setup() {
        const active = ref(''); // 设定选定第几个
        return {
            active,
        };
    },

    created() {
        // 上一页页码存储
        if (this.$route.query.pages) {
            sessionStorage.setItem('pageNumber', this.$route.query.pages)
        }
        window.scrollTo(0, 0) // 进入页面使得滚动条始终位于页面最上方
        if (sessionStorage.getItem('declare_show') === '1') {
            this.declare_show = false // 如果是共享池过来的显示申报合格资源按钮
        }

        // 如果是组长
        if (sessionStorage.getItem('group_leader') === '1') {
            this.group_leader = '1'
            this.group_leader_declare = false
            this.declare_show = true
        }

        //岗位权限判断
        if (sessionStorage.getItem('postID') === '2' || sessionStorage.getItem('postID') === '7') {
            this.post_id_show = false
        }

        if (sessionStorage.getItem('postID') === '1') {
            this.declare_show = false
            this.group_leader_declare = false
        }

        if (sessionStorage.getItem('postID') === '7') {
            this.group_leader = '1'
            this.group_leader_declare = false
        }

        let visit_show = sessionStorage.getItem('visit_status')
        sessionStorage.removeItem('visit_status')
        if (visit_show === '1') {
            this.visit_show = false // 客户回访隐藏申请合格资源
            this.visit_submit = true // 客户回访展示时提交按钮
        }

        this.query_id = this.$route.query.id // 上个页面传递过来的客户id
        this.getRemark() // 获取备注
        this.getSignProject() // 获取签约项目信息
        // this.getDetail() // 获取详情数据
        this.getDetail() // 获取详情数据
        // setTimeout(() => {
        //   //需要定时执行的代码
        //   this.getDetail() // 获取详情数据
        // }, 500)
        this.getAdviserData() // 获取无效原因
        this.getAddressData() // 地区数据
        this.visaHistory() // 获取签证历史数据

        this.customer_form.is_company === 1 ? this.other_company_show = true : this.other_company_show = false // 是否为企业主更多信息
        this.customer_form.third_party_country_status === 1 ? this.passport_info_show = true : this.passport_info_show = false // 其他国家护照情况详情信息
        this.green_card_status === 1 ? this.green_card_show = true : this.green_card_show = false // 其他国家绿卡情况详情信息
        this.customer_form.marriage === 1 ? this.marriage_show = true : this.marriage_show = false // 配偶信息详情
        this.customer_form.issign_bill === 1 ? this.sign_show = true : this.sign_show = false // 签单状态详情信息
        this.iosStates = sessionStorage.getItem("iosStates")
    },

    // 获取屏幕默认高度与实时高度
    mounted: function () {
        //获取默认高度
        this.defaultHeight = document.documentElement.clientHeight
        window.onresize = () => {
            return (() => {
                //实时屏幕高度
                this.nowHeight = document.documentElement.clientHeight
            })();
        };
    },
    // 监听软键盘是否弹出
    watch: {
        nowHeight: function () {
            if (this.defaultHeight !== this.nowHeight) {
                this.bottom_nav_visible = false
            } else {
                this.bottom_nav_visible = true
            }
        }
    },

    methods: {

        //获取签约项目下拉
        getSignProject() {
            signedProject().then(data => {
                if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                    Notify({type: 'danger', message: data.message});
                    sessionStorage.clear()
                    this.$router.push({
                        path: '/weChatLogin'
                    })
                    return
                }
                if (data.code === 200) {
                    let project_data = data.data.data
                    this.sign_project_all = project_data
                    this.condition_content = data.data.condition // 客户条件数组
                    for (let i = 0; i < project_data.length; i++) {
                        this.sign_project_data.push(
                            {'id': project_data[i].id, 'title': project_data[i].title},
                        )
                    }
                } else {
                    Notify({type: 'danger', message: data.message});
                }
            }).catch(() => {
                Notify({type: 'danger', message: '服务连接失败'});
            })
        },


        getRemark(){
            let para ={
                id:this.$route.query.id
            }
            userContentList(para).then(data => {
                if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                    Notify({type: 'danger', message: data.message});
                    sessionStorage.clear()
                    this.$router.push({
                        path: '/weChatLogin'
                    })
                    return
                }
                if (data.code === 200) {
                    this.remarks_data = data.data
                } else {
                    Notify({type: 'danger', message: data.message});
                }
            })
        },

        /* ---- 无效原因部分 ---- */
        /**
         * 跟单顾问(搜索部分)
         * */
        // 跟单顾问弹层控制
        adviserPopup() {
            this.adviser_show = true
        },

        // 获取顾问列表
        getAdviserData() {
            invalidDown().then(data => {
                // 判断token
                if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                    Notify({type: 'danger', message: data.message});
                    sessionStorage.clear()
                    this.$router.push({
                        path: '/weChatLogin'
                    })
                    return
                }
                if (data.code === 200) {
                    this.request_adviser_data = data.data
                    // 请求数据获取顾问数组格式化为vant下拉数组格式
                    for (let i = 0; i < this.request_adviser_data.length; i++) {
                        this.adviser_data.push(this.request_adviser_data[i].title) // vant 下拉数组赋值
                    }
                } else {
                    Notify({type: 'danger', message: data.message});
                }
            }).catch(() => {
                Notify({type: 'danger', message: '服务连接失败'});
            })
        },

        // 确认赋值
        adviserConfirm(value, index) {

            this.adviser_info = value // 顾问赋值
            // 根据选中顾问获取对应顾问id
            for (let i = 0; i < this.request_adviser_data.length; i++) {
                if (index === i) {
                    this.customer_form.invalid_reason = this.request_adviser_data[i].id // 顾问id赋值
                }
            }
            if(value =='其他'){
                this.invalid_visible = true
            }else{
                this.invalid_visible = false
            }
            // 其他原因显示与隐藏
            // for (let i = 0; i < this.request_adviser_data.length; i++) {
            //   if (value === this.request_adviser_data[i].title) {
            //
            //
            //     if (this.request_adviser_data[i].other_status === 1) {
            //       this.invalid_visible = true
            //     } else {
            //       this.invalid_visible = false
            //     }
            //   }
            // }

            this.adviser_show = false // 关闭弹层
            this.clear_adviser_show = false // 切换重置图标
        },

        // 取消选择
        adviserCancel() {
            this.adviser_show = false // 关闭弹层
        },


        // 时间戳转化(年-月-日 时:分:秒)
        formatDate(time) {
            return moment(time * 1000).format("YYYY-MM-DD HH:mm:ss")
        },

        // 时间戳转化(年-月-日)
        formatDate2(time) {
            return moment(time * 1000).format("YYYY-MM-DD")
        },

        // 客户等级转化
        customerGrade(value) {
            let name =''
            this.get_level_data.forEach(function(item){
                if(value === item.id){
                    name =item.name
                }
            })
            return name
        },
        // 学历转化
        education(value) {
            let grade_arr = ['未知', '博士', '硕士', '本科', '专科', '高中及以下']
            return grade_arr[value]
        },

        // 地区数据
        getAddressData() {
            areaDown().then(data => {
                if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                    Notify({type: 'danger', message: data.message});
                    sessionStorage.clear()
                    this.$router.push({
                        path: '/weChatLogin'
                    })
                    return
                }
                if (data.code === 200) {
                    this.address_data = data.data
                } else {
                    Notify({type: 'danger', message: data.message});
                }
            })
        },



        // 客户详情数据
        getDetail() {
            let para = {
                id: this.$route.query.id, // 客户id
                group_leader: this.group_leader // 是否为组长身份
            }
            getCustomerInfo(para).then(data => {
                if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                    Notify({type: 'danger', message: data.message});
                    sessionStorage.clear()
                    this.$router.push({
                        path: '/weChatLogin'
                    })
                    return
                }
                if (data.code === 200) {
                    // this.remarks_data = data.data.remark // 备注赋值
                    this.customer_form = data.data.info // 客户详情大表单赋值

                    // // 签约项目回显
                    this.clear_project_show = false
                    this.sign_project =data.data.info.project_name
                    if(data.data.info.condition_name !== '' || data.data.info.condition_name !== null ){
                        this.condition_visible = true
                        this.condition_val=data.data.info.condition_name
                    }else{
                        this.condition_visible = false
                        this.customer_form.condition = ''
                    }
                    // ------ 客户条件回显 ------
                    // 客户条件判断部分
                    for (let i = 0; i < this.sign_project_all.length; i++) {
                        if (parseInt(data.data.info.project) === this.sign_project_all[i].id) {
                            if (this.sign_project_all[i].children) {
                                this.condition_data = this.sign_project_all[i].children
                                this.condition_visible = true
                            } else {
                                this.condition_visible = false
                                this.customer_form.condition = ''
                            }
                        }
                    }
                    if (data.data.info.condition) {
                        this.condition_visible = true
                        for (let i = 0; i < this.condition_content.length; i++) {
                            if (parseInt(data.data.info.condition) === this.condition_content[i].id) {
                                console.log(this.condition_content[i].title)
                                this.condition_val = this.condition_content[i].title
                                this.clear_condition_show = false
                            }
                        }
                    }else{
                        this.condition_visible = false
                    }
                    var that =this

                    // // 无效原因回显
                    setTimeout(function() {
                        for (let i = 0; i < that.request_adviser_data.length; i++) {
                            if (that.customer_form.invalid_reason === that.request_adviser_data[i].id) {
                                that.adviser_info = that.request_adviser_data[i].title
                                that.clear_adviser_show = false
                            }
                        }
                    }, 500)

                    // 如果有其他原因就显示输入框
                    if (this.customer_form.invalid_reason ==10) {
                        this.invalid_visible = true
                    } else {
                        this.invalid_visible = false
                    }

                    // 配偶回显
                    if (data.data.marriage) {
                        // 配偶电话
                        if (!data.data.marriage.user_phone) {
                            this.spouse_phone = ''
                        } else {
                            this.spouse_phone = data.data.marriage.user_phone // 配偶电话
                        }

                        // 配偶姓名
                        if (!data.data.marriage.user_name) {
                            this.spouse_name = '' // 配偶姓名
                        } else {
                            this.spouse_name = data.data.marriage.user_name // 配偶姓名
                        }

                        // 配偶微信
                        if (!data.data.marriage.user_chat) {
                            this.spouse_vx = '' // 配偶微信
                        } else {
                            this.spouse_vx = data.data.marriage.user_chat // 配偶微信
                        }

                        // 配偶邮箱
                        if (!data.data.marriage.user_email) {
                            this.spouse_email = '' // 配偶邮箱
                        } else {
                            this.spouse_email = data.data.marriage.user_email // 配偶邮箱
                        }

                        // 配偶年龄
                        if (!data.data.marriage.birthday) {
                            this.spouse_age = '' // 配偶年龄
                        } else {
                            this.spouse_age = data.data.marriage.birthday // 配偶年龄
                        }

                        // 配偶QQ
                        if (!data.data.marriage.user_qq) {
                            this.spouse_qq = '' // 配偶QQ
                        } else {
                            this.spouse_qq = data.data.marriage.user_qq // 配偶QQ
                        }

                        // 配偶学历
                        if (!data.data.marriage.education) {
                            this.spouse_education = '' // 配偶学历
                        } else {
                            this.spouse_education = data.data.marriage.education // 配偶学历
                        }

                        // 配偶专业
                        if (!data.data.marriage.speciality) {
                            this.spouse_major = '' // 配偶专业
                        } else {
                            this.spouse_major = data.data.marriage.speciality // 配偶专业
                        }

                        // 配偶职业
                        if (!data.data.marriage.work) {
                            this.spouse_occupation = '' // 配偶职业
                        } else {
                            this.spouse_occupation = data.data.marriage.work // 配偶职业
                        }

                        // 配偶工作年限
                        if (!data.data.marriage.work_life) {
                            this.spouse_work_years = '' // 配偶工作年限
                        } else {
                            this.spouse_work_years = data.data.marriage.work_life // 配偶工作年限
                        }

                        // 配偶外语水平
                        if (!data.data.marriage.language_ability) {
                            this.spouse_language_level = '' // 配偶外语水平
                        } else {
                            this.spouse_language_level = data.data.marriage.language_ability // 配偶外语水平
                        }

                        // 如果没有配偶默认护照情况为无
                        if (!data.data.marriage.third_party_country_status) {
                            this.spouse_passport_status = 0 // 配偶护照情况
                        } else {
                            this.spouse_passport_status = data.data.marriage.third_party_country_status // 配偶护照情况
                        }

                        // 配偶护照详情
                        if (!data.data.marriage.third_party_country) {
                            this.spouse_passport_info = '' // 配偶护照详情
                        } else {
                            this.spouse_passport_info = data.data.marriage.third_party_country // 配偶护照详情
                        }

                        // 如果没有配偶默认绿卡情况为无
                        if (!data.data.marriage.green_card_status) {
                            this.spouse_card_status = 2 // 配偶绿卡情况
                        } else {
                            this.spouse_card_status = data.data.marriage.green_card_status // 配偶绿卡情况
                        }

                        // 配偶绿卡详情
                        if (!data.data.marriage.green_card) {
                            this.spouse_card_info = '' // 配偶绿卡详情
                        } else {
                            this.spouse_card_info = data.data.marriage.green_card // 配偶绿卡详情
                        }

                        this.spouse_passport_status === 1 ? this.spouse_passport_show = true : this.spouse_passport_show = false // 配偶其他国家护照情况
                        this.spouse_card_status === 1 ? this.spouse_card_show = true : this.spouse_card_show = false // 配偶其他国家绿卡情况
                    } else {
                        this.marriage_form
                    }
                    this.initialRadio() // 单选按钮初始化
                    if (data.data.contact) {
                        this.concat_phone_data = data.data.contact.dynamicPhone // 动态电话
                        this.concat_vx_data = data.data.contact.dynamicWeChat // 动态微信
                        this.concat_qq_data = data.data.contact.dynamicQQ // 动态QQ
                        this.concat_email_data = data.data.contact.dynamicEmail // 动态邮箱
                    }
                    // 企业创建时间回显
                    if (this.customer_form.founding_time) {
                        this.create_time = this.formatDate2(this.customer_form.founding_time)
                    }

                    // 签单时间回显
                    if (this.customer_form.bill_time) {
                        this.sign_time = this.formatDate(this.customer_form.bill_time)
                    }

                    /*---- 重置部分 ----*/
                    // 等级重置
                    if (this.customer_form.customer_grade !== 7) {
                        this.clear_level_show = false // 切换重置图标
                    }

                    // 客户地址重置
                    if (this.customer_form.province_m !== '') {
                        this.clear_address_show = false // 切换重置图标
                    }

                    // 学历重置
                    if (this.customer_form.education !== 0) {
                        this.clear_education_show = false // 切换图标
                    }

                    // 企业成立时间重置
                    if (this.customer_form.founding_time) {
                        this.clear_create_show = false // 切换图标
                    }

                    // 配偶学历
                    if (this.spouse_education) {
                        this.spouse_education_visible = false // 切换图标
                    }

                    // 签单时间重置
                    if (this.customer_form.bill_time) {
                        this.clear_sign_show = false // 切换图标
                    }
                } else {
                    Notify({type: 'danger', message: data.message});
                }
            }).catch(() => {
                Notify({type: 'danger', message: '服务连接失败'});
            })
        },

        // 签证历史
        visaHistory() {
            let para ={
                id:this.$route.query.id
            }
            visaHistoryList(para).then(data => {
                if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                    Notify({type: 'danger', message: data.message});
                    sessionStorage.clear()
                    this.$router.push({
                        path: '/weChatLogin'
                    })
                    return
                }
                if (data.code === 200) {
                    this.history_data = data.data
                } else {
                    Notify({type: 'danger', message: data.message});
                }
            })
        },

        // 返回上一页
        goBackPage() {
            this.$router.go(-1)
        },

        // 修改提交
        submit() {

            this.customer_form.founding_time = this.create_time // 企业成立时间戳转化
            this.customer_form.bill_time = this.sign_time // 签约时间
            var newly_added_phone = true // 电话开关
            var newly_added_email = true // 邮箱开关
            var newly_added_QQ = true // QQ开关

            /* ---- 提交时动态电话表单验证 ---- */
            this.customer_form.dynamicPhone = this.concat_phone_data // 动态电话表单
            var judge_phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/; // 电话正则判断
            if (!this.category_phone_switch) {
                for (let i = 0; i < this.concat_phone_data.length; i++) {
                    let added_phone = this.concat_phone_data[i].value
                    let added_phone_state = this.concat_phone_data[i].state
                    if (!judge_phone.test(added_phone) && added_phone_state == '1') {
                        Notify({type: 'danger', message: '新增电话' + this.concat_phone_data[i].value + '不符合规则'});
                        newly_added_phone = false
                    }
                }
            }

            /* ---- 提交时动态邮箱表单验证 ---- */
            this.customer_form.dynamicEmail = this.concat_email_data // 动态邮箱
            var judge_email = /^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/; // 邮箱正则判断
            for (let i = 0; i < this.concat_email_data.length; i++) {
                let added_email = this.concat_email_data[i].value
                if (!judge_email.test(added_email)) {
                    Notify({type: 'danger', message: '新增邮箱' + this.concat_email_data[i].value + '不符合规则'});
                    newly_added_email = false
                }
            }

            this.customer_form.dynamicQQ = this.concat_qq_data // 动态QQ
            var judge_QQ = /^[1-9]\d{4,9}$/; // QQ正则判断
            for (let i = 0; i < this.concat_qq_data.length; i++) {
                let added_QQ = this.concat_qq_data[i].value
                if (!judge_QQ.test(added_QQ)) {
                    Notify({type: 'danger', message: '新增QQ' + this.concat_qq_data[i].value + '不符合规则'});
                    newly_added_QQ = false
                }
            }

            // 如果姓名和联系方式都没填自动过滤(微信)
            for (let i = 0; i < this.concat_vx_data.length; i++) {
                if (this.concat_vx_data[i].name === '' && this.concat_vx_data[i].value === '') {
                    this.concat_vx_data.splice(i--, 1);
                }
            }
            this.customer_form.dynamicWeChat = this.concat_vx_data // 动态微信
            this.customer_form.customer_tags = '' // 客户标签

            // 配偶信息
            this.marriage_form.birthday = this.spouse_age // 年龄
            this.marriage_form.education = this.spouse_education // 学历
            this.marriage_form.green_card = this.spouse_card_info // 绿卡详情
            this.marriage_form.green_card_status = this.spouse_card_status // 绿卡状态
            this.marriage_form.language_ability = this.spouse_language_level // 英语水平
            this.marriage_form.speciality = this.spouse_major // 所学专业
            this.marriage_form.third_party_country = this.spouse_passport_info // 护照详情
            this.marriage_form.third_party_country_status = this.spouse_passport_status // 护照状态
            this.marriage_form.user_chat = this.spouse_vx // 微信
            this.marriage_form.user_email = this.spouse_email // 邮箱
            this.marriage_form.user_name = this.spouse_name // 姓名
            this.marriage_form.user_phone = this.spouse_phone // 电话
            this.marriage_form.user_qq = this.spouse_qq // QQ
            this.marriage_form.work = this.spouse_occupation // 职业
            this.marriage_form.work_life = this.spouse_work_years // 年限

            // 判断无效选择了其他input必填
            if (this.invalid_visible) {
                if (!this.customer_form.invalid_reason_content) {
                    Notify({message: '请填写无效原因', duration: 1500})
                    return
                }
            }

            // 如果选择了其他意外的清空其原因
            if (!this.invalid_visible) {
                this.customer_form.invalid_reason_content = ''
            }

            // 客户等级为无效的时候需要选择无效原因
            if (this.customer_form.customer_grade === 6) {
                if (!this.customer_form.invalid_reason){
                    Notify({message: '客户等级无效，需要选择无效原因', duration: 1500})
                    return
                }
            }

            // 提交参数
            let para = {
                customerForm: this.customer_form, // 主申请人信息
                spouseForm: this.marriage_form // 配偶信息
            }

            // 配偶绿卡情况判断
            if (para.spouseForm.green_card_status === 1) {
                if (!para.spouseForm.green_card) {
                    Notify({message: '请填写配偶绿卡详情', duration: 1500})
                    return
                }
            }

            // 配偶护照情况判断
            if (para.spouseForm.third_party_country_status === 1) {
                if (!para.spouseForm.third_party_country) {
                    Notify({message: '请填写配偶护照详情', duration: 1500})
                    return
                }
            }

            // 如果已婚配偶姓名和电话为必填项
            if (para.customerForm.marriage === 1) {
                if (!para.spouseForm.user_phone) {
                    Notify({message: '请填写配偶电话', duration: 1500})
                    return;
                } else {
                    let judge_phone = /^1[3456789]\d{9}$/; // 电话正则判断
                    if (!judge_phone.test(para.spouseForm.user_phone)) {
                        Notify({type: 'danger', message: '配偶手机号不符合规则'})
                        return false
                    }
                }

                if (!para.spouseForm.user_name) {
                    Notify({message: '请填写配偶姓名', duration: 1500})
                    return;
                }
            }

            // 如果没有其他国家护照清空护照详情
            if (para.spouseForm.third_party_country_status === 0) {
                para.spouseForm.third_party_country = ''
            }

            // 如果没有其他国家绿卡清空绿卡详情
            if (para.spouseForm.green_card_status === 2) {
                para.spouseForm.green_card = ''
            }

            // 如果不是企业业主清空企业主信息
            if (para.customerForm.is_company === 2) {
                para.customerForm.scale = '' // 企业规模
                para.customerForm.business = '' // 主营业务
                para.customerForm.founding_time = '' // 企业成立时间
            }

            // 如果没有护照
            if (para.customerForm.third_party_country_status === 0) {
                para.customerForm.third_party_country = '' // 护照详情
            }

            // 有护照必须填写护照详情
            if (para.customerForm.third_party_country_status) {
                if (!para.customerForm.third_party_country) {
                    Notify('请填写护照详情')
                    return
                }
            }

            // 如果没有绿卡
            if (para.customerForm.green_card_status === 2) {
                para.customerForm.green_card = ''
            }

            // 有绿卡必须填写绿卡详情
            if (para.customerForm.green_card_status !== 2) {
                if (!para.customerForm.green_card) {
                    Notify('请填写绿卡详情')
                    return
                }
            }

            // 如果没有签单
            if (para.customerForm.issign_bill === 2) {
                para.customerForm.project = '' // 签约项目信息
                para.customerForm.bill_time = '' // 签约时间
            }

            if (newly_added_phone && newly_added_email && newly_added_QQ ) {


                if(this.submitStates === true){

                    this.saveBigForm(para) // 请求接口更改数据
                }

            }

        },

        // 保存大表单
        saveBigForm(val) {
            let that = this
            that.submitStates =false
            saveCustomerDetail(val).then(data => {
                if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                    Notify({type: 'danger', message: data.message});
                    sessionStorage.clear()
                    this.$router.push({
                        path: '/weChatLogin'
                    })
                    return
                }
                if (data.code === 200) {
                    that.getDetail() // 更新数据
                    Notify({type: 'success', message: '保存成功'});
                    setTimeout(function () {
                        that.submitStates=true
                        that.$router.go(-1)
                    }, 500)
                } else {
                    that.submitStates=true
                    Notify({type: 'danger', message: data.message});
                }
            }).catch(() => {
                that.submitStates=true
                Notify({type: 'danger', message: '服务连接失败'});
            })
        },

        // 初始化单选样式
        initialRadio() {
            // 0 未知 1 男 2 女
            // 性别初始化
            if (this.customer_form.user_sex === 0) { // 未知
                this.sex_active = 3
            }
            if (this.customer_form.user_sex === 1) { // 男
                this.sex_active = 1
            }
            if (this.customer_form.user_sex === 2) { // 女
                this.sex_active = 2
            }

            // 其他国家护照初始化
            if (this.customer_form.third_party_country_status === 0) {
                this.passport_active = 1
            } else {
                this.passport_active = 2
            }

            // 其他国家绿卡初始化
            if (this.customer_form.green_card_status === 2) {
                this.green_card_active = 1
            } else {
                this.green_card_active = 2
            }

            // 签单状态初始化
            if (this.customer_form.issign_bill === 2) {
                this.sign_active = 1
            } else {
                this.sign_active = 2
            }

            // 配偶回显
            if (this.customer_form.marriage === 0) {
                this.marriage_active = 1
            }
            switch (this.customer_form.marriage) {
                case 0:
                    this.marriage_active = 1 // 未知
                    break
                case 1:
                    this.marriage_active = 2 // 已婚
                    break
                case 2:
                    this.marriage_active = 3 // 未婚
                    break
                case 3:
                    this.marriage_active = 4 // 离异
                    break
                case 4:
                    this.marriage_active = 5 // 丧偶
                    break
            }

            this.company_main_active = this.customer_form.is_company // 企业主初始化
            this.phone_type_active = this.phone_type //电话类型初始化
            this.spouse_passport_active = this.spouse_passport_status // 配偶其他国家护照初始化
            this.spouse_card_active = this.spouse_card_status // 配偶其他国家绿卡初始化
            switch (this.customer_form.contact_information_first) {
                case 0:
                    this.priority_active = 1 // 电话
                    break
                case 1:
                    this.priority_active = 2 // 微信
                    break
                case 2:
                    this.priority_active = 3 // 邮箱
                    break
                case 3:
                    this.priority_active = 4 // QQ
                    break
            }

        },

        // 格式化时间函数
        formatTime(val) {
            let date = new Date(val);
            let year_now = date.getFullYear()
            let month_now = date.getMonth() + 1
            let day_now = date.getDate()
            if (month_now < 10) {
                month_now = '0' + month_now
            }
            if (day_now < 10) {
                day_now = '0' + day_now
            }
            date = year_now + '-' + month_now + '-' + day_now
            return date
        },

        /**
         * 动态添加电话部分
         * */
        // 添加按钮
        addPhone() {
            this.phone_show = true
            this.add_phone_name = '' // 重置姓名
            this.add_phone_text = '' // 重置电话号码
            this.phone_type_active = 2 // 重置电话选中样式
            this.phone_type = 2 // 重置电话回显类别
        },

        // 删除数据
        delPhone(index) {
            Dialog.confirm({
                message: '确认删除本条数据吗?',
            })
                .then(() => {
                    this.concat_phone_data.splice(index, 1)
                })
                .catch(() => {
                    // on cancel
                });
        },

        // 关闭弹窗做一些操作
        onPhoneAddClose(action) {
            if (action === 'confirm') {
                if (!this.add_phone_name) {
                    Notify({type: 'danger', message: '姓名不能为空'})
                    return false
                }

                if (!this.add_phone_text) {
                    Notify({type: 'danger', message: '电话不能为空'})
                    return false
                }

                if (this.phone_type === 2) {
                    let judge_phone = /^1[3456789]\d{9}$/; // 电话正则判断
                    if (!judge_phone.test(this.add_phone_text)) {
                        Notify({type: 'danger', message: '手机号不符合规则'})
                        return false
                    }
                }

                if (this.phone_type === 1) {
                    let judge_phone = /^\+?\d+$/; // 海外电话正则判断
                    if (!judge_phone.test(this.add_phone_text)) {
                        Notify({type: 'danger', message: '仅支持输入+号和数字'})
                        return false
                    }
                }

                let time = new Date().getTime()
                this.concat_phone_data.push({
                    name: this.add_phone_name,
                    value: this.add_phone_text,
                    key: time,
                    state: this.phone_type - 1
                }) // 添加操作
                this.phone_show = false
            } else {
                this.phone_show = false
            }
        },

        /**
         * 动态添加QQ部分
         * */
        // 添加按钮打开弹窗
        addQq() {
            this.qq_show = true
            this.add_qq_name = '' // 重置姓名
            this.add_qq_text = '' // 重置电话号码
        },

        // 关闭弹窗前的一些操作
        onQqAddClose(action) {
            if (action === 'confirm') {
                if (!this.add_qq_name) {
                    Notify('姓名不能为空')
                    return false
                }

                if (!this.add_qq_text) {
                    Notify('QQ不能为空')
                    return false
                }

                if (this.add_qq_text) {
                    let judge_QQ = /^[1-9]\d{4,9}$/; // QQ正则判断
                    if (!judge_QQ.test(this.add_qq_text)) {
                        Notify({type: 'danger', message: 'QQ号不符合规则'})
                        return false
                    }
                }
                let time = new Date().getTime()
                this.concat_qq_data.push({
                    value: this.add_qq_text,
                    key: time,
                    name: this.add_qq_name,
                }) // 添加操作
                this.qq_show = false
            } else {
                this.qq_show = false
            }
        },

        // 删除操作
        delQq(index) {
            Dialog.confirm({
                message: '确认删除本条数据吗?',
            })
                .then(() => {
                    this.concat_qq_data.splice(index, 1)
                })
                .catch(() => {
                    // on cancel
                });
        },

        /**
         * 动态添加微信部分
         * */
        // 添加按钮打开弹窗
        addVx() {
            this.vx_show = true
            this.add_vx_name = '' // 重置姓名
            this.add_vx_text = '' // 重置电话号码
        },

        // 关闭弹窗前的一些操作
        onVxAddClose(action) {
            if (action === 'confirm') {
                if (!this.add_vx_name) {
                    Notify('姓名不能为空')
                    return false
                }

                if (!this.add_vx_text) {
                    Notify('微信不能为空')
                    return false
                }
                let time = new Date().getTime()
                this.concat_vx_data.push({name: this.add_vx_name, key: time, value: this.add_vx_text}) // 添加操作
                this.vx_show = false
            } else {
                this.vx_show = false
            }
        },

        // 删除操作
        delVx(index) {
            Dialog.confirm({
                message: '确认删除本条数据吗?',
            })
                .then(() => {
                    this.concat_vx_data.splice(index, 1)
                })
                .catch(() => {
                    // on cancel
                });
        },

        /**
         * 动态添加邮箱部分
         * */
        // 添加按钮打开弹窗
        addEmail() {
            this.email_show = true
            this.add_email_name = '' // 重置姓名
            this.add_email_text = '' // 重置电话号码
        },

        // 关闭弹窗前的一些操作
        onEmailAddClose(action) {
            if (action === 'confirm') {
                if (!this.add_email_name) {
                    Notify('姓名不能为空')
                    return false
                }

                if (!this.add_email_text) {
                    Notify('邮箱不能为空')
                    return false
                }

                if (this.add_email_text) {
                    let judge_email = /^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/; // 邮箱正则判断
                    if (!judge_email.test(this.add_email_text)) {
                        Notify({type: 'danger', message: '邮箱不符合规则'})
                        return false
                    }
                }

                let time = new Date().getTime()
                this.concat_email_data.push({
                    name: this.add_email_name,
                    value: this.add_email_text,
                    key: time
                }) // 添加操作
                this.email_show = false
            } else {
                this.email_show = false
            }
        },

        // 删除操作
        delEmail(index) {
            Dialog.confirm({
                message: '确认删除本条数据吗?',
            })
                .then(() => {
                    this.concat_email_data.splice(index, 1)
                })
                .catch(() => {
                    // on cancel
                });
        },

        /**
         * 电话类型选择部分
         * */
        // 电话类型单选监听
        phoneTypeChange() {
            // console.log(this.phone_type)
        },

        // 电话类型点击变色
        phoneTypeColor(index) {
            this.phone_type_active = index
        },


        /**
         * 性别选择部分
         * */
        // 性别单选监听
        sexSelect(val) {
            // console.log(val)
        },

        // 单选点击变色
        switchColor(index) {
            this.sex_active = index
        },


        /**
         * 客户等级
         * */
        // 跟单顾问弹层控制
        levelPopup() {
            this.level_popup_show = true
        },

        // 确认赋值
        levelConfirm(value) {
            console.log(value)
            this.customer_form.customer_grade = value.id // 客户等级赋值
            this.level_popup_show = false // 关闭弹层
            this.clear_level_show = false // 切换重置图标
        },

        // 取消选择
        levelCancel() {
            this.level_popup_show = false // 关闭弹层
        },

        /**
         * 客户地址级联选择器部分
         * */
        addressShow() {
            this.address_show = true // 客户地址popup控制
            this.address_cascader_visibel = true // 客户地址级联选择器控制
        },

        // 客户地址选择完毕 finish 事件
        addressFinish({selectedOptions}) {
            this.address_show = false;
            this.customer_form.province_m = selectedOptions.map((option) => option.name).join('/');
            let address_arr = []
            for (let i = 0; i < selectedOptions.length; i++) {
                address_arr.push(selectedOptions[i].id)
            }
            this.customer_form.province = address_arr // 客户地址赋值(请求数据时候用)
            this.clear_address_show = false // 切换重置图标
        },

        /**
         * 跟单顾问(搜索部分)
         * */
        // 跟单顾问弹层控制
        educationPopup() {
            this.education_show = true
        },

        // 确认赋值
        educationConfirm(value) {
            this.customer_form.education = value.value // 学历赋值
            this.education_show = false // 关闭弹层
            this.clear_education_show = false // 切换图标
        },

        // 取消选择
        educationCancel() {
            this.education_show = false // 关闭弹层
        },

        /**
         * 标签部分逻辑
         * */
        // 点击事件
        checkEvent() {
            let arr2 = this.label_data // 标签数组集合
            for (let a = 0; a < arr2.length; a++) {
                arr2[a].switch = 0
            }
            this.label_data = arr2
            this.labelInitial()


        },

        // 标签复选初始化
        labelInitial() {
            let arr1 = this.checked_label // 回显数组
            let arr2 = this.label_data // 标签数组集合
            // 根据回显数组内数据改变标签数组集合中switch值
            for (let i = 0; i < arr1.length; i++) {
                for (let a = 0; a < arr2.length; a++) {
                    if (arr1[i] === arr2[a].id) {
                        arr2[a].switch = 1
                    }
                }
            }
            this.label_data = arr2
        },

        /**
         * 是否为企业主
         * */
        // 是否为企业主单选监听
        enterpriseSelect() {
            this.customer_form.is_company === 1 ? this.other_company_show = true : this.other_company_show = false
        },

        // 单选点击变色
        enterpriseColor(index) {
            this.company_main_active = index
        },

        // 成立时间Popup
        createPopup() {
            this.create_time_show = true
        },

        // 时间赋值
        createTimeConfirm() {
            this.create_time_show = false
            this.create_time = this.formatTime(this.create_current_date)
            this.customer_form.founding_time = this.create_time
            this.clear_create_show = false // 切换图标
        },

        // 取消时间赋值关闭弹窗
        createTimeCancel() {
            this.create_time_show = false
        },

        /**
         * 其他国家护照情况
         * */
        // 其他国家护照情况单选监听
        passportSelect() {
            this.customer_form.third_party_country_status === 1 ? this.passport_info_show = true : this.passport_info_show = false
        },

        // 单选点击变色
        passportColor(index) {
            this.passport_active = index
        },

        /**
         * 其他国家绿卡情况
         * */
        // 其他国家绿卡情况单选监听
        greenCardSelect() {
            this.customer_form.green_card_status === 1 ? this.green_card_show = true : this.green_card_show = false
        },

        // 点击选中变色
        greenCardColor(index) {
            this.green_card_active = index
        },

        /**
         * 婚姻状况
         * */
        // 婚姻状况单选监听
        marriageSelect() {
            this.customer_form.marriage === 1 ? this.marriage_show = true : this.marriage_show = false // 配偶信息详情
        },

        // 点击选中变色
        marriageColor(index) {
            this.marriage_active = index
        },

        // 搜索配偶信息
        searchSpouse() {
            if (!this.spouse_phone) {
                Notify({type: 'danger', message: '请填写电话'})
                return
            }
            getCustomerSpouse(this.spouse_phone).then(data => {
                if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                    Notify({type: 'danger', message: data.message});
                    sessionStorage.clear()
                    this.$router.push({
                        path: '/weChatLogin'
                    })
                    return
                }
                if (data.code === 200) {
                    if (!data.data.data) {
                        Notify({type: 'danger', message: '未在系统内找到信息，请手动录入信息'});
                        return
                    }

                    Dialog.alert({
                        message: data.data.info,
                    }).then(() => {
                        // on close
                    })


                    this.marriage_form.spouse_id=data.data.data.id

                    this.customer_form.spouse_id=data.data.data.id

                    this.marriage_form = data.data.data // 将请求回来的配属数据赋值
                    this.spouse_name = this.marriage_form.user_name // 姓名
                    this.spouse_age = this.marriage_form.birthday // 年龄
                    this.spouse_education = this.marriage_form.education // 学历
                    this.spouse_major = this.marriage_form.speciality // 专业
                    this.spouse_occupation = this.marriage_form.work // 职业
                    this.spouse_work_years = this.marriage_form.work_life // 工作年限
                    this.spouse_language_level = this.marriage_form.language_ability // 外语水平
                    this.spouse_passport_status = this.marriage_form.third_party_country_status // 护照状态
                    this.spouse_passport_info = this.marriage_form.third_party_country // 护照详情
                    this.spouse_card_status = this.marriage_form.green_card_status // 绿卡状态
                    this.spouse_card_info = this.marriage_form.green_card // 绿卡详情
                    // 配偶其他国家护照初始化
                    if (this.spouse_passport_status === 0) {
                        this.spouse_passport_active = 0
                    } else {
                        this.spouse_passport_active = 1
                    }

                    // 配偶其他国家护照初始化
                    if (this.spouse_card_status === 2) {
                        this.spouse_card_active = 2
                    } else {
                        this.spouse_card_active = 1
                    }
                }else{
                    Notify({type: 'danger', message: data.message});
                }
            }).catch(() => {
                Notify({type: 'danger', message: '服务连接失败'});
            })
        },

        // 配偶学历弹层控制
        spouseEducationPopup() {
            this.spouse_education_show = true
        },

        // 确认赋值
        spouseEducationConfirm(value) {
            this.spouse_education = value.value
            this.spouse_education_show = false // 关闭弹层
            this.spouse_education_visible = false // 切换图标
        },

        // 取消选择
        spouseEducationCancel() {
            this.spouse_education_show = false // 关闭弹层
        },

        // 签约项目弹层 (签约项目)
        signProjectPopup() {
            this.sign_project_show = true
        },

        // 确认赋值 (签约项目)
        signProjectConfirm(value) {
            this.sign_project = value.title
            this.condition_val = '' // 重置客户条件
            this.customer_form.condition=''
            for (let i = 0; i < this.sign_project_data.length; i++) {
                if (value.title === this.sign_project_data[i].title) {
                    this.customer_form.project = this.sign_project_data[i].id
                    this.customer_form.project_name =this.sign_project_data[i].title
                }
            }

            // 客户条件判断部分
            for (let i = 0; i < this.sign_project_all.length; i++) {
                if (value.id === this.sign_project_all[i].id) {
                    if (this.sign_project_all[i].children.length>0) {
                        this.condition_data = this.sign_project_all[i].children
                        this.condition_visible = true
                    } else {
                        this.condition_visible = false
                        this.customer_form.condition = ''
                    }
                }
            }
            this.sign_project_show = false
            this.clear_project_show = false
        },

        // 取消选择 (签约项目)
        signProjectCancel() {
            this.sign_project_show = false // 关闭弹层
        },

        // 客户条件弹层
        conditionPopup() {
            this.condition_show = true
        },

        // 客户条件赋值
        conditionConfirm(value) {
            console.log(value)
            this.condition_show = false
            this.condition_val = value.condition
            this.customer_form.condition = value.title
            this.clear_condition_show = false
        },

        // 取消选择(客户条件)
        conditionCancel() {
            this.condition_show = false
        },

        // 配偶其他国家护照情况单选监听
        spousePassportSelect() {
            this.spouse_passport_status === 1 ? this.spouse_passport_show = true : this.spouse_passport_show = false
        },

        // 配偶单选点击变色(护照)
        spousePassportColor(index) {
            this.spouse_passport_active = index
        },

        // 其他国家绿卡情况单选监听
        spouseCardSelect() {
            this.spouse_card_status === 1 ? this.spouse_card_show = true : this.spouse_card_show = false
        },

        // 点击选中变色(配偶绿卡)
        spouseCardColor(index) {
            this.spouse_card_active = index
        },

        /**
         * 签单状态
         * */
        // 签单单选监听
        signSelect() {
            this.customer_form.issign_bill === 1 ? this.sign_show = true : this.sign_show = false
        },

        // 签单单选选中样式
        signColor(index) {
            this.sign_active = index
        },

        // 签约时间Popup
        signPopup() {
            this.sign_time_show = true
        },

        // 时间赋值
        signTimeConfirm() {
            this.sign_time_show = false
            this.sign_time = this.standardTime(this.sign_current_date)
            this.customer_form.bill_time = this.sign_time
            this.clear_sign_show = false // 切换图标
        },

        // 非时间戳转化(年-月-日 时:分:秒)
        standardTime(time) {
            return moment(time).format("YYYY-MM-DD HH:mm:ss")
        },

        // 取消时间赋值关闭弹窗
        signTimeCancel() {
            this.sign_time_show = false
        },

        /**
         * 添加备注
         * */
        // 添加备注
        remarksAdd() {
            this.$router.push({
                path: '/add_remarks',
                query: {
                    id: this.query_id
                }
            })
        },

        /**
         * 签证历史
         * */
        // 添加签证历史
        historyAdd() {
            this.$router.push({
                path: '/add_visa_history',
                query: {
                    id: this.query_id // 客户id
                }
            })
        },

        // 签证历史修改
        historyEdit(item) {
            this.$router.push({
                path: '/add_visa_history',
                query: {
                    visa_data: encodeURIComponent(JSON.stringify(item)), // vue路由传递对象
                    customer_id: this.query_id
                }
            })
        },

        // 签证历史删除
        historyDel(id) {
            Dialog.confirm({
                message: '确认删除本条数据吗?',
            })
                .then(() => {
                    let para = {
                        id: id, // 数据id
                        customer_id: this.query_id // 客户id
                    }
                    delVisaHistory(para).then(data => {
                        if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                            Notify({type: 'danger', message: data.message});
                            sessionStorage.clear()
                            this.$router.push({
                                path: '/weChatLogin'
                            })
                            return
                        }
                        if (data.code === 200) {
                            Notify({type: 'success', message: '已删除'});
                            this.visaHistory() // 更新数据
                        } else {
                            Notify({type: 'danger', message: data.message});
                        }
                    }).catch(() => {
                        Notify({type: 'danger', message: '服务连接失败'});
                    })
                })
                .catch(() => {
                    // on cancel
                });
        },

        // 申报合格资源
        declare() {
            let that = this
            Dialog.confirm({
                message: '是否申报合格资源?',
            })
                .then(() => {
                    let para = {
                        id: this.$route.query.id
                    }
                    declareResources(para).then(data => {
                        if (data.code === 105 || data.code === 101 || data.code === 102 || data.code === 403|| data.code === 401) {
                            Notify({type: 'danger', message: data.message});
                            sessionStorage.clear()
                            this.$router.push({
                                path: '/weChatLogin'
                            })
                            return
                        }
                        if (data.code === 200) {
                            Notify({type: 'success', message: '申报成功'});
                            setTimeout(function () {
                                that.$router.go(-1)
                            }, 500)
                        } else {
                            Notify({type: 'danger', message: data.message});
                        }
                    }).catch(() => {
                        Notify({type: 'danger', message: '服务连接失败'});
                    })
                })
                .catch(() => {
                    // on cancel
                });
        },
        /**
         * 重置下拉按钮
         * */
        // 等级重置
        clearableLevel() {
            this.customer_form.customer_grade = 7
            this.clear_level_show = true // 切换重置图标
        },

        // 重置签约顾问
        clearableAdviser() {
            this.adviser_info = '' // 清空回显
            this.customer_form.invalid_reason = '' // 清空请求时参数
            this.invalid_visible = false // 其他原因隐藏
            this.clear_adviser_show = true // 切换重置图标
        },

        // 客户地址重置
        clearableAddress() {
            this.customer_form.province = [] // 清空客户地址参数集合
            this.customer_form.province_m = '' // 清空客户地址回显
            this.address_value = '' // 重置客户地址选择器
            this.clear_address_show = true // 切换重置图标
            this.address_cascader_visibel = false // 客户地址级联选择器控制
        },

        // 学历重置
        clearableEducation() {
            this.customer_form.education = 0 // 清空学历回显
            this.clear_education_show = true // 切换图标
        },

        // 企业成立时间重置
        clearableCreate() {
            this.create_time = '' // 清空企业成立时间
            this.clear_create_show = true // 切换图标
        },

        // 配偶学历重置
        clearableSpouseEducation() {
            this.spouse_education = '' // 清空配偶学历
            this.spouse_education_visible = true // 切换图标
        },

        // 签单时间重置
        clearableSign() {
            this.sign_time = '' // 清空签单时间
            this.clear_sign_show = true // 切换图标
        },

        // 签约项目重置
        clearableSignProject() {
            this.sign_project = ''
            this.customer_form.project = ''
            this.clear_project_show = true // 切换图标
            this.clear_condition_show = true // 切换图标
            this.condition_visible = false
        },

        // 客户条件重置
        clearableCondition() {
            this.condition_val = ''
            this.customer_form.condition = ''
            this.clear_condition_show = true // 切换图标
        },

        // 苹果键盘事件
        blurIpt() {
            if (this.iosStates) {
                this.bottom_nav_visible = true
                return
            }
        },
        // 苹果键盘事件
        focusIpt() {
            if (this.iosStates) {
                this.bottom_nav_visible = false
                return
            }
        },

        /**
         * 优先级选择部分
         * */
        // 优先级单选监听
        prioritySelect(val) {
            this.priority_id = val
        },

        // 优先级点击变色
        priorityColor(index) {
            this.priority_active = index
        },
    },
    unmounted() {
        sessionStorage.removeItem("group_leader")
        sessionStorage.removeItem("declare_show")
    }
}
</script>

<style scoped>
/* ---- 内容部分(第一块) ---- */
.content-wrap {
    width: 100%;
    margin-top: 12px;
    padding: 20px 0 4px;
    background: #fff;
}

.declare-btn {
    width: 144px;
    height: 38px;
    margin: 0 15px 15px;
    background: #EBF5FF;
    color: #409EFF;
    font-size: 15px;
    text-align: center;
    line-height: 38px;
    border: 1px solid #409EFF;
    border-radius: 4px;
}

.info-box {
    width: 345px;
    margin: 0 auto 16px;
}

.info-title {
    color: #A1A7BA;
    font-size: 15px;
}

.info-input {
    position: relative;
    width: 343px;
    height: 43px;
    margin-top: 12px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
}

.info-input >>> .van-field__control {
    color: #222;
}

.required-icon {
    padding-left: 3px;
    color: #FF0000;
}

.vant-input-edit {
    height: 43px !important;
    line-height: 43px !important;
    padding: 0 15px !important;
    color: #222 !important;
    font-size: 15px !important;
}

.vant-input-edit >>> input::-webkit-input-placeholder {
    color: #606266 !important;
}

.radio-box {
    display: flex;
    margin-top: 12px;
    height: auto;
    color: #606266;
    font-size: 15px;
}

.radio-item {
    position: relative;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #C9CDD6;
    color: #606266;
    font-size: 15px;
}

.radio_active {
    border-color: #409EFF;
}

.radio-item:last-child {
    margin-right: 0;
}

.van-radio--horizontal {
    margin-right: 0;
    padding: 11px;
}

.radio-item >>> .van-radio__icon--round .van-icon {
    display: none;
}

.radio-item >>> .van-radio__label {
    position: relative;
    margin-left: 0;
    padding-left: 20px;
    z-index: 9;
}

.radio-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 14px;
    left: 12px;
    width: 10px;
    height: 10px;
    border: 2px solid #ABAEB8;
    border-radius: 50%;
}

.radio-icon-spot {
    width: 6px;
    height: 6px;
    background: #ABAEB8;
    border-radius: 50%;
}

.radio_active .radio-icon {
    border-color: #409EFF;
}

.radio_active .radio-icon-spot {
    background: #409EFF;
}

.info-textarea {
    height: 110px;
}

.info-textarea-edit {
    color: #222 !important;
    font-size: 15px !important;
}

.info-textarea-edit >>> textarea::-webkit-input-placeholder {
    color: #606266 !important;
}

.info-textarea-edit >>> textarea {
    min-height: 88px;
}

/*重置按钮*/
.down-icon {
    position: absolute;
    top: 0;
    right: 15px;
    width: 12px;
    height: 100%;
    color: #606266;
}

.down-icon svg {
    position: relative;
    top: 14px;
}

.down-icon i {
    position: relative;
    top: 14px;
    left: -2px;
    color: #C9C7C8;
}

/* ---- 动态联系方式部分样式 ---- */
.info-input-flex {
    display: flex;
    justify-content: space-between;
    width: 345px;
    height: 45px;
    border: none;
}

.content-info {
    line-height: 45px;
    color: #222;
    font-size: 15px;
}

.add-content {
    width: 75px;
    height: 45px;
    background: #409EFF;
    color: #fff;
    font-size: 15px;
    text-align: center;
    line-height: 45px;
    border-radius: 4px;
}

.concat-title {
    width: 80px;
    height: 43px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
}

.concat-info {
    width: 162px;
    height: 43px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
}

.content-del {
    background: #F56C6C;
}

.phone-dialog >>> .van-dialog__header {
    padding-top: 15px;
}

.phone-dialog >>> .van-dialog__footer {
    margin-top: 20px;
}

.dialog-phone-box {
    justify-content: space-between;
    padding: 0 20px;
}

.dialog-phone-name {
    color: #A1A7BA;
    font-size: 15px;
    margin: 15px 0 10px;
}

.dialog-phone-detail {
    width: 100%;
    height: 43px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
}

.dialog-phone-detail >>> .van-cell {
    padding: 0 15px;
    width: 100%;
    color: #222;
    font-size: 15px;
}

.dialog-phone-detail >>> input::-webkit-input-placeholder {
    color: #606266 !important;
}

.dialog-phone-detail >>> .van-field__control {
    height: 43px;
}

.phone-type .van-radio-group--horizontal, .phone-type .van-checkbox-group--horizontal {
    flex-direction: row-reverse;
}

.phone-type .radio-item {
    margin-left: 10px;
}

.phone-type .radio-item:last-child {
    margin-left: 0;
}

/* ---- 非输入部分(来源渠道, 跟单顾问, 运营客服) ---- */
.content-wrap-text {
    padding: 15px 0 1px;
}

.not-text-box {
    margin-top: 8px;
    color: #222;
    font-size: 15px;
}

/* ---- 复选框标签 ---- */
.checkbox-box {
    display: flex;
    flex-wrap: wrap;
}

.checkbox-box-item {
    flex-shrink: 0;
    margin: 0 5px 10px;
    padding: 10px 14px;
    border: 1px solid #F2F4F6;
    background: #F2F4F6;
    border-radius: 4px;
    color: #222;
    font-size: 15px;
}

.checkbox-box-item-active {
    border-color: #409EFF;
    background: #EBF5FF;
}

.checkbox-box-item >>> .van-checkbox__label {
    margin-left: 0;
    color: #222;
}

.checkbox-box-item-active >>> .van-checkbox__label {
    color: #409EFF;
}

.checkbox-box-item >>> .van-checkbox__icon {
    display: none;
}

.info-box-checkbox {
    width: 355px;
}

/* ---- 配偶信息详情部分 ---- */
.info-input-marriage {
    display: flex;
    justify-content: space-between;
    border: none;
    height: 45px;
}

.marriage-item {
    width: 265px;
    height: 43px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
}

.marriage-item-btn {
    width: 68px;
    height: 45px;
    background: #409EFF;
    color: #fff;
    font-size: 15px;
    text-align: center;
    line-height: 45px;
    border-radius: 4px;
}

/* ---- 备注添加部分 ---- */
.remarks-top {
    display: flex;
    justify-content: space-between;
    width: 345px;
    margin: 0 auto 20px;
}

.remarks-title {
    color: #606266;
    font-size: 15px;
    line-height: 30px;
}

.remarks-add {
    width: 67px;
    height: 30px;
    background: #409EFF;
    color: #fff;
    font-size: 15px;
    text-align: center;
    line-height: 30px;
    border-radius: 4px;
}

.remarks-info {
    width: 315px;
    margin: 0 auto 15px;
    padding: 15px;
    border: 1px solid #F0F1F4;
    border-radius: 4px;
}

.remarks-item {
    display: flex;
    margin-bottom: 13px;
}

.remarks-item-title {
    flex-shrink: 0;
    width: 45px;
    margin-right: 10px;
    color: #A1A7BA;
    font-size: 15px;
}

.remarks-item-content {
    color: #222;
    font-size: 15px;
}

.remarks-not-data {
    margin-top: -6px;
    padding: 0 15px 13px;
    color: #222;
    font-size: 15px;
}

/* ---- 历史签证 ---- */
.history-info .remarks-item-title {
    width: 75px;
    flex-shrink: 0;
}

.history-btn {
    display: flex;
    justify-content: space-between;
}

.history-btn-item {
    width: 150px;
    height: 40px;
    margin-top: 7px;
    background: #409EFF;
    color: #fff;
    font-size: 15px;
    text-align: center;
    line-height: 40px;
    border-radius: 4px;
}

.history-btn-item:last-child {
    background: #F56C6C;
}

/* ---- 下拉改样式 ---- */
.popup-data {
    padding-left: 15px;
    color: #222;
    font-size: 15px;
    line-height: 43px;
}

.popup-placeholder {
    padding-left: 15px;
    color: #606266;
    font-size: 15px;
    line-height: 43px;
}

/* ---- 0412 无效原因 ---- */
.search-term-detail {
    position: relative;
    width: 343px;
    height: 43px;
    margin: 0 auto;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
}

/*底部栏*/
.navIcon {
    height: 21px;
    width: 21px;
}
.declare-seat{
    height: 72px;
}

.declare-box{
    position: fixed;
    top: 0;
    width: 100%;
    padding: 1px 0;
    background: #FFFFFF;
    z-index: 100;
}

.declare-btn {
    width: 335px;
    height: 38px;
    margin: 15px;
    background: #EBF5FF;
    color: #409EFF;
    font-size: 15px;
    text-align: center;
    line-height: 38px;
    border: 1px solid #409EFF;
    border-radius: 60px;
}
</style>

